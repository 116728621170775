import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarHamburgerMenu.module.scss'

const HeaderTopBarHamburgerMenu = (): ReactElement => {
	const { isOpen, handleSetOpen } = useContext(HamburgerMenuContext)
	const { t } = useTranslation(['common'])

	const handleOpenMenu = useCallback(async (): Promise<void> => {
		handleSetOpen(true)
	}, [])

	return (
		<Button
			hasAriaPopup
			isAriaExpanded={ isOpen }
			variant="neutral"
			size="inherit"
			additionalClass={ styles.hamburgerButton }
			ariaLabel={ t('menu') }
			dataTestId={ DATA_TESTID.HEADER.BUTTON_OPEN_HAMBURGER_MENU }
			onClick={ handleOpenMenu }
		>
			<Icon
				name="hamburger"
				color="navy"
				width={ 24 }
				height={ 14 }
				additionalClass={ styles.icon }
			/>

			<div className={ styles.label }>
				{ t('menu') }
			</div>
		</Button>
	)
}

export { HeaderTopBarHamburgerMenu }
