import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { CartCustomerDataMainAddressForm, ICartCustomerDataEmptyProps } from '~/components/cart/cartCustomer'
import { Button } from '~/components/core/button'
import { useModal } from '~/hooks/modal'
import { CustomerDataRequestUnionType } from '~/api/dataTypes/customer'
import { Alert } from '~/components/core/notifications'

import styles from './CartCustomerDataEmpty.module.scss'

const CartCustomerDataEmpty = (props: ICartCustomerDataEmptyProps): ReactElement => {
	const { customer, customerType, isIncorrectNipPrefix, onCustomerUpdate } = props
	const { t } = useTranslation(['cart'])
	const { isOpen, handleOpen, handleClose } = useModal('CartCustomerDataEdit')

	const { firstName, lastName } = customer
	const alertInfo = isIncorrectNipPrefix ? t('customer.clientData.emptyDataForNipDescription') : t('customer.clientData.emptyDataDescription')

	const handleCustomerUpdate = useCallback(async (customerData: Partial<CustomerDataRequestUnionType>): Promise<void> => {
		await onCustomerUpdate(customerData)
		handleClose()
	}, [onCustomerUpdate])

	return (
		<div className={ styles.wrapper }>
			<span>
				{ firstName }{ ' ' }{ lastName }
			</span>

			<Alert
				icon="warning"
				type="warning"
				additionalClass={ styles.warning }
				canClose={ false }
			>
				{ alertInfo }
			</Alert>

			<Button
				isReverse
				isDisabled={ isIncorrectNipPrefix }
				variant="secondary"
				additionalClass={ styles.button }
				text={ t('customer.clientData.fillData') }
				onClick={ handleOpen }
			/>

			<CartCustomerDataMainAddressForm
				customerType={ customerType }
				customer={ customer }
				isOpen={ isOpen }
				onUpdate={ handleCustomerUpdate }
				onOpen={ handleOpen }
				onClose={ handleClose }
			/>
		</div>
	)
}

export { CartCustomerDataEmpty }
