import React, { ReactElement, useEffect, useCallback, useState, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { isEmpty, isNull, isEqual } from 'lodash'

import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { Container } from '~/components/core/layout/container'
import { CartColumnWrapper } from '~/components/core/cartColumnWrapper'
import { Alert } from '~/components/core/notifications'
import { CartPreviewSection, CartPreviewPreferredStore, CartPreviewActions, CartPreviewPayment, CartPreviewDelivery, CartPreviewEmpty, CartPreviewProducts, CartPreviewSummary, ICartPreviewProps } from '~/components/cart/cartPreview'
import { LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY } from '~/components/cart/cartCustomer'
import { CartAsideWrapper } from '~/components/core/cartAside'
import { CartStepsHeader } from '~/components/cart/cartStepsHeader'
import { getCartData } from '~/actions/cart'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useSare } from '~/hooks/sare'
import { UserContext } from '~/providers/userProvider'
import { getCustomerData } from '~/actions/customer'
import { AuthLoader } from '~/components/core/auth'
import { localStorageGet } from '~/utils/storage'
import { useMozaic } from '~/hooks/mozaic'
import { useLoyaltyCustomer } from '~/hooks/loyaltyCustomer'

import styles from './CartPreview.module.scss'

const CartPreview = (props: ICartPreviewProps): ReactElement => {
	const { data: { shouldGetCustomerData } } = props
	const { data, isPending, tmpCourierNote, activeDeliveryGroup, isStepZero, hasCustomerLoyalty } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { data: { simpleCart: { cartUuid: identificationCartUuid } } } = useSelector((state: IRootState) => state.cartIdentification, shallowEqual)
	const { isPending: isCustomerPending } = useSelector((state: IRootState) => state.customer, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const { t } = useTranslation(['cart'])
	const { GA_checkout } = useGoogleAnalytics()
	const [isAnalyticsEventsSent, setIsAnalyticsEventsSent] = useState<boolean>(false)
	const [isCartDataReady, setIsCartDataReady] = useState<boolean>(false)
	const { SARE_checkoutView } = useSare()
	const { isLogged, isUserLoginPending } = useContext(UserContext)
	const { getShouldUseMozaicFlag } = useMozaic()
	const { shouldHidePromoCodeForNonLoyaltyLoggedUsers, onSetCartLoyaltyCustomerExist } = useLoyaltyCustomer()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { cart, discountCounter, deliveryGroups, anyDeliveryAndPaymentsAvailable, invalidCartMessage, cartStatus } = data
	const { cartItemList, cartUuid, productsValue, paymentSummaryValue } = cart
	const isDeliverySectionVisible = anyDeliveryAndPaymentsAvailable && !isNull(deliveryGroups) && !isStepZero
	const isPaymentSectionVisible = anyDeliveryAndPaymentsAvailable && !isStepZero
	const isCartEmpty = isCartDataReady && isEmpty(cartItemList) && !isPending

	const sectionProductsTitle = shouldUseMozaic ? (isStepZero ? t('zero.table.caption') : t('preview.caption')) : (isStepZero ? t('zero.table.caption') : t('preview.table.caption'))
	const sectionDeliveryTitle = shouldUseMozaic ? t('preview.delivery.title') : t('preview.delivery.caption')
	const sectionPaymentTitle = shouldUseMozaic ? t('preview.payment.title') : t('preview.payment.caption')

	useEffectOnce(() => {
		(async () => {
			if (isLogged && shouldGetCustomerData) {
				await dispatch(getCustomerData())
				await onSetCartLoyaltyCustomerExist()
			}
		})()
	})

	const renderContent = useCallback((): ReactElement => {
		if (isUserLoginPending) {
			return <AuthLoader />
		}

		if (isCartEmpty) {
			return (
				<CartPreviewEmpty />
			)
		}

		return (
			<>
				<CartColumnWrapper>
					<CartPreviewPreferredStore preferredStore={ preferredStore } />

					<CartPreviewSection title={ sectionProductsTitle } isLoading={ isPending }>
						{ !anyDeliveryAndPaymentsAvailable && (
							<Alert
								icon="warning"
								type="warning"
								additionalClass={ styles.warning }
								canClose={ false }
							>
								{ invalidCartMessage }
							</Alert>
						) }

						<CartPreviewProducts cart={ cart } />
					</CartPreviewSection>

					{ !isPending && <CartPreviewActions /> }

					{ isDeliverySectionVisible && (
						<CartPreviewSection
							title={ sectionDeliveryTitle }
							additionalClass={ styles.delivery }
							isLoading={ isPending }
						>
							<CartPreviewDelivery
								cartData={ data }
								tmpCourierNote={ tmpCourierNote }
								activeDeliveryGroup={ activeDeliveryGroup }
							/>
						</CartPreviewSection>
					) }

					{ isPaymentSectionVisible && (
						<CartPreviewSection
							title={ sectionPaymentTitle }
							additionalClass={ styles.payment }
							isLoading={ isPending }
						>
							<CartPreviewPayment cartData={ data } activeDeliveryGroup={ activeDeliveryGroup } />
						</CartPreviewSection>
					) }
				</CartColumnWrapper>

				<CartAsideWrapper isLoading={ isPending }>
					<CartPreviewSummary
						isStepZero={ isStepZero }
						shouldHidePromoCodeForNonLoyaltyLoggedUsers={ shouldHidePromoCodeForNonLoyaltyLoggedUsers }
						discountCounter={ discountCounter }
						cart={ cart }
					/>
				</CartAsideWrapper>
			</>
		)
	}, [cartUuid, data, preferredStore, tmpCourierNote, activeDeliveryGroup, isPending, isCartEmpty, isUserLoginPending, sectionProductsTitle, sectionDeliveryTitle, sectionPaymentTitle, isPaymentSectionVisible, isStepZero, hasCustomerLoyalty])

	const analyticsEvents = useCallback(() => {
		if (!isPending) {
			if (!isEmpty(cartItemList) && !isAnalyticsEventsSent) {
				GA_checkout({ cartItemList, step: isStepZero ? 0 : 1, value: productsValue, cartStatus })
				setIsAnalyticsEventsSent(true)
				SARE_checkoutView(cartItemList, paymentSummaryValue)
			}
		}
	}, [cartItemList, isAnalyticsEventsSent, productsValue, paymentSummaryValue, isPending, isStepZero, cartStatus])

	useEffect(() => {
		(async () => {
			const isAllowOverwriteDeliveryWay = !isCartDataReady && !isEqual(localStorageGet(LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY), 'false')

			await dispatch(getCartData(isAllowOverwriteDeliveryWay))

			setIsCartDataReady(true)
		})()
	}, [preferredStore, identificationCartUuid])

	useEffect(() => {
		analyticsEvents()
	}, [cartItemList])

	return (
		<Container additionalClass={ styles.wrapper }>
			{ (isCartDataReady && !isCustomerPending && !isStepZero) && (
				<CartStepsHeader cartStatus="VIEW" />
			) }

			<div className={ styles.container }>
				{ isCartDataReady && !isCustomerPending ? renderContent() : null }
			</div>
		</Container>
	)
}

export { CartPreview }
