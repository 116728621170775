import { isNil } from 'lodash'

import { withFresherToken } from '~/actions/auth'
import { AppPromiseThunkAction } from '~/actions'
import { getCalendarCartSummary } from '~/api/requests/calendarService'
import { GetServiceCartSummaryActionType, GetServiceCartSummaryStoreActionType, SetServiceCartSummaryPaymentWayActionType, ServiceCartSummaryType } from '~/actions/serviceCartSummary'
import { getStore } from '~/api/requests/store'
import { CalendarServicePaymentWayCodeType } from '~/api/dataTypes/calendarService'

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartSummaryData = withFresherToken((): AppPromiseThunkAction<GetServiceCartSummaryActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY,
		payload: getCalendarCartSummary(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartSummaryStoreData = (): AppPromiseThunkAction<GetServiceCartSummaryStoreActionType> => async (dispatch, getState) => {
	return dispatch({
		type: ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY_STORE,
		payload: async () => {
			const { serviceCartSummary: { data: { cart: { store } } } } = getState()

			const storeCode = store?.storeCode

			if (isNil(storeCode)) {
				return null
			}

			return getStore({ storeCode })
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartSummaryPaymentWay = (paymentWay: CalendarServicePaymentWayCodeType): AppPromiseThunkAction<SetServiceCartSummaryPaymentWayActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartSummaryType.SET_SERVICE_CART_SUMMARY_PAYMENT_WAY,
		payload: Promise.resolve(paymentWay),
	})
}
