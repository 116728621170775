import { isUndefined, reduce } from 'lodash'

import { WithoutUndefinedValuesType } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefinedFields = (<T extends Record<string, any>>(data: T): WithoutUndefinedValuesType<T> => reduce(Object.entries(data), (acc: WithoutUndefinedValuesType<T>, [key, value]: [keyof T, T[keyof T]]) => {
	if (!isUndefined(value)) {
		return Object.assign(acc, { [key]: value })
	}

	return acc
}, {} as WithoutUndefinedValuesType<T>))
