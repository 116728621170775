import React, { ReactElement } from 'react'
import ReactCalendar from 'react-calendar'
import classNames from 'classnames'
import 'react-calendar/dist/Calendar.css'

import { CalendarToggleMonth, ICalendarProps } from '~/components/core/calendar'

import styles from './Calendar.module.scss'

const Calendar = (props: ICalendarProps): ReactElement => {
	const {
		onChange, value,
		isRange = false,
	} = props

	const calendarClass = classNames({
		[styles.calendar]: true,
		[styles.rangeCalendar]: isRange,
	})

	return (
		<ReactCalendar
			value={ value }
			className={ calendarClass }
			prevLabel={ <CalendarToggleMonth type="prev" /> }
			nextLabel={ <CalendarToggleMonth type="next" /> }
			prev2Label={ null }
			next2Label={ null }
			selectRange={ isRange }
			onChange={ onChange }
		/>
	)
}

export { Calendar }
