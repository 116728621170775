export const LS_KEY_CART_UUID = 'cartUuid'
export const LS_KEY_CALENDAR_CART_UUID = 'calendarCartUuid'
export const LS_KEY_AUTH_USER = 'authUser'
export const LS_KEY_LAST_VIEWED_PRODUCTS = 'lastViewedProducts'
export const LS_KEY_PRODUCT_CARD_OPINION_VOTES = 'productCardOpinionVotes'
export const LS_KEY_PREFERRED_STORE = 'preferredStore'
export const LS_KEY_USER_ACTIVE_CONSENT = 'userActiveConsent'
export const LS_KEY_SHOULD_BE_LOGIN_BY_ADEO = 'shouldBeLoginByAdeo'
export const LS_KEY_ADEO_AUTH_USER = 'adeoAuthUser'

export const COOKIE_KEY_PREFERRED_STORE = 'preferredStore'
export const COOKIE_MAX_AGE_PREFERRED_STORE_CODE = 10 * 365 * 24 * 60 * 60 // 10 years
export const COOKIE_MAX_AGE_ONE_YEAR = 31556951

export const EXCLUDE_KOBI_FRAGMENTS_PAGE_CONTEXT = ['CART', 'CART_IDENTIFICATION', 'CART_CUSTOMER', 'CART_SUMMARY', 'CART_FINALIZE', 'CALENDAR_CART_APPOINTMENT', 'CALENDAR_CART_LOGIN', 'CALENDAR_CART_CUSTOMER_DATA', 'CALENDAR_CART_SUMMARY', 'CALENDAR_CART_CONFIRMATION', 'CALENDAR_CART_CREATE', 'TRANSFER_CART']

export const MARKETING_CONSENT_COOKIE_CODE = 'C0004'
export const MARKETING_CONSENT_MAX_LENGTH = 127

export const ADEO_QUERY_LOGIN_PARAM = 'auth-mode'

export const COOKIE_KEY_ADEO_ID_TOKEN = 'idToken.jwt'
export const COOKIE_KEY_ADEO_KOBI_TOKEN = 'Kobi.jwt'
export const COOKIE_KEY_ADEO_AUTOLOGIN_TOKEN = 'autologin.jwt'
export const COOKIE_KEY_CUSTOMER_UUID = 'CUSTOMER_UUID'
export const COOKIE_KEY_ADEO_STORE = 'store'
export const COOKIE_KEY_ADEO_STORE_ID = 'store_id'
export const COOKIE_KEY_ADEO_LMPL_STORE_ID = 'lmpl_store_id'
export const COOKIE_KEY_TEMPO_ORDER_ID = 'Order.id'

export const ADEO_LOGOUT_URL = '/authentication/oauth2/logout'

export const PLANNER_CUSTOMER_SERVICE_NEW = 'planner_customer_service_new'
