import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { CartConfirmationActionType, CartConfirmationType } from '~/actions/cartConfirmation'

import { ICartConfirmationState } from './types'
import { cartConfirmationInitialState as initialState } from './constants'

export const cartConfirmation = produce((draft: Draft<ICartConfirmationState>, action: AppPromiseAction<CartConfirmationActionType>) => {
	switch (action.type) {
		case REQUEST(CartConfirmationType.GET_CART_CONFIRMATION_DATA):
			draft.isPending = true

			return draft
		case FAILURE(CartConfirmationType.GET_CART_CONFIRMATION_DATA):
			draft.isFailure = true
			draft.isPending = false

			return draft
		case SUCCESS(CartConfirmationType.GET_CART_CONFIRMATION_DATA):
			draft.data = action.payload.data
			draft.isFailure = false
			draft.isPending = false

			return draft
		case SUCCESS(CartConfirmationType.SET_CART_CONFIRMATION_ORDER_FRONT_ID):
			draft.orderFrontId = action.payload

			return draft
	}
}, initialState)
