import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'

export enum ServiceCartConfirmationType {
	'GET_SERVICE_CART_CONFIRMATION_DATA' = 'serviceCartConfirmation/GET_SERVICE_CART_CONFIRMATION_DATA',
	'SET_SERVICE_CART_CONFIRMATION_ORDER_FRONT_ID' = 'serviceCartConfirmation/SET_CART_CONFIRMATION_ORDER_FRONT_ID',
}

export type GetServiceCartConfirmationDataActionType = AppAction<ServiceCartConfirmationType.GET_SERVICE_CART_CONFIRMATION_DATA, AxiosResponse<IOrderDetailsResponse>>
export type SetServiceCartConfirmationOrderFrontIdActionType = AppAction<ServiceCartConfirmationType.SET_SERVICE_CART_CONFIRMATION_ORDER_FRONT_ID, string>

export type ServiceCartConfirmationActionType = GetServiceCartConfirmationDataActionType | SetServiceCartConfirmationOrderFrontIdActionType
