import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Input } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { CartPreviewSummaryLoyaltyProgramPinModalError, ICartPreviewSummaryLoyaltyProgramPinModalFormProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewSummaryLoyaltyProgramPinModalForm.module.scss'

const CartPreviewSummaryLoyaltyProgramPinModalForm = (props: ICartPreviewSummaryLoyaltyProgramPinModalFormProps): ReactElement => {
	const { hasAuthorizationError, control, isSubmitting, onSubmit } = props
	const { t } = useTranslation(['cart', 'common'])

	return (
		<form className={ styles.wrapper } onSubmit={ onSubmit }>
			<div className={ styles.caption }>
				{ t('preview.aside.discount.domProgram.modal.caption', { ns: 'cart' }) }
			</div>

			<div className={ styles.description }>
				{ t('preview.aside.discount.domProgram.modal.description', { ns: 'cart' }) }
			</div>

			{ hasAuthorizationError && (
				<CartPreviewSummaryLoyaltyProgramPinModalError />
			) }

			<Input
				control={ control }
				name="cardPassword"
				label={ t('preview.aside.discount.domProgram.modal.passwordLabel', { ns: 'cart' }) }
				type="password"
				theme={ { icon: styles.icon } }
			/>

			<Button
				isLoading={ isSubmitting }
				type="submit"
				text={ t('preview.aside.discount.domProgram.modal.apply', { ns: 'cart' }) }
				additionalClass={ styles.button }
			/>
		</form>
	)
}

export { CartPreviewSummaryLoyaltyProgramPinModalForm }
