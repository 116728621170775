import React, { ReactElement, useContext, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, FieldPath, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axios, { AxiosError } from 'axios'
import * as yup from 'yup'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { includes, isEmpty, isEqual, isFunction, isUndefined, map } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { UserContext } from '~/providers/userProvider'
import { Alert } from '~/components/core/notifications'
import { Button } from '~/components/core/button'
import { Input, PasswordInput } from '~/components/core/form'
import { Link } from '~/components/core/link'
import { ISignInFormProps, SignInAdeo } from '~/components/signIn'
import { ISignInFormData, useAdeoSignIn, useSignIn } from '~/hooks/signIn'
import { useLogError } from '~/hooks/logError'
import { useFormError } from '~/hooks/formError'
import { useMozaic } from '~/hooks/mozaic'
import { IAuthResponseException } from '~/api/dataTypes/auth'
import { AppDispatch } from '~/state/store'
import { setLogout } from '~/actions/auth'

import styles from './SignInForm.module.scss'

const SignInForm = (props: ISignInFormProps): ReactElement => {
	const {
		caption,
		isFromCart = false,
		isFromServiceCart = false,
		theme = {},
	} = props
	const { t, i18n } = useTranslation(['signIn', 'form', 'common'])
	const { urls } = useContext(AppParametersContext)
	const { isLogged } = useContext(UserContext)
	const { handleSignIn, handleFetchDataAfterSignIn, handleRedirectAfterSignIn } = useSignIn({ isFromCart, isFromServiceCart })
	const [alertMessage, setAlertMessage] = useState<string | null>(null)
	const { getErrorMessage } = useFormError()
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()
	const { shouldLoginByAdeo } = useAdeoSignIn()
	const { getShouldUseMozaicFlag } = useMozaic()
	const router = useRouter()

	const schema: yup.SchemaOf<ISignInFormData> = useMemo(() => yup.object().shape({
		username: yup.string().when('.', {
			is: (val: string) => !(val.includes('@')),
			then: (schema: yup.StringSchema) => schema.email(t('validation.emailMustContainAtSign', { ns: 'form' })),
			otherwise: (schema: yup.StringSchema) => schema.email(t('validation.emailFormat', { ns: 'form' })),
		}).required(t('required', { ns: 'form' })),
		password: yup.string().required(t('required', { ns: 'form' })),
	}), [t, i18n])

	const { control, handleSubmit, setError, resetField, formState: { isSubmitting, isValid, isDirty } } = useForm<ISignInFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			username: '',
			password: '',
		},
		mode: 'onChange',
	})

	const isSubmitDisabled = !isValid || !isDirty
	const isShopVersion = isEqual(router.query?.isShopVersion, 'true') || includes(router.query?.from, 'isShopVersion')

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ISignInFormData) => {
		try {
			await handleSignIn(formData)

			const cartIdentification = await handleFetchDataAfterSignIn()

			await handleRedirectAfterSignIn({ cartIdentification })
		} catch (e: unknown) {
			const error = e as AxiosError<IAuthResponseException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data
				const { _fields: fields } = control
				const errorMessage = code === 'UNAUTHORIZED' ? t('validation.unauthorized', { ns: 'signIn' }) : getErrorMessage(code)

				map(fields, (field: Field): void => {
					const { _f: { name: fieldName } } = field

					const name = fieldName as FieldPath<ISignInFormData>

					if (isEqual(name, 'password') && isEqual(code, 'UNAUTHORIZED')) {
						resetField(name)
					}

					setError(name, { message: '' })
				})

				setAlertMessage(errorMessage)

				if (!isUndefined(fields.username)) {
					const focusFunction = fields.username._f.ref.focus

					if (isFunction(focusFunction)) {
						focusFunction()
					}
				}

				if (isLogged) {
					await dispatch(setLogout())
				}
			} else {
				setAlertMessage(getErrorMessage('UNKNOWN_EXCEPTION'))
			}

			sendLogError(e)
		}
	}), [isLogged, alertMessage, control, setError, resetField])

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)
	const captionClass = shouldUseMozaic ? 'mt-heading mt-heading--s' : classNames(styles.wrapper)
	const actionsClass = classNames(styles.actions, theme.actions)

	if (shouldLoginByAdeo && !isShopVersion) {
		return (
			<SignInAdeo caption={ caption } theme={ theme } />
		)
	}

	return (
		<form
			noValidate
			className={ wrapperClass }
			aria-live="assertive"
			onSubmit={ handleFormSubmit }
		>
			<h2 className={ captionClass }>
				{ caption }
			</h2>

			<h3 className={ styles.description }>
				{ t('description.currentCustomer', { ns: 'signIn' }) }
			</h3>

			<div className={ styles.fields }>
				<Alert
					icon="errorIcon"
					type="danger"
					shouldRender={ !isEmpty(alertMessage) }
					canClose={ false }
					additionalClass={ styles.alert }
				>
					{ alertMessage }
				</Alert>

				<Input
					isRequired
					control={ control }
					name="username"
					label={ t('mailLabel', { ns: 'signIn' }) }
					type="email"
				/>

				<PasswordInput
					isRequired
					name="password"
					label={ t('passwordLabel', { ns: 'signIn' }) }
					control={ control }
				/>
			</div>

			<div className={ actionsClass }>
				<Link
					href={ urls.passwordRecovery }
					additionalClass={ styles.link }
					ariaLabel={ t('passwordReminder') }
				>
					{ t('passwordReminder', { ns: 'signIn' }) }
				</Link>
			</div>

			<Button
				type="submit"
				variant="secondary"
				text={ t('signIn', { ns: 'common' }) }
				additionalClass={ styles.button }
				isLoading={ isSubmitting }
				isDisabled={ isSubmitDisabled }
			/>
		</form>
	)
}

export { SignInForm }
