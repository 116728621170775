import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { ICollapseTextButtonProps } from '~/components/core/collapseText'
import { Button } from '~/components/core/button'

import styles from './CollapseTextButton.module.scss'

const CollapseTextButton = (props: ICollapseTextButtonProps): ReactElement => {
	const { isOpened, id, onShowFullText, additionalClass } = props
	const { t } = useTranslation(['common'])

	const label = isOpened ? t('less') : t('more')

	const buttonClass = classNames(styles.wrapper, additionalClass)

	return (
		<Button
			text={ label }
			size="inherit"
			variant="neutral"
			additionalClass={ buttonClass }
			id={ id }
			onClick={ onShowFullText }
		/>
	)
}

export { CollapseTextButton }
