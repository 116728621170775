
import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { IHiddenRadioPrefixProps } from '~/components/core/form'

import styles from './HiddenRadioPrefix.module.scss'

const HiddenRadioPrefix = (props: IHiddenRadioPrefixProps): ReactElement | null => {
	const { isChecked, isDisabled } = props
	const { t } = useTranslation(['form'])

	const checkedPart = isChecked ? t('radio.isChecked') : t('radio.isNotChecked')
	const disabledPart = isDisabled ? ` - ${ t('radio.unavailability') }` : ''

	return (
		<span className={ styles.wrapper }>
			{ `${ t('radio.radioButton') }${ disabledPart } - ${ checkedPart } - ` }
		</span>
	)
}

export { HiddenRadioPrefix }
