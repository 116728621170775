import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { SetAdeoLogoutActionType, SetLogoutActionType } from '~/actions/auth'
import { IFavouriteListResponse, IFavouriteListSimpleResponse, IFavouriteListTransferProductsResponse } from '~/api/dataTypes/favouriteList'

export enum FavouriteListActionType {
	GET_FAVOURITE_LIST = 'favouriteList/GET_FAVOURITE_LIST',
	GET_FAVOURITE_LIST_SIMPLE = 'favouriteList/GET_FAVOURITE_LIST_SIMPLE',
	ADD_PRODUCT_TO_FAVOURITE_LIST = 'favouriteList/ADD_PRODUCT_TO_FAVOURITE_LIST',
	TRANSFER_PRODUCT_TO_CART = 'favouriteList/TRANSFER_PRODUCT_TO_CART',
	REMOVE_PRODUCT_FROM_FAVOURITE_LIST = 'favouriteList/REMOVE_PRODUCT_FROM_FAVOURITE_LIST',
}

export type GetFavouriteListActionType = AppAction<FavouriteListActionType.GET_FAVOURITE_LIST, AxiosResponse<IFavouriteListResponse | undefined>>

export type GetFavouriteListSimpleActionType = AppAction<FavouriteListActionType.GET_FAVOURITE_LIST_SIMPLE, AxiosResponse<IFavouriteListSimpleResponse | undefined>>

export type AddToFavouriteListActionType = AppAction<FavouriteListActionType.ADD_PRODUCT_TO_FAVOURITE_LIST, AxiosResponse<IFavouriteListSimpleResponse>>

export type TransferProductToCartActionType = AppAction<FavouriteListActionType.TRANSFER_PRODUCT_TO_CART, AxiosResponse<IFavouriteListTransferProductsResponse>>

export type RemoveFromFavouriteListActionType = AppAction<FavouriteListActionType.REMOVE_PRODUCT_FROM_FAVOURITE_LIST, AxiosResponse<IFavouriteListSimpleResponse>>

export type FavouriteListActionUnionType = GetFavouriteListActionType | GetFavouriteListSimpleActionType | AddToFavouriteListActionType | TransferProductToCartActionType | RemoveFromFavouriteListActionType | SetLogoutActionType | SetAdeoLogoutActionType
