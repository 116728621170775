import React, { ReactElement } from 'react'

import { IHeaderTopBarSearchBarProductPriceProps } from '~/components/core/layout'

import styles from './HeaderTopBarSearchBarProductPrice.module.scss'

const HeaderTopBarSearchBarProductPrice = (props: IHeaderTopBarSearchBarProductPriceProps): ReactElement => {
	const { bigPrice, bigPriceUnit } = props

	return (
		<span className={ styles.wrapper }>
			<span className={ styles.price }>{ bigPrice }</span>

			<span className={ styles.separator }>/</span>

			<span className={ styles.unit }>{ bigPriceUnit }</span>
		</span>
	)
}

export { HeaderTopBarSearchBarProductPrice }
