import { TFunction } from 'next-i18next'
import { isUndefined } from 'lodash'

import { DeliveryGroupTranslationType } from '~/components/cart/cartPreview'

export const getCartPreviewDeliveryGroupAriaLabel = (name: DeliveryGroupTranslationType, description: DeliveryGroupTranslationType, isSelected: boolean, t: TFunction): string => {
	const selectedPart = isSelected ? t('radio.isChecked') : t('radio.isNotChecked')
	const descriptionPart = !isUndefined(description) ? ` - ${ description }` : ''

	return `${ selectedPart } - ${ name }${ descriptionPart }`
}
