import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './CartCustomerInvoiceHeader.module.scss'

const CartCustomerInvoiceHeader = (): ReactElement => {
	const { t } = useTranslation(['cart'])

	return (
		<h3 className={ styles.wrapper }>
			{ t('customer.invoice.header') }
		</h3>
	)
}

export { CartCustomerInvoiceHeader }
