import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { IHeaderTopBarSearchBarWrapperProps } from '~/components/core/layout'
import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { HeaderTopBarSearchContext } from '~/providers/headerTopBarSearchProvider'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './HeaderTopBarSearchBarWrapper.module.scss'

const HeaderTopBarSearchBarWrapper = (props: IHeaderTopBarSearchBarWrapperProps): ReactElement => {
	const { children } = props
	const { isOpenSearchModal, onCloseSearchModal, onOpenSearchModal } = useContext(HeaderTopBarSearchContext)
	const { t } = useTranslation(['common'])
	const { isDesktop } = useMediaQuery()

	const isModalOpen = isDesktop ? false : isOpenSearchModal

	return (
		<>
			<div className={ styles.wrapper }>
				{ children }
			</div>

			<Modal
				isOpen={ isModalOpen }
				additionalClass={ styles.modal }
				onOpen={ onOpenSearchModal }
			>
				<Button
					variant="neutral"
					size="inherit"
					ariaLabel={ t('back') }
					additionalClass={ styles.button }
					onClick={ onCloseSearchModal }
				>
					<Icon
						name="arrow"
						width={ 7 }
						color="green"
						additionalClass={ styles.arrow }
					/>

					<span className={ styles.label }>
						{ t('back') }
					</span>
				</Button>

				{ children }
			</Modal>
		</>
	)
}

export { HeaderTopBarSearchBarWrapper }
