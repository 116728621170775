import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { find, isUndefined, isNull } from 'lodash'

import { ICartItemData } from '~/api/dataTypes/cart'
import { IRootState } from '~/state/types'
import { Modal } from '~/components/core/modal'
import { AddToCartModalHeader, AddToCartModalProductBlock, AddToCartModalActions, AddToCartModalProductCarousel } from '~/components/core/addToCartModal'
import { AddToCartModalContext } from '~/providers/addToCartModalProvider'

import styles from './AddToCartModal.module.scss'

const AddToCartModal = (): ReactElement => {
	const { isOpen, handleClose, activeProduct } = useContext(AddToCartModalContext)
	const { data, addedToCart } = useSelector((state: IRootState) => state.miniCart, shallowEqual)
	const { products: lastViewedProducts } = useSelector((state: IRootState) => state.lastViewed, shallowEqual)
	const [activeProductQuantity, setActiveProductQuantity] = useState<number>(0)
	const router = useRouter()

	const { cartItemList, wwwUrl } = data

	useEffect(() => {
		const foundProduct = find(cartItemList, (item: ICartItemData) => item.productSimple.id === activeProduct?.productId)

		if (!isUndefined(foundProduct) && !isNull(activeProduct)) {
			setActiveProductQuantity(activeProduct.quantity)
		}
	}, [cartItemList, activeProduct])

	useEffect(() => {
		handleClose()
	}, [router.asPath])

	return (
		<Modal
			additionalClass={ styles.modal }
			isOpen={ isOpen }
			onClose={ handleClose }
		>
			<AddToCartModalHeader />

			<AddToCartModalProductBlock productSimple={ addedToCart } quantity={ activeProductQuantity } />

			<AddToCartModalActions wwwUrl={ wwwUrl } onClose={ handleClose } />

			<AddToCartModalProductCarousel products={ lastViewedProducts } />
		</Modal>
	)
}

export { AddToCartModal }
