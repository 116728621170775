import { useEffect, useState, useRef, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isNull } from 'lodash'

import { IUseAddressesUpdateData, isAddressInList, getUserAddresses, ADDRESS_MAX_REQUESTS } from '~/hooks/addressesUpdate'
import { useAlert } from '~/hooks/alert'
import { ICustomerAddressRequest } from '~/api/dataTypes/customer'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { getCustomerData } from '~/actions/customer'

export const useAddressesUpdate = (): IUseAddressesUpdateData => {
	const userData = useSelector((state: IRootState) => state.customer.data, shallowEqual)
	const [isUpdating, setIsUpdating] = useState<boolean>(false)
	const [activeAddress, setActiveAddress] = useState<Partial<ICustomerAddressRequest> | null>(null)
	const dispatch: AppDispatch = useDispatch()
	const { preventAlert } = useAlert()
	const intervalRef = useRef<NodeJS.Timer | null>(null)
	const requestCounter = useRef<number>(0)

	const clearRequestInterval = useCallback((): void => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current)
			intervalRef.current = null
			setActiveAddress(null)
			setIsUpdating(false)
		}
	}, [])

	const handleUserData = useCallback(async (): Promise<void> => {
		if (requestCounter.current >= ADDRESS_MAX_REQUESTS) {
			clearRequestInterval()
			preventAlert()

			requestCounter.current = 0

			return
		}

		await dispatch(getCustomerData())

		requestCounter.current++
	}, [])

	const onAddressAction = useCallback(async (newAddress: Partial<ICustomerAddressRequest>): Promise<void> => {
		await dispatch(getCustomerData())
		setActiveAddress(newAddress)
	}, [])

	useEffect(()=> {
		return (() => {
			clearRequestInterval()
		})
	}, [])

	useEffect(() => {
		const addresses = getUserAddresses(userData)

		if (isAddressInList(addresses, activeAddress)) {
			clearRequestInterval()
		}
	}, [userData])

	useEffect(() => {
		const addresses = getUserAddresses(userData)

		if (!isNull(activeAddress) && !isAddressInList(addresses, activeAddress)) {
			setIsUpdating(true)
			intervalRef.current = setInterval(handleUserData, 3000)
		}
	}, [activeAddress])

	return {
		isUpdating,
		onAddressAction,
	}
}
