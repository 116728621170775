import React, { ReactElement } from 'react'

import { HeaderWrapper, HeaderTopBar, HeaderMenu, IHeaderProps } from '~/components/core/layout'
import { SkipContentList } from '~/components/core/skipContent'
import { Notifications } from '~/components/core/notifications'
import { useSkipElements } from '~/hooks/skipElements'

const Header = (props: IHeaderProps): ReactElement => {
	const { pageType } = props
	const { headerSkip } = useSkipElements(undefined, pageType)

	return (
		<HeaderWrapper>
			<SkipContentList skipElements={ headerSkip } />

			<HeaderTopBar />

			<HeaderMenu pageType={ pageType } />

			<Notifications />
		</HeaderWrapper>
	)
}

export { Header }
