import React, { ReactElement, useMemo, useEffect, useCallback } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import classNames from 'classnames'
import { isEmpty, isEqual, map, isNull } from 'lodash'

import { buildPaymentRadioOptions, CartPreviewPaymentCashOnDelivery, CartPreviewPaymentLabel, ICartPreviewPaymentOption, ICartPreviewPaymentProps, IPaymentForm, isCashOnDelivery } from '~/components/cart/cartPreview'
import { SkipContentAnchor, SkipContentList } from '~/components/core/skipContent'
import { Radio } from '~/components/core/form'
import { AppDispatch } from '~/state/store'
import { setCartPaymentWayId } from '~/actions/cart'
import { SKIP_CART_PAYMENT_METHODS, useSkipElements } from '~/hooks/skipElements'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewPayment.module.scss'

const CartPreviewPayment = (props: ICartPreviewPaymentProps): ReactElement => {
	const { cartData, activeDeliveryGroup } = props
	const dispatch: AppDispatch = useDispatch()
	const { cartPreviewPaymentSkip } = useSkipElements()
	const { getShouldUseMozaicFlag } = useMozaic()

	const { paymentList, cart, deliveryGroups } = cartData
	const { selectedPaymentWayId, selectedDeliveryWayId } = cart
	const cashOnDelivery = !isNull(deliveryGroups) ? isCashOnDelivery(activeDeliveryGroup, selectedDeliveryWayId, deliveryGroups.delivery.deliveryList) : false

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	const schema: yup.SchemaOf<IPaymentForm> = useMemo(() => yup.object().shape({
		type: yup.string().required(),
	}), [])

	const { control, watch, setValue } = useForm<IPaymentForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			type: !isNull(selectedPaymentWayId) ? selectedPaymentWayId.toString() : '',
		},
	})

	const { field, fieldState: { error } } = useController({ control, name: 'type' })

	const radioOptions: ICartPreviewPaymentOption[] = buildPaymentRadioOptions(paymentList)
	const paymentType = watch('type')

	const handleSetPaymentWayId = useCallback(async (paymentType: string): Promise<void> => {
		await dispatch(setCartPaymentWayId(Number(paymentType)))
	}, [])

	const renderOptions = useCallback((): ReactElement[] => (
		map(radioOptions, (option: ICartPreviewPaymentOption) => {
			const { value, isAllowed } = option
			const isChecked = isEqual(field.value, value)

			const labelClass = classNames({
				[styles.paymentLabel]: true,
				[styles.selected]: isChecked,
			})

			return (
				<Radio
					key={ value }
					value={ value }
					field={ field }
					error={ error }
					isRequired={ false }
					isDisabled={ !isAllowed }
					additionalClass={ labelClass }
				>
					<CartPreviewPaymentLabel
						option={ option }
						isChecked={ isChecked }
						error={ error }
						isDisabled={ !isAllowed }
					/>
				</Radio>
			)
		})
	), [radioOptions, field])

	useEffect(() => {
		(async () => {
			if (!isEmpty(paymentType)) {
				await handleSetPaymentWayId(paymentType)
			}
		})()
	}, [paymentType])

	useEffect(() => {
		setValue('type', !isNull(selectedPaymentWayId) ? selectedPaymentWayId.toString() : '')
	}, [selectedPaymentWayId])

	useEffect(() => {
		if (cashOnDelivery) {
			setValue('type', '')
		}
	}, [cashOnDelivery])

	return (
		<div className={ wrapperClass }>
			<SkipContentAnchor elementId={ SKIP_CART_PAYMENT_METHODS } additionalClass={ styles.skipContentAnchor } />

			<SkipContentList skipElements={ cartPreviewPaymentSkip } theme={ { wrapper: styles.skipContentList } } />

			<form className={ styles.form }>
				{ cashOnDelivery ? <CartPreviewPaymentCashOnDelivery field={ field } error={ error } /> : renderOptions() }
			</form>
		</div>
	)
}

export { CartPreviewPayment }
