import React, { ReactElement, useCallback, useRef } from 'react'
import classNames from 'classnames'
import { isEmpty, isNull, map } from 'lodash'

import { ISkipContentListProps, focusFirstFocusableElementInContainer } from '~/components/core/skipContent'
import { Button } from '~/components/core/button'
import { ISkipContentElement } from '~/hooks/skipElements'

import styles from './SkipContentList.module.scss'

const SkipContentList = (props: ISkipContentListProps): ReactElement => {
	const {
		skipElements,
		additionalClass = '',
		theme = {},
	} = props
	const containerRef = useRef<HTMLUListElement | null>(null)

	const handleClick = useCallback((id: string) => (): void => {
		const { current } = containerRef

		if (!isNull(current)) {
			focusFirstFocusableElementInContainer(current, id)
		}
	}, [containerRef])

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	}, theme.wrapper)

	const buttonClass = classNames(styles.navButton, theme.button)

	const renderList = useCallback((): ReactElement[] =>
		map(skipElements, (skipElement: ISkipContentElement): ReactElement => {
			const { id, text } = skipElement

			return (
				<li key={ id }>
					<Button
						additionalClass={ buttonClass }
						text={ text }
						onClick={ handleClick(id) }
					/>
				</li>
			)
		}), [skipElements, buttonClass])

	return (
		<ul ref={ containerRef } className={ wrapperClass }>
			{ renderList() }
		</ul>
	)
}

export { SkipContentList }
