import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'

import { IUserMenuSectionItem, useUserMenu } from '~/hooks/userMenu'
import { IUserMenuProps, UserMenuLogoutSection, UserMenuItem } from '~/components/core/userMenu'

import styles from './UserMenu.module.scss'

const UserMenu = (props: IUserMenuProps): ReactElement => {
	const { theme, onClose } = props
	const { getUserMenuSection } = useUserMenu()

	const wrapperClass = classNames(styles.wrapper, theme?.wrapper)

	const renderMenuItems = useCallback((): ReactElement[] => (
		map(getUserMenuSection(), (item: IUserMenuSectionItem) => {
			const { id } = item

			return (
				<UserMenuItem
					key={ id }
					theme={ {
						wrapper: theme?.itemWrapper,
						button: theme?.button,
						title: theme?.title,
					} }
					item={ item }
					onClose={ onClose }
				/>
			)
		})
	), [getUserMenuSection, theme, onClose])

	return (
		<ul className={ wrapperClass }>
			{ renderMenuItems() }

			<UserMenuLogoutSection
				theme={ {
					wrapper: theme?.logoutWrapper,
					button: theme?.logoutButton,
				} }
				onClose={ onClose }
			/>
		</ul>
	)
}

export { UserMenu }
