import React, { ReactElement, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { isEmpty, isEqual } from 'lodash'

import { ICartCustomerGuestFormAgreementsItemProps } from '~/components/cart/cartCustomer'
import { ICartCustomerGuestPrivateForm } from '~/hooks/cartGuestCustomerForm'
import { CollapseText } from '~/components/core/collapseText'
import { Checkbox } from '~/components/core/form'

import styles from './CartCustomerGuestFormAgreementsItem.module.scss'

const CartCustomerGuestFormAgreementsItem = (props: ICartCustomerGuestFormAgreementsItemProps): ReactElement => {
	const { agreement } = props
	const { control } = useFormContext<ICartCustomerGuestPrivateForm>()

	const { code, needAccept, content, additionalContent } = agreement

	const renderContent = useCallback((): ReactElement | null => {
		if (isEqual(code, 'ORDER_PROCESSING')) {
			return (
				<Checkbox
					isRequired={ needAccept }
					control={ control }
					name="regulations"
				>
					{ !isEmpty(additionalContent) ? (
						<CollapseText
							additionalClass={ styles.label }
							text={ content }
							fullText={ `${ content } ${ additionalContent }` }
						/>
					) : (
						<span className={ styles.label } dangerouslySetInnerHTML={ { __html: content } } />
					) }
				</Checkbox>
			)
		}

		return null
	}, [code])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { CartCustomerGuestFormAgreementsItem }
