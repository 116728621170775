import React, { ReactElement, useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { isNull } from 'lodash'

import { HamburgerMegaMenuContext } from '~/providers/hamburgerMegaMenuProvider'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { getMegaWorldIconName } from '~/utils/catalog'
import { DATA_TESTID } from '~/utils/dataTestId'
import { HamburgerMenuMegaMenuHeaderNav } from '~/components/core/hamburgerMenu'
import { Icon } from '~/components/core/icon'
import { VisuallyHiddenWrapper } from '~/components/core/visuallyHiddenWrapper'
import { Button } from '~/components/core/button'

import styles from './HamburgerMenuMegaMenuHeader.module.scss'

const HamburgerMenuMegaMenuHeader = (): ReactElement | null => {
	const { activeMegaWorld, activeWorld, handleResetMegaMenu } = useContext(HamburgerMegaMenuContext)
	const { handleSetOpen } = useContext(HamburgerMenuContext)
	const router = useRouter()
	const { t } = useTranslation(['header'])

	const handleGoToWorldPage = useCallback(async (): Promise<void> => {
		if (!isNull(activeWorld)) {
			handleSetOpen(false)
			handleResetMegaMenu()
			await router.push(activeWorld.wwwUrl)
		}
	}, [activeWorld])

	if (isNull(activeMegaWorld)) {
		return null
	}

	const megaWorldIcon = getMegaWorldIconName(activeMegaWorld.id)

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.CORE.HAMBURGER_MENU_MEGA_MENU_HEADER }>
			<HamburgerMenuMegaMenuHeaderNav />

			{ activeMegaWorld && (
				<div className={ styles.activeMegaWorld }>
					<VisuallyHiddenWrapper>
						<span aria-live="assertive">
							{ t('menu.megaMenu.hamburger.ariaLivePrefix', { name: activeMegaWorld.name }) }
						</span>
					</VisuallyHiddenWrapper>

					<Icon
						name={ megaWorldIcon }
						width={ 26 }
						color="gray"
						additionalClass={ styles.icon }
					/>

					<span>
						{ activeMegaWorld.name }
					</span>
				</div>
			) }

			{ activeWorld && (
				<Button
					variant="neutral"
					size="inherit"
					additionalClass={ styles.activeWorld }
					ariaLabel={ activeWorld.name }
					onClick={ handleGoToWorldPage }
				>
					<span>
						{ activeWorld.name }
					</span>

					<Icon
						name="arrow"
						width={ 7 }
						color="green"
					/>
				</Button>
			) }
		</div>
	)
}

export { HamburgerMenuMegaMenuHeader }
