import { map, toString } from 'lodash'

import { ISimpleStoreResponse } from '~/api/dataTypes/store'
import { ISelectOption } from '~/components/core/form'

export const mapStoreDataToFormOption = (stores: ISimpleStoreResponse[]): ISelectOption[] => map(stores, (store: ISimpleStoreResponse): ISelectOption => {
	const { id, name, storeCode } = store

	return {
		label: name,
		value: toString(id),
		storeCode,
	}
})
