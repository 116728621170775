import React, { ReactElement } from 'react'

import { Modal } from '~/components/core/modal'
import { WishListModalContent, IWishListModalProps, WishListModalFooter } from '~/components/core/wishListModal'

import styles from './WishListModal.module.scss'

const WishListModal = (props: IWishListModalProps): ReactElement => {
	const { isOpen, onClose } = props

	return (
		<Modal
			additionalClass={ styles.wrapper }
			isOpen={ isOpen }
			onClose={ onClose }
		>
			<WishListModalContent />

			<WishListModalFooter onClose={ onClose } />
		</Modal>
	)
}

export { WishListModal }
