import React, { ReactElement, useCallback, useState, KeyboardEvent } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import classNames from 'classnames'
import { isEmpty, isFunction, isNull, isUndefined, noop } from 'lodash'

import { Icon } from '~/components/core/icon'
import { ErrorBoundary, ICheckboxProps } from '~/components/core/form'
import { useMozaic } from '~/hooks/mozaic'

import styles from './Checkbox.module.scss'

const Checkbox = <FormFields extends FieldValues = FieldValues>(props: ICheckboxProps<FormFields>): ReactElement | null => {
	const {
		control, name, onClick,
		label = '',
		isDisabled = false,
		isRequired = false,
		additionalClass = '',
		children = null,
		dataTestId = '',
		ariaLabel = '',
		ariaLabelledBy = '',
		theme = {},
		tabIndex = 0,
		onToggleCheckbox = noop,
	} = props
	const { field, fieldState: { error } } = useController<FormFields>({ control, name })
	const [hasCheckboxFocus, setHasCheckboxFocus] = useState<boolean>(false)
	const [isFocusedByPointer, setIsFocusedByPointer] = useState<boolean>(false)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const isError = !isUndefined(error) && !isEmpty(error.message)
	const hasLabel = !isEmpty(label) || !isNull(children)

	const handleClick = useCallback(isFunction(onClick) ? onClick : noop, [onClick])

	const renderLabel = useCallback(() => {
		if (!isNull(children)) {
			return children
		} else if (!isEmpty(label)) {
			return label
		}

		return null
	}, [label, children])

	const handleKeyDownEnter = useCallback((event: KeyboardEvent<HTMLInputElement>): void => {
		const { key } = event

		if (key === 'Enter') {
			event.preventDefault()

			onToggleCheckbox(field.name, !field.value)

			if (isFunction(onClick)) {
				field.onChange(!field.value)
			}
		}
	}, [field.name, field.value, field.onChange, onClick])

	const handleFocus = useCallback((): void => {
		setHasCheckboxFocus(true)
	}, [])

	const handlePointerOver = useCallback((): void => {
		setIsFocusedByPointer(true)
	}, [])

	const handleBlur = useCallback((): void => {
		setHasCheckboxFocus(false)
		setIsFocusedByPointer(false)
	}, [])

	if (!hasLabel) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.focused]: isFocusedByPointer,
	}, theme.wrapper, additionalClass)
	const formControlClass = classNames(styles.formControl, theme.formControl)
	const iconClass = classNames(styles.icon, theme.icon)

	const checkboxClass = classNames(styles.checkbox, {
		[styles.isMozaic]: shouldUseMozaic,
		[styles.error]: isError,
		[styles.focusVisibleIcon]: hasCheckboxFocus,
	}, theme.input)

	const labelClass = classNames(styles.label, {
		[styles.required]: isRequired,
	}, theme.label)

	return (
		<div
			role="button"
			tabIndex={ -1 }
			className={ wrapperClass }
			onClick={ isDisabled ? noop : handleClick }
			onPointerOver={ handlePointerOver }
		>
			<label className={ formControlClass }>
				<input
					type="checkbox"
					checked={ field.value }
					disabled={ isDisabled }
					className={ styles.input }
					data-testid={ dataTestId }
					aria-label={ ariaLabel }
					aria-labelledby={ ariaLabelledBy }
					tabIndex={ tabIndex }
					onClick={ handleClick }
					onFocus={ handleFocus }
					onKeyDown={ handleKeyDownEnter }
					// eslint-disable-next-line react/jsx-props-no-spreading
					{ ...field }
					onBlur={ handleBlur }
				/>

				<div className={ checkboxClass }>
					<Icon
						width={ 16 }
						name="checkmark"
						color="white"
						additionalClass={ iconClass }
					/>
				</div>

				<div className={ labelClass }>
					{ renderLabel() }
				</div>
			</label>

			<ErrorBoundary error={ error } additionalClass={ theme.error } />
		</div>
	)
}

export { Checkbox }
