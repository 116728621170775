import React, { ReactElement } from 'react'

import { ISignInWrapperProps } from '~/components/signIn'

import styles from './SignInWrapper.module.scss'

const SignInWrapper = (props: ISignInWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { SignInWrapper }
