import { useEffect, useMemo, useRef } from 'react'
import { debounce, isUndefined } from 'lodash'

import { UseDebounceCallbackType, UseDebounceDataType } from './types'

export const useDebounce = (callback: UseDebounceCallbackType, ms: number = 500): UseDebounceDataType => {
	const ref = useRef(callback)

	const debouncedCallback = useMemo(() => {
		const func = (): void => {
			if (!isUndefined(ref.current)) {
				ref.current()
			}
		}

		return debounce(func, ms)
	}, [])

	useEffect(() => {
		ref.current = callback
	}, [callback])

	return debouncedCallback
}