import React, { useCallback, ReactElement } from 'react'
import axios, { AxiosError } from 'axios'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDispatch } from 'react-redux'
import { isNil, isUndefined, map } from 'lodash'

import { getHeaderMiniBoxListStyles, IHeaderTopBarFavoritesModalItemsProps } from '~/components/core/layout'
import { ProductBlockMini } from '~/components/core/productBlock'
import { getFavouriteListSimpleData, removeFromFavouriteList, transferProductToCart } from '~/actions/favouriteList'
import { getCartSimpleData } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { useLogError } from '~/hooks/logError'
import { useFormError } from '~/hooks/formError'
import { useAlert } from '~/hooks/alert'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { IFavouriteListItemData } from '~/api/dataTypes/favouriteList'

import styles from './HeaderTopBarFavoritesModalItems.module.scss'

const HeaderTopBarFavoritesModalItems = (props: IHeaderTopBarFavoritesModalItemsProps): ReactElement => {
	const { items } = props
	const dispatch: AppDispatch = useDispatch()
	const { newAlert } = useAlert()
	const { getErrorMessage } = useFormError()
	const { sendLogError } = useLogError()

	const handleRemove = useCallback((lmReference: string) => async (): Promise<void> => {
		await dispatch(removeFromFavouriteList(lmReference))
	}, [])

	const handleTransfer = useCallback((itemIdList: number) => async (): Promise<void> => {
		try {
			await dispatch(transferProductToCart({ itemIdList }))
			await dispatch(getFavouriteListSimpleData())
			await dispatch(getCartSimpleData())
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				if (!isNil(code)) {
					newAlert('danger', getErrorMessage(code), 5000)
				}
			}

			sendLogError(e)
		}
	}, [])

	const renderItems = useCallback((): ReactElement[] => (
		map(items, (item: IFavouriteListItemData, index: number) => {
			const { productSimple, itemId } = item
			const { lmReference, price, deliveryInfo: { isActive } } = productSimple

			return (
				<ProductBlockMini
					key={ lmReference }
					isFavorites
					product={ productSimple }
					price={ price.newPrice }
					dataTestId={ DATA_TESTID.HEADER.FAVORITES_ITEM(index) }
					theme={ {
						wrapper: styles.productWrapper,
						dataWrapper: styles.productDataWrapper,
						nameAndPriceWrapper: styles.productNameAndPriceWrapper,
						priceWrapper: styles.productPriceWrapper,
						priceSaleWrapper: styles.productPriceSaleWrapper,
						actionsWrapper: styles.productActionsWrapper,
						actionsTransferButton: styles.productActionsTransferButton,
						actionsRemoveButton: styles.productActionsRemoveButton,
					} }
					onRemove={ handleRemove(lmReference) }
					onTransferToCart={ isActive ? handleTransfer(itemId) : undefined }
				/>
			)
		})
	), [items])

	return (
		<Scrollbars universal style={ getHeaderMiniBoxListStyles(items.length) }>
			<div className={ styles.wrapper } data-testid={ DATA_TESTID.HEADER.FAVORITES_ITEMS }>
				{ renderItems() }
			</div>
		</Scrollbars>
	)
}

export { HeaderTopBarFavoritesModalItems }
