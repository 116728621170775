import React, { ReactElement, useCallback } from 'react'
import { replace } from 'lodash'

import { IHeaderTopBarSearchBarSuggestionProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'

import styles from './HeaderTopBarSearchBarSuggestion.module.scss'

const HeaderTopBarSearchBarSuggestion = (props: IHeaderTopBarSearchBarSuggestionProps): ReactElement => {
	const { suggestion, query } = props

	const { count, value, wwwUrl } = suggestion

	const highlightValue = useCallback((value: string): string => {
		const caseIntensive = replace(query, new RegExp('\\\\', 'gi'), '\\\\')

		return value.replace(caseIntensive, `<span class="${ styles.highlight }">${ query }</span>`)
	}, [value, query])

	return (
		<div className={ styles.wrapper }>
			<Link href={ wwwUrl } additionalClass={ styles.link }>
				<span dangerouslySetInnerHTML={ { __html: highlightValue(value) } } className={ styles.title } />

				<span className={ styles.count }>
					({ count })
				</span>
			</Link>
		</div>
	)
}

export { HeaderTopBarSearchBarSuggestion }
