import React, { ReactElement } from 'react'

import { ILayoutAccountMenuHeaderTextProps } from '~/components/core/layout'

import styles from './LayoutAccountMenuHeaderText.module.scss'

const LayoutAccountMenuHeaderText = (props: ILayoutAccountMenuHeaderTextProps): ReactElement => {
	const { label } = props

	return (
		<span className={ styles.wrapper }>
			{ label }
		</span>
	)
}

export { LayoutAccountMenuHeaderText }
