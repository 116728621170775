import React, { ReactElement } from 'react'

import { IHeaderStickyModalHeaderProps } from '~/components/core/layout'
import { CloseButton } from '~/components/core/closeButton'

import styles from './HeaderStickyModalHeader.module.scss'

const HeaderStickyModalHeader = (props: IHeaderStickyModalHeaderProps): ReactElement => {
	const { onClose } = props

	return (
		<div className={ styles.wrapper }>
			<CloseButton onClose={ onClose } />
		</div>
	)
}

export { HeaderStickyModalHeader }
