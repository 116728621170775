import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { filter, isEmpty, toString } from 'lodash'

import { usePrice } from '~/hooks/price'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { ICartPreviewProductsProductBlockInfoProps, CartPreviewProductsDeliveryBlock, CartPreviewProductsLabels } from '~/components/cart/cartPreview'
import { Link } from '~/components/core/link'
import { getProductAriaLabelWithPrice } from '~/utils/productAria'
import { ICartItemListLabel } from '~/api/dataTypes/cart'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsProductBlockInfo.module.scss'

const CartPreviewProductsProductBlockInfo = (props: ICartPreviewProductsProductBlockInfoProps): ReactElement => {
	const {
		product, priceUnit,
		labels = [],
	} = props
	const { t } = useTranslation(['common', 'header'])
	const { priceFormat } = usePrice()
	const { isDesktop } = useMediaQuery()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { name, lmReference, price: { newPrice, basePriceUnit, newPricePromoLabel, promotionalPrice }, wwwUrl, deliveryInfo } = product
	const infoLabels = isDesktop ? filter(labels, (label: ICartItemListLabel) => label.type === 'DEFAULT') : labels

	const ariaLabel = getProductAriaLabelWithPrice({
		promotional: !isEmpty(newPricePromoLabel),
		bigPrice: toString(newPrice),
		name,
		bigPriceUnit: `${ priceUnit } ${ !isEmpty(newPricePromoLabel) ? `- ${ newPricePromoLabel }` : '' }`,
		t,
	})

	const priceClass = classNames(styles.price, {
		[styles.promotion]: promotionalPrice,
	})

	const referenceClass = classNames(styles.reference, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const productNameClass = classNames(styles.title, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<Link
				href={ wwwUrl }
				additionalClass={ productNameClass }
				ariaLabel={ `${ ariaLabel }- ${ deliveryInfo.title }` }
			>
				{ name }
			</Link>

			<span className={ referenceClass }>
				{ t('refNumber', { value: lmReference }) }
			</span>

			<div>
				<span className={ priceClass }>
					<span>
						{ priceFormat(newPrice, { precision: 2 }) }
					</span>

					<span className={ styles.unit }>
						{ ' / ' }{ basePriceUnit }
					</span>
				</span>

				<div className={ styles.labels }>
					<CartPreviewProductsLabels labels={ infoLabels } />
				</div>
			</div>

			<div className={ styles.delivery }>
				<CartPreviewProductsDeliveryBlock deliveryInfo={ deliveryInfo } />
			</div>
		</div>
	)
}

export { CartPreviewProductsProductBlockInfo }
