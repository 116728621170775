import { flatMap, map, isString, isNull, isNil } from 'lodash'

import { CartStatusType, ICartItemData } from '~/api/dataTypes/cart'
import { ICatalogSearchProductItemPage, ICatalogSearchProductItemData } from '~/api/dataTypes/catalog'
import { IOrderItemData, IOrderLineData } from '~/api/dataTypes/customerOrder'
import { ICatalogPath, IProductSimple } from '~/api/dataTypes/product'
import { CART_STATUS_NAME, GoogleAnalyticsLoginType, IGoogleAnalyticsCheckoutProduct, IGoogleAnalyticsEcommerceImpression, IGoogleAnalyticsEcommerceProduct, IGoogleAnalyticsImpressionFromCatalogData, PAGE_TYPE_NAME } from '~/hooks/googleAnalytics'
import { PageType } from '~/statics'
import { removeUndefinedFields } from '~/utils/object'
import { getEmptyStringForNull } from '~/utils/string'

export const getGoogleAnalyticsCategory = (catalogPath: ICatalogPath | null): string => {
	if (!isNil(catalogPath)) {
		const { categories } = catalogPath

		return flatMap(categories, (category: string) => category ? category : []).join('/')
	}

	return ''
}

export const getGoogleAnalyticsEcommerceProductData = (product: IProductSimple, wwwUrl: string, quantity?: number): IGoogleAnalyticsEcommerceProduct => {
	const { name, lmReference, deliveryInfo, review: { countReviews, averageRating }, price: { newPrice }, brand, catalogPath } = product

	return {
		name,
		id: lmReference,
		price: newPrice,
		brand: getEmptyStringForNull(brand),
		category: getGoogleAnalyticsCategory(catalogPath),
		quantity,
		dimension2: deliveryInfo.title,
		dimension5: averageRating,
		dimension6: getEmptyStringForNull(catalogPath?.megaWorld),
		dimension7: getEmptyStringForNull(catalogPath?.world),
		dimension8: getEmptyStringForNull(catalogPath?.subWorld),
		dimension9: getEmptyStringForNull(catalogPath?.leaf),
		dimension10: countReviews,
		list: wwwUrl,
	}
}

export const getGoogleAnalyticsCheckoutProductData = (product: IProductSimple, wwwUrl: string, cartStatus: CartStatusType, quantity?: number): IGoogleAnalyticsCheckoutProduct => {
	const { name, lmReference, deliveryInfo, review: { countReviews, averageRating }, price: { newPrice }, brand, catalogPath } = product

	const params = {
		name,
		id: lmReference,
		price: newPrice,
		brand: getEmptyStringForNull(brand),
		category: getGoogleAnalyticsCategory(catalogPath),
		quantity,
		dimension2: deliveryInfo.title,
		dimension5: averageRating,
		dimension10: countReviews,
		contentGroup1: getContentGroup(catalogPath, 0),
		contentGroup2: getContentGroup(catalogPath, 1),
		contentGroup3: getContentGroup(catalogPath, 2),
		contentGroup4: getContentGroup(catalogPath, 3),
		contentGroup5: getContentGroup(catalogPath, 4),
		contentGroup6: getContentGroup(catalogPath, 5),
		contentGroup7: getContentGroup(catalogPath, 6),
		contentGroup8: getContentGroup(catalogPath, 7),
		contentType: getCheckoutContentType(cartStatus),
		list: wwwUrl,
	}

	return removeUndefinedFields(params)
}

export const getPurchaseEcommerceProductData = (orderItemData: IOrderItemData, wwwUrl: string, cartStatus: CartStatusType, quantity: number): IGoogleAnalyticsCheckoutProduct => {
	const { productName, lmReference, brand, catalogPath, averageRating, saleUnitPrice, reviewCount } = orderItemData

	const params = {
		name: productName,
		id: lmReference,
		price: saleUnitPrice,
		brand: getEmptyStringForNull(brand),
		category: getGoogleAnalyticsCategory(catalogPath),
		quantity,
		dimension2: '',
		dimension5: averageRating,
		dimension10: reviewCount,
		contentGroup1: getContentGroup(catalogPath, 0),
		contentGroup2: getContentGroup(catalogPath, 1),
		contentGroup3: getContentGroup(catalogPath, 2),
		contentGroup4: getContentGroup(catalogPath, 3),
		contentGroup5: getContentGroup(catalogPath, 4),
		contentGroup6: getContentGroup(catalogPath, 5),
		contentGroup7: getContentGroup(catalogPath, 6),
		contentGroup8: getContentGroup(catalogPath, 7),
		contentType: getCheckoutContentType(cartStatus),
		list: wwwUrl,
	}

	return removeUndefinedFields(params)
}

export const getPurchaseEcommerceProductsData = (orderLines: IOrderLineData[], wwwUrl: string, cartStatus: CartStatusType): IGoogleAnalyticsCheckoutProduct[] => (
	map(orderLines, ({ orderItemData, quantity }: IOrderLineData) => (
		getPurchaseEcommerceProductData(orderItemData, wwwUrl, cartStatus, quantity)
	))
)

export const getGoogleAnalyticsEcommerceImpressionData = (product: IProductSimple, wwwUrl: string, index: number): IGoogleAnalyticsEcommerceImpression => {
	const { name, lmReference, price: { newPrice }, brand, catalogPath } = product

	return {
		name,
		id: lmReference,
		price: newPrice,
		brand: getEmptyStringForNull(brand),
		category: getGoogleAnalyticsCategory(catalogPath),
		position: index + 1,
		list: wwwUrl,
	}
}

export const getCheckoutEcommerceProductData = (cartItemData: ICartItemData[], wwwUrl: string, cartStatus: CartStatusType): IGoogleAnalyticsCheckoutProduct[] => (
	map(cartItemData, ({ productSimple, quantityInfo: { selectedQuantity } }: ICartItemData) => (
		getGoogleAnalyticsCheckoutProductData(productSimple, wwwUrl, cartStatus, selectedQuantity)
	))
)

export const getPageTypeName = (pageType: PageType): string => {
	return isString(PAGE_TYPE_NAME[pageType]) ? PAGE_TYPE_NAME[pageType] : ''
}

export const getCheckoutContentType = (cartStatus: CartStatusType): string => {
	return isString(CART_STATUS_NAME[cartStatus]) ? CART_STATUS_NAME[cartStatus] : ''
}

export const getGoogleAnalyticsImpressionFromCatalog = (itemPage: ICatalogSearchProductItemPage, wwwUrl: string): IGoogleAnalyticsImpressionFromCatalogData => {
	const { content } = itemPage

	const products = map(content, ({ listingProduct }: ICatalogSearchProductItemData) => listingProduct).filter((product: IProductSimple | null) => !isNull(product)) as IProductSimple[]

	return {
		products,
		wwwUrl,
	}
}

export const getGoogleAnalyticsEcommerceImpressionsData = ({ products, wwwUrl }: IGoogleAnalyticsImpressionFromCatalogData): IGoogleAnalyticsEcommerceImpression[] => (
	map(products, (product: IProductSimple, index: number) => (
		getGoogleAnalyticsEcommerceImpressionData(product, wwwUrl, index)
	))
)

export const getGoogleAnalyticsLoginType = (isLogged: boolean): GoogleAnalyticsLoginType => isLogged ? 'zalogowany' : 'niezalogowany'

export const getContentGroup = (catalogPath: ICatalogPath | null, position: number): string => (!isNil(catalogPath) && !isNil(catalogPath?.categories)) ? catalogPath.categories[position] : ''
