import React, { useCallback, ReactElement } from 'react'
import { map } from 'lodash'

import { IMainMenuItem } from '~/components/core/layout'
import { HamburgerMenuItem, IHamburgerMenuItemsProps } from '~/components/core/hamburgerMenu'

import styles from './HamburgerMenuItems.module.scss'

const HamburgerMenuItems = (props: IHamburgerMenuItemsProps): ReactElement => {
	const { items } = props

	const renderItems = useCallback((): ReactElement[] => (
		map(items, (item: IMainMenuItem) => (
			<HamburgerMenuItem key={ item.id } item={ item } />
		))
	), [items])

	return (
		<ul className={ styles.wrapper }>
			{ renderItems() }
		</ul>
	)
}

export { HamburgerMenuItems }
