import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { useMozaic } from '~/hooks/mozaic'
import { ICartAsideSummaryWrapperProps } from '~/components/core/cartAside'

import styles from './CartAsideSummaryWrapper.module.scss'

const CartAsideSummaryWrapper = (props: ICartAsideSummaryWrapperProps): ReactElement => {
	const { children } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	return (
		<div className={ wrapperClass }>
			<span className={ styles.caption }>
				{ t('aside.summary.caption') }
			</span>

			{ children }
		</div>
	)
}

export { CartAsideSummaryWrapper }
