import React, { ReactElement } from 'react'

import { ICartCustomerAsideBlockProductQuantityProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerAsideBlockProductQuantity.module.scss'

const CartCustomerAsideBlockProductQuantity = (props: ICartCustomerAsideBlockProductQuantityProps): ReactElement => {
	const { quantity, priceUnit } = props

	return (
		<div className={ styles.wrapper }>
			{ quantity }{ ' ' }{ priceUnit }
		</div>
	)
}

export { CartCustomerAsideBlockProductQuantity }
