import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { isNull } from 'lodash'

import { IHamburgerMenuMegaMenuWrapperProps } from '~/components/core/hamburgerMenu'
import { HamburgerMegaMenuContext } from '~/providers/hamburgerMegaMenuProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HamburgerMenuMegaMenuWrapper.module.scss'

const HamburgerMenuMegaMenuWrapper = (props: IHamburgerMenuMegaMenuWrapperProps): ReactElement => {
	const { children } = props
	const { t } = useTranslation(['header'])
	const { activeMegaWorld } = useContext(HamburgerMegaMenuContext)

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.CORE.HAMBURGER_MENU_MEGA_MENU }>
			{ isNull(activeMegaWorld) && (
				<div className={ styles.header }>
					{ t('menu.products') }
				</div>
			) }

			{ children }
		</div>
	)
}

export { HamburgerMenuMegaMenuWrapper }
