import React, { useCallback, ReactElement } from 'react'
import { Collapse } from 'react-collapse'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { map } from 'lodash'

import { IFooterNavigationSectionItem } from '~/hooks/footerNavigation'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { DATA_TESTID } from '~/utils/dataTestId'
import { FooterSectionItem, IFooterSectionProps } from '~/components/core/layout'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'

import styles from './FooterSection.module.scss'

const FooterSection = (props: IFooterSectionProps): ReactElement => {
	const { section } = props
	const [isOpen, setIsOpen] = useToggle(false)
	const { isSmallDesktop } = useMediaQuery()

	const { name, items } = section

	const handleToggleOpen = useCallback((): void => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const renderItems = useCallback((): ReactElement => {
		const isParentOpen = isSmallDesktop ? true : isOpen

		return (
			<ul className={ styles.items } data-testid={ DATA_TESTID.FOOTER.LINKS_SECTION(name) }>
				{ map(items, (item: IFooterNavigationSectionItem): ReactElement => (
					<FooterSectionItem
						key={ item.id }
						item={ item }
						isParentOpen={ isParentOpen }
					/>
				)) }
			</ul>
		)
	}, [items, isOpen, isSmallDesktop])

	const sectionClass = classNames(styles.section, {
		[styles.sectionOpen]: isOpen,
	})

	const collapseClass = classNames('ReactCollapse--collapse', styles.collapseContent)

	return (
		<section className={ sectionClass }>
			<Button
				size="inherit"
				variant="neutral"
				isAriaExpanded={ isOpen }
				isAriaHidden={ isSmallDesktop }
				additionalClass={ styles.headerWrapper }
				data-testid={ DATA_TESTID.FOOTER.BUTTON_TOGGLE_SECTION(name) }
				ariaLabel={ name }
				tabIndex={ isSmallDesktop ? -1 : 0 }
				onClick={ handleToggleOpen }
			>
				<div className={ styles.header }>
					{ name }
				</div>

				<Icon
					name="arrowFull"
					color="green"
					width={ 4 }
					height={ 8 }
					additionalClass={ styles.icon }
				/>
			</Button>

			{ isSmallDesktop ? (
				renderItems()
			) : (
				<Collapse isOpened={ isOpen } theme={ { collapse: collapseClass } }>
					{ renderItems() }
				</Collapse>
			) }
		</section>
	)
}

export { FooterSection }
