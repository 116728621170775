import React, { ReactElement } from 'react'

import { CartPreviewProductsProductBlockImage, CartPreviewProductsProductBlockInfo } from '~/components/cart/cartPreview'
import { ICartPreviewCheckPriceProductProps } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPriceProduct/types'
import { CartPreviewCheckPriceQuantity } from '~/components/cart/cartPreview/cartPreviewCheckPrice'
import { CartPreviewCheckPriceTotal } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPriceTotal'

import styles from './CartPreviewCheckPriceProduct.module.scss'

const CartPreviewCheckPriceProduct = (props: ICartPreviewCheckPriceProductProps): ReactElement => {
	const { item } = props

	const { productSimple, priceInfo, labels } = item
	const { price: { basePriceUnit } } = productSimple
	const { saleUnitQuantity, value, saleUnitName } = priceInfo

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.container }>
				<CartPreviewProductsProductBlockImage product={ productSimple } />

				<CartPreviewProductsProductBlockInfo
					product={ productSimple }
					priceUnit={ basePriceUnit }
					labels={ labels }
				/>
			</div>

			<div className={ styles.mobileContainer }>
				<CartPreviewCheckPriceQuantity quantity={ saleUnitQuantity } unit={ saleUnitName } />

				<CartPreviewCheckPriceTotal summaryPrice={ value } />
			</div>
		</div>
	)
}

export { CartPreviewCheckPriceProduct }
