import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { IProductBlockWrapperProps } from '~/components/core/productBlock'

import styles from './ProductBlockWrapper.module.scss'

const ProductBlockWrapper = (props: IProductBlockWrapperProps): ReactElement => {
	const {
		children, lmReference,
		additionalClass = '',
	} = props

	const wrapperClass = classNames(styles.wrapper, additionalClass, 'product')

	return (
		<div className={ wrapperClass } data-lm-reference={ lmReference }>
			{ children }
		</div>
	)
}

export { ProductBlockWrapper }
