import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { useMozaic } from '~/hooks/mozaic'

import { ILabelProps } from './types'

import styles from './Label.module.scss'

const Label = (props: ILabelProps): ReactElement => {
	const {
		name, caption, isRequired,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['form'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const labelClass = classNames(styles.label, {
		[styles.required]: isRequired,
		[additionalClass]: additionalClass,
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<label htmlFor={ name } className={ labelClass }>
				{ caption }

				{ shouldUseMozaic && isRequired && (
					<span className={ styles.postfix }>
						{ t('labelRequired') }
					</span>
				) }
			</label>
		</div>
	)
}

export { Label }
