import React, { ReactElement, useCallback } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { map } from 'lodash'

import { CartCustomerAsideBlockProduct, ICartCustomerAsideBlockProductsProps } from '~/components/cart/cartCustomer'
import { ICartItemData } from '~/api/dataTypes/cart'

import styles from './CartCustomerAsideBlockProducts.module.scss'

const CartCustomerAsideBlockProducts = (props: ICartCustomerAsideBlockProductsProps): ReactElement | null => {
	const { cartItems } = props

	const renderProducts = useCallback((): ReactElement[] => (
		map(cartItems, (cartItem: ICartItemData): ReactElement => {
			const { itemId } = cartItem

			return (
				<CartCustomerAsideBlockProduct key={ itemId } cartItem={ cartItem } />
			)
		})
	), [cartItems])

	return (
		<div className={ styles.wrapper }>
			<Scrollbars
				universal
				autoHeight
				autoHeightMin={ 100 }
				className={ styles.scrollbar }
			>
				{ renderProducts() }
			</Scrollbars>
		</div>
	)
}

export { CartCustomerAsideBlockProducts }
