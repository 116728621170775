import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './CartPreviewDeliveryPickupTransferTopBarPrice.module.scss'

const CartPreviewDeliveryPickupTransferTopBarPrice = (): ReactElement => {
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			<span>
				{ t('preview.delivery.groups.storeDelivery.charge') }
			</span>

			<span className={ styles.highlight }>
				{ t('preview.delivery.groups.storeDelivery.dependsOnChoice') }
			</span>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTopBarPrice }
