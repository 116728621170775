import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './HamburgerMenuUserAccountHeaderTitle.module.scss'

const HamburgerMenuUserAccountHeaderTitle = (): ReactElement => {
	const { t } = useTranslation(['common'])

	return (
		<div className={ styles.wrapper }>
			<span>
				{ t('yourAccount') }
			</span>
		</div>
	)
}

export { HamburgerMenuUserAccountHeaderTitle }
