import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { ICartPreviewDiscountLysNoCouponsProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons/cartPreviewDiscountLysNoCoupons'
import { Icon } from '~/components/core/icon'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { getCartPreviewData, getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'

import styles from './CartPreviewDiscountLysNoCoupons.module.scss'

const CartPreviewDiscountLysNoCoupons = (props: ICartPreviewDiscountLysNoCouponsProps): ReactElement | null => {
	const { currentRewards } = props
	const { cart: { promotionCodeUsed: isPromotionCodeUsed } } = useSelector(getCartPreviewData, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const { isLysMigrated } = useContext(LysLoyaltyProviderContext)
	const { t } = useTranslation(['cart'])

	const hasPromoCode = isPromotionCodeUsed && isEcommerce
	const shouldRenderDescription = isLysMigrated && isEmpty(currentRewards)

	if (hasPromoCode || !shouldRenderDescription) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.iconWrapper }>
				<Icon
					name="infoCircle"
					width={ 24 }
					height={ 24 }
					color="black65"
				/>
			</div>

			<span className={ styles.description }>
				{ t('preview.aside.lysDiscount.legend.noCoupons') }
			</span>
		</div>
	)
}

export { CartPreviewDiscountLysNoCoupons }
