import React, { ReactElement } from 'react'

import { HeaderMainMenu, HeaderMenuWrapper, IHeaderMenuProps } from '~/components/core/layout'

const HeaderMenu = (props: IHeaderMenuProps): ReactElement => {
	const { pageType } = props

	return (
		<HeaderMenuWrapper>
			<HeaderMainMenu pageType={ pageType } />
		</HeaderMenuWrapper>
	)
}

export { HeaderMenu }
