import { AxiosResponse } from 'axios'

import { ICustomerResult, CustomerDataRequestUnionType, ICustomerAddressRequest, ICustomerChangePasswordDataRequest } from '~/api/dataTypes/customer'
import { request } from '~/api/requests/axios'

export const getCustomer = async (): Promise<AxiosResponse<ICustomerResult>> => (
	await request.get('/v1/customers', {
		service: 'CUSTOMER_SERVICE',
	})
)

export const patchCustomer = async (data: Partial<CustomerDataRequestUnionType>): Promise<AxiosResponse> => (
	await request.patch('/v1/customers', data, {
		service: 'CUSTOMER_SERVICE',
	})
)

export const deleteCustomerAddress = async (addressId: number): Promise<AxiosResponse> => (
	await request.delete(`/v1/customers/addresses/${ addressId }`, {
		service: 'CUSTOMER_SERVICE',
	})
)

// TODO: PLEDEV-4420 - change null to response object
export const postCustomerAddress = async (params: ICustomerAddressRequest): Promise<AxiosResponse> => (
	await request.post('/v1/customers/addresses', params, {
		service: 'CUSTOMER_SERVICE',
	})
)
// TODO: PLEDEV-4420 - change null to response object
export const patchCustomerAddress = async (addressId: number, params: Partial<ICustomerAddressRequest>): Promise<AxiosResponse> => (
	await request.patch(`/v1/customers/addresses/${ addressId }`, params, {
		service: 'CUSTOMER_SERVICE',
	})
)

export const changeCustomerPassword = async (data: ICustomerChangePasswordDataRequest): Promise<AxiosResponse<ICustomerResult>> => (
	await request.post('/v1/customers/password-change', data, {
		validateStatus: (status: number): boolean => {
			return status === 204
		},
		service: 'CUSTOMER_SERVICE',
	})
)

export const deleteCustomerAccount = async (): Promise<AxiosResponse> => (
	await request.delete('/v1/customers', {
		service: 'CUSTOMER_SERVICE',
	})
)
