import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Link } from '~/components/core/link'
import { Icon } from '~/components/core/icon'
import { urls as staticUrls } from '~/utils/urls'
import { useMozaic } from '~/hooks/mozaic'

import styles from './LayoutAccountKobiLink.module.scss'

const LayoutAccountKobiLink = (): ReactElement => {
	const { t } = useTranslation(['account'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<Link
			isNativeLink
			href={ staticUrls.customerSpaceUrl() }
			additionalClass={ wrapperClass }
		>
			<Icon
				additionalClass={ styles.icon }
				name="arrowFull"
				width={ 5 }
			/>

			<span>
				{ t('navigation.backToCustomerSpace') }
			</span>
		</Link>
	)
}

export { LayoutAccountKobiLink }
