import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'

import { DATA_TESTID } from '~/utils/dataTestId'
import { IHeaderTopBarMiniCartButtonProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { Badge } from '~/components/core/badge'
import { Icon } from '~/components/core/icon'

import styles from './HeaderTopBarMiniCartButton.module.scss'

const HeaderTopBarMiniCartButton = (props: IHeaderTopBarMiniCartButtonProps): ReactElement => {
	const { isActive, onOpen, onBlur, buttonRef, numberOfItems, isDisabled } = props
	const { t } = useTranslation(['header'])
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')

	const handleOpen = useCallback((): void => {
		onOpen()
	}, [])

	const handleBlur = useCallback((): void => {
		if (isShiftTabPressed) {
			onBlur()
		}
	}, [isShiftTabPressed])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.active]: isActive,
	})

	return (
		<Button
			hasAriaPopup
			isAriaExpanded={ isActive }
			buttonRef={ buttonRef }
			size="inherit"
			variant="neutral"
			additionalClass={ wrapperClass }
			ariaLabel={ t('topBar.miniCart.caption') }
			dataTestId={ DATA_TESTID.HEADER.BUTTON_OPEN_MINI_CART }
			isDisabled={ isDisabled }
			onClick={ handleOpen }
			onBlur={ handleBlur }
		>
			<span className={ styles.iconWrapper } data-testid={ DATA_TESTID.HEADER.MINI_CART_COUNTER }>
				<Icon
					name="cart"
					color="navy"
					width={ 24 }
					height={ 24 }
					additionalClass={ styles.icon }
				/>

				<Badge value={ numberOfItems } />
			</span>
		</Button>
	)
}

export { HeaderTopBarMiniCartButton }
