import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ITooltipContentProps } from '~/components/core/tooltip'

import styles from './TooltipContent.module.scss'

const TooltipContent = (props: ITooltipContentProps): ReactElement | null => {
	const {
		onSetRef, content, visible, onGetTooltipProps, onGetArrowProps, CustomContentComponent,
		theme = {},
	} = props

	const wrapperClass = classNames({
		'tooltip-container': true,
		[styles.wrapper]: true,
	})

	const arrowClass = classNames({
		'tooltip-arrow': true,
		[styles.arrow]: true,
	})

	if (!visible) {
		return null
	}

	return (
		<div
			ref={ onSetRef }
			role="tooltip"
			className={ theme.wrapper }
			// eslint-disable-next-line react/jsx-props-no-spreading
			{ ...onGetTooltipProps({ className: wrapperClass }) }
		>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<div { ...onGetArrowProps({ className: arrowClass }) } />

			{ content }

			{ CustomContentComponent }
		</div>
	)
}

export { TooltipContent }
