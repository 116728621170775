import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { useGoToSignIn } from '~/hooks/goToSignIn'

import styles from './CartCustomerGuestFormLoginSuggest.module.scss'

const CartCustomerGuestFormLoginSuggest = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { onGoToSignInPage } = useGoToSignIn()

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.icon }>
				<Icon
					name="infoCircle"
					width={ 25 }
					color="black65"
				/>
			</div>

			<div className={ styles.text }>
				<div className={ styles.caption }>
					{ t('customer.guest.loginSuggestion.caption') }
				</div>

				<div className={ styles.description }>
					<Button
						variant="neutral"
						size="inherit"
						additionalClass={ styles.buttonLink }
						onClick={ onGoToSignInPage }
					>
						{ t('customer.guest.loginSuggestion.logIn') }
					</Button>

					<span className={ styles.info }>
						{ t('customer.guest.loginSuggestion.description') }
					</span>
				</div>
			</div>
		</div>
	)
}

export { CartCustomerGuestFormLoginSuggest }
