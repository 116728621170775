import React, { createContext, useCallback, useMemo, ReactElement } from 'react'
import { useToggle } from 'react-use'
import { noop } from 'lodash'

import { IMobileSearchBarProviderProps, IMobileSearchBarContextProps } from '~/providers/mobileSearchBarProvider'

const initialProps: IMobileSearchBarContextProps = {
	visible: true,
	handleToggleVisible: noop,
}

export const MobileSearchBarContext = createContext(initialProps)

export const MobileSearchBarProvider = (props: IMobileSearchBarProviderProps): ReactElement => {
	const { children } = props
	const [isVisible, setIsVisible] = useToggle(initialProps.visible)

	const handleToggleVisible = useCallback((): void => {
		setIsVisible(!isVisible)
	}, [isVisible])

	const providerValue: IMobileSearchBarContextProps = useMemo(() => ({
		visible: isVisible,
		handleToggleVisible,
	}), [isVisible])

	return (
		<MobileSearchBarContext.Provider value={ providerValue }>
			{ children }
		</MobileSearchBarContext.Provider>
	)
}
