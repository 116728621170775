import React, { ReactElement, useMemo } from 'react'
import classNames from 'classnames'
import { useController, FieldValues } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { isNil, join } from 'lodash'

import { IFilesPickerProps, Label, ErrorBoundary, FilesList } from '~/components/core/form'

import styles from './FilesPicker.module.scss'

const FilesPicker = <FormFields extends FieldValues = FieldValues>(props: IFilesPickerProps<FormFields>): ReactElement => {
	const {
		name, label, onChange, files, remove, control, text, extensions,
		maxSize = 5,
		theme = {},
		variant = 'primary',
		reverse = false,
	} = props
	const { fieldState } = useController({ control, name })
	const { t } = useTranslation(['form'])

	const { error } = fieldState
	const acceptExtensions = useMemo((): string => join(extensions, ', '), [extensions])
	const wrapperClass = classNames(styles.wrapper, theme.wrapper)
	const inputClass = classNames({
		[styles.filesPicker]: true,
		[styles[variant]]: variant,
		[styles.reverse]: reverse,
	}, theme.input)

	return (
		<div className={ wrapperClass }>
			<Label name={ name } caption={ label || t('files.label') } />

			<label className={ inputClass }>
				<input
					multiple
					id={ name }
					type="file"
					className={ styles.input }
					accept={ acceptExtensions }
					onChange={ onChange }
				/>

				<span className={ styles.text }>{ text || t('files.selectFile') }</span>
			</label>

			<ErrorBoundary error={ error } />

			<span className={ styles.description }>
				{ `${ t('files.maxSize') }: ${ maxSize }MB` }
			</span>

			{ !isNil(files) && (
				<FilesList remove={ remove } files={ files } />
			) }
		</div>
	)
}

export { FilesPicker }
