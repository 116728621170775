import { Draft, produce } from 'immer'

import { AppPromiseAction } from '~/actions'
import { ProductCardActionType, ProductCardActionUnionType } from '~/actions/productCard'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'

import { productCardAddOpinionInitialState as initialState } from './constants'
import { ProductCardAddOpinionState } from './types'

export const productCardAddOpinion = produce((draft: Draft<ProductCardAddOpinionState>, action: AppPromiseAction<ProductCardActionUnionType>): ProductCardAddOpinionState => {
	switch (action.type) {
		case REQUEST(ProductCardActionType.ADD_REVIEW): {
			draft.isPending = true
			draft.isSuccess = false
			draft.isFailed = false

			return draft
		}

		case SUCCESS(ProductCardActionType.ADD_REVIEW): {
			draft.isPending = false
			draft.isSuccess = true

			return draft
		}

		case FAILURE(ProductCardActionType.ADD_REVIEW): {
			draft.isPending = false
			draft.isFailed = true

			return draft
		}

		case SUCCESS(ProductCardActionType.CLEAN_UP): {
			return initialState
		}

		default:
			return draft
	}
}, initialState)
