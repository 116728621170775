import React, { ReactElement, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'

import { AppDispatch } from '~/state/store'
import { getCustomerData, getCustomerLastViewedProducts } from '~/actions/customer'
import { getCustomerPreferredStore } from '~/actions/preferredStore'
import { getCartSimpleData, setCartStepZeroData } from '~/actions/cart'
import { getFavouriteListSimpleData } from '~/actions/favouriteList'
import { Main, ILayoutProps, Header, Footer } from '~/components/core/layout'
import { HamburgerMenu } from '~/components/core/hamburgerMenu'
import { Fallback } from '~/components/core/fallback'
import { ReactErrorBoundary, ERROR_BOUNDARY_KEYS } from '~/components/core/reactErrorBoundary'
import { Auth } from '~/components/core/auth'
import { HeaderProvider } from '~/providers/headerProvider'
import { HamburgerMenuProvider } from '~/providers/hamburgerMenuProvider'
import { AlertProvider } from '~/providers/alertProvider'
import { HamburgerMegaMenuProvider } from '~/providers/hamburgerMegaMenuProvider'
import { AddToCartModalProvider } from '~/providers/addToCartModalProvider'
import { MobileSearchBarProvider } from '~/providers/mobileSearchBarProvider'
import { UserContext } from '~/providers/userProvider'
import { isPageAuthorized } from '~/utils/authorizedPage'
import { useLogError } from '~/hooks/logError'
import { AddToCartModal } from '~/components/core/addToCartModal'
import { initLastViewedProducts } from '~/actions/lastViewed'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { KobiFavicon } from '~/components/kobi/kobiFavicon'
import { localStorageSet } from '~/utils/storage'
import { LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY } from '~/components/cart/cartCustomer'

/* eslint-disable react/jsx-max-depth */
const Layout = (props: ILayoutProps): ReactElement => {
	const {
		children, pageType,
		AdditionalCustomFooter = null,
		hideNewsletter = false,
	} = props
	const { isLogged } = useContext(UserContext)
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)
	const dispatch: AppDispatch = useDispatch()
	const router = useRouter()
	const { sendLogError } = useLogError()

	const isUnauthorized = !isLogged && isPageAuthorized(pageType)
	const isShopVersion = router.query?.isShopVersion === 'true'

	const renderHeader = useCallback((): ReactElement | null => {
		if (isKobiIntegrationEnabled || isShopVersion) {
			return null
		}

		return (
			<>
				<Header pageType={ pageType } />

				<HamburgerMegaMenuProvider pageType={ pageType }>
					<HamburgerMenu />
				</HamburgerMegaMenuProvider>
			</>
		)
	}, [isShopVersion, pageType])

	const renderFooter = useCallback((): ReactElement | null => {
		if (isKobiIntegrationEnabled || isShopVersion) {
			return null
		}

		return (
			<Footer AdditionalCustomFooter={ AdditionalCustomFooter } hideNewsletter={ hideNewsletter } />
		)
	}, [AdditionalCustomFooter, hideNewsletter, isShopVersion])

	useEffectOnce(() => {
		(async () => {
			localStorageSet(LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY, 'true')

			if (isLogged && !isKobiIntegrationEnabled) {
				await dispatch(getCustomerData())
				await dispatch(getFavouriteListSimpleData())
				await dispatch(getCustomerLastViewedProducts())

				try {
					await dispatch(getCustomerPreferredStore())
				} catch (e: unknown) {
					sendLogError(e)
				}

				await dispatch(setCartStepZeroData(!isLogged))
			} else {
				await dispatch(initLastViewedProducts())
			}

			await dispatch(getCartSimpleData())
		})()
	})

	return (
		<>
			<KobiFavicon />

			<HeaderProvider>
				<AlertProvider>
					<HamburgerMenuProvider>
						<MobileSearchBarProvider>
							<AddToCartModalProvider>
								{ renderHeader() }

								<Main pageType={ pageType }>
									<ReactErrorBoundary
										key={ ERROR_BOUNDARY_KEYS.LAYOUT }
										url={ router.asPath }
										Fallback={ <Fallback /> }
									>
										<Auth isUnauthorized={ isUnauthorized }>
											{ children }
										</Auth>
									</ReactErrorBoundary>
								</Main>

								{ renderFooter() }

								<AddToCartModal />
							</AddToCartModalProvider>
						</MobileSearchBarProvider>
					</HamburgerMenuProvider>
				</AlertProvider>
			</HeaderProvider>
		</>
	)
}

export { Layout }
