import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { useModal } from '~/hooks/modal'
import { PreferredStoreModal } from '~/components/core/preferredStore'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HamburgerMenuUserStoreEmpty.module.scss'

const HamburgerMenuUserStoreEmpty = (): ReactElement => {
	const { t } = useTranslation(['common'])
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')

	return (
		<>
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.button }
				dataTestId={ DATA_TESTID.CORE.BUTTON_OPEN_PREFERRED_STORE_BLOCK }
				ariaLabel={ t('preferredShop.chooseYourStore') }
				onClick={ handleOpen }
			>
				<span className={ styles.name }>
					<Icon
						name="pin"
						color="navy"
						width={ 15 }
						additionalClass={ styles.icon }
					/>

					<span>{ t('preferredShop.chooseYourStore') }</span>
				</span>

				<Icon name="arrowFull" width={ 4 } />
			</Button>

			<PreferredStoreModal isOpen={ isOpen } onClose={ handleClose } />
		</>
	)
}

export { HamburgerMenuUserStoreEmpty }
