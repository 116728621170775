import { AnyAction } from 'redux'
import produce, { Draft } from 'immer'

import { ISimpleStoreResponse } from '~/api/dataTypes/store'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { PreferredStoreActionType } from '~/actions/preferredStore'

import { IPreferredStoresListState } from './types'
import { preferredStoreStoresListInitialState as initialState } from './constants'

export const preferredStoresList = produce((draft: Draft<IPreferredStoresListState>, action: AnyAction) => {
	switch (action.type) {
		case REQUEST(PreferredStoreActionType.GET_PREFERRED_STORES_LIST):
			draft.isPending = true

			return draft
		case SUCCESS(PreferredStoreActionType.GET_PREFERRED_STORES_LIST):
			draft.items = (action.payload.data as ISimpleStoreResponse[])
			draft.isPending = false

			return draft
		case FAILURE(PreferredStoreActionType.GET_PREFERRED_STORES_LIST):
			draft.isPending = false

			return draft
		default:
			return draft
	}
}, initialState)
