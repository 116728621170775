import React, { useCallback, ReactElement } from 'react'
import { map } from 'lodash'

import { IFooterNavigationSection } from '~/hooks/footerNavigation'
import { DATA_TESTID } from '~/utils/dataTestId'
import { FooterSection, IFooterLinksProps } from '~/components/core/layout'

import styles from './FooterLinks.module.scss'

const FooterLinks = (props: IFooterLinksProps): ReactElement => {
	const { sections } = props

	const renderSections = useCallback((): ReactElement[] => (
		map(sections, (section: IFooterNavigationSection): ReactElement => (
			<FooterSection key={ section.id } section={ section } />
		))
	), [sections])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.sections } data-testid={ DATA_TESTID.FOOTER.LINKS_SECTIONS }>
				{ renderSections() }
			</div>
		</div>
	)
}

export { FooterLinks }
