import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { Tooltip } from '~/components/core/tooltip'
import { Button } from '~/components/core/button'
import { ICartCustomerAsideBlockActionsProps } from '~/components/cart/cartCustomer'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerAsideBlockActions.module.scss'

const CartCustomerAsideBlockActions = (props: ICartCustomerAsideBlockActionsProps): ReactElement => {
	const { onBack, onNext, isNextButtonDisabled, nextButtonDisabledInfo, isNextLoading } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	return (
		<div className={ wrapperClass }>
			<Tooltip
				placement="top"
				content={ nextButtonDisabledInfo }
				isActive={ isNextButtonDisabled }
				theme={ { wrapper: styles.tooltip } }
				CustomTriggerComponent={
					<Button
						additionalClass={ styles.button }
						isDisabled={ isNextButtonDisabled }
						text={ t('customer.aside.summary.button.next') }
						isLoading={ isNextLoading }
						type="submit"
						onClick={ onNext }
					/>
				}
			/>

			<Button
				text={ t('customer.aside.summary.button.back') }
				size="inherit"
				variant="neutral"
				additionalClass={ styles.link }
				onClick={ onBack }
			/>
		</div>
	)
}

export { CartCustomerAsideBlockActions }
