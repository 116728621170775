import React, { ReactElement } from 'react'

import { IFooterSectionsWrapperProps } from '~/components/core/layout'

import styles from './FooterSectionsWrapper.module.scss'

const FooterSectionsWrapper = (props: IFooterSectionsWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { FooterSectionsWrapper }
