import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { ICartPreviewSummaryDiscountLoyaltyProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLoyalty'
import { Icon } from '~/components/core/icon'

import styles from './CartPreviewSummaryDiscountLoyalty.module.scss'

const CartPreviewSummaryDiscountLoyalty = (props: ICartPreviewSummaryDiscountLoyaltyProps): ReactElement | null => {
	const {
		hasLoyaltyCard,
		theme = {},
	} = props
	const { t } = useTranslation(['cart'])

	if (!hasLoyaltyCard) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<Icon
				name="checkCircle"
				width={ 16 }
				additionalClass={ theme.icon }
			/>

			<span className={ styles.message } dangerouslySetInnerHTML={ { __html: t('preview.aside.discount.successMessages.domProgram') } } />

			<span className={ styles.additionalMessage } dangerouslySetInnerHTML={ { __html: t('preview.aside.discount.additionalInfoMessages.domProgram') } } />
		</div>
	)
}

export { CartPreviewSummaryDiscountLoyalty }
