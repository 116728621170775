import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { ServiceCartConfirmationActionType, ServiceCartConfirmationType } from '~/actions/serviceCartConfirmation'

import { IServiceCartConfirmationState } from './types'
import { serviceCartConfirmationInitialState as initialState } from './constants'

export const serviceCartConfirmation = produce((draft: Draft<IServiceCartConfirmationState>, action: AppPromiseAction<ServiceCartConfirmationActionType>) => {
	switch (action.type) {
		case REQUEST(ServiceCartConfirmationType.GET_SERVICE_CART_CONFIRMATION_DATA):
			draft.isPending = true

			return draft
		case FAILURE(ServiceCartConfirmationType.GET_SERVICE_CART_CONFIRMATION_DATA):
			draft.isFailure = true
			draft.isPending = false

			return draft
		case SUCCESS(ServiceCartConfirmationType.GET_SERVICE_CART_CONFIRMATION_DATA):
			draft.data = action.payload.data
			draft.isFailure = false
			draft.isPending = false

			return draft
		case SUCCESS(ServiceCartConfirmationType.SET_SERVICE_CART_CONFIRMATION_ORDER_FRONT_ID):
			draft.orderFrontId = action.payload

			return draft
	}
}, initialState)
