import { createSelector } from 'reselect'
import { get } from 'lodash'

import { IStoreResponse } from '~/api/dataTypes/store'
import { IRootState } from '~/state/types'

import { ProductCardStoresState } from './types'

export const getProductCardStores = (state: IRootState): ProductCardStoresState => state.productCard.stores

export const getProductCardStoreById = createSelector(
	getProductCardStores,
	(_state: IRootState, id: number) => id,
	(stores: ProductCardStoresState, id: number): IStoreResponse | undefined => get(stores, id),
)
