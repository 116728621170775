import React, { ReactElement, useCallback, useState } from 'react'
import classNames from 'classnames'
import { isEmpty, isFunction } from 'lodash'

import { Button } from '~/components/core/button'
import { Icon, ICON_TYPE } from '~/components/core/icon'
import { IAlertProps } from '~/components/core/notifications'
import { useMozaic } from '~/hooks/mozaic'

import styles from './Alert.module.scss'

const Alert = (props: IAlertProps): ReactElement | null => {
	const {
		type, icon, children,
		shouldRender = true,
		onClose = undefined,
		canClose = true,
		additionalClass = '',
	} = props
	const [opacity, setOpacity] = useState<boolean>(true)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const iconWidth = shouldUseMozaic ? 26 : 17
	const iconHeight = shouldUseMozaic ? 26 : undefined

	const renderIconName = useCallback((): keyof typeof ICON_TYPE => {
		if (!shouldUseMozaic) {
			return icon
		}

		switch (icon) {
			case 'warning':
				return 'mozaicNotificationCircleInformation'
			default:
				return icon
		}
	}, [icon, shouldUseMozaic])

	const handleClose = useCallback((): void => {
		if (isFunction(onClose)) {
			setOpacity(false)
			setTimeout(onClose, 500)
		}
	}, [onClose])

	const alertClass = classNames({
		[styles.alert]: true,
		[styles.opacity]: opacity,
		[styles[type]]: type,
		[styles.isMozaic]: shouldUseMozaic,
		[additionalClass]: !isEmpty(additionalClass),
	})

	const renderCloseButton = (): ReactElement | null => {
		if (!canClose) {
			return null
		}

		return (
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.button }
				onClick={ handleClose }
				// TODO: add aria-label
			>
				<Icon
					name="close"
					width={ 12 }
					additionalClass={ styles.icon }
				/>
			</Button>
		)
	}

	return shouldRender ? (
		<div className={ styles.wrapper }>
			<div
				className={ alertClass }
				aria-relevant="all"
				aria-live="polite"
			>
				<Icon
					name={ renderIconName() }
					width={ iconWidth }
					height={ iconHeight }
					additionalClass={ styles.icon }
				/>

				<div className={ styles.content }>{ children }</div>

				{ renderCloseButton() }
			</div>
		</div>
	) : null
}

export { Alert }
