import React, { ReactElement, useCallback, useRef } from 'react'
import { useClickAway } from 'react-use'
import classNames from 'classnames'

import { IMegaMenuWrapperProps, WORLDS_WRAPPER_ID } from '~/components/core/megamenu'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './MegaMenuWrapper.module.scss'

const MegaMenuWrapper = (props: IMegaMenuWrapperProps): ReactElement => {
	const { buttonRef, isVisible, onClose, children } = props
	const megaMenuRef = useRef<HTMLElement | null>(null)

	const handleClose = useCallback((event: Event) => {
		const clickedNavButton = buttonRef.current?.contains(event.target as Element)

		if (!clickedNavButton) {
			onClose()
		}
	}, [onClose])

	useClickAway(megaMenuRef, handleClose)

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.visible]: isVisible,
	})

	return (
		<section
			ref={ megaMenuRef }
			id={ WORLDS_WRAPPER_ID }
			className={ wrapperClass }
			data-testid={ DATA_TESTID.CORE.MEGA_MENU }
		>
			{ children }
		</section>
	)
}

export { MegaMenuWrapper }
