/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { IPreferredStoreInfoNavigationProps } from '~/components/core/preferredStore'
import { ArrowLink } from '~/components/core/arrowLink'
import { Link } from '~/components/core/link'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './PreferredStoreInfoNavigation.module.scss'

const PreferredStoreInfoNavigation = (props: IPreferredStoreInfoNavigationProps): ReactElement => {
	const { onNavigate, url } = props
	const { t } = useTranslation(['stores'])
	const { urls } = useContext(AppParametersContext)

	return (
		<div className={ styles.wrapper }>
			<Link
				href={ urls.stores }
				additionalClass={ styles.link }
				dataTestId={ DATA_TESTID.CORE.BUTTON_GO_TO_STORES }
				onClick={ onNavigate }
			>
				<ArrowLink text={ t('stationaryStores') } additionalClass={ styles.label } />
			</Link>

			<Link
				href={ url }
				additionalClass={ styles.link }
				dataTestId={ DATA_TESTID.CORE.BUTTON_GO_TO_STORE_CARD }
				onClick={ onNavigate }
			>
				<ArrowLink text={ t('goToStorePage') } additionalClass={ styles.label } />
			</Link>
		</div>
	)
}

export { PreferredStoreInfoNavigation }
