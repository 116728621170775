import { isEmpty, isEqual, isNull } from 'lodash'

import { CONTAINER_SELECTOR_FOOTER, CONTAINER_SELECTOR_SECTION } from '~/components/core/skipContent'
import { ALL_FOCUSABLE_SELECTOR_QUERY, SKIP_ADDITIONAL_FOOTER, SKIP_FOOTER } from '~/hooks/skipElements'

export const focusFirstFocusableElementInContainer = (container: HTMLUListElement, id: string): void => {
	const focusableElement = getFocusableElementByIdOrFromRef(container, id)

	if (!isNull(focusableElement)) {
		focusableElement?.focus()
	}
}

const getFocusableElementByIdOrFromRef = (container: HTMLUListElement, id: string): HTMLElement | null => {
	if (!isEmpty(id)) {
		return document.getElementById(id)
	}

	return findFirstNextFocusableElement(container)
}

const findFirstNextFocusableElement = (container: HTMLUListElement): HTMLElement | null => {
	const parentElement = getLastFocusableClosestParentElement(container)

	if (isNull(parentElement)) {
		return null
	}

	if (isEqual(parentElement.parentElement?.lastElementChild, parentElement)) {
		return document.getElementById(SKIP_ADDITIONAL_FOOTER)
	}

	const focusableElement = getNextFocusableElement(parentElement)

	return focusableElement
}

const getLastFocusableClosestParentElement = (container: HTMLUListElement): HTMLElement | null => {
	if (isEqual(document.activeElement?.id, SKIP_FOOTER)) {
		return container.closest(CONTAINER_SELECTOR_FOOTER)
	}

	return container.closest(CONTAINER_SELECTOR_SECTION)
}

const getNextFocusableElement = (parentElement: HTMLElement): HTMLElement | null => {
	let nextElement = parentElement.nextElementSibling

	while (!isNull(nextElement)) {
		const focusableInNext = nextElement.querySelector(ALL_FOCUSABLE_SELECTOR_QUERY) as HTMLElement

		if (!isNull(focusableInNext)) {
			return focusableInNext
		}

		nextElement = nextElement.nextElementSibling as HTMLElement
	}

	return null
}
