import { AnyAction } from 'redux'
import produce, { Draft } from 'immer'

import { IStoreResponse } from '~/api/dataTypes/store'
import { REQUEST, SUCCESS, FAILURE } from '~/statics'
import { StoresListActionType } from '~/actions/stores'

import { StoresMapInfoWindowState } from './types'
import { storesMapInfoWindowInitialState as initialState } from './constants'

export const storesMapInfoWindow = produce((draft: Draft<StoresMapInfoWindowState>, action: AnyAction): StoresMapInfoWindowState => {
	switch (action.type) {
		case REQUEST(StoresListActionType.GET_STORE_MAP_INFO_WINDOW): {
			draft.isPending = true

			return draft
		}

		case SUCCESS(StoresListActionType.GET_STORE_MAP_INFO_WINDOW): {
			draft.isPending = false
			draft.store = (action.payload.data as IStoreResponse)

			return draft
		}

		case SUCCESS(StoresListActionType.CLEAR_STORE_MAP_INFO_WINDOW): {
			draft.isPending = false
			draft.store = null

			return draft
		}

		case FAILURE(StoresListActionType.GET_STORE_MAP_INFO_WINDOW): {
			draft.isPending = false
			draft.store = initialState.store

			return draft
		}

		default:
			return draft
	}
}, initialState)

