import { ITurbineMicroservicesState } from '~/state/reducers/turbineMicroservicesReducer'

export const turbineMicroservicesReducerInitialState: ITurbineMicroservicesState = {
	storeDataService: false,
	authService: false,
	adeoCartService: false,
	accountService: false,
	customerService: false,
	proxyApiService: false,
	webToolsService: false,
	authlessOrderService: false,
	offerManagerService: false,
	transactionManagerService: false,
	customerPlannerService: false,
	logService: false,
}
