import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import classNames from 'classnames'
import { useToggle } from 'react-use'
import { isEmpty, isNull } from 'lodash'

import { CollapseTextButton, ICollapseTextProps } from '~/components/core/collapseText'
import { ALL_FOCUSABLE_SELECTOR_QUERY } from '~/hooks/skipElements'

import styles from './CollapseText.module.scss'

const CollapseText = (props: ICollapseTextProps): ReactElement => {
	const {
		fullText,
		id = '',
		text = '',
		isWithoutShortText = false,
		hasTransitionOnAppear = true,
		additionalClass = '',
		theme = {},
	} = props
	const spanRef = useRef<HTMLSpanElement | null>(null)
	const [isFullTextVisible, toggleIsFullTextVisible] = useToggle(isWithoutShortText)

	const textValue = isFullTextVisible ? fullText : text

	const handleShowFullText = useCallback((): void => {
		toggleIsFullTextVisible()
	}, [])

	useEffect(() => {
		if (isFullTextVisible && !isNull(spanRef)) {
			const container = spanRef.current?.childNodes[0] as HTMLElement
			const focusable = container.querySelector(ALL_FOCUSABLE_SELECTOR_QUERY) as HTMLElement

			if (!isNull(focusable)) {
				focusable.focus()
			}
		}
	}, [isFullTextVisible, spanRef])

	const slideDownClass = classNames({
		[styles.slideDown]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	const contentClass = classNames(styles.content, theme.content)

	return (
		<span
			ref={ spanRef }
			className={ styles.wrapper }
			id={ id }
		>
			<SlideDown className={ slideDownClass } transitionOnAppear={ hasTransitionOnAppear }>
				<span className={ contentClass } dangerouslySetInnerHTML={ { __html: textValue } } />

				{ !isWithoutShortText && (
					<CollapseTextButton
						isOpened={ isFullTextVisible }
						additionalClass={ theme.collapseButton }
						onShowFullText={ handleShowFullText }
					/>
				) }
			</SlideDown>
		</span>
	)
}

export { CollapseText }
