import React, { ReactElement, useCallback, useMemo } from 'react'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import axios, { AxiosError } from 'axios'
import { gt, isEmpty, isUndefined, map, replace, size } from 'lodash'

import { CartCustomerFormHeader, CartCustomerFormButtons, getCartContactUpdatedData, ICartCustomerContactEditFormProps, ICartCustomerContactForm } from '~/components/cart/cartCustomer'
import { AppDispatch } from '~/state/store'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { Input } from '~/components/core/form'
import { Modal } from '~/components/core/modal'
import { validation } from '~/utils/validation'
import { patchCustomerData } from '~/actions/customer'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'

import styles from './CartCustomerContactEditForm.module.scss'

const CartCustomerContactEditForm = (props: ICartCustomerContactEditFormProps): ReactElement => {
	const { isOpen, customer, customerType, onOpen, onClose, onUpdate } = props
	const { t, i18n } = useTranslation(['cart', 'form'])
	const dispatch: AppDispatch = useDispatch()
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const { newAlert } = useAlert()
	const { sendLogError } = useLogError()

	const { phone } = customer
	const { mask, regex } = validation

	const schema: yup.SchemaOf<ICartCustomerContactForm> = useMemo(() => yup.object().shape({
		phone: yup.string().length(9, t('validation.phoneNumberLength', { ns: 'form' })).required(t('required', { ns: 'form' })),
	}), [t, i18n])

	const formProps = useForm<ICartCustomerContactForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			phone,
		},
		mode: 'onChange',
	})

	const { formState: { isSubmitting }, handleSubmit, control, setError, watch } = formProps
	const { phone: phoneValue } = watch()

	const isDisabled = isEmpty(phoneValue)
	const placeholder = replace(mask.phone, /0/g, '_')

	const handleChangePhone = useCallback((value: string): void => {
		let inputValue = value
		const maskSize = size(mask.phone)

		if (gt(size(value), maskSize)) {
			inputValue = replace(value, regex.phoneWithoutAreaCode, '')
		}

		formProps.setValue('phone', replace(inputValue, regex.onlyDigits, '').substring(0, maskSize))
	}, [])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerContactForm): Promise<void> => {
		try {
			const requestData = getCartContactUpdatedData(formData, customerType, customer)

			await dispatch(patchCustomerData(requestData))

			await onUpdate(requestData)
			onClose()
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					setError(field as FieldPath<ICartCustomerContactForm>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				newAlert('danger', getErrorMessage(code), 5000)

				sendLogError(error)
			}
		}
	}), [customer, customerType, onUpdate, onClose])

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
			onOpen={ onOpen }
		>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<FormProvider { ...formProps }>
				<form onSubmit={ handleFormSubmit }>
					<CartCustomerFormHeader title={ t('customer.contact.formHeader') } />

					<Input
						isRequired
						control={ control }
						name="phone"
						label={ t('customer.contact.label', { ns: 'cart' }) }
						type="tel"
						prefix={ t('phoneNumberPrefix', { ns: 'common' }) }
						placeholder={ placeholder }
						onChange={ handleChangePhone }
					/>

					<CartCustomerFormButtons
						isDisabled={ isDisabled }
						isPending={ isSubmitting }
						onCancel={ onClose }
					/>
				</form>
			</FormProvider>
		</Modal>
	)
}

export { CartCustomerContactEditForm }
