import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'

import { IErrorBoundaryProps } from '~/components/core/form'
import { useMozaic } from '~/hooks/mozaic'

import styles from './ErrorBoundary.module.scss'

const ErrorBoundary = (props: IErrorBoundaryProps): ReactElement | null => {
	const {
		error = undefined,
		additionalClass = '',
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	if (isUndefined(error) || isEmpty(error.message)) {
		return null
	}

	const { message } = error

	const wrapperClass = classNames(styles.wrapper, additionalClass, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			{ message }
		</div>
	)
}

export { ErrorBoundary }
