import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { ICustomerProjectItem, ICustomerProjects, IPostCustomerProjectsResponse } from '~/api/dataTypes/customerProject'

export enum AccountProjectsType {
	GET_CUSTOMER_PLANNER_PROJECTS = 'accountProjects/GET_CUSTOMER_PLANNER_PROJECTS',
	DELETE_CUSTOMER_PLANNER_PROJECT = 'accountProjects/DELETE_CUSTOMER_PLANNER_PROJECT',
	PATCH_CUSTOMER_PLANNER_PROJECT = 'accountProjects/PATCH_CUSTOMER_PLANNER_PROJECT',
	GET_CUSTOMER_PLANNER_PROJECT = 'accountProjects/GET_CUSTOMER_PLANNER_PROJECT',
	PUT_CUSTOMER_PLANNER_PROJECT = 'accountProjects/PUT_CUSTOMER_PLANNER_PROJECT',
	POST_CUSTOMER_PLANNER_PROJECT = 'accountProjects/POST_CUSTOMER_PLANNER_PROJECT',
}

export type GetCustomerPlannerProjectsType = AppAction<AccountProjectsType.GET_CUSTOMER_PLANNER_PROJECTS, AxiosResponse<ICustomerProjects>>
export type DeleteCustomerPlannerProjectsType = AppAction<AccountProjectsType.DELETE_CUSTOMER_PLANNER_PROJECT, AxiosResponse<void>>
export type PatchCustomerPlannerProjectsType = AppAction<AccountProjectsType.PATCH_CUSTOMER_PLANNER_PROJECT, AxiosResponse<void>>
export type GetCustomerPlannerProjectType = AppAction<AccountProjectsType.GET_CUSTOMER_PLANNER_PROJECT, AxiosResponse<ICustomerProjectItem>>
export type PutCustomerPlannerProjectType = AppAction<AccountProjectsType.PUT_CUSTOMER_PLANNER_PROJECT, AxiosResponse<void>>
export type PostCustomerPlannerProjectType = AppAction<AccountProjectsType.POST_CUSTOMER_PLANNER_PROJECT, AxiosResponse<IPostCustomerProjectsResponse>>

export type AccountProjectsActionType = GetCustomerPlannerProjectsType | DeleteCustomerPlannerProjectsType | PatchCustomerPlannerProjectsType | GetCustomerPlannerProjectType | PutCustomerPlannerProjectType | PostCustomerPlannerProjectType
