import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { find, isEqual, isNil, isNull, map } from 'lodash'

import { vars } from '~/statics'
import { IRootState } from '~/state/types'
import { clearCartDeliveryPickupPoint, searchCartDeliveryPickupPoint } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { GoogleMaps, GoogleMapsPositionType, GoogleMapsStateType, IGoogleMapsMarker } from '~/components/core/googleMaps'
import { CartPreviewDeliveryPickupPointInfoWindow, ICartPreviewDeliveryPickupTransferMapProps } from '~/components/cart/cartPreview'
import { IPickupPointData } from '~/api/dataTypes/pickupPoint'

import styles from './CartPreviewDeliveryPickupTransferMap.module.scss'

const CartPreviewDeliveryPickupTransferMap = (props: ICartPreviewDeliveryPickupTransferMapProps): ReactElement => {
	const { pickupPointType, selectedPointId, zipCode, onSelect } = props
	const { pickupPoints: { geolocation, pickupPointList }, data: { cart: { pickupPoint } } } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const [mapZoom, setMapZoom] = useState<number>(isNull(pickupPoint) ? 10 : 16)
	const [activeMarkerWindow, setActiveMarkerWindow] = useState<IGoogleMapsMarker | null>(null)
	const mapInstance = useRef<GoogleMapsStateType | null>(null)
	const dispatch: AppDispatch = useDispatch()

	const handleLoad = useCallback((map: GoogleMapsStateType) => {
		mapInstance.current = map
	}, [])

	const initialPosition = useMemo((): GoogleMapsPositionType => {
		if (!isNull(pickupPoint)) {
			return { lat: pickupPoint?.geolocation.latitude, lng: pickupPoint?.geolocation.longitude }
		}

		if (!isNull(geolocation)) {
			const { latitude: lat, longitude: lng } = geolocation

			return { lat, lng }
		}

		return vars.map
	}, [geolocation, vars.map])

	const markers: IGoogleMapsMarker[] = useMemo(() => map(pickupPointList, (point: IPickupPointData) => {
		const { id, geolocation: { latitude, longitude }, pickupPointCode } = point

		return {
			id,
			position: {
				lat: latitude,
				lng: longitude,
			},
			icon: vars.images.pinGreen,
			type: 'markerInfoWindow',
			title: pickupPointCode,
			infoWindow: (
				<CartPreviewDeliveryPickupPointInfoWindow
					point={ point }
					selectedPointId={ selectedPointId }
					onSelect={ onSelect }
				/>
			),
		}
	}), [pickupPointList, selectedPointId, onSelect])

	useEffect(() => {
		(async () => {
			await dispatch(searchCartDeliveryPickupPoint({ zipCode, pickupPointType }))
		})()

		return () => {
			(async () => {
				await dispatch(clearCartDeliveryPickupPoint())
			})()
		}
	}, [])

	useEffect(() => {
		const marker = find(markers, ({ id }: IGoogleMapsMarker) => isEqual(id, selectedPointId))

		setActiveMarkerWindow(marker ?? null)
	}, [selectedPointId, markers])

	useEffect(() => {
		if (!isNil(geolocation)) {
			mapInstance.current?.setCenter({ lat: geolocation.latitude, lng: geolocation.longitude })
			mapInstance.current?.setZoom(10)
		}
	}, [geolocation])

	useEffect(() => {
		mapInstance.current?.setZoom(mapZoom)
	}, [mapZoom])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.map }>
				<GoogleMaps
					hasCluster
					shouldCenterOnMarkerClick
					id="store-transfer-pickup-point"
					initCenter={ initialPosition }
					initZoom={ mapZoom }
					markers={ markers }
					activeMarkerWindow={ activeMarkerWindow }
					onLoad={ handleLoad }
				/>
			</div>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferMap }
