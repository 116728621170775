import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ICartPreviewProductsLabelsItemProps } from '~/components/cart/cartPreview'
import { CART_PRODUCTS_LABEL_COLOR_CLASS } from '~/components/cart/constants'

import styles from './CartPreviewProductsLabelsItem.module.scss'

const CartPreviewProductsLabelsItem = (props: ICartPreviewProductsLabelsItemProps): ReactElement => {
	const { label } = props

	const { message, color } = label
	const colorClass = CART_PRODUCTS_LABEL_COLOR_CLASS[color]

	const wrapperClass = classNames([styles.wrapper], [styles[colorClass]])

	return (
		<div
			dangerouslySetInnerHTML={ { __html: message } }
			role="presentation"
			className={ wrapperClass }
		/>
	)
}

export { CartPreviewProductsLabelsItem }
