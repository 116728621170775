import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { gt, isEmpty } from 'lodash'

import { HeaderTopBarSearchBarProductsCounter, IHeaderTopBarSearchBarTitleTileProps } from '~/components/core/layout'

import styles from './HeaderTopBarSearchBarTitleTile.module.scss'

const HeaderTopBarSearchBarTitleTile = (props: IHeaderTopBarSearchBarTitleTileProps): ReactElement => {
	const {
		title,
		count = 0,
		additionalClass = '',
	} = props

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass }>
			<span className={ styles.title }>{ title }</span>

			{ gt(count, 0) && (
				<HeaderTopBarSearchBarProductsCounter count={ count } />
			) }
		</div>
	)
}

export { HeaderTopBarSearchBarTitleTile }
