import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import classNames from 'classnames'

import { UserContext } from '~/providers/userProvider'
import { useModal } from '~/hooks/modal'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { getSummaryDiscountButtonTranslate, ICartPreviewSummaryDiscountProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount'
import { CartPreviewSummaryDiscountContent } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountContent'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { useSkipElements } from '~/hooks/skipElements'
import { SkipContentList } from '~/components/core/skipContent'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewSummaryDiscount.module.scss'

const CartPreviewSummaryDiscount = (props: ICartPreviewSummaryDiscountProps): ReactElement | null => {
	const { isStepZero, shouldHidePromoCodeForNonLoyaltyLoggedUsers } = props
	const { isLogged } = useContext(UserContext)
	const { loyaltyType } = useContext(LysLoyaltyProviderContext)
	const { loyaltyProgram: { lysIntegrationEnabled: isLysIntegrationEnabled, cartPromotionCodeForNonLoyaltyUsersEnabled: isCartPromotionCodeForNonLoyaltyUsersEnabled } } = useContext(AppParametersContext)
	const { isOpen: isOpenDiscountModal, handleOpen: handleOpenDiscountModal, handleClose: handleCloseDiscountModal } = useModal('DOMProgramDiscount')
	const [isSlideDownClosedState, toggleIsSideDownClosedState] = useToggle(!isLogged || isStepZero)
	const { cartPreviewDiscountSkip } = useSkipElements()
	const { getShouldUseMozaicFlag } = useMozaic()
	const { t } = useTranslation(['cart'])

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const handleToggleActivity = useCallback((): void => {
		toggleIsSideDownClosedState()

		if (isOpenDiscountModal) {
			handleCloseDiscountModal()
		} else {
			handleOpenDiscountModal()
		}
	}, [isOpenDiscountModal])

	const text = getSummaryDiscountButtonTranslate(isLogged, isLysIntegrationEnabled, isStepZero, loyaltyType, t)

	const buttonClass = classNames(styles.button, {
		[styles.lys]: isLysIntegrationEnabled || isStepZero,
	})

	const arrowClass = classNames(styles.arrow, {
		[styles.arrowLys]: isLysIntegrationEnabled || isStepZero,
		[styles.arrowActive]: !isSlideDownClosedState,
	})

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	if (shouldHidePromoCodeForNonLoyaltyLoggedUsers) {
		return null
	}

	return (
		<div className={ wrapperClass }>
			<SkipContentList skipElements={ cartPreviewDiscountSkip } />

			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ buttonClass }
				ariaLabel={ text }
				onClick={ handleToggleActivity }
			>
				<span>
					{ text }
				</span>

				<Icon
					additionalClass={ arrowClass }
					width={ 9 }
					name="arrowFull"
				/>
			</Button>

			<CartPreviewSummaryDiscountContent
				isOpenDiscountModal={ isOpenDiscountModal }
				isClosed={ isSlideDownClosedState }
				isStepZero={ isStepZero }
				onToggleActivity={ handleToggleActivity }
			/>
		</div>
	)
}

export { CartPreviewSummaryDiscount }
