import { isEqual, isUndefined } from 'lodash'

import { vars } from '~/statics'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFlagBehindKobiIntegrationEnabled = (config: { [key: string]: any } | undefined, field: string): boolean => {
	const { isKobiIntegrationEnabled: isKobiIntegrationEnabledByEnv } = vars

	return isUndefined(isKobiIntegrationEnabledByEnv) ? Boolean(config?.[field]) : isEqual(isKobiIntegrationEnabledByEnv, 'true')
}
