import React, { ReactElement } from 'react'

import { AdditionalFooterWrapper } from '~/components/core/layout'
import { Newsletter } from '~/components/core/newsletter'

import styles from './FooterSimple.module.scss'

const FooterSimple = (): ReactElement => {
	return (
		<section className={ styles.wrapper }>
			<AdditionalFooterWrapper>
				<Newsletter />
			</AdditionalFooterWrapper>
		</section>
	)
}

export { FooterSimple }
