import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'

import { DATA_TESTID } from '~/utils/dataTestId'
import { Link } from '~/components/core/link'
import { Icon } from '~/components/core/icon'
import { IUserMenuItemProps } from '~/components/core/userMenu'

import styles from './UserMenuItem.module.scss'

const UserMenuItem = (props: IUserMenuItemProps): ReactElement => {
	const {
		item, onClose,
		theme = {},
	} = props

	const { name, url, icon } = item

	const titleClass = classNames(styles.title, theme.title)

	const handleGoToPage = useCallback(() => {
		onClose()
	}, [onClose])

	return (
		<li className={ styles.wrapper }>
			<Link
				href={ url }
				additionalClass={ styles.button }
				dataTestId={ DATA_TESTID.CORE.USER_MENU_ITEM(name) }
				ariaLabel={ name }
				onClick={ handleGoToPage }
			>
				<Icon
					color="navy"
					name={ icon }
					width={ 18 }
				/>

				<span className={ titleClass }>
					{ name }
				</span>
			</Link>
		</li>
	)
}

export { UserMenuItem }
