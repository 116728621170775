import { IAccountLoyaltyHistoryDetailsState } from '~/state/reducers/accountReducer'

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_ADDITIONAL_CARD_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	cardNumber: '2046502052657',
	balance: 0,
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_ASSIGN_CUSTOMER_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	balance: 20,
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_MIGRATION_POINTS_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	balance: 20,
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_EXCHANGE_POINTS_FOR_VOUCHER_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	balance: -100,
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_CORRECTION_POINTS_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	balance: 100,
	comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo sodales pulvinar sic tempor.',
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_REGISTER_CUSTOMER_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	cardNumber: '2046502052657',
	balance: 30,
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_EXPIRED_POINTS_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	balance: -30,
	regulationsUrl: '/todoRegulationsUrl',
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_EXCHANGE_POINTS_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	cardNumber: '2046502052657',
	shopName: 'Warszawa Arkadia',
	balance: -30,
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_DIRECT_EXCHANGE_POINTS_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	cardNumber: '2046502052657',
	cashierNumber: '01289',
	shopName: 'Warszawa Arkadia',
	balance: -30,
	voucherValue: '100 zł',
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_RETURN_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	cardNumber: '2046502052657',
	cashierNumber: '01289',
	shopName: 'E-commerce',
	balance: -30,
	returnValue: '189,95',
	returnNumber: '001503577',
	orderLines: [
		{
			id: 1,
			product: {
				id: 1,
				image: 'https://static02.leroymerlin.pl/files/media/image/497/1834497/product/myjka-do-okien-wv-classic-karcher,main.jpg',
				name: 'Myjka do okien WV Classic Karcher',
				refNumber: '45589453',
				value: 159.99,
			},
			quantity: {
				value: 1,
				unit: 'szt.',
			},
			totalValue: 159.99,
		},
		{
			id: 2,
			product: {
				id: 2,
				image: 'https://static02.leroymerlin.pl/files/media/image/502/2173502/product/fotel-wiszacy-kokon-aruba-szary,main.jpg',
				name: 'Fotel wiszący kokon Aruba szary',
				refNumber: '82395592',
				value: 599.00,
			},
			quantity: {
				value: 2,
				unit: 'szt.',
			},
			totalValue: 1198.00,
		},
	],
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_SALE_MOCK: IAccountLoyaltyHistoryDetailsState = {
	date: 1653477934000,
	cardNumber: '2046502052657',
	receiptNumber: '01289_12321_12321344',
	shopName: 'E-commerce',
	balance: 35,
	value: '189,95 zł',
	orderLines: [
		{
			id: 1,
			product: {
				id: 1,
				image: 'https://static02.leroymerlin.pl/files/media/image/497/1834497/product/myjka-do-okien-wv-classic-karcher,main.jpg',
				name: 'Myjka do okien WV Classic Karcher',
				refNumber: '45589453',
				value: 159.99,
			},
			quantity: {
				value: 1,
				unit: 'szt.',
			},
			totalValue: 159.99,
		},
		{
			id: 2,
			product: {
				id: 2,
				image: 'https://static02.leroymerlin.pl/files/media/image/502/2173502/product/fotel-wiszacy-kokon-aruba-szary,main.jpg',
				name: 'Fotel wiszący kokon Aruba szary',
				refNumber: '82395592',
				value: 599.00,
			},
			quantity: {
				value: 2,
				unit: 'szt.',
			},
			totalValue: 1198.00,
		},
	],
}

export const ACCOUNT_LOYALTY_HISTORY_DETAILS_MOCK: IAccountLoyaltyHistoryDetailsState = {
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_ADDITIONAL_CARD_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_ASSIGN_CUSTOMER_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_MIGRATION_POINTS_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_EXCHANGE_POINTS_FOR_VOUCHER_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_CORRECTION_POINTS_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_REGISTER_CUSTOMER_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_EXPIRED_POINTS_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_EXCHANGE_POINTS_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_DIRECT_EXCHANGE_POINTS_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_RETURN_MOCK,
	...ACCOUNT_LOYALTY_HISTORY_DETAILS_SALE_MOCK,
}
