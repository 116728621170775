import React, { createContext, useState, ReactElement, useMemo, useCallback, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { useTranslation } from 'next-i18next'
import { isEmpty, isUndefined, noop } from 'lodash'

import { ILysLoyaltyContextProps, ILysLoyaltyProviderProps, getCurrentReward } from '~/providers/lysLoyaltyProvider'
import { getCartPreviewLoyaltyProgram, getCartPreviewLoyaltySelectedReward } from '~/state/reducers/cartPreviewReducer'
import { IRootState } from '~/state/types'
import { ICartPreviewDiscountLysSelectedReward } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'

const initialProps: ILysLoyaltyContextProps = {
	loyaltyType: '',
	selectedReward: {
		code: '',
		type: null,
	},
	isLoading: false,
	isLysMigrated: false,
	isLysCommunicationError: false,
	isEditMode: false,
	isSelectedReward: false,
	shouldClearInput: false,
	onSetEditMode: noop,
	onSetSelectedReward: noop,
	onSetIsSelectedReward: noop,
	onSetLysCommunicationError: noop,
	onSetShouldClearInput: noop,
}

export const LysLoyaltyProviderContext = createContext(initialProps)

export const LysLoyaltyProvider = (props: ILysLoyaltyProviderProps): ReactElement => {
	const { children } = props
	const { isPending: isCartPending, data: { cart: { promotionCodeUsed: isPromotionCodeUsed, promotionCode, promotionCodeValue } }, customerLoyalty: { accountData: { cards }, isPending: isLoyaltyPending } } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const loyaltyProgramType = useSelector(getCartPreviewLoyaltyProgram, shallowEqual)
	const rawCurrentReward = useSelector(getCartPreviewLoyaltySelectedReward, shallowEqual)
	const currentReward = getCurrentReward(promotionCode, rawCurrentReward, promotionCodeValue)
	const [selectedReward, setSelectedReward] = useState<ICartPreviewDiscountLysSelectedReward>(currentReward)
	const [isLysCommunicationError, setIsLysCommunicationError] = useToggle(false)
	const [isSelectedReward, setIsSelectedReward] = useToggle(isPromotionCodeUsed)
	const [isEditMode, setIsEditMode] = useToggle(false)
	const [shouldClearInput, setShouldClearInput] = useToggle(false)
	const { t } = useTranslation(['common'])

	const isLysMigrated = !isEmpty(cards)
	const isLoading = isLoyaltyPending || isCartPending

	const handleSetSelectedReward = useCallback((selectedReward: ICartPreviewDiscountLysSelectedReward): void => {
		setSelectedReward(selectedReward)
	}, [])

	const handleSetLysCommunicationError = useCallback((isLysCommunicationError: boolean): void => {
		setIsLysCommunicationError(isLysCommunicationError)
	}, [])

	const handleSetEditMode = useCallback((isEditMode: boolean): void => {
		setIsEditMode(isEditMode)
	}, [])

	const handleSetIsSelectedReward = useCallback((isSelectedReward?: boolean): void => {
		isUndefined(isSelectedReward) ? setIsSelectedReward() : setIsSelectedReward(isSelectedReward)
	}, [])

	const handleSetShouldClearInput = useCallback((shouldClearInput?: boolean): void => {
		isUndefined(shouldClearInput) ? setShouldClearInput() : setShouldClearInput(shouldClearInput)
	}, [])

	useEffect(() => {
		handleSetSelectedReward(currentReward)
	}, [])

	const providerValue: ILysLoyaltyContextProps = useMemo(() => ({
		loyaltyType: t(`loyaltyProgramTypes.${ loyaltyProgramType }`),
		isLysMigrated,
		selectedReward,
		isLysCommunicationError,
		isEditMode,
		isSelectedReward,
		shouldClearInput,
		isLoading,
		onSetEditMode: handleSetEditMode,
		onSetSelectedReward: handleSetSelectedReward,
		onSetIsSelectedReward: handleSetIsSelectedReward,
		onSetShouldClearInput: handleSetShouldClearInput,
		onSetLysCommunicationError: handleSetLysCommunicationError,
	}), [selectedReward, isLysMigrated, isLysCommunicationError, loyaltyProgramType, isEditMode, isSelectedReward, shouldClearInput, isLoading, handleSetEditMode, handleSetSelectedReward, handleSetIsSelectedReward, handleSetShouldClearInput, handleSetLysCommunicationError])

	return (
		<LysLoyaltyProviderContext.Provider value={ providerValue }>
			{ children }
		</LysLoyaltyProviderContext.Provider>
	)
}
