import React, { ReactElement, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { Input, IRadioOption, RadioGroup } from '~/components/core/form'
import { DISCOUNT, ICartPreviewSummaryDiscountFormData, ICartPreviewSummaryPromoCodeProps } from '~/components/cart/cartPreview'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewSummaryPromoCode.module.scss'

const CartPreviewSummaryPromoCode = (props: ICartPreviewSummaryPromoCodeProps): ReactElement => {
	const { promotionCodeUsed, promotionCodeType } = props
	const { t } = useTranslation(['cart'])
	const { control, setValue, watch } = useFormContext<ICartPreviewSummaryDiscountFormData>()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { discount } = watch()

	const handleSetPromoDiscount = useCallback((): void => {
		if (!isEqual(discount, DISCOUNT.PROMOTION_CODE)) {
			setValue('discount', DISCOUNT.PROMOTION_CODE)
		}
	}, [discount])

	const renderContent = useCallback((): ReactElement => {
		const options: IRadioOption[] = [{
			label: t('preview.aside.discount.promoCode.label', { ns: 'cart' }),
			value: DISCOUNT.PROMOTION_CODE,
		}]

		const labelClass = classNames(styles.label, {
			[styles.isMozaic]: shouldUseMozaic,
		})

		return (
			<>
				<RadioGroup
					control={ control }
					name="discount"
					options={ options }
					theme={ { wrapper: styles.radio, label: labelClass } }
				/>

				<div aria-hidden onClick={ handleSetPromoDiscount }>
					<Input
						type="text"
						additionalClass={ styles.input }
						control={ control }
						name="promotionCode"
						placeholder={ t('preview.aside.discount.promoCode.codeInputPlaceholder') }
					/>
				</div>
			</>
		)
	}, [promotionCodeUsed, control, promotionCodeType, shouldUseMozaic])

	useEffect(() => {
		if (promotionCodeType === 'LOYALTY') {
			setValue('promotionCode', '')
		}
	}, [promotionCodeType])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { CartPreviewSummaryPromoCode }
