import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { ICartPreviewDiscountLysCouponDescriptionProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons/cartPreviewDiscountLysCouponDescription'
import { useDate } from '~/hooks/date'

import styles from './CartPreviewDiscountLysCouponDescription.module.scss'

const CartPreviewDiscountLysCouponDescription = (props: ICartPreviewDiscountLysCouponDescriptionProps): ReactElement => {
	const { name, expiryDate, isUsed, isSelectedCoupon } = props
	const { formatDateUtc, getDateFormat, getTodayDate, getTimeFormat } = useDate()
	const { t } = useTranslation(['cart'])

	const renderDate = useCallback((isTheSameDate: boolean, date: string, expiryDateUTC: Date): ReactElement => {
		const dateValue = isTheSameDate ? formatDateUtc(expiryDateUTC, getTimeFormat()) : date

		return (
			<span className={ styles.couponDescriptionDate }>
				{ dateValue }
			</span>
		)
	}, [])

	const expiryDateUTC = new Date(`${ expiryDate }Z`)
	const date = formatDateUtc(expiryDateUTC, getDateFormat())
	const todayDate = formatDateUtc(getTodayDate(), getDateFormat())
	const isTheSameDate = isUsed && isEqual(todayDate, date)
	const text = isTheSameDate ? t('preview.aside.lysDiscount.couponsSection.expiryTime') : t('preview.aside.lysDiscount.couponsSection.expiryDate')

	const wrapperClass = classNames(styles.wrapper, {
		[styles.selected]: isSelectedCoupon,
	})

	return (
		<div className={ wrapperClass }>
			<span className={ styles.couponCaption }>
				{ name }
			</span>

			<div className={ styles.couponDescriptionContainer }>
				<span>
					{ text }
				</span>

				{ renderDate(isTheSameDate, date, expiryDateUTC) }
			</div>
		</div>
	)
}

export { CartPreviewDiscountLysCouponDescription }
