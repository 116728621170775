import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IHeaderTopBarSearchBarHistoryResetProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarSearchBarHistoryReset.module.scss'

const HeaderTopBarSearchBarHistoryReset = (props: IHeaderTopBarSearchBarHistoryResetProps): ReactElement => {
	const {
		onReset, handleBlur,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['header'])

	const buttonClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<Button
			text={ t('search.clearHistory') }
			variant="neutral"
			size="inherit"
			additionalClass={ buttonClass }
			dataTestId={ DATA_TESTID.HEADER.BUTTON_RESET_HISTORY_SEARCH }
			onClick={ onReset }
			onBlur={ handleBlur }
		/>
	)
}

export { HeaderTopBarSearchBarHistoryReset }
