import React, { useCallback, ReactElement } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { map, throttle } from 'lodash'

import { getHeaderMiniBoxListStyles, IHeaderTopBarMiniCartModalItemsProps, getPriceValue } from '~/components/core/layout'
import { ProductBlockMini } from '~/components/core/productBlock'
import { cartCalculate, getCartData, removeProductFromCart } from '~/actions/cart'
import { getMiniCartItemQuantityList } from '~/state/reducers/miniCartReducer'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { ICartItemData } from '~/api/dataTypes/cart'
import { IProductSimple, IProductQuantity } from '~/api/dataTypes/product'

import styles from './HeaderTopBarMiniCartModalItems.module.scss'

const HeaderTopBarMiniCartModalItems = (props: IHeaderTopBarMiniCartModalItemsProps): ReactElement => {
	const { items, cartUuid, productsQuantityMap } = props
	const dispatch: AppDispatch = useDispatch()
	const { GA_addToCart } = useGoogleAnalytics()
	const { promotionCode } = useSelector((state: IRootState) => state.cartPreview.data.cart, shallowEqual)
	const cartItemQuantityList = useSelector((state: IRootState) => getMiniCartItemQuantityList(state))

	const handleSetProductQuantity = useCallback((quantity: number, itemId: number, productSimple: IProductSimple, isIncrement: boolean) => throttle(async (): Promise<void> => {
		const itemQuantityList: IProductQuantity[] = map(cartItemQuantityList, (item: IProductQuantity) => {
			const { itemId: itemQuantityId } = item

			return itemQuantityId === itemId ? { itemId, quantity } : item
		})
		const { price: { newPrice, basePrice } } = productSimple
		const priceValue = getPriceValue(newPrice, basePrice)

		if (isIncrement) {
			GA_addToCart({ product: productSimple, value: priceValue, quantity: 1 })
		}

		await dispatch(cartCalculate({
			promotionCode,
			itemQuantityList,
		}))
	}, 700, { leading: false }), [cartItemQuantityList, promotionCode])

	const handleRemove = useCallback((productSimple: IProductSimple) => async (): Promise<void> => {
		const { lmReference } = productSimple

		await dispatch(removeProductFromCart({ lmReference }))
		await dispatch(getCartData())
	}, [productsQuantityMap])

	const renderItems = useCallback((): ReactElement[] => (
		map(items, (item: ICartItemData, index: number) => {
			const { itemId, productSimple, valueForLine, saleUnitName } = item
			const { lmReference } = productSimple

			const count = productsQuantityMap[lmReference]
			const incrementValue = count + 1
			const decrementValue = count - 1

			return (
				<ProductBlockMini
					key={ itemId }
					product={ productSimple }
					price={ valueForLine }
					count={ count }
					saleUnitName={ saleUnitName }
					dataTestId={ DATA_TESTID.HEADER.MINI_CART_ITEM(index) }
					onIncrement={ handleSetProductQuantity(incrementValue, itemId, productSimple, true) }
					onDecrement={ handleSetProductQuantity(decrementValue, itemId, productSimple, false) }
					onRemove={ handleRemove(productSimple) }
				/>
			)
		})
	), [items, cartUuid])

	return (
		<Scrollbars universal style={ getHeaderMiniBoxListStyles(items.length) }>
			<div className={ styles.wrapper } data-testid={ DATA_TESTID.HEADER.MINI_CART_ITEMS }>
				{ renderItems() }
			</div>
		</Scrollbars>
	)
}

export { HeaderTopBarMiniCartModalItems }
