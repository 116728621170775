import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { CartSummaryType, CartSummaryActionType } from '~/actions/cartSummary'

import { ICartSummaryState } from './types'
import { cartSummaryInitialState as initialState } from './constants'

export const cartSummary = produce((draft: Draft<ICartSummaryState>, action: AppPromiseAction<CartSummaryActionType>) => {
	switch (action.type) {
		case REQUEST(CartSummaryType.GET_CART_SUMMARY):
			draft.isPending = true

			return draft
		case SUCCESS(CartSummaryType.GET_CART_SUMMARY):
			draft.isPending = false
			draft.data = action.payload.data

			return draft
		case FAILURE(CartSummaryType.GET_CART_SUMMARY):
			draft.isPending = false

			return draft

		case SUCCESS(CartSummaryType.CLEAR_CART_SUMMARY_CHANGED_PRICES_ALERT_MESSAGE):
			draft.data.changedPricesAlertMessage = null

			return draft
	}
}, initialState)
