import { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { setCookie } from 'cookies-next'

import { AppDispatch } from '~/state/store'
import { setPreferredStore, clearPreferredStore } from '~/actions/preferredStore'
import { IUsePreferredStoreData } from '~/hooks/preferredStore'
import { COOKIE_KEY_ADEO_LMPL_STORE_ID, COOKIE_KEY_ADEO_STORE, COOKIE_KEY_ADEO_STORE_ID, COOKIE_MAX_AGE_ONE_YEAR } from '~/utils/constants'
import { AppParametersContext } from '~/providers/appParametersProvider'

export const usePreferredStore = (): IUsePreferredStoreData => {
	const dispatch: AppDispatch = useDispatch()
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const handleSetPreferredStore = useCallback(async (storeCode: string, name: string, isLogged: boolean): Promise<void> => {
		await dispatch(setPreferredStore(storeCode, isLogged))

		if (isKobiIntegrationEnabled) {
			const storeId = parseInt(storeCode, 10).toString()
			const storeValueCookie = `store=${ storeId }|dateContext=${ new Date().toISOString().slice(0, 10).replace(/-/g, '') }`
			const domain = `.${ location.hostname.split('.').slice(-2).join('.') }`

			setCookie(COOKIE_KEY_ADEO_STORE, storeValueCookie, {
				domain, encode: (storeValueCookie: string) => storeValueCookie,
				sameSite: 'lax',
				maxAge: COOKIE_MAX_AGE_ONE_YEAR,
			})

			setCookie(COOKIE_KEY_ADEO_STORE_ID, storeId, {
				domain,
				sameSite: 'lax',
				maxAge: COOKIE_MAX_AGE_ONE_YEAR,
			})

			setCookie(COOKIE_KEY_ADEO_LMPL_STORE_ID, storeId, {
				domain,
				sameSite: 'lax',
				maxAge: COOKIE_MAX_AGE_ONE_YEAR,
			})
		}
	}, [isKobiIntegrationEnabled])

	const handleClearPreferredStore = useCallback(async (isLogged: boolean): Promise<void> => {
		await dispatch(clearPreferredStore(isLogged))
	}, [])

	return {
		setPreferredStore: handleSetPreferredStore,
		clearPreferredStore: handleClearPreferredStore,
	}
}
