import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { Image } from '~/components/core/image'
import { ICartPreviewPaymentLabelProps } from '~/components/cart/cartPreview'
import { getRadioId, HiddenRadioPrefix, RadioIcon } from '~/components/core/form'
import { PaymentWayType } from '~/api/dataTypes/cart'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewPaymentLabel.module.scss'

const CartPreviewPaymentLabel = (props: ICartPreviewPaymentLabelProps): ReactElement => {
	const { option, isChecked, error, isDisabled } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const { label, imageSrc, description, paymentWayType } = option

	const isOneyInstallments = isEqual(paymentWayType, 'PRZELEWY24_ONEY' as PaymentWayType)

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	return (
		<div className={ wrapperClass } id={ getRadioId(label) }>
			<div className={ styles.radio }>
				<HiddenRadioPrefix isDisabled={ isDisabled } isChecked={ isChecked } />

				<RadioIcon isChecked={ isChecked } error={ error } />

				<div className={ styles.data }>
					<div className={ styles.img }>
						<Image
							width={ 40 }
							height={ 40 }
							loading="lazy"
							src={ imageSrc }
							alt={ label }
							role="none"
						/>
					</div>

					{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
					<span className={ styles.name }>
						{ label }
					</span>
				</div>
			</div>

			{ isChecked && (
				<div className={ styles.description }>
					<span className={ styles.text } dangerouslySetInnerHTML={ { __html: description } } />
				</div>
			) }
		</div>
	)
}

export { CartPreviewPaymentLabel }
