import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { AccountProjectsActionType, AccountProjectsType } from '~/actions/account'

import { IAccountProjectsState } from './types'
import { accountProjectsInitialState as initialState } from './constants'

export const projects = produce((draft: Draft<IAccountProjectsState>, action: AppPromiseAction<AccountProjectsActionType>) => {
	switch (action.type) {
		case REQUEST(AccountProjectsType.GET_CUSTOMER_PLANNER_PROJECTS):
		case FAILURE(AccountProjectsType.GET_CUSTOMER_PLANNER_PROJECTS):
			draft.isPending = true

			return draft
		case SUCCESS(AccountProjectsType.GET_CUSTOMER_PLANNER_PROJECTS):
			draft.list = action.payload.data
			draft.isPending = false

			return draft
		case SUCCESS(AccountProjectsType.DELETE_CUSTOMER_PLANNER_PROJECT):
			draft.isPending = false

			return draft
	}
}, initialState)
