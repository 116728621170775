import { filter, isEmpty, some } from 'lodash'

import { IComparisonProduct, IComparisonState, ComparisonFailedType } from '~/state/reducers/comparisonReducer'
import { AppPromiseThunkAction } from '~/actions'
import { ComparisonAddItemType, ComparisonRemoveItemType, ComparisonClearType, ComparisonClearErrorsType, ComparisonActionType } from '~/actions/comparison'
import { vars } from '~/statics'

// eslint-disable-next-line @typescript-eslint/typedef
export const addComparisonItem = (product: IComparisonProduct): AppPromiseThunkAction<ComparisonAddItemType> => async (dispatch, getState) => {
	const { comparison } = getState()
	const { products, comparisonCategory } = comparison

	const isItemInProducts = some(products, (item: IComparisonProduct) => item.id === product.id)
	const category = comparisonCategory || product.category

	const isDifferentCategory = category !== product.category
	const isToManyProducts = products.length === vars.comparison.maxProducts

	const isFailed = isDifferentCategory || isToManyProducts
	const failed: ComparisonFailedType | null = isDifferentCategory ? 'CATEGORY' : isToManyProducts ? 'ITEMS_COUNT' : null

	const result: Partial<IComparisonState> = {
		products: isItemInProducts ? products : [...products, product],
		comparisonCategory: category,
	}

	return dispatch({
		type: ComparisonActionType.ADD_COMPARISON_ITEM,
		payload: isFailed ? Promise.reject(failed): Promise.resolve(result),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const removeComparisonItem = (productId: number): AppPromiseThunkAction<ComparisonRemoveItemType> => async (dispatch, getState) => {
	const { comparison } = getState()
	const { products, comparisonCategory } = comparison

	const filteredProducts = filter(products, (item: IComparisonProduct) => item.id !== productId)

	const result: Partial<IComparisonState> = {
		products: filteredProducts,
		comparisonCategory: isEmpty(filteredProducts) ? null : comparisonCategory,
	}

	return dispatch({
		type: ComparisonActionType.REMOVE_COMPARISON_ITEM,
		payload: Promise.resolve(result),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearComparisonItems = (): AppPromiseThunkAction<ComparisonClearType> => async (dispatch) => {
	return dispatch({
		type: ComparisonActionType.CLEAR_COMPARISON,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearErrors = (): AppPromiseThunkAction<ComparisonClearErrorsType> => async (dispatch) => {
	return dispatch({
		type: ComparisonActionType.CLEAR_ERRORS,
		payload: Promise.resolve(),
	})
}
