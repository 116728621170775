import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import axios, { AxiosError } from 'axios'
import classNames from 'classnames'
import { isEmpty, isNil, isUndefined, isNull } from 'lodash'

import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { validation } from '~/utils/validation'
import { Input } from '~/components/core/form'
import { ICartPreviewDeliveryPickupTransferZipCodeForm, ICartPreviewDeliveryPickupTransferZipCodeProps } from '~/components/cart/cartPreview'
import { getCartDeliveryTransportFromStoreCategoriesData, setDeliveryFromStoreZipCode, clearDeliveryFromStoreData } from '~/actions/cart'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { ICartDeliveryTransportFromStoreCategoriesParameters } from '~/api/dataTypes/cart'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDeliveryPickupTransferZipCode.module.scss'

const CartPreviewDeliveryPickupTransferZipCode = (props: ICartPreviewDeliveryPickupTransferZipCodeProps): ReactElement => {
	const { deliveryStore, onClearDeliveryFromStore } = props
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { t } = useTranslation(['cart', 'form'])
	const dispatch: AppDispatch = useDispatch()
	const { preventAlert } = useAlert()
	const { sendLogError } = useLogError()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { mask } = validation
	const isDisabled = isNil(preferredStore)
	const tooltipLabel = isDisabled ? t('preview.preferredStore.noChosenStore') : ''

	const schema: yup.SchemaOf<ICartPreviewDeliveryPickupTransferZipCodeForm> = useMemo(() => yup.object().shape({
		zipCode: yup.string().required(t('required', { ns: 'form' })).max(6),
	}), [])

	const { control, watch, setError, formState: { isDirty, errors } } = useForm<ICartPreviewDeliveryPickupTransferZipCodeForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			zipCode: !isNull(deliveryStore) ? deliveryStore.zipCode : '',
		},
		mode: 'onChange',
	})

	const { zipCode } = watch()

	const getCartDeliveryTransportData = useCallback(async (): Promise<void> => {
		if (!isEmpty(zipCode)) {
			try {
				const params: ICartDeliveryTransportFromStoreCategoriesParameters = {
					zipCode,
				}

				await dispatch(clearDeliveryFromStoreData())
				await dispatch(setDeliveryFromStoreZipCode(zipCode))
				await dispatch(getCartDeliveryTransportFromStoreCategoriesData(params))
			} catch (e: unknown) {
				const error = e as AxiosError<IResourceBadRequestException>

				if (axios.isAxiosError(error) && !isUndefined(error.response)) {
					const { message } = error.response.data

					if (!isEmpty(message)) {
						setError('zipCode', {
							message,
						})
					} else {
						preventAlert(e)
					}

					onClearDeliveryFromStore()
				}

				sendLogError(e)
			}
		}
	}, [zipCode, onClearDeliveryFromStore])

	useEffect(() => {
		(async () => {
			await getCartDeliveryTransportData()
		})()
	}, [zipCode])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
		[styles.isDirty]: isDirty || !isEmpty(errors),
	})

	return (
		<div className={ wrapperClass }>
			<div className={ styles.caption }>
				{ t('preview.delivery.groups.storeDelivery.enterZipCode') }
			</div>

			<div className={ styles.form }>
				<Input
					isRequired
					isDisabled={ isDisabled }
					tooltipLabel={ tooltipLabel }
					name="zipCode"
					mask={ mask.zipCode }
					label={ t('preview.delivery.groups.storeDelivery.zipCodeLabel') }
					control={ control }
					theme={ { inputWrapper: styles.zipCode } }
				/>
			</div>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferZipCode }
