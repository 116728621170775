import React, { ReactElement } from 'react'

import { IHeaderTopBarUserAccountLoggedProps } from '~/components/core/layout'
import { UserMenu } from '~/components/core/userMenu'

import styles from './HeaderTopBarUserAccountLogged.module.scss'

const HeaderTopBarUserAccountLogged = (props: IHeaderTopBarUserAccountLoggedProps): ReactElement => {
	const { onClose } = props

	return (
		<div className={ styles.wrapper }>
			<UserMenu
				theme={ {
					wrapper: styles.menu,
					itemWrapper: styles.itemWrapper,
					button: styles.button,
					logoutWrapper: styles.logoutWrapper,
					logoutButton: styles.logoutButton,
				} }
				onClose={ onClose }
			/>
		</div>
	)
}

export { HeaderTopBarUserAccountLogged }
