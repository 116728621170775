import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { IAvailableProductQuantityResponse, IProductAllReviewsResponse, IProductReviewVoteResponse } from '~/api/dataTypes/product'
import { IStoreResponse } from '~/api/dataTypes/store'

export enum ProductCardActionType {
	GET_STORE_AVAILABILITY = 'productCard/GET_STORE_AVAILABILITY',
	GET_STORE = 'productCard/GET_STORE',
	GET_REVIEWS = 'productCard/GET_REVIEWS',
	GET_REVIEWS_NEXT_PAGE = 'productCard/GET_REVIEWS_NEXT_PAGE',
	SET_REVIEWS = 'productCard/SET_REVIEWS',
	ADD_REVIEW = 'productCard/ADD_REVIEW',
	ADD_REVIEW_VOTE = 'productCard/ADD_REVIEW_VOTE',
	DELETE_REVIEW_VOTE = 'productCard/DELETE_REVIEW_VOTE',
	CLEAN_UP = 'productCard/CLEAN_UP',
}

export type GetProductCardStoreAvailabilityActionType = AppAction<ProductCardActionType.GET_STORE_AVAILABILITY, AxiosResponse<IAvailableProductQuantityResponse>>

export type GetProductCardStoreActionType = AppAction<ProductCardActionType.GET_STORE, AxiosResponse<IStoreResponse>, { storeId: number }>

export type GetProductCardReviewsActionType = AppAction<ProductCardActionType.GET_REVIEWS, AxiosResponse<IProductAllReviewsResponse>>

export type GetProductCardReviewsNextPageActionType = AppAction<ProductCardActionType.GET_REVIEWS_NEXT_PAGE, AxiosResponse<IProductAllReviewsResponse>>

export type SetProductCardReviewsActionType = AppAction<ProductCardActionType.SET_REVIEWS, IProductAllReviewsResponse>

export type AddProductCardReviewActionType = AppAction<ProductCardActionType.ADD_REVIEW, AxiosResponse<void>>

export type AddProductCardReviewVoteActionType = AppAction<ProductCardActionType.ADD_REVIEW_VOTE, AxiosResponse<IProductReviewVoteResponse>>

export type DeleteProductCardReviewVoteActionType = AppAction<ProductCardActionType.DELETE_REVIEW_VOTE, AxiosResponse<IProductReviewVoteResponse>>

export type CleanUpProductCardActionType = AppAction<ProductCardActionType.CLEAN_UP>

export type ProductCardActionUnionType = GetProductCardStoreAvailabilityActionType
| GetProductCardStoreActionType
| GetProductCardReviewsActionType
| GetProductCardReviewsNextPageActionType
| SetProductCardReviewsActionType
| AddProductCardReviewActionType
| AddProductCardReviewVoteActionType
| DeleteProductCardReviewVoteActionType
| CleanUpProductCardActionType
