import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { ICartPreviewDeliveryEcommerceCostProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryEcommerceCost.module.scss'

const CartPreviewDeliveryEcommerceCost = (props: ICartPreviewDeliveryEcommerceCostProps): ReactElement => {
	const { price } = props
	const { t } = useTranslation(['cart', 'common'])

	return (
		<div className={ styles.wrapper }>
			<span>
				{ t('preview.delivery.payment') }
			</span>

			<span className={ styles.price }>
				{ `${ price } ${ t('currency', { ns: 'common' }) }` }
			</span>
		</div>
	)
}

export { CartPreviewDeliveryEcommerceCost }
