import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isUndefined } from 'lodash'

import { IPreferredStoreSelectionProps, PreferredStoreModalData } from '~/components/core/preferredStore'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './PreferredStoreSelection.module.scss'

const PreferredStoreSelection = (props: IPreferredStoreSelectionProps): ReactElement | null => {
	const { selectedStore, onClick } = props
	const { t } = useTranslation(['common'])

	if (isUndefined(selectedStore)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<PreferredStoreModalData store={ selectedStore } />

			<Button
				isReverse
				variant="secondary"
				text={ t('preferredShop.chooseStore') }
				additionalClass={ styles.button }
				dataTestId={ DATA_TESTID.CORE.BUTTON_SET_PREFERRED_STORE }
				onClick={ onClick }
			/>
		</div>
	)
}

export { PreferredStoreSelection }
