import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './NewsletterCaption.module.scss'

const NewsletterCaption = (): ReactElement => {
	const { t } = useTranslation(['newsletter'])

	return (
		<h2 className={ styles.wrapper }>
			{ t('newsletter.caption') }
		</h2>
	)
}

export { NewsletterCaption }
