import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IRequiredInfoProps } from '~/components/core/form'
import { useMozaic } from '~/hooks/mozaic'

import styles from './RequiredInfo.module.scss'

const RequiredInfo = (props: IRequiredInfoProps): ReactElement | null => {
	const { additionalClass = '' } = props
	const { t } = useTranslation(['form'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	if (shouldUseMozaic) {
		return null
	}

	return (
		<div className={ wrapperClass }>
			{ `* ${ t('requiredFields', { ns: 'form' }) }` }
		</div>
	)
}

export { RequiredInfo }
