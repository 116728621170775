import { withFresherToken } from '~/actions/auth'
import { AppPromiseThunkAction } from '~/actions/types'
import { getCustomerReviews, putCustomerReviewsSubscription } from '~/api/requests/customerReviews'
import { ICustomerReviewsSubscriptionRequestData } from '~/api/dataTypes/customerReviews'
import { IPaginationRequestParams } from '~/api/dataTypes/pageable'

import { CustomerReviewActionType, GetCustomerReviewsActionType, SetCustomerReviewsSubscriptionActionType } from './types'

// eslint-disable-next-line @typescript-eslint/typedef
export const getCustomerReviewsData = withFresherToken((params: IPaginationRequestParams): AppPromiseThunkAction<GetCustomerReviewsActionType> => async (dispatch) => {
	return dispatch({
		type: CustomerReviewActionType.GET_CUSTOMER_REVIEWS,
		payload: getCustomerReviews(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setCustomerReviewsSubscriptionData = withFresherToken((params: ICustomerReviewsSubscriptionRequestData): AppPromiseThunkAction<SetCustomerReviewsSubscriptionActionType> => async (dispatch) => {
	return dispatch({
		type: CustomerReviewActionType.SET_CUSTOMER_REVIEWS_SUBSCRIPTION,
		payload: putCustomerReviewsSubscription(params),
	})
})
