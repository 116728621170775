import { combineReducers } from 'redux'

import { IProductCardState } from '~/state/reducers/productCardReducer'

import { productCardStoreAvailability } from './storeAvailability'
import { productCardStores } from './stores'
import { productCardOpinions } from './opinions'
import { productCardAddOpinion } from './addOpinion'
import { productCardVoteOpinion } from './voteOpinion'

const productCard = combineReducers<IProductCardState>({
	storeAvailability: productCardStoreAvailability,
	stores: productCardStores,
	opinions: productCardOpinions,
	addOpinion: productCardAddOpinion,
	voteOpinion: productCardVoteOpinion,
})

export { productCard }
