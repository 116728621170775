import React, { ReactElement, useMemo } from 'react'
import classNames from 'classnames'
import { map, includes } from 'lodash'

import { ILayoutAccountMenuSectionProps, LayoutAccountMenuItem } from '~/components/core/layout'
import { IAccountPanelMenuItem } from '~/hooks/userMenu'

import styles from './LayoutAccountMenuSection.module.scss'

const LayoutAccountMenuSection = (props: ILayoutAccountMenuSectionProps): ReactElement => {
	const { section, isLast, active } = props
	const { items } = section

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.lastSection]: isLast,
	})

	const menuItems = useMemo((): ReactElement[] => map(items, (item: IAccountPanelMenuItem) => {
		const { pages, id } = item
		const isActive = includes(pages, active)

		return (
			<LayoutAccountMenuItem
				key={ id }
				item={ item }
				isActive={ isActive }
			/>
		)
	}), [items, active])

	return (
		<div className={ wrapperClass }>
			{ menuItems }
		</div>
	)
}

export { LayoutAccountMenuSection }
