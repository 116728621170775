import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useLocalStorage } from 'react-use'
import { isEmpty } from 'lodash'

import { UserContext } from '~/providers/userProvider'
import { DATA_TESTID } from '~/utils/dataTestId'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { IPreferredStoreInfoActionsProps } from '~/components/core/preferredStore'
import { ArrowLink } from '~/components/core/arrowLink'
import { usePreferredStore } from '~/hooks/preferredStore'

import styles from './PreferredStoreInfoActions.module.scss'

const PreferredStoreInfoActions = (props: IPreferredStoreInfoActionsProps): ReactElement => {
	const {
		onChange, onBlur,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['stores'])
	const [value, setValue, remove] = useLocalStorage<string>('preferredStore', '') // eslint-disable-line @typescript-eslint/no-unused-vars
	const { isLogged } = useContext(UserContext)
	const { clearPreferredStore } = usePreferredStore()

	const handleClearPreferredStore = useCallback(async (): Promise<void> => {
		remove()

		await clearPreferredStore(isLogged)
	}, [isLogged])

	const handleChangeStore = useCallback((): void => {
		onChange()
	}, [onChange])

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass }>
			<Button
				text={ t('clearStoreSelection') }
				variant="neutral"
				size="inherit"
				additionalClass={ styles.link }
				dataTestId={ DATA_TESTID.CORE.BUTTON_CLEAR_PREFERRED_STORE }
				onClick={ handleClearPreferredStore }
			/>

			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.link }
				dataTestId={ DATA_TESTID.CORE.BUTTON_CHANGE_PREFERRED_STORE }
				ariaLabel={ t('changeStore') }
				onClick={ handleChangeStore }
				onBlur={ onBlur }
			>
				<Icon
					width={ 17 }
					name="group"
					color="green"
					additionalClass={ styles.icon }
				/>

				<ArrowLink text={ t('changeStore') } />
			</Button>
		</div>
	)
}

export { PreferredStoreInfoActions }
