import { getCartUuid } from '~/utils/cart'

import { ICartPreviewState } from './types'

export const cartPreviewInitialState: ICartPreviewState = {
	data: {
		cart: {
			cartUuid: getCartUuid(),
			anyPostPurchaseRewards: false,
			selectedDeliveryWayId: null,
			selectedPaymentWayId: null,
			selectedDeliveryWayType: null,
			cartStatus: 'VIEW',
			availability: {
				name: '',
				label: 'A',
				id: 0,
				numberOfDeliveryDays: 0,
				onDemand: false,
			},
			cartItemList: [],
			pickupInStore: {
				pickupInStoreDateInfo: '',
				timeSlot: '',
				shouldRecalculate: false,
			},
			deliveryStore: {
				pickupPoint: false,
				category: '',
				zipCode: '',
				storeName: '',
				timeSlot: {
					date: 0,
					toTime: 0,
					fromTime: 0,
				},
				categoryName: '',
				shouldRecalculate: false,
				transportCalculation: false,
			},
			numberOfItems: 0,
			paymentSummaryValue: 0,
			paymentSummaryNetValue: 0,
			productsQuantityMap: {},
			productsValue: 0,
			productsNetValue: 0,
			promotionCode: null,
			promotionCodeType: 'NONE',
			loyaltyCardNumber: '',
			wwwUrl: '',
			promotionCodeUsed: false,
			promotionCodeValue: '',
			totalCostValue: 0,
			promotionValue: 0,
			deliveryCost: 0,
			pickupPoint: null,
			appliedPromotions: [],
		},
		cartStatus: 'VIEW',
		invalidCartMessage: '',
		allProductUnavailable: false,
		anyDeliveryAndPaymentsAvailable: false,
		discountCounter: {
			discountedDeliveryCost: 0,
			missingValueForDiscount: 0,
		},
		changeCartItemQuantityList: [],
		paymentList: [],
		courierNote: '',
		deliveryGroups: {
			delivery: {
				deliveryList: [],
				deliveryType: 'DELIVERY',
				selected: false,
				active: false,
			},
			pickup: {
				deliveryList: [],
				deliveryType: 'PICKUP',
				selected: false,
				active: false,
			},
			storeDelivery: {
				deliveryList: [],
				deliveryType: 'STORE_DELIVERY',
				selected: false,
				active: false,
			},
		},
	},
	tmpCourierNote: '',
	activeDeliveryGroup: null,
	isPending: false,
	itemsAvailability: [],
	pickupPoints: {
		geolocation: null,
		lastUsedPickupPoint: null,
		pickupPointList: [],
	},
	deliveryStoreTimeSlots: {
		zipCode: '',
		categories: {
			transportCategoryList: [],
			errorMessage: null,
		},
		timeslotList: [],
		selectedTimeSlot: {
			date: 0,
			timePeriod: [],
		},
	},
	customerLoyalty: {
		isPending: false,
		accountData: {
			availablePoints: 0,
			cards: [],
			currentRewards: [],
			firstName: '',
			lastName: '',
			status: '',
			type: 'DOM',
		},
	},
	isStepZero: true,
	hasCustomerLoyalty: false,
}
