import React, { ReactElement } from 'react'
import { useRouter } from 'next/router'

import { HeaderSimple, HeaderWrapper, ILayoutOrdersStatusProps, LayoutOrdersStatusWrapper, Main } from '~/components/core/layout'
import { ReactErrorBoundary, ERROR_BOUNDARY_KEYS } from '~/components/core/reactErrorBoundary'
import { AlertProvider } from '~/providers/alertProvider'
import { HeaderProvider } from '~/providers/headerProvider'
import { KobiFavicon } from '~/components/kobi/kobiFavicon'

const LayoutOrdersStatus = (props: ILayoutOrdersStatusProps): ReactElement => {
	const { children, pageType } = props
	const router = useRouter()

	return (
		<>
			<KobiFavicon />

			<HeaderProvider>
				<HeaderWrapper isSimple>
					<HeaderSimple />
				</HeaderWrapper>

				<AlertProvider>
					<Main pageType={ pageType }>
						<ReactErrorBoundary key={ ERROR_BOUNDARY_KEYS.LAYOUT_ORDERS_STATUS } url={ router.asPath }>
							<LayoutOrdersStatusWrapper>
								{ children }
							</LayoutOrdersStatusWrapper>
						</ReactErrorBoundary>
					</Main>
				</AlertProvider>
			</HeaderProvider>
		</>
	)
}

export { LayoutOrdersStatus }
