import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { ICartPreviewProductsActionBlockProps } from '~/components/cart/cartPreview'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsActionBlock.module.scss'

const CartPreviewProductsActionBlock = (props: ICartPreviewProductsActionBlockProps): ReactElement => {
	const { onOpenRemoveConfirmationModal } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const iconName = shouldUseMozaic ? 'mozaicTrashbin' : 'close'
	const iconWidth = shouldUseMozaic ? 15 : 13
	const iconColor = shouldUseMozaic ? 'black' : 'green'

	return (
		<div className={ styles.wrapper }>
			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ styles.button }
				ariaLabel={ t('preview.table.removeAction') }
				dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_REMOVE_PRODUCT }
				onClick={ onOpenRemoveConfirmationModal }
			>
				<Icon
					name={ iconName }
					width={ iconWidth }
					color={ iconColor }
					additionalClass={ styles.icon }
				/>
			</Button>
		</div>
	)
}

export { CartPreviewProductsActionBlock }
