import { useTranslation } from 'next-i18next'

import { IBreadcrumb } from '~/api/dataTypes/breadcrumb'
import { IUseAccountBreadcrumbsData } from '~/hooks/accountBreadcrumbs'
import { PageType } from '~/statics'

export const useAccountBreadcrumbs = (): IUseAccountBreadcrumbsData => {
	const { t } = useTranslation(['header', 'account'])

	// TODO: add translations
	// TODO: add urls from config
	const getBreadcrumbs = (accountPageType: PageType): IBreadcrumb[] => {
		switch (accountPageType) {
			case 'ACCOUNT':
				return [
					{
						label: 'Moje konto',
					},
				]
			case 'ACCOUNT_USER_DATA':
				return [
					{
						label: t('topBar.account.data', { ns: 'header' }),
					},
				]
			case 'ACCOUNT_ADDRESS_BOOK':
				return [
					{
						label: 'Książka adresowa',
					},
				]
			case 'ACCOUNT_ADDRESS_BOOK_EDIT':
				return [
					{
						label: 'Książka adresowa',
						url: '/account/address-book',
					},
					{
						label: 'Edytuj adres',
					},
				]
			case 'ACCOUNT_ADDRESS_BOOK_NEW':
				return [
					{
						label: 'Książka adresowa',
					},
					{
						label: 'Dodaj nowy adres',
					},
				]
			case 'ACCOUNT_MARKETING_CONSENTS':
				return [
					{
						label: t('topBar.account.marketingAgreements', { ns: 'header' }),
					},
				]
			case 'ACCOUNT_ORDERS':
				return [
					{
						label: t('topBar.account.orders', { ns: 'header' }),
					},
				]
			case 'ACCOUNT_FAVORITES':
				return [
					{
						label: t('topBar.account.favorites', { ns: 'header' }),
					},
				]
			case 'ACCOUNT_PROJECTS':
				return [
					{
						label:  t('topBar.account.projects', { ns: 'header' }),
					},
				]
			case 'ACCOUNT_RETURNS':
				return [
					{
						label: 'Zwroty i reklamacje',
					},
				]
			case 'ACCOUNT_RETURNS_APPLICATION':
				return [
					{
						label: 'Zwroty i reklamacje',
						pageType: 'ACCOUNT_RETURNS',
						url: '/account/returns',
					},
					{
						label: 'Nowy wniosek',
					},
				]
			case 'ACCOUNT_RETURNS_APPLICATION_ORDER':
				return [
					{
						label: 'Zwroty i reklamacje',
						pageType: 'ACCOUNT_RETURNS',
						url: '/account/returns',
					},
					{
						label: 'Wybierz zamówienie',
					},
				]
			case 'ACCOUNT_RETURNS_APPLICATION_SENT':
				return [
					{
						label: 'Zwroty i reklamacje',
						pageType: 'ACCOUNT_RETURNS',
						url: '/account/returns',
					},
					{
						label: 'Wniosek wysłany',
					},
				]
			case 'ACCOUNT_REVIEWS':
				return [
					{
						label: t('topBar.account.opinions', { ns: 'header' }),
					},
				]
			case 'ACCOUNT_LOYALTY_PROGRAM':
				return [
					{
						label: 'Program lojalnościowy',
					},
				]
			case 'ACCOUNT_LOYALTY_JOIN':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
				]
			case 'ACCOUNT_LOYALTY_JOIN_SUCCESS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
				]
			case 'ACCOUNT_LOYALTY_CONNECT':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Połącz kartę z kontem',
					},
				]
			case 'ACCOUNT_LOYALTY_CONNECT_ACCOUNT':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Połącz kartę z kontem',
						pageType: 'ACCOUNT_LOYALTY_CONNECT',
						url: '/account/loyalty-program/connect',
					},
					{
						label: 'Połącz używając konta',
					},
				]
			case 'ACCOUNT_LOYALTY_CONNECT_CARD':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Połącz kartę z kontem',
						pageType: 'ACCOUNT_LOYALTY_CONNECT',
						url: '/account/loyalty-program/connect',
					},
					{
						label: 'Połącz używając karty',
					},
				]
			case 'ACCOUNT_LOYALTY_CONNECT_FORGOT_PASSWORD':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Połącz kartę z kontem',
						pageType: 'ACCOUNT_LOYALTY_CONNECT',
						url: '/account/loyalty-program/connect',
					},
					{
						label: 'Połącz używając konta',
						pageType: 'ACCOUNT_LOYALTY_CONNECT_ACCOUNT',
						url: '/account/loyalty-program/connect/account',
					},
					{
						label: 'Odzyskiwanie hasła',
					},
				]
			case 'ACCOUNT_LOYALTY_CONNECT_FORGOT_PASSWORD_SUCCESS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Połącz kartę z kontem',
						pageType: 'ACCOUNT_LOYALTY_CONNECT',
						url: '/account/loyalty-program/connect',
					},
					{
						label: 'Połącz używając konta',
						pageType: 'ACCOUNT_LOYALTY_CONNECT_ACCOUNT',
						url: '/account/loyalty-program/connect/account',
					},
					{
						label: 'Wysłano hasło',
					},
				]
			case 'ACCOUNT_LOYALTY_CONNECT_SUCCESS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Połącz kartę z kontem',
					},
				]
			case 'ACCOUNT_LOYALTY_DASHBOARD':
				return [
					{
						label: 'Program lojalnościowy',
					},
				]
			case 'ACCOUNT_LOYALTY_EXCHANGE_POINTS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Wymień punkty',
					},
				]
			case 'ACCOUNT_LOYALTY_EXCHANGE_POINTS_FOUNDATION':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_EXCHANGE_POINTS',
						url: '/account/loyalty-program/exchange-points',
						label: 'Wymień punkty',
					},
					{
						label: 'Pomagamy bohaterom',
					},
				]
			case 'ACCOUNT_LOYALTY_EXCHANGE_POINTS_FOUNDATION_SUCCESS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_EXCHANGE_POINTS',
						url: '/account/loyalty-program/exchange-points',
						label: 'Wymień punkty',
					},
					{
						label: 'Dziękujemy za wsparcie fundacji Leroy Merlin',
					},
				]
			case 'ACCOUNT_LOYALTY_EXCHANGE_POINTS_TRANSFER':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_EXCHANGE_POINTS',
						url: '/account/loyalty-program/exchange-points',
						label: 'Wymień punkty',
					},
					{
						label: 'Wymień punkty na rabat',
					},
				]
			case 'ACCOUNT_LOYALTY_EXCHANGE_POINTS_TRANSFER_SUCCESS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_EXCHANGE_POINTS',
						url: '/account/loyalty-program/exchange-points',
						label: 'Wymień punkty',
					},
					{
						label: 'Punkty zostały wymienione na rabat',
					},
				]
			case 'ACCOUNT_LOYALTY_VOUCHER_ACTIVATION_SUCCESS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_EXCHANGE_POINTS',
						url: '/account/loyalty-program/exchange-points',
						label: 'Wymień punkty',
					},
					{
						label: 'Rabat lojalnościowy został aktywowany',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						label: 'Historia',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_ADDITIONAL_CARD':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Karta dodatkowa',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_CUSTOMER_ASSIGN':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Przypisanie klienta',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_CUSTOMER_REGISTER':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Rejestracja klienta',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_EXCHANGE_POINTS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Wymiana punktów',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_EXPIRED_POINTS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Wygaśnięcie punktów',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_DIRECT_EXCHANGE_POINTS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Bezpośrednia wymiana punktów',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_EXCHANGE_POINTS_FOR_VOUCHER':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Wymiana punktów na bon',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_MIGRATION_POINTS':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Migracja punktów',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_POINTS_CORRECTION':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Korekta punktów',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_RETURN':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Zwrot',
					},
				]
			case 'ACCOUNT_LOYALTY_HISTORY_SALE':
				return [
					{
						pageType: 'ACCOUNT_LOYALTY_PROGRAM',
						url: '/account/loyalty-program',
						label: 'Program lojalnościowy',
					},
					{
						pageType: 'ACCOUNT_LOYALTY_HISTORY',
						url: '/account/loyalty-program/history',
						label: 'Historia',
					},
					{
						label: 'Sprzedaż',
					},
				]
			case 'SHOPPING_LIST':
				return [
					{
						label: t('topBar.account.favorites', { ns: 'header' }),
					},
				]
			default:
				return [
					{
						label: 'Moje konto',
					},
				]
		}
	}

	return {
		getBreadcrumbs,
	}
}
