import React, { ReactElement } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { IWatermarkPlaceholderProps } from '~/components/core/watermarkPlaceholder'
import { vars } from '~/statics/variables'
import { Loader } from '~/components/core/loader'

import styles from './WatermarkPlaceholder.module.scss'

const WatermarkPlaceholder = (props: IWatermarkPlaceholderProps): ReactElement | null => {
	const {
		children,
		isLoading = false,
		text = 'Not in MVP / API',
		size = 'lg',
	} = props
	const router = useRouter()

	const isWatermarkVisible = router.query.watermark === 'true' || vars.isDeveloperMode
	const wrapperClass = classNames(styles.wrapper, styles[size])

	if (!isWatermarkVisible) {
		return null
	}

	return (
		<div className={ wrapperClass } data-text={ text }>
			{ isLoading && <Loader theme={ { wrapper: styles.withLoader } } /> }

			{ children }
		</div>
	)
}

export { WatermarkPlaceholder }
