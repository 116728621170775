import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { isEmpty, noop } from 'lodash'

import { CartCustomerAsideBlock, CartCustomerGuestForm, CartCustomerGuestLoader, CartCustomerInvoice, CartCustomerOrderCollection, CartCustomerWrapper, CartSummaryGuestFormWrapper, getCustomerNextTooltipInvoiceInfo, getLmReferencesFromCartItemList } from '~/components/cart/cartCustomer'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { useCartStatus } from '~/hooks/cartStatus'
import { useCartGuestCustomer } from '~/hooks/cartGuestCustomer'
import { useCartGuestCustomerForm } from '~/hooks/cartGuestCustomerForm'
import { useInitialLoader } from '~/hooks/initialLoader'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { getCartCustomerData } from '~/actions/cartCustomer'
import { CartColumnWrapper } from '~/components/core/cartColumnWrapper'

const CartCustomerGuest = (): ReactElement => {
	const { data, isPending, isNextButtonDisabledByInvoice } = useSelector((state: IRootState) => state.cartCustomer, shallowEqual)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { t } = useTranslation(['cart'])
	const { changeCartView } = useCartStatus()
	const { isNextLoading, onNext } = useCartGuestCustomer()
	const { privateFormProps, companyFormProps, isMainFormSubmitDisable } = useCartGuestCustomerForm()
	const dispatch: AppDispatch = useDispatch()
	const { preventAlert } = useAlert()
	const { GA_checkout } = useGoogleAnalytics()
	const [isInitialLoader] = useInitialLoader(isPending)
	const [isAnalyticsEventsSent, setIsAnalyticsEventsSent] = useState<boolean>(false)
	const { sendLogError } = useLogError()

	const { customerOtherContact, receiptNip, invoice, cart, customerGuest: { customerType }, delivery, invoiceRequired, cartStatus } = data
	const { totalCostValue, cartItemList, productsValue } = cart
	const nextButtonDisabledInfo = getCustomerNextTooltipInvoiceInfo(t, isNextButtonDisabledByInvoice, isMainFormSubmitDisable)

	const handleBack = useCallback(async (): Promise<void> => {
		await changeCartView(1, cartStatus)
	}, [])

	const fetchCustomerData = useCallback(async (): Promise<void> => {
		try {
			await dispatch(getCartCustomerData())
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		}
	}, [])

	const analyticsEvents = useCallback(() => {
		if (!isEmpty(cartItemList) && !isAnalyticsEventsSent) {
			GA_checkout({ cartItemList, step: 2, value:  productsValue, cartStatus })
			setIsAnalyticsEventsSent(true)
		}
	}, [cartItemList, isAnalyticsEventsSent, productsValue, cartStatus])

	const renderContent = useCallback((): ReactElement => {
		if (isInitialLoader) {
			return (
				<CartCustomerGuestLoader />
			)
		}

		return (
			<>
				<CartCustomerGuestForm type={ customerType } />

				<CartCustomerOrderCollection otherContact={ customerOtherContact } />

				<CartCustomerInvoice
					isInvoiceRequired={ invoiceRequired }
					totalCostValue={ totalCostValue }
					receiptNip={ receiptNip }
					invoice={ invoice }
					guestCustomerType={ customerType }
					isMainFormIncomplete={ isMainFormSubmitDisable }
				/>
			</>
		)
	}, [isInitialLoader, customerType, customerOtherContact, invoiceRequired, totalCostValue, receiptNip, invoice, delivery, isMainFormSubmitDisable])

	useEffect(() => {
		fetchCustomerData()
	}, [preferredStore])

	useEffect(() => {
		analyticsEvents()
	}, [cartItemList])

	return (
		<CartSummaryGuestFormWrapper
			privateFormProps={ privateFormProps }
			companyFormProps={ companyFormProps }
			type={ customerType }
			onNext={ onNext }
		>
			<CartCustomerWrapper>
				<CartColumnWrapper>
					{ renderContent() }
				</CartColumnWrapper>

				<CartCustomerAsideBlock
					cart={ cart }
					isLoading={ isPending }
					isNextButtonDisabled={ isMainFormSubmitDisable || isNextButtonDisabledByInvoice }
					nextButtonDisabledInfo={ nextButtonDisabledInfo }
					isNextLoading={ isNextLoading }
					onBack={ handleBack }
					onNext={ noop }
				/>
			</CartCustomerWrapper>
		</CartSummaryGuestFormWrapper>
	)
}

export { CartCustomerGuest }
