import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { usePrice } from '~/hooks/price'
import { ICartPreviewDeliverySplitTotalPriceProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliverySplitTotalPrice.module.scss'

const CartPreviewDeliverySplitTotalPrice = (props: ICartPreviewDeliverySplitTotalPriceProps): ReactElement => {
	const { summaryPrice } = props
	const { priceFormat } = usePrice()
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.header }>
				{ t('preview.table.header.summaryPrice') }
			</span>

			<span className={ styles.price }>
				{ priceFormat(summaryPrice, { precision: 2 }) }
			</span>
		</div>
	)
}

export { CartPreviewDeliverySplitTotalPrice }
