import { useCallback, useMemo } from 'react'
import { useTranslation } from 'next-i18next'

import { IUseDropdownSelect, ReactSelectAriaOnFocusProps } from '~/hooks/dropdownSelect'
import { ISelectOption } from '~/components/core/form'

export const useDropdownSelect = <T extends ISelectOption>(): IUseDropdownSelect<T> => {
	const { t, i18n } = useTranslation(['common'])

	const handleFocusAria = useCallback((props: ReactSelectAriaOnFocusProps<T>): string => {
		const { focused, isSelected, isDisabled } = props

		const focusedLabel: string = focused.label
		const selectedFocusedPart = isSelected ? `${ focusedLabel } - ${ t('reactSelect.currentSelectedOption') }` : focusedLabel
		const disabledPart = isDisabled ? `- ${ t('reactSelect.optionDisabled') }` : ''

		return `${ t('reactSelect.currentFocusedOption') } ${ selectedFocusedPart } ${ disabledPart }`
	}, [t, i18n])

	const guidance = useMemo(() => (): string => (
		t('reactSelect.guidance')
	), [t, i18n])

	return {
		guidance,
		handleFocusAria,
	}
}
