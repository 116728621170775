import md5 from 'md5'
import { filter, isEmpty, isNull, map, reduce } from 'lodash'

import { ISareCustomerDataAddress, ISareCustomerDataEvent, ISareCustomerEmailEvent, ISareProductDefine, ISareProductEvent, SareCustomerDataAddressGenderType, SareCustomerDataAddressType, SareProductInteractionType } from '~/utils/sare'
import { SARE_CLIENT_DATA_COUNTRY, SARE_PRODUCTS_LIST_MAX_ELEMENTS } from '~/hooks/sare'
import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'
import { ICartItemData } from '~/api/dataTypes/cart'
import { ICatalogSearchProductItemPage, ICatalogSearchProductItemData } from '~/api/dataTypes/catalog'
import { CustomerGenderType, CustomerType, ICustomerAddressData, ICustomerData, ICustomerResult } from '~/api/dataTypes/customer'
import { IOrderLineData } from '~/api/dataTypes/customerOrder'
import { IPriceData } from '~/api/dataTypes/price'
import { IProductSimple } from '~/api/dataTypes/product'

const getSareProductPriceFormat = (price: number): string => (
	`${ price.toFixed(2) } PLN`
)

const getSareProductPrice = (price: IPriceData): string => {
	const sarePrice = price.newPrice || price.basePrice || 0

	return getSareProductPriceFormat(sarePrice)
}

const getSareProductSimpleIds = (products: IProductSimple[]): string => (
	reduce(products, (result: string, product: IProductSimple) => {
		return result + `${ product.lmReference },`
	}, '').slice(0, -1)
)

const getSareProductSimplePrices = (products: IProductSimple[]): string => (
	reduce(products, (result: string, product: IProductSimple) => {
		return result + `${ getSareProductPrice(product.price) },`
	}, '').slice(0, -1)
)

const getSareCartProductsIds = (itemList: ICartItemData[]): ISareProductDefine[] => (
	map(itemList, (item: ICartItemData) => ({
		id: item.productSimple.lmReference,
		qty: item.quantityInfo.selectedQuantity,
	}))
)

const getSareOrderProductsIds = (orderLines: IOrderLineData[]): ISareProductDefine[] => (
	map(orderLines, (orderLine: IOrderLineData) => ({
		id: orderLine.orderItemData.lmReference,
		qty: orderLine.quantity,
	}))
)

const getSareClientGender = (gender: CustomerGenderType): SareCustomerDataAddressGenderType => {
	switch (gender) {
		case 'MALE':
			return 'M'
		case 'FEMALE':
			return 'F'
		default:
			return ''
	}
}

const getSareClientType = (customerType: CustomerType): SareCustomerDataAddressType => {
	switch (customerType) {
		case 'COMPANY':
			return 'company'
		case 'PRIVATE':
			return 'private'
		default:
			return ''
	}
}

const getSareClientDefaultAddress = (): ISareCustomerDataAddress => ({
	type: '',
	company_name: '',
	gender: '',
	first_name: '',
	last_name: '',
	country: '',
	region_code: '',
	postal_code: '',
	city: '',
	street: '',
	house_no: '',
	flat_no: '',
	phone: '',
})

const getSareClientAddress = (address: ICustomerAddressData | null, customerData: ICustomerData, customerType: CustomerType): ISareCustomerDataAddress => {
	if (isNull(address)) {
		return getSareClientDefaultAddress()
	}

	const { corporateName, firstName, lastName, postalCode, city, street, houseNumber, flatNumber } = address
	const { gender, phone } = customerData

	return {
		city,
		street,
		phone,
		region_code: '',
		first_name: firstName,
		last_name: lastName,
		postal_code: postalCode,
		house_no: houseNumber,
		flat_no: flatNumber || '',
		company_name: corporateName || '',
		country: SARE_CLIENT_DATA_COUNTRY,
		type: getSareClientType(customerType),
		gender: getSareClientGender(gender),
	}
}

const getSareClientDeliveryAddress = (deliveryAddresses: ICustomerAddressData[], customerData: ICustomerData, customerType: CustomerType): ISareCustomerDataAddress => {
	if (isEmpty(deliveryAddresses)) {
		return getSareClientDefaultAddress()
	}

	return getSareClientAddress(deliveryAddresses[0], customerData, customerType)
}

export const getSareProductByProductSimple = (product: IProductSimple, interactionType: SareProductInteractionType): ISareProductEvent => {
	const { lmReference, price } = product

	return {
		interactionType,
		productDefine: lmReference,
		productValue: getSareProductPrice(price),
	}
}

export const getSareProductByCatalogSearch = (itemPage: ICatalogSearchProductItemPage, interactionType: SareProductInteractionType): ISareProductEvent => {
	const { content } = itemPage

	const filtersProducts = filter(content, (item: ICatalogSearchProductItemData) => !isNull(item.listingProduct)).slice(0, SARE_PRODUCTS_LIST_MAX_ELEMENTS)
	const products = map(filtersProducts, (item: ICatalogSearchProductItemData) => item.listingProduct) as IProductSimple[]

	return {
		interactionType,
		productDefine: getSareProductSimpleIds(products),
		productValue: getSareProductSimplePrices(products),
	}
}

export const getSareProductByCart = (itemList: ICartItemData[], summaryValue: number, interactionType: SareProductInteractionType): ISareProductEvent => ({
	interactionType,
	productDefine: getSareCartProductsIds(itemList),
	productValue: getSareProductPriceFormat(summaryValue),
})

export const getSareProductByOrder = (order: IOrderDetailsResponse, interactionType: SareProductInteractionType): ISareProductEvent => {
	const { orderLines, orderCostSummaryData: { totalCostGrossValue } } = order

	return {
		interactionType,
		productDefine: getSareOrderProductsIds(orderLines),
		productValue: getSareProductPriceFormat(totalCostGrossValue),
	}
}

export const getSareClientData = (clientData: ICustomerResult): ISareCustomerDataEvent => {
	const { addresses, customerType, customer, login } = clientData
	const { birthDate, nip, customerNumber, mainAddress } = customer

	return {
		id: customerNumber,
		email: login,
		vat_no: nip || '',
		loyalty_program_card_no: '',
		date_of_birth: birthDate || '',
		addresses: {
			main:  getSareClientAddress(mainAddress, customer, customerType),
			delivery: getSareClientDeliveryAddress(addresses, customer, customerType),
		},
	}
}

export const getSareClientEmail = (clientData: ICustomerResult): ISareCustomerEmailEvent => {
	const { login } = clientData

	return {
		emailIds: {
			md5: md5(login),
		},
	}
}
