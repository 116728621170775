import React, { ReactElement } from 'react'

import { Image } from '~/components/core/image'
import { IAddToCartModalProductImageProps } from '~/components/core/addToCartModal'

import styles from './AddToCartModalProductImage.module.scss'

const AddToCartModalProductImage = (props: IAddToCartModalProductImageProps): ReactElement => {
	const { imageUrl, alt } = props

	return (
		<div className={ styles.wrapper }>
			<Image
				src={ imageUrl }
				width={ 80 }
				height={ 80 }
				alt={ alt }
				tabIndex={ -1 }
			/>
		</div>
	)
}

export { AddToCartModalProductImage }
