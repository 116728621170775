import React, { ReactElement, useCallback } from 'react'
import { isFunction, isNull, noop } from 'lodash'

import { IPreferredStoreProps, PreferredStoreButton, PreferredStoreModal } from '~/components/core/preferredStore'
import { useModal } from '~/hooks/modal'

const PreferredStore = (props: IPreferredStoreProps): ReactElement => {
	const {
		buttonRef, isModalVisible, onOpen,
		color = 'navy',
		dataTestId = '',
		additionalClass = '',
		handleBlur = noop,
		children = null,
	} = props
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')

	const handleOpenPopup = (): void => {
		if (isFunction(onOpen)) {
			onOpen()
		} else {
			handleOpen()
		}
	}

	const renderContent = useCallback(() => {
		if (!isNull(children)) {
			return children
		}

		return (
			<PreferredStoreModal isOpen={ isOpen } onClose={ handleClose } />
		)
	}, [children, isOpen, handleClose])

	return (
		<>
			<PreferredStoreButton
				buttonRef={ buttonRef }
				color={ color }
				additionalClass={ additionalClass }
				dataTestId={ dataTestId }
				isOpen={ isModalVisible }
				onOpen={ handleOpenPopup }
				onClose={ handleBlur }
			/>

			{ renderContent() }
		</>
	)
}

export { PreferredStore }
