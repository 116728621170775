import React, { ReactElement, useCallback } from 'react'
import { isEmpty, map } from 'lodash'

import { ICartItemData } from '~/api/dataTypes/cart'
import { CartPreviewDeliverySplitProductsHeader, CartPreviewDeliverySplitProductsItem, ICartPreviewDeliverySplitProductsProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliverySplitProducts.module.scss'

const CartPreviewDeliverySplitProducts = (props: ICartPreviewDeliverySplitProductsProps): ReactElement | null => {
	const {
		type, items,
		titles = [],
	} = props

	const renderTitles = useCallback((): ReactElement[] => (
		map(titles, (title: string) => (
			<div key={ title }>
				{ title }
			</div>
		))
	), [titles])

	const renderProductTable = useCallback((): ReactElement => (
		<>
			<CartPreviewDeliverySplitProductsHeader />

			{ map(items, (item: ICartItemData) => (
				<CartPreviewDeliverySplitProductsItem
					key={ item.itemId }
					item={ item }
					type={ type }
				/>
			)) }
		</>
	), [items, type])

	if (isEmpty(items)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption }>
				{ renderTitles() }
			</div>

			{ renderProductTable() }
		</div>
	)
}

export { CartPreviewDeliverySplitProducts }
