import React, { ReactElement, useCallback } from 'react'
import { SwiperOptions } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import classNames from 'classnames'
import { isEmpty, map } from 'lodash'

import { IProductSimple } from '~/api/dataTypes/product'
import { ProductBlock } from '~/components/core/productBlock'
import { Carousel } from '~/components/core/carousel'
import { IProductsCarouselProps, PRODUCTS_CAROUSEL_PARAMS } from '~/components/core/productsCarousel'

import styles from './ProductsCarousel.module.scss'

const ProductsCarousel = (props: IProductsCarouselProps): ReactElement | null => {
	const {
		products,
		title = '',
		hasWishList = true,
		params = {},
		theme = {},
		additionalClass = '',
	} = props

	const carouselParams: SwiperOptions = {
		...PRODUCTS_CAROUSEL_PARAMS,
		...params,
	}

	const slideClass = classNames(styles.slide, theme.slide)

	const renderProducts = useCallback((): ReactElement[] => (
		map(products, (product: IProductSimple) => (
			<SwiperSlide
				key={ product.id }
				className={ slideClass }
			>
				<ProductBlock
					product={ product }
					hasWishList={ hasWishList }
					theme={ theme }
					tag="h3"
				/>
			</SwiperSlide>
		))
	), [products, hasWishList, theme, title])

	if (isEmpty(products)) {
		return null
	}

	return (
		<Carousel
			title={ title }
			params={ carouselParams }
			additionalClass={ additionalClass }
			theme={ { wrapper: styles.wrapper } }
		>
			{ renderProducts() }
		</Carousel>
	)
}

export { ProductsCarousel }
