import { IAccountOrdersState } from '~/state/reducers/accountReducer'

export const accountOrdersInitialState: IAccountOrdersState = {
	list: {
		orders: {
			content: [],
			pageable: {
				pageNumber: 0,
				pageSize: 10,
				totalElements: 0,
				totalPages: 0,
				firstPage: true,
				lastPage: true,
				hasNext: true,
				hasPrevious: true,
			},
		},
		metadata: {
			orderCount: 0,
			orderCountByChannel: {
				internet: 0,
				store: 0,
			},
		},
	},
	paymentWays: [],
	storeDetails: null,
	details: null,
	activeDetailsId: null,
	isListPending: false,
	isDetailsPending: false,
	isPaymentWaysPending: false,
	isStorePending: false,
}
