import React, { ReactElement } from 'react'

import { ICartHeaderStepProps, CartHeaderStepLine, CartHeaderStepTitle } from '~/components/core/cartHeader'

import styles from './CartHeaderStep.module.scss'

const CartHeaderStep = (props: ICartHeaderStepProps): ReactElement => {
	const { step, hasStepLine } = props

	return (
		<div className={ styles.wrapper }>
			<CartHeaderStepTitle step={ step } />

			<CartHeaderStepLine hasStepLine={ hasStepLine } isPrevious={ step.isPrevious } />
		</div>
	)
}

export { CartHeaderStep }
