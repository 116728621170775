import React, { ReactElement } from 'react'
import { isEmpty } from 'lodash'

import { HeaderTopBarSearchBarNodesList, IHeaderTopBarSearchBarNodesProps } from '~/components/core/layout'

const HeaderTopBarSearchBarNodes = (props: IHeaderTopBarSearchBarNodesProps): ReactElement | null => {
	const { nodes, query, onHideSuggestions } = props

	if (isEmpty(nodes)) {
		return null
	}

	return (
		<HeaderTopBarSearchBarNodesList
			nodes={ nodes }
			query={ query }
			onHideSuggestions={ onHideSuggestions }
		/>
	)
}

export { HeaderTopBarSearchBarNodes }
