import React, { ReactElement } from 'react'

import { ICartStepsHeaderProps } from '~/components/cart/cartStepsHeader'
import { CartHeader } from '~/components/core/cartHeader'
import { useCartHeader } from '~/hooks/cartHeader'

const CartStepsHeader = (props: ICartStepsHeaderProps): ReactElement => {
	const { cartStatus, additionalClass } = props
	const { headerItems } = useCartHeader(cartStatus)

	return (
		<CartHeader headerItems={ headerItems } additionalClass={ additionalClass } />
	)
}

export { CartStepsHeader }
