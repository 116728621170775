import { IAccountReturnsState } from './types'

export const accountReturnsInitialState: IAccountReturnsState = {
	list: {
		items: [],
		pagination: {
			totalItems: 0,
			page: 1,
			totalPages: 1,
			perPage: 10,
		},
	},
	orders: [],
	application: {
		orderNumber: '',
		customer: {
			firstName: '',
			lastName: '',
			email: '',
			delivery: {
				firstName: '',
				lastName: '',
				street: '',
				postalCode: '',
				city: '',
				phoneNumber: '',
			},
		},
		products: [],
	},
}
