import React, { ReactElement, useContext } from 'react'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { NewsletterWrapper, NewsletterCaption, NewsletterContentWrapper, NewsletterIcons, NewsletterForm } from '~/components/core/newsletter'

const Newsletter = (): ReactElement => {
	const { personalDataProcessingChecks: { newsletterChecks } } = useContext(AppParametersContext)

	return (
		<NewsletterWrapper>
			<NewsletterCaption />

			<NewsletterContentWrapper>
				<NewsletterIcons />

				<NewsletterForm agreements={ newsletterChecks } />
			</NewsletterContentWrapper>
		</NewsletterWrapper>
	)
}

export { Newsletter }
