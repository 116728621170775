import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IProductBlockMiniWrapperProps } from '~/components/core/productBlock'

import styles from './ProductBlockMiniWrapper.module.scss'

const ProductBlockMiniWrapper = (props: IProductBlockMiniWrapperProps): ReactElement => {
	const {
		children,
		dataTestId = '',
		additionalClass = '',
	} = props

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass } data-testid={ dataTestId }>
			{ children }
		</div>
	)
}

export { ProductBlockMiniWrapper }
