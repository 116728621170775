import React, { ReactElement, useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'

import { CartCustomerInvoiceReceiptModalActions, ICartCustomerInvoiceReceiptForm, ICartCustomerInvoiceReceiptModalProps } from '~/components/cart/cartCustomer'
import { Input } from '~/components/core/form'
import { Modal } from '~/components/core/modal'
import { isValidNip } from '~/utils/string'
import { validation } from '~/utils/validation'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { addReceiptNip } from '~/actions/cartCustomer'
import { ICartCustomerReceiptNip } from '~/api/dataTypes/cart'
import { CustomerType } from '~/api/dataTypes/customer'

import styles from './CartCustomerInvoiceReceiptModal.module.scss'

const CartCustomerInvoiceReceiptModal = (props: ICartCustomerInvoiceReceiptModalProps): ReactElement => {
	const { isOpen, onClose, nipInvoiceThresholdAmount, receiptNip } = props
	const { t, i18n } = useTranslation(['cart', 'form'])
	const dispatch: AppDispatch = useDispatch()
	const { data: userData } = useSelector((state: IRootState) => state.customer, shallowEqual)

	const { mask: { nip } } = validation
	const isCustomerTypeCompany = isEqual(userData?.customerType, 'COMPANY' as CustomerType)

	const nipSchema = useMemo(() => yup.string().test('isValidNip', t('validation.nipFormat', { ns: 'form' }), (value: string | undefined) => isValidNip(value)).required(t('required', { ns: 'form' })), [t, i18n])

	const schema: yup.SchemaOf<ICartCustomerInvoiceReceiptForm> = useMemo(() => yup.object().shape({
		nip: nipSchema,
	}), [])

	const { control, handleSubmit } = useForm<ICartCustomerInvoiceReceiptForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			nip: receiptNip || '',
		},
		mode: 'onChange',
	})

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerInvoiceReceiptForm): Promise<void> => {
		const { nip } = formData

		const params: ICartCustomerReceiptNip = {
			nip,
			invoiceExpectation: 'NO',
		}

		await dispatch(addReceiptNip(params))
		onClose()
	}), [])

	return (
		<Modal
			title={ t('customer.invoice.receiptModal.title') }
			additionalClass={ styles.wrapper }
			isOpen={ isOpen }
			onClose={ onClose }
		>
			<div className={ styles.content }>
				<div className={ styles.description }>
					{ t('customer.invoice.receiptModal.description') }
				</div>

				{ isCustomerTypeCompany && (
					<div className={ styles.description }>
						{ t('customer.invoice.receiptModal.descriptionCompanyPartOne') }

						<span className={ styles.highlight }>
							{ t('customer.invoice.receiptModal.descriptionCompanyPartTwo', { nipInvoiceThresholdAmount }) }
						</span>

						{ t('customer.invoice.receiptModal.descriptionCompanyPartThree') }
					</div>
				) }

				<Input
					name="nip"
					label={ t('customer.invoice.receiptModal.nipLabel') }
					control={ control }
					mask={ nip }
					additionalClass={ styles.control }
				/>

				<CartCustomerInvoiceReceiptModalActions onSubmit={ handleFormSubmit } onCancel={ onClose } />

			</div>
		</Modal>
	)
}

export { CartCustomerInvoiceReceiptModal }
