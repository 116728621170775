import { useCallback } from 'react'
import { isNull } from 'lodash'

import { IUseSareData, getSareClientData, getSareClientEmail, getSareProductByCart, getSareProductByCatalogSearch, getSareProductByOrder, getSareProductByProductSimple } from '~/hooks/sare'
import { ISareProductEvent, sare } from '~/utils/sare'
import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'
import { ICartItemData } from '~/api/dataTypes/cart'
import { ICatalogSearchProductItemPage } from '~/api/dataTypes/catalog'
import { ICustomerResult } from '~/api/dataTypes/customer'
import { IProductSimple } from '~/api/dataTypes/product'

export const useSare = (): IUseSareData => {
	const handleCookieConsentReady = useCallback((callback: () => void): void => {
		if (window.OneTrust?.OnConsentChanged) {
			callback()

			return
		}

		const oneTrustInterval = setInterval(() => {
			if (window.OneTrust?.OnConsentChanged) {
				clearInterval(oneTrustInterval)

				callback()
			}
		}, 300)
	}, [])

	const SARE_productEvent = useCallback((sareEventData: ISareProductEvent): void => {
		handleCookieConsentReady(() => sare.productEvents(sareEventData))
	}, [])

	const SARE_productView = useCallback((product: IProductSimple): void => {
		const sareEventData = getSareProductByProductSimple(product, 'product_view')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_productsView = useCallback((itemPage: ICatalogSearchProductItemPage): void => {
		const sareEventData = getSareProductByCatalogSearch(itemPage, 'products_view')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_search = useCallback((itemPage: ICatalogSearchProductItemPage): void => {
		const sareEventData = getSareProductByCatalogSearch(itemPage, 'search')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_addToCart = useCallback((product: IProductSimple): void => {
		const sareEventData = getSareProductByProductSimple(product, 'add_to_cart')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_addToWishList = useCallback((product: IProductSimple): void => {
		const sareEventData = getSareProductByProductSimple(product, 'add_to_wishlist')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_checkoutView = useCallback((itemList: ICartItemData[], summaryValue: number): void => {
		const sareEventData = getSareProductByCart(itemList, summaryValue, 'checkout_view')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_paymentInfo = useCallback((itemList: ICartItemData[], summaryValue: number): void => {
		const sareEventData = getSareProductByCart(itemList, summaryValue, 'payment_info')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_purchase = useCallback((order: IOrderDetailsResponse): void => {
		const sareEventData = getSareProductByOrder(order, 'purchase')

		SARE_productEvent(sareEventData)
	}, [])

	const SARE_customerEmail = useCallback((clientData: ICustomerResult | null): void => {
		if (isNull(clientData)) {
			return
		}

		const sareEventData = getSareClientEmail(clientData)

		handleCookieConsentReady(() => sare.customerEmailEvents(sareEventData))
	}, [])

	const SARE_customerData = useCallback((clientData: ICustomerResult | null): void => {
		if (isNull(clientData)) {
			return
		}

		const sareEventData = getSareClientData(clientData)

		handleCookieConsentReady(() => sare.customerDataEvents(sareEventData))
	}, [])

	return {
		SARE_productView,
		SARE_productsView,
		SARE_search,
		SARE_addToCart,
		SARE_addToWishList,
		SARE_checkoutView,
		SARE_paymentInfo,
		SARE_purchase,
		SARE_customerEmail,
		SARE_customerData,
	}
}
