import React, { ReactElement } from 'react'

import { IHeaderTopBarPreferredStoreSelectedProps } from '~/components/core/layout'
import { PreferredStoreInfo } from '~/components/core/preferredStore'

import styles from './HeaderTopBarPreferredStoreSelected.module.scss'

const HeaderTopBarPreferredStoreSelected = (props: IHeaderTopBarPreferredStoreSelectedProps): ReactElement => {
	const { preferredStore, onChange, onNavigate } = props

	return (
		<div className={ styles.wrapper }>
			<PreferredStoreInfo
				store={ preferredStore }
				additionalActionsClass={ styles.actions }
				onChange={ onChange }
				onNavigate={ onNavigate }
			/>
		</div>
	)
}

export { HeaderTopBarPreferredStoreSelected }
