import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Icon } from '~/components/core/icon'
import { ITooltipIconProps } from '~/components/core/tooltip'
import { useMozaic } from '~/hooks/mozaic'

import styles from './TooltipIcon.module.scss'

const TooltipIcon = (props: ITooltipIconProps): ReactElement => {
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()
	const {
		onSetRef, CustomTriggerComponent, width, height,
		theme = {},
		ariaLabel = t('tooltip.triggerLabel'),
	} = props

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const iconClass = classNames(styles.icon, theme.icon)
	const wrapperClass = classNames(styles.wrapper, theme.wrapper)
	const iconName = shouldUseMozaic ? 'mozaicInformation' : 'errorIcon'
	const iconColor = shouldUseMozaic ? 'mozaicPrimary01600' : 'green'

	const renderContent = (): ReactNode => (
		CustomTriggerComponent ? (
			CustomTriggerComponent
		) : (
			<Icon
				name={ iconName }
				width={ width }
				height={ height }
				color={ iconColor }
				additionalClass={ iconClass }
			/>
		)
	)

	return (
		<div
			ref={ onSetRef }
			aria-label={ ariaLabel }
			role="button"
			className={ wrapperClass }
		>
			{ renderContent() }
		</div>
	)
}

export { TooltipIcon }
