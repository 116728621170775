import dayjs from 'dayjs'
import dayjsLocaleData from 'dayjs/plugin/localeData'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { includes, split } from 'lodash'

const getShortLang = (lang: string): string => {
	if (!includes(lang, '-')) {
		return lang
	}

	return split(lang, '-')[0]
}

export const importDayjsLocale = async (lang: string): Promise<void> => {
	dayjs.extend(dayjsLocaleData)

	try {
		await import(`dayjs/locale/${ lang }.js`)
		dayjs.locale(lang)
	} catch (e: unknown) {
		const shortLang = getShortLang(lang)

		if (shortLang !== lang) {
			await importDayjsLocale(shortLang)
		}
	}
}

export const importReactDatepickerLocale = async (lang: string): Promise<void> => {
	try {
		const locale = await import(`date-fns/locale/${ lang }/index.js`)

		registerLocale(lang, locale)
		setDefaultLocale(lang)
	} catch (e: unknown) {
		const shortLang = getShortLang(lang)

		if (shortLang !== lang) {
			await importReactDatepickerLocale(shortLang)
		}
	}
}
