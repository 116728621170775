import { useCallback } from 'react'
import axios, { AxiosError } from 'axios'

import { postErrorLog } from '~/api/requests/log'
import { log } from '~/utils/logger'
import { IUseLogErrorData } from '~/hooks/logError'
import { vars } from '~/statics'

export const useLogError = (): IUseLogErrorData => {
	const { isDeveloperMode } = vars

	const sendLogError = useCallback(async (error: AxiosError | unknown): Promise<void> => {
		if (axios.isAxiosError(error) && !isDeveloperMode) {
			await postErrorLog(log.axiosError(error))
		}
	}, [isDeveloperMode])

	return {
		sendLogError,
	}
}
