import { AppPromiseThunkAction } from '~/actions'
import { withFresherToken } from '~/actions/auth'
import { FavouriteListActionType, GetFavouriteListActionType, AddToFavouriteListActionType, RemoveFromFavouriteListActionType, GetFavouriteListSimpleActionType, TransferProductToCartActionType } from '~/actions/favouriteList'
import { IFavouriteListParams, IFavouriteListTransferProductsParams } from '~/api/dataTypes/favouriteList'
import { getFavouriteList, getFavouriteListSimple, postAddToFavouriteList, postTransferToCart, deleteFromFavouriteList } from '~/api/requests/favouriteList'

// eslint-disable-next-line @typescript-eslint/typedef
export const getFavouriteListData = withFresherToken((): AppPromiseThunkAction<GetFavouriteListActionType> => async (dispatch) => {
	return dispatch({
		type: FavouriteListActionType.GET_FAVOURITE_LIST,
		payload: getFavouriteList(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getFavouriteListSimpleData = withFresherToken((): AppPromiseThunkAction<GetFavouriteListSimpleActionType> => async (dispatch) => {
	return dispatch({
		type: FavouriteListActionType.GET_FAVOURITE_LIST_SIMPLE,
		payload: getFavouriteListSimple(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const addToFavouriteList = withFresherToken((params: IFavouriteListParams): AppPromiseThunkAction<AddToFavouriteListActionType> => async (dispatch) => {
	return dispatch({
		type: FavouriteListActionType.ADD_PRODUCT_TO_FAVOURITE_LIST,
		payload: postAddToFavouriteList(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const transferProductToCart = withFresherToken((params: IFavouriteListTransferProductsParams): AppPromiseThunkAction<TransferProductToCartActionType> => async (dispatch) => {
	return dispatch({
		type: FavouriteListActionType.TRANSFER_PRODUCT_TO_CART,
		payload: postTransferToCart(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const removeFromFavouriteList = withFresherToken((lmReference: string): AppPromiseThunkAction<RemoveFromFavouriteListActionType> => async (dispatch) => {
	return dispatch({
		type: FavouriteListActionType.REMOVE_PRODUCT_FROM_FAVOURITE_LIST,
		payload: deleteFromFavouriteList(lmReference),
	})
})
