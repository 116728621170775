import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Icon } from '~/components/core/icon'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewSummaryInfo.module.scss'

const CartPreviewSummaryInfo = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const iconName = shouldUseMozaic ? 'mozaicNotificationCircleInformation' : 'infoCircle'
	const iconColor = shouldUseMozaic ? 'mozaicGrey600' : 'navy'

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<div className={ styles.iconWrapper }>
				<Icon
					name={ iconName }
					width={ 16 }
					color={ iconColor }
				/>
			</div>

			<div className={ styles.content }>
				{ t('preview.aside.info.content') }
			</div>
		</div>
	)
}

export { CartPreviewSummaryInfo }
