import React, { ReactElement, useCallback, useMemo } from 'react'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import axios, { AxiosError } from 'axios'
import { isUndefined, map } from 'lodash'

import { AppDispatch } from '~/state/store'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { Modal } from '~/components/core/modal'
import { RequiredInfo } from '~/components/core/form'
import { ICartCustomerDataForm, ICartCustomerDataMainAddressFormProps, CartCustomerDataFormFields, getCartCustomerUpdatedData, CartCustomerFormButtons, CartCustomerFormHeader } from '~/components/cart/cartCustomer'
import { patchCustomerData } from '~/actions/customer'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { hasNotNumberAndAllowOnlyDash, hasNumber, hasNumberOrEmpty, hasOnlyLetters, hasOnlyLettersNumbersSpacesDotsOrDash, hasOnlyLettersSpacesOrDash } from '~/utils/string'

import styles from './CartCustomerDataMainAddressForm.module.scss'

const CartCustomerDataMainAddressForm = (props: ICartCustomerDataMainAddressFormProps): ReactElement => {
	const { isOpen, customer, customerType, onOpen, onClose, onUpdate } = props
	const { t, i18n } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const { newAlert } = useAlert()
	const { sendLogError } = useLogError()

	const { firstName, lastName, nip, corporateName, mainAddress } = customer

	const firstNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLetters', t('validation.unexpectedCharacters', { field: t('customer.orderCollection.form.firstNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLetters(value)).required(t('customer.clientData.form.validation.firstNameRequired', { ns: 'cart' })), [t, i18n])
	const lastNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasNotNumberAndAllowOnlyDash', t('validation.unexpectedCharacters', { field: t('customer.orderCollection.form.lastNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasNotNumberAndAllowOnlyDash(value)).required(t('customer.clientData.form.validation.lastNameRequired', { ns: 'cart' })), [t, i18n])
	const houseNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).required(t('customer.clientData.form.validation.houseNumberRequired', { ns: 'serviceCart' })).test('hasNumber', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.houseNumber', { ns: 'serviceCart' }), ns: 'form' }), (value: string | undefined): boolean => hasNumber(value)), [t, i18n])
	const flatNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).defined().test('hasNumberOrEmpty', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.flatNumber', { ns: 'serviceCart' }), ns: 'form' }), (value: string | undefined): boolean => hasNumberOrEmpty(value)), [t, i18n])
	const citySchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLettersOrSpaces', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.city', { ns: 'serviceCart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersSpacesOrDash(value)).required(t('customer.clientData.form.validation.cityRequired', { ns: 'serviceCart' })), [t, i18n])
	const streetSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(42, t('mustBeShortestThen42', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.street', { ns: 'serviceCart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('customer.clientData.form.validation.streetRequired', { ns: 'serviceCart' })), [t, i18n])

	const schema: yup.SchemaOf<ICartCustomerDataForm> = useMemo(() => yup.object().shape({
		firstName: firstNameSchema,
		lastName: lastNameSchema,
		mainAddress: yup.object().shape({
			country: yup.string().required(t('customer.clientData.form.validation.countryRequired')),
			postalCode: yup.string().required(t('customer.clientData.form.validation.zipCodeRequired')),
			city: citySchema,
			street: streetSchema,
			houseNumber: houseNumberSchema,
			flatNumber: flatNumberSchema,
		}),
	}), [t, i18n])

	const formProps = useForm<ICartCustomerDataForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: firstName || '',
			lastName: lastName || '',
			mainAddress: {
				country: 'PL',
				postalCode: mainAddress?.postalCode || '',
				city: mainAddress?.city || '',
				street: mainAddress?.street || '',
				houseNumber: mainAddress?.houseNumber || '',
				flatNumber: mainAddress?.flatNumber || '',
			},
		},
		mode: 'onChange',
	})

	const { formState: { isSubmitting }, handleSubmit } = formProps

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerDataForm): Promise<void> => {
		try {
			const requestData = getCartCustomerUpdatedData({ formData, customerType, nip, corporateName })

			await dispatch(patchCustomerData(requestData))

			await onUpdate(requestData)
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					formProps.setError(field as FieldPath<ICartCustomerDataForm>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				newAlert('danger', getErrorMessage(code), 5000)
			}

			sendLogError(e)
		}
	}), [customer, customerType, onUpdate])

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
			onOpen={ onOpen }
		>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<FormProvider { ...formProps }>
				<form onSubmit={ handleFormSubmit }>
					<CartCustomerFormHeader title={ t('customer.clientData.form.editTitle') } />

					<CartCustomerDataFormFields />

					<RequiredInfo />

					<CartCustomerFormButtons isPending={ isSubmitting } onCancel={ onClose } />
				</form>
			</FormProvider>
		</Modal>
	)
}

export { CartCustomerDataMainAddressForm }
