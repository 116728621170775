import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import { gt, replace, size } from 'lodash'

import { Input, IRadioOption, RadioGroup, RequiredInfo, Select } from '~/components/core/form'
import { ICartCustomerTitle } from '~/api/dataTypes/cart'
import { CartCustomerGuestFormAgreements, CartCustomerGuestFormEmail } from '~/components/cart/cartCustomer'
import { validation } from '~/utils/validation'
import { useCountries } from '~/hooks/countries'
import { ICartCustomerGuestPrivateForm } from '~/hooks/cartGuestCustomerForm'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './CartCustomerGuestFormPrivate.module.scss'

const CartCustomerGuestFormPrivate = (): ReactElement => {
	const { t, i18n } = useTranslation(['cart', 'common', 'form'])
	const { countriesSelectOptions } = useCountries()
	const { control, setValue } = useFormContext<ICartCustomerGuestPrivateForm>()
	const { personalDataProcessingChecks: { orderChecks } } = useContext(AppParametersContext)

	const { mask: { zipCode, phone }, regex: { onlyDigits, phoneWithoutAreaCode } } = validation

	const phonePlaceholder = replace(phone, /0/g, '_')

	const customerTypeOptions: IRadioOption<ICartCustomerTitle>[] = useMemo(() => [{
		label: t('customer.guest.form.mrs'),
		value: 'MS',
	}, {
		label: t('customer.guest.form.mr'),
		value: 'MR',
	}], [t, i18n])

	const handleChangePhone = useCallback((value: string): void => {
		let inputValue = value
		const maskSize = size(phone)

		if (gt(size(value), maskSize)) {
			inputValue = replace(value, phoneWithoutAreaCode, '')
		}

		setValue('phoneNumber', replace(inputValue, onlyDigits, '').substring(0, maskSize))
	}, [])

	return (
		<div className={ styles.wrapper }>
			<RadioGroup
				control={ control }
				name="customerTitle"
				options={ customerTypeOptions }
			/>

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.firstNameLabel') }
				name="firstName"
			/>

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.lastNameLabel') }
				name="lastName"
			/>

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.streetLabel') }
				name="street"
			/>

			<div className={ styles.address }>
				<Input
					isRequired
					control={ control }
					label={ t('customer.guest.form.houseNumberLabel') }
					name="houseNumber"
					additionalClass={ styles.control }
				/>

				<Input
					control={ control }
					label={ t('customer.guest.form.flatNumberLabel') }
					name="flatNumber"
					additionalClass={ styles.control }
				/>
			</div>

			<div className={ styles.postal }>
				<Input
					isRequired
					control={ control }
					label={ t('customer.guest.form.zipCodeLabel') }
					mask={ zipCode }
					name="postalCode"
					additionalClass={ styles.zip }
				/>

				<Input
					isRequired
					control={ control }
					label={ t('customer.guest.form.cityLabel') }
					name="city"
					additionalClass={ styles.city }
				/>
			</div>

			<Select
				isRequired
				isDisabled
				instanceId="customer-guest-country"
				options={ countriesSelectOptions }
				name="country"
				label={ t('customer.guest.form.countryLabel') }
				placeholder={ t('customer.guest.form.countryPlaceholder') }
				control={ control }
			/>

			<CartCustomerGuestFormEmail setPrivateValue={ setValue } />

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.phoneNumberLabel') }
				name="phoneNumber"
				type="tel"
				prefix={ t('phoneNumberPrefix', { ns: 'common' }) }
				placeholder={ phonePlaceholder }
				onChange={ handleChangePhone }
			/>

			<CartCustomerGuestFormAgreements agreements={ orderChecks } />

			<RequiredInfo />
		</div>
	)
}

export { CartCustomerGuestFormPrivate }
