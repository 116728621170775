import React, { ReactElement } from 'react'

import { ICartCustomerWrapperProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerWrapper.module.scss'

const CartCustomerWrapper = (props: ICartCustomerWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { CartCustomerWrapper }
