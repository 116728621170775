import React, { ReactElement } from 'react'
import { isNull } from 'lodash'

import { IAddToCartModalProductBlockProps, AddToCartModalProductImage, AddToCartModalProductInfo } from '~/components/core/addToCartModal'

import styles from './AddToCartModalProductBlock.module.scss'

const AddToCartModalProductBlock = (props: IAddToCartModalProductBlockProps): ReactElement | null => {
	const { productSimple, quantity } = props

	if (isNull(productSimple)) {
		return null
	}

	const { name, image: { thumbnailUrl } } = productSimple

	return (
		<div className={ styles.wrapper }>
			<AddToCartModalProductImage alt={ name } imageUrl={ thumbnailUrl } />

			<AddToCartModalProductInfo product={ productSimple } quantity={ quantity } />
		</div>
	)
}

export { AddToCartModalProductBlock }
