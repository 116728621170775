import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import { useAdeoSignIn } from '~/hooks/signIn'
import { Button } from '~/components/core/button'
import { ISignInAdeoProps } from '~/components/signIn'

import styles from './SignInAdeo.module.scss'

const SignInAdeo = (props: ISignInAdeoProps): ReactElement => {
	const {
		caption,
		theme = {},
	} = props
	const { handleSignInByAdeo } = useAdeoSignIn()
	const { asPath } = useRouter()
	const { t } = useTranslation(['common'])

	const handleClick = useCallback(async (): Promise<void> => {
		await handleSignInByAdeo(asPath)
	}, [asPath])

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div className={ wrapperClass }>
			<h2 className={ styles.caption }>
				{ caption }
			</h2>

			<Button
				type="button"
				variant="secondary"
				text={ t('signInRedirection') }
				additionalClass={ styles.button }
				onClick={ handleClick }
			/>
		</div>
	)
}

export { SignInAdeo }
