import { gte, isNull, isUndefined, toString } from 'lodash'

import { WORLDS_SUB_CATEGORY_LIST_QUERY, TAB_INDEX_ATTRIBUTE, WORLDS_WRAPPER_ID, WORLDS_SELECTED_MAIN_CATEGORY_QUERY, WORLDS_TYPE_MEGAWORLD, WORLDS_DEFAULT_TABINDEX } from '~/components/core/megamenu'
import { IProductCatalogData } from '~/api/dataTypes/catalog'
import { ALL_FOCUSABLE_SELECTOR_QUERY } from '~/hooks/skipElements'

export const isMegaWorld = (item: IProductCatalogData): boolean => (
	item.templateType === WORLDS_TYPE_MEGAWORLD
)

export const setElementsTabIndex = (element: HTMLElement, tabIndex: number): void => {
	const fixedTabIndex = toString(tabIndex)

	element.setAttribute(TAB_INDEX_ATTRIBUTE, fixedTabIndex)
}

export const focusToActiveElement = (): void => {
	const activeElement = document.querySelector(WORLDS_SELECTED_MAIN_CATEGORY_QUERY) as HTMLAnchorElement

	setElementsTabIndex(activeElement, WORLDS_DEFAULT_TABINDEX)
	activeElement?.focus()
}

export const focusToFirstChildOfActiveElement = (activeClientSideIndex: number, anchorElement?: HTMLAnchorElement): void => {
	const elementToFocus = getElementToFocus(activeClientSideIndex, anchorElement)

	if (!isNull(elementToFocus)) {
		elementToFocus?.focus()
	}
}

const getElementToFocus = (activeClientSideIndex: number, anchorElement: HTMLAnchorElement | undefined): HTMLAnchorElement | null => {
	if (!isNull(anchorElement) && !isUndefined(anchorElement)) {
		setElementsTabIndex(anchorElement, WORLDS_DEFAULT_TABINDEX)

		return anchorElement
	} else if (gte(activeClientSideIndex, 0)) {
		return getFocusableElementViaSelector(activeClientSideIndex)
	}

	return null
}

const getFocusableElementViaSelector = (activeIndex: number): HTMLAnchorElement | null => {
	const megaMenuWrapper = document.getElementById(WORLDS_WRAPPER_ID)
	const visibleSubCategoriesList = megaMenuWrapper?.querySelectorAll(WORLDS_SUB_CATEGORY_LIST_QUERY)[activeIndex]
	const focusableElement = visibleSubCategoriesList?.querySelector(ALL_FOCUSABLE_SELECTOR_QUERY)

	if (isNull(focusableElement) || isUndefined(focusableElement)) {
		return null
	}

	return focusableElement as HTMLAnchorElement
}
