import { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { isEmpty, isNull, isUndefined } from 'lodash'

import { useCartStatus } from '~/hooks/cartStatus'
import { ICartGuestCustomerData } from '~/hooks/cartGuestCustomer'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { IRootState } from '~/state/types'
import { getCustomerGuestContact } from '~/components/cart/cartCustomer'
import { ICartCustomerGuestData, ICartCustomerNextParams } from '~/api/dataTypes/cart'
import { postCartCustomerNext } from '~/api/requests/cart'

const useCartGuestCustomer = (): ICartGuestCustomerData => {
	const { data, isAnotherReceiverActive } = useSelector((state: IRootState) => state.cartCustomer, shallowEqual)
	const { changeCartView } = useCartStatus()
	const [isNextLoading, setIsNextLoading] = useToggle(false)
	const { preventAlert } = useAlert()
	const { sendLogError } = useLogError()

	const { customerOtherContact, receiptNip, invoice } = data

	const handleNext = useCallback(async (customerGuest: ICartCustomerGuestData): Promise<void> => {
		if (!isEmpty(customerGuest.phone)) {
			setIsNextLoading(true)

			const contact = isAnotherReceiverActive ? customerOtherContact : getCustomerGuestContact(customerGuest)
			const { regulations, ...customer } = customerGuest

			if (!isNull(contact) && !isNull(invoice)) {
				const params: ICartCustomerNextParams = {
					customerAddressId: null,
					dataProcessingChecks: !isUndefined(regulations) && regulations ? ['ORDER_PROCESSING'] : [],
					contact,
					customer,
					invoice,
					receiptNip: receiptNip.nip || null,
				}

				try {
					const { data } = await postCartCustomerNext(params)

					await changeCartView(3, data.cartStatus)
				} catch (e: unknown) {
					preventAlert(e)

					setIsNextLoading(false)
					sendLogError(e)
				}
			}
		}
	}, [invoice, data, receiptNip, isAnotherReceiverActive, customerOtherContact])

	return {
		onNext: handleNext,
		isNextLoading,
	}
}

export { useCartGuestCustomer }
