import { Draft, produce } from 'immer'
import { isNil } from 'lodash'

import { AppPromiseAction } from '~/actions'
import { ProductCardActionType, ProductCardActionUnionType } from '~/actions/productCard'
import { SUCCESS } from '~/statics'

import { productCardStoresInitialState as initialState } from './constants'
import { ProductCardStoresState } from './types'

export const productCardStores = produce((draft: Draft<ProductCardStoresState>, action: AppPromiseAction<ProductCardActionUnionType>): ProductCardStoresState => {
	switch (action.type) {
		case SUCCESS(ProductCardActionType.GET_STORE): {
			const { payload, meta } = action
			const { data } = payload

			if (isNil(data) || isNil(meta) || isNil(meta.storeId)) {
				return draft
			}

			draft[meta.storeId] = data

			return draft
		}

		case SUCCESS(ProductCardActionType.CLEAN_UP): {
			return initialState
		}

		default:
			return draft
	}
}, initialState)
