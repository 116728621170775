import React, { ReactElement, useCallback } from 'react'

import { BreadcrumbItem, IBreadcrumbProps } from '~/components/core/breadcrumbs'
import { Link } from '~/components/core/link'

import styles from './Breadcrumb.module.scss'

const Breadcrumb = (props: IBreadcrumbProps): ReactElement => {
	const { breadcrumb, position, isLast } = props

	const {
		label,
		url = '',
	} = breadcrumb

	const renderBreadcrumb = useCallback((): ReactElement => {
		if (isLast) {
			return (
				<BreadcrumbItem
					isLast={ isLast }
					label={ label }
					position={ position }
				/>
			)
		}

		return (
			<Link
				passHref
				href={ url }
				itemProp="item"
				ariaLabel={ label }
			>
				<BreadcrumbItem label={ label } position={ position } />
			</Link>
		)
	}, [isLast])

	return (
		<div className={ styles.breadcrumb }>
			{ renderBreadcrumb() }
		</div>
	)
}

export { Breadcrumb }
