import { ICartItemData } from '~/api/dataTypes/cart'

export interface ICartPreviewProductsQuantityBlockProps {
	quantity: number
	item: ICartItemData
	priceUnit: string
	isNotEnoughItem: boolean
}

export enum BasePriceUnitEnum {
	SQUARE_METERS = 'm2',
}
