import { IAccountState } from '~/state/reducers/accountReducer'

import { accountReviewsInitialState } from './accountReviewsReducer'
import { accountOrdersInitialState } from './accountOrdersReducer'
import { accountLoyaltyProgramInitialState } from './accountLoyaltyProgramReducer'
import { accountReturnsInitialState } from './accountReturnsReducer/constants'
import { accountProjectsInitialState } from './accountProjectsReducer'

export const accountInitialState: IAccountState = {
	reviews: accountReviewsInitialState,
	orders: accountOrdersInitialState,
	loyaltyProgram: accountLoyaltyProgramInitialState,
	returns: accountReturnsInitialState,
	projects: accountProjectsInitialState,
}
