import React, { ReactElement } from 'react'
import { noop } from 'lodash'

import { IHeaderTopBarEmptyListProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'

import styles from './HeaderTopBarEmptyList.module.scss'

const HeaderTopBarEmptyList = (props: IHeaderTopBarEmptyListProps): ReactElement => {
	const {
		title, subTitle, buttonText, onBlur,
		onClick = noop,
	} = props

	return (
		<section className={ styles.wrapper }>
			<span className={ styles.caption }>
				{ title }
			</span>

			<span className={ styles.question }>
				{ subTitle }
			</span>

			<Button
				isReverse
				variant="secondary"
				text={ buttonText }
				size="small"
				onClick={ onClick }
				onBlur={ onBlur }
			/>
		</section>
	)
}

export { HeaderTopBarEmptyList }
