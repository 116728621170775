import { createSelector } from 'reselect'
import { isNil } from 'lodash'

import { IRootState } from '~/state/types'
import { ICustomerOrderDetailsResponse, ICustomerDetailsData, IPickupPointDeliveryWayDetailsData } from '~/api/dataTypes/customerOrder'

export const getOrderDetails = (state: IRootState): ICustomerOrderDetailsResponse | null => (
	state.account.orders.details
)

export const getOrderDetailsCustomerData = createSelector(getOrderDetails, (data: ICustomerOrderDetailsResponse | null): ICustomerDetailsData | null => {
	if (isNil(data)) {
		return null
	}

	const { deliveryOrderDetailsData, pickupOrderDetailsData } = data

	if (!isNil(deliveryOrderDetailsData)) {
		return deliveryOrderDetailsData.customerDetailsData
	}

	if (!isNil(pickupOrderDetailsData) && !isNil(pickupOrderDetailsData.customerDetailsData)) {
		return pickupOrderDetailsData.customerDetailsData
	}

	return null
})

export const getOrderDetailsPickupPointData = createSelector(getOrderDetails, (data: ICustomerOrderDetailsResponse | null): IPickupPointDeliveryWayDetailsData | null => {
	if (isNil(data)) {
		return null
	}

	const { deliveryOrderDetailsData } = data

	return deliveryOrderDetailsData?.pickupPointDeliveryWayDetailsData || null
})

export const getOrderDetailsDeliveryWay = createSelector(getOrderDetails, (data: ICustomerOrderDetailsResponse | null): string | null => {
	if (isNil(data)) {
		return null
	}

	const { pickupOrderDetailsData, deliveryWayDetailsData } = data

	if (!isNil(pickupOrderDetailsData) && !isNil(pickupOrderDetailsData.transportFromStoreDetailsData)) {
		return pickupOrderDetailsData.transportFromStoreDetailsData.categoryName
	}

	return deliveryWayDetailsData.deliveryWayName || null
})

export const isPickupOrder = createSelector(getOrderDetails, (data: ICustomerOrderDetailsResponse | null): boolean => {
	if (isNil(data)) {
		return false
	}

	return !isNil(data.pickupOrderDetailsData)
})

export const getServiceLmRef = createSelector(getOrderDetails, (data: ICustomerOrderDetailsResponse | null): string | null => {
	if (isNil(data) || data.orderTypeSource !== 'SERVICE') {
		return null
	}

	const { orderLines } = data

	return orderLines[0]?.orderItemData?.lmReference || null
})
