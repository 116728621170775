import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { usePrice } from '~/hooks/price'
import { ICartPreviewSummaryFreeDeliveryProps } from '~/components/cart/cartPreview'
import { ProgressBar } from '~/components/core/progressBar'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewSummaryFreeDelivery.module.scss'

const CartPreviewSummaryFreeDelivery = (props: ICartPreviewSummaryFreeDeliveryProps): ReactElement | null => {
	const { summaryPrice, missingValueForDiscount } = props
	const { t } = useTranslation(['cart'])
	const { priceFormat } = usePrice()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const discountRequiredAmount = missingValueForDiscount + summaryPrice

	if (missingValueForDiscount <= 0) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<div className={ styles.textBox }>
				<span className={ styles.valueLabel }>
					{ t('preview.aside.summary.freeDeliveryText') }
				</span>

				<span className={ styles.value }>
					{ priceFormat(missingValueForDiscount, { precision: 2 }) }
				</span>
			</div>

			<ProgressBar
				value={ summaryPrice }
				maxValue={ discountRequiredAmount }
				theme={ { wrapper: styles.progressBar } }
			/>
		</div>
	)
}

export { CartPreviewSummaryFreeDelivery }
