import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { ICartCustomerDataItemButtonProps } from '~/components/cart/cartCustomer'
import { Button } from '~/components/core/button'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerDataItemButton.module.scss'

const CartCustomerDataItemButton = (props: ICartCustomerDataItemButtonProps): ReactElement => {
	const { buttonType, onSelect, onEdit, ariaLabelledBy } = props
	const { t } = useTranslation(['cart'])
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const buttonClass = classNames({
		[styles.wrapper]: true,
		[styles.editButton]: buttonType === 'edit',
		[styles.selectButton]: buttonType === 'select',
		[styles.isMozaic]: shouldUseMozaic,
	})

	const selectButtonClass = classNames(styles.button, {
		'mc-button--bordered': isKobiIntegrationEnabled,
	})

	if (buttonType === 'edit') {
		return (
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ buttonClass }
				ariaLabelledBy={ ariaLabelledBy }
				onClick={ onEdit }
			>
				<span aria-hidden="true">
					{ t('customer.clientData.edit') }
				</span>
			</Button>
		)
	}

	return (
		<Button
			isReverse
			variant="secondary"
			additionalClass={ selectButtonClass }
			ariaLabelledBy={ ariaLabelledBy }
			onClick={ onSelect }
		>
			<span aria-hidden="true">
				{ t('customer.clientData.select') }
			</span>
		</Button>
	)
}

export { CartCustomerDataItemButton }
