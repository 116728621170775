import React, { ReactElement, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'

import { getCartPreviewData, getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { Icon } from '~/components/core/icon'
import { CartPreviewDiscountLysLoyaltyLegendCoupons } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLegend/cartPreviewDiscountLysLoyaltyLegendCoupons'

import styles from './CartPreviewDiscountLysLoyaltyLegend.module.scss'

const CartPreviewDiscountLysLoyaltyLegend = (): ReactElement | null => {
	const { cart: { promotionCodeUsed: isPromotionCodeUsed } } = useSelector(getCartPreviewData, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const { isLysMigrated, isLysCommunicationError, isEditMode, loyaltyType } = useContext(LysLoyaltyProviderContext)
	const { t } = useTranslation(['cart'])

	const shouldRenderLysCouponsInfo = isLysMigrated || (isPromotionCodeUsed && isEcommerce && !isEditMode)

	if (shouldRenderLysCouponsInfo) {
		return <CartPreviewDiscountLysLoyaltyLegendCoupons />
	}

	const descriptionText = isLysCommunicationError ? t('preview.aside.lysDiscount.legend.communicationError') : t('preview.aside.lysDiscount.legend.notMigratedCouponsDescription')

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.caption }>
				{ t('preview.aside.lysDiscount.legend.coupons', { loyaltyType }) }
			</span>

			<div className={ styles.descriptionContainer }>
				<div className={ styles.iconWrapper }>
					<Icon
						name="infoCircle"
						width={ 24 }
						height={ 24 }
						color="black65"
					/>
				</div>

				<span className={ styles.description }>
					{ descriptionText }
				</span>
			</div>
		</div>
	)
}

export { CartPreviewDiscountLysLoyaltyLegend }
