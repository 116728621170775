import { Draft, produce } from 'immer'

import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { AppPromiseAction } from '~/actions'
import { AuthActionType, AuthAdeoActionType } from '~/actions/auth'
import { CustomerActionType, CustomerActionUnionType } from '~/actions/customer'

import { customerInitialState as initialState } from './constants'
import { ICustomerState } from './types'

export const customer = produce((draft: Draft<ICustomerState>, action: AppPromiseAction<CustomerActionUnionType>): ICustomerState => {
	switch (action.type) {
		case REQUEST(CustomerActionType.GET_CUSTOMER):
		case REQUEST(CustomerActionType.REMOVE_CUSTOMER_ADDRESS):
		case REQUEST(CustomerActionType.ADD_CUSTOMER_ADDRESS):
		case REQUEST(CustomerActionType.UPDATE_CUSTOMER_ADDRESS):
		case REQUEST(CustomerActionType.PATCH_CUSTOMER):
			draft.isPending = true

			return draft
		case SUCCESS(CustomerActionType.GET_CUSTOMER):
			draft.isPending = false
			draft.data = action.payload.data

			return draft
		case SUCCESS(CustomerActionType.ADD_CUSTOMER_ADDRESS):
		case SUCCESS(CustomerActionType.UPDATE_CUSTOMER_ADDRESS):
		case SUCCESS(CustomerActionType.PATCH_CUSTOMER):
			draft.isPending = false

			return draft
		case SUCCESS(CustomerActionType.REMOVE_CUSTOMER_ADDRESS):
			draft.isPending = false
			draft.data = action.payload

			return draft
		case FAILURE(CustomerActionType.GET_CUSTOMER):
		case FAILURE(CustomerActionType.REMOVE_CUSTOMER_ADDRESS):
		case FAILURE(CustomerActionType.ADD_CUSTOMER_ADDRESS):
		case FAILURE(CustomerActionType.UPDATE_CUSTOMER_ADDRESS):
		case FAILURE(CustomerActionType.PATCH_CUSTOMER):
			draft.isPending = false

			return draft
		case FAILURE(AuthActionType.SET_AUTHENTICATE_TOKEN):
		case FAILURE(AuthActionType.SET_AUTHENTICATE_REFRESH_TOKEN):
		case SUCCESS(AuthActionType.SET_LOGOUT):
			draft.data = initialState.data

			return draft
		case SUCCESS(AuthAdeoActionType.SET_ADEO_LOGOUT):
			draft.data = initialState.data

			return draft
		default:
			return draft
	}
}, initialState)
