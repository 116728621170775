import React, { ReactElement, createContext, useMemo } from 'react'

import { IIconProviderProps, IIconProviderContextProps } from '~/providers/iconProvider'

const initialData: IIconProviderContextProps = {
	width: '0px',
	height: '0px',
}

export const IconContext = createContext(initialData)

export const IconProvider = (props: IIconProviderProps): ReactElement => {
	const { children, width, height } = props

	const providerValue: IIconProviderContextProps = useMemo(() => ({
		width,
		height,
	}), [width, height])

	return (
		<IconContext.Provider value={ providerValue }>
			{ children }
		</IconContext.Provider>
	)
}
