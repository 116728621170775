import React, { ReactElement, useCallback, useRef } from 'react'
import { isNull } from 'lodash'

import { focusFirstElementAfterSkipAnchor, ISkipContentAnchorProps } from '~/components/core/skipContent'
import { Button } from '~/components/core/button'

const SkipContentAnchor = (props: ISkipContentAnchorProps): ReactElement => {
	const {
		elementId,
		additionalClass = '',
	} = props
	const buttonRef = useRef<HTMLButtonElement | null>(null)

	const handleFocus = useCallback((): void => {
		const { current } = buttonRef

		if (!isNull(current)) {
			focusFirstElementAfterSkipAnchor(current)
		}
	}, [buttonRef])

	return (
		<Button
			isAriaHidden
			buttonRef={ buttonRef }
			id={ elementId }
			tabIndex={ -1 }
			variant="neutral"
			size="inherit"
			additionalClass={ additionalClass }
			onFocus={ handleFocus }
		/>
	)
}

export { SkipContentAnchor }
