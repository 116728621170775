import React, { useCallback, ReactElement } from 'react'
import { map } from 'lodash'

import { Image } from '~/components/core/image'
import { IFooterNavigationBanner } from '~/hooks/footerNavigation'
import { IFooterBannersProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'

import styles from './FooterBanners.module.scss'

const FooterBanners = (props: IFooterBannersProps): ReactElement => {
	const { banners } = props

	const renderBanners = useCallback((): ReactElement[] => (
		map(banners, (banner: IFooterNavigationBanner): ReactElement => {
			const { id, imageUrl, label, url } = banner

			return (
				<Link
					key={ id }
					href={ url }
					theme={ {
						wrapper: styles.banner,
					} }
					target="_blank"
					rel="noopener noreferrer"
					ariaLabel={ label }
				>
					<div className={ styles.imgWrapper }>
						<Image
							fill
							src={ imageUrl }
							alt={ `banner-${ label }` }
							className={ styles.image }
							loading="lazy"
						/>
					</div>

					<div className={ styles.label }>
						{ label }
					</div>
				</Link>
			)
		})
	), [banners])

	return (
		<section className={ styles.wrapper }>
			{ renderBanners() }
		</section>
	)
}

export { FooterBanners }
