import { AxiosResponse } from 'axios'

import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'
import { request } from '~/api/requests/axios'
import { IGetAuthlessOrderDetailsParams } from '~/api/requests/types'
import { getNoCacheParam } from '~/utils/urls'

export const getAuthlessOrderDetails = async (params: IGetAuthlessOrderDetailsParams): Promise<AxiosResponse<IOrderDetailsResponse>> => {
	const {
		orderFrontId,
		isInternal = false,
		isTurbine = false,
		noCache = '',
	} = params

	return await request.get(`/v1/orders/${ orderFrontId }`, {
		service: 'AUTHLESS_ORDER_SERVICE',
		isInternal,
		isTurbine,
		params: getNoCacheParam(noCache),
	})
}

export const putConfirmCashOnDeliveryOrder = async (orderFrontId: string, isInternal: boolean = false): Promise<AxiosResponse> => (
	await request.put(`/v1/orders/${ orderFrontId }/cash-on-delivery-confirmation`, null, {
		service: 'AUTHLESS_ORDER_SERVICE',
		isInternal,
	})
)
