import React, { ReactElement, useCallback, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { isEmpty, isUndefined } from 'lodash'

import { Button } from '~/components/core/button'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { ICartPreviewDiscountLysApplyButtonProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysSubmit/cartPreviewDiscountLysApplyButton'
import { ICartPreviewDiscountLysFormCode, ICartPreviewDiscountLysSelectedReward } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'
import { postCartLoyaltyProgramSelectReward } from '~/api/requests/cart'
import { useCartPreviewCalculate } from '~/hooks/cartPreviewCalculate'

const CartPreviewDiscountLysApplyButton = (props: ICartPreviewDiscountLysApplyButtonProps): ReactElement => {
	const { additionalClass, selectedRewardElement, isRewardInUse, onSetCurrentRewards } = props
	const { selectedReward: { code: rewardCode }, onSetShouldClearInput, onSetEditMode, onSetSelectedReward } = useContext(LysLoyaltyProviderContext)
	const { watch } = useFormContext<ICartPreviewDiscountLysFormCode>()
	const { onRecalculate, onSetPromotionCode } = useCartPreviewCalculate()
	const { t } = useTranslation(['cart'])

	const { promotionCode: promoCode } = watch()

	const handlePostReward = useCallback(async (): Promise<void> => {
		if (!isUndefined(selectedRewardElement) && (!isEmpty(rewardCode) || isRewardInUse)) {
			const newRewardCode = isUndefined(selectedRewardElement.code) ? rewardCode : selectedRewardElement.code

			const { data: { success } } = await postCartLoyaltyProgramSelectReward({
				rewardCode: newRewardCode,
			})

			if (success) {
				await onRecalculate()

				const reward: ICartPreviewDiscountLysSelectedReward = {
					code: selectedRewardElement.code || '',
					type: selectedRewardElement.type || null,
				}

				onSetCurrentRewards([selectedRewardElement])
				onSetSelectedReward(reward)
			}
		}
	}, [selectedRewardElement, rewardCode, onSetCurrentRewards, onRecalculate])

	const handlePostPromotionCode = useCallback(async (): Promise<void> => {
		if (!isUndefined(promoCode) && !isEmpty(promoCode)) {
			await onSetPromotionCode(promoCode)
		}
	}, [promoCode, onSetPromotionCode])

	const handleApplyLoyaltyReward = useCallback(async (): Promise<void> => {
		await handlePostReward()
		await handlePostPromotionCode()
		onSetEditMode(false)

		onSetShouldClearInput(false)
	}, [onSetShouldClearInput, handlePostReward, handlePostPromotionCode])

	const isDisabled = !isRewardInUse && isEmpty(rewardCode) && isEmpty(promoCode)

	return (
		<Button
			variant="secondary"
			additionalClass={ additionalClass }
			isDisabled={ isDisabled }
			text={ t('preview.aside.lysDiscount.submitButton.apply') }
			onClick={ handleApplyLoyaltyReward }
		/>
	)
}

export { CartPreviewDiscountLysApplyButton }
