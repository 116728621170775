import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { ISimpleStoreResponse, IStoreResponse } from '~/api/dataTypes/store'

export type PreferredStoresListGetType = AppAction<PreferredStoreActionType.GET_PREFERRED_STORES_LIST, AxiosResponse<ISimpleStoreResponse[]>>
export type PreferredStoreSetType = AppAction<PreferredStoreActionType.SET_PREFERRED_STORE, IStoreResponse>
export type PreferredStoreClearType = AppAction<PreferredStoreActionType.CLEAR_PREFERRED_STORE>
export type PreferredStoreSetCustomerType = AppAction<PreferredStoreActionType.GET_CUSTOMER_PREFERRED_STORE, AxiosResponse<IStoreResponse>>

export interface IPreferredStoreData {
	id: number
	storeCode: string
	name: string
}

export enum PreferredStoreActionType {
	GET_PREFERRED_STORES_LIST = 'preferredStore/getPreferredStoresList',
	CLEAR_PREFERRED_STORE = 'preferredStore/clearPreferredStore',
	SET_PREFERRED_STORE = 'preferredStore/setPreferredStore',
	GET_CUSTOMER_PREFERRED_STORE = 'preferredStore/getCustomerPreferredStore',
}
