import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { isNull } from 'lodash'

import { CloseButton } from '~/components/core/closeButton'
import { Icon } from '~/components/core/icon'
import { HAMBURGER_MENU_ANIMATION_TIME } from '~/components/core/hamburgerMenu'
import { Button } from '~/components/core/button'
import { HamburgerMegaMenuContext } from '~/providers/hamburgerMegaMenuProvider'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HamburgerMenuMegaMenuHeaderNav.module.scss'

const HamburgerMenuMegaMenuHeaderNav = (): ReactElement | null => {
	const { activeMegaWorld, activeWorld, handleResetMegaMenu, handleSetActiveWorld, handleSetActiveMegaWorld } = useContext(HamburgerMegaMenuContext)
	const { handleSetOpen } = useContext(HamburgerMenuContext)
	const { t } = useTranslation(['common'])

	const handleCloseMenu = useCallback((): void => {
		handleSetOpen(false)
		setTimeout(handleResetMegaMenu, HAMBURGER_MENU_ANIMATION_TIME)
	}, [])

	const handleBack = useCallback((): void => {
		if (!isNull(activeWorld)) {
			handleSetActiveWorld(null)
		} else if (!isNull(activeMegaWorld)) {
			handleSetActiveMegaWorld(null)
		}
	}, [activeWorld, activeMegaWorld])

	if (isNull(activeMegaWorld)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<Button
				variant="neutral"
				size="inherit"
				dataTestId={ DATA_TESTID.CORE.BUTTON_GO_BACK_HAMBURGER_MENU }
				ariaLabel={ t('back') }
				onClick={ handleBack }
			>
				<Icon
					name="arrow"
					width={ 7 }
					color="green"
					additionalClass={ styles.arrow }
				/>

				<span className={ styles.label }>
					{ t('back') }
				</span>
			</Button>

			<CloseButton onClose={ handleCloseMenu } />
		</div>
	)
}

export { HamburgerMenuMegaMenuHeaderNav }
