import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useMeasure } from 'react-use'
import classNames from 'classnames'
import { gt, isEmpty } from 'lodash'

import { HeaderTopBarSearchBarNodes, HeaderTopBarSearchBarResultsAside, HeaderTopBarSearchBarSuggestions, IHeaderTopBarSearchBarResultsMainProps, HeaderTopBarSearchBarHistory, SEARCH_SUGGESTIONS_MAX_HEIGHT } from '~/components/core/layout'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarSearchBarResultsMain.module.scss'

const HeaderTopBarSearchBarResultsMain = (props: IHeaderTopBarSearchBarResultsMainProps): ReactElement | null => {
	const { nodes, suggestions, products, history, query, hasCategoriesOnly, onHideSearchSuggestions, onForceRerenderResults, handleHideSuggestions, isModalOpen } = props
	const { isDesktop, isTablet } = useMediaQuery()
	const [containerRef, { height }] = useMeasure<HTMLDivElement>()
	const wrapperRef = useRef<HTMLDivElement>(null)

	const isContentAvailable = useMemo((): boolean => {
		return isEmpty(nodes) && isEmpty(suggestions) && isEmpty(products) && isEmpty(history)
	}, [nodes, suggestions, products, history])

	const handleHideSuggestionsNodes = useCallback((): void => {
		const areOtherSectionsEmpty = isEmpty(suggestions) && isEmpty(history)

		if (hasCategoriesOnly && areOtherSectionsEmpty) {
			onHideSearchSuggestions()
		}
	}, [hasCategoriesOnly, suggestions, history, onHideSearchSuggestions])

	const autoHeightMin = useMemo((): number | string => {
		if (isModalOpen) {
			if (isTablet) {
				return '60vh'
			}

			return '75vh'
		}

		return gt(height, SEARCH_SUGGESTIONS_MAX_HEIGHT) ? SEARCH_SUGGESTIONS_MAX_HEIGHT : height
	}, [height, isModalOpen, isTablet])

	if (isContentAvailable) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.modal]: isModalOpen,
	})

	return (
		<div ref={ wrapperRef } className={ wrapperClass }>
			<Scrollbars
				universal
				autoHeight
				hideTracksWhenNotNeeded
				autoHeightMin={ autoHeightMin }
			>
				<div ref={ containerRef } data-testid={ DATA_TESTID.HEADER.SEARCH_BAR_RESULTS_MAIN }>
					<HeaderTopBarSearchBarNodes
						nodes={ nodes }
						query={ query }
						onHideSuggestions={ handleHideSuggestionsNodes }
					/>

					<HeaderTopBarSearchBarSuggestions suggestions={ suggestions } query={ query } />

					<HeaderTopBarSearchBarHistory
						history={ history }
						onForceRerenderResults={ onForceRerenderResults }
						onHideSuggestions={ handleHideSuggestions }
					/>

					{ !isDesktop && (
						<HeaderTopBarSearchBarResultsAside
							shouldHideOnBlur
							products={ products }
							query={ query }
							onHideSearchSuggestions={ onHideSearchSuggestions }
						/>
					) }
				</div>
			</Scrollbars>
		</div>
	)
}

export { HeaderTopBarSearchBarResultsMain }
