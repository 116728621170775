import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty, isFunction } from 'lodash'

import { ProductBlockMiniWrapper, ProductBlockMiniImage, ProductBlockMiniDataWrapper, ProductBlockMiniNameAndPrice, ProductBlockMiniActions, IProductBlockMiniProps } from '~/components/core/productBlock'

const ProductBlockMini = (props: IProductBlockMiniProps): ReactElement => {
	const {
		product, price,
		dataTestId = '',
		saleUnitName = '',
		onIncrement = undefined,
		onDecrement = undefined,
		onTransferToCart = undefined,
		onRemove = undefined,
		count = 1,
		isFavorites = false,
		theme = {},
	} = props
	const { t } = useTranslation(['header'])

	const { image, name, wwwUrl, price: productSimplePrice, promotionLabel } = product
	const { basePriceUnit, packagePriceUnit, newPrice, basePrice, newPricePromoLabel, omnibusPrice } = productSimplePrice

	const fixedPrice = isFavorites ? newPrice : price
	const priceStrikethrough = isFavorites ? basePrice : null
	const fixedPromoLabel = isFavorites ? newPricePromoLabel : null

	const priceUnit = useMemo(() => {
		if (!isEmpty(saleUnitName)) {
			return saleUnitName
		}

		return isEmpty(packagePriceUnit) ? basePriceUnit : packagePriceUnit

	}, [saleUnitName, basePriceUnit, packagePriceUnit])

	const handleRemove = useCallback(async (): Promise<void> => {
		if (isFunction(onRemove)) {
			onRemove()
		}
	}, [onRemove])

	return (
		<ProductBlockMiniWrapper dataTestId={ dataTestId } additionalClass={ theme.wrapper }>
			<ProductBlockMiniImage
				name={ name }
				image={ image }
				url={ wwwUrl }
			/>

			<ProductBlockMiniDataWrapper additionalClass={ theme.dataWrapper }>
				<ProductBlockMiniNameAndPrice
					name={ name }
					url={ wwwUrl }
					price={ fixedPrice }
					priceStrikethrough={ priceStrikethrough }
					promoLabel={ fixedPromoLabel }
					omnibusPrice={ omnibusPrice }
					ariaLabel={ `${ name }, ${ t('product.price.regular', { price: price }) }${ count } ${ basePriceUnit }` }
					priceUnit={ priceUnit }
					promotionLabelCode={ promotionLabel?.code }
					theme={ {
						wrapper: theme.nameAndPriceWrapper,
						priceWrapper: theme.priceWrapper,
						priceSaleWrapper: theme.priceSaleWrapper,
					} }
				/>

				<ProductBlockMiniActions
					count={ count }
					priceUnit={ priceUnit }
					theme={ {
						wrapper: theme.actionsWrapper,
						transferButton: theme.actionsTransferButton,
						removeButton: theme.actionsRemoveButton,
					} }
					onRemove={ handleRemove }
					onIncrement={ onIncrement }
					onDecrement={ onDecrement }
					onTransferToCart={ onTransferToCart }
				/>
			</ProductBlockMiniDataWrapper>
		</ProductBlockMiniWrapper>
	)
}

export { ProductBlockMini }
