import React, { ReactElement } from 'react'

import { useMediaQuery } from '~/hooks/mediaQuery'
import { ICartPreviewCheckPriceItemProps } from '~/components/cart/cartPreview/cartPreviewCheckPrice'
import { CartPreviewCheckPriceProduct } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPriceProduct'
import { CartPreviewCheckPriceQuantity } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPriceQuantity'
import { CartPreviewCheckPricePriceValue } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPricePriceValue'
import { CartPreviewCheckPriceTotal } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPriceTotal'

import styles from './CartPreviewCheckPriceItem.module.scss'

const CartPreviewCheckPriceItem = (props: ICartPreviewCheckPriceItemProps): ReactElement => {
	const { item } = props
	const { isDesktop } = useMediaQuery()

	const { priceInfo, labels, productSimple: { price } } = item
	const { saleUnitQuantity, value, saleUnitName } = priceInfo

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.data }>
				<CartPreviewCheckPriceProduct item={ item } />

				{ isDesktop && (
					<>
						<CartPreviewCheckPricePriceValue price={ price } labels={ labels } />

						<CartPreviewCheckPriceQuantity quantity={ saleUnitQuantity } unit={ saleUnitName } />

						<CartPreviewCheckPriceTotal summaryPrice={ value } />
					</>
				) }
			</div>
		</div>
	)
}

export { CartPreviewCheckPriceItem }
