import React, { ReactElement, useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { isEmpty, isNull } from 'lodash'

import { CartPreviewDeliveryModal, CartPreviewDeliveryPickupTransferCategories, CartPreviewDeliveryPickupTransferLogotype, CartPreviewDeliveryPickupTransferTopBar, CartPreviewDeliveryPickupTransferZipCode, ICartPreviewDeliveryPickupTransferCardProps } from '~/components/cart/cartPreview'
import { IRootState } from '~/state/types'
import { clearDeliveryFromStoreData } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDeliveryPickupTransferCard.module.scss'

const CartPreviewDeliveryPickupTransferCard = (props: ICartPreviewDeliveryPickupTransferCardProps): ReactElement => {
	const { item, deliveryStore, onChangeDelivery } = props
	const { deliveryStoreTimeSlots } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { categories: { transportCategoryList, errorMessage } } = deliveryStoreTimeSlots
	const { id, availabilityInfo: { availabilityStatus } } = item
	const isPickupCategoriesVisible = isEmpty(errorMessage) && !isNull(transportCategoryList)

	const handleChangeDeliveryId = useCallback(async (pickupPointId?: number | null): Promise<void> => {
		await onChangeDelivery({ deliveryId: Number(id), isDeliveryFromStore: true, pickupPointId })
	}, [id])

	const handleClearDeliveryFromStore = useCallback(async (): Promise<void> => {
		await onChangeDelivery({ deliveryId: Number(id), isDeliveryFromStore: false, pickupPointId: null })
	}, [id])

	useEffect(() => {
		return () => {
			(async () => {
				await dispatch(clearDeliveryFromStoreData())
			})()
		}
	}, [])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<div className={ styles.topBar }>
				<CartPreviewDeliveryPickupTransferLogotype />

				<CartPreviewDeliveryPickupTransferTopBar content={ item } />
			</div>

			<CartPreviewDeliveryPickupTransferZipCode deliveryStore={ deliveryStore } onClearDeliveryFromStore={ handleClearDeliveryFromStore } />

			{ isPickupCategoriesVisible && (
				<CartPreviewDeliveryPickupTransferCategories
					deliveryStoreTimeSlots={ deliveryStoreTimeSlots }
					deliveryStore={ deliveryStore }
					onChangeDelivery={ handleChangeDeliveryId }
				/>
			) }

			<CartPreviewDeliveryModal
				availabilityStatus={ availabilityStatus }
				deliveryWayId={ id }
			/>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferCard }
