import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { IUseServiceCartStatusData, StepNumberType } from '~/hooks/serviceCartStatus'
import { getUrlWithQuery } from '~/utils/urls'
import { CalendarServiceCartStatusType } from '~/api/dataTypes/calendarService'

export const useServiceCartStatus = (): IUseServiceCartStatusData => {
	const { urls } = useContext(AppParametersContext)
	const router = useRouter()

	const handleChangeServiceCartView = useCallback(async (nextStepNumber: StepNumberType, cartStatus: CalendarServiceCartStatusType, query?: Record<string, string>): Promise<void> => {
		switch (nextStepNumber) {
			case 1:
				await router.push(urls.calendarCartsAppointment)
				break
			case 2:
				switch (cartStatus) {
					case 'CUSTOMER_DATA':
					case 'PAYMENT_WITH_SUMMARY':
					case 'FINALIZE':
						await router.push(getUrlWithQuery(urls.calendarCartsCustomerData, query))
						break
					case 'LOGIN':
						await router.push(getUrlWithQuery(urls.calendarCartsLogin, query))
						break
					case 'APPOINTMENT':
					default:
						await router.push(getUrlWithQuery(urls.calendarCartsAppointment, query))
				}
				break
			case 3:
				switch (cartStatus) {
					case 'PAYMENT_WITH_SUMMARY':
					case 'FINALIZE':
						await router.push(getUrlWithQuery(urls.calendarCartsSummary, query))
						break
					case 'CUSTOMER_DATA':
						await router.push(getUrlWithQuery(urls.calendarCartsCustomerData, query))
						break
					case 'LOGIN':
						await router.push(getUrlWithQuery(urls.calendarCartsLogin, query))
						break
					case 'APPOINTMENT':
					default:
						await router.push(getUrlWithQuery(urls.calendarCartsAppointment, query))
				}
				break
			case 4:
				switch (cartStatus) {
					case 'FINALIZE':
						await router.push(getUrlWithQuery(urls.calendarCartsConfirmation, query))
						break
					case 'CUSTOMER_DATA':
						await router.push(getUrlWithQuery(urls.calendarCartsCustomerData, query))
						break
					case 'LOGIN':
						await router.push(getUrlWithQuery(urls.calendarCartsLogin, query))
						break
					case 'PAYMENT_WITH_SUMMARY':
						await router.push(getUrlWithQuery(urls.calendarCartsSummary, query))
						break
					case 'APPOINTMENT':
					default:
						await router.push(getUrlWithQuery(urls.calendarCartsAppointment, query))
				}
				break
		}
	}, [urls])

	return {
		changeServiceCartView: handleChangeServiceCartView,
	}
}
