import React, { createContext, ReactElement, useContext, useEffect, useMemo } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { getCookie } from 'cookies-next'
import { isNull, isUndefined, isEqual } from 'lodash'

import { IRootState } from '~/state/types'
import { isUserLogged, isUserAdeoLogged } from '~/state/reducers/authReducer/authSelector'
import { IUserContextProps, IUserProviderProps } from '~/providers/userProvider/types'
import { convertUserStoreCode } from '~/providers/userProvider/helpers'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useAdeoSignIn } from '~/hooks/signIn'
import { isValidKobiJwtToken } from '~/utils/token'
import { localStorageSet, localStorageGet } from '~/utils/storage'
import { COOKIE_KEY_ADEO_STORE_ID, LS_KEY_ADEO_AUTH_USER, LS_KEY_SHOULD_BE_LOGIN_BY_ADEO } from '~/utils/constants'
import { parseCookieStoreIdToStoreCode } from '~/utils/stores'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { setPreferredStore } from '~/actions/preferredStore'
import { AdeoAuthDataType } from '~/state/reducers/authReducer'

const initialProps: IUserContextProps = {
	isLogged: false,
	isAuthorizationReady: false,
	userName: '',
	customerNumber: '',
	isUserLoginPending: false,
	usersStoreCode: '',
}

export const UserContext = createContext(initialProps)

export const UserProvider = (props: IUserProviderProps): ReactElement => {
	const { children, value } = props
	const isUnityLogged = useSelector((state: IRootState) => isUserLogged(state))
	const isAdeoLogged = useSelector((state: IRootState) => isUserAdeoLogged(state))
	const { adeo } = useSelector((state: IRootState) => state.auth, shallowEqual)
	const { data: customerData } = useSelector((state: IRootState) => state.customer, shallowEqual)
	const { GA_account } = useGoogleAnalytics()
	const router = useRouter()
	const { shouldLoginByAdeo, handleFetchDataAfterRefreshTokenByAdeo, handleLoginByAdeo, handleRefreshAccessTokenByAdeo, handleLogoutByAdeo, isPending } = useAdeoSignIn()
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)
	const preferredStore = useSelector((state: IRootState) => state.preferredStore.data, shallowEqual)
	const dispatch = useDispatch()
	const currentKobiJwt = localStorageGet<AdeoAuthDataType>(LS_KEY_ADEO_AUTH_USER)?.kobiJwt

	const { cookies } = value || {}
	const { kobiJwt } = cookies || {}
	const userName = customerData?.customer.firstName ?? initialProps.userName
	const customerNumber = customerData?.customer.customerNumber ?? initialProps.customerNumber
	const isAuthorizationReady = !isNull(customerData)
	const isLogged = shouldLoginByAdeo ? isAdeoLogged : isUnityLogged
	const shouldRefreshToken = shouldLoginByAdeo && !isUndefined(kobiJwt) && !isEqual(kobiJwt, adeo?.kobiJwt)
	const adeoCookieStoreId = getCookie(COOKIE_KEY_ADEO_STORE_ID) as string | undefined
	const usersStoreCode = convertUserStoreCode(customerData?.storeCode || null)

	useEffect(() => {
		if (!isUndefined(shouldLoginByAdeo)) {
			localStorageSet(LS_KEY_SHOULD_BE_LOGIN_BY_ADEO, shouldLoginByAdeo)
		}
	}, [shouldLoginByAdeo])

	useEffect(() => {
		if (isAuthorizationReady) {
			GA_account(customerNumber)
		}
	}, [router.asPath])

	useEffect(() => {
		if (!isLogged && shouldLoginByAdeo && !isUndefined(kobiJwt)) {
			(async () => {
				await handleLoginByAdeo(kobiJwt)
			})()
		}
	}, [isLogged, shouldLoginByAdeo, handleLoginByAdeo, kobiJwt])

	useEffect(() => {
		if (shouldLoginByAdeo && !isUndefined(kobiJwt) && !isUndefined(currentKobiJwt) && !isEqual(kobiJwt, currentKobiJwt)) {
			router.reload()
		}
	}, [currentKobiJwt, kobiJwt, shouldLoginByAdeo, router])

	useEffect(() => {
		if (shouldRefreshToken && isValidKobiJwtToken(kobiJwt)) {
			(async () => {
				await handleRefreshAccessTokenByAdeo(kobiJwt)

				if (isNull(customerData)) {
					await handleFetchDataAfterRefreshTokenByAdeo()
				}
			})()
		}
	}, [shouldRefreshToken, kobiJwt, customerData, isLogged])

	useEffect(() => {
		if (isUndefined(kobiJwt) && shouldLoginByAdeo && isLogged) {
			(async () => {
				await handleLogoutByAdeo()
			})()
		}
	}, [kobiJwt, shouldLoginByAdeo, isLogged])

	useEffect(() => {
		if (!isUndefined(adeoCookieStoreId) && isKobiIntegrationEnabled) {
			(async () => {
				const storeCode = parseCookieStoreIdToStoreCode(adeoCookieStoreId)

				if (!isEqual(storeCode, preferredStore?.storeCode)) {
					await dispatch(setPreferredStore(storeCode, isLogged))
				}
			})()
		}
	}, [adeoCookieStoreId, isKobiIntegrationEnabled, isLogged])

	const providerValue: IUserContextProps = useMemo(() => ({
		isLogged,
		isAuthorizationReady,
		userName,
		customerNumber,
		isUserLoginPending: isPending,
		usersStoreCode,
	}), [isLogged, userName, customerNumber, isAuthorizationReady, isPending, usersStoreCode])

	return (
		<UserContext.Provider value={ providerValue }>
			{ children }
		</UserContext.Provider>
	)
}
