import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import { gt, isNil } from 'lodash'

import { IRootState } from '~/state/types'

import { AuthDataType, AdeoAuthDataType } from './types'

export const getAuthData = (state: IRootState): AuthDataType => state.auth.data
export const getAdeoAuthData = (state: IRootState): AdeoAuthDataType => state.auth.adeo

export const isUserLogged = createSelector(
	getAuthData,
	(data: AuthDataType) => {
		if (!isNil(data)) {
			const { expires_in } = data
			const currentDate = dayjs()
			const expiredDate = currentDate.add(expires_in, 'seconds')

			return gt(expiredDate.diff(currentDate), 0)
		}

		return false
	},
)

export const isUserAdeoLogged = createSelector(
	getAdeoAuthData,
	(data: AdeoAuthDataType): boolean => !isNil(data)
)
