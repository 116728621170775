import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { ICartPreviewDeliveryPickupTransferTopBarInfoProps } from '~/components/cart/cartPreview'
import { Tooltip } from '~/components/core/tooltip'

import styles from './CartPreviewDeliveryPickupTransferTopBarInfo.module.scss'

const CartPreviewDeliveryPickupTransferTopBarInfo = (props: ICartPreviewDeliveryPickupTransferTopBarInfoProps): ReactElement => {
	const { name, description } = props
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.caption }>
				{ name }
			</span>

			<Tooltip content={ !isEmpty(description) ? description : t('preview.delivery.groups.storeDelivery.fastDelivery') } />
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTopBarInfo }
