import { ICartCustomerState } from '~/state/reducers/cartCustomerReducer'

export const cartCustomerInitialState: ICartCustomerState = {
	data: {
		cartStatus: 'CUSTOMER',
		addressList: [],
		invoiceRequired: false,
		orderingAsGuest: false,
		otherContact: false,
		customer: {
			address: {
				city: null,
				flatNumber: null,
				houseNumber: null,
				street: null,
				zipCode: null,
			},
			customerType: 'PRIVATE',
			customerTitle: 'MS',
			companyName: null,
			nip: null,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
		},
		companySimpleInvoiceAvailable: false,
		customerContact: {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
			companyName: '',
			nip: '',
		},
		cart: {
			cartUuid: '',
			anyPostPurchaseRewards: false,
			selectedDeliveryWayId: null,
			selectedPaymentWayId: null,
			selectedDeliveryWayType: null,
			cartStatus: 'VIEW',
			availability: {
				name: '',
				label: 'A',
				id: 0,
				numberOfDeliveryDays: 0,
				onDemand: false,
			},
			cartItemList: [],
			numberOfItems: 0,
			pickupInStore: {
				pickupInStoreDateInfo: '',
				timeSlot: '',
				shouldRecalculate: false,
			},
			deliveryStore: {
				pickupPoint: false,
				category: '',
				zipCode: '',
				storeName: '',
				timeSlot: {
					date: 0,
					toTime: 0,
					fromTime: 0,
				},
				categoryName: '',
				shouldRecalculate: false,
				transportCalculation: false,
			},
			paymentSummaryValue: 0,
			paymentSummaryNetValue: 0,
			productsQuantityMap: {},
			productsValue: 0,
			productsNetValue: 0,
			promotionCode: null,
			promotionCodeType: 'NONE',
			loyaltyCardNumber: '',
			wwwUrl: '',
			promotionCodeUsed: false,
			promotionCodeValue: '',
			totalCostValue: 0,
			promotionValue: 0,
			deliveryCost: 0,
			pickupPoint: null,
			appliedPromotions: [],
		},
		delivery: {
			id: 0,
			name: '',
			fullName: '',
			price: 0,
			processingTime: '',
			description: '',
			availabilityInfo: {
				availabilityMessage: '',
				availabilityStatus: 'NONE',
			},
			prepaid: false,
			pickupWithTransportDelivery: false,
			isAllowed: false,
			courierNoteAvailable: false,
			isCourierWithCarryInDelivery: false,
			isPickupPoint: false,
			pickupPointTypes: [],
		},
		invoice: {
			invoice: false,
			customerTitle: null,
			customerType: null,
			contact: null,
			address: null,
		},
		choosePaymentStep: false,
		customerOtherContact: {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
		},
		receiptNip: {
			nip: '',
			invoiceExpectation: 'NO',
		},
		customerGuest: {
			customerTitle: '',
			customerType: 'PRIVATE',
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			address: {
				city: '',
				zipCode: '',
				street: '',
				houseNumber: '',
			},
		},
		nipInvoiceThresholdAmount: 0,
	},
	isNextButtonDisabled: true,
	isNextButtonDisabledByInvoice: true,
	isAnotherReceiverActive: false,
	invoiceSelectedType: '',
	addressId: null,
	isPending: false,
}
