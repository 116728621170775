import { useCallback } from 'react'
import { useToggle } from 'react-use'
import { isNil } from 'lodash'

import { FormErrorCodeType } from '~/api/dataTypes/form'
import { IUseRecaptchaErrorData, RECAPTCHA_DISABLE_TIMEOUT } from '~/hooks/recaptchaError'

export const useRecaptchaError = (): IUseRecaptchaErrorData => {
	const [isDisabled, setIsDisabled] = useToggle(false)

	const isRecaptchaError = useCallback((code?: FormErrorCodeType): boolean => (
		!isNil(code) && code === 'RECAPTCHA_INVALID_SCORE'
	), [])

	const handleRecaptchaError = useCallback((code?: FormErrorCodeType) => {
		if (isRecaptchaError(code)) {
			setIsDisabled(true)

			setTimeout(() => setIsDisabled(false), RECAPTCHA_DISABLE_TIMEOUT)
		}
	}, [])

	return {
		isDisabled,
		isRecaptchaError,
		onRecaptchaError: handleRecaptchaError,
	}
}
