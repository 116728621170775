import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IContainerProps } from '~/components/core/layout/container'

import styles from './Container.module.scss'

const Container = (props: IContainerProps): ReactElement => {
	const {
		children,
		additionalClass = '',
	} = props

	const containerClass = classNames({
		[styles.container]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ containerClass }>
			{ children }
		</div>
	)
}

export { Container }
