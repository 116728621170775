import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { ICustomerReviewsResult } from '~/api/dataTypes/customerReviews'

export enum CustomerReviewActionType {
	GET_CUSTOMER_REVIEWS = 'customer/GET_CUSTOMER_REVIEWS',
	SET_CUSTOMER_REVIEWS_SUBSCRIPTION = 'customer/SET_CUSTOMER_REVIEWS_SUBSCRIPTION',
}

export type GetCustomerReviewsActionType = AppAction<CustomerReviewActionType.GET_CUSTOMER_REVIEWS, AxiosResponse<ICustomerReviewsResult>>
export type SetCustomerReviewsSubscriptionActionType = AppAction<CustomerReviewActionType.SET_CUSTOMER_REVIEWS_SUBSCRIPTION>

export type CustomerReviewActionUnionType = GetCustomerReviewsActionType | SetCustomerReviewsSubscriptionActionType
