import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { isEmpty, isNull, isNil } from 'lodash'

import { IProductBlockImageProps } from '~/components/core/productBlock'
import { Link } from '~/components/core/link'
import { PromoLabel } from '~/components/core/promoLabel'
import { ProductImage } from '~/components/core/productImage'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'

import styles from './ProductBlockImage.module.scss'

const ProductBlockImage = (props: IProductBlockImageProps): ReactElement => {
	const {
		product, newPricePromoLabel,
		position = 0,
		additionalClass = '',
		overrideImageSrc = '',
		hasPriority = false,
		theme = {},
	} = props
	const { GA_productClick } = useGoogleAnalytics()

	const { name, wwwUrl, promotionLabel, image, imageData } = product
	const { labels } = product
	const { price: { omnibusPrice } } = product

	const omnibusPromoLabel = !isNil(newPricePromoLabel) ? newPricePromoLabel : labels?.pricePromotionLabel
	const promoLabelCode = !isNil(promotionLabel) ? promotionLabel.code : labels?.promotionLabel?.code
	const promoLabelName = !isNil(promotionLabel) ? promotionLabel.name : labels?.promotionLabel?.name

	// TODO: change type in customer-preference-service/last viewed
	const thumbnailUrl = !isEmpty(overrideImageSrc) ? overrideImageSrc : image?.thumbnailUrl ?? imageData?.thumbnailUrl

	const handleProductClick = useCallback(() => {
		GA_productClick(product, position)
	}, [product])

	const imageClass = classNames(styles.wrapper, theme.wrapper, additionalClass)

	const mouseoverImageUrl = isEmpty(overrideImageSrc) ? image?.mouseoverUrl : null

	const omnibusPromoLabelClass = classNames(styles.label, {
		[styles.extraBottom]: !isNull(omnibusPrice),
	})

	return (
		<Link
			href={ wwwUrl }
			tabIndex={ -1 }
			ariaLabel={ name }
			theme={ {
				wrapper: imageClass,
				link: theme.link,
			} }
			onClick={ handleProductClick }
		>
			<ProductImage
				imageUrl={ thumbnailUrl }
				alt={ name }
				mouseoverUrl={ mouseoverImageUrl }
				hasPriority={ hasPriority }
				position={ position }
			/>

			{ !isNull(omnibusPrice) && (
				<PromoLabel
					code={ promoLabelCode }
					name={ omnibusPromoLabel }
					additionalClass={ omnibusPromoLabelClass }
				/>
			) }

			<PromoLabel
				code={ promoLabelCode }
				name={ promoLabelName }
				additionalClass={ styles.label }
			/>
		</Link>
	)
}

export { ProductBlockImage }
