import React, { ReactElement, ReactNode, useCallback } from 'react'
import classNames from 'classnames'
import { isEmpty, isNull, noop, isEqual } from 'lodash'

import { Loader } from '~/components/core/loader'
import { IButtonProps } from '~/components/core/button'
import { useMozaic } from '~/hooks/mozaic'

import styles from './Button.module.scss'

const Button = (props: IButtonProps): ReactElement => {
	const {
		dataNumberOf, role, isAriaExpanded, ariaLabelledBy,
		children = null,
		buttonRef = null,
		type = 'button',
		variant = 'primary',
		size = 'normal',
		additionalClass = '',
		text = '',
		ariaLabel = '',
		id = '',
		dataTestId = '',
		tabIndex = 0,
		isReverse = false,
		isLoading = false,
		isDisabled = false,
		hasFormNoValidation = false,
		isAriaHidden = false,
		hasAriaPopup = false,
		isContentAriaHidden = true,
		onFocus = noop,
		onBlur = noop,
		onClick = noop,
		onKeyDown = noop,
		onMouseEnter = noop,
		onMouseLeave = noop,
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const getAriaLabel = useCallback((): string | undefined => {
		if (!isEmpty(ariaLabel)) {
			return ariaLabel
		}

		if (!isEmpty(text)) {
			return text
		}

		return undefined
	}, [ariaLabel, text])

	const renderContent = useCallback((): ReactNode | null => {
		const content = !isEmpty(text) ? text : children

		if (isNull(content)) {
			return null
		}

		return (
			<div aria-hidden={ isContentAriaHidden } className={ styles.ariaWrapper }>
				{ isLoading ? <Loader /> : content }
			</div>
		)
	}, [isLoading, text, children, isContentAriaHidden])

	const renderWrapperClass = useCallback(() => {
		if (shouldUseMozaic && !isEqual(variant, 'neutral')) {
			switch (variant) {
				case 'primary':
				case 'secondary':
					return classNames({
						'mc-button': isEqual(variant, 'primary') || isEqual(variant, 'secondary'),
						'mc-button--bordered': isReverse,
						[styles[size]]: !isEmpty(size),
						[additionalClass]: !isEmpty(additionalClass),
						'is-disabled': isDisabled,
						[styles.loading]: isLoading,
						[styles.mozaicWrapper]: true,
					})
			}
		}

		return classNames(styles.wrapper, {
			[styles[variant]]: variant,
			[styles.reverse]: isReverse,
			[styles.disabled]: isDisabled,
			[styles[size]]: !isEmpty(size),
			[styles.loading]: isLoading,
			[additionalClass]: !isEmpty(additionalClass),
		})
	}, [shouldUseMozaic, variant, isReverse, additionalClass, isDisabled, size, isLoading])

	const wrapperClass = renderWrapperClass()

	return (
		<button
			ref={ buttonRef }
			className={ wrapperClass }
			id={ id }
			// eslint-disable-next-line react/button-has-type
			type={ type }
			disabled={ isDisabled }
			formNoValidate={ hasFormNoValidation }
			role={ role }
			tabIndex={ tabIndex }
			data-testid={ dataTestId }
			data-number-of={ dataNumberOf }
			aria-label={ getAriaLabel() }
			aria-disabled={ isDisabled }
			aria-haspopup={ hasAriaPopup }
			aria-hidden={ isAriaHidden }
			aria-expanded={ isAriaExpanded }
			aria-labelledby={ ariaLabelledBy }
			onClick={ onClick }
			onBlur={ onBlur }
			onFocus={ onFocus }
			onMouseEnter={ onMouseEnter }
			onMouseLeave={ onMouseLeave }
			onKeyDown={ onKeyDown }
		>
			{ renderContent() }
		</button>
	)
}

export { Button }
