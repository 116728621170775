import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { IStoreResponse, IStoreSimpleResultResponse } from '~/api/dataTypes/store'

export enum StoresListActionType {
	GET_STORE_MAP_INFO_WINDOW = 'stores/GET_STORE_MAP_INFO_WINDOW',
	CLEAR_STORE_MAP_INFO_WINDOW = 'stores/CLEAR_STORE_MAP_INFOWINDOW',
	SET_STORE_USER_LOCATION = 'stores/SET_STORE_USER_LOCATION',
	CLEAR_STORE_USER_LOCATION = 'stores/CLEAR_STORE_USER_LOCATION',
	GET_SIMPLE_STORE_LIST = 'stores/GET_STORE_SIMPLE_LIST',
}

export type GetStoreMapInfoWindowActionType = AppAction<StoresListActionType.GET_STORE_MAP_INFO_WINDOW, AxiosResponse<IStoreResponse>>
export type ClearStoreMapInfoWindowdActionType = AppAction<StoresListActionType.CLEAR_STORE_MAP_INFO_WINDOW>
export type SetStoreUserLocationActionType = AppAction<StoresListActionType.SET_STORE_USER_LOCATION>
export type ClearStoreUserLocationActionType = AppAction<StoresListActionType.CLEAR_STORE_USER_LOCATION>
export type GetSimpleStoreListActionType = AppAction<StoresListActionType.GET_SIMPLE_STORE_LIST, AxiosResponse<IStoreSimpleResultResponse[]>>
