import React, { useCallback, ReactElement } from 'react'
import { map } from 'lodash'

import { HeaderMainMenuItem, IHeaderMainMenuItemsProps, IMainMenuItem } from '~/components/core/layout'

const HeaderMainMenuItems = (props: IHeaderMainMenuItemsProps): ReactElement => {
	const { items } = props

	const renderItems = useCallback((): ReactElement[] => (
		map(items, (item: IMainMenuItem) => (
			<HeaderMainMenuItem key={ item.id } item={ item } />
		))
	), [items])

	return (
		<>
			{ renderItems() }
		</>
	)
}

export { HeaderMainMenuItems }
