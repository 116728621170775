import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { ICartPreviewProductsProductBlockProps, CartPreviewProductsProductBlockImage, CartPreviewProductsProductBlockInfo, CartPreviewProductsDeliveryBlock, CartPreviewProductsAvailabilityBlock, CartPreviewProductsQuantityBlock, CartPreviewProductsSummaryPriceBlock } from '~/components/cart/cartPreview'
import { DotMenu, IDotMenuListItem } from '~/components/core/dotMenu'

import styles from './CartPreviewProductsProductBlock.module.scss'

const CartPreviewProductsProductBlock = (props: ICartPreviewProductsProductBlockProps): ReactElement => {
	const { item, userStoreStock, quantity, isNotEnoughItem, priceUnit, onOpenMobileRemoveProductModal } = props
	const { t } = useTranslation(['cart'])

	const { productSimple, valueForLine, labels } = item
	const { deliveryInfo } = productSimple

	const dotMenuList: IDotMenuListItem[] = [
		{
			id: 'removeProductFromCart',
			title: t('preview.table.removeAction'),
			icon: {
				name: 'close',
				size: 14,
				color: 'navy',
			},
			additionalClass: styles.removeMobileButton,
			onClick: onOpenMobileRemoveProductModal,
		},
	]

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.container }>
				<CartPreviewProductsProductBlockImage product={ productSimple } />

				<CartPreviewProductsProductBlockInfo
					labels={ labels }
					product={ productSimple }
					priceUnit={ priceUnit }
				/>

				<div className={ styles.mobileDotMenu }>
					<DotMenu nodes={ dotMenuList } />
				</div>
			</div>

			<div className={ styles.mobileContainer }>
				<CartPreviewProductsDeliveryBlock deliveryInfo={ deliveryInfo } />

				<CartPreviewProductsAvailabilityBlock
					userStoreStock={ userStoreStock }
					priceUnit={ priceUnit }
					deliveryInfo={ deliveryInfo }
				/>
			</div>

			<div className={ styles.mobileContainer }>
				<CartPreviewProductsQuantityBlock
					item={ item }
					quantity={ quantity }
					priceUnit={ priceUnit }
					isNotEnoughItem={ isNotEnoughItem }
				/>

				<CartPreviewProductsSummaryPriceBlock summaryPrice={ valueForLine } />
			</div>
		</div>
	)
}

export { CartPreviewProductsProductBlock }
