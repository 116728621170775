import produce, { Draft } from 'immer'
import { isNil } from 'lodash'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { ServiceCartSummaryActionType, ServiceCartSummaryType } from '~/actions/serviceCartSummary'

import { IServiceCartSummaryState } from './types'
import { serviceCartSummaryInitialState as initialState } from './constants'

export const serviceCartSummary = produce((draft: Draft<IServiceCartSummaryState>, action: AppPromiseAction<ServiceCartSummaryActionType>) => {
	switch (action.type) {
		case REQUEST(ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY):
			draft.isPending = true

			return draft
		case REQUEST(ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY_STORE):
			draft.isStorePending = true

			return draft
		case FAILURE(ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY):
			draft.isPending = false

			return draft
		case FAILURE(ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY_STORE):
			draft.isStorePending = false

			return draft
		case SUCCESS(ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY):
			draft.data = action.payload.data
			draft.selectedPaymentWay = null
			draft.isPending = false

			return draft
		case SUCCESS(ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY_STORE):
			draft.storeDetails = isNil(action.payload) ? null : action.payload.data
			draft.isStorePending = false

			return draft
		case SUCCESS(ServiceCartSummaryType.SET_SERVICE_CART_SUMMARY_PAYMENT_WAY):
			draft.selectedPaymentWay = action.payload

			return draft
	}
}, initialState)
