import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { ICartPreviewNotAvailableOrderProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewNotAvailableOrder.module.scss'

const CartPreviewNotAvailableOrder = (props: ICartPreviewNotAvailableOrderProps): ReactElement => {
	const { isOpen, isLoading, onClose } = props
	const { t } = useTranslation(['cart', 'common'])

	return (
		<Modal
			isOpen={ isOpen }
			title={ t('preview.aside.summary.modal.notAvailableProducts.title') }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
		>
			<div className={ styles.content }>
				<div className={ styles.subtitle }>
					{ t('preview.aside.summary.modal.notAvailableProducts.info') }
				</div>

				<div className={ styles.actions }>
					<Button
						text={ t('ok', { ns: 'common' }) }
						variant="secondary"
						additionalClass={ styles.button }
						isLoading={ isLoading }
						onClick={ onClose }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { CartPreviewNotAvailableOrder }
