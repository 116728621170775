import React, { MouseEvent, ReactElement, useCallback, useRef } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import { isEqual, isNull } from 'lodash'

import { getMegaWorldIconName } from '~/utils/catalog'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IMegaMenuMegaWorldsItemProps, focusToFirstChildOfActiveElement, setElementsTabIndex, WORLDS_DEFAULT_TABINDEX, WORLDS_NEUTRAL_TABINDEX } from '~/components/core/megamenu'
import { Link } from '~/components/core/link'
import { Icon } from '~/components/core/icon'

import styles from './MegaMenuMegaWorldsItem.module.scss'

const MegaMenuMegaWorldsItem = (props: IMegaMenuMegaWorldsItemProps): ReactElement => {
	const { item, isActive, activeClientSideIndex, isFirstElement, isLastElement, onClose, onSetActive } = props
	const linkRef = useRef<HTMLAnchorElement | null>(null)
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')
	const [isTabPressed] = useKeyboardJs('tab')
	const { t } = useTranslation(['header'])

	const { name, id, wwwUrl } = item
	const megaWorldIcon = getMegaWorldIconName(id)

	const handleSetActive = useCallback((event: MouseEvent): void => {
		event.preventDefault()
		onSetActive(item)
	}, [item])

	const handleCloseAtFirst = useCallback((isFirst: boolean, isShiftTab: boolean): void => {
		if (isFirst && isShiftTab) {
			onClose()
		}
	}, [onClose])

	const handleCloseAtLast = useCallback((isLast: boolean, isTab: boolean): void => {
		if (isLast && isTab) {
			onClose()
		}
	}, [onClose])

	const handleBlur = useCallback((): void => {
		handleCloseAtFirst(isFirstElement, isShiftTabPressed)
		handleCloseAtLast(isLastElement, isTabPressed)
	}, [isLastElement, isFirstElement, isTabPressed, isShiftTabPressed])

	const handleKeyDownEnter = useCallback((event: KeyboardEvent): void => {
		const { current } = linkRef

		if (isEqual(event.key, 'Enter') && !isNull(current)) {
			setElementsTabIndex(current, WORLDS_DEFAULT_TABINDEX)
			focusToFirstChildOfActiveElement(activeClientSideIndex)
		}
	}, [activeClientSideIndex, linkRef])

	const handleFocus = useCallback((): void => {
		const { current } = linkRef

		if (!isNull(current)) {
			setElementsTabIndex(current, WORLDS_NEUTRAL_TABINDEX)
		}
	}, [linkRef])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.active]: isActive,
	})

	return (
		<li>
			<Link
				isNativeLink
				linkRef={ linkRef }
				href={ wwwUrl }
				theme={ {
					wrapper: wrapperClass,
				} }
				ariaLabel={ `${ t('menu.megaMenu.mainNavAriaPrefix') } ${ name }` }
				hasAriaCurrent={ isActive }
				dataTestId={ DATA_TESTID.CORE.MEGA_MENU_ITEM(name) }
				onFocus={ handleFocus }
				onClick={ handleSetActive }
				onKeyDown={ handleKeyDownEnter }
				onBlur={ handleBlur }
			>
				<Icon
					name={ megaWorldIcon }
					width={ 26 }
					color="navy"
					additionalClass={ styles.icon }
				/>

				<span>
					{ name }
				</span>
			</Link>
		</li>
	)
}

export { MegaMenuMegaWorldsItem }
