import React, { ReactElement } from 'react'
import { filter } from 'lodash'

import { ICartPreviewDeliverySplitPriceProps, CartPreviewProductsLabels } from '~/components/cart/cartPreview'
import { usePrice } from '~/hooks/price'
import { ICartItemListLabel } from '~/api/dataTypes/cart'

import styles from './CartPreviewDeliverySplitPrice.module.scss'

const CartPreviewDeliverySplitPrice = (props: ICartPreviewDeliverySplitPriceProps): ReactElement => {
	const { price, labels } = props
	const { priceFormat } = usePrice()

	const infoLabels = filter(labels, (label: ICartItemListLabel) => label.type === 'PRICE')

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.price }>
				{ priceFormat(price.newPrice) }
			</span>

			<CartPreviewProductsLabels labels={ infoLabels } />
		</div>
	)
}

export { CartPreviewDeliverySplitPrice }
