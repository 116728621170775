import { createSelector } from 'reselect'
import { map } from 'lodash'

import { IRootState } from '~/state/types'
import { ISimpleCartData, ICartItemData } from '~/api/dataTypes/cart'
import { IProductQuantity } from '~/api/dataTypes/product'

export const getMiniCartData = (state: IRootState): ISimpleCartData => (
	state.miniCart.data
)

export const getMiniCartItemQuantityList = createSelector(getMiniCartData, (miniCartData: ISimpleCartData): IProductQuantity[] => {
	const { cartItemList } = miniCartData

	return map(cartItemList, (cartItem: ICartItemData) => ({
		itemId: cartItem.itemId,
		quantity: miniCartData.productsQuantityMap[cartItem.productSimple.lmReference],
	}))
})
