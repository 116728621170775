import { IAccountProjectsState } from '~/state/reducers/accountReducer'

export const accountProjectsInitialState: IAccountProjectsState = {
	isPending: false,
	list: {
		content: [],
		pageable: {
			pageNumber: 1,
			pageSize: 10,
			totalElements: 0,
			totalPages: 1,
			firstPage: false,
			lastPage: false,
			hasNext: false,
			hasPrevious: false,
		},
	},
}
