import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { SwiperOptions, Grid } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useDispatch } from 'react-redux'
import 'swiper/css/grid'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual, map, isEmpty, some } from 'lodash'

import { IPickupTimeslotData } from '~/api/dataTypes/cart'
import { ICartPreviewDeliveryPickupTimeSlotsAvailableHoursListProps, CartPreviewDeliveryPickupTimeTile, ICartPreviewDeliveryPickupTimeSlotsAvailableHoursListForm } from '~/components/cart/cartPreview'
import { Radio } from '~/components/core/form'
import { cartDeliveryCalculate, cartDeliveryPickupInStoreBooking } from '~/actions/cart'
import { useDate } from '~/hooks/date'
import { useLogError } from '~/hooks/logError'
import { AppDispatch } from '~/state/store'

import styles from './CartPreviewDeliveryPickupTimeSlotsAvailableHoursList.module.scss'

const CartPreviewDeliveryPickupTimeSlotsAvailableHoursList = (props: ICartPreviewDeliveryPickupTimeSlotsAvailableHoursListProps): ReactElement => {
	const { timeslots, selectedDay, timeChosen, calculateParams } = props
	const { formatDate, getDateTimeIsoFormat } = useDate()
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()

	const schema: yup.SchemaOf<ICartPreviewDeliveryPickupTimeSlotsAvailableHoursListForm> = useMemo(() => yup.object().shape({
		timeSlotValue: yup.string().required(),
	}), [])

	const { control, setValue, watch } = useForm<ICartPreviewDeliveryPickupTimeSlotsAvailableHoursListForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			timeSlotValue: timeChosen,
		},
		mode: 'onChange',
	})

	const { field, fieldState: { error } } = useController({ control, name: 'timeSlotValue' })

	const { timeSlotValue } = watch()

	const isChoosenHourAvailable = some(timeslots, (timeSlot: IPickupTimeslotData) => isEqual(timeSlot.possibleHour, timeSlotValue))

	const handleCartDeliveryPickupInStoreBooking = useCallback(async (): Promise<void> => {
		if (!isEmpty(selectedDay) && !isEmpty(timeSlotValue)) {
			const dateTime = `${ selectedDay } ${ timeSlotValue }`
			const localDateTime = formatDate(dateTime, getDateTimeIsoFormat())

			try {
				if (isChoosenHourAvailable) {
					await dispatch(cartDeliveryPickupInStoreBooking(localDateTime))
				}

				await dispatch(cartDeliveryCalculate(calculateParams))
			} catch (e: unknown) {
				setValue('timeSlotValue', '')
				sendLogError(e)
			}
		}
	}, [selectedDay, timeSlotValue, calculateParams, timeslots])

	useEffect(() => {
		(async () => {
			await handleCartDeliveryPickupInStoreBooking()
		})()
	}, [timeSlotValue])

	const renderAvailableHours = useCallback((): ReactElement[] => (
		map(timeslots, (timeslot: IPickupTimeslotData): ReactElement => {
			const { possibleHour } = timeslot
			const isChecked = isEqual(timeSlotValue, possibleHour)

			return (
				<SwiperSlide key={ possibleHour } className={ styles.dateTile }>
					<Radio
						value={ possibleHour }
						field={ field }
						error={ error }
					>
						<CartPreviewDeliveryPickupTimeTile isChecked={ isChecked }>
							{ possibleHour }
						</CartPreviewDeliveryPickupTimeTile>
					</Radio>
				</SwiperSlide>
			)
		})
	), [field, timeChosen])

	const swiperParams: SwiperOptions = {
		spaceBetween: 15,
		slidesPerView: 'auto',
		slidesPerGroup: 2,
		grid: {
			rows: 2,
		},
		modules: [Grid],
	}

	return (
		<div className={ styles.wrapper }>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<Swiper { ...swiperParams } className={ styles.swiper }>
				{ renderAvailableHours() }
			</Swiper>
		</div>
	)
}

export { CartPreviewDeliveryPickupTimeSlotsAvailableHoursList }
