import React, { ReactElement, useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { isEmpty, isEqual, map, findIndex, isNull } from 'lodash'

import { CartPreviewDeliveryPickupOccurrenceDate, CartPreviewDeliveryPickupTimeSlotsAvailableHoursList, ICartPreviewDeliveryPickupOccurrenceDateForm, ICartPreviewDeliveryPickupTimeSlotsCalendarListProps } from '~/components/cart/cartPreview'
import { IPickupCalendarTimeslot } from '~/api/dataTypes/cart'
import { Radio } from '~/components/core/form'
import { useDate } from '~/hooks/date'

import styles from './CartPreviewDeliveryPickupTimeSlotsCalendarList.module.scss'

const CartPreviewDeliveryPickupTimeSlotsCalendarList = (props: ICartPreviewDeliveryPickupTimeSlotsCalendarListProps): ReactElement | null => {
	const { timeslotCalendarList, pickupInStore, calculateParams } = props
	const { t } = useTranslation(['cart'])
	const { formatDateUtc, getDateFormatIso, getTimeFormat } = useDate()

	const { timeslotCalendarList: calendarList, message } = timeslotCalendarList

	const pickupOccurrenceDateChosen = !isNull(pickupInStore) ? formatDateUtc(pickupInStore.timeSlot, getDateFormatIso()) : ''
	const pickupOccurrenceTimeChosen = !isNull(pickupInStore) ? formatDateUtc(pickupInStore.timeSlot, getTimeFormat()) : ''

	const schema: yup.SchemaOf<ICartPreviewDeliveryPickupOccurrenceDateForm> = useMemo(() => yup.object().shape({
		pickupOccurrenceDate: yup.string().required(),
	}), [])

	const { control } = useForm<ICartPreviewDeliveryPickupOccurrenceDateForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			pickupOccurrenceDate: pickupOccurrenceDateChosen,
		},
		mode: 'onChange',
	})

	const { field, fieldState: { error } } = useController({ control, name: 'pickupOccurrenceDate' })

	const slotIndex = findIndex(calendarList, (item: IPickupCalendarTimeslot) => isEqual(item.pickupOccurrenceDate.occurrenceDateText, field.value))
	const timeslots: IPickupCalendarTimeslot = calendarList[slotIndex]
	const { pickupTimeslotList } = timeslots || []

	const renderList = useCallback((): ReactElement[] => (
		map(calendarList, (calendarTimeslot: IPickupCalendarTimeslot) => {
			const { pickupOccurrenceDate } = calendarTimeslot

			const { occurrenceDateText } = pickupOccurrenceDate
			const isChecked = isEqual(field.value, occurrenceDateText)

			return (
				<Radio
					key={ occurrenceDateText }
					value={ occurrenceDateText }
					field={ field }
					error={ error }
					additionalClass={ styles.label }
				>
					<div className={ styles.dateTile }>
						<CartPreviewDeliveryPickupOccurrenceDate
							isChecked={ isChecked }
							occurrenceDate={ pickupOccurrenceDate }
						/>
					</div>
				</Radio>
			)
		})
	), [timeslots, field])

	const renderTimeslots = useCallback(() => {
		if (!isEmpty(pickupTimeslotList)) {
			return (
				<CartPreviewDeliveryPickupTimeSlotsAvailableHoursList
					selectedDay={ field.value }
					timeslots={ pickupTimeslotList }
					timeChosen={ pickupOccurrenceTimeChosen }
					calculateParams={ calculateParams }
				/>
			)
		}
	}, [pickupTimeslotList, field.value, calculateParams, pickupOccurrenceTimeChosen])

	if (isEmpty(calendarList)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption } dangerouslySetInnerHTML={ { __html: message } } />

			<div className={ styles.order }>
				{ t('preview.delivery.groups.pickup.anotherTerm') }
			</div>

			<div className={ styles.dateTiles }>
				{ renderList() }
			</div>

			<div>
				{ renderTimeslots() }
			</div>
		</div>
	)
}

export { CartPreviewDeliveryPickupTimeSlotsCalendarList }
