import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useLocalStorage } from 'react-use'

import { useModal } from '~/hooks/modal'
import { usePreferredStore } from '~/hooks/preferredStore'
import { PreferredStoreModal } from '~/components/core/preferredStore'
import { Button } from '~/components/core/button'
import { UserContext } from '~/providers/userProvider'

import styles from './CartPreviewProductsItemNotAvailable.module.scss'

const CartPreviewProductsItemNotAvailable = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')
	const [value, setValue, remove] = useLocalStorage<string>('preferredStore', '') // eslint-disable-line @typescript-eslint/no-unused-vars
	const { clearPreferredStore } = usePreferredStore()
	const { isLogged } = useContext(UserContext)

	const handleChangeStore = useCallback(() => {
		handleOpen()
	}, [handleOpen])

	const handleClearPreferredStore = useCallback(async (): Promise<void> => {
		remove()

		await clearPreferredStore(isLogged)
	}, [isLogged])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.title }>
				{ t('preview.warnings.notAvailable.title') }
			</div>

			<div className={ styles.description }>
				<span>
					{ `${ t('preview.warnings.notAvailable.infoPrefix') }` }{ ' ' }
				</span>

				<Button
					text={ t('preview.warnings.notAvailable.infoChange') }
					size="inherit"
					variant="neutral"
					additionalClass={ styles.action }
					onClick={ handleChangeStore }
				/>

				<span>
					{ ' ' }{ t('preview.warnings.notAvailable.infoOr') }{ ' ' }
				</span>

				<Button
					text={ t('preview.warnings.notAvailable.infoRemove') }
					size="inherit"
					variant="neutral"
					additionalClass={ styles.action }
					onClick={ handleClearPreferredStore }
				/>

				<span>
					{ ' ' }{ t('preview.warnings.notAvailable.infoPostfix') }
				</span>
			</div>

			<PreferredStoreModal
				isOpen={ isOpen }
				onClose={ handleClose }
			/>
		</div>
	)
}

export { CartPreviewProductsItemNotAvailable }
