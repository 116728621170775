import { Draft, produce } from 'immer'

import { AppPromiseAction } from '~/actions'
import { AuthActionType, AuthAdeoActionType } from '~/actions/auth'
import { FavouriteListActionUnionType, FavouriteListActionType } from '~/actions/favouriteList'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'

import { favouriteListInitialState as initialState } from './constants'
import { IFavouriteListState } from './types'

export const favouriteList = (state: IFavouriteListState = initialState, action: AppPromiseAction<FavouriteListActionUnionType>): IFavouriteListState => {
	return produce(state, (draft: Draft<IFavouriteListState>) => {
		switch (action.type) {
			case REQUEST(FavouriteListActionType.GET_FAVOURITE_LIST):
			case REQUEST(FavouriteListActionType.GET_FAVOURITE_LIST_SIMPLE):
			case REQUEST(FavouriteListActionType.ADD_PRODUCT_TO_FAVOURITE_LIST):
			case REQUEST(FavouriteListActionType.TRANSFER_PRODUCT_TO_CART):
			case REQUEST(FavouriteListActionType.REMOVE_PRODUCT_FROM_FAVOURITE_LIST):
				draft.isPending = true

				return draft
			case SUCCESS(FavouriteListActionType.GET_FAVOURITE_LIST):
				draft.isPending = false
				draft.data = action.payload.data || initialState.data

				return draft
			case SUCCESS(FavouriteListActionType.GET_FAVOURITE_LIST_SIMPLE):
			case SUCCESS(FavouriteListActionType.ADD_PRODUCT_TO_FAVOURITE_LIST):
			case SUCCESS(FavouriteListActionType.REMOVE_PRODUCT_FROM_FAVOURITE_LIST):
				draft.isPending = false
				draft.simpleList = action.payload.data || initialState.simpleList

				return draft
			case SUCCESS(FavouriteListActionType.TRANSFER_PRODUCT_TO_CART):
				draft.isPending = false
				draft.data = action.payload.data.favouriteList

				return draft
			case SUCCESS(AuthActionType.SET_LOGOUT):
			case SUCCESS(AuthAdeoActionType.SET_ADEO_LOGOUT):

				return initialState
			case FAILURE(FavouriteListActionType.GET_FAVOURITE_LIST):
			case FAILURE(FavouriteListActionType.GET_FAVOURITE_LIST_SIMPLE):
			case FAILURE(FavouriteListActionType.ADD_PRODUCT_TO_FAVOURITE_LIST):
			case FAILURE(FavouriteListActionType.TRANSFER_PRODUCT_TO_CART):
			case FAILURE(FavouriteListActionType.REMOVE_PRODUCT_FROM_FAVOURITE_LIST):
				draft.isPending = false

				return draft
			default:
				return draft
		}
	})
}
