import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { includes } from 'lodash'

import { getCheckoutEcommerceProductData, getGoogleAnalyticsEcommerceImpressionData, getGoogleAnalyticsEcommerceImpressionsData, getGoogleAnalyticsEcommerceProductData, getPageTypeName, getPurchaseEcommerceProductsData, IGoogleAnalyticsAddToCartEventParams, IGoogleAnalyticsCheckoutEventParams, IGoogleAnalyticsImpressionFromCatalogData, IGoogleAnalyticsPushParams, IUseGoogleAnalyticsData, IGoogleAnalyticsPromotionEventParams, IGoogleAnalyticsLys, IGoogleAnalyticsLysError, IGoogleAnalyticsPurchaseEventParams, getGoogleAnalyticsLoginType } from '~/hooks/googleAnalytics'
import { console } from '~/utils/console'
import { getEmptyStringForNull } from '~/utils/string'
import { PageType } from '~/statics'
import { PageTypeContext } from '~/providers/pageTypeProvider'
import { ICatalogPath, IProductCardResponse, IProductSimple } from '~/api/dataTypes/product'
import { UserContext } from '~/providers/userProvider'

export const useGoogleAnalytics = (): IUseGoogleAnalyticsData => {
	const router = useRouter()
	const pageType = useContext(PageTypeContext)
	const { isLogged } = useContext(UserContext)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const log = useCallback((log: any): void => {
		console.log(
			'%cGA4:',
			'color:green;font-weight:bold',
			log,
		)
	}, [])

	const pushEvent = useCallback((eventParams: IGoogleAnalyticsPushParams): void => {
		if (window.dataLayer) {
			window.dataLayer.push({ ecommerce: null })
			window.dataLayer.push(eventParams)
			log(eventParams)
		}
	}, [])

	const GA_testSiteLegacy = useCallback((isKobiIntegrationEnabled: boolean): void => {
		if (!isKobiIntegrationEnabled) {
			pushEvent({
				event: 'test_site_B',
			})
		}
	}, [])

	const GA_isCatalogContext = useCallback((pageType: PageType): boolean => (
		includes(['PRODUCT_LIST', 'MEGA_WORLD', 'WORLD', 'PRODUCT_CARD'], pageType)
	), [])

	const GA_contentGroup = useCallback((pageType: PageType, catalogPath?: ICatalogPath) => {
		pushEvent({
			event: 'contentGroup',
			contentGroup1: getEmptyStringForNull(catalogPath?.megaWorld),
			contentGroup2: getEmptyStringForNull(catalogPath?.world),
			contentGroup3: getEmptyStringForNull(catalogPath?.subWorld),
			contentGroup4: getEmptyStringForNull(catalogPath?.leaf),
			contentGroup5: getPageTypeName(pageType),
		})
	}, [])

	const GA_viewItem = useCallback((productData: IProductCardResponse): void => {
		const { productSimple } = productData
		const { wwwUrl } = productSimple

		pushEvent({
			event: 'view_item',
			ecommerce: {
				detail: {
					actionField: {
						list: wwwUrl,
					},
					products: [getGoogleAnalyticsEcommerceProductData(productSimple, router.asPath)],
				},
			},
		})
	}, [router.asPath])

	const GA_addToCart = useCallback(({ product, quantity, value }: IGoogleAnalyticsAddToCartEventParams): void => {
		pushEvent({
			event: 'add_to_cart',
			ecommerce: {
				currencyCode: 'PLN',
				add: {
					actionField: {
						list: getPageTypeName(pageType),
						value,
					},
					products: [getGoogleAnalyticsEcommerceProductData(product, router.asPath, quantity)],
				},
			},
		})
	}, [router.asPath, pageType])

	const GA_checkout = useCallback(({ cartItemList, step, value, cartStatus }: IGoogleAnalyticsCheckoutEventParams): void => {
		pushEvent({
			event: 'checkout',
			login: getGoogleAnalyticsLoginType(isLogged),
			ecommerce: {
				checkout: {
					actionField: {
						step: step.toString(),
						value,
					},
					products: getCheckoutEcommerceProductData(cartItemList, router.asPath, cartStatus),
				},
			},
		})
	}, [router.asPath, isLogged])

	const GA_purchase = useCallback((eventParams: IGoogleAnalyticsPurchaseEventParams): void => {
		const { orderData, cartStatus } = eventParams
		const { orderCostSummaryData, deliveryWayDetailsData: { deliveryWayName }, orderLines, orderNumber, customer: { email } } = orderData
		const { transportCostValue, totalCostGrossValue, taxValue } = orderCostSummaryData

		pushEvent({
			event: 'purchase',
			login: getGoogleAnalyticsLoginType(isLogged),
			ecommerce: {
				purchase: {
					actionField: {
						id: getEmptyStringForNull(orderNumber),
						revenue: totalCostGrossValue.toString(),
						tax: taxValue.toString(),
						shipping: transportCostValue?.toString() || '0',
						coupon: '', // TODO
						currency: 'PLN', // TODO
						dimension42: getEmptyStringForNull(deliveryWayName),
						email,
					},
					products: getPurchaseEcommerceProductsData(orderLines, router.asPath, cartStatus),
				},
			},
		})
	}, [router.asPath, isLogged])

	const GA_impressions = useCallback(({ products, wwwUrl }: IGoogleAnalyticsImpressionFromCatalogData): void => {
		pushEvent({
			event: 'impressions',
			ecommerce: {
				currencyCode: 'PLN', // TODO
				impressions: getGoogleAnalyticsEcommerceImpressionsData({ products, wwwUrl }),
			},
		})
	}, [])

	const GA_productClick = useCallback((productSimple: IProductSimple, position: number): void => {
		pushEvent({
			event: 'productClick',
			ecommerce: {
				click: {
					actionField: {
						list: router.asPath,
					},
					products: [getGoogleAnalyticsEcommerceImpressionData(productSimple, router.asPath, position)],
				},
			},
		})
	}, [router.asPath])

	const GA_promotionView = useCallback((bannerData: IGoogleAnalyticsPromotionEventParams, position: number): void => {
		pushEvent({
			event: 'promotion_view',
			ecommerce: {
				promoView: {
					promotions: [{
						name: bannerData.name,
						id: bannerData.id,
						creative: router.asPath,
						position,
					}],
				},
			},
		})
	}, [router.asPath])

	const GA_promotionClick = useCallback((bannerData: IGoogleAnalyticsPromotionEventParams, position: number): void => {
		pushEvent({
			event: 'promotion_click',
			ecommerce: {
				promoView: {
					promotions: [{
						name: bannerData.name,
						id: bannerData.id,
						creative: router.asPath,
						position,
					}],
				},
			},
		})
	}, [router.asPath])

	const GA_login = useCallback((userId: string): void => {
		pushEvent({
			event: 'login',
			user_id: userId,
		})
	}, [])

	const GA_account = useCallback((userId: string): void => {
		pushEvent({
			event: 'account',
			user_id: userId,
		})
	}, [])

	const GA_guestPurchase = useCallback((): void => {
		pushEvent({
			event: 'konto_goscia',
		})
	}, [])

	const GA_lys = useCallback((data: IGoogleAnalyticsLys, position: number): void => {
		pushEvent({
			event: 'lys',
			user_id: data.userId,
			lystype: data.lysType,
			lysstep: data.lysStep,
			position,
		})
	}, [])

	const GA_lysError = useCallback((data: IGoogleAnalyticsLysError, position: number): void => {
		pushEvent({
			event: 'lyserror',
			user_id: data.userId,
			lyserrortype: data.lysErrorType,
			position,
		})
	}, [])

	return {
		GA_isCatalogContext,
		GA_testSiteLegacy,
		GA_guestPurchase,
		GA_viewItem,
		GA_addToCart,
		GA_checkout,
		GA_purchase,
		GA_impressions,
		GA_productClick,
		GA_promotionView,
		GA_promotionClick,
		GA_login,
		GA_account,
		GA_contentGroup,
		GA_lys,
		GA_lysError,
	}
}
