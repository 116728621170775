import { AxiosResponse } from 'axios'

import { ISendRecoverPasswordEmailRequest, IRecoverPasswordRequest, ISignUpCustomer, IGusCustomerParams, IGusCustomerResult, ICustomerLoginExistenceResult, IGuestTransformationEmailParams, ICustomerActivateParams } from '~/api/dataTypes/account'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'

export const postPasswordSendEmail = async (params: ISendRecoverPasswordEmailRequest, reCaptchaToken: string): Promise<AxiosResponse<void>> => (
	await request.post('/v1/customers/password-send-email', params, {
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
		service: 'ACCOUNT_SERVICE',
	})
)

export const postPasswordRecovery = async (params: IRecoverPasswordRequest, reCaptchaToken: string): Promise<AxiosResponse<void>> => (
	await request.post('/v1/customers/password-recovery', params, {
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
		service: 'ACCOUNT_SERVICE',
	})
)

export const postSignUpCustomer = async (params: ISignUpCustomer, reCaptchaToken: string): Promise<AxiosResponse<void>> => (
	await request.post('/v1/customers', params, {
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
		service: 'ACCOUNT_SERVICE',
	})
)

export const getGusCustomerData = async (params: IGusCustomerParams, noCache?: string | string[]): Promise<AxiosResponse<IGusCustomerResult>> => (
	await request.get('/v1/customers/gus', {
		params: {
			...params,
			...getNoCacheParam(noCache),
		},
		service: 'ACCOUNT_SERVICE',
	})
)

export const getCustomerLoginExistence = async (login: string): Promise<AxiosResponse<ICustomerLoginExistenceResult>> => (
	await request.get('/v1/customers/login-existence', {
		params: {
			login,
		},
		service: 'ACCOUNT_SERVICE',
	})
)

export const postGuestTransformationEmail = async (params: IGuestTransformationEmailParams, reCaptchaToken: string): Promise<AxiosResponse> => (
	await request.post('/v1/customers/guest-transformation-email', params, {
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
		service: 'ACCOUNT_SERVICE',
	})
)

export const postCustomerActivation = async (params: ICustomerActivateParams, reCaptchaToken: string): Promise<AxiosResponse> => (
	await request.post('/v1/customers/activation', params, {
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
		service: 'ACCOUNT_SERVICE',
	})
)
