import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useMeasure } from 'react-use'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { isNull, size } from 'lodash'

import { SCROLL_BOTTOM_OFFSET, HeaderTopBarSearchBarProducts, IHeaderTopBarSearchBarResultsAsideProps } from '~/components/core/layout'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './HeaderTopBarSearchBarResultsAside.module.scss'

const HeaderTopBarSearchBarResultsAside = (props: IHeaderTopBarSearchBarResultsAsideProps): ReactElement => {
	const {
		products, query, onHideSearchSuggestions,
		shouldHideOnBlur = false,
	} = props
	const [containerRef, { height }] = useMeasure<HTMLDivElement>()
	const wrapperRef = useRef<HTMLDivElement>(null)
	const [offsetTop, setOffsetTop] = useState<number>(0)
	const [windowHeight, setWindowHeight] = useState<number>(0)
	const { isDesktop } = useMediaQuery()

	const productsCount = size(products)

	const handleBlur = useCallback(() => {
		if (shouldHideOnBlur) {
			onHideSearchSuggestions()
		}
	}, [shouldHideOnBlur, onHideSearchSuggestions])

	useEffect(() => {
		if (!isNull(wrapperRef.current)) {
			setOffsetTop(wrapperRef.current.getBoundingClientRect().top)
			setWindowHeight(window.innerHeight - SCROLL_BOTTOM_OFFSET)
		}
	}, [])

	const scrollHeight = windowHeight - offsetTop < height ? windowHeight - offsetTop : height

	return (
		<div className={ styles.wrapper }>
			{ isDesktop ? (
				<div ref={ wrapperRef }>
					<Scrollbars
						universal
						autoHeight
						autoHeightMin={ scrollHeight }
					>
						<div ref={ containerRef }>
							<HeaderTopBarSearchBarProducts
								products={ products }
								query={ query }
								count={ productsCount }
								handleBlur={ handleBlur }
							/>
						</div>
					</Scrollbars>
				</div>
			) : (
				<HeaderTopBarSearchBarProducts
					products={ products }
					query={ query }
					count={ productsCount }
					handleBlur={ handleBlur }
				/>
			) }
		</div>
	)
}

export { HeaderTopBarSearchBarResultsAside }
