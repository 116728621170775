import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { CloseButton } from '~/components/core/closeButton'

import styles from './HamburgerMenuHeader.module.scss'

const HamburgerMenuHeader = (): ReactElement => {
	const { t } = useTranslation(['common'])
	const { handleSetOpen } = useContext(HamburgerMenuContext)

	const handleCloseMenu = useCallback((): void => {
		handleSetOpen(false)
	}, [handleSetOpen])

	return (
		<div className={ styles.wrapper }>
			<span>
				{ t('menu') }
			</span>

			<CloseButton additionalContextAriaLabel={ t('menu') } onClose={ handleCloseMenu } />
		</div>
	)
}

export { HamburgerMenuHeader }
