import React, { ReactElement, useCallback } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import classNames from 'classnames'
import { isEqual, isNull, map } from 'lodash'

import { IMegaMenuWorldsProps, WORLDS_CONTAINER_STYLES, MegaMenuWorldsList } from '~/components/core/megamenu'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IProductCatalogData } from '~/api/dataTypes/catalog'

import styles from './MegaMenuWorlds.module.scss'

const MegaMenuWorlds = (props: IMegaMenuWorldsProps): ReactElement => {
	const { activeMegaWorld, megaWorlds, onGetNodeData, onClose } = props

	const renderWorldsList = useCallback((): ReactElement[] => (
		map(megaWorlds, (megaWorld: IProductCatalogData) => {
			const { id } = megaWorld
			const isActive = isEqual(id, activeMegaWorld?.id)

			return (
				<MegaMenuWorldsList
					key={ id }
					isActive={ isActive }
					megaWorld={ megaWorld }
					onGetNodeData={ onGetNodeData }
					onClose={ onClose }
				/>
			)
		})
	), [activeMegaWorld, megaWorlds, onGetNodeData, onClose])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.activeMegaWorld]: !isNull(activeMegaWorld),
	})

	return (
		<div
			className={ wrapperClass }
			data-testid={ DATA_TESTID.CORE.WORLDS_LIST }
			role={ isNull(activeMegaWorld) ? 'presentation' : undefined }
		>
			<Scrollbars universal style={ WORLDS_CONTAINER_STYLES }>
				{ renderWorldsList() }
			</Scrollbars>
		</div>
	)
}

export { MegaMenuWorlds }
