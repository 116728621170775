import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { HeaderTopBarSearchBarSuggestion, IHeaderTopBarSearchBarSuggestionsListProps, ISearchSuggestion } from '~/components/core/layout'

import styles from './HeaderTopBarSearchBarSuggestionsList.module.scss'

const HeaderTopBarSearchBarSuggestionsList = (props: IHeaderTopBarSearchBarSuggestionsListProps): ReactElement => {
	const { suggestions, query } = props

	const renderList = useCallback((): ReactElement[] => (
		map(suggestions, (suggestion: ISearchSuggestion) => (
			<HeaderTopBarSearchBarSuggestion
				key={ suggestion.wwwUrl }
				suggestion={ suggestion }
				query={ query }
			/>
		))
	), [suggestions])

	return (
		<div className={ styles.wrapper }>
			{ renderList() }
		</div>
	)
}

export { HeaderTopBarSearchBarSuggestionsList }
