import React, { ReactElement, useCallback, useContext, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useKeyPressEvent } from 'react-use'
import { useRouter } from 'next/router'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import { isEmpty } from 'lodash'

import { Icon } from '~/components/core/icon'
import { MegaMenu } from '~/components/core/megamenu'
import { Button } from '~/components/core/button'
import { FlatTreeContext } from '~/providers/flatTreeProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderMainMenuProducts.module.scss'

const HeaderMainMenuProducts = (): ReactElement => {
	const nodes = useContext(FlatTreeContext)
	const [megaMenuVisible, setMegaMenuVisible] = useState<boolean>(false)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const { asPath } = useRouter()
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')
	const { t } = useTranslation(['header'])

	const handleToggleMegaMenu = useCallback(async (): Promise<void> => {
		setMegaMenuVisible(!megaMenuVisible)
	}, [megaMenuVisible, nodes])

	const handleCloseMegaMenu = useCallback((): void => {
		setMegaMenuVisible(false)
	}, [])

	const handleBlur = useCallback((): void => {
		if (isShiftTabPressed) {
			handleCloseMegaMenu()
		}
	}, [isShiftTabPressed])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.megaMenuVisible]: megaMenuVisible,
	})

	useEffect(() => {
		handleCloseMegaMenu()
	}, [asPath])

	useKeyPressEvent('Escape', handleCloseMegaMenu)

	return (
		<>
			<ul>
				<li className={ wrapperClass }>
					<Button
						hasAriaPopup
						isAriaExpanded={ megaMenuVisible }
						variant="neutral"
						size="inherit"
						buttonRef={ buttonRef }
						additionalClass={ styles.button }
						dataTestId={ DATA_TESTID.HEADER.BUTTON_TOGGLE_MEGA_MENU }
						ariaLabel={ t('menu.products') }
						onClick={ handleToggleMegaMenu }
						onBlur={ handleBlur }
					>
						<Icon
							name="list"
							color="white"
							width={ 20 }
							height={ 12 }
							additionalClass={ styles.icon }
						/>

						<span className={ styles.label }>
							{ t('menu.products') }
						</span>
					</Button>
				</li>
			</ul>

			{ !isEmpty(nodes) && (
				<MegaMenu
					nodes={ nodes }
					buttonRef={ buttonRef }
					isVisible={ megaMenuVisible }
					onClose={ handleCloseMegaMenu }
				/>
			) }
		</>
	)
}

export { HeaderMainMenuProducts }
