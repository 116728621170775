import React, { Component, ReactNode, cloneElement } from 'react'
import { withTranslation } from 'react-i18next'
import { isUndefined } from 'lodash'

import { ServerError } from '~/components/core/serverError'
import { IReactErrorBoundaryState, IReactErrorBoundaryProps, IGetDerivedStateFromError } from '~/components/core/reactErrorBoundary'
import { postErrorLog } from '~/api/requests/log'
import { log } from '~/utils/logger'
import { vars } from '~/statics/variables'

class ReactErrorBoundary extends Component<IReactErrorBoundaryProps, IReactErrorBoundaryState> {
	public state: IReactErrorBoundaryState = {
		hasError: false,
		url: '',
	}

	public resetReactErrorBoundaryState = (): void => {
		this.setState({ hasError: false })
	}

	public static getDerivedStateFromError(): IGetDerivedStateFromError {
		return { hasError: true }
	}

	public async componentDidCatch(error: Error): Promise<void> {
		const { props: { url: url } } = this
		const { isDeveloperMode } = vars

		this.setState({ url })

		if (!isDeveloperMode) {
			postErrorLog(log.clientError(error, url))
		} else {
			console.log(error)
		}
	}

	public render(): ReactNode {
		const { state: { hasError, url }, props: { children, Fallback } } = this

		if (hasError) {
			return !isUndefined(Fallback) ? cloneElement(Fallback, { brokenUrl: url, onResetState: this.resetReactErrorBoundaryState }) : <ServerError />
		}

		return children
	}
}

export default withTranslation('common')(ReactErrorBoundary)
