import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { isEmpty, isEqual } from 'lodash'

import { ICartAsidePromotionProps } from '~/components/core/cartAside'
import { usePrice } from '~/hooks/price'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartAsidePromotion.module.scss'

const CartAsidePromotion = (props: ICartAsidePromotionProps): ReactElement | null => {
	const {
		promotionValue,
		promotionName = '',
		isFirstElement = false,
	} = props
	const { t } = useTranslation(['cart'])
	const { priceFormat } = usePrice()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.firstElement]: isFirstElement,
		[styles.isMozaic]: shouldUseMozaic,
	})

	const renderPromotionName = useCallback((): ReactElement => {
		if (isEmpty(promotionName)) {
			return (
				<div>
					{ t('aside.summary.promotionCode') }
				</div>
			)
		}

		return (
			<div>
				{ promotionName }
			</div>
		)
	}, [promotionName])

	if (isEqual(promotionValue, 0)) {
		return null
	}

	return (
		<div className={ wrapperClass }>
			{ renderPromotionName() }

			<div>
				{ '-' }{ priceFormat(promotionValue, { precision: 2 }) }
			</div>
		</div>
	)
}

export { CartAsidePromotion }
