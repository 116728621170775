import { createSelector } from 'reselect'

import { IRootState } from '~/state/types'
import { CustomerDataType } from '~/state/reducers/customerReducer'

export const getCustomerData = (state: IRootState): CustomerDataType => (
	state.customer.data
)

export const getIsCompanyCustomer = createSelector(getCustomerData, (data: CustomerDataType): boolean => {
	return data?.customerType === 'COMPANY'
})
