import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { HeaderTopBarSearchBarProductsList, HeaderTopBarSearchBarTitleTile, IHeaderTopBarSearchBarProductsProps } from '~/components/core/layout'

const HeaderTopBarSearchBarProducts = (props: IHeaderTopBarSearchBarProductsProps): ReactElement | null => {
	const { products, query, count, handleBlur } = props
	const { t } = useTranslation(['header'])

	if (isEmpty(products)) {
		return null
	}

	return (
		<>
			<HeaderTopBarSearchBarTitleTile title={ t('search.products') } count={ count } />

			<HeaderTopBarSearchBarProductsList
				products={ products }
				query={ query }
				handleBlur={ handleBlur }
			/>
		</>
	)
}

export { HeaderTopBarSearchBarProducts }
