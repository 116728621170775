import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { isEmpty, isEqual, isNull, map, find, isUndefined, size, toString } from 'lodash'

import { CartPreviewDeliveryEcommerceLabel, CartPreviewDeliveryListWrapper, ICartPreviewDeliveryEcommerceForm, ICartPreviewDeliveryEcommerceProps } from '~/components/cart/cartPreview'
import { Radio } from '~/components/core/form'
import { AppDispatch } from '~/state/store'
import { clearSelectedDeliveryWayId, setCartCourierNote } from '~/actions/cart'
import { ICheckPriceData, IDeliveryData, IPickupPoint } from '~/api/dataTypes/cart'
import { getCartCheckPrice } from '~/api/requests/cart'
import { useModal } from '~/hooks/modal'
import { useMozaic } from '~/hooks/mozaic'
import { IRootState } from '~/state/types'
import { CartPreviewCheckPriceModal } from '~/components/cart/cartPreview/cartPreviewCheckPrice'

import styles from './CartPreviewDeliveryEcommerce.module.scss'

const CartPreviewDeliveryEcommerce = (props: ICartPreviewDeliveryEcommerceProps): ReactElement => {
	const { items, cartData, tmpCourierNote, onChangeDelivery } = props
	const dispatch: AppDispatch = useDispatch()
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const [pickupPointId, setPickupPointId] = useState<number | null>(null)
	const [pickupPointData, setPickupPointData] = useState<IPickupPoint | null>(null)
	const { isOpen: isOpenCheckPriceModal, handleOpen, handleClose } = useModal('CartPreviewCheckPriceModal')
	const [checkPriceData, setCheckPriceData] = useState<ICheckPriceData | null>(null)
	const { getShouldUseMozaicFlag } = useMozaic()

	const { cart: { selectedDeliveryWayId, selectedDeliveryWayType, pickupPoint }, courierNote } = cartData
	const defaultValue = isUndefined(find(items, (item: IDeliveryData) => isEqual(item.id, selectedDeliveryWayId))) || isNull(selectedDeliveryWayId) ? '' : toString(selectedDeliveryWayId)

	const schema: yup.SchemaOf<ICartPreviewDeliveryEcommerceForm> = useMemo(() => yup.object().shape({
		value: yup.string().required(),
	}), [])

	const { control, watch, setValue } = useForm<ICartPreviewDeliveryEcommerceForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			value: defaultValue,
		},
	})

	const { field, fieldState: { error } } = useController({ control, name: 'value' })
	const { value } = watch()

	const handleSetPickupPointId = useCallback((pointId: number | null): void => {
		setPickupPointId(pointId)
		onChangeDelivery({ deliveryId: Number(value), pickupPointId: pointId })
	}, [onChangeDelivery, value])

	const handleSetPickupPoint = useCallback((data: IPickupPoint) => {
		setPickupPointData(data)
		onChangeDelivery({ deliveryId: Number(value), pickupPoint: { type: data.type, code: data.code } })
	}, [onChangeDelivery, value])

	const handleSetCourierNote = useCallback(async (courierNote: string): Promise<void> => {
		await dispatch(setCartCourierNote(courierNote))
	}, [])

	const handleConfirmChangePrice = useCallback(async (value: string): Promise<void> => {
		const clearCondition = isEmpty(value) || isUndefined(items.find((item: IDeliveryData) => isEqual(item.id, Number(value))))

		if (clearCondition) {
			await dispatch(clearSelectedDeliveryWayId())
		} else {
			onChangeDelivery({
				deliveryId: Number(value),
				pickupPointId: !isNull(pickupPoint) ? pickupPoint.id : null,
			})
		}
	}, [value, items, pickupPoint])

	const handleSetDeliveryWayId = useCallback(async (): Promise<void> => {
		if (isEqual(selectedDeliveryWayType, 'PICKUP_IN_STORE') && !isEmpty(value) && !isEqual(value, '0')) {
			const { data } = await getCartCheckPrice({
				storeCode: preferredStore?.storeCode,
				deliveryWayId: Number(value),
			})

			if (!isEmpty(data.newSelection.itemList)) {
				setCheckPriceData({
					data,
					onConfirm: () => handleConfirmChangePrice(value),
				})

				handleOpen()
				setValue('value', defaultValue)

				return
			}
		}

		await handleConfirmChangePrice(value)
	}, [value, selectedDeliveryWayType, handleConfirmChangePrice, defaultValue])

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	const renderItems = useCallback((): ReactElement[] => (
		map(items, (option: IDeliveryData) => {
			const { id, isAllowed } = option

			const isChecked = isEqual(field.value, id.toString())

			const labelClass = classNames(styles.deliveryLabel, {
				[styles.selected]: isChecked,
				[styles.disabled]: !isAllowed,
			})

			return (
				<div key={ id } className={ wrapperClass }>
					<Radio
						value={ id.toString() }
						field={ field }
						error={ error }
						isDisabled={ !isAllowed }
						additionalClass={ labelClass }
					>
						<CartPreviewDeliveryEcommerceLabel
							item={ option }
							isChecked={ isChecked }
							error={ error }
							tmpCourierNote={ tmpCourierNote }
							pickupPointId={ pickupPointId }
							pickupPointData={ pickupPoint }
							onSetCourierNote={ handleSetCourierNote }
							onSetPickupPointId={ handleSetPickupPointId }
							onSetPickupPoint={ handleSetPickupPoint }
						/>
					</Radio>
				</div>
			)
		})
	), [items, field, courierNote, tmpCourierNote, pickupPointId, pickupPointData])

	useEffect(() => {
		(async () => {
			await handleSetCourierNote(!isNull(courierNote) ? courierNote : '')
		})()
	}, [courierNote])

	useEffect(() => {
		(async () => {
			await handleSetDeliveryWayId()
		})()
	}, [value])

	useEffect(() => {
		if (!isNull(selectedDeliveryWayId)) {
			setValue('value', selectedDeliveryWayId.toString())
		}
	}, [selectedDeliveryWayId])

	useEffect(() => {
		if (!isNull(pickupPoint)) {
			setPickupPointId(pickupPoint?.id)
		}
	}, [])

	return (
		<>
			<CartPreviewDeliveryListWrapper itemsCount={ size(items) }>
				{ renderItems() }
			</CartPreviewDeliveryListWrapper>

			<CartPreviewCheckPriceModal
				isOpen={ isOpenCheckPriceModal }
				itemsData={ checkPriceData }
				type="changeDelivery"
				onClose={ handleClose }
			/>
		</>
	)
}

export { CartPreviewDeliveryEcommerce }
