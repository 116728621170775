import { localStorageGet } from '~/utils/storage'
import { LS_KEY_PRODUCT_CARD_OPINION_VOTES } from '~/utils/constants'
import { ProductCardVoteOpinionState } from '~/state/reducers/productCardReducer'

const defaultState: ProductCardVoteOpinionState = {
	userUuid: null,
	votedReviewList: [],
}

export const productCardVoteOpinionInitialState: ProductCardVoteOpinionState = (
	localStorageGet<ProductCardVoteOpinionState>(LS_KEY_PRODUCT_CARD_OPINION_VOTES) || defaultState
)
