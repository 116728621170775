import React, { ReactElement, useCallback, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useTranslation } from 'next-i18next'
import { lt, map, size, sortBy } from 'lodash'

import { ISimpleStoreResponse } from '~/api/dataTypes/store'
import { useDistance } from '~/hooks/distance'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { IPreferredStoreDistanceListProps, PreferredStoreDistanceListItem, STORES_COUNTER, SCROLLBAR_MAX_HEIGHT } from '~/components/core/preferredStore'
import { Button } from '~/components/core/button'

import styles from './PreferredStoreDistanceList.module.scss'

const PreferredStoreDistanceList = (props: IPreferredStoreDistanceListProps): ReactElement => {
	const { coords, items, onSelect } = props
	const { t } = useTranslation(['common'])
	const [visibleStores, setVisibleStores] = useState<number>(STORES_COUNTER)
	const { isTablet } = useMediaQuery()
	const { calculateDistance } = useDistance()

	const stores: ISimpleStoreResponse[] = sortBy(items, (item: ISimpleStoreResponse) => calculateDistance({ lat: coords.latitude, lng: coords.longitude }, { lat: item.address.latitude, lng: item.address.longitude }))

	const canGetMoreStores = lt(visibleStores, size(stores))
	const maxHeight = isTablet ? SCROLLBAR_MAX_HEIGHT.SHORT : SCROLLBAR_MAX_HEIGHT.LONG

	const handleGetMoreStores = useCallback((): void => {
		setVisibleStores(visibleStores + STORES_COUNTER)
	}, [visibleStores])

	const renderItems = useCallback((): ReactElement => (
		<div className={ styles.list }>
			{ map(stores, (store: ISimpleStoreResponse, index: number) => {
				if (lt(index, visibleStores)) {
					return (
						<PreferredStoreDistanceListItem
							key={ store.id }
							store={ store }
							coords={ coords }
							onSelect={ onSelect }
						/>
					)
				}
			}) }
		</div>
	), [stores, visibleStores, coords, onSelect])

	return (
		<div className={ styles.wrapper }>
			<Scrollbars
				universal
				autoHeight
				autoHeightMax={ maxHeight }
				className={ styles.scrollbar }
			>
				{ renderItems() }
			</Scrollbars>

			{ canGetMoreStores && (
				<Button
					text={ t('showMore') }
					variant="neutral"
					size="inherit"
					additionalClass={ styles.button }
					onClick={ handleGetMoreStores }
				/>
			) }
		</div>
	)
}

export { PreferredStoreDistanceList }
