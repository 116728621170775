import React, { ReactElement } from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { IPasswordInputProps, Input, PasswordInputValidationList, getPasswordAriaLabelledBy } from '~/components/core/form'

const PasswordInput = <FormFields extends FieldValues = FieldValues>(props: IPasswordInputProps<FormFields>): ReactElement => {
	const {
		inlineValidation, name, control,
		theme = {},
		...restProps
	} = props
	const { field, formState } = useController({ control, name })

	const id = getPasswordAriaLabelledBy(name)

	return (
		<>
			<Input
				control={ control }
				name={ name }
				type="password"
				additionalClass={ theme.input }
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...restProps }
				ariaLabelledBy={ id }
			/>

			{ inlineValidation && (
				<PasswordInputValidationList
					password={ field.value }
					isValidated={ formState.isDirty }
					inputsId={ id }
					theme={ { wrapper: theme.validationList } }
				/>
			) }
		</>
	)
}

export { PasswordInput }
