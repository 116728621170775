import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { ICartPreviewDeliveryPickupTransferTopBarRealizationProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryPickupTransferTopBarRealization.module.scss'

const CartPreviewDeliveryPickupTransferTopBarRealization = (props: ICartPreviewDeliveryPickupTransferTopBarRealizationProps): ReactElement => {
	const { processingTime } = props
	const { t } = useTranslation(['cart'])

	const renderContent = useCallback((): ReactElement => {
		if (!isEmpty(processingTime)) {
			return (
				<span>
					{ processingTime }
				</span>
			)
		}

		return (
			<>
				<span>
					{ t('preview.delivery.groups.storeDelivery.realizationTime') }
				</span>

				<span className={ styles.highlight }>
					{ t('preview.delivery.groups.storeDelivery.after24h') }
				</span>
			</>
		)
	}, [processingTime])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTopBarRealization }
