import React, { ReactElement } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import classNames from 'classnames'
import { isEmpty, map } from 'lodash'

import { ErrorBoundary, Radio, IRadioGroupProps, IRadioOption, Label } from '~/components/core/form'

import styles from './RadioGroup.module.scss'

const RadioGroup = <FormFields extends FieldValues = FieldValues>(props: IRadioGroupProps<FormFields>): ReactElement => {
	const {
		control, name, options,
		label = '',
		isRequired = false,
		isDisabled = false,
		theme = {},
		onChange = undefined,
	} = props
	const { field, fieldState: { error } } = useController<FormFields>({ control, name })

	const hasLabel = !isEmpty(label)

	const renderOptions = (): ReactElement => (
		<>
			{ map(options, (option: IRadioOption) => {
				const { value, label, rightAccessory } = option

				const labelClass = classNames(styles.label, theme.label)

				return (
					<Radio
						key={ value }
						value={ value }
						field={ field }
						label={ label }
						rightAccessory={ rightAccessory }
						error={ error }
						isRequired={ isRequired }
						isDisabled={ isDisabled }
						additionalClass={ labelClass }
						onChange={ onChange }
					/>
				)
			}) }
		</>
	)

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div className={ wrapperClass }>
			{ hasLabel && <Label name={ name } caption={ label } /> }

			{ renderOptions() }

			<ErrorBoundary error={ error } additionalClass={ styles.error } />
		</div>
	)
}

export { RadioGroup }
