import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isNull } from 'lodash'

import { ICartCustomerDataFormFieldWrapperProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerDataFormFieldWrapper.module.scss'

const CartCustomerDataFormFieldWrapper = (props: ICartCustomerDataFormFieldWrapperProps): ReactElement | null => {
	const {
		children,
		additionalClass = '',
	} = props

	if (isNull(children)) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, additionalClass)

	return (
		<div className={ wrapperClass }>
			{ children }
		</div>
	)
}

export { CartCustomerDataFormFieldWrapper }
