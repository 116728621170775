import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { ICartPreviewSummaryDiscountModalProps } from '~/components/cart/cartPreview'
import { Modal } from '~/components/core/modal'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './CartPreviewSummaryDiscountModal.module.scss'

const CartPreviewSummaryDiscountModal = (props: ICartPreviewSummaryDiscountModalProps): ReactElement | null => {
	const { children, isLogged, isOpen, isStepZero, onClose } = props
	const { isTablet } = useMediaQuery()
	const { t } = useTranslation(['cart'])

	const caption = useMemo((): string => {
		if (isLogged) {
			return t('preview.aside.discount.loggedCaption')
		} else if (isStepZero) {
			return t('zero.aside.discount.notLoggedCaption')
		}

		return t('preview.aside.discount.notLoggedCaption')
	}, [isLogged, isStepZero])

	if (isTablet) {
		return null
	}

	const contentClass = classNames(styles.content, {
		[styles.legend]: isStepZero,
	})

	return (
		<Modal
			additionalClass={ styles.wrapper }
			isOpen={ isOpen }
			onClose={ onClose }
		>
			<span className={ styles.headerCaption }>
				{ caption }
			</span>

			<div className={ contentClass }>
				{ children }
			</div>
		</Modal>
	)
}

export { CartPreviewSummaryDiscountModal }
