import { getCartUuid } from '~/utils/cart'

import { IMiniCartState } from './types'

export const miniCartInitialState: IMiniCartState = {
	data: {
		cartItemList: [],
		cartUuid: getCartUuid(),
		totalCartValue: 0,
		wwwUrl: '',
		cartStatus: 'VIEW',
		productsValue: 0,
		numberOfItems: 0,
		productsQuantityMap: {},
	},
	addedToCart: null,
	isPending: false,
}
