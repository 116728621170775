import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { ProductsCarousel } from '~/components/core/productsCarousel'
import { SectionHeader } from '~/components/core/sectionHeader'
import { IAddToCartModalProductCarouselProps, ADD_TO_CART_PRODUCTS_CAROUSEL_PARAMS } from '~/components/core/addToCartModal'

import styles from './AddToCartModalProductCarousel.module.scss'

const AddToCartModalProductCarousel = (props: IAddToCartModalProductCarouselProps): ReactElement | null => {
	const { products } = props
	const { t } = useTranslation(['common'])

	if (isEmpty(products)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<SectionHeader title={ t('lastViewed.caption') } size="small" />

			<ProductsCarousel
				products={ products }
				theme={ { wrapper: styles.product, checkAvailabilityButton: styles.checkAvailabilityButton } }
				params={ ADD_TO_CART_PRODUCTS_CAROUSEL_PARAMS }
				title={ t('lastViewed.caption') }
			/>
		</div>
	)
}

export { AddToCartModalProductCarousel }
