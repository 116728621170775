import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { NumberFormatValues } from 'react-number-format'
import { isUndefined, debounce, isNull, gt, lte } from 'lodash'

import { BasePriceUnitEnum, DEBOUNCE_DELAY, ICartPreviewProductsQuantityBlockProps } from '~/components/cart/cartPreview'
import { CartPreviewProductsQuantityField } from '~/components/cart/cartPreview/cartPreviewProducts/cartPreviewProductsQuantityField'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { vars } from '~/statics'
import { useDebounce } from '~/hooks/debounce'
import { useCartPreviewCalculate } from '~/hooks/cartPreviewCalculate'
import { DATA_TESTID } from '~/utils/dataTestId'
import { calculateProductQuantityPackages, calculateProductQuantitySquareMeters } from '~/utils/calculateProductQuantity'
import { useKobiHeader } from '~/hooks/kobiHeader'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsQuantityBlock.module.scss'

const CartPreviewProductsQuantityBlock = (props: ICartPreviewProductsQuantityBlockProps): ReactElement => {
	const { quantity, item, priceUnit, isNotEnoughItem } = props
	const { t } = useTranslation(['cart'])
	const [quantityValue, setQuantityValue] = useState<number>(quantity)
	const [quantityByAreaValue, setQuantityByAreaValue] = useState<number>(calculateProductQuantitySquareMeters(quantity, item.productSimple.price.packageQuantity))
	const { onToggleQuantity } = useCartPreviewCalculate()
	const { handleDispatchKobiCartHeader } = useKobiHeader()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { itemId, productSimple: { price: { packageQuantity, basePriceUnit } } } = item
	const isDecrementButtonDisabled = quantityValue === 1
	const decrementButtonIconColor: keyof typeof vars.colors = isDecrementButtonDisabled ? 'black10' : 'green'
	const multiplier = packageQuantity !== 1 ? packageQuantity : null
	const shouldCalculateByArea = basePriceUnit === BasePriceUnitEnum.SQUARE_METERS
	const decrementIconWidth = shouldUseMozaic ? 12 : 9
	const incrementIconWidth = shouldUseMozaic ? 14 : 10

	const handleSetProductQuantity = useCallback(useDebounce(async (): Promise<void> => {
		if (isUndefined(quantityValue) || lte(quantityValue, 0)) {
			await setQuantityValue(0)
		} else {
			await onToggleQuantity(quantityValue, item)
		}
	}, DEBOUNCE_DELAY), [quantityValue, item])

	const handleQuantityChange = useCallback((newQuantityValue: number): void => {
		const area = calculateProductQuantitySquareMeters(newQuantityValue, packageQuantity)

		setQuantityByAreaValue(area)
		setQuantityValue(newQuantityValue)
	}, [packageQuantity])

	const handleIncrement = useCallback((): void => {
		if (!isUndefined(quantityValue)) {
			const oneMore = quantityValue + 1

			handleQuantityChange(oneMore)
		}
	}, [quantityValue])

	const handleDecrement = useCallback((): void => {
		if (gt(quantityValue, 0)) {
			const oneLess = quantityValue - 1

			handleQuantityChange(oneLess)
		}
	}, [quantityValue])

	const handleInputChange = useCallback(debounce((values: NumberFormatValues): void => {
		const { floatValue } = values

		if (!isUndefined(floatValue)) {
			setQuantityValue(floatValue)
			handleSetProductQuantity()
		}
	}, DEBOUNCE_DELAY), [handleSetProductQuantity])

	const handleAreaInputChange = useCallback(debounce((values: NumberFormatValues): void => {
		const { floatValue } = values
		const isMultiplierValid = !isNull(multiplier) && gt(multiplier, 1)

		if (!isUndefined(floatValue) && isMultiplierValid) {
			const quantityFromAreaCalculation = calculateProductQuantityPackages(floatValue, multiplier)

			setQuantityValue(quantityFromAreaCalculation)
			setQuantityByAreaValue(floatValue)
			handleSetProductQuantity()
		}
	}, DEBOUNCE_DELAY), [handleSetProductQuantity, multiplier])

	useEffect(() => {
		setQuantityValue(quantity)

		handleDispatchKobiCartHeader()
	}, [quantity, handleDispatchKobiCartHeader])

	useEffect(() => {
		if (shouldCalculateByArea && !isNull(multiplier) && !isUndefined(quantityValue)) {
			const area = calculateProductQuantitySquareMeters(quantityValue, packageQuantity)

			setQuantityByAreaValue(area)
		}
	}, [])

	const decrementButtonClass = classNames(styles.decrementButton, {
		[styles.disabled]: isDecrementButtonDisabled,
		[styles.isMozaic]: shouldUseMozaic,
	})

	const incrementButtonClass = classNames(styles.incrementButton, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const unitClass = classNames(styles.unit, {
		[styles.notEnough]: isNotEnoughItem,
	})

	const iconClass = classNames(styles.icon, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const actionsClass = classNames(styles.actions, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const renderQuantityInfo = useCallback((): ReactElement => {
		if (shouldCalculateByArea) {
			return (
				<div className={ styles.quantityByAreaLabel }>
					<span className={ styles.packageQuantityInfo }>
						{ `${ t('preview.table.singlePackageAreaQuantity') } ${ packageQuantity } ${ t('summary.table.squareMetersUnit') }` }
					</span>

					<span className={ styles.packageQuantityInfo }>
						{ `${ t('preview.table.packagesQuantity') } ${ quantityValue }` }
					</span>
				</div>
			)
		}

		return (
			<div className={ unitClass }>
				{ priceUnit }
			</div>
		)
	}, [shouldCalculateByArea, quantityValue, packageQuantity, priceUnit])

	return (
		<div className={ styles.wrapper }>
			<div className={ actionsClass }>
				<Button
					size="inherit"
					variant="neutral"
					isDisabled={ isDecrementButtonDisabled }
					additionalClass={ decrementButtonClass }
					ariaLabel={ t('preview.table.decrement') }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_DECREMENT_PRODUCT_QUANTITY }
					onClick={ handleDecrement }
				>
					<Icon
						name="minus"
						width={ decrementIconWidth }
						height={ 2 }
						color={ decrementButtonIconColor }
						additionalClass={ iconClass }
					/>
				</Button>

				<CartPreviewProductsQuantityField
					isNotEnoughItem={ isNotEnoughItem }
					shouldCalculateByArea={ shouldCalculateByArea }
					multiplier={ multiplier }
					itemId={ itemId }
					basePriceUnit={ basePriceUnit }
					quantityValue={ quantityValue }
					quantityByAreaValue={ quantityByAreaValue }
					handleInputChange={ handleInputChange }
					handleAreaInputChange={ handleAreaInputChange }
				/>

				<Button
					size="inherit"
					variant="neutral"
					additionalClass={ incrementButtonClass }
					ariaLabel={ t('preview.table.increment') }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_INCREMENT_PRODUCT_QUANTITY }
					onClick={ handleIncrement }
				>
					<Icon
						name="plus"
						width={ incrementIconWidth }
						height={ 10 }
						additionalClass={ iconClass }
					/>
				</Button>
			</div>

			{ renderQuantityInfo() }
		</div>
	)
}

export { CartPreviewProductsQuantityBlock }
