import React, { ReactElement, useCallback, useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { AxiosError } from 'axios'
import { isEmpty, isNil } from 'lodash'

import { IAddToCartResponseException } from '~/api/dataTypes/cart'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useLogError } from '~/hooks/logError'
import { useAlert } from '~/hooks/alert'
import { Button } from '~/components/core/button'
import { IAddToCartButtonProps } from '~/components/core/addToCartButton'
import { addProductToCart } from '~/actions/cart'
import { AddToCartModalContext } from '~/providers/addToCartModalProvider'
import { useSare } from '~/hooks/sare'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './AddToCartButton.module.scss'

const AddToCartButton = (props: IAddToCartButtonProps): ReactElement => {
	const {
		productSimple, priceValue,
		quantity = 1,
		isReverse = false,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['common'])
	const { handleOpen, handleSetActiveProduct } = useContext(AddToCartModalContext)
	const dispatch: AppDispatch = useDispatch()
	const { data } = useSelector((state: IRootState) => state.miniCart, shallowEqual)
	const [isLoading, setIsLoading] = useToggle(false)
	const { newAlert } = useAlert()
	const { GA_addToCart } = useGoogleAnalytics()
	const { sendLogError } = useLogError()
	const { SARE_addToCart } = useSare()

	const { id: productId, lmReference } = productSimple
	const { cartUuid } = data

	const buttonClass = classNames(styles.button, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	const handleAction = useCallback(async () => {
		const totalPriceValue = priceValue * quantity

		try {
			setIsLoading(true)
			await dispatch(addProductToCart({ cartUuid, lmReference, quantity }))
			await handleSetActiveProduct({ productId, quantity })
			handleOpen()
			GA_addToCart({ product: productSimple, value: totalPriceValue, quantity })
			SARE_addToCart(productSimple)
		} catch (e: unknown) {
			const error = e as AxiosError<IAddToCartResponseException>
			const { message } = error

			if (isNil(message)) {
				newAlert('danger', <div dangerouslySetInnerHTML={ { __html: message } } />)
			}

			sendLogError(error)
		} finally {
			setIsLoading(false)
		}
	}, [handleOpen, data, quantity, priceValue, productSimple])

	return (
		<Button
			isReverse={ isReverse }
			text={ t('addToCart') }
			additionalClass={ buttonClass }
			isLoading={ isLoading }
			dataTestId={ DATA_TESTID.CORE.BUTTON_ADD_TO_CART }
			onClick={ handleAction }
		/>
	)
}

export { AddToCartButton }
