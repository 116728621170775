import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { ICartCustomerDataMainItemPrivHeaderProps } from '~/components/cart/cartCustomer'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerDataMainItemPrivHeader.module.scss'

const CartCustomerDataMainItemPrivHeader = (props: ICartCustomerDataMainItemPrivHeaderProps): ReactElement | null => {
	const { firstName, lastName } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	if (isEmpty(firstName) || isEmpty(lastName)) {
		return null
	}

	return (
		<div className={ wrapperClass }>
			{ firstName }{ ' ' }{ lastName }
		</div>
	)
}

export { CartCustomerDataMainItemPrivHeader }
