import { deleteCookie, setCookie } from 'cookies-next'

import { COOKIE_KEY_CUSTOMER_UUID, COOKIE_MAX_AGE_ONE_YEAR } from '~/utils/constants'
import { IAdeoCookies, ICustomerUuidCookieSetOptions } from '~/utils/types'

export const adeoCookies: IAdeoCookies = {
	customerUuid: {
		set: ({ cartUuid, hostname }: ICustomerUuidCookieSetOptions): void => setCookie(COOKIE_KEY_CUSTOMER_UUID, cartUuid, { ...adeoCookies.customerUuid.options, domain: `.${ hostname.replace('www.', '') }` }),
		delete: (hostname: string): void => deleteCookie(COOKIE_KEY_CUSTOMER_UUID, { ...adeoCookies.customerUuid.options, domain: `.${ hostname.replace('www.', '') }` }),
		options: {
			maxAge: COOKIE_MAX_AGE_ONE_YEAR,
			secure: true,
		},
	},
}
