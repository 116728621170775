import { useTranslation } from 'next-i18next'

import { ISelectOption } from '~/components/core/form'
import { IUseCountriesData, CountriesShortMapType } from '~/hooks/countries'

export const useCountries = (): IUseCountriesData => {
	const { t } = useTranslation(['common'])

	const countriesSelectOptions: ISelectOption[] = [
		{ value: 'PL', label: t('countries.poland') },
	]

	const countriesShortsMap: CountriesShortMapType = {
		PL: t('countries.poland'),
	}

	return {
		countriesSelectOptions,
		countriesShortsMap,
	}
}
