import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { AdditionalQueryParamsType, IUseGoToSignInData } from '~/hooks/goToSignIn'

export const useGoToSignIn = (): IUseGoToSignInData => {
	const { asPath, push } = useRouter()
	const { urls } = useContext(AppParametersContext)

	const handleGoToSignInPage = useCallback(async (additionalQueryParams?: AdditionalQueryParamsType): Promise<void> => {
		const isHtml = asPath.endsWith('.html')

		await push({
			pathname: urls.login,
			query: {
				from: isHtml ? asPath.replace('.html', '') : asPath,
				isHtml,
				...additionalQueryParams,
			},
		})
	}, [asPath, urls])

	return {
		onGoToSignInPage: handleGoToSignInPage,
	}
}
