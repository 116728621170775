import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { ITokenResult } from '~/api/dataTypes/auth'
import { IAuthenticationResult, IExtractTokensResult, IRefreshTokensResult } from '~/api/dataTypes/authAdeo'
import { IRootState } from '~/state/types'

export enum AuthActionType {
	SET_AUTHENTICATE_TOKEN = 'auth/SET_AUTHENTICATE_TOKEN',
	SET_AUTHENTICATE_REFRESH_TOKEN = 'auth/SET_AUTHENTICATE_REFRESH_TOKEN',
	SET_LOGOUT = 'auth/SET_LOGOUT',
}

export enum AuthAdeoActionType {
	GET_ADEO_AUTHENTICATE = 'auth/GET_ADEO_AUTHENTICATE',
	SET_ADEO_EXTRACT_TOKENS = 'auth/SET_ADEO_EXTRACT_TOKENS',
	SET_ADEO_REFRESH_TOKENS = 'auth/SET_ADEO_REFRESH_TOKENS',
	SET_ADEO_LOGOUT = 'auth/SET_ADEO_LOGOUT',
	SET_LOGIN_BY_ADEO = 'auth/SET_LOGIN_BY_ADEO',
}

export type GetAuthenticateTokenActionType = AppAction<AuthActionType.SET_AUTHENTICATE_TOKEN, AxiosResponse<ITokenResult>>

export type GetAuthenticateRefreshTokenActionType = AppAction<AuthActionType.SET_AUTHENTICATE_REFRESH_TOKEN, AxiosResponse<ITokenResult>>

export type SetLogoutActionType = AppAction<AuthActionType.SET_LOGOUT>

export type GetAdeoAuthenticateActionType = AppAction<AuthAdeoActionType.GET_ADEO_AUTHENTICATE, AxiosResponse<IAuthenticationResult>>

export type SetAdeoExtractTokensActionType = AppAction<AuthAdeoActionType.SET_ADEO_EXTRACT_TOKENS, AxiosResponse<IExtractTokensResult>>

export type SetAdeoRefreshTokensActionType = AppAction<AuthAdeoActionType.SET_ADEO_REFRESH_TOKENS, AxiosResponse<IRefreshTokensResult>>

export type SetAdeoLogoutActionType = AppAction<AuthAdeoActionType.SET_ADEO_LOGOUT>

export type SetLoginByAdeoType = AppAction<AuthAdeoActionType.SET_LOGIN_BY_ADEO, boolean>

export type AuthActionUnionType = GetAuthenticateTokenActionType | GetAuthenticateRefreshTokenActionType | SetLogoutActionType | GetAdeoAuthenticateActionType | SetAdeoExtractTokensActionType | SetAdeoRefreshTokensActionType | SetLoginByAdeoType | SetAdeoLogoutActionType

export interface IGetAuthenticateTokenActionParams {
	username: string
	password: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FresherTokenPromiseAction = (action: any) => (...args: any) => (dispatch: ThunkDispatch<IRootState, unknown, AnyAction>, getState: () => IRootState) => Promise<any>
