import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { IHeaderTopBarUserAccountNotLoggedProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { useGoToSignIn } from '~/hooks/goToSignIn'
import { useAdeoSignIn } from '~/hooks/signIn'

import styles from './HeaderTopBarUserAccountNotLogged.module.scss'

const HeaderTopBarUserAccountNotLogged = (props: IHeaderTopBarUserAccountNotLoggedProps): ReactElement => {
	const {
		isShopVersion, onClose,
		theme = {},
	} = props
	const { t } = useTranslation(['common'])
	const router = useRouter()
	const { urls: { registration } } = useContext(AppParametersContext)
	const { onGoToSignInPage } = useGoToSignIn()
	const { handleSignInByAdeo, shouldLoginByAdeo } = useAdeoSignIn()

	const handleGoToSignInPage = useCallback(async (): Promise<void> => {
		onClose()

		if (shouldLoginByAdeo) {
			await handleSignInByAdeo(router.asPath)
		} else {
			isShopVersion ? await onGoToSignInPage({ isShopVersion }) : await onGoToSignInPage()
		}
	}, [onClose, onGoToSignInPage, handleSignInByAdeo, shouldLoginByAdeo, router.asPath, isShopVersion])

	const handleGoToRegisterPage = useCallback(async (): Promise<void> => {
		onClose()

		if (shouldLoginByAdeo) {
			await handleSignInByAdeo(router.asPath)
		} else {
			await router.push(registration)
		}
	}, [onClose, registration, shouldLoginByAdeo, router.asPath])

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<section className={ wrapperClass }>
			<Button
				variant="secondary"
				text={ t('signIn') }
				size="small"
				onClick={ handleGoToSignInPage }
			/>

			<footer className={ styles.registrationWrapper }>
				<span className={ styles.question }>
					{ t('newClientQuestion') }
				</span>

				<Button
					isReverse
					variant="secondary"
					text={ t('createAccount') }
					size="small"
					additionalClass={ styles.button }
					onClick={ handleGoToRegisterPage }
					onBlur={ onClose }
				/>
			</footer>
		</section>
	)
}

export { HeaderTopBarUserAccountNotLogged }
