import { map } from 'lodash'

import { ICartItemData } from '~/api/dataTypes/cart'

export const getNotAvailableSplitProducts = (itemsAvailable: ICartItemData[], itemsNotAvailable: ICartItemData[]): ICartItemData[] => {
	const notAvailableSplitProducts: ICartItemData[] = []

	map(itemsAvailable, (item: ICartItemData) => {
		const { quantityInfo, value, valueForLine } = item
		const { possibleQuantity, selectedQuantity } = quantityInfo

		if (selectedQuantity > possibleQuantity) {
			const restQuantity = selectedQuantity - possibleQuantity
			const restValue = valueForLine - value

			notAvailableSplitProducts.push({ ...item, quantityInfo: { ...quantityInfo, possibleQuantity: restQuantity }, value: restValue })
		}
	})

	map(itemsNotAvailable, (item: ICartItemData) => {
		const { quantityInfo, valueForLine } = item
		const { selectedQuantity } = quantityInfo

		notAvailableSplitProducts.push({ ...item, quantityInfo: { ...quantityInfo, possibleQuantity: selectedQuantity }, value: valueForLine })
	})

	return notAvailableSplitProducts
}
