import React, { ReactElement } from 'react'

import { CartCustomerAsideBlockProductImage, CartCustomerAsideBlockProductName, CartCustomerAsideBlockProductQuantity, ICartCustomerSummaryOrderProductProps } from '~/components/cart/cartCustomer'
import { usePrice } from '~/hooks/price'

import styles from './CartCustomerAsideBlockProduct.module.scss'

const CartCustomerAsideBlockProduct = (props: ICartCustomerSummaryOrderProductProps): ReactElement => {
	const { cartItem } = props
	const { priceFormat } = usePrice()

	const { productSimple, quantityInfo: { selectedQuantity }, saleUnitName } = cartItem
	const { name, image: { thumbnailUrl }, wwwUrl, price } = productSimple
	const { newPrice } = price

	return (
		<div className={ styles.wrapper }>
			<CartCustomerAsideBlockProductImage name={ name } image={ thumbnailUrl } />

			<div className={ styles.content }>
				<CartCustomerAsideBlockProductName name={ name } wwwUrl={ wwwUrl } />

				<CartCustomerAsideBlockProductQuantity quantity={ selectedQuantity } priceUnit={ saleUnitName } />

				<div className={ styles.price }>
					{ priceFormat(newPrice) }
				</div>
			</div>
		</div>
	)
}

export { CartCustomerAsideBlockProduct }
