import React, { createContext, ReactElement } from 'react'

import { IPageTypeProviderProps } from '~/providers/pageTypeProvider/types'
import { PageType } from '~/statics'

const initialProps: PageType = '' as PageType

export const PageTypeContext = createContext(initialProps)

export const PageTypeProvider = (props: IPageTypeProviderProps): ReactElement => {
	const { children, value } = props

	return (
		<PageTypeContext.Provider value={ value }>
			{ children }
		</PageTypeContext.Provider>
	)
}
