import React, { ReactElement, useState, useEffect, useRef, useCallback } from 'react'
import { Swiper as SwiperCore, Navigation, Pagination } from 'swiper'
import { Swiper } from 'swiper/react'
import { SwiperOptions, NavigationOptions } from 'swiper/types'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import classNames from 'classnames'
import { isEmpty, isNull } from 'lodash'

import { useSkipElements } from '~/hooks/skipElements'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { CarouselNavigation, ICarouselProps } from '~/components/core/carousel'
import { SkipContentList } from '~/components/core/skipContent'

import styles from './Carousel.module.scss'

const Carousel = (props: ICarouselProps): ReactElement | null => {
	const {
		children,
		title = '',
		params = {},
		theme = {},
		additionalClass = '',
		isWithoutGradient = false,
		isWithoutNavigation = false,
	} = props
	const [swiperRef, setSwiperRef] = useState<SwiperCore>()
	const [showPrevButton, setShowPrevButton] = useState<boolean>(true)
	const [showNextButton, setShowNextButton] = useState<boolean>(true)
	const [isSwiperVisible, setIsSwiperVisible] = useState<boolean>(false)
	const prevElRef = useRef<HTMLButtonElement>(null)
	const nextElRef = useRef<HTMLButtonElement>(null)
	const { isTablet } = useMediaQuery()
	const { carouselSkip } = useSkipElements(title)

	const navigation: NavigationOptions | boolean = isTablet ? {
		prevEl: prevElRef.current,
		nextEl: nextElRef.current,
	} : false

	const swiperParams: SwiperOptions = {
		modules: [Navigation, Pagination],
		navigation,
		pagination: {
			clickable: true,
		},
		...params,
		init: false,
	}

	const handleSetNavigationVisibility = (swiper: SwiperCore): void => {
		const { isBeginning, isEnd } = swiper

		setShowPrevButton(!isBeginning && !!swiperParams.navigation)
		setShowNextButton(!isEnd && !!swiperParams.navigation)
	}

	const handleSetSwiperRef = async (swiper: SwiperCore): Promise<void> => {
		setSwiperRef(swiper)
		await setIsSwiperVisible(true)
		handleSetNavigationVisibility(swiper)
	}

	const handleChangeSlide = (swiper: SwiperCore): void => {
		handleSetNavigationVisibility(swiper)
	}

	const renderSkipSection = useCallback((): ReactElement | null => (
		!isEmpty(children) ? (
			<SkipContentList skipElements={ carouselSkip } theme={ theme?.skipContent } />
		) : null
	), [children, carouselSkip, theme?.skipContent])

	useEffect(() => {
		swiperRef?.init()
	}, [])

	useEffect(() => {
		if (swiperRef) {
			handleSetNavigationVisibility(swiperRef)
		}
	}, [isTablet])

	if (isNull(children)) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, additionalClass, theme.wrapper, {
		[styles.visible]: isSwiperVisible,
	})

	return (
		<div className={ wrapperClass }>
			{ renderSkipSection() }

			<Swiper
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...swiperParams }
				className={ theme.swiper }
				onSwiper={ handleSetSwiperRef }
				onSlideChange={ handleChangeSlide }
			>
				<CarouselNavigation
					isPrev
					isHidden={ !showPrevButton || isWithoutNavigation || !isSwiperVisible }
					innerRef={ prevElRef }
					isWithoutGradient={ isWithoutGradient }
				/>

				{ children }

				<CarouselNavigation
					isHidden={ !showNextButton || isWithoutNavigation || !isSwiperVisible }
					innerRef={ nextElRef }
					isWithoutGradient={ isWithoutGradient }
				/>
			</Swiper>
		</div>
	)
}

export { Carousel }
