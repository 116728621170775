import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'

import { CartCustomerDataFormFieldWrapper, ICartCustomerDataForm } from '~/components/cart/cartCustomer'
import { useCountries } from '~/hooks/countries'
import { validation } from '~/utils/validation'
import { Input, Select } from '~/components/core/form'

import styles from './CartCustomerDataFormFields.module.scss'

const CartCustomerDataFormFields = (): ReactElement => {
	const { control } = useFormContext<ICartCustomerDataForm>()
	const { t } = useTranslation(['cart'])
	const { countriesSelectOptions } = useCountries()

	const { mask: { zipCode } } = validation

	return (
		<div className={ styles.wrapper }>
			<CartCustomerDataFormFieldWrapper>
				<Input
					isRequired
					name="firstName"
					label={ t('customer.clientData.form.name') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper>
				<Input
					isRequired
					name="lastName"
					label={ t('customer.clientData.form.surname') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper>
				<Input
					isRequired
					name="mainAddress.street"
					label={ t('customer.clientData.form.street') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper additionalClass={ styles.addressNumbersControls }>
				<Input
					isRequired
					name="mainAddress.houseNumber"
					label={ t('customer.clientData.form.houseNumber') }
					control={ control }
					additionalClass={ styles.control }
				/>

				<Input
					name="mainAddress.flatNumber"
					label={ t('customer.clientData.form.flatNumber') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper additionalClass={ styles.cityControls }>
				<Input
					isRequired
					name="mainAddress.postalCode"
					label={ t('customer.clientData.form.zipCode') }
					control={ control }
					mask={ zipCode }
					additionalClass={ styles.control }
				/>

				<Input
					isRequired
					name="mainAddress.city"
					label={ t('customer.clientData.form.city') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper>
				<Select
					isRequired
					isDisabled
					instanceId="delivery-address-country"
					options={ countriesSelectOptions }
					name="mainAddress.country"
					label={ t('customer.clientData.form.country') }
					placeholder={ t('customer.clientData.form.countryPlaceholder') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>
		</div>
	)
}

export { CartCustomerDataFormFields }
