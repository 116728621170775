import React, { ReactElement } from 'react'

import { IHeaderTopBarFavoritesModalWrapperProps } from '~/components/core/layout'

import styles from './HeaderTopBarFavoritesModalWrapper.module.scss'

const HeaderTopBarFavoritesModalWrapper = (props: IHeaderTopBarFavoritesModalWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { HeaderTopBarFavoritesModalWrapper }
