import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { isNull } from 'lodash'

import { getCartPreviewData, getIsPromotionEcommerce, getIsPromotionLoyalty } from '~/state/reducers/cartPreviewReducer'

import styles from './CartPreviewDiscountDynamicLegend.module.scss'

const CartPreviewDiscountDynamicLegend = (): ReactElement | null => {
	const { cart: { promotionCodeUsed: isPromotionCodeUsed, loyaltyCardNumber } } = useSelector(getCartPreviewData, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const isLoyalty = useSelector(getIsPromotionLoyalty, shallowEqual)
	const { t } = useTranslation(['cart'])

	const hasPromoCode = isPromotionCodeUsed && isEcommerce

	if (hasPromoCode) {
		return null
	}

	const hasLoyaltyReward = isPromotionCodeUsed && isLoyalty
	const hasLoyaltyProgram = !isNull(loyaltyCardNumber) || hasLoyaltyReward

	if (hasLoyaltyProgram) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			{ t('preview.aside.discount.legendLoyalty') }
		</div>
	)
}

export { CartPreviewDiscountDynamicLegend }
