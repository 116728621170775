import { AxiosResponse } from 'axios'

import { ICustomerProjects, ICustomerProjectItem, IPostCustomerProjectsResponse, IGetPlannerProjectsParams } from '~/api/dataTypes/customerProject'
import { request } from '~/api/requests/axios'
import { getCustomerPlannerServicePath } from '~/utils/planner'

export const getPlannerProjects = async (params: IGetPlannerProjectsParams): Promise<AxiosResponse<ICustomerProjects>> => (
	await request.get(getCustomerPlannerServicePath(), {
		params,
		service: 'CUSTOMER_PLANNER_SERVICE',
	})
)

export const deletePlannerProject = async (projectNumber: string): Promise<AxiosResponse<void>> => (
	await request.delete(`${ getCustomerPlannerServicePath() }/${ projectNumber }`, {
		service: 'CUSTOMER_PLANNER_SERVICE',
	})
)

export const patchPlannerProject = async (projectNumber: string): Promise<AxiosResponse<void>> => (
	await request.patch(`${ getCustomerPlannerServicePath() }/${ projectNumber }`, {}, {
		service: 'CUSTOMER_PLANNER_SERVICE',
	})
)

export const getPlannerProject = async (projectNumber: string): Promise<AxiosResponse<ICustomerProjectItem>> => (
	await request.get(`${ getCustomerPlannerServicePath() }/${ projectNumber }`, {
		service: 'CUSTOMER_PLANNER_SERVICE',
	})
)

// @data   ICustomerProjectData
export const putPlannerProject = async (projectNumber: string, data: FormData): Promise<AxiosResponse<void>> => (
	await request.put(`${ getCustomerPlannerServicePath() }/${ projectNumber }`, data, {
		service: 'CUSTOMER_PLANNER_SERVICE',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
)

// @data   ICustomerProjectData
export const postPlannerProjects = async (data: FormData): Promise<AxiosResponse<IPostCustomerProjectsResponse>> => (
	await request.post(getCustomerPlannerServicePath(), data, {
		service: 'CUSTOMER_PLANNER_SERVICE',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
)
