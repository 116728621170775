import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { CartPreviewDeliveryPickupTopBarLogotype, ICartPreviewDeliveryPickupTopBarProps } from '~/components/cart/cartPreview'
import { Tooltip } from '~/components/core/tooltip'

import styles from './CartPreviewDeliveryPickupTopBar.module.scss'

const CartPreviewDeliveryPickupTopBar = (props: ICartPreviewDeliveryPickupTopBarProps): ReactElement => {
	const { name, description, processingTime, price } = props
	const { t } = useTranslation(['cart', 'common'])

	return (
		<div className={ styles.wrapper }>
			<CartPreviewDeliveryPickupTopBarLogotype />

			<div className={ styles.data }>
				<div className={ styles.description }>
					<span className={ styles.caption }>
						{ name }
					</span>

					<Tooltip content={ description } />
				</div>

				<div className={ styles.realization } dangerouslySetInnerHTML={ { __html: processingTime } } />

				<div className={ styles.payment }>
					<span>
						{ t('preview.delivery.payment') }
					</span>

					<span className={ styles.price }>
						{ `${ price } ${ t('currency', { ns: 'common' }) }` }
					</span>
				</div>
			</div>
		</div>
	)
}

export { CartPreviewDeliveryPickupTopBar }
