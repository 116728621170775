import { AxiosResponse } from 'axios'

import { IDesktopUrlDefinitionResponse } from '~/api/dataTypes/define'
import { request } from '~/api/requests/axios'

export const getDefine = async (url: string, isInternal: boolean = false): Promise<AxiosResponse<IDesktopUrlDefinitionResponse>> => (
	await request.get(`/v1/define?url=${ url }`, {
		service: 'WEB_TOOLS_SERVICE',
		isInternal,
	})
)
