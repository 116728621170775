import React, { ReactElement, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'

import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { getCartPreviewData } from '~/state/reducers/cartPreviewReducer'

const CartPreviewDiscountLysStaticLegend = (): ReactElement | null => {
	const { cart: { promotionCodeUsed: isPromotionCodeUsed } } = useSelector(getCartPreviewData, shallowEqual)
	const { loyaltyType, isEditMode } = useContext(LysLoyaltyProviderContext)
	const { t } = useTranslation(['cart'])

	if (isEditMode || !isPromotionCodeUsed) {
		return (
			<>
				<span>
					{ t('preview.aside.lysDiscount.legend.loggedCaption', { loyaltyType }) }
				</span>

				<span>
					{ t('preview.aside.lysDiscount.legend.selectOption') }
				</span>
			</>
		)
	}

	return null
}

export { CartPreviewDiscountLysStaticLegend }
