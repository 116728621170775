import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { map } from 'lodash'

import { ILayoutAccountMenuListProps, LayoutAccountMenuSection } from '~/components/core/layout'
import { IAccountPanelMenuSection } from '~/hooks/userMenu'

import styles from './LayoutAccountMenuList.module.scss'

const LayoutAccountMenuList = (props: ILayoutAccountMenuListProps): ReactElement => {
	const { active, menuItemsSections } = props
	const { t } = useTranslation(['account'])

	const renderSection = useCallback((): ReactElement[] => (
		map(menuItemsSections, (section: IAccountPanelMenuSection, index: number): ReactElement => {
			const isLast = index === menuItemsSections.length - 1

			return (
				<LayoutAccountMenuSection
					key={ section.id }
					isLast={ isLast }
					section={ section }
					active={ active }
				/>
			)
		})
	), [active])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption }>
				{ t('menu.yourAccount') }
			</div>

			{ renderSection() }
		</div>
	)
}

export { LayoutAccountMenuList }
