import React, { ReactElement } from 'react'
import Script from 'next/script'

const DataDomeAdeoScript = (): ReactElement => {
	return (
		// eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
		<Script
			id="date-dome-adeo-script"
			strategy="beforeInteractive"
			dangerouslySetInnerHTML={ {
				__html: `
					!function(a,b,c,d,e,f){a.ddjskey=e;a.ddoptions=f||null;var m=b.createElement(c),n=b.getElementsByTagName(c)[0];m.async=1,m.src=d,n.parentNode.insertBefore(m,n)}(window,document,"script","https://bot.cdn.adeo.cloud/tags.js","6359540A6EBC822786D20BD976568F", { ajaxListenerPath: true });
				`,
			} }
		/>
	)
}

export { DataDomeAdeoScript }
