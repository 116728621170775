import { isNull, isNil, isUndefined, isEqual, includes } from 'lodash'

import { AppPromiseThunkAction } from '~/actions'
import { withFresherToken } from '~/actions/auth'
import { CartActionType, RemoveProductFromCartActionType, GetCartActionType, GetCartSimpleActionType, SetQuantityProductInCartActionType, ClearTransportFromStoreDataType, CartCalculateType, CartDeliveryNextType, ICartDeliveryNextData, CartDeliveryCalculateType, SearchCartDeliveryPickupPointType, ClearCartDeliveryPickupPointType, CartDeliveryTransportFromStoreType, CartDeliveryPickupInStoreBookingType, CartMergeType, SetCartPaymentWayIdType, SetCartCourierNoteType, SetActiveDeliveryGroupType, ClearSelectedDeliveryWayIdType, GetTransportFromStoreCategoriesType, SetTransportFromStoreZipCodeType, GetTransportFromStoreTimeSlotsType, GetCartCheckAvailabilityType, TransferProductsToCartActionType, RemoveProductsFromCartActionType, CartDeliveryTransportFromStorePickupPointType, AddProductToCartActionType, GetCartLoyaltyAccountType, SelectCartLoyaltyAccountRewardType, SetCartStepZeroType, GetCartLoyaltyCustomerExistType } from '~/actions/cart'
import { deleteProductFromCart, getCart, getCartSimple, postCartDeliveryNext, postSetQuantityProductInCart, postCartDeliveryCalculate, postCartCalculate, getCartDeliveryPickupPoint, putCartDeliveryPickupInStoreBooking, putCartDeliveryTransportFromStore, postCartMerge, getCartDeliveryTransportFromStoreCategories, getCartDeliveryTransportFromStoreTimeslots, getCartCheckAvailability, postTransferProductsToCart, deleteProductsFromCart, putCartDeliveryTransportPickupPoint, postAddProductToCart, postTransferProductsFromPlannerToCart } from '~/api/requests/cart'
import { IRemoveFromCartParameters, ISetQuantityProductInCartParameters, ICartPreviewCalculateParams, ICartDeliveryCalculateParams, ICartDeliveryPickupPointParameters, ICartDeliveryTransportFromStoreParameters, ICartMergeParams, DeliveryGroupType, ICartDeliveryTransportFromStoreCategoriesParameters, ICartDeliveryTransportFromStoreTimeslotsParameters, ITransferProductsToCart, ICartDeliveryTransportFromStorePickupPointParameters, IAddToCartParameters, ITransferProductsFromPlannerToCartParams, ITransferProductsFromPlannerToCartParamsResponse } from '~/api/dataTypes/cart'
import { getCartUuid } from '~/utils/cart'
import { adeoCookies } from '~/utils/adeoCookies'
import { tempoCookies } from '~/utils/tempoCookies'
import { getCustomerLoyaltyAccount, getCustomerLoyaltyExist } from '~/api/requests/customerLoyalty'

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartSimpleData = withFresherToken((): AppPromiseThunkAction<GetCartSimpleActionType> => async (dispatch, getState) => {
	const { miniCart, customer } = getState()

	if (isUndefined(customer.data?.login) && isNull(miniCart.data.cartUuid)) {
		return
	}

	return dispatch({
		type: CartActionType.GET_CART_SIMPLE,
		payload: getCartSimple(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartData = withFresherToken((isAllowOverwriteDeliveryWay?: boolean): AppPromiseThunkAction<GetCartActionType> => async (dispatch, getState) => {
	const { cartPreview: { isStepZero } } = getState()

	return dispatch({
		type: CartActionType.GET_CART,
		payload: async () => {
			try {
				const response = await getCart(isStepZero ? false : isAllowOverwriteDeliveryWay, isStepZero)

				const { data: { cart } } = response

				const cartUuid = cart?.cartUuid ?? null
				const currentCartUuid = getCartUuid()

				if (!isNull(cartUuid) && !isEqual(currentCartUuid, cartUuid)) {
					adeoCookies.customerUuid.set({ cartUuid, hostname: window.location.hostname })
				}

				return Promise.resolve(response)
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const addProductToCart = withFresherToken((params: IAddToCartParameters): AppPromiseThunkAction<AddProductToCartActionType> => async (dispatch) => {
	const { cartUuid } = params

	const response = await postAddProductToCart(params)

	const currentCartUuid = getCartUuid()
	const shouldOverrideCartUuid = isNull(cartUuid) && isNil(currentCartUuid) || !includes([currentCartUuid, cartUuid], response.data.simpleCart.cartUuid)

	if (shouldOverrideCartUuid && !isNil(response.data.simpleCart.cartUuid)) {
		adeoCookies.customerUuid.set({ cartUuid: response.data.simpleCart.cartUuid, hostname: window.location.hostname })
	}

	return dispatch({
		type: CartActionType.ADD_PRODUCT_TO_CART,
		payload: Promise.resolve(response),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const transferProductsToCart = withFresherToken((params: ITransferProductsToCart): AppPromiseThunkAction<TransferProductsToCartActionType> => async (dispatch) => {
	const response = await postTransferProductsToCart(params)

	const { data: { cartUuid } } = response

	const currentCartUuid = getCartUuid()

	if (isNull(cartUuid) && isNil(currentCartUuid) || !isEqual(cartUuid, currentCartUuid)) {
		adeoCookies.customerUuid.set({ cartUuid, hostname: window.location.hostname })
	}

	dispatch({
		type: CartActionType.TRANSFER_PRODUCTS_TO_CART,
		payload: Promise.resolve(response),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const transferProductsFromPlannerToCart = withFresherToken((data: ITransferProductsFromPlannerToCartParamsResponse): AppPromiseThunkAction<TransferProductsToCartActionType> => async (dispatch) => {
	const { cartUUID: cartUuid, tempoOrderId } = data
	const { hostname } = window.location

	if (!isNull(cartUuid)) {
		adeoCookies.customerUuid.set({ cartUuid, hostname })
	}

	if (!isNull(tempoOrderId)) {
		tempoCookies.tempoOrderId.set({ tempoOrderId, hostname })
	}

	return dispatch({
		type: CartActionType.TRANSFER_PRODUCTS_TO_CART,
		payload: getCartSimple(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setQuantityProductInCart = withFresherToken((params: ISetQuantityProductInCartParameters): AppPromiseThunkAction<SetQuantityProductInCartActionType> => async (dispatch) => (
	dispatch({
		type: CartActionType.SET_QUANTITY_PRODUCT_IN_CART,
		payload: postSetQuantityProductInCart(params),
	})
))

// eslint-disable-next-line @typescript-eslint/typedef
export const removeProductFromCart = withFresherToken((params: IRemoveFromCartParameters): AppPromiseThunkAction<RemoveProductFromCartActionType> => async (dispatch) => (
	dispatch({
		type: CartActionType.REMOVE_PRODUCT_FROM_CART,
		payload: deleteProductFromCart(params),
	})
))

// eslint-disable-next-line @typescript-eslint/typedef
export const removeProductsFromCart = withFresherToken((): AppPromiseThunkAction<RemoveProductsFromCartActionType> => async (dispatch) => (
	dispatch({
		type: CartActionType.REMOVE_PRODUCTS_FROM_CART,
		payload: deleteProductsFromCart(),
	})
))

// eslint-disable-next-line @typescript-eslint/typedef
export const cartCalculate = withFresherToken((params: ICartPreviewCalculateParams): AppPromiseThunkAction<CartCalculateType> => async (dispatch) => (
	dispatch({
		type: CartActionType.CART_CALCULATE,
		payload: postCartCalculate(params),
	})
))

// eslint-disable-next-line @typescript-eslint/typedef
export const cartMerge = withFresherToken((params: ICartMergeParams): AppPromiseThunkAction<CartMergeType> => async (dispatch) => (
	dispatch({
		type: CartActionType.CART_MERGE,
		payload: postCartMerge(params),
	})
))

// eslint-disable-next-line @typescript-eslint/typedef
export const cartDeliveryNext = withFresherToken((data: ICartDeliveryNextData): AppPromiseThunkAction<CartDeliveryNextType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CART_DELIVERY_NEXT,
		payload: postCartDeliveryNext(data),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const cartDeliveryCalculate = withFresherToken((params: ICartDeliveryCalculateParams): AppPromiseThunkAction<CartDeliveryCalculateType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CART_DELIVERY_CALCULATE,
		payload: postCartDeliveryCalculate(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const searchCartDeliveryPickupPoint = withFresherToken((params: ICartDeliveryPickupPointParameters): AppPromiseThunkAction<SearchCartDeliveryPickupPointType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.SEARCH_CART_DELIVERY_PICKUP_POINT,
		payload: getCartDeliveryPickupPoint(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const clearCartDeliveryPickupPoint = (): AppPromiseThunkAction<ClearCartDeliveryPickupPointType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CLEAR_CART_DELIVERY_PICKUP_POINT,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const cartDeliveryPickupInStoreBooking = withFresherToken((localDateTime: string): AppPromiseThunkAction<CartDeliveryPickupInStoreBookingType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CART_DELIVERY_PICKUP_IN_STORE_BOOKING,
		payload: putCartDeliveryPickupInStoreBooking(localDateTime),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartDeliveryTransportFromStore = withFresherToken((params: ICartDeliveryTransportFromStoreParameters): AppPromiseThunkAction<CartDeliveryTransportFromStoreType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CART_DELIVERY_TRANSPORT_FROM_STORE,
		payload: putCartDeliveryTransportFromStore(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartDeliveryTransportPickupPoint = withFresherToken((params: ICartDeliveryTransportFromStorePickupPointParameters): AppPromiseThunkAction<CartDeliveryTransportFromStorePickupPointType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CART_DELIVERY_TRANSPORT_FROM_STORE_PICKUP_POINT,
		payload: putCartDeliveryTransportPickupPoint(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartPaymentWayId = (paymentWayId: number): AppPromiseThunkAction<SetCartPaymentWayIdType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.SET_CART_PAYMENT_WAY_ID,
		payload: Promise.resolve(paymentWayId),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartCourierNote = (courierNote: string): AppPromiseThunkAction<SetCartCourierNoteType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.SET_CART_COURIER_NOTE,
		payload: Promise.resolve(courierNote),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setActiveDeliveryGroup = (deliveryGroup: DeliveryGroupType | null): AppPromiseThunkAction<SetActiveDeliveryGroupType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.SET_ACTIVE_DELIVERY_GROUP,
		payload: Promise.resolve(deliveryGroup),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearSelectedDeliveryWayId = (): AppPromiseThunkAction<ClearSelectedDeliveryWayIdType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CLEAR_SELECTED_DELIVERY_WAY_ID,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartDeliveryTransportFromStoreCategoriesData = withFresherToken((params: ICartDeliveryTransportFromStoreCategoriesParameters): AppPromiseThunkAction<GetTransportFromStoreCategoriesType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.GET_TRANSPORT_FROM_STORE_CATEGORIES,
		payload: getCartDeliveryTransportFromStoreCategories(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setDeliveryFromStoreZipCode = (zipCode: string): AppPromiseThunkAction<SetTransportFromStoreZipCodeType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.SET_TRANSPORT_FROM_STORE_ZIP_CODE,
		payload: Promise.resolve(zipCode),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearDeliveryFromStoreData = (): AppPromiseThunkAction<ClearTransportFromStoreDataType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.CLEAR_TRANSPORT_FROM_STORE_DATA,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartDeliveryTransportFromStoreTimeslotsData = withFresherToken((params: ICartDeliveryTransportFromStoreTimeslotsParameters): AppPromiseThunkAction<GetTransportFromStoreTimeSlotsType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.GET_TRANSPORT_FROM_STORE_TIMESLOTS,
		payload: getCartDeliveryTransportFromStoreTimeslots(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartCheckAvailabilityData = withFresherToken((deliveryWayId: number): AppPromiseThunkAction<GetCartCheckAvailabilityType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.GET_CART_CHECK_AVAILABILITY,
		payload: getCartCheckAvailability(deliveryWayId),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartLoyaltyAccountData = withFresherToken((): AppPromiseThunkAction<GetCartLoyaltyAccountType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.GET_CART_LOYALTY_ACCOUNT,
		payload: getCustomerLoyaltyAccount(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartLoyaltyCustomerExistData = withFresherToken((): AppPromiseThunkAction<GetCartLoyaltyCustomerExistType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.GET_CUSTOMER_LOYALTY_EXIST,
		payload: getCustomerLoyaltyExist(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const selectCartLoyaltyAccountReward = withFresherToken((code: string): AppPromiseThunkAction<SelectCartLoyaltyAccountRewardType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.SELECT_CART_LOYALTY_ACCOUNT_REWARD,
		payload: Promise.resolve(code),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartStepZeroData = (isStepZero: boolean): AppPromiseThunkAction<SetCartStepZeroType> => async (dispatch) => {
	return dispatch({
		type: CartActionType.SET_CART_STEP_ZERO,
		payload: Promise.resolve(isStepZero),
	})
}
