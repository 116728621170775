import { useCallback, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isNull } from 'lodash'

import { getComparisonFunction, IUseCustomerUpdateData, UseCustomerUpdateType, CUSTOMER_MAX_REQUESTS } from '~/hooks/customerUpdate'
import { useAlert } from '~/hooks/alert'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { CustomerDataRequestUnionType } from '~/api/dataTypes/customer'
import { getCustomerData } from '~/actions/customer'

export const useCustomerUpdate = (updateType: UseCustomerUpdateType = 'DEFAULT'): IUseCustomerUpdateData => {
	const userData = useSelector((state: IRootState) => state.customer.data, shallowEqual)
	const [isUpdating, setIsUpdating] = useState<boolean>(false)
	const [activeUser, setActiveUser] = useState<Partial<CustomerDataRequestUnionType> | null>(null)
	const dispatch: AppDispatch = useDispatch()
	const { preventAlert } = useAlert()
	const intervalRef = useRef<NodeJS.Timer | null>(null)
	const requestCounter = useRef<number>(0)

	const comparisonFunction = getComparisonFunction(updateType)

	const clearRequestInterval = useCallback((): void => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current)
			intervalRef.current = null
			requestCounter.current = 0
			setActiveUser(null)
			setIsUpdating(false)
		}
	}, [])

	const handleUserData = useCallback(async (): Promise<void> => {
		if (requestCounter.current >= CUSTOMER_MAX_REQUESTS) {
			clearRequestInterval()
			preventAlert()

			return
		}

		await dispatch(getCustomerData())

		requestCounter.current++
	}, [])

	const onCustomerUpdate = useCallback(async (newCustomerData: Partial<CustomerDataRequestUnionType>): Promise<void> => {
		await dispatch(getCustomerData())
		setActiveUser(newCustomerData)
	}, [])

	useEffect(()=> {
		return (() => {
			clearRequestInterval()
		})
	}, [])

	useEffect(() => {
		if (comparisonFunction(activeUser, userData)) {
			clearRequestInterval()
		}
	}, [userData])

	useEffect(() => {
		if (!isNull(activeUser) && !comparisonFunction(activeUser, userData)) {
			setIsUpdating(true)
			intervalRef.current = setInterval(handleUserData, 3000)
		}
	}, [activeUser])

	return {
		isUpdating,
		onCustomerUpdate,
	}
}
