import React, { createContext, ReactElement, useMemo, useCallback, useContext, useEffect } from 'react'
import { useToggle } from 'react-use'
import { noop } from 'lodash'

import { IHamburgerMenuContextProps, IHamburgerMenuProviderProps } from '~/providers/hamburgerMenuProvider'
import { UserContext } from '~/providers/userProvider'

const initialProps: IHamburgerMenuContextProps = {
	isOpen: false,
	handleSetOpen: noop,
	isUserMenuOpen: false,
	handleSetUserMenuOpen: noop,
}

export const HamburgerMenuContext = createContext(initialProps)

export const HamburgerMenuProvider = (props: IHamburgerMenuProviderProps): ReactElement => {
	const { children } = props
	const [isOpen, setIsOpen] = useToggle(initialProps.isOpen)
	const [isUserMenuOpen, setIsUserMenuOpen] = useToggle(initialProps.isUserMenuOpen)
	const { isLogged } = useContext(UserContext)

	const handleSetOpen = useCallback((state: boolean): void => {
		setIsOpen(state)
	}, [])

	const handleSetUserMenuOpen = useCallback((state: boolean): void => {
		setIsUserMenuOpen(state)
	}, [])

	useEffect(() => {
		if (!isLogged) {
			setIsUserMenuOpen(false)
		}
	}, [isLogged])

	const providerValue: IHamburgerMenuContextProps = useMemo(() => ({
		isOpen,
		handleSetOpen,
		isUserMenuOpen,
		handleSetUserMenuOpen,
	}), [isOpen, isUserMenuOpen])

	return (
		<HamburgerMenuContext.Provider value={ providerValue }>
			{ children }
		</HamburgerMenuContext.Provider>
	)
}
