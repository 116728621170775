const HEADER = {
	MAIN_MENU: 'Header:Container:MainMenu',
	FAVORITES_ITEMS: 'Header:Container:FavoritesItems',
	FAVORITES_ITEM: (index: number) => `Header:Container:FavoritesItem_${ index }`,
	MINI_CART_ITEMS: 'Header:Container:MiniCartItems',
	MINI_CART_ITEM: (index: number) => `Header:Container:MiniCartItem_${ index }`,
	SEARCH_BAR_RESULTS_MAIN: 'Header:Container:SearchBarResultsMain',
	SEARCH_BAR_NODES_LIST: 'Header:Container:SearchBarNodesList',
	SEARCH_BAR_NODE_LIST_ITEM: (index: number) => `Header:Container:SearchBarNodeListItem_${ index }`,
	SEARCH_BAR_PRODUCTS_LIST: 'Header:Container:SearchBarProductsList',
	SEARCH_BAR_PRODUCTS_LIST_ITEM: (index: number) => `Header:Container:SearchBarProductsListItem_${ index }`,
	SEARCH_BAR_HISTORY_LIST: 'Header:Container:SearchBarHistoryList',
	SEARCH_BAR_PRODUCTS_COUNTER: 'Header:Content:SearchBarProductsCounter',
	USER_MENU_LABEL: 'Header:Content:UserMenuLabel',
	FAVORITES_COUNTER: 'Header:Content:FavoritesCounter',
	MINI_CART_COUNTER: 'Header:Content:MiniCartCounter',
	MINI_CART_SUMMARY_COST: 'Header:Content:MiniCartSummaryCost',
	BUTTON_OPEN_PREFERRED_STORE: 'Header:Button:Open_PreferredStore',
	BUTTON_OPEN_PREFERRED_STORE_BLOCK: 'Header:Button:Open_PreferredStoreBlock',
	BUTTON_OPEN_NOT_SELECTED_PREFERRED_STORE_MODAL: 'Header:Button:Open_NotSelectedPreferredStoreModal',
	BUTTON_SUBMIT_SEARCH: 'Header:Button:Submit_Search',
	BUTTON_RESET_HISTORY_SEARCH: 'Header:Button:Reset_HistorySearch',
	BUTTON_TOGGLE_MEGA_MENU: 'Header:Button:Toggle_MegaMenu',
	BUTTON_OPEN_USER_MENU: 'Header:Button:Open_UserMenu',
	BUTTON_OPEN_FAVORITES: 'Header:Button:Open_Favorites',
	BUTTON_OPEN_FAVORITES_SHARE: 'Header:Button:Open_FavoritesShare',
	BUTTON_GO_TO_FAVORITES: 'Header:Button:GoTo_Favorites',
	BUTTON_OPEN_MINI_CART: 'Header:Button:Open_MiniCart',
	BUTTON_REFRESH_MINI_CART: 'Header:Button:Refresh_MiniCart',
	BUTTON_GO_TO_CART_PREVIEW: 'Header:Button:GoTo_CartPreview',
	BUTTON_OPEN_HAMBURGER_MENU: 'Header:Button:Open_HamburgerMenu:Desktop',
	BUTTON_TOGGLE_SEARCH_BAR: 'Header:Button:Toggle_SearchBar:Desktop',
	BUTTON_CLOSE_SEARCH_BAR: 'Header:Button:Close_SearchBar:Desktop',
	BUTTON_LOGIN: 'Header:Button:GoTo_Login',
	BUTTON_REGISTER: 'Header:Button:GoTo_Register',
	LOGO: 'Header:Link:GoTo_Home',
	MAIN_MENU_ITEM: (item: string) => `Header:Menu_Item:GoTo_${ item }`,
	FIELD_SEARCH: 'Header:Field:Search',
	MODAL_PREFERRED_STORE: 'Header:Modal:PreferredStore',
	MODAL_USER_MENU: 'Header:Modal:UserMenu',
	MODAL_FAVORITES: 'Header:Modal:Favorites',
	MODAL_MINI_CART: 'Header:Modal:MiniCart',
}

const FOOTER = {
	BUTTONS: 'Footer:Container:Buttons',
	LINKS_SECTIONS: 'Footer:Container:LinksSections',
	LINKS_SECTION: (name: string) => `Footer:Container:LinksSection_${ name }`,
	APPS_AND_BANNERS_SECTION: 'Footer:Container:AppsAndBanners',
	BOTTOM_BAR: 'Footer:Container:BottomBar',
	BOTTOM_BAR_LINKS: 'Footer:Container:BottomBarLinks',
	BUTTON_TOGGLE_SECTION: (name: string) => `Footer:Button:Toggle_Section_${ name }:SmallDesktop`,
	BUTTON_OPEN_PREFERRED_STORE: 'Footer:Button:Open_PreferredStore',
	BUTTON_OPEN_PREFERRED_STORE_DESKTOP: 'Footer:Button:Open_PreferredStore:Desktop',
	BUTTON_ITEM: (name: string) => `Footer:Link:GoTo_${ name }`,
}

const CORE = {
	// megaMenu
	MEGA_MENU: 'Core:Container:MegaMenu',
	MEGA_WORLDS_LIST: 'Core:Container:MegaWorldsList',
	WORLDS_LIST: 'Core:Container:WorldsList',
	WORLDS_LIST_ITEM: (item: string) => `Core:Container:WorldsList_${ item }`,
	MEGA_MENU_ITEM: (item: string) => `Core:Menu_Item:Set_ActiveWorldsList_${ item }`,
	// hamburgerMenu
	HAMBURGER_MENU: 'Core:Container:HamburgerMenu:Desktop',
	HAMBURGER_MENU_LIST: 'Core:Container:HamburgerMenuList:Desktop',
	HAMBURGER_MENU_MEGA_MENU: 'Core:Container:HamburgerMenuMegaMenu:Desktop',
	HAMBURGER_MENU_MEGA_MENU_LIST: 'Core:Container:HamburgerMenuMegaMenuList:Desktop',
	HAMBURGER_MENU_MEGA_MENU_HEADER: 'Core:Container:hamburgerMenuMegaMenuHeader:Desktop',
	BUTTON_GO_BACK_HAMBURGER_MENU: 'Core:Button:Go_Back_HamburgerMenu',
	BUTTON_GO_TO_STORE_CARD_DESKTOP: 'Core:Button:GoTo_StoreCard:Desktop',
	BUTTON_OPEN_PREFERRED_STORE_BLOCK: 'Core:Button:Open_PreferredStoreBlock:Desktop',
	BUTTON_CLICK_HAMBURGER_MENU_USER: 'Core:Button:Click_HamburgerMenuUser:Desktop',
	HAMBURGER_MENU_ITEM: (item: string) => `Core:Menu_Item:GoTo_${ item }:Desktop`,
	USER_MENU_ITEM: (item: string) => `Core:Menu_Item:GoTo_${ item }`,
	// popularProductCategories
	POPULAR_PRODUCT_CATEGORIES_LIST: 'Core:Container:PopularProductCategoriesList',
	POPULAR_PRODUCT_CATEGORY: (name: string) => `Core:Link:GoTo_PopularProductCategory_${ name }`,
	// inspirationsBlock
	INSPIRATIONS_BLOCK_MASONRY_GALLERY: 'Core:Container:InspirationsBlockMasonryGallery',
	INSPIRATIONS_BLOCK_CATEGORY_CAROUSEL_LIST: 'Core:Container:InspirationsBlockCategoryCarouselList',
	INSPIRATIONS_BLOCK_MASONRY_ITEM: (index: number) => `Core:Link:Click_InspirationsBlockMasonryItem_${ index }`,
	INSPIRATIONS_CATEGORY_CAROUSEL_LIST_ITEM: (name: string) => `Core:Link:GoTo_InspirationsBlockCategory_${ name }`,
	// servicesBlock
	FULL_MENU: 'Core:Container:ServicesBlockFullMenu',
	FULL_ITEMS: 'Core:Container:ServicesBlockFullItems',
	SERVICES_LIST: 'Core:Container:ServicesList',
	BUTTON_CHANGE_SERVICE_TAB: (index: number) => `Core:Button:Change_ServiceTab_${ index }`,
	BUTTON_TOGGLE_SERVICE_LIST: (index: number) => `Core:Button:Toggle_ServiceList_${ index }:SmallDesktop`,
	SERVICES_ITEM: (name: string) => `Core:Link:GoTo_Service_${ name }`,
	// brands block
	BRANDS_CAROUSEL: 'Core:Container:BrandsBlockCarousel',
	BRANDS_ITEM: (name: string) => `Core:Link:GoTo_Brand_${ name }`,
	// newsletter
	NEWSLETTER: 'Core:Container:Newsletter',
	CHECKBOX_AGREEMENT: 'Core:Checkbox:Toggle_NewsletterAgreement',
	BUTTON_SUBMIT_EMAIL: 'Core:Button:Submit_NewsletterEmail',
	// preferredStore
	STORE_BLOCK: 'Core:Container:PreferredStoreBlock',
	STORE_LIST: 'Core:Container:PreferredStoreList',
	STORE_INFO: 'Core:Container:PreferredStoreInfo',
	BUTTON_GET_USER_GEOLOCATION: 'Core:Button:Get_UserGeolocation',
	BUTTON_CLEAR_PREFERRED_STORE: 'Core:Button:Clear_PreferredStore',
	BUTTON_CHANGE_PREFERRED_STORE: 'Core:Button:Change_PreferredStore',
	BUTTON_SET_PREFERRED_STORE: 'Core:Button:Set_PreferredStore',
	BUTTON_GO_TO_STORES: 'Core:Button:GoTo_Stores',
	BUTTON_GO_TO_STORE_CARD: 'Core:Button:GoTo_StoreCard',
	// projectSteps
	STEPS_LIST: 'Core:Container:ProjectStepsList',
	// series
	SERIES_ITEM: (index: number) => `Core:Container:SeriesItem_${ index }`,
	// others
	BUTTON_SCROLL_TO_TOP: 'Core:Button:ScrollTo_Top',
	MODAL_TITLE: 'Core:Modal:Title',
	BUTTON_CLOSE_MODAL: 'Core:Button:Close_Modal',
	BUTTON_ADD_TO_CART: 'Core:Button:AddTo_Cart',
}

const FAVORITES = {
	MODAL_SHARE: 'Favorites:Modal:Share',
	BUTTON_SUBMIT_SHARE: 'Favorites:Button:Submit_Share',
}

const HOME = {
	NEWS_SECTION: 'Home:Container:NewsSection',
	BANNERS_SLOT_A: 'Home:Container:BannersSlot_A',
	PRODUCTS_RECOMMENDATION_BLOCK: 'Home:Container:ProductsRecommendationBlock',
	POPULAR_PRODUCT_CATEGORIES_BLOCK: 'Home:Container:PopularProductCategoriesBlock',
	INSPIRATIONS_BLOCK: 'Home:Container:InspirationsBlock',
	BANNERS_SLOT_B: 'Home:Container:BannersSlot_B',
	SERIES_CAROUSEL: 'Home:Container:SeriesCarousel',
	BANNERS_SLOT_C: 'Home:Container:BannersSlot_C',
	SERVICES_BLOCK: 'Home:Container:ServicesBlock',
	BANNERS_SLOT_D: 'Home:Container:BannersSlot_D',
	BANNERS_SLOT_E: 'Home:Container:BannersSlot_E',
	BRANDS_BLOCK: 'Home:Container:BrandsBlock',
	LAST_VIEWED_PRODUCTS: 'Home:Container:LastViewedProducts',
	BUTTON_CHANGE_SWIPER_SLIDE: (index: number) => `Home:Button:Change_HomeSwiperSlide_${ index }`,
	SWIPER_SLIDE: (index: number) => `Home:Link:Click_HomeSwiperSlide_${ index }`,
}

const SEARCH = {
	LAST_VIEWED_PRODUCTS: 'Search:Container:LastViewedProducts',
}

const CART_PREVIEW = {
	BUTTON_DELIVERY_SPLIT_DETAILS: 'CartPreview:Button:DeliverySplitDetails',
	BUTTON_DELIVERY_MODAL_OK: 'CartPreview:Button:DeliveryModalOk',
	BUTTON_OPEN_PREFERRED_STORE_MODAL: 'CartPreview:Button:Open_PreferredStoreModal',
	BUTTON_INCREMENT_PRODUCT_QUANTITY: 'CartPreview:Button:Increment_ProductQuantity',
	BUTTON_DECREMENT_PRODUCT_QUANTITY: 'CartPreview:Button:Decrement_ProductQuantity',
	BUTTON_REMOVE_PRODUCT: 'CartPreview:Button:Remove_Product',
	BUTTON_REMOVE_ALL_PRODUCTS: 'CartPreview:Button:Remove_AllProducts',
	BUTTON_OPEN_SHARE_CART_MODAL: 'CartPreview:Button:Open_ShareCartModal',
	BUTTON_SET_DELIVERY_METHOD: 'CartPreview:Button:Set_DeliveryMethod',
	BUTTON_SET_PICKUP_METHOD: 'CartPreview:Button:Set_PickupMethod',
	BUTTON_SET_STORE_DELIVERY_METHOD: 'CartPreview:Button:Set_StoreDeliveryMethod',
	BUTTON_GO_TO_NEXT_CART_STEP: 'CartPreview:Button:GoTo_NextCartStep',
	BUTTON_SUBMIT_DISCOUNT: 'CartPreview:Button:Submit_Discount',
	BACK_TO_HOME: 'CartPreview:Link:BackToHome',
}

const PRODUCT_LIST = {
	BREAK_CAMPAIGN: 'ProductList:Link:Click_BreakCampaign',
	BREAK_PROMOTING_SUBPAGES: 'ProductList:Link:Click_BreakPromotingSubpages',
}

const CMS_PAGES = {
	IMAGE: 'CmsPages:Link:Click_Image',
}

const PRODUCT_CARD = {
	ASIDE_BLOCK: 'ProductCard:Container:AsideBlock',
	BREAK_BANNER: 'ProductCard:Link:Click_BreakBanner',
	BUTTON_TOGGLE_STORE_STOCK_INFO_MODAL: 'ProductCard:Button:Toggle_StoreStockInfoModal',
	BUTTON_CLICK_CHECK_HOW_TO_GET: 'ProductCard:Button:Click_CheckHowToGet',
}

const CART_MODAL = {
	BUTTON_GO_TO_CART_PREVIEW: 'CartModal:Button:GoTo_CartPreview',
	BUTTON_CLOSE_MODAL: 'CartModal:Button:Close_Modal',
}

const ADVICES = {
	LAST_VIEWED_PRODUCTS: 'Advices:Container:LastViewedProducts',
}

export const DATA_TESTID = {
	HEADER,
	HOME,
	FAVORITES,
	FOOTER,
	CORE,
	SEARCH,
	CART_PREVIEW,
	PRODUCT_LIST,
	CMS_PAGES,
	PRODUCT_CARD,
	CART_MODAL,
	ADVICES,
}
