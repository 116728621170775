import { i18n } from 'next-i18next'
import { find, isEqual, isNull, isUndefined, map } from 'lodash'

import { ICartPreviewPaymentOption } from '~/components/cart/cartPreview'
import { vars } from '~/statics'
import { getPaymentLogo } from '~/utils/paymentLogo'
import { IPaymentData, DeliveryGroupType, IDeliveryData } from '~/api/dataTypes/cart'

export const buildPaymentRadioOptions = (paymentList: IPaymentData[]): ICartPreviewPaymentOption[] => (
	map(paymentList, (payment: IPaymentData) => {
		const { id, name, paymentWayType, description, isAllowed, isSelected } = payment

		return {
			value: id.toString(),
			label: name,
			description,
			isAllowed,
			isSelected,
			imageSrc: getPaymentLogo(paymentWayType),
			paymentWayType,
		}
	})
)

export const isCashOnDelivery = (activeDeliveryGroup: DeliveryGroupType | null, selectedDeliveryWayId: number | null, deliveryList: IDeliveryData[]): boolean => isEqual(activeDeliveryGroup, 'DELIVERY') && !isNull(selectedDeliveryWayId) && !isUndefined(find(deliveryList, (delivery: IDeliveryData) => delivery.id === selectedDeliveryWayId)) && !find(deliveryList, (delivery: IDeliveryData) => delivery.id === selectedDeliveryWayId)?.prepaid

export const isPickupDelivery = (activeDeliveryGroup: DeliveryGroupType | null, selectedDeliveryWayId: number | null, deliveryList: IDeliveryData[]): boolean => isEqual(activeDeliveryGroup, 'DELIVERY') && !isNull(selectedDeliveryWayId) && !isUndefined(find(deliveryList, (delivery: IDeliveryData) => delivery.id === selectedDeliveryWayId)) && !isUndefined(find(deliveryList, (delivery: IDeliveryData) => delivery.id === selectedDeliveryWayId && delivery.isPickupPoint))

export const buildCashOnDeliveryRadioOption = (): ICartPreviewPaymentOption => ({
	value: '',
	label: i18n?.t('preview.payment.cashOnDelivery.title', { ns: 'cart' }) || '',
	description: i18n?.t('preview.payment.cashOnDelivery.description', { ns: 'cart' }) || '',
	isAllowed: true,
	isSelected: true,
	imageSrc: vars.images.paymentCashOnDelivery,
	paymentWayType: 'DELIVERY',
})
