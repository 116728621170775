import React, { useCallback, ReactElement, ReactNode } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { ICartAsideWrapperProps } from '~/components/core/cartAside'
import { Loader } from '~/components/core/loader'
import { SkipContentAnchor } from '~/components/core/skipContent'
import { StickyBlock } from '~/components/core/stickyBlock'
import { SKIP_CART_ASIDE } from '~/hooks/skipElements'

import styles from './CartAsideWrapper.module.scss'

const CartAsideWrapper = (props: ICartAsideWrapperProps): ReactElement => {
	const {
		children, isLoading,
		additionalClass = '',
	} = props

	const renderContent = useCallback((): ReactNode => {
		const contentClass = classNames(styles.content, {
			[styles.hidden]: isLoading,
		})

		return (
			<>
				{ isLoading && (
					<div className={ styles.loaderWrapper }>
						<Loader />
					</div>
				) }

				<div className={ contentClass }>
					{ children }
				</div>
			</>
		)
	}, [children, isLoading])

	const stickyBlockWrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<StickyBlock theme={ { wrapper: stickyBlockWrapperClass } }>
			<SkipContentAnchor elementId={ SKIP_CART_ASIDE } additionalClass={ styles.skipAnchor } />

			{ renderContent() }
		</StickyBlock>
	)
}

export { CartAsideWrapper }
