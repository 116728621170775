import { Draft, produce } from 'immer'
import { isNil } from 'lodash'

import { AppPromiseAction } from '~/actions'
import { ProductCardActionUnionType, ProductCardActionType } from '~/actions/productCard'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'

import { productCardStoreAvailabilityInitialState as initialState } from './constants'
import { ProductCardStoreAvailabilityState } from './types'

export const productCardStoreAvailability = produce((draft: Draft<ProductCardStoreAvailabilityState>, action: AppPromiseAction<ProductCardActionUnionType>): ProductCardStoreAvailabilityState => {
	switch (action.type) {
		case REQUEST(ProductCardActionType.GET_STORE_AVAILABILITY): {
			draft.isPending = true

			return draft
		}

		case SUCCESS(ProductCardActionType.GET_STORE_AVAILABILITY): {
			const { payload } = action

			if (isNil(payload?.data)) {
				return draft
			}

			draft.isPending = false
			draft.availability = payload.data

			return draft
		}

		case FAILURE(ProductCardActionType.GET_STORE_AVAILABILITY): {
			draft.isPending = false

			return draft
		}

		case SUCCESS(ProductCardActionType.CLEAN_UP): {
			return initialState
		}

		default:
			return draft
	}
}, initialState)
