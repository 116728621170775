import React, { ReactElement, useCallback } from 'react'
import { isEqual, map } from 'lodash'

import { HeaderTopBarSearchBarNode, IHeaderTopBarSearchBarNodesListProps, ISearchNode } from '~/components/core/layout'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarSearchBarNodesList.module.scss'

const HeaderTopBarSearchBarNodesList = (props: IHeaderTopBarSearchBarNodesListProps): ReactElement => {
	const { nodes, query, onHideSuggestions } = props

	const handleOnBlur = useCallback((index: number, nodesCount: number): void => {
		if (isEqual(index, nodesCount-1)) {
			onHideSuggestions()
		}
	}, [onHideSuggestions])

	const renderNodes = useCallback((): ReactElement[] => (
		map(nodes, (node: ISearchNode, index: number) => {
			const onBlurHandler = (): void => {
				handleOnBlur(index, nodes.length)
			}

			return (
				<HeaderTopBarSearchBarNode
					key={ node.id }
					node={ node }
					query={ query }
					dataTestId={ DATA_TESTID.HEADER.SEARCH_BAR_NODE_LIST_ITEM(index) }
					onBlur={ onBlurHandler }
				/>
			)
		}
		)
	), [nodes])

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.HEADER.SEARCH_BAR_NODES_LIST }>
			{ renderNodes() }
		</div>
	)
}

export { HeaderTopBarSearchBarNodesList }
