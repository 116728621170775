import React, { ReactElement, useCallback, useEffect } from 'react'
import classNames from 'classnames'

import { ICartPreviewSummaryDiscountSuccessProps } from '~/components/cart/cartPreview'
import { CartPreviewSummaryDiscountRemoveButton } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountRemoveButton'
import { CartPreviewSummaryDiscountLoyalty } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLoyalty'
import { CartPreviewSummaryDiscountCode } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountCode'

import styles from './CartPreviewSummaryDiscountSuccess.module.scss'

const CartPreviewSummaryDiscountSuccess = (props: ICartPreviewSummaryDiscountSuccessProps): ReactElement | null => {
	const {
		isFormHidden, hasLoyaltyCard, promotionCodeType, promotionCodeUsed, promotionCodeValue, setIsFormHidden,
		promotionCode = '',
		theme = {},
	} = props

	const hasPromoCode = promotionCodeUsed && promotionCodeType === 'ECOMMERCE'
	const hasLoyaltyReward = promotionCodeUsed && promotionCodeType === 'LOYALTY'
	const hasLoyaltyProgram = hasLoyaltyReward || hasLoyaltyCard

	const handleHideForm = useCallback((): void => {
		setIsFormHidden(true)
	}, [])

	const handleShowForm = useCallback((): void => {
		setIsFormHidden(false)
	}, [])

	const handleCheckIfAnyDiscountUsed = useCallback((): void => {
		const isAnyDiscountUsed = hasPromoCode || hasLoyaltyProgram || hasLoyaltyCard

		if (isAnyDiscountUsed) {
			handleHideForm()
		} else {
			handleShowForm()
		}
	}, [hasPromoCode, hasLoyaltyProgram, hasLoyaltyCard])

	useEffect(() => {
		handleCheckIfAnyDiscountUsed()
	}, [hasPromoCode, hasLoyaltyProgram, hasLoyaltyCard, promotionCodeValue, promotionCodeUsed])

	if (!isFormHidden) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div className={ wrapperClass }>
			<CartPreviewSummaryDiscountCode
				hasPromoCode={ hasPromoCode }
				hasLoyaltyReward={ hasLoyaltyReward }
				promotionCodeValue={ promotionCodeValue }
				promotionCode={ promotionCode }
				theme={ { icon: theme.icon } }
			/>

			<CartPreviewSummaryDiscountLoyalty hasLoyaltyCard={ hasLoyaltyCard } theme={ { icon: theme.icon } } />

			<CartPreviewSummaryDiscountRemoveButton
				hasPromoCode={ hasPromoCode }
				hasLoyaltyCard={ hasLoyaltyCard }
				handleShowForm={ handleShowForm }
			/>
		</div>
	)
}

export { CartPreviewSummaryDiscountSuccess }
