import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ICartHeaderStepCounterProps } from '~/components/core/cartHeader'

import styles from './CartHeaderStepCounter.module.scss'

const CartHeaderStepCounter = (props: ICartHeaderStepCounterProps): ReactElement => {
	const {
		stepNumber, isActive, isPrevious,
		isMozaic = false,
	} = props

	const wrapperClass = classNames(styles.wrapper, {
		[styles.active]: isActive,
		[styles.previous]: isPrevious,
		[styles.isMozaic]: isMozaic,
	})

	return (
		<div className={ wrapperClass }>
			{ stepNumber }
		</div>
	)
}

export { CartHeaderStepCounter }
