import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import axios, { AxiosError } from 'axios'
import { isNull, isEmpty, isUndefined, isEqual, join, find, isNil, map, toString } from 'lodash'

import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { getCartCustomerData } from '~/actions/cartCustomer'
import { CartCustomerWrapper, CartCustomerData, CartCustomerContact, CartCustomerOrderCollection, getCustomerLoggedNextTooltipInfo, getCustomerContactData, CartCustomerInvoice, CartCustomerAsideBlock, getLmReferencesFromCartItemList, CartCustomerLoggedLoader, CartCustomerNextFieldsType, getDisabledInfoForAddressData, getDisabledInfoForCustomerData } from '~/components/cart/cartCustomer'
import { CartTransportFromStoreModal } from '~/components/cart/cartTransportFromStoreModal'
import { useCartStatus } from '~/hooks/cartStatus'
import { useAlert } from '~/hooks/alert'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useLogError } from '~/hooks/logError'
import { useInitialLoader } from '~/hooks/initialLoader'
import { useModal } from '~/hooks/modal'
import { validation } from '~/utils/validation'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { ICartCustomerNextParams } from '~/api/dataTypes/cart'
import { postCartCustomerNext } from '~/api/requests/cart'
import { CartColumnWrapper } from '~/components/core/cartColumnWrapper'

const CartCustomerLogged = (): ReactElement => {
	const { data, isPending, addressId: customerAddressId, isAnotherReceiverActive, isNextButtonDisabledByInvoice, isNextButtonDisabled } = useSelector((state: IRootState) => state.cartCustomer, shallowEqual)
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { data: customerData } = useSelector((state: IRootState) => state.customer, shallowEqual)
	const [isNextLoading, setIsNextLoading] = useToggle(false)
	const { changeCartView } = useCartStatus()
	const dispatch: AppDispatch = useDispatch()
	const { GA_checkout } = useGoogleAnalytics()
	const { t } = useTranslation(['cart'])
	const [isInitialLoader] = useInitialLoader(isPending)
	const [isAnalyticsEventsSent, setIsAnalyticsEventsSent] = useState<boolean>(false)
	const { newAlert, preventAlert } = useAlert()
	const { isOpen, handleOpen, handleClose } = useModal('TransportFromStoreError')
	const { sendLogError } = useLogError()

	const { cart, customerOtherContact, receiptNip, invoice, cartStatus, delivery, invoiceRequired } = data
	const { totalCostValue, cartItemList, productsValue } = cart

	const isCustomerDataFetched = isNull(customerData)
	const isNextButtonDisabledByPhone = isAnotherReceiverActive ? isNil(customerOtherContact.phone) : isNil(customerData?.customer.phone)
	const isNextButtonDisabledByPhonePrefix = isAnotherReceiverActive ? false : !validation.regex.polishPhonePrefix.test(toString(customerData?.customer.phonePrefix))
	const disabledInfoForAddressData = getDisabledInfoForAddressData(customerData, customerAddressId)
	const disabledInfoForCustomerData = getDisabledInfoForCustomerData(customerData)
	const nextButtonDisabledInfo = getCustomerLoggedNextTooltipInfo({ t, customerAddressId, disabledInfoForAddressData, disabledInfoForCustomerData, isNextButtonDisabledByInvoice, isNextButtonDisabledByPhone, isNextButtonDisabledByPhonePrefix })
	const isNextButtonBlocked = isNextButtonDisabled || !isEmpty(nextButtonDisabledInfo)

	const handleBack = useCallback(async (): Promise<void> => {
		await changeCartView(1, cartStatus)
	}, [cartStatus])

	const handleNext = useCallback(async (): Promise<void> => {
		setIsNextLoading(true)

		const { customerOtherContact } = data
		const contact = isAnotherReceiverActive ? customerOtherContact : getCustomerContactData(customerData, customerAddressId)

		if (!isNull(contact) && !isNull(invoice)) {
			const params: ICartCustomerNextParams = {
				customerAddressId,
				dataProcessingChecks: ['ORDER_PROCESSING'],
				contact,
				invoice,
				receiptNip: receiptNip.nip || null,
			}

			try {
				const { data } = await postCartCustomerNext(params)

				await changeCartView(3, data.cartStatus)
			} catch (e: unknown) {
				const error = e as AxiosError<IResourceBadRequestException<CartCustomerNextFieldsType>>

				if (axios.isAxiosError(error) && !isUndefined(error.response)) {
					const { errors } = error.response.data

					if (!isEmpty(errors)) {
						const transportFromStoreError = find(errors, (error: IRestExceptionResponse<CartCustomerNextFieldsType>) => isEqual(error.field, 'customerAddressId' as CartCustomerNextFieldsType))

						if (!isUndefined(transportFromStoreError)) {
							handleOpen()
						} else {
							if (!isEmpty(errors)) {
								const message = [t('customer.submitValidation.caption')]

								map(errors, (error: IRestExceptionResponse<CartCustomerNextFieldsType>) => {
									message.push(`- ${ t(`customer.submitValidation.fields.${ error.field }`) } ${ error.errorMessages ? `- ${ error.errorMessages }` : '' }`)
								})

								newAlert('danger', <div dangerouslySetInnerHTML={ { __html: join(message, '<br />') } } />, 5000)
							} else {
								preventAlert(e)
							}
						}
					}
				}

				setIsNextLoading(false)
				sendLogError(e)
			}
		}
	}, [data, customerData, customerAddressId, isAnotherReceiverActive, invoice, receiptNip])

	const analyticsEvents = useCallback(() => {
		if (!isEmpty(cartItemList) && !isAnalyticsEventsSent) {
			GA_checkout({ cartItemList, step: 2, value: productsValue, cartStatus })
			setIsAnalyticsEventsSent(true)
		}
	}, [cartItemList, isAnalyticsEventsSent, productsValue, cartStatus])

	const renderContent = useCallback((): ReactElement => {
		if (isInitialLoader) {
			return (
				<CartCustomerLoggedLoader />
			)
		}

		return (
			<>
				<CartCustomerData />

				<CartCustomerContact />

				<CartCustomerOrderCollection otherContact={ customerOtherContact } />

				<CartCustomerInvoice
					isInvoiceRequired={ invoiceRequired }
					totalCostValue={ totalCostValue }
					receiptNip={ receiptNip }
					invoice={ invoice }
					customerAddressId={ customerAddressId }
					customerData={ customerData }
					isAnotherReceiverActive={ isAnotherReceiverActive }
					customerOtherContact={ customerOtherContact }
					isMainFormIncomplete={ isNextButtonDisabled }
				/>
			</>
		)
	}, [isInitialLoader, customerOtherContact, invoiceRequired, totalCostValue, receiptNip, invoice, delivery, customerAddressId, customerData, isAnotherReceiverActive, isNextButtonDisabled])

	useEffect(() => {
		(async () => {
			try {
				if (!isCustomerDataFetched) {
					await dispatch(getCartCustomerData())
				}
			} catch (e: unknown) {
				sendLogError(e)
			}
		})()
	}, [preferredStore, isCustomerDataFetched])

	useEffect(() => {
		analyticsEvents()
	}, [cartItemList])

	return (
		<CartCustomerWrapper>
			<CartColumnWrapper>
				{ renderContent() }
			</CartColumnWrapper>

			<CartCustomerAsideBlock
				cart={ cart }
				isNextButtonDisabled={ isNextButtonBlocked }
				nextButtonDisabledInfo={ nextButtonDisabledInfo }
				isNextLoading={ isNextLoading }
				isLoading={ isPending }
				onNext={ handleNext }
				onBack={ handleBack }
			/>

			<CartTransportFromStoreModal
				isOpen={ isOpen }
				onBack={ handleBack }
				onClose={ handleClose }
			/>
		</CartCustomerWrapper>
	)
}

export { CartCustomerLogged }
