import React, { ReactElement, useCallback, useContext } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'

import { CartHeaderStep, ICartHeaderProps } from '~/components/core/cartHeader'
import { ICartHeaderItem } from '~/hooks/cartHeader'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { HeaderLogotype } from '~/components/core/layout'

import styles from './CartHeader.module.scss'

const CartHeader = (props: ICartHeaderProps): ReactElement => {
	const { headerItems } = props
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const renderContent = useCallback(() => (
		map(headerItems, (step: ICartHeaderItem, index: number) => {
			const isLastItem = index === headerItems.length - 1

			return (
				<CartHeaderStep
					key={ step.id }
					hasStepLine={ !isLastItem }
					step={ step }
				/>
			)
		})
	), [headerItems])

	const renderLogoKobi = useCallback((): ReactElement | null => {
		if (isKobiIntegrationEnabled) {
			return (
				<div className={ styles.logotype }>
					<HeaderLogotype isWithoutAnimation />
				</div>
			)
		}

		return null
	}, [isKobiIntegrationEnabled])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.withKobi]: isKobiIntegrationEnabled,
	})

	return (
		<div className={ wrapperClass }>
			{ renderLogoKobi() }

			<div className={ styles.content }>
				{ renderContent() }
			</div>
		</div>
	)
}

export { CartHeader }
