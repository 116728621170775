import React, { ReactElement, ReactNode, useCallback } from 'react'

import { ICartPreviewSectionProps } from '~/components/cart/cartPreview'
import { SectionHeader } from '~/components/core/sectionHeader'
import { Loader } from '~/components/core/loader'

import styles from './CartPreviewSection.module.scss'

const CartPreviewSection = (props: ICartPreviewSectionProps): ReactElement => {
	const {
		title, children,
		additionalClass = '',
		isLoading = false,
	} = props

	const renderContent = useCallback((): ReactNode => {
		if (isLoading) {
			return (
				<div className={ styles.loaderWrapper }>
					<Loader />
				</div>
			)
		}

		return children
	}, [isLoading, children])

	return (
		<div className={ styles.wrapper }>
			<SectionHeader
				title={ title }
				additionalClass={ additionalClass }
			/>

			{ renderContent() }
		</div>
	)
}

export { CartPreviewSection }
