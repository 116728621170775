import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { find, isNil } from 'lodash'

import { StorePhoneType } from '~/api/dataTypes/store'
import { ArrowLink } from '~/components/core/arrowLink'
import { getPhoneUrl, IPreferredStoreInfoDataProps } from '~/components/core/preferredStore'
import { Link } from '~/components/core/link'
import { useNavigation } from '~/hooks/navigation'

import styles from './PreferredStoreInfoData.module.scss'

const PreferredStoreInfoData = (props: IPreferredStoreInfoDataProps): ReactElement => {
	const { store } = props
	const { t } = useTranslation(['stores'])
	const { getGoogleMapToStoreUrl } = useNavigation()

	const { address, name, phoneList } = store
	const { street, zipCode, city } = address

	const informationPointPhone = find(phoneList, ['phoneType', 'INFORMATION_POINT_PHONE' as StorePhoneType])
	const informationCreditPoint = find(phoneList, ['phoneType', 'INFORMATION_CREDIT_POINT_PHONE' as StorePhoneType])

	const showPhoneSection = !isNil(informationPointPhone) || !isNil(informationCreditPoint)

	const handleOpenMap = useCallback((): void => {
		window.open(getGoogleMapToStoreUrl(store), '_blank')
	}, [store])

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.header }>
				{ `${ t('myStore') }: ${ name }` }
			</span>

			<div className={ styles.data }>
				<span>
					{ `${ street }, ${ zipCode } ${ city }` }
				</span>

				<ArrowLink text={ t('checkWay') } onClick={ handleOpenMap } />
			</div>

			{ showPhoneSection && (
				<div className={ styles.info }>
					{ !isNil(informationPointPhone) && (
						<span>
							{ informationPointPhone.name }

							<Link
								isNativeLink
								href={ getPhoneUrl(informationPointPhone.number) }
								theme={ { wrapper: styles.link } }
								ariaLabel={ `${ t('call', { intro: informationPointPhone.name, tel: informationPointPhone.number }) }` }
							>
								{ informationPointPhone.number }
							</Link>
						</span>
					) }

					{ !isNil(informationCreditPoint) && (
						<div>
							{ informationCreditPoint.name }

							<Link
								isNativeLink
								href={ getPhoneUrl(informationCreditPoint.number) }
								theme={ { wrapper: styles.link } }
								ariaLabel={ `${ t('call', { intro: informationCreditPoint.name, tel: informationCreditPoint.number }) }` }
							>
								{ informationCreditPoint.number }
							</Link>
						</div>
					) }
				</div>
			) }
		</div>
	)
}

export { PreferredStoreInfoData }
