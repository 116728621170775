import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import { isUndefined } from 'lodash'

import { ISimpleStoreResponse } from '~/api/dataTypes/store'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IPreferredStoreBlockProps, PreferredStoreDistanceList, PreferredStoreList, PreferredStoreSelection } from '~/components/core/preferredStore'
import { Loader } from '~/components/core/loader'
import { Button } from '~/components/core/button'

import styles from './PreferredStoreBlock.module.scss'

const PreferredStoreBlock = (props: IPreferredStoreBlockProps): ReactElement => {
	const { isPending, items, selectedStore, onSelectStore, onClose } = props
	const { t } = useTranslation(['common', 'form'])
	const [isDistanceListVisible, setIsDistanceListVisible] = useToggle(false)
	const [geoCoords, setGeoCoords] = useState<GeolocationCoordinates | undefined>(undefined)

	const handleGetUserGeolocation = useCallback((): void => {
		navigator.geolocation.getCurrentPosition(async (position: GeolocationPosition) => {
			const { coords } = position

			setGeoCoords(coords)
			setIsDistanceListVisible(true)
		}, async (error: GeolocationPositionError) => {
			console.log(error.code, error.message)
		})
	}, [navigator])

	const handleSelectStore = useCallback((store: ISimpleStoreResponse | undefined): void => {
		onSelectStore(store)
		setIsDistanceListVisible(false)
	}, [onSelectStore])

	const showDistanceList = isDistanceListVisible && !isUndefined(geoCoords)

	const renderContent = useCallback((): ReactElement => {
		if (isPending) {
			return (
				<Loader theme={ { loader: styles.loader } } />
			)
		}

		if (showDistanceList) {
			return (
				<PreferredStoreDistanceList
					coords={ geoCoords }
					items={ items }
					onSelect={ handleSelectStore }
				/>
			)
		}

		return (
			<>
				<div className={ styles.info }>
					{ t('preferredShop.modalSelection.chooseForAvailability') }
				</div>

				<Button
					isReverse
					text={ t('preferredShop.modalSelection.getUserLocation') }
					variant="secondary"
					additionalClass={ styles.button }
					dataTestId={ DATA_TESTID.CORE.BUTTON_GET_USER_GEOLOCATION }
					onClick={ handleGetUserGeolocation }
				/>

				<PreferredStoreList items={ items } onSelect={ handleSelectStore } />

				<PreferredStoreSelection selectedStore={ selectedStore } onClick={ onClose } />
			</>
		)
	}, [isPending, showDistanceList, geoCoords, items, selectedStore, onClose])

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.CORE.STORE_BLOCK }>
			<div className={ styles.caption }>
				{ t('preferredShop.modalSelection.findInTheArea') }
			</div>

			{ renderContent() }
		</div>
	)
}

export { PreferredStoreBlock }
