import { AxiosResponse } from 'axios'

import { ISearchResponse } from '~/api/dataTypes/search'
import { request } from '~/api/requests/axios'

export const getSuggestions = async (keyword: string): Promise<AxiosResponse<ISearchResponse>> => (
	await request.get('/v1/catalog/search-suggestions', {
		params: { keyword },
		withStoreCode: true,
	})
)
