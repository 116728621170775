import React, { ReactElement, useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { isUndefined, map } from 'lodash'

import { CartCustomerInvoiceModalActions, CartCustomerInvoiceModalPrivateFormFields, ICartCustomerInvoiceModalPrivateForm, ICartCustomerInvoiceModalPrivateFormProps } from '~/components/cart/cartCustomer'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { validateCartCustomerPrivateInvoice } from '~/actions/cartCustomer'
import { AppDispatch } from '~/state/store'
import { hasNotNumberAndAllowOnlyDash, hasOnlyLetters, hasOnlyLettersNumbersOrDash, hasOnlyLettersSpacesOrDash, hasOnlyLettersNumbersSpacesDotsOrDash } from '~/utils/string'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { ICartCustomerPrivateInvoice } from '~/api/dataTypes/cart'

const CartCustomerInvoiceModalPrivateForm = (props: ICartCustomerInvoiceModalPrivateFormProps): ReactElement => {
	const { onClose, onCancel, invoice } = props
	const { t, i18n } = useTranslation(['cart', 'form'])
	const { newAlert } = useAlert()
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()

	const { contact, address } = invoice
	const { firstName, lastName } = contact || {}
	const { street, houseNumber, flatNumber, zipCode, city } = address || {}

	const firstNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLetters', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoicePrivateForm.firstNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLetters(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const lastNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasNotNumberAndAllowOnlyDash', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoicePrivateForm.lastNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasNotNumberAndAllowOnlyDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const houseNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).test('hasOnlyLettersOrNumbers', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoicePrivateForm.buildingNumberLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const flatNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).test('hasOnlyLettersOrNumbers', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoicePrivateForm.flatNumberLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersOrDash(value)), [t, i18n])
	const citySchema = useMemo(() => yup.string().max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLettersOrSpaces', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoicePrivateForm.cityLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersSpacesOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const streetSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(42, t('mustBeShortestThen42', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoicePrivateForm.streetLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])

	const schema: yup.SchemaOf<ICartCustomerInvoiceModalPrivateForm> = useMemo(() => yup.object().shape({
		firstName: firstNameSchema,
		lastName: lastNameSchema,
		street: streetSchema,
		houseNumber: houseNumberSchema,
		flatNumber: flatNumberSchema,
		zipCode: yup.string().required(t('required')),
		city: citySchema,
	}), [t, i18n])

	const formProps = useForm<ICartCustomerInvoiceModalPrivateForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: firstName || '',
			lastName: lastName || '',
			street: street || '',
			houseNumber: houseNumber || '',
			flatNumber: flatNumber || '',
			zipCode: zipCode || '',
			city: city || '',
		},
		mode: 'onChange',
	})

	const { handleSubmit } = formProps

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerInvoiceModalPrivateForm) => {
		try {
			const { firstName, lastName, ...restFormData } = formData

			const params: ICartCustomerPrivateInvoice = {
				firstName,
				lastName,
				customerType: 'PRIVATE',
				address: {
					...restFormData,
					addressType: 'INVOICE',
				},
			}

			await dispatch(validateCartCustomerPrivateInvoice(params))

			onClose()
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					formProps.setError(field as FieldPath<ICartCustomerInvoiceModalPrivateForm>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				newAlert('danger', getErrorMessage(code), 5000)
			}

			sendLogError(e)
		}
	}), [])

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<FormProvider { ...formProps }>
			<CartCustomerInvoiceModalPrivateFormFields />

			<CartCustomerInvoiceModalActions onCancel={ onCancel } onSubmit={ handleFormSubmit } />
		</FormProvider>
	)
}

export { CartCustomerInvoiceModalPrivateForm }
