import React, { ReactElement, useCallback } from 'react'
import { SlideDown } from 'react-slidedown'
import { useToggle } from 'react-use'
import { isUndefined } from 'lodash'

import { IDotMenuProps, DotMenuList } from '~/components/core/dotMenu'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './DotMenu.module.scss'

const DotMenu = (props: IDotMenuProps): ReactElement | null => {
	const { nodes } = props
	const [isVisible, setIsVisible] = useToggle(false)
	const { isTablet } = useMediaQuery()

	const renderMenu = useCallback((): ReactElement => {
		if (isTablet) {
			return (
				<DotMenuList items={ nodes } />
			)
		}

		return (
			<div className={ styles.wrapper }>
				<Button
					size="inherit"
					variant="neutral"
					additionalClass={ styles.menu }
					onClick={ setIsVisible }
					// TODO: add aria-label
				>
					<Icon
						name="menuDots"
						width={ 4 }
						height={ 18 }
						color="navy"
					/>
				</Button>

				<SlideDown className={ styles.menuList }>
					{ isVisible && (
						<div className={ styles.list }>
							<DotMenuList items={ nodes } />
						</div>
					) }
				</SlideDown>
			</div>
		)
	}, [isTablet, isVisible])

	if (isUndefined((nodes))) {
		return null
	}

	return (
		renderMenu()
	)
}

export { DotMenu }
