import { getStore, getStoresSimpleList } from '~/api/requests/store'
import { AppPromiseThunkAction } from '~/actions'
import { GetStoreMapInfoWindowActionType, ClearStoreMapInfoWindowdActionType, SetStoreUserLocationActionType, ClearStoreUserLocationActionType, StoresListActionType, GetSimpleStoreListActionType } from '~/actions/stores'
import { LatLng } from '~/hooks/distance'

// eslint-disable-next-line @typescript-eslint/typedef
export const getStoresMapInfoWindow = (storeCode: string): AppPromiseThunkAction<GetStoreMapInfoWindowActionType> => async (dispatch) => {
	return dispatch({
		type: StoresListActionType.GET_STORE_MAP_INFO_WINDOW,
		payload: getStore({ storeCode }),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearStoresMapInfoWindow = (): AppPromiseThunkAction<ClearStoreMapInfoWindowdActionType> => (dispatch) => {
	return dispatch({
		type: StoresListActionType.CLEAR_STORE_MAP_INFO_WINDOW,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setStoreUserLocation = (coords: LatLng): AppPromiseThunkAction<SetStoreUserLocationActionType> => (dispatch) => {
	return dispatch({
		type: StoresListActionType.SET_STORE_USER_LOCATION,
		payload: Promise.resolve(coords),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearStoreUserLocation = (): AppPromiseThunkAction<ClearStoreUserLocationActionType> => (dispatch) => {
	return dispatch({
		type: StoresListActionType.CLEAR_STORE_USER_LOCATION,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getSimpleStoreList = (): AppPromiseThunkAction<GetSimpleStoreListActionType> => (dispatch) => {
	return dispatch({
		type: StoresListActionType.GET_SIMPLE_STORE_LIST,
		payload: getStoresSimpleList(),
	})
}
