import dayjs, { ConfigType, Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

import { IUseDateData } from '~/hooks/date'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

export const useDate = (): IUseDateData => {
	const getDateFormat = (): string => 'DD.MM.YYYY'
	const getDateShortFormat = (): string => 'DD.MM'
	const getDateFormatIso = (): string => 'YYYY-MM-DD'
	const getTimeFormat = (): string => 'HH:mm'
	const getFullTimeFormat = (): string => 'HH:mm:ss'
	const getDateTimeFormat = (): string => 'DD.MM.YYYY HH:mm'
	const getDateFullTimeFormat = (): string => 'DD.MM.YYYY HH:mm:ss'
	const getDateFormatReactDatePicker = (): string => 'dd/MM/yyyy'
	const getDateFormatCalendar = (): string => 'DD/MM/YYYY'
	const getDateTimeFormatCalendar = (): string => 'DD/MM/YYYY HH:mm'
	const getDateTimeIsoFormat = (): string => 'YYYY-MM-DDTHH:mm:ss'
	const getYearFormat = (): string => 'YYYY'

	const formatDate = (date: ConfigType, template: string): string => dayjs(date).format(template)
	const formatDateUtc = (date: ConfigType, template: string): string => dayjs.utc(date).format(template)
	const formatDateUnix = (time: number, template: string): string => dayjs.unix(time).format(template)
	const stringToDate = (date: string, template: string): Dayjs => dayjs(date, template)
	const getTodayDate = (): Dayjs => dayjs()
	const getFirstDayOfActiveMonth = (): string => dayjs().startOf('month').format(getDateFormatIso())
	const getLastDayOfActiveMonth = (): string => dayjs().endOf('month').format(getDateFormatIso())
	const getFirstDayOfMonth = (date: ConfigType): string => dayjs(date).startOf('month').format(getDateFormatIso())
	const getLastDayOfMonth = (date: ConfigType): string => dayjs(date).endOf('month').format(getDateFormatIso())
	const getDaysDiff = (date: number): number => dayjs.unix(date).startOf('D').diff(dayjs().startOf('D'), 'days')

	const isValidDate = (date: string | undefined, template: string): boolean => dayjs(date, template, true).isValid()

	return {
		getDateFormat,
		getDateShortFormat,
		getDateFormatIso,
		getTimeFormat,
		getFullTimeFormat,
		getDateTimeFormat,
		getDateFullTimeFormat,
		getDateFormatReactDatePicker,
		getDateFormatCalendar,
		getDateTimeFormatCalendar,
		getDateTimeIsoFormat,
		getYearFormat,

		formatDate,
		formatDateUtc,
		formatDateUnix,
		stringToDate,
		getTodayDate,
		getFirstDayOfActiveMonth,
		getLastDayOfActiveMonth,
		getFirstDayOfMonth,
		getLastDayOfMonth,
		getDaysDiff,

		isValidDate,
	}
}
