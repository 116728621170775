import React, { ReactElement } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'

import { ErrorBoundary, ITextareaProps, Label } from '~/components/core/form'
import { useMozaic } from '~/hooks/mozaic'

import styles from './Textarea.module.scss'

const Textarea = <FormFields extends FieldValues = FieldValues>(props: ITextareaProps<FormFields>): ReactElement => {
	const {
		label, name, control,
		placeholder = '',
		isValid = false,
		isInvalid = false,
		isDisabled = false,
		isRequired = false,
		additionalClass = '',
	} = props
	const { field, fieldState: { error } } = useController<FormFields>({ control, name })
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const isError = !isUndefined(error) && !isEmpty(error.message)

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	const formControlClass = classNames({
		[styles.formControl]: true,
		[styles.valid]: isValid,
		[styles.invalid]: isInvalid,
	})

	const textareaClass = classNames({
		[styles.textarea]: true,
		[styles.error]: isError,
		[styles.valid]: isValid,
		[styles.invalid]: isInvalid,
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<Label
				isRequired={ isRequired }
				name={ name }
				caption={ label }
			/>

			<div className={ formControlClass }>
				<textarea
					id={ name }
					rows={ 3 }
					placeholder={ placeholder }
					className={ textareaClass }
					disabled={ isDisabled }
					aria-label={ label }
					// eslint-disable-next-line react/jsx-props-no-spreading
					{ ...field }
				/>
			</div>

			<ErrorBoundary error={ error } />
		</div>
	)
}

export { Textarea }
