import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { Icon } from '~/components/core/icon'
import { ICalendarToggleMonthProps } from '~/components/core/calendar'

import styles from './CalendarToggleMonth.module.scss'

const CalendarToggleMonth = (props: ICalendarToggleMonthProps): ReactElement => {
	const { type } = props

	const iconClass = classNames({
		[styles.prevIcon]: type === 'prev',
	})

	return (
		<Icon
			name="arrow"
			color="gray"
			additionalClass={ iconClass }
			width={ 8 }
			height={ 12 }
		/>
	)
}

export { CalendarToggleMonth }
