import React, { ElementType, ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'

import { ISectionHeaderProps } from '~/components/core/sectionHeader'
import { ArrowLink } from '~/components/core/arrowLink'
import { Link } from '~/components/core/link'
import { useMozaic } from '~/hooks/mozaic'

import styles from './SectionHeader.module.scss'

const SectionHeader = (props: ISectionHeaderProps): ReactElement => {
	const {
		title,
		tag = 'h2',
		label = '',
		borderBottomColor = 'green',
		linkTitle = '',
		linkUrl = '',
		additionalClass = '',
		additionalTitleClass = '',
		counter = undefined,
		RightCustomAccessory = null,
		size = '',
		dataTestId = '',
		theme = {},
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const WrapperTag: ElementType = tag

	const isLinkVisible = !isEmpty(linkTitle) && !isEmpty(linkUrl)
	const isCounterVisible = !isUndefined(counter)
	const isLabel = !isEmpty(label)

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, additionalClass, theme.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	const titleClass = shouldUseMozaic ? classNames('mt-heading mt-heading--s mt-heading--underline', additionalTitleClass) : classNames(styles.title, {
		[styles[size]]: !isEmpty(size),
		[styles[borderBottomColor]]: borderBottomColor,
	}, theme.title)

	return (
		<div className={ wrapperClass }>
			<WrapperTag className={ titleClass } data-testid={ dataTestId }>
				{ isLabel && <span className={ styles.label }>{ label }{ ' ' }</span> }

				{ title }

				{ isCounterVisible && <span className={ styles.counter }>({ counter })</span> }
			</WrapperTag>

			{ isLinkVisible && (
				<Link
					passHref
					href={ linkUrl }
					ariaLabel={ title }
				>
					<ArrowLink text={ linkTitle } />
				</Link>
			) }

			{ RightCustomAccessory }
		</div>
	)
}

export { SectionHeader }
