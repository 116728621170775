import { Draft, produce } from 'immer'
import { isNil } from 'lodash'

import { AppPromiseAction } from '~/actions'
import { ProductCardActionType, ProductCardActionUnionType } from '~/actions/productCard'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'

import { productCardOpinionsInitialState as initialState } from './constants'
import { ProductCardOpinionsState } from './types'

export const productCardOpinions = produce((draft: Draft<ProductCardOpinionsState>, action: AppPromiseAction<ProductCardActionUnionType>): ProductCardOpinionsState => {
	switch (action.type) {
		case REQUEST(ProductCardActionType.GET_REVIEWS): {
			draft.isPending = true

			return draft
		}

		case SUCCESS(ProductCardActionType.SET_REVIEWS): {
			const { payload } = action

			draft.isPending = false
			draft.data = payload

			return draft
		}

		case SUCCESS(ProductCardActionType.GET_REVIEWS): {
			const { payload } = action

			draft.isPending = false
			draft.data = payload.data

			return draft
		}

		case FAILURE(ProductCardActionType.GET_REVIEWS): {
			draft.isPending = false

			return draft
		}

		case REQUEST(ProductCardActionType.GET_REVIEWS_NEXT_PAGE): {
			draft.isPending = true

			return draft
		}

		case SUCCESS(ProductCardActionType.GET_REVIEWS_NEXT_PAGE): {
			const { payload } = action

			draft.isPending = false

			if (!isNil(draft.data)) {
				draft.data.productReviewList = [
					...draft.data.productReviewList,
					...payload.data.productReviewList,
				]
			}

			return draft
		}

		case SUCCESS(ProductCardActionType.CLEAN_UP): {
			return initialState
		}

		default:
			return draft
	}
}, initialState)
