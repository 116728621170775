import React, { ReactElement } from 'react'

import { IHamburgerMenuUserAccountWrapperProps } from '~/components/core/hamburgerMenu'

import styles from './HamburgerMenuUserAccountWrapper.module.scss'

const HamburgerMenuUserAccountWrapper = (props: IHamburgerMenuUserAccountWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { HamburgerMenuUserAccountWrapper }
