import { find, isEqual, isUndefined } from 'lodash'

import { ICustomerLoyaltyAccountReward } from '~/api/dataTypes/customerLoyalty'
import { ICartPreviewDiscountLysSelectedReward } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'

export const getSelectedRewardElement = (currentRewards: ICustomerLoyaltyAccountReward[], selectedReward: ICartPreviewDiscountLysSelectedReward): ICustomerLoyaltyAccountReward | undefined => (
	find(currentRewards, (reward: ICustomerLoyaltyAccountReward) => {
		const isRewardSelected = reward?.selected || false
		const shouldBeRewardSelected = isEqual(reward?.code, selectedReward.code) && isEqual(reward.type, selectedReward.type)

		return isRewardSelected || shouldBeRewardSelected
	})
)

export const limitCurrentRewards = (isEditMode: boolean, isEcommerce: boolean, isLoyalty: boolean, availableRewards: ICustomerLoyaltyAccountReward[], selectedRewardElement: ICustomerLoyaltyAccountReward | undefined): ICustomerLoyaltyAccountReward[] => {
	if (isEcommerce) {
		return []
	} else if (isLoyalty && !isUndefined(selectedRewardElement) && !isEditMode) {
		return [selectedRewardElement]
	}

	return availableRewards
}
