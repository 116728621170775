import { find, isUndefined } from 'lodash'

import { IProductSimple } from '~/api/dataTypes/product'
import { IComparisonProduct } from '~/state/reducers/comparisonReducer'

export const getComparisonBlockProduct = (product: IProductSimple): IComparisonProduct => {
	const { id, name, image, wwwUrl } = product
	const { thumbnailUrl: thumbnail } = image
	const category = 123 // FIXME to change after api add category id to response

	return ({
		id,
		name,
		wwwUrl,
		category,
		thumbnail,
	})
}

export const getIsProductInComparison = (id: number, comparisonProducts: IComparisonProduct[]): boolean => {
	const product = find(comparisonProducts, (product: IComparisonProduct) => product.id === id)

	return !isUndefined(product)
}
