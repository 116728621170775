import { AxiosResponse } from 'axios'

import { ICartDeliveryNextData } from '~/actions/cart'
import { ICartSimpleResponse, IShoppingCartResponse, ICartDeliveryNextResponse, IAddToCartParameters, IAddToCartResponse, ITransferProductsToCart, ISetQuantityProductInCartParameters, ISetQuantityProductInCartResponse, IRemoveFromCartParameters, IRemoveProductFromCartResponse, IRemoveProductsFromCartResponse, ICartPreviewCalculateParams, IShoppingCartSimpleResponse, IGetCartDeliveryPickupInStoreTimeslotsResponse, ICartDeliveryCalculateParams, ICartDeliveryPickupPointParameters, ICartDeliveryPickupPointResponse, ICartCustomerResponse, ICartSummaryResponse, ICartSummaryFinalizeResponse, ICartDeliveryTransportFromStoreCategoriesParameters, ITransportCategoriesResponse, ICartDeliveryTransportFromStoreTimeslotsParameters, ITransportTimeSlotsCalendarResponse, ICartIdentificationParameters, ICartIdentificationResponse, ICartMergeParams, ICartDeliveryTransportFromStoreParameters, ICartDeliveryTransportFromStorePickupPointParameters, ICartCustomerValidateSimpleDataParameters, ICartCustomerNextParams, ICartCustomerNextResponse, IInvoiceData, ILoyaltyProgramWithRewardResponse, ICartLoyaltyProgramRewardParams, ICartLoyaltyProgramResponse, ICheckAvailabilityResponse, ICartCheckPriceParams, IGetCartCheckPriceResponse, ICartIdentificationOrderAsGuestResponse, ITransferProductsFromPlannerToCartParams, ITransferProductsFromPlannerToCartParamsResponse } from '~/api/dataTypes/cart'
import { request } from '~/api/requests/axios'

export const getCartSimple = async (): Promise<AxiosResponse<ICartSimpleResponse>> => (
	await request.get('/v1/cart/simple', {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const getCart = async (isAllowOverwriteDeliveryWay?: boolean, isStepZero?: boolean): Promise<AxiosResponse<IShoppingCartResponse>> => (
	await request.get('/v1/cart', {
		cartUuid: true,
		restAuthorization: true,
		params: {
			allowOverwriteDeliveryWay: isAllowOverwriteDeliveryWay || false, // true only on 1st render step1
			fromStepZero: isStepZero,
		},
	})
)

export const postCartDeliveryNext = async (data: ICartDeliveryNextData): Promise<AxiosResponse<ICartDeliveryNextResponse>> => (
	await request.post('/v1/cart/delivery/next', data, {
		restAuthorization: true,
		cartUuid: true,
	})
)

export const postCartDeliveryNextWithAccept = async (data: ICartDeliveryNextData): Promise<AxiosResponse<ICartDeliveryNextResponse>> => (
	await request.post('/v1/cart/delivery/next-with-accept', data, {
		restAuthorization: true,
		cartUuid: true,
	})
)

export const postAddProductToCart = async (params: IAddToCartParameters): Promise<AxiosResponse<IAddToCartResponse>> => (
	await request.post('/v1/cart/items/add', null, {
		params,
		restAuthorization: true,
		cartUuid: true,
	})
)

export const postTransferProductsToCart = async (params: ITransferProductsToCart): Promise<AxiosResponse<ICartSimpleResponse>> => (
	await request.post('/v1/cart/items/transfer', params, {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const postTransferProductsFromPlannerToCart = async (params: ITransferProductsFromPlannerToCartParams): Promise<AxiosResponse<ITransferProductsFromPlannerToCartParamsResponse>> => (
	await request.post('/cart/v2/products', params, {
		service: 'ADEO_CART_SERVICE',
		restAuthorization: true,
	})
)

export const postSetQuantityProductInCart = async (params: ISetQuantityProductInCartParameters): Promise<AxiosResponse<ISetQuantityProductInCartResponse>> => (
	await request.post('/v1/cart/items/set-quantity', null, {
		params,
		cartUuid: true,
		restAuthorization: true,
	})
)

export const deleteProductFromCart = async (params: IRemoveFromCartParameters): Promise<AxiosResponse<IRemoveProductFromCartResponse>> => {
	const { lmReference } = params

	return await request.delete(`/v1/cart/items/${ lmReference }`, {
		cartUuid: true,
		restAuthorization: true,
	})
}

export const deleteProductsFromCart = async (): Promise<AxiosResponse<IRemoveProductsFromCartResponse>> => (
	await request.delete('/v1/cart', {
		restAuthorization: true,
		cartUuid: true,
	})
)

export const postCartCalculate = async (params: ICartPreviewCalculateParams): Promise<AxiosResponse<IShoppingCartSimpleResponse>> => {
	return await request.post('/v1/cart/calculate', params, {
		cartUuid: true,
		restAuthorization: true,
	})
}

export const getCartDeliveryPickupInStoreTimeslots = async (): Promise<AxiosResponse<IGetCartDeliveryPickupInStoreTimeslotsResponse>> => (
	await request.get('/v1/cart/delivery/pickup-in-store/timeslots', {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const postCartDeliveryCalculate = async (params: ICartDeliveryCalculateParams): Promise<AxiosResponse<IShoppingCartResponse>> => {
	return await request.post('/v1/cart/delivery/calculate', params, {
		cartUuid: true,
		restAuthorization: true,
	})
}

export const getCartDeliveryPickupPoint = async (params: ICartDeliveryPickupPointParameters): Promise<AxiosResponse<ICartDeliveryPickupPointResponse>> => {
	return await request.get('/v1/cart/delivery/pickup-point/search', {
		params,
		cartUuid: true,
		restAuthorization: true,
	})
}

export const putCartDeliveryPickupInStoreBooking = async (localDateTime: string): Promise<AxiosResponse<ICartDeliveryPickupPointResponse>> => {
	return await request.put('/v1/cart/delivery/pickup-in-store/booking', { localDateTime }, {
		cartUuid: true,
		restAuthorization: true,
	})
}

export const getCartCustomer = async (): Promise<AxiosResponse<ICartCustomerResponse>> => (
	await request.get('/v1/cart/customer', {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const getCartSummary = async (): Promise<AxiosResponse<ICartSummaryResponse>> => (
	await request.get('/v1/cart/summary', {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const postCartSummary = async (): Promise<AxiosResponse<ICartSummaryFinalizeResponse>> => (
	await request.post('/v1/cart/summary', null, {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const getCartDeliveryTransportFromStoreCategories = async (params: ICartDeliveryTransportFromStoreCategoriesParameters): Promise<AxiosResponse<ITransportCategoriesResponse>> => (
	await request.get('/v1/cart/delivery/transport-from-store/categories', {
		params,
		cartUuid: true,
		restAuthorization: true,
	})
)

export const getCartDeliveryTransportFromStoreTimeslots = async (params: ICartDeliveryTransportFromStoreTimeslotsParameters): Promise<AxiosResponse<ITransportTimeSlotsCalendarResponse>> => (
	await request.get('/v1/cart/delivery/transport-from-store/timeslots', {
		params,
		cartUuid: true,
		restAuthorization: true,
	})
)

export const postCartIdentification = async (params: ICartIdentificationParameters): Promise<AxiosResponse<ICartIdentificationResponse>> => (
	await request.post('/v1/cart/identification', null, {
		params,
		restAuthorization: true,
	})
)

export const postCartIdentificationOrderAsGuest = async (cartUuid: string): Promise<AxiosResponse<ICartIdentificationOrderAsGuestResponse>> => (
	await request.post('/v1/cart/identification/order-as-guest', null, {
		params: {
			cartUuid,
		},
	})
)

export const getCartIdentification = async (cartUuid: string): Promise<AxiosResponse<ICartIdentificationResponse>> => (
	await request.get('/v1/cart/identification', {
		params: {
			cartUuid,
		},
	})
)

export const postCartMerge = async (params: ICartMergeParams): Promise<AxiosResponse<ICartSimpleResponse>> => (
	await request.post('/v1/cart/merge', null, {
		params,
		restAuthorization: true,
	})
)

export const putCartDeliveryTransportFromStore = async (params: ICartDeliveryTransportFromStoreParameters): Promise<AxiosResponse> => (
	await request.put('/v1/cart/delivery/transport-from-store', params, {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const putCartDeliveryTransportPickupPoint = async (params: ICartDeliveryTransportFromStorePickupPointParameters): Promise<AxiosResponse> => (
	await request.put('/v1/cart/delivery/transport-from-store/pickup-point', params, {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const postCartCustomerValidatePrivateContactData = async (params: ICartCustomerValidateSimpleDataParameters): Promise<AxiosResponse> => (
	await request.post('/v1/cart/customer/validate/private-contact-data', params, {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const postCartCustomerValidateCompanyContactData = async (params: ICartCustomerValidateSimpleDataParameters): Promise<AxiosResponse> => (
	await request.post('/v1/cart/customer/validate/company-contact-data', params, {
		cartUuid: true,
		restAuthorization: true,
	})
)

export const postCartCustomerNext = async (params: ICartCustomerNextParams): Promise<AxiosResponse<ICartCustomerNextResponse>> => (
	await request.post('/v1/cart/customer/next', params, {
		restAuthorization: true,
		cartUuid: true,
	})
)

export const postCartCustomerValidatePrivateInvoiceData = async (params: IInvoiceData): Promise<AxiosResponse> => {
	return await request.post('/v1/cart/customer/validate/private-invoice-data', params, {
		restAuthorization: true,
	})
}

export const postCartCustomerValidateCorporateInvoiceData = async (params: IInvoiceData): Promise<AxiosResponse> => {
	return await request.post('/v1/cart/customer/validate/company-invoice-data', params, {
		restAuthorization: true,
	})
}

export const getCartLoyaltyProgramRewards = async (cardNumber: string): Promise<AxiosResponse<ILoyaltyProgramWithRewardResponse>> => {
	return await request.get(`/v1/cart/loyalty-program/${ cardNumber }/rewards`, {
		restAuthorization: true,
		cartUuid: true,
	})
}

export const postCartLoyaltyProgramSelectReward = async (params: ICartLoyaltyProgramRewardParams): Promise<AxiosResponse<ICartLoyaltyProgramResponse>> => {
	return await request.post('/v1/cart/loyalty-program/select-reward', params, {
		restAuthorization: true,
		cartUuid: true,
	})
}

export const putCartLoyaltyProgramRemoveCard = async (): Promise<AxiosResponse<ICartLoyaltyProgramResponse>> => {
	return await request.put('/v1/cart/loyalty-program/remove', null, {
		restAuthorization: true,
		cartUuid: true,
	})
}

export const getCartCheckAvailability = async (deliveryWayId: number): Promise<AxiosResponse<ICheckAvailabilityResponse>> => {
	return await request.get('/v1/cart/check-availability', {
		restAuthorization: true,
		cartUuid: true,
		params: {
			deliveryWayId,
		},
	})
}

export const getCartCheckPrice = async (params: ICartCheckPriceParams): Promise<AxiosResponse<IGetCartCheckPriceResponse>> => {
	return await request.get('/v1/cart/check-price', {
		restAuthorization: true,
		cartUuid: true,
		params,
	})
}
