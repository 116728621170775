import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import { vars } from '~/statics'
import { ICustomerOrderListRequestParams, ICustomerOrderListResponse, ICustomerOrderDetailsResponse, IAvailableOrderPaymentWayResponse, ICustomerOrderPaymentWayRequestParams, ICustomerOrderUpdateRequestParams, ICustomerOrderInvoiceResponse, ICustomerPostAddToCartResponse } from '~/api/dataTypes/customerOrder'
import { request } from '~/api/requests/axios'

export const getCustomerOrdersList = async (params?: ICustomerOrderListRequestParams): Promise<AxiosResponse<ICustomerOrderListResponse>> => (
	await request.get('/v1/orders', {
		params,
		service: 'CUSTOMER_ORDER_SERVICE',
	})
)

export const getCustomerOrderDetails = async (orderFrontId: string): Promise<AxiosResponse<ICustomerOrderDetailsResponse>> => (
	await request.get(`/v1/orders/${ orderFrontId }`, {
		service: 'CUSTOMER_ORDER_SERVICE',
	})
)

export const getCustomerOrderPaymentWays = async (orderFrontId: string): Promise<AxiosResponse<IAvailableOrderPaymentWayResponse[]>> => {
	const params: ICustomerOrderPaymentWayRequestParams = {
		clientSupportedPaymentCodes: vars.supportedPaymentWays,
	}

	return (
		await request.get(`/v1/orders/${ orderFrontId }/payment-ways`, {
			params,
			paramsSerializer: (): string => queryString.stringify(params),
			service: 'CUSTOMER_ORDER_SERVICE',
		})
	)
}

export const updateCustomerOrder = async (orderFrontId: string, params: ICustomerOrderUpdateRequestParams): Promise<AxiosResponse> => (
	await request.patch(`/v1/orders/${ orderFrontId }`, params, {
		service: 'CUSTOMER_ORDER_SERVICE',
	})
)

export const getCustomerOrderInvoice = async (orderFrontId: string): Promise<AxiosResponse<ICustomerOrderInvoiceResponse>> => (
	await request.get(`/v1/orders/${ orderFrontId }/invoice`, {
		service: 'CUSTOMER_ORDER_SERVICE',
	})
)

export const postAddToCart = async (orderFrontId: string): Promise<AxiosResponse<ICustomerPostAddToCartResponse>> => (
	await request.post(`/v1/orders/${ orderFrontId }/add-to-cart`, null, {
		service: 'CUSTOMER_ORDER_SERVICE',
	})
)

export const putCancelOrder = async (orderFrontId: string): Promise<AxiosResponse> => (
	await request.put(`/v1/orders/${ orderFrontId }/cancellation`, null, {
		service: 'CUSTOMER_ORDER_SERVICE',
	})
)
