import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { isEmpty, filter } from 'lodash'

import { usePrice } from '~/hooks/price'
import { ICartPreviewProductsPriceBlockProps, CartPreviewProductsLabels } from '~/components/cart/cartPreview'
import { ICartItemListLabel } from '~/api/dataTypes/cart'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsPriceBlock.module.scss'

const CartPreviewProductsPriceBlock = (props: ICartPreviewProductsPriceBlockProps): ReactElement => {
	const {
		price, labels,
		priceUnit = '',
	} = props
	const { priceFormat } = usePrice()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { newPrice, promotionalPrice } = price
	const priceUnitValue = !isEmpty(priceUnit) ? ` / ${ priceUnit }` : ''
	const priceLabels = filter(labels, (label: ICartItemListLabel) => label.type === 'PRICE')

	const priceClass = classNames(styles.price, {
		[styles.promotion]: promotionalPrice,
		[styles.isMozaic]: shouldUseMozaic,
	})

	const renderProductLabels = useCallback((): ReactElement | null => {
		if (isEmpty(priceLabels)) {
			return null
		}

		return <CartPreviewProductsLabels labels={ priceLabels } />
	}, [priceLabels])

	return (
		<div className={ styles.wrapper }>
			<span className={ priceClass }>
				{ `${ priceFormat(newPrice, { precision: 2 }) } ${ priceUnitValue }` }
			</span>

			{ renderProductLabels() }
		</div>
	)
}

export { CartPreviewProductsPriceBlock }
