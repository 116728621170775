import React, { ReactElement, useCallback, useMemo } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'next-i18next'
import axios, { AxiosError } from 'axios'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { isUndefined, find } from 'lodash'

import { useRecaptchaError } from '~/hooks/recaptchaError'
import { useLogError } from '~/hooks/logError'
import { useFormError } from '~/hooks/formError'
import { useAlert } from '~/hooks/alert'
import { useMozaic } from '~/hooks/mozaic'
import { DATA_TESTID } from '~/utils/dataTestId'
import { INewsletterFormData, INewsletterFormProps } from '~/components/core/newsletter'
import { Checkbox, Input } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { CollapseText } from '~/components/core/collapseText'
import { RecaptchaLabel } from '~/components/core/recaptchaLabel'
import { useGoogleRecaptcha } from '~/hooks/googleRecaptcha'
import { AcceptanceCheckType } from '~/api/dataTypes/app'
import { INewsletterResponseException } from '~/api/dataTypes/newsletter'
import { postNewsletterSubscribe } from '~/api/requests/newsletter'

import styles from './NewsletterForm.module.scss'

const NewsletterForm = (props: INewsletterFormProps): ReactElement => {
	const { agreements } = props
	const { t, i18n } = useTranslation(['newsletter'])
	const { newAlert } = useAlert()
	const [isLoading, setIsLoading] = useToggle(false)
	const { executeRecaptcha } = useGoogleRecaptcha()
	const { getErrorMessage } = useFormError()
	const { isDisabled, onRecaptchaError } = useRecaptchaError()
	const { sendLogError } = useLogError()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const marketingAgreement = find(agreements, ['code', 'NEWSLETTER_MARKETING' as AcceptanceCheckType])

	const schema: yup.SchemaOf<INewsletterFormData> = useMemo(() => yup.object().shape({
		newsletterEmail: yup.string().email(t('validation.emailFormat')).required(t('required', { ns: 'form' })),
		agreement: yup.boolean().oneOf([true], t('validation.agreementRequired')).required(t('validation.agreementRequired')),
	}), [t, i18n])

	const { control, handleSubmit, reset, setError, setValue } = useForm<INewsletterFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			newsletterEmail: '',
			agreement: false,
		},
	})

	const handleToggleCheckbox = useCallback((fieldName: FieldPath<INewsletterFormData>, value: boolean) => {
		setValue(fieldName, value)
	}, [])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: INewsletterFormData): Promise<void> => {
		const { newsletterEmail } = formData

		setIsLoading(true)

		try {
			const reCaptchaToken = await executeRecaptcha('submitNewsletter')

			const { data: { message } } = await postNewsletterSubscribe({ email: newsletterEmail }, reCaptchaToken)

			newAlert('success', <div dangerouslySetInnerHTML={ { __html: message } } />)
			reset()
		} catch (e: unknown) {
			const error = e as AxiosError<INewsletterResponseException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				setError('newsletterEmail', {
					message: getErrorMessage(code),
				})

				onRecaptchaError(code)
			} else {
				setError('newsletterEmail', {
					message: getErrorMessage('UNKNOWN_EXCEPTION'),
				})
			}

			sendLogError(e)
		} finally {
			setIsLoading(false)
		}
	}), [executeRecaptcha])

	const buttonClass = classNames(styles.button, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<form className={ styles.wrapper } onSubmit={ handleFormSubmit }>
			<div className={ styles.content }>
				<Input
					control={ control }
					name="newsletterEmail"
					label={ t('newsletter.mailLabel') }
					type="email"
					placeholder={ t('newsletter.mailPlaceholder') }
					additionalClass={ styles.input }
				/>
			</div>

			{ !isUndefined(marketingAgreement) && (
				<Checkbox
					control={ control }
					name="agreement"
					theme={ {
						wrapper: styles.checkbox,
						label: styles.label,
					} }
					ariaLabelledBy="newsletterCheckboxLabel"
					dataTestId={ DATA_TESTID.CORE.CHECKBOX_AGREEMENT }
					onToggleCheckbox={ handleToggleCheckbox }
				>
					<CollapseText
						id="newsletterCheckboxLabel"
						fullText={ `${ marketingAgreement.content } ${ marketingAgreement.additionalContent }` }
						text={ marketingAgreement.content }
						theme={ {
							collapseButton: styles.collapseButton,
						} }
					/>
				</Checkbox>
			) }

			<Button
				hasFormNoValidation
				isDisabled={ isDisabled }
				isLoading={ isLoading }
				text={ t('newsletter.saveButtonLabel') }
				type="submit"
				additionalClass={ buttonClass }
				dataTestId={ DATA_TESTID.CORE.BUTTON_SUBMIT_EMAIL }
			/>

			<RecaptchaLabel theme={ { wrapper: styles.recaptcha, link: styles.link } } />
		</form>
	)
}

export { NewsletterForm }
