import { withFresherToken } from '~/actions/auth'
import { deletePlannerProject, getPlannerProjects, patchPlannerProject, getPlannerProject, putPlannerProject, postPlannerProjects } from '~/api/requests/customerProject'
import { IPaginationRequestParams } from '~/api/dataTypes/pageable'
import { AppPromiseThunkAction } from '~/actions'
import { AccountProjectsType, DeleteCustomerPlannerProjectsType, GetCustomerPlannerProjectsType, GetCustomerPlannerProjectType, PatchCustomerPlannerProjectsType, PostCustomerPlannerProjectType, PutCustomerPlannerProjectType } from '~/actions/account'

// eslint-disable-next-line @typescript-eslint/typedef
export const getCustomerPlannerProjects = withFresherToken((params: IPaginationRequestParams): AppPromiseThunkAction<GetCustomerPlannerProjectsType> => async (dispatch) => {
	const { page } = params

	return dispatch({
		type: AccountProjectsType.GET_CUSTOMER_PLANNER_PROJECTS,
		payload: getPlannerProjects({
			...params,
			page: page - 1,
			includeProjectsFiles: false,
		}),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const deleteCustomerPlannerProject = withFresherToken((projectNumber: string): AppPromiseThunkAction<DeleteCustomerPlannerProjectsType> => async (dispatch) => {
	return dispatch({
		type: AccountProjectsType.DELETE_CUSTOMER_PLANNER_PROJECT,
		payload: deletePlannerProject(projectNumber),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const patchCustomerPlannerProject = withFresherToken((projectNumber: string): AppPromiseThunkAction<PatchCustomerPlannerProjectsType> => async (dispatch) => {
	return dispatch({
		type: AccountProjectsType.PATCH_CUSTOMER_PLANNER_PROJECT,
		payload: patchPlannerProject(projectNumber),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getCustomerPlannerProject = withFresherToken((projectNumber: string): AppPromiseThunkAction<GetCustomerPlannerProjectType> => async (dispatch) => {
	return dispatch({
		type: AccountProjectsType.GET_CUSTOMER_PLANNER_PROJECT,
		payload: getPlannerProject(projectNumber),
	})
})

// @data   ICustomerProjectData
// eslint-disable-next-line @typescript-eslint/typedef
export const putCustomerPlannerProject = withFresherToken((projectNumber: string, data: FormData): AppPromiseThunkAction<PutCustomerPlannerProjectType> => async (dispatch) => {
	return dispatch({
		type: AccountProjectsType.PUT_CUSTOMER_PLANNER_PROJECT,
		payload: putPlannerProject(projectNumber, data),
	})
})

// @data   ICustomerProjectData
// eslint-disable-next-line @typescript-eslint/typedef
export const postCustomerPlannerProject = withFresherToken((data: FormData): AppPromiseThunkAction<PostCustomerPlannerProjectType> => async (dispatch) => {
	return dispatch({
		type: AccountProjectsType.POST_CUSTOMER_PLANNER_PROJECT,
		payload: postPlannerProjects(data),
	})
})
