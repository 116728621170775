import React, { ReactElement, useContext, useCallback } from 'react'
import { useRouter } from 'next/router'
import { isNull, map } from 'lodash'

import { HamburgerMegaMenuContext } from '~/providers/hamburgerMegaMenuProvider'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { HamburgerMenuMegaMenuItem, IHamburgerMenuMegaMenuItemsProps } from '~/components/core/hamburgerMenu'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IProductCatalogData } from '~/api/dataTypes/catalog'

import styles from './HamburgerMenuMegaMenuItems.module.scss'

const HamburgerMenuMegaMenuItems = (props: IHamburgerMenuMegaMenuItemsProps): ReactElement => {
	const { onGetNodeData } = props
	const { activeMegaWorld, activeWorld, handleResetMegaMenu, handleSetActiveWorld } = useContext(HamburgerMegaMenuContext)
	const { handleSetOpen } = useContext(HamburgerMenuContext)
	const router = useRouter()

	const handleWorldClick = useCallback((nodeData: IProductCatalogData) => (): void => {
		handleSetActiveWorld(nodeData)
	}, [])

	const renderWorlds = (): ReactElement | null => {
		if (isNull(activeMegaWorld) || !isNull(activeWorld)) {
			return null
		}

		return (
			<>
				{ map(activeMegaWorld.childrenIds, (id: number) => {
					const nodeData = onGetNodeData(id)

					return (
						<HamburgerMenuMegaMenuItem
							key={ id }
							name={ nodeData.name }
							onClick={ handleWorldClick(nodeData) }
						/>
					)
				}) }
			</>
		)
	}

	const handleGoToProductList = useCallback((wwwUrl: string) => async (): Promise<void> => {
		handleSetOpen(false)
		handleResetMegaMenu()
		await router.push(wwwUrl)
	}, [])

	const renderLeafs = (): ReactElement | null => {
		if (isNull(activeWorld)) {
			return null
		}

		return (
			<>
				{ map(activeWorld.childrenIds, (id: number) => {
					const { wwwUrl, name } = onGetNodeData(id)

					return (
						<HamburgerMenuMegaMenuItem
							key={ id }
							isLeaf
							name={ name }
							onClick={ handleGoToProductList(wwwUrl) }
						/>
					)
				}) }
			</>
		)
	}

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.CORE.HAMBURGER_MENU_MEGA_MENU_LIST }>
			{ renderWorlds() }

			{ renderLeafs() }
		</div>
	)
}

export { HamburgerMenuMegaMenuItems }
