import React, { ReactElement } from 'react'

import { ICartPreviewDeliveryEcommerceRealizationProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryEcommerceRealization.module.scss'

const CartPreviewDeliveryEcommerceRealization = (props: ICartPreviewDeliveryEcommerceRealizationProps): ReactElement => {
	const { processingTime } = props

	return (
		<div className={ styles.wrapper }>
			{ processingTime }
		</div>
	)
}

export { CartPreviewDeliveryEcommerceRealization }
