import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { isEmpty } from 'lodash'

import { IAvailabilityInStoreButtonProps } from '~/components/core/availabilityInStoreButton'
import { Button } from '~/components/core/button'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './AvailabilityInStoreButton.module.scss'

const AvailabilityInStoreButton = (props: IAvailabilityInStoreButtonProps): ReactElement => {
	const {
		wwwUrl,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['common'])
	const router = useRouter()
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const buttonClass = classNames(styles.button, {
		[additionalClass]: !isEmpty(additionalClass),
		'mc-button--bordered-neutral': isKobiIntegrationEnabled,
	},)

	const handleClick = useCallback(async (): Promise<void> => {
		await router.push(wwwUrl)
	}, [wwwUrl, router])

	return (
		<Button
			isReverse={ !isKobiIntegrationEnabled }
			text={ t('checkAvailabilityInStore') }
			additionalClass={ buttonClass }
			variant="secondary"
			onClick={ handleClick }
		/>
	)
}

export { AvailabilityInStoreButton }
