import React, { ReactElement, useCallback, useContext, useEffect, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useToggle, useMountedState, useClickAway, useKeyPressEvent } from 'react-use'
import classNames from 'classnames'
import { isEmpty, isNil, isNull } from 'lodash'

import { IRootState } from '~/state/types'
import { useModal } from '~/hooks/modal'
import { usePreferredStore } from '~/hooks/preferredStore'
import { DATA_TESTID } from '~/utils/dataTestId'
import { HeaderStickyModal, HeaderTopBarPreferredStoreSelected, HeaderTopBarPreferredStoreNotSelected } from '~/components/core/layout'
import { PreferredStore, PreferredStoreModal } from '~/components/core/preferredStore'
import { UserContext } from '~/providers/userProvider'

import styles from './HeaderTopBarPreferredStore.module.scss'

const HeaderTopBarPreferredStore = (): ReactElement => {
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const [isModalVisible, setIsModalVisible] = useToggle(false)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const sectionRef = useRef<HTMLElement | null>(null)
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')
	const isMounted = useMountedState()
	const { setPreferredStore, clearPreferredStore } = usePreferredStore()
	const { isLogged } = useContext(UserContext)

	const handleHideModal = useCallback((): void => {
		setIsModalVisible(false)
	}, [])

	const handleToggleModal = useCallback((): void => {
		setIsModalVisible()
	}, [])

	const handleUseModal = useCallback((): void => {
		handleHideModal()
		handleOpen()
	}, [handleOpen])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.active]: isModalVisible,
	})

	useEffect(() => {
		(async () => {
			if (!isNil(preferredStore) && isEmpty(preferredStore.wwwUrl)) {
				await clearPreferredStore(isLogged)
				await setPreferredStore(preferredStore.storeCode, preferredStore.name, isLogged)
			}
		})()
	}, [isLogged])

	useClickAway(sectionRef, handleHideModal)
	useKeyPressEvent('Escape', handleHideModal)

	return (
		<section ref={ sectionRef } className={ styles.container }>
			<PreferredStore
				buttonRef={ buttonRef }
				color={ isModalVisible ? 'white' : 'navy' }
				additionalClass={ wrapperClass }
				dataTestId={ DATA_TESTID.HEADER.BUTTON_OPEN_PREFERRED_STORE }
				handleBlur={ handleHideModal }
				isModalVisible={ isModalVisible }
				onOpen={ handleToggleModal }
			>
				<HeaderStickyModal
					buttonRef={ buttonRef }
					isOpen={ isModalVisible }
					dataTestId={ DATA_TESTID.HEADER.MODAL_PREFERRED_STORE }
					onClose={ handleHideModal }
				>
					{ !isNull(preferredStore) && isMounted() ? (
						<HeaderTopBarPreferredStoreSelected
							preferredStore={ preferredStore }
							onChange={ handleUseModal }
							onNavigate={ handleHideModal }
						/>
					) : (
						<HeaderTopBarPreferredStoreNotSelected
							onClick={ handleUseModal }
							onBlur={ handleHideModal }
						/>
					) }

					<PreferredStoreModal
						canForceChange={ !isNull(preferredStore) }
						isOpen={ isOpen }
						onClose={ handleClose }
					/>
				</HeaderStickyModal>
			</PreferredStore>
		</section>
	)
}

export { HeaderTopBarPreferredStore }
