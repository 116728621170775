import React, { createContext, ReactElement, useMemo } from 'react'
import { noop } from 'lodash'

import { useModal } from '~/hooks/modal'
import { IHeaderTopBarSearchContextProps, IHeaderTopBarSearchProviderProps } from '~/providers/headerTopBarSearchProvider'

const initialProps: IHeaderTopBarSearchContextProps = {
	isOpenSearchModal: false,
	onOpenSearchModal: noop,
	onCloseSearchModal: noop,
}

export const HeaderTopBarSearchContext = createContext(initialProps)

export const HeaderTopBarSearchProvider = (props: IHeaderTopBarSearchProviderProps): ReactElement | null => {
	const { children } = props
	const { isOpen, handleOpen, handleClose } = useModal('SearchModal')

	const providerValue: IHeaderTopBarSearchContextProps = useMemo(() => ({
		isOpenSearchModal: isOpen,
		onOpenSearchModal: handleOpen,
		onCloseSearchModal: handleClose,
	}), [isOpen, handleOpen, handleClose])

	return (
		<HeaderTopBarSearchContext.Provider value={ providerValue }>
			{ children }
		</HeaderTopBarSearchContext.Provider>
	)
}
