import { AnyAction } from 'redux'
import produce, { Draft } from 'immer'

import { IComparisonState, ComparisonFailedType } from '~/state/reducers/comparisonReducer'
import { FAILURE, SUCCESS } from '~/statics'
import { ComparisonActionType } from '~/actions/comparison'

import { comparisonInitialState as initialState } from './constants'

export const comparison = produce((draft: Draft<IComparisonState>, action: AnyAction) => {
	switch (action.type) {
		case SUCCESS(ComparisonActionType.ADD_COMPARISON_ITEM):
		case SUCCESS(ComparisonActionType.REMOVE_COMPARISON_ITEM):
			draft.products = (action.payload as IComparisonState).products
			draft.comparisonCategory = (action.payload as IComparisonState).comparisonCategory

			return draft
		case FAILURE(ComparisonActionType.ADD_COMPARISON_ITEM):
			draft.failed = (action.payload as ComparisonFailedType)

			return draft
		case SUCCESS(ComparisonActionType.CLEAR_COMPARISON):
			draft.products = []

			return draft
		case SUCCESS(ComparisonActionType.CLEAR_ERRORS):
			draft.failed = null

			return draft
		default:
			return draft
	}
}, initialState)
