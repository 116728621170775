import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { CartPreviewDeliverySplitProducts, ICartPreviewDeliveryNotAvailableProps } from '~/components/cart/cartPreview'
import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './CartPreviewDeliveryNotAvailable.module.scss'

const CartPreviewDeliveryNotAvailable = (props: ICartPreviewDeliveryNotAvailableProps): ReactElement => {
	const { itemsNotAvailable, isOpen, onClose } = props
	const { t } = useTranslation(['cart', 'common'])

	return (
		<Modal
			isOpen={ isOpen }
			title={ t('preview.aside.summary.modal.notAvailableProducts.title') }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
		>
			<div className={ styles.content }>
				<div className={ styles.subtitle }>
					{ t('preview.aside.summary.modal.notAvailableProducts.subtitle') }
				</div>

				<CartPreviewDeliverySplitProducts
					type="missingProducts"
					items={ itemsNotAvailable }
				/>

				<div className={ styles.actions }>
					<Button
						variant="secondary"
						text={ t('ok', { ns: 'common' }) }
						dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_DELIVERY_MODAL_OK }
						additionalClass={ styles.button }
						onClick={ onClose }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { CartPreviewDeliveryNotAvailable }
