import React, { createContext, ReactElement } from 'react'

import { IInitialParamsProviderProps } from '~/providers/initialParamsProvider'
import { IInitialParamsData } from '~/mocks/initialParamsMock'

const initialProps: IInitialParamsData = {} as IInitialParamsData

export const InitialParamsContext = createContext(initialProps)

export const InitialParamsProvider = (props: IInitialParamsProviderProps): ReactElement => {
	const { children, value } = props

	return (
		<InitialParamsContext.Provider value={ value }>
			{ children }
		</InitialParamsContext.Provider>
	)
}
