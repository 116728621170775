import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { Image } from '~/components/core/image'
import { HeaderTopBarSearchBarProductInfoBlock, IHeaderTopBarSearchBarProductProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { usePrice } from '~/hooks/price'
import { getProductAriaLabelWithPrice } from '~/utils/productAria'

import styles from './HeaderTopBarSearchBarProduct.module.scss'

const HeaderTopBarSearchBarProduct = (props: IHeaderTopBarSearchBarProductProps): ReactElement => {
	const {
		product,
		dataTestId = '',
	} = props
	const { t } = useTranslation(['header'])
	const { priceFormat } = usePrice()
	const { GA_productClick } = useGoogleAnalytics()

	const { name, bigPrice: rawPrice, bigPriceUnit, promotional, imageThumbnailUrl, wwwUrl } = product

	const bigPrice = priceFormat(rawPrice)

	const handleProductClick = useCallback(() => {
		// todo after PLEDEV-6674
		// GA_productClick(product, position)
	}, [product])

	const ariaLabel = getProductAriaLabelWithPrice({
		promotional,
		bigPrice,
		name,
		bigPriceUnit,
		t,
	})

	return (
		<div className={ styles.wrapper } data-testid={ dataTestId }>
			<Link
				href={ wwwUrl }
				additionalClass={ styles.link }
				ariaLabel={ ariaLabel }
				onClick={ handleProductClick }
			>
				<span className={ styles.imageWrapper } aria-hidden="true">
					<Image
						src={ imageThumbnailUrl }
						alt={ name }
						width={ 60 }
						height={ 60 }
						loading="lazy"
						className={ styles.image }
					/>
				</span>

				<HeaderTopBarSearchBarProductInfoBlock
					name={ name }
					bigPrice={ bigPrice }
					bigPriceUnit={ bigPriceUnit }
					promotional={ promotional }
				/>
			</Link>

			{ /* PLEDEV-6674 */ }

			{ /* <AddToCartButton isReverse productSimple={ product } priceValue={ rawPrice } additionalClass={ styles.button } /> */ }
		</div>
	)
}

export { HeaderTopBarSearchBarProduct }
