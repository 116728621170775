import React, { ReactElement } from 'react'

import { CartPreviewDeliveryListWrapper, CartPreviewDeliveryPickupTransferCard, ICartPreviewDeliveryPickupTransferProps } from '~/components/cart/cartPreview'

const CartPreviewDeliveryPickupTransfer = (props: ICartPreviewDeliveryPickupTransferProps): ReactElement => {
	const { deliveryStoreData, deliveryStore, onChangeDelivery } = props

	return (
		<CartPreviewDeliveryListWrapper>
			<CartPreviewDeliveryPickupTransferCard
				item={ deliveryStoreData }
				deliveryStore={ deliveryStore }
				onChangeDelivery={ onChangeDelivery }
			/>
		</CartPreviewDeliveryListWrapper>
	)
}

export { CartPreviewDeliveryPickupTransfer }
