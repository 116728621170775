import React, { ChangeEvent, ReactElement, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { FieldValues, useController } from 'react-hook-form'
import { useClickAway, useList } from 'react-use'
import { filter, gt, includes, isEmpty, isFunction, map, size, toLower } from 'lodash'

import { ErrorBoundary, IDropdownInputProps, InputBase } from '~/components/core/form'

import styles from './Dropdown.module.scss'

const DropdownInput = <FormFields extends FieldValues = FieldValues>(props: IDropdownInputProps<FormFields>): ReactElement => {
	const {
		name, control, aside, options, onToggle,
		isDisabled = false,
		isRequired = false,
		isMenuOpen = false,
		prefix = '',
		label = '',
		tooltipLabel = '',
		placeholder = '',
		theme = {},
		dataTestId = '',
		onChange = undefined,
	} = props
	const { field, fieldState: { error, invalid } } = useController<FormFields>({ control, name })
	const [items, { set }] = useList<string>(options)
	const dropdownRef = useRef<HTMLDivElement | null>(null)

	const isValid = !invalid && !isEmpty(field.value)
	const isInvalid = invalid

	const handleOpenMenu = useCallback((): void => {
		onToggle(true)
	}, [])

	const handleCloseMenu = useCallback((): void => {
		onToggle(false)
	}, [])

	const handleClickItem = useCallback((value: string) => (): void => {
		field.onChange(value)

		if (isFunction(onChange)) {
			onChange(value)
		}

		handleCloseMenu()
	}, [field.onChange, onChange])

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
		field.onChange(event)

		if (isFunction(onChange)) {
			onChange(event.target.value)
		}

		set(filter(options, (option: string) => includes(toLower(option), toLower(event.target.value))))
	}, [field.onChange, onChange, options])

	const renderList = useCallback((): ReactElement[] => (
		map(items, (item: string) => (
			<li
				key={ item }
				aria-hidden
				className={ styles.item }
				onClick={ handleClickItem(item) }
			>
				{ item }
			</li>
		))
	), [items])

	useEffect(() => {
		set(options)
	}, [options])

	useEffect(() => {
		if (isMenuOpen) {
			handleOpenMenu()
		}
	}, [isMenuOpen])

	useClickAway(dropdownRef, handleCloseMenu)

	const showDropdown = isMenuOpen && !isEmpty(items) && gt(size(options), 1)

	return (
		<div className={ classNames(styles.wrapper, theme.wrapper) }>
			<div aria-hidden onClick={ handleOpenMenu } />

			<InputBase
				disabled={ isDisabled }
				isInvalid={ isInvalid }
				isRequired={ isRequired }
				isValid={ isValid }
				label={ label }
				tooltipLabel={ tooltipLabel }
				placeholder={ placeholder }
				prefix={ prefix }
				type="text"
				autoComplete="off"
				aside={ aside }
				dataTestId={ dataTestId }
				theme={ {
					input: theme.input,
					label: theme.label,
					prefix: theme.prefix,
					wrapper: styles.inputWrapper,
				} }
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...field }
				onChange={ handleChange }
				onWrapperClick={ handleOpenMenu }
			/>

			{ showDropdown && (
				<div ref={ dropdownRef } className={ styles.dropdown }>
					<ul className={ styles.items }>
						{ renderList() }
					</ul>
				</div>
			) }

			<ErrorBoundary error={ error } />
		</div>
	)
}

export { DropdownInput }
