import React, { ReactElement, useCallback, useRef } from 'react'
import classNames from 'classnames'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FieldValues, useController } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { isDate, isNil, isUndefined } from 'lodash'

import { ErrorBoundary, IDatePickerProps, DatePickerCustomInput, DatePickerCustomMaskedInput } from '~/components/core/form'
import { useDate } from '~/hooks/date'

import styles from './DatePicker.module.scss'

const DatePicker = <FormFields extends FieldValues = FieldValues>(props: IDatePickerProps<FormFields>): ReactElement => {
	const {
		name, control, label,
		mask = undefined,
		isRequired = false,
		theme = {},
	} = props
	const { t } = useTranslation(['form'])
	const { field, fieldState: { error, invalid }, formState: { isSubmitted } } = useController<FormFields>({ control, name })
	const { getDateFormatReactDatePicker } = useDate()
	const datePickerRef = useRef<ReactDatePicker>(null)
	const hasMask = !isUndefined(mask)

	const dateSelectedValue = isDate(field.value) ? field.value : null
	const isValid = isSubmitted && !invalid && !isNil(dateSelectedValue)
	const isInvalid = invalid

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	const handleCalendarClick = useCallback((): void => {
		datePickerRef.current?.setOpen(true)
	}, [datePickerRef])

	const renderCustomInput = useCallback((): ReactElement => {
		if (hasMask) {
			return (
				<DatePickerCustomMaskedInput
					mask={ mask }
					label={ label }
					isRequired={ isRequired }
					name={ field.name }
					isValid={ isValid }
					isInvalid={ isInvalid }
					onCalendarClick={ handleCalendarClick }
				/>
			)
		}

		return (
			<DatePickerCustomInput
				label={ label }
				isRequired={ isRequired }
				name={ field.name }
				isValid={ isValid }
				isInvalid={ isInvalid }
				onCalendarClick={ handleCalendarClick }
			/>
		)
	}, [label, field.name, isValid, isInvalid, mask])

	return (
		<div className={ wrapperClass }>
			<ReactDatePicker
				ref={ datePickerRef }
				fixedHeight
				preventOpenOnFocus
				strictParsing
				dateFormat={ getDateFormatReactDatePicker() }
				placeholderText={ t('datepicker.placeholder') }
				name={ field.name }
				selected={ dateSelectedValue }
				value={ field.value }
				customInput={ renderCustomInput() }
				onChange={ field.onChange }
				onBlur={ field.onBlur }
			/>

			<ErrorBoundary error={ error } />
		</div>
	)
}

export { DatePicker }
