import { ITokenResult } from '~/api/dataTypes/auth'
import { localStorageGet } from '~/utils/storage'
import { LS_KEY_ADEO_AUTH_USER, LS_KEY_AUTH_USER, LS_KEY_SHOULD_BE_LOGIN_BY_ADEO } from '~/utils/constants'

import { AdeoAuthDataType, IAuthState } from './types'

export const authInitialState: IAuthState = {
	isPending: false,
	data: localStorageGet<ITokenResult>(LS_KEY_AUTH_USER),
	adeo: localStorageGet<AdeoAuthDataType>(LS_KEY_ADEO_AUTH_USER),
	shouldLoginByAdeo: localStorageGet<boolean>(LS_KEY_SHOULD_BE_LOGIN_BY_ADEO) ?? false,
}
