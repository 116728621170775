import { SwiperOptions } from 'swiper'

import { vars } from '~/statics/variables'

const { gaps, breakpoints } = vars
const { productsCarousel: { mobile: gapsMobile } } = gaps
const { smallMobile, mobile, tablet, smallDesktop, desktop, largeDesktop } = breakpoints

export const PRODUCTS_CAROUSEL_PARAMS: SwiperOptions = {
	slidesPerView: 'auto',
	slidesPerGroup: 2,
	spaceBetween: gapsMobile,
	breakpoints: {
		[smallMobile]: {
			slidesPerView: 'auto',
			slidesPerGroup: 2,
			spaceBetween: gapsMobile,
		},
		[mobile]: {
			slidesPerView: 'auto',
			slidesPerGroup: 2,
			spaceBetween: gapsMobile,
		},
		[tablet]: {
			slidesPerView: 'auto',
			slidesPerGroup: 3,
			spaceBetween: 0,
		},
		[smallDesktop]: {
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 0,
		},
		[desktop]: {
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 0,
		},
		[largeDesktop]: {
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 0,
		},
	},
}
