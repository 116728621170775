import { Draft, produce } from 'immer'

import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { AppPromiseAction } from '~/actions'
import { AuthActionType, AuthAdeoActionType, AuthActionUnionType } from '~/actions/auth'

import { authInitialState as initialState } from './constants'
import { IAuthState } from './types'

export const auth = produce((draft: Draft<IAuthState>, action: AppPromiseAction<AuthActionUnionType>): IAuthState => {
	switch (action.type) {
		case REQUEST(AuthActionType.SET_AUTHENTICATE_TOKEN):
		case REQUEST(AuthActionType.SET_AUTHENTICATE_REFRESH_TOKEN):
		case REQUEST(AuthAdeoActionType.SET_ADEO_EXTRACT_TOKENS):
		case REQUEST(AuthAdeoActionType.SET_ADEO_REFRESH_TOKENS):
			draft.isPending = true

			return draft
		case SUCCESS(AuthActionType.SET_AUTHENTICATE_TOKEN):
		case SUCCESS(AuthActionType.SET_AUTHENTICATE_REFRESH_TOKEN):
			draft.isPending = false
			draft.data = action.payload.data

			return draft
		case SUCCESS(AuthAdeoActionType.SET_ADEO_EXTRACT_TOKENS):
			draft.isPending = false
			draft.adeo = action.payload.data

			return draft
		case SUCCESS(AuthAdeoActionType.SET_ADEO_REFRESH_TOKENS):
			draft.isPending = false
			draft.adeo = action.payload.data

			return draft
		case SUCCESS(AuthAdeoActionType.SET_LOGIN_BY_ADEO):
			draft.shouldLoginByAdeo = action.payload

			return draft
		case FAILURE(AuthActionType.SET_AUTHENTICATE_TOKEN):
		case FAILURE(AuthActionType.SET_AUTHENTICATE_REFRESH_TOKEN):
			draft.isPending = false
			draft.data = null

			return draft
		case FAILURE(AuthAdeoActionType.SET_ADEO_EXTRACT_TOKENS):
		case FAILURE(AuthAdeoActionType.SET_ADEO_REFRESH_TOKENS):
			draft.isPending = false
			draft.adeo = null

			return draft
		case SUCCESS(AuthActionType.SET_LOGOUT):
			draft.data = null

			return draft
		case SUCCESS(AuthAdeoActionType.SET_ADEO_LOGOUT):
			draft.adeo = null

			return draft
		default:
			return draft
	}
}, initialState)
