import React, { ReactElement, ElementType, Fragment } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { ILoaderProps } from '~/components/core/loader'

import styles from './Loader.module.scss'

const Loader = (props: ILoaderProps): ReactElement => {
	const {
		fullPage = false,
		withOpacity = false,
		label = '',
		theme = {},
		id = '',
	} = props

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	const fullPageLoaderClass = classNames(styles.fullPage, theme.overlay, {
		[styles.withOpacity]: withOpacity,
	})

	const loaderWrapperClass = classNames(styles.loaderWrapper, theme.loader)

	const LoaderComponent: ElementType = fullPage ? 'div' : Fragment

	return (
		<div className={ wrapperClass } id={ id }>
			{ !isEmpty(label) && (
				<span className={ styles.label }>
					{ label }
				</span>
			) }

			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<LoaderComponent { ...fullPage && { className: fullPageLoaderClass } }>
				<div className={ loaderWrapperClass }>
					<div className={ styles.loader } />
				</div>
			</LoaderComponent>
		</div>
	)
}

export { Loader }
