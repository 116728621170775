import React, { ReactElement, useCallback } from 'react'
import { isNull } from 'lodash'

import { CartCustomerDataEditForm, CartCustomerDataItem, ICartCustomerDataAddressItemProps } from '~/components/cart/cartCustomer'
import { useModal } from '~/hooks/modal'
import { useCountries } from '~/hooks/countries'

import styles from './CartCustomerDataAddressItem.module.scss'

const CartCustomerDataAddressItem = (props: ICartCustomerDataAddressItemProps): ReactElement => {
	const { isActive, onSelect, onAddressEdit, address, isCompany } = props
	const { countriesShortsMap } = useCountries()
	const { isOpen, handleOpen, handleClose } = useModal('CartCustomerDataEdit')

	const { city, country, flatNumber, houseNumber, postalCode, street, addressId } = address

	const handleOnSelect = useCallback((): void => {
		onSelect(addressId)
	}, [addressId, onSelect])

	return (
		<div className={ styles.wrapper }>
			<CartCustomerDataItem
				isCompany={ isCompany }
				id={ addressId }
				isActive={ isActive }
				onOpen={ handleOpen }
				onSelect={ handleOnSelect }
			>
				<span>
					{ `${ street } ${ houseNumber }${ !isNull(flatNumber) ? ` /${ flatNumber }` : ' ' }` }
				</span>

				<span>
					{ `${ postalCode }, ${ city }` }
				</span>

				<span>
					{ countriesShortsMap[country] }
				</span>
			</CartCustomerDataItem>

			<CartCustomerDataEditForm
				isOpen={ isOpen }
				address={ address }
				onOpen={ handleOpen }
				onClose={ handleClose }
				onEdit={ onAddressEdit }
			/>
		</div>
	)
}

export { CartCustomerDataAddressItem }
