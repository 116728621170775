import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isNull } from 'lodash'

import { ICartPreviewDeliveryPickupTimeTileProps } from '~/components/cart/cartPreview'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDeliveryPickupTimeTile.module.scss'

const CartPreviewDeliveryPickupTimeTile = (props: ICartPreviewDeliveryPickupTimeTileProps): ReactElement | null => {
	const { children, isChecked } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	if (isNull(children)) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.selected]: isChecked,
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			{ children }
		</div>
	)
}

export { CartPreviewDeliveryPickupTimeTile }
