import { withFresherToken } from '~/actions/auth'
import { AppPromiseThunkAction } from '~/actions'
import { GetServiceCartCustomerActionType, UpdateServiceCartCustomerDataActionType, UpdateServiceCartCustomerContactActionType, SetServiceCartCustomerYesInvoiceActionType, SetServiceCartCustomerNoInvoiceActionType, ServiceCartCustomerType } from '~/actions/serviceCartCustomer'
import { getCalendarCartCustomer } from '~/api/requests/calendarService'
import { ICalendarCartCustomerResponse } from '~/api/dataTypes/calendarService'

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartCustomerData = withFresherToken((): AppPromiseThunkAction<GetServiceCartCustomerActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartCustomerType.GET_SERVICE_CART_CUSTOMER,
		payload: getCalendarCartCustomer(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const updateServiceCartCustomerData = (data: Pick<ICalendarCartCustomerResponse, 'firstName' | 'lastName' | 'email' | 'companyName' | 'address'>): AppPromiseThunkAction<UpdateServiceCartCustomerDataActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartCustomerType.UPDATE_SERVICE_CART_CUSTOMER_DATA,
		payload: Promise.resolve(data),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const updateServiceCartCustomerContact = (data: Pick<ICalendarCartCustomerResponse, 'phone'>): AppPromiseThunkAction<UpdateServiceCartCustomerContactActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartCustomerType.UPDATE_SERVICE_CART_CUSTOMER_CONTACT,
		payload: Promise.resolve(data),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartCustomerYesInvoice = (nip: string): AppPromiseThunkAction<SetServiceCartCustomerYesInvoiceActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartCustomerType.SET_SERVICE_CART_CUSTOMER_YES_INVOICE,
		payload: Promise.resolve(nip),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartCustomerNoInvoice = (): AppPromiseThunkAction<SetServiceCartCustomerNoInvoiceActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartCustomerType.SET_SERVICE_CART_CUSTOMER_NO_INVOICE,
		payload: Promise.resolve(),
	})
}
