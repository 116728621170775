import { AxiosResponse } from 'axios'

import { INewsletterRequest, INewsletterResponse } from '~/api/dataTypes/newsletter'
import { request } from '~/api/requests/axios'

export const postNewsletterSubscribe = async (params: INewsletterRequest, reCaptchaToken: string): Promise<AxiosResponse<INewsletterResponse>> => (
	await request.post('/v1/newsletter/subscription', null, {
		params,
		service: 'AUTHLESS_WEB_INTERACTION_SERVICE',
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
	})
)
