import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { SwiperOptions } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { isEmpty, isEqual, isNull, map } from 'lodash'

import { AppDispatch } from '~/state/store'
import { IRootState } from '~/state/types'
import { CartCustomerDataAddAddressButton, CartCustomerDataAddressItem, CartCustomerDataEmpty, CartCustomerDataLoader, CartCustomerDataMainItem, CartCustomerLoader } from '~/components/cart/cartCustomer'
import { CustomerType, ICustomerAddressData } from '~/api/dataTypes/customer'
import { Carousel } from '~/components/core/carousel'
import { SectionHeader } from '~/components/core/sectionHeader'
import { useCustomerUpdate } from '~/hooks/customerUpdate'
import { useAddressesUpdate } from '~/hooks/addressesUpdate'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { setSelectedAddressId } from '~/actions/cartCustomer'
import { getIsCompanyCustomer } from '~/state/reducers/customerReducer'

import styles from './CartCustomerData.module.scss'

const CartCustomerData = (): ReactElement => {
	const { data: userData } = useSelector((state: IRootState) => state.customer, shallowEqual)
	const { addressId: selectedId } = useSelector((state: IRootState) => state.cartCustomer, shallowEqual)
	const isCompany = useSelector(getIsCompanyCustomer, shallowEqual)
	const { t } = useTranslation(['cart'])
	const { isTablet } = useMediaQuery()
	const { onAddressAction, isUpdating: isAddressUpdating } = useAddressesUpdate()
	const { onCustomerUpdate, isUpdating: isCustomerUpdating } = useCustomerUpdate()
	const dispatch: AppDispatch = useDispatch()

	const isUpdating = isAddressUpdating || isCustomerUpdating
	const isIncorrectNipPrefix = isCompany && !isEqual(userData?.customer.nipPrefix, 'PL')

	const params: SwiperOptions = {
		spaceBetween: isTablet ? 32 : 16,
		slidesPerView: 'auto',
		slidesPerGroup: 1,
	}

	const handleOnSelect = useCallback(async (id: number): Promise<void> => {
		await dispatch(setSelectedAddressId(id))
	}, [])

	const renderContent = useCallback((): ReactElement | ReactElement[] => {
		if (isNull(userData)) {
			return (
				<CartCustomerDataLoader />
			)
		}

		if (isEmpty(userData.addresses) || isEmpty(userData?.customer?.mainAddress?.houseNumber) || isIncorrectNipPrefix) {
			const { customer, customerType } = userData

			return (
				<SwiperSlide className={ styles.slide }>
					<CartCustomerDataEmpty
						customerType={ customerType }
						customer={ customer }
						isIncorrectNipPrefix={ isIncorrectNipPrefix }
						onCustomerUpdate={ onCustomerUpdate }
					/>
				</SwiperSlide>
			)
		}

		return (
			map(userData.addresses, (address: ICustomerAddressData, index: number): ReactElement => {
				const { addressId } = address
				const isActive = isEqual(addressId, selectedId)

				if (isEqual(index, 0)) {
					const { customer, customerType } = userData
					const swiperClass = classNames(styles.slide, styles.main)

					return (
						<SwiperSlide key={ addressId } className={ swiperClass }>
							<CartCustomerDataMainItem
								isCompany={ isCompany }
								isActive={ isActive }
								address={ address }
								customer={ customer }
								customerType={ customerType }
								onSelect={ handleOnSelect }
								onAddressEdit={ onAddressAction }
							/>
						</SwiperSlide>
					)
				}

				return (
					<SwiperSlide key={ addressId } className={ styles.slide }>
						<CartCustomerDataAddressItem
							isCompany={ isCompany }
							isActive={ isActive }
							address={ address }
							onSelect={ handleOnSelect }
							onAddressEdit={ onAddressAction }
						/>
					</SwiperSlide>
				)
			})
		)
	}, [userData, selectedId, isCompany, isIncorrectNipPrefix, onAddressAction])

	return (
		<section className={ styles.wrapper }>
			<SectionHeader additionalClass={ styles.header } title={ t('customer.clientData.title') } />

			<div className={ styles.content }>
				<Carousel
					isWithoutGradient
					isWithoutNavigation
					additionalClass={ styles.carousel }
					params={ params }
					title={ t('customer.clientData.title') }
				>
					{ renderContent() }
				</Carousel>

				<CartCustomerLoader isLoading={ isUpdating } />
			</div>

			<CartCustomerDataAddAddressButton onAddNewAddress={ onAddressAction } />
		</section>
	)
}

export { CartCustomerData }
