import React, { ReactElement, useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import classNames from 'classnames'
import { map, isEmpty } from 'lodash'

import { Breadcrumb, BreadcrumbHome, BreadcrumbItem, IBreadcrumbsItemsProps } from '~/components/core/breadcrumbs'
import { IBreadcrumb } from '~/api/dataTypes/breadcrumb'
import { useMozaic } from '~/hooks/mozaic'

import styles from './BreadcrumbsItems.module.scss'

const BreadcrumbsItems = (props: IBreadcrumbsItemsProps): ReactElement => {
	const { breadcrumbs, name } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const isSimpleBreadcrumbs = !isEmpty(name) && isEmpty(breadcrumbs)

	const renderCarousel = useCallback((): ReactElement => {
		const startingPosition = isSimpleBreadcrumbs ? 3 : 2

		const breadcrumbClass = classNames(styles.breadcrumb, {
			'mc-breadcrumb__item': shouldUseMozaic,
		})

		return (
			<>
				<SwiperSlide
					itemScope
					itemProp="itemListElement"
					itemType="https://schema.org/ListItem"
					className={ breadcrumbClass }
					tag="li"
				>
					<BreadcrumbHome />
				</SwiperSlide>

				{ isSimpleBreadcrumbs && (
					<SwiperSlide
						itemScope
						itemProp="itemListElement"
						itemType="https://schema.org/ListItem"
						className={ breadcrumbClass }
						tag="li"
					>
						<BreadcrumbItem
							isLast
							label={ name }
							position="2"
						/>
					</SwiperSlide>
				) }

				{ map(breadcrumbs, (breadcrumb: IBreadcrumb, index: number) => {
					const { label } = breadcrumb

					const position = startingPosition + index
					const isLast = index === breadcrumbs.length - 1

					return (
						<SwiperSlide
							key={ label }
							itemScope
							itemProp="itemListElement"
							itemType="https://schema.org/ListItem"
							className={ breadcrumbClass }
							tag="li"
						>
							<Breadcrumb
								breadcrumb={ breadcrumb }
								position={ String(position) }
								isLast={ isLast }
							/>
						</SwiperSlide>
					)
				}) }
			</>
		)
	}, [breadcrumbs])

	return (
		<Swiper
			itemScope
			slidesPerView="auto"
			wrapperTag="ul"
			tag="nav"
			itemType="https://schema.org/BreadcrumbList"
		>
			{ renderCarousel() }
		</Swiper>
	)
}

export { BreadcrumbsItems }
