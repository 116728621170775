import React, { ReactElement } from 'react'

import { ICartColumnWrapperProps } from '~/components/core/cartColumnWrapper'

import styles from './CartColumnWrapper.module.scss'

const CartColumnWrapper = (props: ICartColumnWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { CartColumnWrapper }
