import React, { ReactElement } from 'react'
import { isNull } from 'lodash'

import { ICartPreviewSummaryProps, CartPreviewSummaryFreeDelivery, CartPreviewSummaryBlock, CartPreviewSummaryDiscount, CartPreviewSummaryInfo } from '~/components/cart/cartPreview'
import { LysLoyaltyProvider } from '~/providers/lysLoyaltyProvider'

import styles from './CartPreviewSummary.module.scss'

const CartPreviewSummary = (props: ICartPreviewSummaryProps): ReactElement => {
	const { cart, discountCounter, isStepZero, shouldHidePromoCodeForNonLoyaltyLoggedUsers } = props

	const { paymentSummaryValue } = cart

	return (
		<div className={ styles.wrapper }>
			{ !isNull(discountCounter) && !isStepZero && (
				<CartPreviewSummaryFreeDelivery summaryPrice={ paymentSummaryValue } missingValueForDiscount={ discountCounter.missingValueForDiscount } />
			) }

			<CartPreviewSummaryBlock cart={ cart } />

			<LysLoyaltyProvider>
				<CartPreviewSummaryDiscount isStepZero={ isStepZero } shouldHidePromoCodeForNonLoyaltyLoggedUsers={ shouldHidePromoCodeForNonLoyaltyLoggedUsers } />
			</LysLoyaltyProvider>

			{ !isStepZero && (
				<CartPreviewSummaryInfo />
			) }
		</div>
	)
}

export { CartPreviewSummary }
