import React, { ReactElement, useState, useCallback, useEffect, useRef } from 'react'
import { GoogleMap, useLoadScript, MarkerF, MarkerClustererF } from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { map, isUndefined, isFunction, isNull, isNil } from 'lodash'

import { vars } from '~/statics'
import { Loader } from '~/components/core/loader'
import { GoogleMapsMarkerInfoWindow, GoogleMapsMarkerLocation, IGoogleMapsProps, GoogleMapsStateType, IGoogleMapsMarker } from '~/components/core/googleMaps'
import { Alert } from '~/components/core/notifications'

import styles from './GoogleMaps.module.scss'

const GoogleMaps = (props: IGoogleMapsProps): ReactElement => {
	const {
		id, initCenter, initZoom, options, markers, activeMarkerWindow, onCenterChanged, onZoomChanged, onMarkerLoad, onLoad,
		language = 'pl',
		theme = {},
		isClickableIcons = true,
		currentLocation = null,
		hasCluster = false,
		shouldCenterOnMarkerClick = false,
		minimumClusterSize = 3,
	} = props
	const { t } = useTranslation(['googleMaps'])
	const [selectedMarker, setSelectedMarker] = useState<IGoogleMapsMarker | null>(null)
	const mapInstance = useRef<GoogleMapsStateType | null>(null)
	const { isLoaded, loadError } = useLoadScript({
		id,
		language,
		googleMapsApiKey: vars.google.mapApiKey || '',
	})

	const handleOnLoad = useCallback((map: GoogleMapsStateType) => {
		if (isFunction(onLoad)) {
			onLoad(map)
		}

		mapInstance.current = map
	}, [onLoad])

	const handleMarkerLoad = useCallback((marker: IGoogleMapsMarker) => {
		if (isFunction(onMarkerLoad)) {
			onMarkerLoad(marker)
		}
	}, [onMarkerLoad])

	const onMarkerClick = useCallback((marker: IGoogleMapsMarker) => (): void => {
		setSelectedMarker(marker)

		if (shouldCenterOnMarkerClick) {
			mapInstance.current?.setZoom(18)
			mapInstance.current?.setCenter(marker.position)
		}
	}, [shouldCenterOnMarkerClick])

	const handleCloseInfoWindow = useCallback((): void => {
		setSelectedMarker(null)
	}, [])

	const renderMarkers = useCallback((clusterer?: Clusterer): ReactElement[] | null => {
		if (isUndefined(markers)) {
			return null
		}

		return map(markers, (marker: IGoogleMapsMarker, index: number) => {
			const { active, icon, activeIcon, infoWindow, type, zIndex, id, position, title } = marker

			switch (type) {
				case 'markerInfoWindow':
					return (
						<GoogleMapsMarkerInfoWindow
							key={ index }
							marker={ marker }
							infoWindow={ infoWindow }
							icon={ active ? activeIcon : icon }
							isInfoWindowOpen={ selectedMarker?.id === id }
							clusterer={ clusterer }
							onLoad={ handleMarkerLoad }
							onCloseInfoWindow={ handleCloseInfoWindow }
							onClick={ onMarkerClick(marker) }
						/>
					)
				case 'marker':
				default:
					return (
						<MarkerF
							key={ index }
							position={ position }
							icon={ icon }
							title={ title }
							zIndex={ zIndex }
							clusterer={ clusterer as Clusterer }
							onClick={ onMarkerClick(marker) }
						/>
					)
			}
		})
	}, [markers, selectedMarker])

	const renderContent = useCallback((): (ReactElement | ReactElement[]) | null => {
		if (hasCluster) {
			return (
				<MarkerClustererF minimumClusterSize={ minimumClusterSize }>
					{ /* @ts-expect-error: "render prop" is not fully compatible */ }

					{ (clusterer: Clusterer) => renderMarkers(clusterer) }
				</MarkerClustererF>
			)
		}

		return renderMarkers()
	}, [hasCluster, markers, selectedMarker])

	useEffect(() => {
		if (!isNil(activeMarkerWindow)) {
			setSelectedMarker(activeMarkerWindow)
			mapInstance.current?.setCenter(activeMarkerWindow.position)
		}
	}, [activeMarkerWindow])

	const renderLocationPoint = useCallback((): ReactElement | null => {
		if (isNull(currentLocation)) {
			return null
		}

		return (
			<GoogleMapsMarkerLocation position={ currentLocation } />
		)
	}, [currentLocation])

	const renderMap = (): ReactElement => {
		const mapContainerClass = classNames(styles.wrapper, theme.wrapper)

		if (loadError) {
			return (
				<Alert
					icon="warning"
					type="warning"
					canClose={ false }
				>
					{ t('cannotLoadMapRightNow') }
				</Alert>
			)
		}

		return (
			<GoogleMap
				mapContainerClassName={ mapContainerClass }
				center={ initCenter }
				zoom={ initZoom }
				options={ options }
				clickableIcons={ isClickableIcons }
				onLoad={ handleOnLoad }
				onCenterChanged={ onCenterChanged }
				onZoomChanged={ onZoomChanged }
			>
				{ renderContent() }

				{ renderLocationPoint() }
			</GoogleMap>
		)
	}

	if (isLoaded) {
		return renderMap()
	}

	return (
		<Loader />
	)
}

export { GoogleMaps }
