import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { CartPreviewSection } from '~/components/cart/cartPreview'
import { Link } from '~/components/core/link'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewEmpty.module.scss'

const CartPreviewEmpty = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled }, urls } = useContext(AppParametersContext)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const linkClass = shouldUseMozaic ? 'mc-button' : styles.link

	const renderLink = useCallback((): ReactElement => {
		return (
			<Link
				href="/"
				ariaLabel={ t('preview.empty.action') }
				additionalClass={ linkClass }
			>
				{ t('preview.empty.action') }
			</Link>
		)

	}, [isKobiIntegrationEnabled, urls])

	return (
		<div className={ styles.wrapper }>
			<CartPreviewSection title={ t('preview.empty.title') } additionalClass={ styles.header }>
				<div className={ styles.info }>
					{ t('preview.empty.info') }
				</div>

				{ renderLink() }
			</CartPreviewSection>
		</div>
	)
}

export { CartPreviewEmpty }
