import React, { ReactElement } from 'react'
import { map, isNull, isEqual, size } from 'lodash'

import { MegaMenuMegaWorldsItem, IMegaMenuMegaWorldsProps } from '~/components/core/megamenu'
import { IProductCatalogData } from '~/api/dataTypes/catalog'

import styles from './MegaMenuMegaWorlds.module.scss'

const MegaMenuMegaWorlds = (props: IMegaMenuMegaWorldsProps): ReactElement => {
	const { active, onSetActive, onClose, items } = props

	const renderMegaWorlds = (): ReactElement[] => (
		map(items, (megaWorld: IProductCatalogData, index: number) => {
			const { id } = megaWorld
			const isActive = !isNull(active) && isEqual(id, active.id)
			const activeClientSideIndex = isActive ? index : -1

			return (
				<MegaMenuMegaWorldsItem
					key={ id }
					item={ megaWorld }
					isActive={ isActive }
					activeClientSideIndex={ activeClientSideIndex }
					isFirstElement={ isEqual(index, 0) }
					isLastElement={ isEqual(index, size(items)-1) }
					onSetActive={ onSetActive }
					onClose={ onClose }
				/>
			)
		})
	)

	return (
		<ul className={ styles.wrapper }>
			{ renderMegaWorlds() }
		</ul>
	)
}

export { MegaMenuMegaWorlds }
