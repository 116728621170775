import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isUndefined } from 'lodash'

import { Button } from '~/components/core/button'
import { ICartCustomerOrderCollectionModalFormActionsProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerOrderCollectionModalFormActions.module.scss'

const CartCustomerOrderCollectionModalFormActions = (props: ICartCustomerOrderCollectionModalFormActionsProps): ReactElement => {
	const { onCancel, onSubmit } = props
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			<Button
				type={ isUndefined(onSubmit) ? 'submit' : 'button' }
				variant="secondary"
				text={ t('customer.orderCollection.form.saveChanges') }
				additionalClass={ styles.button }
				onClick={ onSubmit }
			/>

			<Button
				isReverse
				variant="secondary"
				text={ t('customer.orderCollection.form.cancel') }
				additionalClass={ styles.button }
				onClick={ onCancel }
			/>
		</div>
	)
}

export { CartCustomerOrderCollectionModalFormActions }
