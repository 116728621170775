import React, { ReactElement } from 'react'
import NextImage from 'next/image'
import { isEmpty, isNil } from 'lodash'

import { IImageProps } from '~/components/core/image'

const Image = (props: IImageProps): ReactElement | null => {
	const { src } = props

	if (isNil(src) || isEmpty(src)) {
		return null
	}

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<NextImage { ...props } src={ src } />
	)
}

export { Image }
