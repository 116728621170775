import React, { ReactElement } from 'react'

import { useMainMenu } from '~/hooks/mainMenu'
import { SKIP_MAIN_MENU } from '~/hooks/skipElements'
import { DATA_TESTID } from '~/utils/dataTestId'
import { HeaderMainMenuItems, HeaderMainMenuProducts, IHeaderMainMenuProps } from '~/components/core/layout'
import { SkipContentAnchor } from '~/components/core/skipContent'

import styles from './HeaderMainMenu.module.scss'

const HeaderMainMenu = (props: IHeaderMainMenuProps): ReactElement => {
	const { pageType } = props
	const { firstSection, secondSection } = useMainMenu(pageType)

	return (
		<ul
			className={ styles.wrapper }
			data-testid={ DATA_TESTID.HEADER.MAIN_MENU }
			role="navigation"
		>
			<SkipContentAnchor elementId={ SKIP_MAIN_MENU } />

			<HeaderMainMenuProducts />

			<div className={ styles.menuItems }>
				<ul className={ styles.firstSection }>
					<HeaderMainMenuItems items={ firstSection } />
				</ul>

				<ul className={ styles.secondSection }>
					<HeaderMainMenuItems items={ secondSection } />
				</ul>
			</div>
		</ul>
	)
}

export { HeaderMainMenu }
