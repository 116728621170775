import { AxiosResponse } from 'axios'

import { IAuthenticateRefreshTokenRequest, IAuthenticateTokenRequest, ITokenResult } from '~/api/dataTypes/auth'
import { request } from '~/api/requests/axios'

export const postAuthenticateToken = async (params: IAuthenticateTokenRequest | IAuthenticateRefreshTokenRequest): Promise<AxiosResponse<ITokenResult>> => (
	await request.post('/v2/authenticate/token', params, {
		service: 'AUTH_SERVICE',
	})
)

export const postAuthenticateRefreshToken = async (params: IAuthenticateTokenRequest | IAuthenticateRefreshTokenRequest): Promise<AxiosResponse<ITokenResult>> => (
	await request.post('/v2/authenticate/token', params, {
		service: 'AUTH_SERVICE',
	})
)
