import React, { ReactElement, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { isEmpty, isEqual, replace, size } from 'lodash'

import { CartCustomerDataFormFieldWrapper, ICartCustomerDataAddressForm } from '~/components/cart/cartCustomer'
import { useHogaDictionary } from '~/hooks/hogaDictionary'
import { useCountries } from '~/hooks/countries'
import { DropdownInput, Input, Select } from '~/components/core/form'
import { validation } from '~/utils/validation'

import styles from './CartCustomerDataAddressFormFields.module.scss'

const CartCustomerDataAddressFormFields = (): ReactElement => {
	const { control, setValue, watch } = useFormContext<ICartCustomerDataAddressForm>()
	const { t } = useTranslation(['cart'])
	const { countriesSelectOptions } = useCountries()
	const { isDropdownOpen, list, getHogaDictionary, onOpenDropdown, onToggleDropdown } = useHogaDictionary()

	const { postalCode } = watch()
	const { mask: { zipCode } } = validation

	const handleChangePostalCode = useCallback(async (): Promise<void> => {
		await getHogaDictionary(postalCode ?? '')
	}, [postalCode])

	const handleChangeSelectList = useCallback((): void => {
		if (isEqual(size(list), 1)) {
			setValue('city', list[0])
		} else if (!isEmpty(list)) {
			onOpenDropdown()
		}
	}, [list])

	const handleChangeStreetName = useCallback((value: string): void => {
		setValue('street', replace(value, /\s\s+/g, ''))
	}, [])

	useEffect(() => {
		(async () => {
			await handleChangePostalCode()
		})()
	}, [postalCode])

	useEffect(() => {
		handleChangeSelectList()
	}, [list])

	return (
		<div className={ styles.wrapper }>
			<CartCustomerDataFormFieldWrapper>
				<Input
					isRequired
					name="street"
					label={ t('customer.clientData.form.street') }
					control={ control }
					additionalClass={ styles.control }
					onChange={ handleChangeStreetName }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper additionalClass={ styles.addressNumbersControls }>
				<Input
					isRequired
					name="houseNumber"
					label={ t('customer.clientData.form.houseNumber') }
					control={ control }
					additionalClass={ styles.control }
				/>

				<Input
					name="flatNumber"
					label={ t('customer.clientData.form.flatNumber') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper additionalClass={ styles.cityControls }>
				<Input
					isRequired
					name="postalCode"
					label={ t('customer.clientData.form.zipCode') }
					control={ control }
					mask={ zipCode }
					additionalClass={ styles.control }
				/>

				<DropdownInput
					isRequired
					isMenuOpen={ isDropdownOpen }
					control={ control }
					name="city"
					label={ t('customer.clientData.form.city') }
					options={ list }
					theme={ { wrapper: styles.control } }
					onToggle={ onToggleDropdown }
				/>
			</CartCustomerDataFormFieldWrapper>

			<CartCustomerDataFormFieldWrapper>
				<Select
					isRequired
					isDisabled
					instanceId="delivery-address-country"
					options={ countriesSelectOptions }
					name="country"
					label={ t('customer.clientData.form.country') }
					placeholder={ t('customer.clientData.form.countryPlaceholder') }
					control={ control }
					additionalClass={ styles.control }
				/>
			</CartCustomerDataFormFieldWrapper>
		</div>
	)
}

export { CartCustomerDataAddressFormFields }
