import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { isEqual, debounce, noop } from 'lodash'

import { Input } from '~/components/core/form'
import { CartCustomerGuestFormLoginSuggest, ICartCustomerGuestFormEmailForm, ICartCustomerGuestFormEmailProps } from '~/components/cart/cartCustomer'
import { setCartCustomerGuestEmail } from '~/actions/cartCustomer'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { ICustomerLoginExistenceStatusType } from '~/api/dataTypes/account'
import { getCustomerLoginExistence } from '~/api/requests/account'
import { validation } from '~/utils/validation'

const CartCustomerGuestFormEmail = (props: ICartCustomerGuestFormEmailProps): ReactElement => {
	const {
		setCompanyValue = noop,
		setPrivateValue = noop,
	} = props
	const { customer: { email } } = useSelector((state: IRootState) => state.cartCustomer.data, shallowEqual)
	const { t } = useTranslation(['cart', 'form'])
	const dispatch: AppDispatch = useDispatch()
	const [isLoginSuggestVisible, setIsLoginSuggestVisible] = useToggle(false)

	const schema: yup.SchemaOf<ICartCustomerGuestFormEmailForm> = useMemo(() => yup.object().shape({
		email: yup.string().when('.', {
			is: (val: string) => !(val.includes('@')),
			then: (schema: yup.StringSchema) => schema.email(t('validation.emailMustContainAtSign', { ns: 'form' })),
			otherwise: (schema: yup.StringSchema) => schema.email(t('validation.emailFormat', { ns: 'form' })),
		}).required(t('required', { ns: 'form' })),
	}), [])

	const { control, watch } = useForm<ICartCustomerGuestFormEmailForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			email: email || '',
		},
		mode: 'onChange',
	})

	const handleOnChange = useCallback(debounce(async (): Promise<void> => {
		const { email } = watch() // TODO: Refactor to use react-hook-form's watch outside of useCallback
		const isValidEmail = validation.regex.email.test(email)

		setCompanyValue('email', email)
		setPrivateValue('email', email)

		if (isValidEmail) {
			await dispatch(setCartCustomerGuestEmail(email))

			const data = await getCustomerLoginExistence(email)

			const { data: { ecommerceLoginStatus } } = data

			if (isEqual(ecommerceLoginStatus, 'TAKEN' as ICustomerLoginExistenceStatusType)) {
				setIsLoginSuggestVisible(true)

				return
			}
		}

		setIsLoginSuggestVisible(false)
	}, 700), [control, setCompanyValue, setPrivateValue])

	useEffect(() => {
		handleOnChange()
	}, [])

	return (
		<div>
			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.emailLabel') }
				name="email"
				onChange={ handleOnChange }
			/>

			{ isLoginSuggestVisible && <CartCustomerGuestFormLoginSuggest /> }
		</div>
	)
}

export { CartCustomerGuestFormEmail }
