import React, { ReactElement, useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isNull } from 'lodash'

import { IPickupPointData } from '~/api/dataTypes/pickupPoint'
import { AppDispatch } from '~/state/store'
import { IRootState } from '~/state/types'
import { setCartDeliveryTransportPickupPoint } from '~/actions/cart'
import { usePrice } from '~/hooks/price'
import { RadioIcon } from '~/components/core/form'
import { ICartPreviewDeliveryPickupTransferCategoryProps, CartPreviewDeliveryPickupTransferTimeslots, CartPreviewDeliveryPickupTransferMap } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryPickupTransferCategory.module.scss'

const CartPreviewDeliveryPickupTransferCategory = (props: ICartPreviewDeliveryPickupTransferCategoryProps): ReactElement => {
	const { category, error, isChecked, zipCode, onChangeDelivery } = props
	const { pickupPoint } = useSelector((state: IRootState) => state.cartPreview.data.cart, shallowEqual)
	const [pickupPointId, setPickupPointId] = useState<number | null>(!isNull(pickupPoint) ? pickupPoint.id : null)
	const { t } = useTranslation(['cart'])
	const { priceFormat } = usePrice()
	const dispatch: AppDispatch = useDispatch()

	const { name, price, code, pickupPointType } = category

	const showMap = !isNull(pickupPointType)

	const handleSelectPoint = useCallback(async (point: IPickupPointData): Promise<void> => {
		await dispatch(setCartDeliveryTransportPickupPoint({
			category: category.code,
			zipCode,
			pickupPointId: point.id,
		}))
		onChangeDelivery(point.id)
		setPickupPointId(point.id)
	}, [category, zipCode, onChangeDelivery])

	const renderContent = useCallback((): ReactElement | null => {
		if (!isChecked) {
			return null
		}

		if (showMap) {
			return (
				<CartPreviewDeliveryPickupTransferMap
					pickupPointType={ pickupPointType }
					selectedPointId={ pickupPointId }
					zipCode={ zipCode }
					onSelect={ handleSelectPoint }
				/>
			)
		}

		return (
			<CartPreviewDeliveryPickupTransferTimeslots categoryCode={ code } onChangeDelivery={ onChangeDelivery } />
		)
	}, [isChecked, showMap, code, onChangeDelivery, zipCode, pickupPointType, pickupPointId])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.withMap]: showMap,
	})

	return (
		<div className={ wrapperClass }>
			<div className={ styles.radio }>
				<RadioIcon isChecked={ isChecked } error={ error } />

				<div className={ styles.content }>
					<div>
						{ name }
					</div>

					<div className={ styles.charge }>
						<span>
							{ t('preview.delivery.groups.storeDelivery.charge') }
						</span>

						<span className={ styles.price }>
							{ priceFormat(price) }
						</span>
					</div>
				</div>
			</div>

			{ renderContent() }
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferCategory }
