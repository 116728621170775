import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { gt } from 'lodash'

import { usePrice } from '~/hooks/price'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IHeaderTopBarMiniCartModalActionsProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { getCartSimpleData } from '~/actions/cart'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'

import styles from './HeaderTopBarMiniCartModalActions.module.scss'

const HeaderTopBarMiniCartModalActions = (props: IHeaderTopBarMiniCartModalActionsProps): ReactElement => {
	const { summaryCost, productRows, onGoToCart, onBlur } = props
	const { isPending } = useSelector((state: IRootState) => state.miniCart, shallowEqual)
	const { t } = useTranslation(['common'])
	const dispatch: AppDispatch = useDispatch()
	const { priceFormat } = usePrice()

	const handleRefresh = useCallback(async (): Promise<void> => {
		await dispatch(getCartSimpleData())
	}, [])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.scrollable]: gt(productRows, 2),
	})

	const iconClass = classNames(styles.icon, {
		[styles.loading]: isPending,
	})

	return (
		<div className={ wrapperClass }>
			<div className={ styles.summary }>
				<span>
					{ t('sum') }
				</span>

				<span className={ styles.cost } data-testid={ DATA_TESTID.HEADER.MINI_CART_SUMMARY_COST }>
					{ priceFormat(summaryCost) }
				</span>
			</div>

			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.refreshButton }
				dataTestId={ DATA_TESTID.HEADER.BUTTON_REFRESH_MINI_CART }
				ariaLabel={ t('refresh') }
				onClick={ handleRefresh }
			>
				<Icon
					name="refresh"
					color="navy"
					width={ 10 }
					height={ 10 }
					additionalClass={ iconClass }
				/>

				<span>
					{ t('refresh') }
				</span>
			</Button>

			<Button
				text={ t('goToCart') }
				additionalClass={ styles.button }
				size="small"
				dataTestId={ DATA_TESTID.HEADER.BUTTON_GO_TO_CART_PREVIEW }
				onClick={ onGoToCart }
				onBlur={ onBlur }
			/>
		</div>
	)
}

export { HeaderTopBarMiniCartModalActions }
