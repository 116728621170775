import { filter } from 'lodash'

import { ICustomerResult, ICustomerAddressData } from '~/api/dataTypes/customer'

export const getCustomersAfterAddressRemove = (data: ICustomerResult, addressId: number): ICustomerResult => (
	{ ...data, addresses: filter(data.addresses, (address: ICustomerAddressData) => address.addressId !== addressId) }
)

export const getCustomerAfterAddressAdd = (data: ICustomerResult, address: ICustomerAddressData): ICustomerResult => (
	{ ...data, addresses: [...data.addresses, address] }
)
