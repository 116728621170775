import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { filter, isNull } from 'lodash'

import { usePrice } from '~/hooks/price'
import { ICartPreviewCheckPricePriceValueProps } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPricePriceValue/types'
import { CartPreviewProductsLabels } from '~/components/cart/cartPreview'
import { ICartItemListLabel } from '~/api/dataTypes/cart'

import styles from './CartPreviewCheckPricePriceValue.module.scss'

const CartPreviewCheckPricePriceValue = (props: ICartPreviewCheckPricePriceValueProps): ReactElement => {
	const { price, labels } = props
	const { priceFormat } = usePrice()

	const { newPrice, basePrice, newPricePromoLabel } = price

	const infoLabels = filter(labels, (label: ICartItemListLabel) => label.type === 'PRICE')

	const priceClass = classNames(styles.price, {
		[styles.promotion]: !isNull(basePrice) || newPricePromoLabel === 'SUPER_OFFER',
	})

	return (
		<div className={ styles.wrapper }>
			<span className={ priceClass }>
				{ priceFormat(newPrice, { precision: 2 }) }
			</span>

			<CartPreviewProductsLabels labels={ infoLabels } />
		</div>
	)
}

export { CartPreviewCheckPricePriceValue }
