import React, { ReactElement } from 'react'

import { HeaderTopBarSearchBar, HeaderTopBarUserAccount, HeaderTopBarWrapper, HeaderTopBarFavorites, HeaderTopBarMiniCart, HeaderLogotype, HeaderTopBarSearchIcon, HeaderTopBarHamburgerMenu, HeaderTopBarSearchBarWrapper } from '~/components/core/layout'
import { useMounted } from '~/hooks/mounted'
import { HeaderTopBarSearchProvider } from '~/providers/headerTopBarSearchProvider'
import { HeaderTopBarPreferredStore } from '~/components/core/layout/header'

const HeaderTopBar = (): ReactElement => {
	const { isMounted } = useMounted()

	return (
		<HeaderTopBarWrapper>
			<HeaderLogotype />

			<HeaderTopBarPreferredStore />

			<HeaderTopBarSearchProvider>
				<HeaderTopBarSearchBarWrapper>
					<HeaderTopBarSearchBar />
				</HeaderTopBarSearchBarWrapper>

				<HeaderTopBarUserAccount />

				<HeaderTopBarSearchIcon />
			</HeaderTopBarSearchProvider>

			<HeaderTopBarFavorites isEnabled={ isMounted } />

			<HeaderTopBarMiniCart isEnabled={ isMounted } />

			<HeaderTopBarHamburgerMenu />
		</HeaderTopBarWrapper>
	)
}

export { HeaderTopBar }
