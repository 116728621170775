import { useCallback } from 'react'

import { ISimpleStoreResponse, IStoreResponse } from '~/api/dataTypes/store'
import { IUseNavigationData } from '~/hooks/navigation'

export const useNavigation = (): IUseNavigationData => {

	const getGoogleMapToStoreUrl = useCallback((store: ISimpleStoreResponse | IStoreResponse): string => {
		const { name, address } = store
		const { street, zipCode, city, latitude, longitude } = address

		const url = `https://www.google.pl/maps/dir//${ name },${ encodeURIComponent(street) },${ zipCode }+${ city }/@${ latitude },${ longitude },15z`

		return url
	}, [])

	return {
		getGoogleMapToStoreUrl,
	}

}
