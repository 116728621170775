import { TFunction } from 'next-i18next'
import { isEqual } from 'lodash'

import { CustomerLoyaltyRewardType } from '~/api/dataTypes/customerLoyalty'

export const formatDiscountValue = (value: number, type: CustomerLoyaltyRewardType, t: TFunction): string => {
	const valueSuffix = t(`${ isEqual(type, 'PERCENT') ? 'percent' : 'currency' }`, { ns: 'common' })

	return `${ value } ${ valueSuffix }`
}
