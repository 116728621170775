import { CookieValueTypes, getCookie } from 'cookies-next'
import { isBoolean, isUndefined } from 'lodash'

import { COOKIE_KEY_CUSTOMER_UUID, LS_KEY_CART_UUID, COOKIE_KEY_TEMPO_ORDER_ID } from '~/utils/constants'
import { localStorageGet } from '~/utils/storage'

export const getCartUuid = (): string | null => {
	const cartUuid: CookieValueTypes = getCookie(COOKIE_KEY_CUSTOMER_UUID) || localStorageGet(LS_KEY_CART_UUID)

	if (isBoolean(cartUuid) || isUndefined(cartUuid)) {
		return null
	}

	return cartUuid
}

export const getCartTempoOrderId = (): string | null => {
	const tempoOrderId: CookieValueTypes = getCookie(COOKIE_KEY_TEMPO_ORDER_ID)

	if (isBoolean(tempoOrderId) || isUndefined(tempoOrderId)) {
		return null
	}

	return tempoOrderId
}
