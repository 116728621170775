import { createSelector } from 'reselect'
import { map, filter, isEqual, find } from 'lodash'

import { IRootState } from '~/state/types'
import { IShoppingCartResponse, ICartItemData, IChangeCartItemQuantity, PromotionCodeType } from '~/api/dataTypes/cart'
import { IProductQuantity } from '~/api/dataTypes/product'
import { CustomerLoyaltyType, ICustomerLoyaltyData } from '~/api/dataTypes/customerLoyalty'
import { ICartPreviewDiscountLysSelectedReward } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'

export const getCartPreviewData = (state: IRootState): IShoppingCartResponse => (
	state.cartPreview.data
)

export const getCartPreviewItemsAvailability = (state: IRootState): ICartItemData[] => (
	state.cartPreview.itemsAvailability
)

export const getCartPreviewItemsAvailable = createSelector(
	getCartPreviewItemsAvailability,
	(items: ICartItemData[]): ICartItemData[] => (
		filter(items, (item: ICartItemData): boolean => item.quantityInfo.possibleQuantity > 0)
	)
)

export const getCartPreviewItemsNotAvailable = createSelector(
	getCartPreviewItemsAvailability,
	(items: ICartItemData[]): ICartItemData[] => (
		filter(items, (item: ICartItemData): boolean => isEqual(item.quantityInfo.possibleQuantity, 0))
	)
)

export const getChangeCartItemQuantityList = (state: IRootState): IChangeCartItemQuantity[] => (
	state.cartPreview.data.changeCartItemQuantityList
)

export const getCartItemQuantityList = createSelector(getCartPreviewData, (data: IShoppingCartResponse): IProductQuantity[] => {
	const { cart: { cartItemList, productsQuantityMap } } = data

	return map(cartItemList, (cartItem: ICartItemData) => ({
		itemId: cartItem.itemId,
		quantity: productsQuantityMap[cartItem.productSimple.lmReference],
	}))
})

export const getIsTargetedOffer = (state: IRootState): boolean => (
	isEqual(state.cartPreview.data.cart.anyPostPurchaseRewards, true)
)

export const getIsPromotionEcommerce = (state: IRootState): boolean => (
	isEqual(state.cartPreview.data.cart.promotionCodeType, 'ECOMMERCE' as PromotionCodeType)
)

export const getIsPromotionLoyalty = (state: IRootState): boolean => (
	isEqual(state.cartPreview.data.cart.promotionCodeType, 'LOYALTY' as PromotionCodeType)
)

export const getIsPromotionNotApplied = (state: IRootState): boolean => (
	isEqual(state.cartPreview.data.cart.promotionCodeType, 'NONE' as PromotionCodeType)
)

export const getCartPreviewLoyaltyData = (state: IRootState): ICustomerLoyaltyData => (
	state.cartPreview.customerLoyalty
)

export const getCartPreviewLoyaltySelectedReward = createSelector(getCartPreviewLoyaltyData, (data: ICustomerLoyaltyData): ICartPreviewDiscountLysSelectedReward => {
	const { accountData: { currentRewards } } = data
	const selectedReward = find(currentRewards, { selected: true }) || find(currentRewards, { status: 'IN_USE' })

	const reward: ICartPreviewDiscountLysSelectedReward = {
		code: selectedReward?.code || '',
		type: selectedReward?.type || null,
	}

	return reward
})

export const getCartPreviewLoyaltyProgram = createSelector(getCartPreviewLoyaltyData, (data: ICustomerLoyaltyData): CustomerLoyaltyType => {
	const { accountData: { type } } = data

	return type
})

export const getCartPreviewLoyaltyLoading = createSelector(getCartPreviewLoyaltyData, (data: ICustomerLoyaltyData): boolean => (
	data.isPending
))
