import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'

import { CartCustomerInvoiceModalCompanyForm, CartCustomerInvoiceModalPrivateForm, ICartCustomerInvoiceModalForm, ICartCustomerInvoiceModalProps } from '~/components/cart/cartCustomer'
import { IRadioOption, RadioGroup } from '~/components/core/form'
import { Modal } from '~/components/core/modal'
import { CustomerType } from '~/api/dataTypes/customer'

import styles from './CartCustomerInvoiceModal.module.scss'

const CartCustomerInvoiceModal = (props: ICartCustomerInvoiceModalProps): ReactElement => {
	const { isOpen, onClose, onCancel, totalCostValue, isDeliveryType, invoice } = props
	const { t } = useTranslation(['cart'])

	const schema: yup.SchemaOf<ICartCustomerInvoiceModalForm> = useMemo(() => yup.object().shape({
		customerType: yup.string().oneOf(['PRIVATE', 'COMPANY']).required(),
	}), [])

	const { customerType } = invoice

	const { control } = useForm<ICartCustomerInvoiceModalForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			customerType: customerType || 'PRIVATE',
		},
	})

	const radioOptions: IRadioOption<CustomerType>[] = [
		{ label: t('customer.invoice.invoiceModal.private'), value: 'PRIVATE' },
		{ label: t('customer.invoice.invoiceModal.company'), value: 'COMPANY' },
	]

	const { field: { value } } = useController({ control, name: 'customerType' })
	const isPrivate = isEqual(value, 'PRIVATE' as CustomerType)

	const renderForm = useCallback((): ReactElement => {
		if (isPrivate) {
			return (
				<CartCustomerInvoiceModalPrivateForm
					invoice={ invoice }
					onCancel={ onCancel }
					onClose={ onClose }
				/>
			)
		}

		return (
			<CartCustomerInvoiceModalCompanyForm
				totalCostValue={ totalCostValue }
				isDeliveryType={ isDeliveryType }
				invoice={ invoice }
				onCancel={ onCancel }
				onClose={ onClose }
			/>
		)
	}, [isPrivate, totalCostValue, isDeliveryType, invoice])

	return (
		<Modal
			title={ t('customer.invoice.invoiceModal.title') }
			additionalClass={ styles.wrapper }
			isOpen={ isOpen }
			onClose={ onCancel }
		>
			<div className={ styles.content }>
				<RadioGroup
					control={ control }
					name="customerType"
					options={ radioOptions }
				/>

				<div className={ styles.form }>
					{ renderForm() }
				</div>
			</div>
		</Modal>
	)
}

export { CartCustomerInvoiceModal }
