import React, { ReactElement } from 'react'
import { size } from 'lodash'

import { HeaderTopBarMiniCartModalWrapper, HeaderTopBarMiniCartModalHeader, HeaderTopBarMiniCartModalItems, HeaderTopBarMiniCartModalActions, IHeaderTopBarMiniCartModalProps } from '~/components/core/layout'

const HeaderTopBarMiniCartModal = (props: IHeaderTopBarMiniCartModalProps): ReactElement => {
	const {
		items, cartUuid, productsQuantityMap, onGoToCart, onBlur,
		summaryCost = 0,
	} = props

	const productRows = size(items)

	return (
		<HeaderTopBarMiniCartModalWrapper>
			<HeaderTopBarMiniCartModalHeader />

			<HeaderTopBarMiniCartModalItems
				cartUuid={ cartUuid }
				items={ items }
				productsQuantityMap={ productsQuantityMap }
			/>

			<HeaderTopBarMiniCartModalActions
				summaryCost={ summaryCost }
				productRows={ productRows }
				onGoToCart={ onGoToCart }
				onBlur={ onBlur }
			/>
		</HeaderTopBarMiniCartModalWrapper>
	)
}

export { HeaderTopBarMiniCartModal }
