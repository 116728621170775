import React, { ReactElement, useCallback } from 'react'
import { isEmpty, map } from 'lodash'

import { Icon } from '~/components/core/icon'
import { IProfitListItem, IProfitsListProps } from '~/components/core/profitsList'

import styles from './ProfitsList.module.scss'

const ProfitsList = (props: IProfitsListProps): ReactElement => {
	const {
		items,
		color = 'green',
		caption = '',
	} = props

	const renderItems = useCallback((): ReactElement[] => (
		map(items, (item: IProfitListItem) => {
			const { icon, content, name } = item

			return (
				<li key={ name } className={ styles.item }>
					<Icon
						width={ 25 }
						height={ 25 }
						name={ icon }
						color={ color }
						additionalClass={ styles.icon }
					/>

					<div className={ styles.label }>
						{ content }
					</div>
				</li>
			)
		})
	), [items])

	return (
		<div className={ styles.wrapper }>
			{ !isEmpty(caption) && (
				<h2 className={ styles.caption }>
					{ caption }
				</h2>
			) }

			<ul className={ styles.items }>
				{ renderItems() }
			</ul>
		</div>
	)
}

export { ProfitsList }
