import React, { ReactElement, useCallback, useMemo } from 'react'
import ReactSelect, { SingleValue } from 'react-select'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { find, isEqual, isNull, toNumber } from 'lodash'

import { ISimpleStoreResponse } from '~/api/dataTypes/store'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IPreferredStoreFormProps, mapStoreDataToFormOption } from '~/components/core/preferredStore'
import { ISelectOption } from '~/components/core/form'
import { useDropdownSelect } from '~/hooks/dropdownSelect'

import styles from './PreferredStoreList.module.scss'

const PreferredStoreList = (props: IPreferredStoreFormProps): ReactElement => {
	const { items, onSelect } = props
	const { handleFocusAria, guidance } = useDropdownSelect()
	const { t } = useTranslation(['common'])

	const options = useMemo(() => mapStoreDataToFormOption(items), [items])

	const handleChange = useCallback((selectedOption: SingleValue<ISelectOption>): void => {
		if (!isNull(selectedOption)) {
			const selectedStore: ISimpleStoreResponse | undefined = find(items, (item: ISimpleStoreResponse) => isEqual(item.id, toNumber(selectedOption.value)))

			onSelect(selectedStore)
		}
	}, [items, onSelect])

	const selectClass = classNames({
		[styles.select]: true,
		'react-select': true,
	})

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.CORE.STORE_LIST }>
			<div className={ styles.searchInfo }>
				{ t('preferredShop.modalSelection.findInList') }
			</div>

			<div className={ styles.label }>
				{ t('preferredShop.chooseStore') }
			</div>

			<ReactSelect
				isSearchable
				isMulti={ false }
				className={ selectClass }
				classNamePrefix="react-select"
				instanceId="preferred-store-list"
				menuPosition="fixed"
				options={ options }
				components={ {
					IndicatorSeparator: () => null,
				} }
				placeholder={ t('select.placeholder', { ns: 'form' }) }
				noOptionsMessage={ () => t('preferredShop.modalSelection.noResults') }
				ariaLiveMessages={ {
					onFocus: handleFocusAria,
					guidance,
				} }
				onChange={ handleChange }
			/>
		</div>
	)
}

export { PreferredStoreList }
