import { useCallback, useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { getCartLoyaltyCustomerExistData } from '~/actions/cart'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { AppDispatch } from '~/state/store'
import { IRootState } from '~/state/types'

import { IUseLoyaltyCustomerData } from './types'

export const useLoyaltyCustomer = (): IUseLoyaltyCustomerData => {
	const { loyaltyProgram: { cartPromotionCodeForNonLoyaltyUsersEnabled: isCartPromotionCodeForNonLoyaltyUsersEnabled } } = useContext(AppParametersContext)
	const { hasCustomerLoyalty } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const shouldHidePromoCodeForNonLoyaltyLoggedUsers = !hasCustomerLoyalty && !isCartPromotionCodeForNonLoyaltyUsersEnabled

	const handleSetCartLoyaltyCustomerExistData = useCallback(async (): Promise<void> => {
		if (!isCartPromotionCodeForNonLoyaltyUsersEnabled) {
			await dispatch(getCartLoyaltyCustomerExistData())
		}
	}, [isCartPromotionCodeForNonLoyaltyUsersEnabled])

	return {
		shouldHidePromoCodeForNonLoyaltyLoggedUsers,
		onSetCartLoyaltyCustomerExist: handleSetCartLoyaltyCustomerExistData,
	}
}
