import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { nanoid } from 'nanoid'
import { useDispatch } from 'react-redux'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { isEqual, isNull, map, split } from 'lodash'

import { CartPreviewDeliveryPickupTransferTimeslotsTile, ICartPreviewDeliveryPickupTransferTimeslotsForm, ICartPreviewDeliveryPickupTransferTimeslotsTilesProps, IFormattedTimeslotTimePeriod } from '~/components/cart/cartPreview'
import { Radio } from '~/components/core/form'
import { AppDispatch } from '~/state/store'
import { getCartDeliveryTransportFromStoreTimeslotsData, setCartDeliveryTransportFromStore } from '~/actions/cart'
import { ICartDeliveryTransportFromStoreParameters } from '~/api/dataTypes/cart'
import { useLogError } from '~/hooks/logError'
import { Loader } from '~/components/core/loader'
import { useAlert } from '~/hooks/alert'

import styles from './CartPreviewDeliveryPickupTransferTimeslotsTiles.module.scss'

const CartPreviewDeliveryPickupTransferTimeslotsTiles = (props: ICartPreviewDeliveryPickupTransferTimeslotsTilesProps): ReactElement => {
	const { timeslot, deliveryStoreTimeSlots, categoryCode, deliveryStore, selectedDeliveryWayId, onChangeDelivery } = props
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()
	const [isPending, setIsPending] = useToggle(false)
	const { newAlert } = useAlert()
	const { t } = useTranslation(['cart'])

	const { date, timePeriod } = timeslot
	const { zipCode } = deliveryStoreTimeSlots
	const selectedTimeSlot = !isNull(deliveryStore) ? deliveryStore.timeSlot : null
	const selectedCategory = !isNull(deliveryStore) ? deliveryStore.category : ''
	const selectedZipCode = !isNull(deliveryStore) ? deliveryStore.zipCode : ''

	const selectedTimePeriod = useMemo(() => !isNull(selectedTimeSlot) ? `${ selectedTimeSlot.fromTime }-${ selectedTimeSlot.toTime }` : '', [selectedTimeSlot])

	const schema: yup.SchemaOf<ICartPreviewDeliveryPickupTransferTimeslotsForm> = useMemo(() => yup.object().shape({
		timePeriod: yup.string().required(),
	}), [])

	const { control, reset, setValue } = useForm<ICartPreviewDeliveryPickupTransferTimeslotsForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			timePeriod: selectedTimePeriod,
		},
		mode: 'onChange',
	})

	const { field, fieldState: { error } } = useController({ control, name: 'timePeriod' })

	const swiperParams: SwiperOptions = {
		spaceBetween: 16,
		slidesPerView: 'auto',
	}

	const setDeliveryTransportFromStore = useCallback(async (value: string): Promise<void> => {
		const fromToTime = split(value, '-', 2)

		const params: ICartDeliveryTransportFromStoreParameters = {
			category: categoryCode,
			date: date,
			fromTime: Number(fromToTime[0]),
			toTime: Number(fromToTime[1]),
			zipCode,
		}

		setIsPending(true)

		try {
			await dispatch(setCartDeliveryTransportFromStore(params))
			await onChangeDelivery()
		} catch (e: unknown) {
			setValue('timePeriod', '')
			sendLogError(e)

			newAlert('danger', t('preview.warnings.notAvailable.timeSlot'), 5000)

			await dispatch(getCartDeliveryTransportFromStoreTimeslotsData({
				category: categoryCode,
				type: 'BASIC',
			}))
		} finally {
			setIsPending(false)
		}
	}, [date, categoryCode, zipCode])

	const renderTiles = useCallback((): ReactElement[] => (
		map(timePeriod, (period: IFormattedTimeslotTimePeriod): ReactElement => {
			const { fromTime, toTime } = period

			const isChecked = isEqual(field.value, `${ fromTime }-${ toTime }`)

			const slideClass = classNames(styles.slide, {
				[styles.loading]: isPending,
			})

			return (
				<SwiperSlide key={ nanoid() } className={ slideClass }>
					<Radio
						value={ `${ fromTime }-${ toTime }` }
						field={ field }
						error={ error }
						isDisabled={ isChecked }
						onClick={ setDeliveryTransportFromStore }
					>
						<CartPreviewDeliveryPickupTransferTimeslotsTile
							isChecked={ isChecked }
							timePeriod={ period }
						/>
					</Radio>
				</SwiperSlide>
			)
		})
	), [field, timePeriod, error])

	useEffect(() => {
		reset()
		setValue('timePeriod', '')
	}, [categoryCode, zipCode, selectedDeliveryWayId])

	useEffect(() => {
		(async () => {
			if (isEqual(selectedCategory, categoryCode) && isEqual(selectedZipCode, zipCode)) {
				setValue('timePeriod', selectedTimePeriod)
			}
		})()
	}, [selectedTimePeriod, selectedCategory])

	return (
		<div className={ styles.wrapper }>
			{ isPending && (
				<Loader theme={ { wrapper: styles.loader } } />
			) }

			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<Swiper { ...swiperParams } className={ styles.swiper }>
				{ renderTiles() }
			</Swiper>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTimeslotsTiles }
