import React, { ReactElement } from 'react'
import { useRouter } from 'next/router'
import { isEqual } from 'lodash'

import { GoogleAnalyticsScript, GoogleRecaptchaScript, RealUserMonitoringScript, SareScript, DataDomeAdeoScript } from '~/components/core/externalScripts'
import { OneTrustCookieConsentScript } from '~/components/core/externalScripts/oneTrustCookieConsentScript'

const ExternalScripts = (): ReactElement | null => {
	const { query } = useRouter()

	const isExternalScriptsOff = isEqual(query.externalScripts, 'off')

	if (isExternalScriptsOff) {
		return null
	}

	return (
		<>
			<OneTrustCookieConsentScript />

			<GoogleAnalyticsScript />

			<GoogleRecaptchaScript />

			<SareScript />

			<RealUserMonitoringScript />

			<DataDomeAdeoScript />
		</>
	)
}

export { ExternalScripts }
