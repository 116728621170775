import { AxiosResponse } from 'axios'

import { CalendarServicePaymentWayCodeType, ICalendarSummaryResponse } from '~/api/dataTypes/calendarService'
import { AppAction } from '~/actions'
import { IStoreResponse } from '~/api/dataTypes/store'

export enum ServiceCartSummaryType {
	GET_SERVICE_CART_SUMMARY = 'serviceCartSummary/GET_SERVICE_CART_SUMMARY',
	GET_SERVICE_CART_SUMMARY_STORE = 'serviceCartSummary/GET_SERVICE_CART_SUMMARY_STORE',
	SET_SERVICE_CART_SUMMARY_PAYMENT_WAY = 'serviceCartSummary/SET_SERVICE_CART_SUMMARY_PAYMENT_WAY',
}

export type GetServiceCartSummaryActionType = AppAction<ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY, AxiosResponse<ICalendarSummaryResponse>>
export type GetServiceCartSummaryStoreActionType = AppAction<ServiceCartSummaryType.GET_SERVICE_CART_SUMMARY_STORE, AxiosResponse<IStoreResponse> | null>
export type SetServiceCartSummaryPaymentWayActionType = AppAction<ServiceCartSummaryType.SET_SERVICE_CART_SUMMARY_PAYMENT_WAY, CalendarServicePaymentWayCodeType>

export type ServiceCartSummaryActionType = GetServiceCartSummaryActionType | GetServiceCartSummaryStoreActionType | SetServiceCartSummaryPaymentWayActionType
