import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty, intersectionBy } from 'lodash'

import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { CartPreviewDeliverySplitProducts, getNotAvailableSplitProducts, ICartPreviewDeliverySplitProps } from '~/components/cart/cartPreview'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './CartPreviewDeliverySplit.module.scss'

const CartPreviewDeliverySplit = (props: ICartPreviewDeliverySplitProps): ReactElement | null => {
	const { itemsAvailable, itemsNotAvailable, isOpen, onClose } = props
	const { t } = useTranslation(['cart', 'common'])

	const notAvailableSplitProducts = getNotAvailableSplitProducts(itemsAvailable, itemsNotAvailable)
	const isNotEnoughItems = isEmpty(intersectionBy(notAvailableSplitProducts, itemsAvailable, 'itemId'))
	const modalTitle = isNotEnoughItems ? t('preview.warnings.delivery.modal.title') : t('preview.warnings.splitProducts.title')
	const subtitle = isNotEnoughItems ? t('preview.warnings.delivery.modal.subtitle') : t('preview.warnings.splitProducts.subtitle')

	return (
		<Modal
			isOpen={ isOpen }
			title={ modalTitle }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
		>
			<div className={ styles.content }>
				<div className={ styles.subtitle }>
					{ subtitle }
				</div>

				<CartPreviewDeliverySplitProducts
					type="availableProducts"
					titles={ [t('preview.warnings.delivery.modal.caption')] }
					items={ itemsAvailable }
				/>

				<CartPreviewDeliverySplitProducts
					type="availableProducts"
					titles={ [t('preview.warnings.splitProducts.caption')] }
					items={ notAvailableSplitProducts }
				/>

				<div className={ styles.actions }>
					<Button
						variant="secondary"
						text={ t('ok', { ns: 'common' }) }
						dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_DELIVERY_MODAL_OK }
						additionalClass={ styles.button }
						onClick={ onClose }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { CartPreviewDeliverySplit }
