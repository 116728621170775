import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './MergeCartModalHeader.module.scss'

const MergeCartModalHeader = (): ReactElement => {
	const { t } = useTranslation(['common'])

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.title }>
				{ t('cart.merge.caption') }
			</span>

			<span className={ styles.content }>
				{ t('cart.merge.content') }
			</span>
		</div>
	)
}

export { MergeCartModalHeader }
