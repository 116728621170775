import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { AuthActionUnionType } from '~/actions/auth'
import { ICustomerResult } from '~/api/dataTypes/customer'
import { ICustomerLastViewedProductsResponse } from '~/api/dataTypes/lastViewed'

export enum CustomerActionType {
	GET_CUSTOMER = 'customer/GET_CUSTOMER',
	PATCH_CUSTOMER = 'customer/PATCH_CUSTOMER',
	REMOVE_CUSTOMER_ADDRESS = 'customer/REMOVE_CUSTOMER_ADDRESS',
	ADD_CUSTOMER_ADDRESS = 'customer/ADD_CUSTOMER_ADDRESS',
	UPDATE_CUSTOMER_ADDRESS = 'customer/UPDATE_CUSTOMER_ADDRESS',
	GET_CUSTOMER_LAST_VIEWED_PRODUCTS = 'customer/GET_CUSTOMER_LAST_VIEWED_STORE',
}

export type GetCustomerActionType = AppAction<CustomerActionType.GET_CUSTOMER, AxiosResponse<ICustomerResult>>
export type RemoveCustomerAddressActionType = AppAction<CustomerActionType.REMOVE_CUSTOMER_ADDRESS, ICustomerResult>
export type PatchCustomerActionType = AppAction<CustomerActionType.PATCH_CUSTOMER, AxiosResponse>
export type AddCustomerAddressActionType = AppAction<CustomerActionType.ADD_CUSTOMER_ADDRESS, AxiosResponse>
export type UpdateCustomerAddressActionType = AppAction<CustomerActionType.UPDATE_CUSTOMER_ADDRESS, AxiosResponse>
export type GetCustomerLastViewedProductsType = AppAction<CustomerActionType.GET_CUSTOMER_LAST_VIEWED_PRODUCTS, AxiosResponse<ICustomerLastViewedProductsResponse>>

export type CustomerActionUnionType = GetCustomerActionType | PatchCustomerActionType | RemoveCustomerAddressActionType | AddCustomerAddressActionType | UpdateCustomerAddressActionType | AuthActionUnionType | GetCustomerLastViewedProductsType

