import React, { ReactElement, useCallback } from 'react'
import { isEmpty } from 'lodash'

import { ICartCustomerInvoicePrivateBoxProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerInvoicePrivateBox.module.scss'

const CartCustomerInvoicePrivateBox = (props: ICartCustomerInvoicePrivateBoxProps): ReactElement => {
	const { invoice } = props

	const { address, contact } = invoice
	const { firstName, lastName } = contact || {}
	const { street, houseNumber, flatNumber, zipCode, city } = address || {}

	const renderFlatNumber = useCallback((): ReactElement | null => {
		if (!isEmpty(flatNumber)) {
			return (
				<span>
					{ '/' }{ flatNumber }
				</span>
			)
		}

		return null
	}, [flatNumber])

	return (
		<div className={ styles.wrapper }>
			<div>
				{ firstName }{ ' ' }{ lastName }
			</div>

			<div className={ styles.address }>
				<div>
					<span>
						{ street }{ ' ' }{ houseNumber }
					</span>

					{ renderFlatNumber() }
				</div>

				<div>
					{ zipCode }{ ', ' }{ city }
				</div>
			</div>
		</div>
	)
}

export { CartCustomerInvoicePrivateBox }
