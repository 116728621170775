import { AxiosResponse } from 'axios'

import { ICustomerReviewsResult, ICustomerReviewsSubscriptionRequestData } from '~/api/dataTypes/customerReviews'
import { IPaginationRequestParams } from '~/api/dataTypes/pageable'
import { request } from '~/api/requests/axios'

export const getCustomerReviews = async (params: IPaginationRequestParams): Promise<AxiosResponse<ICustomerReviewsResult>> => (
	await request.get('/v1/reviews/products', {
		params,
		service: 'CUSTOMER_REVIEW_SERVICE',
	})
)

export const putCustomerReviewsSubscription = async (data: ICustomerReviewsSubscriptionRequestData): Promise<AxiosResponse> => (
	await request.put('/v1/reviews/subscription', data, {
		service: 'CUSTOMER_REVIEW_SERVICE',
	})
)
