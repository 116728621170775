import { AccountLoyaltyPageType } from '~/statics'

export const MENU_ITEMS_LOYALTY_PAGES: AccountLoyaltyPageType[] = [
	'ACCOUNT_LOYALTY_PROGRAM',
	'ACCOUNT_LOYALTY_DASHBOARD',
	'ACCOUNT_LOYALTY_HISTORY',
	'ACCOUNT_LOYALTY_EXCHANGE_POINTS',
	'ACCOUNT_LOYALTY_VOUCHER_ACTIVATION_SUCCESS',
	'ACCOUNT_LOYALTY_EXCHANGE_POINTS_FOUNDATION',
	'ACCOUNT_LOYALTY_EXCHANGE_POINTS_FOUNDATION_SUCCESS',
	'ACCOUNT_LOYALTY_EXCHANGE_POINTS_TRANSFER',
	'ACCOUNT_LOYALTY_EXCHANGE_POINTS_TRANSFER_SUCCESS',
	'ACCOUNT_LOYALTY_JOIN',
	'ACCOUNT_LOYALTY_JOIN_SUCCESS',
	'ACCOUNT_LOYALTY_CONNECT',
	'ACCOUNT_LOYALTY_CONNECT_ACCOUNT',
	'ACCOUNT_LOYALTY_CONNECT_CARD',
	'ACCOUNT_LOYALTY_CONNECT_FORGOT_PASSWORD',
	'ACCOUNT_LOYALTY_CONNECT_FORGOT_PASSWORD_SUCCESS',
	'ACCOUNT_LOYALTY_CONNECT_SUCCESS',
	'ACCOUNT_LOYALTY_HISTORY_SALE',
	'ACCOUNT_LOYALTY_HISTORY_RETURN',
	'ACCOUNT_LOYALTY_HISTORY_ADDITIONAL_CARD',
	'ACCOUNT_LOYALTY_HISTORY_MIGRATION_POINTS',
	'ACCOUNT_LOYALTY_HISTORY_EXCHANGE_POINTS_FOR_VOUCHER',
	'ACCOUNT_LOYALTY_HISTORY_DIRECT_EXCHANGE_POINTS',
	'ACCOUNT_LOYALTY_HISTORY_EXCHANGE_POINTS',
	'ACCOUNT_LOYALTY_HISTORY_EXPIRED_POINTS',
	'ACCOUNT_LOYALTY_HISTORY_CUSTOMER_ASSIGN',
	'ACCOUNT_LOYALTY_HISTORY_CUSTOMER_REGISTER',
	'ACCOUNT_LOYALTY_HISTORY_POINTS_CORRECTION',
]
