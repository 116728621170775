import React, { ReactElement } from 'react'
import { IMaskMixin } from 'react-imask'

import { IIMaskMixinProps, IInputBaseProps, InputBase } from '~/components/core/form'

// eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/no-unused-vars
export const MaskedInput = IMaskMixin<IIMaskMixinProps & IInputBaseProps>(({ inputRef, ref, ...props }): ReactElement => (
	<InputBase
		ref={ inputRef }
		// eslint-disable-next-line react/jsx-props-no-spreading
		{ ...props }
	/>
))
