import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { CloseButton } from '~/components/core/closeButton'
import { Icon } from '~/components/core/icon'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'

import styles from './HamburgerMenuUserAccountHeaderNav.module.scss'

const HamburgerMenuUserAccountHeaderNav = (): ReactElement => {
	const { handleSetUserMenuOpen, handleSetOpen } = useContext(HamburgerMenuContext)
	const { t } = useTranslation(['common'])

	const handleBack = useCallback(() => {
		handleSetUserMenuOpen(false)
	}, [handleSetUserMenuOpen])

	const handleCloseMenu = useCallback(() => {
		handleSetOpen(false)
	}, [handleSetOpen])

	return (
		<div className={ styles.wrapper }>
			<Button
				variant="neutral"
				size="inherit"
				ariaLabel={ t('back') }
				onClick={ handleBack }
			>
				<Icon
					name="arrow"
					width={ 7 }
					color="green"
					additionalClass={ styles.arrow }
				/>

				<span className={ styles.label }>
					{ t('back') }
				</span>
			</Button>

			<CloseButton onClose={ handleCloseMenu } />
		</div>
	)
}

export { HamburgerMenuUserAccountHeaderNav }
