import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEqual, isUndefined, map, times } from 'lodash'

import { Icon } from '~/components/core/icon'
import { getStarIconName, IStarsRatingProps } from '~/components/core/starsRating'

import styles from './StarsRating.module.scss'

const StarsRating = (props: IStarsRatingProps): ReactElement | null => {
	const {
		averageRating, reviewCount,
		numberOfStars = 5,
		color = 'orange',
		size = 18,
		additionalClass = '',
	} = props

	const hasReviewCount = !isUndefined(reviewCount) && !isEqual(reviewCount, 0)

	const stars: number[] = times(numberOfStars, (index: number) => index + 1)
	const starsClasses = classNames(styles.rating, additionalClass)

	return (
		<div className={ starsClasses }>
			<div>
				{ map(stars, (star: number) => {
					const iconName = getStarIconName(star, averageRating)

					return (
						<Icon
							key={ star }
							name={ iconName }
							width={ size }
							height={ size }
							color={ color }
							additionalClass={ styles.icon }
						/>
					)
				}) }
			</div>

			{ hasReviewCount && <div className={ styles.counter }>({ reviewCount })</div> }
		</div>
	)
}

export { StarsRating }
