import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { ICartPreviewSummaryDiscountRemoveButtonProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountRemoveButton'
import { Button } from '~/components/core/button'

import styles from './CartPreviewSummaryDiscountRemoveButton.module.scss'

const CartPreviewSummaryDiscountRemoveButton = (props: ICartPreviewSummaryDiscountRemoveButtonProps): ReactElement | null => {
	const { hasPromoCode, hasLoyaltyCard, handleShowForm } = props
	const { t } = useTranslation(['cart'])

	if (hasPromoCode && hasLoyaltyCard) {
		return (
			<Button
				size="inherit"
				variant="neutral"
				text={ t('preview.aside.discount.removeMessages.modifyPromoCode') }
				additionalClass={ styles.wrapper }
				onClick={ handleShowForm }
			/>
		)
	}

	if (hasPromoCode) {
		return (
			<Button
				size="inherit"
				variant="neutral"
				text={ t('preview.aside.discount.removeMessages.promoCode') }
				additionalClass={ styles.wrapper }
				onClick={ handleShowForm }
			/>
		)
	}

	if (hasLoyaltyCard) {
		return (
			<Button
				size="inherit"
				variant="neutral"
				text={ t('preview.aside.discount.removeMessages.specialDiscount') }
				additionalClass={ styles.wrapper }
				onClick={ handleShowForm }
			/>
		)
	}

	return null
}

export { CartPreviewSummaryDiscountRemoveButton }
