import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import { useFormContext, useWatch } from 'react-hook-form'
import axios, { AxiosError } from 'axios'
import classNames from 'classnames'
import { forEach, isEmpty, isEqual, isUndefined, size } from 'lodash'

import { ICartCustomerInvoiceModalCompanyForm, NIP_FIELDS } from '~/components/cart/cartCustomer'
import { useHogaDictionary } from '~/hooks/hogaDictionary'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { validation } from '~/utils/validation'
import { Input, RequiredInfo, DropdownInput } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { getGusCustomerData } from '~/api/requests/account'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerInvoiceModalCompanyFormFields.module.scss'

const CartCustomerInvoiceModalCompanyFormFields = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { control, setValue, setError, resetField, watch } = useFormContext<ICartCustomerInvoiceModalCompanyForm>()
	const { nip } = useWatch<ICartCustomerInvoiceModalCompanyForm>({ control })
	const [isLoading, setIsLoading] = useToggle(false)
	const { newAlert } = useAlert()
	const { getErrorMessage } = useFormError()
	const { isDropdownOpen, list, getHogaDictionary, onOpenDropdown, onToggleDropdown } = useHogaDictionary()
	const { sendLogError } = useLogError()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { postalCode, city } = watch()
	const { mask } = validation

	const handleChangePostalCode = useCallback(async (): Promise<void> => {
		await getHogaDictionary(postalCode ?? '')
	}, [postalCode])

	const handleChangeSelectList = useCallback((): void => {
		if (isEqual(size(list), 1) && isEmpty(city)) {
			setValue('city', list[0])
		} else if (!isEmpty(list)) {
			onOpenDropdown()
		}
	}, [list, city])

	const handleClick = useCallback(async (): Promise<void> => {
		try {
			if (isUndefined(nip)) {
				return
			}

			setIsLoading(true)

			const { data } = await getGusCustomerData({ nip })

			forEach(NIP_FIELDS, (field: keyof ICartCustomerInvoiceModalCompanyForm) => setValue(field, data[field] || ''))
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				if (code === 'INVALID_NIP_NUMBER') {
					setError('nip', { message: getErrorMessage(code) })
					forEach(NIP_FIELDS, (field: keyof ICartCustomerInvoiceModalCompanyForm) => resetField(field))
				} else {
					newAlert('danger', getErrorMessage(code), 5000)
				}
			}

			sendLogError(e)
		} finally {
			setIsLoading(false)
		}
	}, [nip])

	const renderNipButton = useCallback((): ReactElement => (
		<Button
			isLoading={ isLoading }
			variant="secondary"
			text={ t('customer.invoice.invoiceCompanyForm.nipButton') }
			additionalClass={ styles.nipButton }
			onClick={ handleClick }
		/>
	), [nip, isLoading])

	useEffect(() => {
		(async () => {
			await handleChangePostalCode()
		})()
	}, [postalCode])

	useEffect(() => {
		handleChangeSelectList()
	}, [list])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<Input
				isRequired
				control={ control }
				name="nip"
				mask={ mask.nip }
				label={ t('customer.invoice.invoiceCompanyForm.nipLabel') }
				aside={ renderNipButton() }
				theme={ { icon: styles.nipControlIcon } }
			/>

			<Input
				isRequired
				control={ control }
				name="corporateName"
				label={ t('customer.invoice.invoiceCompanyForm.companyNameLabel') }
			/>

			<Input
				control={ control }
				name="firstName"
				label={ t('customer.invoice.invoiceCompanyForm.firstNameLabel') }
			/>

			<Input
				control={ control }
				name="lastName"
				label={ t('customer.invoice.invoiceCompanyForm.lastNameLabel') }
			/>

			<Input
				isRequired
				control={ control }
				name="street"
				label={ t('customer.invoice.invoiceCompanyForm.streetLabel') }
			/>

			<div className={ styles.addressNumbersControls }>
				<Input
					isRequired
					control={ control }
					name="houseNumber"
					label={ t('customer.invoice.invoiceCompanyForm.buildingNumberLabel') }
					additionalClass={ styles.control }
				/>

				<Input
					control={ control }
					name="flatNumber"
					label={ t('customer.invoice.invoiceCompanyForm.flatNumberLabel') }
					additionalClass={ styles.control }
				/>
			</div>

			<div className={ styles.cityControls }>
				<Input
					isRequired
					control={ control }
					name="postalCode"
					label={ t('customer.invoice.invoiceCompanyForm.zipCodeLabel') }
					mask={ mask.zipCode }
					additionalClass={ styles.control }
				/>

				<DropdownInput
					isRequired
					isMenuOpen={ isDropdownOpen }
					control={ control }
					name="city"
					label={ t('customer.invoice.invoiceCompanyForm.cityLabel') }
					options={ list }
					theme={ { wrapper: styles.control } }
					onToggle={ onToggleDropdown }
				/>
			</div>

			<RequiredInfo />
		</div>
	)
}

export { CartCustomerInvoiceModalCompanyFormFields }
