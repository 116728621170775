import { isNull } from 'lodash'

import { DISCOUNT } from '~/components/cart/cartPreview'

export const checkDiscountType = (promotionCode: string | null, isCodeTypeEcommerce: boolean): DISCOUNT => {
	if (isNull(promotionCode)) {
		return DISCOUNT.NONE
	}

	if (isCodeTypeEcommerce) {
		return DISCOUNT.PROMOTION_CODE
	}

	return DISCOUNT.DOM_PRO
}
