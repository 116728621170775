import { IAccountLoyaltyProgramState } from '~/state/reducers/accountReducer'

export const accountLoyaltyProgramInitialState: IAccountLoyaltyProgramState = {
	type: 'PRIVATE',
	cardNumber: null,
	additionalCard: null,
	loyaltyClubUrl: '',
	regulationsUrl: '',
	balance: 0,
	name: '',
	cardsLimit: 1,
	discounts: [],
	expiringBalance: 0,
	cards: [],
	pointsActions: [],
	contactMail: '',
	resignMail: '',
	history: {
		items: [],
		pagination: {
			totalItems: 0,
			page: 1,
			totalPages: 1,
			perPage: 10,
		},
		dateFilter: '',
		sort: '',
	},
	exchangePoints: {
		items: [],
	},
	joinConsents: {
		additionalCardConsent: {
			name: '',
			required: false,
			description: null,
			label: '',
		},
		regulationCompanyConsent: {
			name: '',
			required: false,
			description: null,
			label: '',
		},
		regulationPrivateConsent: {
			name: '',
			required: false,
			description: null,
			label: '',
		},
		policyFullText: '',
		policyText: '',
	},
}
