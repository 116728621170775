import produce, { Draft } from 'immer'
import { isEmpty, isNull } from 'lodash'

import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { AppPromiseAction } from '~/actions'
import { CartActionType, CartPreviewActionType } from '~/actions/cart'
import { AuthActionType, AuthAdeoActionType } from '~/actions/auth'

import { ICartPreviewState } from './types'
import { cartPreviewInitialState as initialState } from './constants'
import { mapSelectedRewardStatus } from './helpers'

export const cartPreview = produce((draft: Draft<ICartPreviewState>, action: AppPromiseAction<CartPreviewActionType>) => {
	switch (action.type) {
		case REQUEST(CartActionType.GET_CART):
			draft.isPending = true

			return draft
		case REQUEST(CartActionType.GET_CART_LOYALTY_ACCOUNT):
		case REQUEST(CartActionType.SELECT_CART_LOYALTY_ACCOUNT_REWARD):
			draft.customerLoyalty.isPending = true

			return draft
		case FAILURE(CartActionType.GET_CART_LOYALTY_ACCOUNT):
		case FAILURE(CartActionType.SELECT_CART_LOYALTY_ACCOUNT_REWARD):
			draft.customerLoyalty.isPending = false

			return draft
		case SUCCESS(CartActionType.GET_CART):
			if (isEmpty(action.payload.data.cart)) {
				draft.data = initialState.data
				draft.isPending = false

				return draft
			}

			draft.data = action.payload.data
			draft.isPending = false

			if (!isNull(action.payload.data.cart.deliveryStore)) {
				draft.deliveryStoreTimeSlots.zipCode = action.payload.data.cart.deliveryStore.zipCode
			}

			return draft
		case SUCCESS(CartActionType.ADD_PRODUCT_TO_CART): {
			const { cartItemList, productsQuantityMap, productsValue } = action.payload.data.simpleCart

			draft.data.cart.cartItemList = cartItemList
			draft.data.cart.productsQuantityMap = productsQuantityMap
			draft.data.cart.productsValue = productsValue

			return draft
		}
		case SUCCESS(CartActionType.REMOVE_PRODUCT_FROM_CART):
			draft.data.cart = { ...draft.data.cart, ...action.payload.data }

			return draft
		case SUCCESS(CartActionType.CART_CALCULATE):
			draft.data = { ...draft.data, ...action.payload.data }

			if (!isNull(action.payload.data.cart.deliveryStore)) {
				draft.deliveryStoreTimeSlots.zipCode = action.payload.data.cart.deliveryStore.zipCode
			}

			return draft
		case SUCCESS(CartActionType.CART_DELIVERY_CALCULATE):
			draft.data = action.payload.data

			return draft
		case FAILURE(CartActionType.GET_CART):
			draft.isPending = false

			return draft
		case SUCCESS(CartActionType.SEARCH_CART_DELIVERY_PICKUP_POINT):
			draft.pickupPoints = action.payload.data

			return draft
		case SUCCESS(CartActionType.CLEAR_CART_DELIVERY_PICKUP_POINT):
			draft.pickupPoints = initialState.pickupPoints

			return draft
		case SUCCESS(CartActionType.SET_CART_COURIER_NOTE):
			draft.tmpCourierNote = action.payload

			return draft
		case SUCCESS(CartActionType.SET_CART_PAYMENT_WAY_ID):
			draft.data.cart.selectedPaymentWayId = action.payload

			return draft
		case SUCCESS(CartActionType.SET_ACTIVE_DELIVERY_GROUP):
			draft.activeDeliveryGroup = action.payload

			return draft
		case SUCCESS(CartActionType.CLEAR_SELECTED_DELIVERY_WAY_ID):
			draft.data.cart.selectedDeliveryWayId = null

			return draft
		case SUCCESS(CartActionType.GET_TRANSPORT_FROM_STORE_CATEGORIES):
			draft.deliveryStoreTimeSlots.categories = action.payload.data

			return draft
		case SUCCESS(CartActionType.SET_TRANSPORT_FROM_STORE_ZIP_CODE):
			draft.deliveryStoreTimeSlots.zipCode = action.payload

			return draft
		case SUCCESS(CartActionType.GET_TRANSPORT_FROM_STORE_TIMESLOTS):
			draft.deliveryStoreTimeSlots.timeslotList = action.payload.data.timeslotList

			return draft
		case SUCCESS(CartActionType.CLEAR_TRANSPORT_FROM_STORE_DATA):
			draft.deliveryStoreTimeSlots = initialState.deliveryStoreTimeSlots

			return draft
		case SUCCESS(CartActionType.GET_CART_CHECK_AVAILABILITY):
			draft.itemsAvailability = action.payload.data.itemList

			return draft
		case SUCCESS(CartActionType.GET_CART_LOYALTY_ACCOUNT):
			draft.customerLoyalty.accountData = action.payload.data
			draft.customerLoyalty.isPending = false

			return draft
		case SUCCESS(CartActionType.SELECT_CART_LOYALTY_ACCOUNT_REWARD):
			draft.customerLoyalty.accountData.currentRewards = mapSelectedRewardStatus(draft.customerLoyalty.accountData.currentRewards, action)
			draft.customerLoyalty.isPending = false

			return draft
		case SUCCESS(CartActionType.SET_CART_STEP_ZERO):
			draft.isStepZero = action.payload

			return draft
		case SUCCESS(CartActionType.GET_CUSTOMER_LOYALTY_EXIST):
			draft.hasCustomerLoyalty = action.payload.data.exists

			return draft
		case SUCCESS(CartActionType.REMOVE_PRODUCTS_FROM_CART):
		case SUCCESS(AuthActionType.SET_LOGOUT):
		case SUCCESS(AuthAdeoActionType.SET_ADEO_LOGOUT):
			return initialState
		default:
			return draft
	}
}, initialState)
