import { isNaN, isUndefined, isEmpty, toNumber, isNil, replace } from 'lodash'

import { HtmlTagType } from '~/utils/types'

export const hasUppercase = (value: string | undefined, min: number = 1): boolean => {
	if (isUndefined(value)) {
		return false
	}

	const chars = value.split('')
	const lowerChars = value.toLowerCase().split('')

	return chars.filter((v: string) => !lowerChars.includes(v)).length >= min
}

export const hasLowercase = (value: string | undefined, min: number = 1): boolean => {
	if (isUndefined(value)) {
		return false
	}

	const chars = value.split('')
	const upperChars = value.toUpperCase().split('')

	return chars.filter((v: string) => !upperChars.includes(v)).length >= min
}

export const hasNumberOrEmpty = (value: string | undefined, min: number = 1): boolean => {
	if (isUndefined(value)) {
		return false
	}

	if (isEmpty(value)) {
		return true
	}

	const numberRegExp = new RegExp(/\d/g)

	if (!numberRegExp.test(value)) {
		return false
	}

	const numbers = value
		.split('')
		.map((v: string) => toNumber(v))
		.filter((v: number) => !isNaN(v))

	return numbers.length >= min
}

export const hasNumber = (value: string | undefined, min: number = 1): boolean => {
	if (isUndefined(value)) {
		return false
	}

	const numberRegExp = new RegExp(/\d/g)

	if (!numberRegExp.test(value)) {
		return false
	}

	const numbers = value
		.split('')
		.map((v: string) => toNumber(v))
		.filter((v: number) => !isNaN(v))

	return numbers.length >= min
}

export const hasNotNumberAndAllowOnlyDash = (value: string | undefined): boolean => {
	if (isUndefined(value)) {
		return false
	}

	const matchDashBetweenWords = new RegExp(/[^A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]/s)

	return !matchDashBetweenWords.test(value)
}

export const hasSpecialChar = (value: string | undefined): boolean => {
	if (isUndefined(value)) {
		return false
	}

	const matchSpecialCharacter = new RegExp(/[-!@#$%^&*()_+|=`{}\\[\]:";'<>?,.\\/]/s)

	return matchSpecialCharacter.test(value)
}

export const hasOnlyLetters = (value?: string): boolean => {
	if (isEmpty(value)) {
		return true
	}

	if (isUndefined(value)) {
		return false
	}

	const lettersRegExp = new RegExp(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/)

	return lettersRegExp.test(value)
}

export const hasOnlyLettersNumbersOrDash = (value: string | undefined): boolean => {
	if (isEmpty(value)) {
		return true
	}

	if (isUndefined(value)) {
		return false
	}

	const lettersRegExp = new RegExp(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9-]*$/)

	return lettersRegExp.test(value)
}

export const hasOnlyNumbersWithLetters = (value: string | undefined): boolean => {
	if (isEmpty(value)) {
		return true
	}

	if (isUndefined(value)) {
		return false
	}

	const lettersRegExp = new RegExp(/^[0-9]+[a-zA-Z]*$/)

	return lettersRegExp.test(value)
}

export const hasOnlyLettersSpacesOrDash = (value: string | undefined): boolean => {
	if (isEmpty(value)) {
		return true
	}

	if (isUndefined(value)) {
		return false
	}

	const lettersRegExp = new RegExp(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ.-\s]*$/)

	return lettersRegExp.test(value)
}

export const hasOnlyLettersNumbersSpacesDotsOrDash = (value: string | undefined): boolean => {
	if (isEmpty(value)) {
		return true
	}

	if (isUndefined(value)) {
		return false
	}

	const lettersRegExp = new RegExp(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9-\s-.]*$/)

	return lettersRegExp.test(value)
}

export const hasNumbersOptionalLettersAndBackslash = (value: string | undefined): boolean => {
	if (isNil(value)) {
		return false
	}

	const lettersRegExp = new RegExp(/(^[1-9])[A-Za-z0-9\\/]*$/)

	return lettersRegExp.test(value)
}

export const getContentFromFirstTag = (htmlString: string, tag: HtmlTagType): string => new DOMParser().parseFromString(htmlString, 'text/html').querySelector(tag)?.textContent || ''

export const getScriptsFromContent = (htmlString: string): string[] | null => {
	const scripts = htmlString.matchAll(/<script[\s\S]*?>([\s\S]*?)<\/script>/gm)
	const scriptsList: string[] = []

	for (const script of scripts) {
		scriptsList.push(script[1])
	}

	return isEmpty(scriptsList) ? null : scriptsList
}

export const convertInputValueToPreventNaNs = (inputValue: string, defaultValue: number): number => {
	const value = Number(inputValue)

	return isNaN(value) ? defaultValue : value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEmptyStringForNull = (value: string | null | undefined): string => isNil(value) ? '' : value

export const getStringWithNoSpaces = (value: string): string => value.replaceAll(' ', '')

export const isValidNip = (nip: string | undefined): boolean => {
	if (isUndefined(nip)) {
		return false
	}

	const reg = /^[0-9]{10}$/

	if (reg.test(nip) == false) {
		return false
	}

	const digits = (''+nip).split('')
	const checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11

	return (parseInt(digits[9]) === checksum)
}

export const getStringWithoutDoubleQuotes = (value: string): string => replace(value, /"/g, '\'')
