import { createSelector } from 'reselect'
import { isNull } from 'lodash'

import { ICartCustomerData } from '~/api/dataTypes/cart'
import { IRootState } from '~/state/types'

export const getCartCustomerData = (state: IRootState): ICartCustomerData => (
	state.cartCustomer.data
)

export const isCartCustomerInvoiceSetInApiData = createSelector(getCartCustomerData, (data: ICartCustomerData): boolean => {
	const { invoice: { address, contact } } = data

	return !isNull(address) && !isNull(contact)
})
