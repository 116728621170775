import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { CartCustomerOtherContactData, ICartCustomerOtherContactProps } from '~/components/cart/cartCustomer'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerOtherContact.module.scss'

const CartCustomerOtherContact = (props: ICartCustomerOtherContactProps): ReactElement | null => {
	const { otherContact, onEdit, isDataEmpty } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	if (isDataEmpty) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass } id="cart-customer-other-contact-edit">
			<CartCustomerOtherContactData otherContact={ otherContact } />

			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ styles.edit }
				ariaLabel={ ` - ${ t('customer.orderCollection.edit') }` }
				ariaLabelledBy="cart-customer-other-contact-edit"
				onClick={ onEdit }
			>
				<span aria-hidden="true">
					{ t('customer.orderCollection.edit') }
				</span>
			</Button>

			<div className={ styles.checkmark }>
				<Icon
					name="checkmark"
					width={ 15 }
					height={ 11 }
					color="white"
				/>
			</div>
		</div>
	)
}

export { CartCustomerOtherContact }
