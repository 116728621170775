import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import { isArray, join, map } from 'lodash'

import { IHeaderTopBarUserAccountButtonProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { Icon, ICON_TYPE } from '~/components/core/icon'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useMounted } from '~/hooks/mounted'

import styles from './HeaderTopBarUserAccountButton.module.scss'

const HeaderTopBarUserAccountButton = (props: IHeaderTopBarUserAccountButtonProps): ReactElement | null => {
	const { isLogged, isActive, buttonRef, userName, handleToggleModal, prepareText } = props
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')
	const { isMounted } = useMounted()

	const iconName: keyof typeof ICON_TYPE = isLogged ? 'personFull' : 'person'

	const ariaLabel = useCallback((): string => {
		const text = prepareText(userName)

		return isArray(text) ? join(text, '') : text
	}, [userName])

	const renderTextArray = useCallback((text: string[]): ReactElement[] => (
		map(text, (phrase: string): ReactElement => (
			<span key={ phrase }>
				{ phrase }
			</span>
		))
	), [])

	const renderContent = useCallback((): ReactElement => {
		const text = prepareText(userName)

		if (isLogged) {
			return (
				<span className={ styles.name }>
					{ text }

					<Icon
						additionalClass={ styles.arrow }
						name="arrow"
						width={ 8 }
						color="green"
					/>
				</span>
			)
		}

		return (
			<>
				{ renderTextArray(text as string[]) }
			</>
		)
	}, [isLogged, userName, prepareText, renderTextArray])

	const handleOnBlur = useCallback(() => {
		if (isShiftTabPressed && isActive) {
			handleToggleModal()
		}
	}, [isShiftTabPressed, buttonRef])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.active]: isActive,
	})

	if (!isMounted) {
		return null
	}

	return (
		<Button
			hasAriaPopup
			isAriaExpanded={ isActive }
			buttonRef={ buttonRef }
			additionalClass={ wrapperClass }
			variant="neutral"
			size="inherit"
			dataTestId={ DATA_TESTID.HEADER.BUTTON_OPEN_USER_MENU }
			ariaLabel={ ariaLabel() }
			onClick={ handleToggleModal }
			onBlur={ handleOnBlur }
		>
			<Icon
				name={ iconName }
				color="navy"
				width={ 28 }
				height={ 29 }
				additionalClass={ styles.icon }
			/>

			<span
				className={ styles.label }
				data-testid={ DATA_TESTID.HEADER.USER_MENU_LABEL }
			>
				{ renderContent() }
			</span>
		</Button>
	)
}

export { HeaderTopBarUserAccountButton }
