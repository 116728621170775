import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'

import { IHeaderMainMenuItemProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderMainMenuItem.module.scss'

const HeaderMainMenuItem = (props: IHeaderMainMenuItemProps): ReactElement => {
	const { item } = props

	const { pageType, url, name, promotion, color, isSelected } = item

	const renderContent = useCallback((): ReactElement => (
		<Link
			href={ url }
			theme={ {
				wrapper: styles.link,
			} }
			style={ { color } }
			dataTestId={ DATA_TESTID.HEADER.MAIN_MENU_ITEM(name) }
			ariaLabel={ name }
		>
			{ name }
		</Link>
	), [pageType, url, name])

	const itemClass = classNames(styles.item, {
		[styles.promotion]: promotion,
		[styles.selected]: isSelected,
	})

	return (
		<li className={ itemClass }>
			{ renderContent() }
		</li>
	)
}

export { HeaderMainMenuItem }
