import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ILayoutAccountMenuItemProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'
import { Icon } from '~/components/core/icon'

import styles from './LayoutAccountMenuItem.module.scss'

const LayoutAccountMenuItem = (props: ILayoutAccountMenuItemProps): ReactElement => {
	const { isActive, item } = props
	const { icon, title, url } = item

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.active]: isActive,
	})

	return (
		<Link
			href={ url }
			theme={ { wrapper: wrapperClass } }
			ariaLabel={ title }
		>
			<div className={ styles.iconWrapper }>
				<Icon
					color="navy"
					width={ 16 }
					name={ icon }
				/>
			</div>

			<span className={ styles.title }>
				{ title }
			</span>
		</Link>
	)
}

export { LayoutAccountMenuItem }
