import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { every, isEmpty, isNull, map } from 'lodash'

import { IWorkingDaysData } from '~/api/dataTypes/store'
import { useDate } from '~/hooks/date'
import { IPreferredStoreInfoOpeningHoursProps } from '~/components/core/preferredStore'
import { useMozaic } from '~/hooks/mozaic'

import styles from './PreferredStoreInfoOpeningHours.module.scss'

const PreferredStoreInfoOpeningHours = (props: IPreferredStoreInfoOpeningHoursProps): ReactElement | null => {
	const {
		workingDays,
		theme = {},
	} = props
	const { formatDateUnix, getTimeFormat } = useDate()
	const { t } = useTranslation(['stores', 'common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const renderWorkingDays = useCallback((): ReactElement => (
		<ul className={ styles.items }>
			{ map(workingDays, (workingDay: IWorkingDaysData) => {
				const { dayOfWeek, currentDay, workDay, openingTime, closingTime } = workingDay

				const formattedOpeningTime = !isNull(openingTime) ? formatDateUnix(openingTime, getTimeFormat()) : ''
				const formattedClosingTime = !isNull(closingTime) ? formatDateUnix(closingTime, getTimeFormat()) : ''

				const hasOpeningClosingTime = every([openingTime, closingTime], !isNull)

				const itemClass = classNames(styles.item, {
					[styles.active]: currentDay,
				})

				return (
					<li key={ dayOfWeek } className={ itemClass }>
						<span>
							{ dayOfWeek }
						</span>

						{ workDay ? (
							<span>
								{ `${ formattedOpeningTime }-${ formattedClosingTime }` }
							</span>
						) : (
							<div className={ styles.content }>
								<span>
									{ t('closed', { ns: 'common' }) }
								</span>

								{ hasOpeningClosingTime && (
									<span className={ styles.hours }>
										{ `${ t('usuallyOpen') } (${ formattedOpeningTime }-${ formattedClosingTime })` }
									</span>
								) }
							</div>
						) }
					</li>
				)
			}) }
		</ul>
	), [workingDays])

	if (isEmpty(workingDays)) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, theme.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<span className={ styles.caption }>
				{ t('openingHours') }
			</span>

			{ renderWorkingDays() }
		</div>
	)
}

export { PreferredStoreInfoOpeningHours }
