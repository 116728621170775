import { ReactNode, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { isNil } from 'lodash'

import { AlertType } from '~/components/core/notifications'
import { AlertContext, IAlert } from '~/providers/alertProvider'
import { IAlertData } from '~/hooks/alert'
import { console } from '~/utils/console'

const useAlert = (): IAlertData => {
	const { alerts, addAlert, removeAlert } = useContext(AlertContext)
	const { t } = useTranslation(['common'])
	const { log } = console

	const newAlert = (type: AlertType, children: ReactNode, duration?: number): void => {
		const alert: IAlert = {
			index: 0,
			type: 'warning',
			icon: 'warning',
			children,
			duration,
		}

		switch (type) {
			case 'warning':
				alert.type = 'warning'
				alert.icon = 'warning'

				break
			case 'danger':
				alert.type = 'danger'
				alert.icon = 'errorIcon'

				break
			case 'info':
				alert.type = 'info'
				alert.icon = 'errorIcon'

				break
			case 'success':
				alert.type = 'success'
				alert.icon = 'checkCircle'

				break
			default:
				newAlert('warning', t('unexpectedError'))
		}

		addAlert(alert)
	}

	const preventAlert = useCallback((e?: unknown): void => {
		newAlert('danger', t('unexpectedErrorTryAgain'), 5000)

		if (!isNil(e)) {
			log('preventAlertError:', e)
		}
	}, [])

	return {
		alerts,
		newAlert,
		removeAlert,
		preventAlert,
	}
}

export { useAlert }
