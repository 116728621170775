import { useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { compact, isNil } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { IUseUserMenuData, IUserMenuSectionItem, IAccountPanelMenuSection, MENU_ITEMS_LOYALTY_PAGES } from '~/hooks/userMenu'
import { urls as utilsUrls } from '~/utils/urls'

export const useUserMenu = (): IUseUserMenuData => {
	const { loyaltyProgram, urls } = useContext(AppParametersContext)
	const { t } = useTranslation(['header'])

	const getUserMenuSection = useCallback((): IUserMenuSectionItem[] => compact([
		{
			id: 'person',
			name: t('topBar.account.data'),
			url: urls.customerEditData,
			icon: 'person',
		},
		{
			id: 'envelope',
			name: t('topBar.account.marketingAgreements'),
			url: urls.customerMarketingConsents,
			icon: 'envelope',
		},
		{
			id: 'cart',
			name: t('topBar.account.orders'),
			url: urls.customerOrderList,
			icon: 'cart',
		},
		{
			id: 'heartEmpty',
			name: t('topBar.account.favorites'),
			url: urls.shoppingList,
			icon: 'heartEmpty',
		},
		{
			id: 'pen',
			name: t('topBar.account.projects'),
			url: urls.customerPlanerProjects,
			icon: 'pen',
		},
		{
			id: 'star-empty',
			name: t('topBar.account.opinions'),
			url: urls.customerOpinion,
			icon: 'starEmpty',
		},
		!isNil(loyaltyProgram) && {
			id: 'percent',
			name: loyaltyProgram.menuName,
			url: urls.loyaltyProgram,
			icon: 'percent',
		},
	]), [loyaltyProgram, urls, utilsUrls])

	const getAccountPanelMenuSections = useCallback((): IAccountPanelMenuSection[] => compact([
		{
			id: 'account-panel',
			items: [
				{
					id: 'person',
					icon: 'person',
					title: t('topBar.account.data'),
					pages: ['ACCOUNT_USER_DATA'],
					pageType: 'ACCOUNT_USER_DATA',
					url: urls.customerEditData,
				},
				{
					id: 'envelope',
					icon: 'envelope',
					title: t('topBar.account.marketingAgreements'),
					pages: ['ACCOUNT_MARKETING_CONSENTS'],
					pageType: 'ACCOUNT_MARKETING_CONSENTS',
					url: urls.customerMarketingConsents,
				},
				{
					id: 'cart',
					icon: 'cart',
					title: t('topBar.account.orders'),
					pages: ['ACCOUNT_ORDERS'],
					pageType: 'ACCOUNT_ORDERS',
					url:  urls.customerOrderList,
				},
				{
					id: 'heart-empty',
					icon: 'heartEmpty',
					title: t('topBar.account.favorites'),
					pages: ['SHOPPING_LIST'],
					pageType: 'SHOPPING_LIST',
					url: urls.shoppingList,
				},
				{
					id: 'pen',
					icon: 'pen',
					title: t('topBar.account.projects'),
					pages: ['ACCOUNT_PROJECTS'],
					pageType: 'ACCOUNT_PROJECTS',
					url: urls.customerPlanerProjects,
				},
				{
					id: 'star-empty',
					icon: 'starEmpty',
					title: t('topBar.account.opinions'),
					pages: ['ACCOUNT_REVIEWS'],
					pageType: 'ACCOUNT_REVIEWS',
					url: urls.customerOpinion,
				},
				{
					id: 'percent',
					icon: 'percent',
					title: loyaltyProgram.menuName,
					pages: MENU_ITEMS_LOYALTY_PAGES,
					url: urls.loyaltyProgram,
				},
			],
		},
	]), [loyaltyProgram, urls, utilsUrls])

	return {
		getUserMenuSection,
		getAccountPanelMenuSections,
	}
}
