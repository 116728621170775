import React, { ReactElement, useCallback, useContext, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useClickAway, useKeyPressEvent, useToggle } from 'react-use'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { IRootState } from '~/state/types'
import { DATA_TESTID } from '~/utils/dataTestId'
import { HeaderTopBarMiniCartButton, HeaderStickyModal, HeaderTopBarMiniCartModal, HeaderTopBarEmptyList, IHeaderTopBarMiniCartProps } from '~/components/core/layout'
import { useCartStatus } from '~/hooks/cartStatus'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './HeaderTopBarMiniCart.module.scss'

const HeaderTopBarMiniCart = (props: IHeaderTopBarMiniCartProps): ReactElement => {
	const { isEnabled } = props
	const { wwwUrl, cartUuid, productsValue, cartItemList, numberOfItems, productsQuantityMap, cartStatus } = useSelector((state: IRootState) => state.miniCart.data, shallowEqual)
	const [isModalVisible, setIsModalVisible] = useToggle(false)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const sectionRef = useRef<HTMLElement | null>(null)
	const { t } = useTranslation(['header', 'common'])
	const router = useRouter()
	const { changeCartView } = useCartStatus()
	const { isTablet } = useMediaQuery()
	const { urls } = useContext(AppParametersContext)

	const handleClickMiniCartButton = useCallback(async (): Promise<void> => {
		isTablet ? setIsModalVisible() : await router.push(urls.cartView)
	}, [isTablet, urls])

	const handleHideModal = useCallback((): void => {
		setIsModalVisible(false)
	}, [])

	const handleGoToCart = useCallback(async (): Promise<void> => {
		await changeCartView(1, cartStatus)

		handleHideModal()
	}, [wwwUrl])

	const handleGoToFavorites = useCallback(async (): Promise<void> => {
		await router.push(urls.shoppingList)

		handleHideModal()
	}, [urls.shoppingList])

	useClickAway(sectionRef, handleHideModal)
	useKeyPressEvent('Escape', handleHideModal)

	return (
		<section ref={ sectionRef } className={ styles.wrapper }>
			<HeaderTopBarMiniCartButton
				buttonRef={ buttonRef }
				isActive={ isModalVisible }
				numberOfItems={ numberOfItems }
				isDisabled={ !isEnabled }
				onOpen={ handleClickMiniCartButton }
				onBlur={ handleHideModal }
			/>

			{ isEnabled && (
				<HeaderStickyModal
					buttonRef={ buttonRef }
					isOpen={ isModalVisible }
					dataTestId={ DATA_TESTID.HEADER.MODAL_MINI_CART }
					onClose={ handleHideModal }
				>
					{ isEmpty(cartItemList) ? (
						<HeaderTopBarEmptyList
							title={ t('topBar.miniCart.empty.title') }
							subTitle={ t('topBar.miniCart.empty.subTitle') }
							buttonText={ t('goToFavorites', { ns: 'common' }) }
							onBlur={ handleHideModal }
							onClick={ handleGoToFavorites }
						/>
					) : (
						<HeaderTopBarMiniCartModal
							items={ cartItemList }
							summaryCost={ productsValue }
							cartUuid={ cartUuid }
							productsQuantityMap={ productsQuantityMap }
							onGoToCart={ handleGoToCart }
							onBlur={ handleHideModal }
						/>
					) }
				</HeaderStickyModal>
			) }
		</section>
	)
}

export { HeaderTopBarMiniCart }
