import React, { ReactElement } from 'react'
import { size } from 'lodash'

import { HeaderTopBarFavoritesModalWrapper, HeaderTopBarFavoritesModalHeader, HeaderTopBarFavoritesModalItems, HeaderTopBarFavoritesModalActions, IHeaderTopBarFavoritesModalProps } from '~/components/core/layout'

const HeaderTopBarFavoritesModal = (props: IHeaderTopBarFavoritesModalProps): ReactElement => {
	const { productsData, onOpen } = props

	const productRows = size(productsData)

	return (
		<HeaderTopBarFavoritesModalWrapper>
			<HeaderTopBarFavoritesModalHeader />

			<HeaderTopBarFavoritesModalItems items={ productsData } />

			<HeaderTopBarFavoritesModalActions productRows={ productRows } onOpen={ onOpen } />
		</HeaderTopBarFavoritesModalWrapper>
	)
}

export { HeaderTopBarFavoritesModal }
