import { AxiosResponse } from 'axios'

import { ICalendarCartCustomerResponse } from '~/api/dataTypes/calendarService'
import { AppAction } from '~/actions'

export enum ServiceCartCustomerType {
	GET_SERVICE_CART_CUSTOMER = 'serviceCartCustomer/GET_SERVICE_CART_CUSTOMER',
	UPDATE_SERVICE_CART_CUSTOMER_DATA = 'serviceCartCustomer/UPDATE_SERVICE_CART_CUSTOMER_DATA',
	UPDATE_SERVICE_CART_CUSTOMER_CONTACT = 'serviceCartCustomer/UPDATE_SERVICE_CART_CUSTOMER_CONTACT',
	SET_SERVICE_CART_CUSTOMER_YES_INVOICE = 'serviceCartCustomer/SET_SERVICE_CART_CUSTOMER_YES_INVOICE',
	SET_SERVICE_CART_CUSTOMER_NO_INVOICE = 'serviceCartCustomer/SET_SERVICE_CART_CUSTOMER_NO_INVOICE',
}

export type GetServiceCartCustomerActionType = AppAction<ServiceCartCustomerType.GET_SERVICE_CART_CUSTOMER, AxiosResponse<ICalendarCartCustomerResponse>>
export type UpdateServiceCartCustomerDataActionType = AppAction<ServiceCartCustomerType.UPDATE_SERVICE_CART_CUSTOMER_DATA, Pick<ICalendarCartCustomerResponse, 'firstName' | 'lastName' | 'email' | 'companyName' | 'address'>>
export type UpdateServiceCartCustomerContactActionType = AppAction<ServiceCartCustomerType.UPDATE_SERVICE_CART_CUSTOMER_CONTACT, Pick<ICalendarCartCustomerResponse, 'phone'>>
export type SetServiceCartCustomerYesInvoiceActionType = AppAction<ServiceCartCustomerType.SET_SERVICE_CART_CUSTOMER_YES_INVOICE, string>
export type SetServiceCartCustomerNoInvoiceActionType = AppAction<ServiceCartCustomerType.SET_SERVICE_CART_CUSTOMER_NO_INVOICE>

export type ServiceCartCustomerActionType = GetServiceCartCustomerActionType | UpdateServiceCartCustomerDataActionType | UpdateServiceCartCustomerContactActionType | SetServiceCartCustomerYesInvoiceActionType | SetServiceCartCustomerNoInvoiceActionType
