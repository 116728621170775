import React, { ReactElement } from 'react'

import { IHeaderTopBarWrapperProps } from '~/components/core/layout/header'
import { Container } from '~/components/core/layout/container'

import styles from './HeaderTopBarWrapper.module.scss'

const HeaderTopBarWrapper = (props: IHeaderTopBarWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			<Container>
				<div className={ styles.content }>
					{ children }
				</div>
			</Container>
		</div>
	)
}

export { HeaderTopBarWrapper }
