import React, { ReactElement } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useMountedState } from 'react-use'
import { isNull } from 'lodash'

import { IRootState } from '~/state/types'
import { HamburgerMenuUserStoreEmpty, HamburgerMenuUserStoreSelected } from '~/components/core/hamburgerMenu'

const HamburgerMenuUserStore = (): ReactElement => {
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const isMounted = useMountedState()

	if (!isNull(preferredStore) && isMounted()) {
		return (
			<HamburgerMenuUserStoreSelected store={ preferredStore } />
		)
	}

	return (
		<HamburgerMenuUserStoreEmpty />
	)
}

export { HamburgerMenuUserStore }
