import { useEffect } from 'react'
import { useToggle } from 'react-use'

import { IUseMountedData } from '~/hooks/mounted'

export const useMounted = (): IUseMountedData => {
	const [isMounted, setIsMounted] = useToggle(false)

	useEffect(() => {
		setIsMounted(true)

		return () => {
			setIsMounted(false)
		}
	}, [])

	return {
		isMounted,
	}
}
