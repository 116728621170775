export const WORLDS_TYPE_MEGAWORLD = 'MEGA_WORLD'

export const WORLDS_WRAPPER_ID = 'MEGAMENU'

export const TAB_INDEX_ATTRIBUTE = 'tabindex'

export const WORLDS_SUB_CATEGORY_LIST_QUERY = 'div[role="list"]'

export const WORLDS_SELECTED_MAIN_CATEGORY_QUERY = 'a[aria-current="true"]'

export const WORLDS_DEFAULT_TABINDEX = 10

export const WORLDS_NEUTRAL_TABINDEX = 0
