import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { isNull } from 'lodash'

import { IHamburgerMenuMegaMenuItemProps } from '~/components/core/hamburgerMenu'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'

import styles from './HamburgerMenuMegaMenuItem.module.scss'

const HamburgerMenuMegaMenuItem = (props: IHamburgerMenuMegaMenuItemProps): ReactElement => {
	const {
		name, onClick,
		icon = null,
		isLeaf = false,
	} = props
	const { t } = useTranslation(['header'])

	const ariaLabel = `${ t('linkPrefix') }${ t(`menu.megaMenu.${ isLeaf ? 'subNavAriaPrefix' : 'mainNavAriaPrefix' }`) } ${ name }`

	const wrapperClass = classNames(styles.wrapper, {
		[styles.withoutIcon]: isNull(icon),
		[styles.leaf]: isLeaf,
	})

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ wrapperClass }
			ariaLabel={ ariaLabel }
			onClick={ onClick }
		>
			<span className={ styles.name }>
				{ !isNull(icon) && (
					<Icon
						name={ icon }
						color="navy"
						width={ 25 }
						additionalClass={ styles.icon }
					/>
				) }

				<span>
					{ name }
				</span>
			</span>

			<Icon
				name="arrow"
				width={ 7 }
				color="green"
				additionalClass={ styles.arrow }
			/>
		</Button>
	)
}

export { HamburgerMenuMegaMenuItem }
