import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Button } from '~/components/core/button'
import { ICartPreviewSummaryLoyaltyProgramNotLoggedProps } from '~/components/cart/cartPreview'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewSummaryLoyaltyProgramNotLogged.module.scss'

const CartPreviewSummaryLoyaltyProgramNotLogged = (props: ICartPreviewSummaryLoyaltyProgramNotLoggedProps): ReactElement => {
	const { onClick } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const linkClass = classNames(styles.link, {
		[styles.isMozaic]: shouldUseMozaic,
		'mc-button mc-button--bordered': shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<div>
				{ t('preview.aside.discount.domProgram.checkDiscounts') }
			</div>

			<Button
				variant="secondary"
				additionalClass={ linkClass }
				text={ t('preview.aside.discount.domProgram.logIn') }
				ariaLabel={ t('preview.aside.discount.domProgram.checkDiscounts') }
				onClick={ onClick }
			/>
		</div>
	)
}

export { CartPreviewSummaryLoyaltyProgramNotLogged }
