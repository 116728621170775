import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'

import { ISkipContentElement, IUseSkipElements, SkipContentIds, SKIP_CART_ASIDE, SKIP_CART_DELIVERY_METHODS, SKIP_CART_PAYMENT_METHODS, SKIP_FOOTER, SKIP_MAIN_MENU, SKIP_SCROLL_TO_TOP, SKIP_LISTING, SKIP_LISTING_BREADCRUMBS, SKIP_LISTING_FILTERS, SKIP_LISTING_PRODUCTS, SKIP_PRODUCT, SKIP_PRODUCT_ADVICES, SKIP_PRODUCT_BREADCRUMBS, SKIP_PRODUCT_DETAILS, SKIP_PRODUCT_INFO, SKIP_PRODUCT_MEDIA, SKIP_PRODUCT_OPINIONS, SKIP_PRODUCT_SERVICES, SKIP_PRODUCT_SHOP_AVAILABILITY, SKIP_PRODUCT_SIMILAR, SKIP_PRODUCT_SUMMARY, SKIP_FILTERS_SUBCATEGORIES, SKIP_FILTERS_BUTTON, SKIP_TO_FILTERS, SKIP_ADDITIONAL_FOOTER } from '~/hooks/skipElements'

export const useSkipElements = (title?: string, pageType?: string): IUseSkipElements => {
	const { t, i18n } = useTranslation(['header', 'common'])

	const productMainSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_PRODUCT,
			text: t('skipContent.productPage.skipProductInfo', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_BREADCRUMBS,
			text: t('skipContent.skipBreadcrumbs', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_SUMMARY,
			text: t('skipContent.productPage.skipToProductSummary', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_SHOP_AVAILABILITY,
			text: t('skipContent.productPage.skipToProductShopAvailability', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_MEDIA,
			text: t('skipContent.productPage.skipToProductMedia', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_INFO,
			text: t('skipContent.productPage.skipToProductInfo', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_DETAILS,
			text: t('skipContent.productPage.skipToProductDetails', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_OPINIONS,
			text: t('skipContent.productPage.skipToProductOpinions', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_SIMILAR,
			text: t('skipContent.productPage.skipToProductSimilar', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_SERVICES,
			text: t('skipContent.productPage.skipToProductServices', { ns: 'common' }),
		},
		{
			id: SKIP_PRODUCT_ADVICES,
			text: t('skipContent.productPage.skipToProductAdvices', { ns: 'common' }),
		},
		{
			id: SKIP_FOOTER,
			text: t('skipContent.skipToFooter', { ns: 'common' }),
		},
	], [t, i18n])

	const cartDeliveryPreviewGroupsSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_CART_PAYMENT_METHODS,
			text: t('skipContent.skipDelivery', { ns: 'common' }),
		},
	], [t, i18n])

	const cartPreviewDeliveryListWrapperSkip = useMemo((): ISkipContentElement[] => [
		{
			id: '',
			text: t('skipContent.skipDeliveryMethods', { ns: 'common' }),
		},
	], [t, i18n])

	const cartPreviewPaymentSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_CART_ASIDE,
			text: t('skipContent.skipPaymentMethods', { ns: 'common' }),
		},
	], [t, i18n])

	const cartPreviewDiscountSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_ADDITIONAL_FOOTER,
			text: t('skipContent.skipDiscount', { ns: 'common' }),
		},
	], [t, i18n])

	const cartPreviewProductsItemSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_CART_DELIVERY_METHODS,
			text: t('skipContent.skipCartProducts', { ns: 'common' }),
		},
	], [t, i18n])

	const serviceCartSummaryPaymentSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_CART_ASIDE,
			text: t('skipContent.skipPaymentMethods', { ns: 'common' }),
		},
	], [t, i18n])

	const carouselSkip = useMemo((): ISkipContentElement[] => [
		{
			id: '',
			text: `${ t('skipContent.skipCarousel', { ns: 'common' }) } ${ title }`,
		},
	], [t, i18n, title])

	const inspirationsBlockSkip = useMemo((): ISkipContentElement[] => [
		{
			id: '',
			text: t('skipContent.skipInspirations', { ns: 'common' }),
		},
	], [t, i18n])

	const additionalFooterWrapperSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_FOOTER,
			text: t('skipContent.skipNewsletter', { ns: 'common' }),
		},
	], [t, i18n])

	const footerWrapperSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_SCROLL_TO_TOP,
			text: t('skipContent.skipFooter', { ns: 'common' }),
		},
	], [t, i18n])

	const headerSkip = useMemo((): ISkipContentElement[] => [
		{
			id: pageType as SkipContentIds,
			text: t('skipNavigation.skipHeader', { ns: 'header' }),
		},
		{
			id: SKIP_MAIN_MENU,
			text: t('skipNavigation.skipToMenu', { ns: 'header' }),
		},
		{
			id: SKIP_FOOTER,
			text: t('skipNavigation.skipToFooter', { ns: 'header' }),
		},
	], [t, i18n, pageType])

	const newsSectionSkip = useMemo((): ISkipContentElement[] => [
		{
			id: '',
			text: t('skipContent.skipBanner', { ns: 'common' }),
		},
	], [t, i18n])

	const popularProductCategoriesSkip = useMemo((): ISkipContentElement[] => [
		{
			id: '',
			text: t('skipContent.skipCategories', { ns: 'common' }),
		},
	], [t, i18n])

	const servicesBlockSkip = useMemo((): ISkipContentElement[] => [
		{
			id: '',
			text: t('skipContent.skipServices', { ns: 'common' }),
		},
	], [t, i18n])

	const homeSwiperSkip = useMemo((): ISkipContentElement[] => [
		{
			id: '',
			text: t('skipContent.skipBanner', { ns: 'common' }),
		},
	], [t, i18n])

	const productListSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_LISTING,
			text: t('skipContent.productListing.skipListing', { ns: 'common' }),
		},
		{
			id: SKIP_LISTING_BREADCRUMBS,
			text: t('skipContent.skipBreadcrumbs', { ns: 'common' }),
		},
		{
			id: SKIP_LISTING_FILTERS,
			text: t('skipContent.productListing.skipToFilters', { ns: 'common' }),
		},
		{
			id: SKIP_LISTING_PRODUCTS,
			text: t('skipContent.productListing.skipToProducts', { ns: 'common' }),
		},
		{
			id: SKIP_FOOTER,
			text: t('skipContent.skipToFooter', { ns: 'common' }),
		},
	], [t, i18n])

	const filtersListSkip = useMemo((): ISkipContentElement[] => [
		{
			id: SKIP_LISTING_PRODUCTS,
			text: t('skipContent.productListing.skipToProducts', { ns: 'common' }),
		},
		{
			id: SKIP_FILTERS_SUBCATEGORIES,
			text: t('skipContent.filters.skipSubcategories', { ns: 'common' }),
		},
		{
			id: SKIP_TO_FILTERS,
			text: t('skipContent.productListing.skipToFilters', { ns: 'common' }),
		},
		{
			id: SKIP_FILTERS_BUTTON,
			text: t('skipContent.filters.skipToFiltersButton', { ns: 'common' }),
		},
	], [t, i18n])

	return {
		productMainSkip,
		cartDeliveryPreviewGroupsSkip,
		cartPreviewDeliveryListWrapperSkip,
		cartPreviewPaymentSkip,
		cartPreviewProductsItemSkip,
		cartPreviewDiscountSkip,
		serviceCartSummaryPaymentSkip,
		carouselSkip,
		inspirationsBlockSkip,
		additionalFooterWrapperSkip,
		footerWrapperSkip,
		headerSkip,
		newsSectionSkip,
		popularProductCategoriesSkip,
		servicesBlockSkip,
		homeSwiperSkip,
		productListSkip,
		filtersListSkip,
	}
}
