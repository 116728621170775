import { AppAction } from '~/actions'
import { IComparisonState } from '~/state/reducers/comparisonReducer'

export type ComparisonAddItemType = AppAction<ComparisonActionType.ADD_COMPARISON_ITEM, Partial<IComparisonState>>
export type ComparisonRemoveItemType = AppAction<ComparisonActionType.REMOVE_COMPARISON_ITEM, Partial<IComparisonState>>
export type ComparisonClearType = AppAction<ComparisonActionType.CLEAR_COMPARISON>
export type ComparisonClearErrorsType = AppAction<ComparisonActionType.CLEAR_ERRORS>

export enum ComparisonActionType {
	ADD_COMPARISON_ITEM = 'comparison/addComparisonItem',
	REMOVE_COMPARISON_ITEM = 'comparison/removeComparisonItem',
	CLEAR_COMPARISON = 'comparison/clearComparison',
	CLEAR_ERRORS = 'comparison/clearErrors',
}
