import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import { gt, isNil } from 'lodash'

import { IKobiTokenData, ITokenData } from '~/utils/types'

export const getDecodedToken = (token: string): ITokenData => jwtDecode(token)
export const getDecodededKobiToken = (token: string): IKobiTokenData => jwtDecode(token)

export const isValidToken = (token: string): boolean => {
	const { exp } = getDecodedToken(token)
	const currentDate = dayjs().unix()

	return gt(exp, currentDate)
}

export const isValidKobiJwtToken = (token: string | null): boolean => {
	if (isNil(token)) {
		return false
	}

	const { exp } = getDecodededKobiToken(token)
	const currentDate = dayjs().unix()

	return gt(exp, currentDate)
}
