/* eslint-disable react/jsx-newline */
import React, { ForwardedRef, forwardRef, ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { RefCallBack } from 'react-hook-form'

import { Icon } from '~/components/core/icon'
import { IDatePickerCustomMaskedInputProps, MaskedInput } from '~/components/core/form'
import { Button } from '~/components/core/button'

import styles from './DatePickerCustomInput.module.scss'

const DatePickerCustomMaskedInput = forwardRef((props: IDatePickerCustomMaskedInputProps, forwardedRef: ForwardedRef<HTMLInputElement>): ReactElement => {
	const { onCalendarClick, mask, ...restProps } = props
	const { t } = useTranslation(['common'])

	return (
		<div className={ styles.wrapper }>
			{ /* @ts-expect-error: "mask" prop type is not fully compatible */ }
			<MaskedInput
				inputRef={ forwardedRef as RefCallBack }
				mask={ mask }
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...restProps }
			/>

			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ styles.button }
				ariaLabel={ t('datePicker.triggerLabel') }
				onClick={ onCalendarClick }
			>
				<Icon
					name="calendar"
					width={ 16 }
					color="black65"
				/>
			</Button>
		</div>
	)
})

export { DatePickerCustomMaskedInput }
