import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import ReactSelect, { SingleValue } from 'react-select'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { find, isEmpty, isEqual, isUndefined, map } from 'lodash'

import { ILayoutAccountMenuProps, LayoutAccountMenuList, ILayoutAccountMenuSelectOption, LayoutAccountMenuHeaderText } from '~/components/core/layout'
import { RadioIcon } from '~/components/core/form'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useUserMenu } from '~/hooks/userMenu/useUserMenu'
import { useDropdownSelect } from '~/hooks/dropdownSelect'
import { IAccountPanelMenuItem } from '~/hooks/userMenu/types'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { LayoutAccountKobiLink } from '~/components/core/layout/layoutAccount/layoutAccountMenu/layoutAccountKobiLink'

import styles from './LayoutAccountMenu.module.scss'

const LayoutAccountMenu = (props: ILayoutAccountMenuProps): ReactElement | null => {
	const { active } = props
	const { isSmallDesktop } = useMediaQuery()
	const { getAccountPanelMenuSections } = useUserMenu()
	const { push } = useRouter()
	const { guidance, handleFocusAria } = useDropdownSelect<ILayoutAccountMenuSelectOption>()
	const { t } = useTranslation(['account'])
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const menuItemsSections = getAccountPanelMenuSections()
	const menuItems: IAccountPanelMenuItem[] = menuItemsSections.at(0)?.items || []

	const options: ILayoutAccountMenuSelectOption[] = useMemo(() => (
		map(menuItems, (section: IAccountPanelMenuItem): ILayoutAccountMenuSelectOption => ({
			value: section.id,
			label: section.title,
			url: section.url,
		})
		)), [menuItems])

	const value = useMemo((): ILayoutAccountMenuSelectOption | null => {
		const activeElement = find(menuItems, (section: IAccountPanelMenuItem) => isEqual(section.pageType, active))

		if (isUndefined(activeElement)) {
			return null
		}

		return find(options, (option: ILayoutAccountMenuSelectOption) => option.value === activeElement.id) || null
	}, [menuItems, active])

	const renderOptionLabel = useCallback((option: ILayoutAccountMenuSelectOption): ReactElement => {
		const { label } = option

		return (
			<div className={ styles.option }>
				<RadioIcon isChecked={ isEqual(value?.label, label) } />

				<span>
					{ label }
				</span>
			</div>
		)
	}, [value])

	const handleChange = useCallback(async (option: SingleValue<ILayoutAccountMenuSelectOption>): Promise<void> => {
		const { url } = option as ILayoutAccountMenuSelectOption

		if (!isUndefined(url)) {
			await push(url)
		}
	}, [])

	if (isKobiIntegrationEnabled) {
		return (
			<LayoutAccountKobiLink />
		)
	}

	if (isEmpty(menuItemsSections) || isUndefined(menuItemsSections)) {
		return null
	}

	if (isSmallDesktop) {
		return (
			<LayoutAccountMenuList active={ active } menuItemsSections={ menuItemsSections } />
		)
	}

	const selectClass = classNames(styles.select, 'react-select')

	return (
		<>
			<LayoutAccountMenuHeaderText label={ t('menu.yourAccount') } />

			<ReactSelect
				isMulti={ false }
				isSearchable={ false }
				isClearable={ false }
				className={ selectClass }
				classNamePrefix="react-select"
				instanceId="account-menu-navigation"
				components={ {
					IndicatorSeparator: () => null,
				} }
				formatOptionLabel={ renderOptionLabel }
				options={ options }
				defaultValue={ value }
				ariaLiveMessages={ {
					onFocus: handleFocusAria,
					guidance,
				} }
				onChange={ handleChange }
			/>
		</>
	)
}

export { LayoutAccountMenu }
