import React, { ReactElement } from 'react'

import { Image } from '~/components/core/image'
import { ICartPreviewProductsProductBlockImageProps } from '~/components/cart/cartPreview'
import { Link } from '~/components/core/link'

import styles from './CartPreviewProductsProductBlockImage.module.scss'

const CartPreviewProductsProductBlockImage = (props: ICartPreviewProductsProductBlockImageProps): ReactElement => {
	const { product } = props

	const { image: { thumbnailUrl }, name, wwwUrl } = product

	return (
		<Link
			href={ wwwUrl }
			additionalClass={ styles.wrapper }
			ariaLabel={ name }
			tabIndex={ -1 }
		>
			<Image
				priority
				className={ styles.image }
				src={ thumbnailUrl }
				width={ 80 }
				height={ 80 }
				alt={ name }
			/>
		</Link>
	)
}

export { CartPreviewProductsProductBlockImage }
