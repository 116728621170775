import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { ICartItemData } from '~/api/dataTypes/cart'
import { IMergeCartModalListProps, MergeCartModalListItem } from '~/components/core/mergeCartModal'

import styles from './MergeCartModalList.module.scss'

const MergeCartModalList = (props: IMergeCartModalListProps): ReactElement => {
	const { cartItemList, productsQuantityMap } = props

	const renderCartItemList = useCallback((): ReactElement[] => (
		map(cartItemList, (item: ICartItemData) => {
			const { itemId } = item

			return (
				<MergeCartModalListItem
					key={ itemId }
					cartItem={ item }
					productsQuantityMap={ productsQuantityMap }
				/>
			)
		})
	), [cartItemList, productsQuantityMap])

	return (
		<div className={ styles.wrapper }>
			{ renderCartItemList() }
		</div>
	)
}

export { MergeCartModalList }
