import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { useModal } from '~/hooks/modal'
import { PreferredStoreModal } from '~/components/core/preferredStore'
import { Button } from '~/components/core/button'

import styles from './CartPreviewDeliveryPickupStoreNotSelected.module.scss'

const CartPreviewDeliveryPickupStoreNotSelected = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')

	const handleChangeStore = useCallback(() => {
		handleOpen()
	}, [handleOpen])

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.text }>
				{ t('preview.delivery.groups.pickup.noChosenStore') }
			</span>

			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ styles.button }
				ariaLabel={ t('preview.delivery.groups.pickup.chooseStore') }
				onClick={ handleChangeStore }
			>
				<span className={ styles.text }>
					{ t('preview.delivery.groups.pickup.chooseStore') }
				</span>
			</Button>

			<PreferredStoreModal
				isWithCheckPrice
				isOpen={ isOpen }
				onClose={ handleClose }
			/>
		</div>
	)
}

export { CartPreviewDeliveryPickupStoreNotSelected }
