import React, { ReactElement } from 'react'

import { INewsletterWrapperProps } from '~/components/core/newsletter'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './NewsletterWrapper.module.scss'

const NewsletterWrapper = (props: INewsletterWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.CORE.NEWSLETTER }>
			{ children }
		</div>
	)
}

export { NewsletterWrapper }
