import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { Icon } from '~/components/core/icon'
import { IInputPasswordToggleProps } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { useMozaic } from '~/hooks/mozaic'

import styles from './InputPasswordToggle.module.scss'

const InputPasswordToggle = (props: IInputPasswordToggleProps): ReactElement => {
	const {
		isVisible, onClick,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['common', 'form'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const buttonText = !shouldUseMozaic ? '' : isVisible ? t('passwordToggle.hide', { ns: 'form' }) : t('passwordToggle.show', { ns: 'form' })

	const buttonClass = classNames(styles.button, additionalClass, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<Button
			size="inherit"
			variant="neutral"
			additionalClass={ buttonClass }
			role="switch"
			ariaLabel={ t('passwordInput.triggerLabel') }
			tabIndex={ -1 }
			text={ buttonText }
			onClick={ onClick }
		>
			{ !shouldUseMozaic && (
				<Icon
					name={ isVisible ? 'visibilityOff' : 'visibilityOn' }
					width={ 16 }
					additionalClass={ styles.icon }
				/>
			) }
		</Button>
	)
}

export { InputPasswordToggle }
