import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isNil } from 'lodash'

import { ArrowLink } from '~/components/core/arrowLink'
import { getPhoneUrl, IPreferredStoreModalDataProps } from '~/components/core/preferredStore'
import { Link } from '~/components/core/link'
import { useNavigation } from '~/hooks/navigation'

import styles from './PreferredStoreModalData.module.scss'

const PreferredStoreModalData = (props: IPreferredStoreModalDataProps): ReactElement => {
	const { store } = props
	const { t } = useTranslation(['stores'])
	const { getGoogleMapToStoreUrl } = useNavigation()

	const { name, address, informationPointPhone, informationCreditPoint, availableHours } = store
	const { street, zipCode, city } = address
	const { message } = availableHours

	const handleOpenMap = useCallback((): void => {
		window.open(getGoogleMapToStoreUrl(store), '_blank')
	}, [store])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.name }>{ name }</div>

			<div className={ styles.data }>
				<div>
					{ message }
				</div>

				<div>
					{ `${ street }, ${ zipCode } ${ city }` }
				</div>

				<ArrowLink
					text={ t('checkWay') }
					additionalClass={ styles.link }
					onClick={ handleOpenMap }
				/>
			</div>

			<div className={ styles.info }>
				<div>
					{ t('informationPoint') }

					<Link
						isNativeLink
						href={ getPhoneUrl(informationPointPhone) }
						additionalClass={ styles.link }
					>
						{ informationPointPhone }
					</Link>
				</div>

				{ !isNil(informationCreditPoint) && (
					<div>
						{ t('informationCreditPoint') }

						<Link
							isNativeLink
							href={ getPhoneUrl(informationCreditPoint) }
							additionalClass={ styles.link }
						>
							{ informationPointPhone }
						</Link>
					</div>
				) }
			</div>
		</div>
	)
}

export { PreferredStoreModalData }
