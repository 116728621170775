import React, { ReactElement, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useController } from 'react-hook-form'
import { isNil } from 'lodash'

import { ICartPreviewDeliveryNoteForm, MAX_COURIER_ADDITIONAL_NOTE_LENGTH, CartPreviewDeliveryEcommerceAdditionalNoteProps } from '~/components/cart/cartPreview'
import { Textarea } from '~/components/core/form'

import styles from './CartPreviewDeliveryEcommerceAdditionalNote.module.scss'

const CartPreviewDeliveryEcommerceAdditionalNote = (props: CartPreviewDeliveryEcommerceAdditionalNoteProps): ReactElement => {
	const { onSetCourierNote, tmpCourierNote } = props
	const { t, i18n } = useTranslation(['cart'])

	const schema: yup.SchemaOf<ICartPreviewDeliveryNoteForm> = useMemo(() => yup.object().shape({
		additionalNote: yup.string().max(MAX_COURIER_ADDITIONAL_NOTE_LENGTH, t('preview.delivery.noteTooLong')),
	}), [t, i18n])

	const { control, watch, setValue } = useForm<ICartPreviewDeliveryNoteForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			additionalNote: tmpCourierNote,
		},
		mode: 'onChange',
	})

	const { field: { value } } = useController({ control, name: 'additionalNote' })

	const { additionalNote } = watch()

	const handleChangeCourierNote = useCallback((): void => {
		if (!isNil(additionalNote)) {
			onSetCourierNote(additionalNote)
		}
	}, [additionalNote])

	useEffect(() => {
		handleChangeCourierNote()
	}, [additionalNote])

	useEffect(() => {
		setValue('additionalNote', tmpCourierNote)
	}, [tmpCourierNote])

	return (
		<div className={ styles.wrapper }>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<form>
				<Textarea
					control={ control }
					name="additionalNote"
					label={ t('preview.delivery.messageForCourier') }
				/>

				<div className={ styles.counter }>
					{ `${ value?.length }/${ MAX_COURIER_ADDITIONAL_NOTE_LENGTH }` }
				</div>
			</form>
		</div>
	)
}

export { CartPreviewDeliveryEcommerceAdditionalNote }
