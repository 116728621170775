import React, { ReactElement, useCallback } from 'react'
import ReactModal, { Props as ModalProps } from 'react-modal'
import classNames from 'classnames'
import { isEmpty, isNull, noop, isUndefined } from 'lodash'

import { CloseButton } from '~/components/core/closeButton'
import { IModalProps } from '~/components/core/modal'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './Modal.module.scss'

const Modal = (props: IModalProps): ReactElement | null => {
	const {
		isOpen, children,
		onClose = undefined,
		onOpen = noop,
		onAfterClose = noop,
		title = '',
		additionalClass = '',
		dataTestId = '',
		theme = {},
	} = props
	const { isTablet } = useMediaQuery()

	const isCloseDisabled = isUndefined(onClose)

	const handleOpen = useCallback((async (): Promise<void> => {
		await onOpen()
	}), [])

	const handleClose = useCallback(async (): Promise<void> => {
		if (!isCloseDisabled) {
			await onClose()
		}
	}, [isCloseDisabled])

	const handleAfterClose = useCallback((async (): Promise<void> => {
		await onAfterClose()
	}), [onAfterClose])

	const contentClass: ModalProps['className'] = classNames({
		[styles.wrapper]: true,
		[additionalClass]: !isEmpty(additionalClass),
		[styles.withTitle]: !isEmpty(title),
	}, theme.content)

	const overlayStyles: ModalProps['overlayClassName'] = {
		base: classNames(styles.overlay, theme.overlay),
		afterOpen: styles.afterOpen,
		beforeClose: styles.beforeClose,
	}

	const titleClass = classNames(styles.title, theme.title)

	const closeButtonClass = classNames(styles.closeButton, theme.closeButton)

	const closeTimeout: ModalProps['closeTimeoutMS'] = isTablet ? 500 : 0

	if (isNull(children)) {
		return null
	}

	return (
		<ReactModal
			isOpen={ isOpen }
			ariaHideApp={ false }
			overlayClassName={ overlayStyles }
			className={ contentClass }
			closeTimeoutMS={ closeTimeout }
			htmlOpenClassName={ styles.html }
			testId={ dataTestId }
			onAfterOpen={ handleOpen }
			onRequestClose={ handleClose }
			onAfterClose={ handleAfterClose }
		>
			{ !isEmpty(title) && (
				<div className={ titleClass } data-testid={ DATA_TESTID.CORE.MODAL_TITLE }>
					{ title }
				</div>
			) }

			{ !isCloseDisabled && (
				<div className={ closeButtonClass }>
					<CloseButton
						showLabel={ false }
						dataTestId={ DATA_TESTID.CORE.BUTTON_CLOSE_MODAL }
						onClose={ onClose }
					/>
				</div>
			) }

			{ children }
		</ReactModal>
	)
}

export { Modal }
