import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { GOOGLE_PRIVACY_POLICY_URL, GOOGLE_TERMS_OF_USE_URL, IRecaptchaLabelProps } from '~/components/core/recaptchaLabel'
import { Link } from '~/components/core/link'
import { useMozaic } from '~/hooks/mozaic'

import styles from './RecaptchaLabel.module.scss'

const RecaptchaLabel = (props: IRecaptchaLabelProps): ReactElement => {
	const {
		theme = {},
	} = props
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, theme.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const textClass = classNames(styles.text, theme.text)
	const linkClass = classNames(styles.link, theme.link, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<span className={ textClass }>
				{ t('recaptcha.prefixInfo') }
			</span>

			<Link
				isNativeLink
				additionalClass={ linkClass }
				ariaLabel={ t('recaptcha.privacyPolicy') }
				target="_blank"
				href={ GOOGLE_PRIVACY_POLICY_URL }
			>
				{ t('recaptcha.privacyPolicy') }
			</Link>

			<span className={ textClass }>
				{ t('recaptcha.and') }
			</span>

			<Link
				isNativeLink
				additionalClass={ linkClass }
				ariaLabel={ t('recaptcha.termsOfUse') }
				target="_blank"
				href={ GOOGLE_TERMS_OF_USE_URL }
			>
				{ t('recaptcha.termsOfUse') }
			</Link>

			<span className={ textClass }>
				{ t('recaptcha.fromGoogleServices') }
			</span>
		</div>
	)
}

export { RecaptchaLabel }
