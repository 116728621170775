import { TFunction } from 'next-i18next'
import { isEmpty } from 'lodash'

export const getSubmitSearchButtonAriaLabel = (t: TFunction, search: string): string => {
	if (!isEmpty(search)) {
		return t('search.richSearchLabel', { phrase: ` ${ search }.` })
	}

	return t('search.searchLabel')
}
