import { useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { CartStatusType } from '~/api/dataTypes/cart'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { ICartHeaderItem, ICartHeaderData } from '~/hooks/cartHeader'

export const useCartHeader = (cartStatus: CartStatusType): ICartHeaderData => {
	const { t } = useTranslation(['cart'])
	const { urls } = useContext(AppParametersContext)

	const getHeaderItems = useCallback((): ICartHeaderItem[] => [
		{
			id: 'view',
			stepNumber: 1,
			title: t('header.stepOneTitle'),
			status: 'VIEW',
			isActive: cartStatus === 'VIEW',
			isPrevious: cartStatus === 'CUSTOMER' || cartStatus === 'IDENTIFICATION' || cartStatus === 'SUMMARY' || cartStatus === 'FINALIZE',
			isDisabled: cartStatus === 'FINALIZE',
			pageType: 'CART',
			url: urls.cartView,
		},
		{
			id: 'customer',
			stepNumber: 2,
			title: t('header.stepTwoTitle'),
			status: 'CUSTOMER',
			isActive: cartStatus === 'CUSTOMER' || cartStatus === 'IDENTIFICATION',
			isPrevious: cartStatus === 'SUMMARY' || cartStatus === 'FINALIZE',
			isDisabled: cartStatus === 'FINALIZE',
			pageType: 'CART_CUSTOMER',
			url: urls.cartCustomer,
		},
		{
			id: 'summary',
			stepNumber: 3,
			title: t('header.stepThreeTitle'),
			status: 'SUMMARY',
			isActive: cartStatus === 'SUMMARY',
			isPrevious: cartStatus === 'FINALIZE',
			isDisabled: cartStatus === 'FINALIZE',
			pageType: 'CART_SUMMARY',
			url: urls.cartSummary,
		},
		{
			id: 'finalize',
			stepNumber: 4,
			title: t('header.stepFourTitle'),
			status: 'FINALIZE',
			isActive: cartStatus === 'FINALIZE',
			isPrevious: false,
			isDisabled: cartStatus === 'FINALIZE',
			pageType: 'CART_FINALIZE',
			url: urls.cartConfirmation,
		},
	], [cartStatus, urls, t])

	return {
		headerItems: getHeaderItems(),
	}
}
