import { combineReducers } from 'redux'

import { IStoresState } from '~/state/reducers/storesReducer'

import { storesMapInfoWindow } from './storesMapInfoWindowReducer'
import { storesUserLocation } from './storesUserLocationReducer'

const stores = combineReducers<IStoresState>({
	infoWindow: storesMapInfoWindow,
	userLocation: storesUserLocation,
})

export { stores }
