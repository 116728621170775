import { ICartSummaryState } from '~/state/reducers/cartSummaryReducer'

export const cartSummaryInitialState: ICartSummaryState = {
	data: {
		cartStatus: 'SUMMARY',
		cartItemList: [],
		cartDelivery: {
			name: '',
			deliveryValue: 0,
			courierNote: '',
			pickupPoint: {
				id: 0,
				address: {
					city: '',
					street: '',
					zipCode: '',
				},
				cashOnDelivery: false,
				pickupPointCode: '',
				geolocation: {
					latitude: 0,
					longitude: 0,
				},
				type: 'DHL',
				openHours: [],
				typeName: '',
			},
			deliveryStore: {
				pickupPoint: false,
				category: '',
				zipCode: '',
				storeName: '',
				timeSlot: {
					date: 0,
					toTime: 0,
					fromTime: 0,
				},
				categoryName: '',
				shouldRecalculate: false,
				transportCalculation: false,
			},
			pickupPointStore: {
				city: '',
				storeName: '',
				street: '',
				zipCode: '',
				informationPointPhone: '',
			},
			processingTime: '',
		},
		invoice: {
			city: '',
			companyName: '',
			nip: '',
			street: '',
			zipCode: '',
			flatNumber: '',
			lastName: '',
			houseNumber: '',
			firstName: '',
		},
		cartSimple: {
			deliveryValue: 0,
			promotionSummaryValue: 0,
			summaryValue: 0,
			summaryNetValue: 0,
			totalCartValue: 0,
			promotionValue: 0,
			productsNetValue: 0,
			productsValue: 0,
			appliedPromotions: [],
		},
		customer: {
			firstName: '',
			email: '',
			flatNumber: '',
			houseNumber: '',
			lastName: '',
			street: '',
			phone: '',
			zipCode: '',
			city: '',
		},
		contact: {
			customerType: 'PRIVATE',
			companyName: '',
			email: '',
			firstName: '',
			lastName: '',
			phone: '',
		},
		paymentWay: {
			name: '',
			paymentWayType: 'DELIVERY',
		},
		invoiceSelected: false,
		otherContact: false,
		receiptNip: '',
		changedPricesAlertMessage: null,
	},
	isPending: false,
}
