import { AxiosResponse } from 'axios'

import { ICalendarCartCustomerNextStepParams, ICalendarCartCustomerNextStepResponse, ICalendarCartCustomerResponse, ICalendarCartServiceResponse, ICalendarCartNextStepParams, ICalendarCartNextStepResponse, ICalendarDesignerQueryParams, ICalendarDesignerResponse, ICalendarScheduleQueryParams, ICalendarScheduleResponse, ICalendarServiceResponse, ICalendarServiceStoresQueryParams, ICalendarServiceStoreResponse, ICalendarSummaryFinalizeResponse, ICalendarSummaryResponse, ICalendarIdentificationParameters, ICalendarIdentificationResponse, ICalendarServiceConfigurationResponse, ICalendarSummaryFinalizeNextParams } from '~/api/dataTypes/calendarService'
import { request } from '~/api/requests/axios'

export const postCreateCalendarCart = async (lmReference: string): Promise<AxiosResponse<ICalendarCartServiceResponse>> => (
	await request.post('/v1/calendar/cart/create', null, {
		params: {
			lmReference,
		},
		restAuthorization: true,
	})
)

export const getCalendarCart = async (): Promise<AxiosResponse<ICalendarServiceResponse>> => (
	await request.get('/v1/calendar/cart', {
		calendarCartUuid: true,
		restAuthorization: true,
	})
)

export const getCalendarConfiguration = async (): Promise<AxiosResponse<ICalendarServiceConfigurationResponse>> => (
	await request.get('/v1/calendar/configuration', {
		calendarCartUuid: true,
		restAuthorization: true,
	})
)

export const postCalendarCartNext = async (params: ICalendarCartNextStepParams): Promise<AxiosResponse<ICalendarCartNextStepResponse>> => (
	await request.post('/v1/calendar/cart/next', params, {
		calendarCartUuid: true,
		restAuthorization: true,
	})
)

export const getCalendarServicesStores = async (params: ICalendarServiceStoresQueryParams): Promise<AxiosResponse<ICalendarServiceStoreResponse>> => {
	const { lmReference, ...restParams } = params

	return (
		await request.get(`/v1/calendar/services/${ lmReference }/stores`, {
			params: restParams,
			calendarCartUuid: true,
			restAuthorization: true,
		})
	)
}

export const getCalendarSchedule = async (params: ICalendarScheduleQueryParams): Promise<AxiosResponse<ICalendarScheduleResponse>> => (
	await request.get('/v1/calendar/schedule', {
		params,
		calendarCartUuid: true,
		restAuthorization: true,
	})
)

export const getCalendarDesigners = async (params: ICalendarDesignerQueryParams): Promise<AxiosResponse<ICalendarDesignerResponse>> => (
	await request.get('/v1/calendar/designers', {
		params,
		calendarCartUuid: true,
		restAuthorization: true,
	})
)

export const postCalendarIdentification = async (params: ICalendarIdentificationParameters): Promise<AxiosResponse<ICalendarIdentificationResponse>> => (
	await request.post('/v1/calendar/cart/identification', null, {
		params,
		calendarCartUuid: true,
		cartUuid: true,
		restAuthorization: true,
	})
)

export const getCalendarCartCustomer = async (): Promise<AxiosResponse<ICalendarCartCustomerResponse>> => (
	await request.get('/v1/calendar/cart/customer', {
		restAuthorization: true,
	})
)

export const postCalendarCartCustomerNext = async (params: ICalendarCartCustomerNextStepParams): Promise<AxiosResponse<ICalendarCartCustomerNextStepResponse>> => (
	await request.post('/v1/calendar/cart/customer/next', params, {
		restAuthorization: true,
	})
)

export const getCalendarCartSummary = async (): Promise<AxiosResponse<ICalendarSummaryResponse>> => (
	await request.get('/v1/calendar/cart/summary', {
		restAuthorization: true,
	})
)

export const postCalendarCartSummaryNext = async (params: ICalendarSummaryFinalizeNextParams): Promise<AxiosResponse<ICalendarSummaryFinalizeResponse>> => (
	await request.post('/v1/calendar/cart/summary', params, {
		restAuthorization: true,
	})
)
