import React, { ReactElement, useCallback, useContext } from 'react'

import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { HamburgerMenuUserAccountWrapper, HamburgerMenuUserAccountHeader } from '~/components/core/hamburgerMenu'
import { UserMenu } from '~/components/core/userMenu'

import styles from './HamburgerMenuUserAccount.module.scss'

const HamburgerMenuUserAccount = (): ReactElement => {
	const { isUserMenuOpen, handleSetOpen } = useContext(HamburgerMenuContext)

	const handleClose = useCallback(async () => {
		handleSetOpen(false)
	}, [handleSetOpen])

	return (
		<HamburgerMenuUserAccountWrapper>
			<HamburgerMenuUserAccountHeader isUserMenuOpen={ isUserMenuOpen } />

			<UserMenu
				theme={ {
					wrapper: styles.wrapper,
					itemWrapper: styles.itemWrapper,
					button: styles.button,
					title: styles.title,
					logoutWrapper: styles.logoutWrapper,
					logoutButton: styles.logoutButton,
				} }
				onClose={ handleClose }
			/>
		</HamburgerMenuUserAccountWrapper>
	)
}

export { HamburgerMenuUserAccount }
