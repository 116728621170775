import React, { ReactElement, useCallback, useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isEmpty, isUndefined } from 'lodash'

import { UserContext } from '~/providers/userProvider'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { getCartLoyaltyAccountData } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { getCartPreviewLoyaltySelectedReward } from '~/state/reducers/cartPreviewReducer'
import { ICartPreviewDiscountLysSubmitProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysSubmit'
import { CartPreviewDiscountLysRemoveOrEditButton } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysSubmit/cartPreviewDiscountLysRemoveOrEditButton'
import { CartPreviewDiscountLysApplyButton } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysSubmit/cartPreviewDiscountLysApplyButton'
import { IRootState } from '~/state/types'

import styles from './CartPreviewDiscountLysSubmit.module.scss'

const CartPreviewDiscountLysSubmit = (props: ICartPreviewDiscountLysSubmitProps): ReactElement | null => {
	const { selectedRewardElement, inUseReward, onSetCurrentRewards } = props
	const { data: { cart: { promotionCodeUsed: isPromotionCodeUsed } } } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const currentReward = useSelector(getCartPreviewLoyaltySelectedReward, shallowEqual)
	const { isLogged } = useContext(UserContext)
	const { isLysCommunicationError, isLysMigrated, isEditMode, onSetIsSelectedReward } = useContext(LysLoyaltyProviderContext)
	const dispatch: AppDispatch = useDispatch()

	const handleRequestLoyaltyReward = useCallback(async (): Promise<void> => {
		if (isLysMigrated) {
			await dispatch(getCartLoyaltyAccountData())
		}
	}, [isLysMigrated])

	useEffect(() => {
		onSetIsSelectedReward(!isEmpty(currentReward))
	}, [currentReward])

	if (!isLogged || isLysCommunicationError) {
		return null
	}

	if (isPromotionCodeUsed && !isEditMode) {
		return (
			<CartPreviewDiscountLysRemoveOrEditButton additionalClass={ styles.wrapper } onRequestLoyaltyReward={ handleRequestLoyaltyReward } />
		)
	}

	return (
		<CartPreviewDiscountLysApplyButton
			additionalClass={ styles.wrapper }
			selectedRewardElement={ selectedRewardElement || inUseReward }
			isRewardInUse={ !isUndefined(inUseReward) }
			onSetCurrentRewards={ onSetCurrentRewards }
		/>
	)
}

export { CartPreviewDiscountLysSubmit }
