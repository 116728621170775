import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty, isNull } from 'lodash'

import { ICartCustomerDataMainItemCompHeaderProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerDataMainItemCompHeader.module.scss'

const CartCustomerDataMainItemCompHeader = (props: ICartCustomerDataMainItemCompHeaderProps): ReactElement | null => {
	const { nip, corporateName, firstName, lastName } = props
	const { t } = useTranslation(['cart'])

	const isCorporateNameExist = !isNull(corporateName) && !isEmpty(corporateName)

	if (isEmpty(nip)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div>
				{ `${ t('customer.clientData.nipLabel') }: ${ nip }` }
			</div>

			{ isCorporateNameExist && (
				<div>
					{ corporateName }
				</div>
			) }

			<div>
				{ `${ firstName } ${ lastName }` }
			</div>
		</div>
	)
}

export { CartCustomerDataMainItemCompHeader }
