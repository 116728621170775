import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import axios, { AxiosError } from 'axios'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { isEmpty, isEqual, isUndefined, map } from 'lodash'

import { CartCustomerInvoiceBox, CartCustomerInvoiceModal, getCustomerInvoiceData, ICartCustomerInvoiceDeliveryProps, ICartCustomerInvoiceForm, InvoiceExpectationType } from '~/components/cart/cartCustomer'
import { Radio } from '~/components/core/form'
import { useModal } from '~/hooks/modal'
import { useAlert } from '~/hooks/alert'
import { useScrollToTop } from '~/hooks/scrollToTop'
import { useLogError } from '~/hooks/logError'
import { clearInvoiceData, setCartCustomerInvoiceMethodSelected, validateCartCustomerCompanyInvoice, validateCartCustomerPrivateInvoice } from '~/actions/cartCustomer'
import { isCartCustomerInvoiceSetInApiData } from '~/state/reducers/cartCustomerReducer'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { ICustomerData } from '~/api/dataTypes/customer'

import styles from './CartCustomerInvoiceDelivery.module.scss'

const CartCustomerInvoiceDelivery = (props: ICartCustomerInvoiceDeliveryProps): ReactElement => {
	const { totalCostValue, invoice, isDeliveryType, customerAddressId, customerOtherContact, customerData, isAnotherReceiverActive, isInvoiceRequired } = props
	const { invoiceSelectedType } = useSelector((state: IRootState) => state.cartCustomer)
	const isInvoiceSetInApiData = useSelector((state: IRootState) => isCartCustomerInvoiceSetInApiData(state))
	const { t } = useTranslation(['cart'])
	const { isOpen: isOpenInvoice, handleOpen: handleOpenInvoice, handleClose: handleCloseInvoice } = useModal('CartCustomerInvoice')
	const [wasOpenBefore, setWasOpenBefore] = useToggle(false)
	const dispatch: AppDispatch = useDispatch()
	const { newAlert } = useAlert()
	const { scrollToTop } = useScrollToTop()
	const { sendLogError } = useLogError()

	const schema: yup.SchemaOf<ICartCustomerInvoiceForm> = useMemo(() => yup.object().shape({
		invoiceExpect: yup.string().oneOf(['NO', 'YES']).required(),
	}), [])

	const { control, setValue } = useForm<ICartCustomerInvoiceForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			invoiceExpect: isInvoiceSetInApiData ? invoiceSelectedType : '',
		},
	})

	const { field, field: { value }, fieldState: { error } } = useController({ control, name: 'invoiceExpect' })

	const getErrorMessage = (fieldName: keyof ICustomerData, message: string, customerType: 'COMPANY' | 'PRIVATE'): string => {
		if (customerType === 'COMPANY') {
			return `${ t(`customer.invoice.invoiceCompanyForm.${ fieldName }Label`) } - ${ message }`
		}

		return `${ t(`customer.invoice.invoicePrivateForm.${ fieldName }Label`) } - ${ message }`
	}

	const handleCancelInvoice = useCallback(() => {
		handleCloseInvoice()

		if (!isInvoiceSetInApiData) {
			dispatch(setCartCustomerInvoiceMethodSelected(''))
		}
	}, [invoice, isInvoiceSetInApiData, handleCloseInvoice])

	const renderInvoiceBox = useCallback((): ReactElement | null => {
		if (isEqual(value, 'YES' as InvoiceExpectationType)) {
			return (
				<CartCustomerInvoiceBox invoice={ invoice } onEdit={ handleOpenInvoice } />
			)
		}

		return null
	}, [invoice, value])

	const setInvoiceLoggedUserData = useCallback(async (): Promise<void> => {
		if (!isUndefined(customerData)) {
			const invoiceData = getCustomerInvoiceData(customerData)

			if (isEqual(customerData?.customerType, 'COMPANY')) {
				try {
					await dispatch(validateCartCustomerCompanyInvoice(invoiceData))
				} catch (e: unknown) {
					const error = e as AxiosError<IResourceBadRequestException<keyof ICustomerData>>

					if (axios.isAxiosError(error) && !isUndefined(error.response)) {
						const { errors } = error.response.data

						map(errors, (err: IRestExceptionResponse<keyof ICustomerData>) => {
							const { errorMessages, field } = err

							if (!isUndefined(errorMessages) && !isEmpty(errorMessages)) {
								newAlert('danger', getErrorMessage(field, errorMessages[0], 'COMPANY'), 5000)
							}
						})
					} else {
						newAlert('danger', t('customer.invoice.invoiceValidateError'), 5000)
					}

					setValue('invoiceExpect', '')
					scrollToTop()
					sendLogError(e)
				}
			}

			if (isEqual(customerData?.customerType, 'PRIVATE')) {
				try {
					await dispatch(validateCartCustomerPrivateInvoice(invoiceData))
				} catch (e: unknown) {
					const error = e as AxiosError<IResourceBadRequestException<keyof ICustomerData>>

					if (axios.isAxiosError(error) && !isUndefined(error.response)) {
						const { errors } = error.response.data

						map(errors, (err: IRestExceptionResponse<keyof ICustomerData>) => {
							const { errorMessages, field } = err

							if (!isUndefined(errorMessages) && !isEmpty(errorMessages)) {
								newAlert('danger', getErrorMessage(field, errorMessages[0], 'PRIVATE'), 5000)
							}
						})
					} else {
						newAlert('danger', t('customer.invoice.invoiceValidateError'), 5000)
					}

					setValue('invoiceExpect', '')
					scrollToTop()
					sendLogError(e)
				}
			}
		}
	}, [customerAddressId, customerOtherContact, customerData, isAnotherReceiverActive])

	const handleAboveInvoiceData = useCallback(async (): Promise<void> => {
		await setInvoiceLoggedUserData()
	}, [customerAddressId, customerOtherContact, customerData, isAnotherReceiverActive])

	const handleSetIsInvoiceMethodSelected = useCallback(async (value: string): Promise<void> => {
		dispatch(setCartCustomerInvoiceMethodSelected(value))
		setWasOpenBefore(true)
	}, [])

	const handleCleanInvoiceData = useCallback(async (): Promise<void> => {
		await dispatch(clearInvoiceData())
	}, [])

	const radioNoLabel = isInvoiceRequired ? t('customer.invoice.delivery.customerInvoiceData') : t('customer.invoice.noThankYou')
	const radioYesLabel = isInvoiceRequired ? t('customer.invoice.delivery.otherInvoiceData') : t('customer.invoice.yesPlease')

	useEffect(() => {
		if (wasOpenBefore || invoiceSelectedType === '') {
			if (isEqual(value, 'NO' as InvoiceExpectationType)) {
				if (isInvoiceRequired) {
					handleAboveInvoiceData()
				} else {
					handleCleanInvoiceData()
				}

				handleSetIsInvoiceMethodSelected('NO')
			} else if (isEqual(value, 'YES' as InvoiceExpectationType)) {
				handleOpenInvoice()
				handleSetIsInvoiceMethodSelected('YES')
			}
		} else {
			setWasOpenBefore(true)
		}
	}, [value])

	useEffect(() => {
		if (!isInvoiceSetInApiData) {
			dispatch(setCartCustomerInvoiceMethodSelected(''))
		}
	}, [])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.radios }>
				<Radio
					value={ 'NO' as InvoiceExpectationType }
					field={ field }
					error={ error }
					label={ radioNoLabel }
					additionalClass={ styles.radioLabel }
				/>

				<Radio
					value={ 'YES' as InvoiceExpectationType }
					field={ field }
					error={ error }
					label={ radioYesLabel }
					additionalClass={ styles.radioLabel }
				/>

				{ renderInvoiceBox() }

				<CartCustomerInvoiceModal
					invoice={ invoice }
					totalCostValue={ totalCostValue }
					isDeliveryType={ isDeliveryType }
					isOpen={ isOpenInvoice }
					onClose={ handleCloseInvoice }
					onCancel={ handleCancelInvoice }
				/>
			</div>
		</div>
	)
}

export { CartCustomerInvoiceDelivery }
