import { Draft, produce } from 'immer'

import { AppPromiseAction } from '~/actions'
import { ProductCardActionType, ProductCardActionUnionType } from '~/actions/productCard'
import { SUCCESS } from '~/statics'

import { productCardVoteOpinionInitialState as initialState } from './constants'
import { ProductCardVoteOpinionState } from './types'

export const productCardVoteOpinion = produce((draft: Draft<ProductCardVoteOpinionState>, action: AppPromiseAction<ProductCardActionUnionType>): ProductCardVoteOpinionState => {
	switch (action.type) {
		case SUCCESS(ProductCardActionType.ADD_REVIEW_VOTE): {
			const { payload } = action

			return payload.data
		}

		case SUCCESS(ProductCardActionType.DELETE_REVIEW_VOTE): {
			const { payload } = action

			return payload.data
		}

		default:
			return draft
	}
}, initialState)
