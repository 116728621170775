import React, { ReactElement, useCallback } from 'react'
import { isEmpty, map } from 'lodash'

import { IDotMenuListItem, DotMenuListItem, IDotMenuListProps } from '~/components/core/dotMenu'

import styles from './DotMenuList.module.scss'

const DotMenuList = (props: IDotMenuListProps): ReactElement | null => {
	const { items } = props

	const renderList = useCallback((): ReactElement[] => (
		map(items, (item: IDotMenuListItem): ReactElement => (
			<DotMenuListItem key={ item.id } item={ item } />
		))
	), [items])

	if (isEmpty(items)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			{ renderList() }
		</div>
	)
}

export { DotMenuList }
