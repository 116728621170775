import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { HeaderTopBarSearchContext } from '~/providers/headerTopBarSearchProvider'
import { MobileSearchBarContext } from '~/providers/mobileSearchBarProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarSearchIcon.module.scss'

const HeaderTopBarSearchIcon = (): ReactElement => {
	const { handleToggleVisible } = useContext(MobileSearchBarContext)
	const { onOpenSearchModal } = useContext(HeaderTopBarSearchContext)
	const { t } = useTranslation(['header'])

	const handleOpenModal = useCallback((): void => {
		onOpenSearchModal()
		handleToggleVisible()
	}, [onOpenSearchModal, handleToggleVisible])

	return (
		<Button
			hasAriaPopup
			additionalClass={ styles.searchButton }
			variant="neutral"
			size="inherit"
			ariaLabel={ t('search.searchLabel') }
			dataTestId={ DATA_TESTID.HEADER.BUTTON_TOGGLE_SEARCH_BAR }
			onClick={ handleOpenModal }
		>
			<Icon
				name="search"
				color="navy"
				width={ 25 }
				height={ 27 }
				additionalClass={ styles.icon }
			/>
		</Button>
	)
}

export { HeaderTopBarSearchIcon }
