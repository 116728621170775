import { IServiceCartSummaryState } from '~/state/reducers/serviceCartSummaryReducer'

export const serviceCartSummaryInitialState: IServiceCartSummaryState = {
	data: {
		cart: {
			cartStatus: 'PAYMENT_WITH_SUMMARY',
			cartUuid: '',
			service: {
				content: '',
				id: null,
				imageUrl: '',
				lmReference: '',
				name: '',
				price: null,
			},
			customer: null,
			designer: null,
			onlineAppointment: false,
			paymentWay: null,
			price: null,
			slot: null,
			store: null,
		},
		availablePaymentWays: [],
	},
	selectedPaymentWay: null,
	storeDetails: null,
	isPending: false,
	isStorePending: false,
}
