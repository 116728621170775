import React, { ReactElement } from 'react'

import { useMainMenu } from '~/hooks/mainMenu'
import { HamburgerMenuItems, HamburgerMenuItemsWrapper, IHamburgerMenuMainMenu } from '~/components/core/hamburgerMenu'

const HamburgerMenuMainMenu = (props: IHamburgerMenuMainMenu): ReactElement => {
	const { pageType } = props
	const { firstSection, secondSection } = useMainMenu(pageType)

	return (
		<HamburgerMenuItemsWrapper>
			<HamburgerMenuItems items={ firstSection } />

			<HamburgerMenuItems items={ secondSection } />
		</HamburgerMenuItemsWrapper>
	)
}

export { HamburgerMenuMainMenu }
