import { isEqual, isNull } from 'lodash'

import { CustomerLoyaltyRewardType } from '~/api/dataTypes/customerLoyalty'
import { ICartPreviewDiscountLysSelectedReward } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'

export const getCurrentReward = (code: string | null, rawCurrentReward: ICartPreviewDiscountLysSelectedReward, promotionCodeValue?: string): ICartPreviewDiscountLysSelectedReward => {
	if (!isNull(code) && isEqual(rawCurrentReward.code, '')) {
		const type: CustomerLoyaltyRewardType = !isNull(promotionCodeValue) && promotionCodeValue?.endsWith('%') ? 'PERCENT' : 'CURRENCY'

		return {
			code,
			type,
		}
	}

	return rawCurrentReward
}
