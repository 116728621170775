export const parseCookieStoreIdToStoreCode = (cookieStoreId: string): string => {
	const storeId = Number(cookieStoreId)

	switch (true) {
		case storeId < 10:
			return '00' + storeId
		case storeId < 100:
			return '0' + storeId
		default:
			return '' + storeId
	}
}
