import { AppAction } from '~/actions'
import { ICartIdentificationResponse } from '~/api/dataTypes/cart'

export enum CartIdentificationType {
	CART_IDENTIFICATION = 'cart/CART_IDENTIFICATION',
	RESET_CART_IDENTIFICATION = 'cart/RESET_CART_IDENTIFICATION',
	SET_ORDER_AS_GUEST = 'cart/SET_ORDER_AS_GUEST',
}

export type GetCartIdentificationType = AppAction<CartIdentificationType.CART_IDENTIFICATION, ICartIdentificationResponse>
export type ResetCartIdentificationType = AppAction<CartIdentificationType.RESET_CART_IDENTIFICATION>
export type SetOrderAsGuestType = AppAction<CartIdentificationType.SET_ORDER_AS_GUEST, boolean>

export type CartIdentificationActionType = GetCartIdentificationType | ResetCartIdentificationType | SetOrderAsGuestType
