import produce, { Draft } from 'immer'
import { isEmpty } from 'lodash'

import { IMiniCartState } from '~/state/reducers/miniCartReducer'
import { AppPromiseAction } from '~/actions'
import { CartActionType, MiniCartActionType } from '~/actions/cart'
import { REQUEST, FAILURE, SUCCESS } from '~/statics'
import { AuthActionType, AuthAdeoActionType } from '~/actions/auth'
import { IAddToCartResponse } from '~/api/dataTypes/cart'

import { miniCartInitialState as initialState } from './constants'

const miniCart = produce((draft: Draft<IMiniCartState>, action: AppPromiseAction<MiniCartActionType>): IMiniCartState => {
	switch (action.type) {
		case REQUEST(CartActionType.CART_CALCULATE):
		case REQUEST(CartActionType.GET_CART_SIMPLE):
		case REQUEST(CartActionType.REMOVE_PRODUCT_FROM_CART):
		case REQUEST(AuthActionType.SET_LOGOUT):
			draft.isPending = true

			return draft
		case SUCCESS(CartActionType.GET_CART_SIMPLE):
		case SUCCESS(CartActionType.REMOVE_PRODUCT_FROM_CART):
		case SUCCESS(CartActionType.CART_MERGE):
		case SUCCESS(CartActionType.TRANSFER_PRODUCTS_TO_CART):
			if (isEmpty(action.payload.data)) {
				draft.data = initialState.data

				return draft
			}

			draft.data = action.payload.data
			draft.isPending = false

			return draft
		case SUCCESS(CartActionType.SET_QUANTITY_PRODUCT_IN_CART):
		case SUCCESS(CartActionType.ADD_PRODUCT_TO_CART):
			draft.data = (action.payload.data as IAddToCartResponse).simpleCart
			draft.addedToCart = (action.payload.data as IAddToCartResponse).productSimple
			draft.isPending = false

			return draft
		case SUCCESS(CartActionType.CART_CALCULATE):
			draft.data = { ...action.payload.data.cart, totalCartValue: action.payload.data.cart.totalCostValue }
			draft.isPending = false

			return draft
		case SUCCESS(AuthActionType.SET_LOGOUT):
		case SUCCESS(AuthAdeoActionType.SET_ADEO_LOGOUT):
			return initialState
		case FAILURE(CartActionType.CART_CALCULATE):
		case FAILURE(CartActionType.GET_CART_SIMPLE):
		case FAILURE(CartActionType.REMOVE_PRODUCT_FROM_CART):
		case FAILURE(AuthActionType.SET_LOGOUT):
			draft.isPending = false

			return draft
		default:
			return draft
	}
}, initialState)

export { miniCart }
