import React, { ReactElement, useRef } from 'react'
import classNames from 'classnames'

import { IStickyBlockProps, StickyElement } from '~/components/core/stickyBlock'

import styles from './StickyBlock.module.scss'

const StickyBlock = (props: IStickyBlockProps): ReactElement => {
	const {
		children,
		theme = {},
	} = props
	const ref = useRef<HTMLDivElement | null>(null)

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div ref={ ref } className={ wrapperClass }>
			<StickyElement wrapperRef={ ref } additionalClass={ theme.elementWrapper }>
				{ children }
			</StickyElement>
		</div>
	)
}

export { StickyBlock }
