import React, { ReactElement } from 'react'

import { Image } from '~/components/core/image'
import { IMergeCartModalListItemProps } from '~/components/core/mergeCartModal'
import { usePrice } from '~/hooks/price'

import styles from './MergeCartModalListItem.module.scss'

const MergeCartModalListItem = (props: IMergeCartModalListItemProps): ReactElement => {
	const { cartItem, productsQuantityMap } = props
	const { priceFormat } = usePrice()

	const { saleUnitName, valueForLine, productSimple: { lmReference, name, image: { thumbnailUrl } } } = cartItem

	return (
		<div className={ styles.wrapper }>
			<Image
				src={ thumbnailUrl }
				width={ 80 }
				height={ 80 }
				alt={ name }
			/>

			<div className={ styles.aside }>
				<span className={ styles.title }>
					{ name }
				</span>

				<div className={ styles.details }>
					<span className={ styles.quantity }>
						{ `${ productsQuantityMap[lmReference] } ${ saleUnitName }` }
					</span>

					<span className={ styles.price }>
						{ priceFormat(valueForLine, { precision: 2 }) }
					</span>
				</div>
			</div>
		</div>
	)
}

export { MergeCartModalListItem }
