import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { IHeaderTopBarSearchBarLoaderProps } from '~/components/core/layout'
import { Loader } from '~/components/core/loader'

import styles from './HeaderTopBarSearchBarLoader.module.scss'

const HeaderTopBarSearchBarLoader = (props: IHeaderTopBarSearchBarLoaderProps): ReactElement | null => {
	const { isLoading, isModalOpen } = props

	if (!isLoading) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.modal]: isModalOpen,
	})

	return (
		<div className={ wrapperClass }>
			<Loader theme={ { loader: styles.loader } } />
		</div>
	)
}

export { HeaderTopBarSearchBarLoader }
