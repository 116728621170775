import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { IProductBlockPriceProps } from '~/components/core/productBlock'
import { ProductPrice } from '~/components/core/productPrice'

import styles from './ProductBlockPrice.module.scss'

const ProductBlockPrice = (props: IProductBlockPriceProps): ReactElement => {
	const { price, promotionLabelCode, additionalClass } = props

	const { basePrice, basePriceUnit, newPrice, newPricePromoLabel, omnibusPrice } = price

	const priceClass = classNames(styles.price, additionalClass)

	return (
		<div className={ priceClass }>
			<ProductPrice
				price={ newPrice }
				priceStrikethrough={ basePrice }
				priceUnit={ basePriceUnit }
				pricePromoLabel={ newPricePromoLabel }
				promotionLabelCode={ promotionLabelCode }
				omnibusPrice={ omnibusPrice }
			/>
		</div>
	)
}

export { ProductBlockPrice }
