import { AppAction } from '~/actions'
import { ICalendarIdentificationResponse } from '~/api/dataTypes/calendarService'

export enum ServiceCartIdentificationType {
	SERVICE_CART_IDENTIFICATION = 'serviceCartIdentification/SERVICE_CART_IDENTIFICATION',
	RESET_SERVICE_CART_IDENTIFICATION = 'serviceCartIdentification/RESET_SERVICE_CART_IDENTIFICATION',
	GET_SERVICE_CART_CALENDAR_UUID = 'serviceCartIdentification/GET_SERVICE_CART_CALENDAR_UUID',
}

export type GetServiceCartIdentificationType = AppAction<ServiceCartIdentificationType.SERVICE_CART_IDENTIFICATION, ICalendarIdentificationResponse>
export type ResetServiceCartIdentificationType = AppAction<ServiceCartIdentificationType.RESET_SERVICE_CART_IDENTIFICATION>
export type GetServiceCartCalendarUuidType = AppAction<ServiceCartIdentificationType.GET_SERVICE_CART_CALENDAR_UUID, string | null>

export type ServiceCartIdentificationActionType = GetServiceCartIdentificationType | ResetServiceCartIdentificationType | GetServiceCartCalendarUuidType
