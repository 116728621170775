import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import Script from 'next/script'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { isNull, isUndefined } from 'lodash'

import { IInPostData } from '~/api/dataTypes/inpost'
import { IInPostGeowidgetProps } from '~/components/core/inPostGeowidget'
import { vars } from '~/statics'

import { INPOST_GEOWIDGET_SELECT_POINT_EVENT_NAME } from './constants'

import styles from './InPostGeowidget.module.scss'

const InPostGeowidget = (props: IInPostGeowidgetProps): ReactElement => {
	const {
		config, pickupPointData,
		language = 'pl',
		theme = {},
		onSetPickupPoint,
	} = props
	const widgetRef = useRef<HTMLElement | null>(null)
	const { inPost: { geoWidgetToken, geoWidgetDomain } } = vars
	const { t } = useTranslation(['cart'])

	const handleInitialize = useCallback(((event: CustomEvent<IInPostData>): void => {
		const { api } = event.detail

		if (!isNull(pickupPointData) && pickupPointData.type === 'INPOST') {
			api.showPoint(pickupPointData.pickupPointCode)
		}

	}) as EventListener, [pickupPointData])

	const handleSelectPoint = useCallback(((event: CustomEvent<IInPostData>): void => {
		const { detail: { name } } = event

		onSetPickupPoint({
			type: 'INPOST',
			code: name,
		})
	}) as EventListener, [onSetPickupPoint])

	const renderLabel = useCallback(() => {
		if (isUndefined(pickupPointData?.pickupPointCode)) {
			return (
				<div className={ styles.emptyLabel } />
			)
		}

		return (
			<span dangerouslySetInnerHTML={ { __html: t('preview.delivery.pickupPoints.selectedInpostPoint', { pointName: pickupPointData?.pickupPointCode }) } } className={ styles.label } />
		)

	}, [pickupPointData])

	useEffect(() => {
		document.addEventListener(INPOST_GEOWIDGET_SELECT_POINT_EVENT_NAME, handleSelectPoint)

		widgetRef.current?.addEventListener('inpost.geowidget.init', handleInitialize)

		return () => {
			document.removeEventListener(INPOST_GEOWIDGET_SELECT_POINT_EVENT_NAME, handleSelectPoint)

			widgetRef.current?.removeEventListener('inpost.geowidget.init', handleInitialize)
		}
	}, [])

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div className={ wrapperClass }>
			<link rel="stylesheet" href={ `${ geoWidgetDomain }/inpost-geowidget.css` } />

			<Script
				defer
				id="inpost-geowidget"
				strategy="lazyOnload"
				src={ `${ geoWidgetDomain }/inpost-geowidget.js` }
			/>

			{ renderLabel() }

			<div className={ styles.widget }>
				<inpost-geowidget
					ref={ widgetRef }
					onpoint={ INPOST_GEOWIDGET_SELECT_POINT_EVENT_NAME }
					token={ geoWidgetToken }
					language={ language }
					config={ config }
				/>
			</div>
		</div>
	)
}

export { InPostGeowidget }
