import React, { ReactElement } from 'react'

import { Image } from '~/components/core/image'
import { IProductBlockMiniImageProps } from '~/components/core/productBlock'
import { Link } from '~/components/core/link'

import styles from './ProductBlockMiniImage.module.scss'

const ProductBlockMiniImage = (props: IProductBlockMiniImageProps): ReactElement => {
	const { name, image, url } = props

	const { thumbnailUrl } = image

	return (
		<div className={ styles.wrapper }>
			<Link href={ url } tabIndex={ -1 }>
				<Image
					src={ thumbnailUrl }
					width={ 80 }
					height={ 80 }
					alt={ name }
					loading="lazy"
					className={ styles.image }
				/>
			</Link>
		</div>
	)
}

export { ProductBlockMiniImage }
