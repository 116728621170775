import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual, isEmpty } from 'lodash'

import { useModal } from '~/hooks/modal'
import { getCartCheckAvailabilityData } from '~/actions/cart'
import { Alert } from '~/components/core/notifications'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'
import { CartPreviewDeliverySplit, CartPreviewDeliveryNotAvailable, ICartPreviewDeliveryModalProps } from '~/components/cart/cartPreview'
import { getCartPreviewItemsAvailable, getCartPreviewItemsNotAvailable } from '~/state/reducers/cartPreviewReducer'
import { AppDispatch } from '~/state/store'

import styles from './CartPreviewDeliveryModal.module.scss'

const CartPreviewDeliveryModal = (props: ICartPreviewDeliveryModalProps): ReactElement | null => {
	const {
		availabilityStatus, deliveryWayId,
		isDisabled = false,
	} = props
	const itemsAvailable = useSelector(getCartPreviewItemsAvailable)
	const itemsNotAvailable = useSelector(getCartPreviewItemsNotAvailable)
	const { isOpen, handleOpen, handleClose } = useModal('DeliverySplitDetails')
	const dispatch: AppDispatch = useDispatch()
	const { t } = useTranslation(['cart', 'common'])

	const isDeliveryWarningVisible = !isEqual(availabilityStatus, 'ALL_AVAILABLE') && !isDisabled

	const handleShowDetails = useCallback(async (): Promise<void> => {
		await dispatch(getCartCheckAvailabilityData(deliveryWayId))
		handleOpen()
	}, [deliveryWayId])

	const renderModal = useCallback((): ReactElement | null => {
		switch (availabilityStatus) {
			case 'SOME_UNAVAILABLE':
				if (isEmpty(itemsAvailable)) {
					return (
						<CartPreviewDeliveryNotAvailable
							itemsNotAvailable={ itemsNotAvailable }
							isOpen={ isOpen }
							onClose={ handleClose }
						/>
					)
				}

				return (
					<CartPreviewDeliverySplit
						itemsAvailable={ itemsAvailable }
						itemsNotAvailable={ itemsNotAvailable }
						isOpen={ isOpen }
						onClose={ handleClose }
					/>
				)
			case 'NONE':
				return (
					<CartPreviewDeliveryNotAvailable
						itemsNotAvailable={ itemsNotAvailable }
						isOpen={ isOpen }
						onClose={ handleClose }
					/>
				)
			default:
				return null
		}
	}, [availabilityStatus, itemsAvailable, itemsNotAvailable, isOpen])

	if (!isDeliveryWarningVisible) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<Alert
				icon="warning"
				type="warning"
				canClose={ false }
			>
				{ t('preview.warnings.delivery.prefix') }

				<Button
					text={ t('preview.warnings.delivery.details') }
					variant="neutral"
					size="inherit"
					additionalClass={ styles.link }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_DELIVERY_SPLIT_DETAILS }
					onClick={ handleShowDetails }
				/>
			</Alert>

			{ renderModal() }
		</div>
	)
}

export { CartPreviewDeliveryModal }
