import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { IHeaderTopBarPreferredStoreNotSelectedProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarPreferredStoreNotSelected.module.scss'

const HeaderTopBarPreferredStoreNotSelected = (props: IHeaderTopBarPreferredStoreNotSelectedProps): ReactElement => {
	const { onClick, onBlur } = props
	const { t } = useTranslation(['common'])

	return (
		<section className={ styles.wrapper }>
			<header className={ styles.caption }>
				<span>
					{ t('preferredShop.notSelected') }
				</span>
			</header>

			<Button
				isReverse
				variant="secondary"
				text={ t('preferredShop.chooseStore') }
				additionalClass={ styles.button }
				dataTestId={ DATA_TESTID.HEADER.BUTTON_OPEN_NOT_SELECTED_PREFERRED_STORE_MODAL }
				onClick={ onClick }
				onBlur={ onBlur }
			/>
		</section>
	)
}

export { HeaderTopBarPreferredStoreNotSelected }
