import React, { ReactElement, useCallback, useState } from 'react'
import { MarkerF, InfoWindowF } from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { isFunction, isUndefined } from 'lodash'

import { IGoogleMapsMarkerInfoWindowProps, IGoogleMapMarkerInstanceType } from '~/components/core/googleMaps'

import styles from './GoogleMapsMarkerInfoWindow.module.scss'

const GoogleMapsMarkerInfoWindow = (props: IGoogleMapsMarkerInfoWindowProps): ReactElement => {
	const {
		marker, icon, onLoad, onClick, infoWindow, onCloseInfoWindow,
		isInfoWindowOpen = false,
		clusterer = undefined,
	} = props
	const [anchor, setAnchor] = useState<IGoogleMapMarkerInstanceType | undefined>(undefined)

	const { position, zIndex, title } = marker

	const handleLoadMarker = useCallback((googleMarker: IGoogleMapMarkerInstanceType) => {
		setAnchor(googleMarker)
		onLoad(marker)
	}, [onLoad, marker])

	const handleCloseInfoWindow = async (): Promise<void> => {
		if (isFunction(onCloseInfoWindow)) {
			onCloseInfoWindow()
		}
	}

	return (
		<div className={ styles.wrapper }>
			<MarkerF
				position={ position }
				icon={ icon }
				title={ title }
				zIndex={ zIndex }
				clusterer={ clusterer as Clusterer }
				onLoad={ handleLoadMarker }
				onClick={ onClick }
			>
				{ isInfoWindowOpen && !isUndefined(anchor) && (
					<div className={ styles.infoWindow }>
						<InfoWindowF anchor={ anchor } onCloseClick={ handleCloseInfoWindow }>
							{ infoWindow }
						</InfoWindowF>
					</div>
				) }
			</MarkerF>
		</div>
	)
}

export { GoogleMapsMarkerInfoWindow }
