import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './CartPreviewSummaryDiscountLegend.module.scss'

const CartPreviewSummaryDiscountLegend = (): ReactElement => {
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			{ t('preview.aside.discount.legend') }
		</div>
	)
}

export { CartPreviewSummaryDiscountLegend }
