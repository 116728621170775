import React, { ReactElement } from 'react'
import Script from 'next/script'

import { vars } from '~/statics'

export const GoogleRecaptchaScript = (): ReactElement => {
	return (
		<Script
			id="google-recaptcha-v3-script"
			strategy="afterInteractive"
			src={ `https://www.google.com/recaptcha/api.js?render=${ vars.google.reCaptchaSiteKey }` }
		/>
	)
}
