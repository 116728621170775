import React, { ReactElement, useContext } from 'react'
import NextLink from 'next/link'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { includes, isEmpty, isNil, noop } from 'lodash'

import { ILinkProps } from '~/components/core/link'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './Link.module.scss'

const ROUTE_PARTS_TO_NOT_FORCE_RELOAD = ['/klient', '/inspiracje']

const Link = (props: ILinkProps): ReactElement | null => {
	const {
		href, target,
		children = null,
		linkRef = null,
		passHref = false,
		ariaLabel: rawAriaLabel = '',
		isNativeLink = false,
		dataTestId = '',
		itemProp = '',
		additionalClass = '',
		hasAriaCurrent = false,
		rel = '',
		tabIndex = 0,
		canDownload = false,
		isAriaHidden = false,
		theme = {},
		style = {},
		onClick = noop,
		onBlur = noop,
		onFocus = noop,
		onMouseOver = noop,
		onKeyDown = noop,
		...restProps
	} = props
	const { t } = useTranslation(['header'])
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const ariaLabel = isEmpty(rawAriaLabel) ? rawAriaLabel : `${ t('linkPrefix') } ${ rawAriaLabel }`

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	const linkWrapperClass = classNames(styles.link, {
		[additionalClass]: !isEmpty(additionalClass),
	}, theme.link)

	if (isNil(href)) {
		return null
	}

	const shouldForcePageReload = !includes(ROUTE_PARTS_TO_NOT_FORCE_RELOAD, href) && isKobiIntegrationEnabled

	return isNativeLink || shouldForcePageReload ? (
		<a
			ref={ linkRef }
			href={ href }
			download={ canDownload }
			className={ wrapperClass }
			aria-label={ ariaLabel }
			aria-hidden={ isAriaHidden }
			data-testid={ dataTestId }
			itemProp={ itemProp }
			aria-current={ hasAriaCurrent }
			tabIndex={ tabIndex }
			style={ style }
			rel={ rel }
			target={ target }
			onClick={ onClick }
			onBlur={ onBlur }
			onFocus={ onFocus }
			onMouseOver={ onMouseOver }
			onKeyDown={ onKeyDown }
			// eslint-disable-next-line react/jsx-props-no-spreading
			{ ...restProps }
		>
			<span aria-hidden="true" className={ linkWrapperClass }>
				{ children }
			</span>
		</a>
	) : (
		<NextLink
			ref={ linkRef }
			href={ href }
			download={ canDownload }
			passHref={ passHref }
			aria-label={ ariaLabel }
			aria-hidden={ isAriaHidden }
			data-testid={ dataTestId }
			itemProp={ itemProp }
			aria-current={ hasAriaCurrent }
			tabIndex={ tabIndex }
			rel={ rel }
			className={ wrapperClass }
			style={ style }
			target={ target }
			onClick={ onClick }
			onBlur={ onBlur }
			onFocus={ onFocus }
			onMouseOver={ onMouseOver }
			onKeyDown={ onKeyDown }
			// eslint-disable-next-line react/jsx-props-no-spreading
			{ ...restProps }
		>
			<span aria-hidden="true" className={ linkWrapperClass }>
				{ children }
			</span>
		</NextLink>
	)
}

export { Link }
