import { AxiosResponse } from 'axios'

import { IAuthenticationResult, IExtractTokensRequest, IExtractTokensResult, IRefreshTokensRequest, IRefreshTokensResult } from '~/api/dataTypes/authAdeo'
import { request } from '~/api/requests/axios'

export const getAuthenticate = async (callback: string): Promise<AxiosResponse<IAuthenticationResult>> => (
	await request.get('/v1/authentication-module/authenticate', {
		service: 'AUTH_SERVICE',
		params: {
			callback,
		},
	})
)

export const postExtractTokens = async (params: IExtractTokensRequest): Promise<AxiosResponse<IExtractTokensResult>> => (
	await request.post('/v1/authentication-module/extract-tokens', params, {
		service: 'AUTH_SERVICE',
	})
)

export const postRefreshTokens = async (params: IRefreshTokensRequest): Promise<AxiosResponse<IRefreshTokensResult>> => (
	await request.post('/v1/authentication-module/refresh', params, {
		service: 'AUTH_SERVICE',
	})
)
