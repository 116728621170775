import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isNil } from 'lodash'

import { IProductCardResponse, IAvailableProductQuantityResponse, IProductAllReviewsResponse, IProductAddReviewRequest, IProductReviewVoteResponse, IProductSimpleResponse } from '~/api/dataTypes/product'
import { request } from '~/api/requests/axios'
import { getNoCacheParam } from '~/utils/urls'
import { IGetProductParams } from '~/api/requests/types'

export const getProduct = async (params: IGetProductParams): Promise<AxiosResponse<IProductCardResponse>> => {
	const {
		lmReference,
		storeCode = '',
		isInternal = true,
		noCache = '',
	} = params

	const requestConfig: AxiosRequestConfig = {
		isInternal,
		withStoreCode: true,
		params: getNoCacheParam(noCache),
	}

	if (!isNil(storeCode)) {
		requestConfig.headers = {
			storeCode,
		}
	}

	return await request.get(`/v1/products/${ lmReference }`, requestConfig)
}

export const getProductStoreAvailableQuantity = async (lmReference: string): Promise<AxiosResponse<IAvailableProductQuantityResponse>> => (
	await request.get(`/v1/products/${ lmReference }/store/available-quantity`, { withStoreCode: true })
)

export const getProductReviews = async (lmReference: string, params: { page?: number; size?: number; noCache?: string | string[] }, isInternal: boolean = false): Promise<AxiosResponse<IProductAllReviewsResponse>> => (
	await request.get(`/v1/products/${ lmReference }/review`, { params, isInternal })
)

export const postProductReview = async (lmReference: string, params: IProductAddReviewRequest): Promise<AxiosResponse<void>> => (
	await request.post(`/v1/products/${ lmReference }/review`, params, {
		restAuthorization: true,
	})
)

export const putProductReviewVote = async (reviewId: number, userUuid?: string | null): Promise<AxiosResponse<IProductReviewVoteResponse>> => {
	const requestConfig: AxiosRequestConfig = {}

	if (!isNil(userUuid)) {
		requestConfig.headers = {
			userUuid,
		}
	}

	return await request.put(`/v1/products/review/${ reviewId }/vote`, undefined, requestConfig)
}
// userUuid as header
export const deleteProductReviewVote = async (reviewId: number, userUuid: string): Promise<AxiosResponse<IProductReviewVoteResponse>> => {
	const requestConfig: AxiosRequestConfig = {}

	if (!isNil(userUuid)) {
		requestConfig.headers = {
			userUuid,
		}
	}

	return await request.delete(`/v1/products/review/${ reviewId }/vote`, requestConfig)
}

export const getProductsSimple = async (query: string): Promise<AxiosResponse<IProductSimpleResponse>> => {
	return await request.get(`/v1/products/simple?${ query }`, {
		withStoreCode: true,
	})
}
