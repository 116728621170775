import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import axios, { AxiosError } from 'axios'
import SlideDown from 'react-slidedown'
import { isEmpty, isEqual, isNull, isUndefined } from 'lodash'

import { UserContext } from '~/providers/userProvider'
import { ICartPreviewSummaryDiscountContentProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountContent'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useModal } from '~/hooks/modal'
import { CartPreviewSummaryDiscountModal } from '~/components/cart/cartPreview'
import { CartPreviewDiscountFormContent } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewDiscountFormContent'
import { CartPreviewDiscountDynamicLegend } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewDiscountDynamicLegend'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { AppDispatch } from '~/state/store'
import { getCartPreviewData, getIsPromotionEcommerce, getIsPromotionLoyalty } from '~/state/reducers/cartPreviewReducer'
import { CartPreviewDiscountLysLegend } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLegend'
import { CartPreviewDiscountLysContent } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysContent'
import { useLogError } from '~/hooks/logError'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { useCartPreviewCalculate } from '~/hooks/cartPreviewCalculate'
import { getCartLoyaltyAccountData, selectCartLoyaltyAccountReward } from '~/actions/cart'
import { WatermarkPlaceholder } from '~/components/core/watermarkPlaceholder'

const CartPreviewSummaryDiscountContent = (props: ICartPreviewSummaryDiscountContentProps): ReactElement => {
	const { isOpenDiscountModal, isClosed, isStepZero, onToggleActivity } = props
	const { cart: { promotionCodeUsed: isPromotionCodeUsed, promotionCode } } = useSelector(getCartPreviewData, shallowEqual)
	const { isLoading, onSetLysCommunicationError } = useContext(LysLoyaltyProviderContext)
	const { loyaltyProgram: { lysIntegrationEnabled: isLysIntegrationEnabled } } = useContext(AppParametersContext)
	const isLoyalty = useSelector(getIsPromotionLoyalty, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const { isLogged } = useContext(UserContext)
	const [ currentReward, setCurrentReward ] = useState<string>('')
	const { handleClose: handleClosePasswordModal } = useModal('DOMProgramPassword')
	const { sendLogError } = useLogError()
	const { isTablet } = useMediaQuery()
	const { onSetPromotionCode } = useCartPreviewCalculate()
	const dispatch: AppDispatch = useDispatch()

	const hasPromotion = isPromotionCodeUsed && !isNull(promotionCode) && !isEmpty(promotionCode)

	const handleCloseMobileDiscountModal = useCallback((): void => {
		handleClosePasswordModal()
		onToggleActivity()
	}, [isOpenDiscountModal, onToggleActivity, handleClosePasswordModal])

	const handleGetLysLoyalty = useCallback(async (): Promise<void> => {
		if (isLysIntegrationEnabled && isLogged) {
			(async () => {
				try {
					onSetLysCommunicationError(false)

					await dispatch(getCartLoyaltyAccountData())
				} catch (e: unknown) {
					const error = e as AxiosError

					sendLogError(e)

					if (axios.isAxiosError(error) && !isUndefined(error.response) && isEqual(error.response.status, 503)) {
						// TODO: check communication error code with BE :)
						onSetLysCommunicationError(true)
					}
				}
			})()
		}
	}, [isLysIntegrationEnabled, isLogged, onSetLysCommunicationError])

	const handleSetLysReward = useCallback(async (): Promise<void> => {
		if (!isEmpty(currentReward)) {
			(async () => {
				if (isLoyalty) {
					await dispatch(selectCartLoyaltyAccountReward(currentReward))
				} else if (isEcommerce) {
					await onSetPromotionCode(currentReward)
				}
			})()
		}
	}, [currentReward, isLoyalty, isEcommerce, onSetPromotionCode])

	const renderLysLoyalty = useCallback((): ReactElement => {
		if (isLoading) {
			return (
				<WatermarkPlaceholder text="" isLoading={ isLoading }>
					<CartPreviewDiscountLysLegend />

					{ !isStepZero && (
						<CartPreviewDiscountLysContent />
					) }
				</WatermarkPlaceholder>
			)
		}

		return (
			<>
				<CartPreviewDiscountLysLegend />

				{ !isStepZero && (
					<CartPreviewDiscountLysContent />
				) }
			</>
		)
	}, [isLoading, isStepZero])

	useEffect(() => {
		(async () => {
			await handleGetLysLoyalty()

			if (hasPromotion && (isLoyalty || isEcommerce)) {
				setCurrentReward(promotionCode)
			}
		})()
	}, [])

	useEffect(() => {
		(async () => {
			await handleSetLysReward()
		})()
	}, [currentReward])

	if ((isLysIntegrationEnabled || isStepZero) && isTablet) {
		return (
			<SlideDown closed={ isClosed }>
				{ renderLysLoyalty() }
			</SlideDown>
		)
	}

	if (isLysIntegrationEnabled || isStepZero) {
		return (
			<CartPreviewSummaryDiscountModal
				isLogged={ isLogged }
				isOpen={ isOpenDiscountModal }
				isStepZero={ isStepZero }
				onClose={ handleCloseMobileDiscountModal }
			>
				{ renderLysLoyalty() }
			</CartPreviewSummaryDiscountModal>
		)
	}

	if (isTablet) {
		return (
			<SlideDown closed={ isClosed }>
				<CartPreviewDiscountDynamicLegend />

				{ !isStepZero && (
					<CartPreviewDiscountFormContent onCloseMobileDiscountModal={ handleCloseMobileDiscountModal } onToggleActivity={ onToggleActivity } />
				) }
			</SlideDown>
		)
	}

	return (
		<CartPreviewSummaryDiscountModal
			isLogged={ isLogged }
			isOpen={ isOpenDiscountModal }
			isStepZero={ isStepZero }
			onClose={ handleCloseMobileDiscountModal }
		>
			{ isStepZero ? (
				<CartPreviewDiscountDynamicLegend />
			) : (
				<CartPreviewDiscountFormContent onCloseMobileDiscountModal={ handleCloseMobileDiscountModal } onToggleActivity={ onToggleActivity } />
			) }
		</CartPreviewSummaryDiscountModal>
	)
}

export { CartPreviewSummaryDiscountContent }
