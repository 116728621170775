import React, { ReactElement, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { AppDispatch } from '~/state/store'
import { setAdeoLogout, setLogout } from '~/actions/auth'
import { Icon } from '~/components/core/icon'
import { IUserMenuLogoutSectionProps } from '~/components/core/userMenu'
import { Button } from '~/components/core/button'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useAdeoSignIn } from '~/hooks/signIn'

import styles from './UserMenuLogoutSection.module.scss'

const UserMenuLogoutSection = (props: IUserMenuLogoutSectionProps): ReactElement => {
	const { theme, onClose } = props
	const { t } = useTranslation(['header'])
	const dispatch: AppDispatch = useDispatch()
	const { isDesktop } = useMediaQuery()
	const { shouldLoginByAdeo } = useAdeoSignIn()

	const handleLogout = useCallback(async (): Promise<void> => {
		if (shouldLoginByAdeo) {
			await dispatch(setAdeoLogout())
		} else {
			await dispatch(setLogout())
		}
		onClose()
	}, [onClose, shouldLoginByAdeo])

	const renderText = useCallback((): string => (
		isDesktop ? t('topBar.account.logout') : t('topBar.account.logoutMobile')
	), [isDesktop])

	const wrapperClass = classNames(styles.wrapper, theme?.wrapper)

	return (
		<li className={ wrapperClass }>
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.button }
				ariaLabel={ renderText() }
				onClick={ handleLogout }
				onBlur={ onClose }
			>
				<Icon
					color="navy"
					name="logout"
					width={ 18 }
					additionalClass={ styles.icon }
				/>

				{ renderText() }
			</Button>
		</li>
	)
}

export { UserMenuLogoutSection }
