import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { IWishListModalFooterProps } from '~/components/core/wishListModal'
import { Button } from '~/components/core/button'
import { useGoToSignIn } from '~/hooks/goToSignIn'
import { useAdeoSignIn } from '~/hooks/signIn'

import styles from './WishListModalFooter.module.scss'

const WishListModalFooter = (props: IWishListModalFooterProps): ReactElement => {
	const { onClose } = props
	const { t } = useTranslation(['common'])
	const router = useRouter()
	const { urls: { registration } } = useContext(AppParametersContext)
	const { onGoToSignInPage } = useGoToSignIn()
	const { shouldLoginByAdeo, handleSignInByAdeo } = useAdeoSignIn()

	const handleGoToSignInPage = useCallback(async (): Promise<void> => {
		onClose()

		if (shouldLoginByAdeo) {
			await handleSignInByAdeo(router.asPath)
		} else {
			await onGoToSignInPage()
		}
	}, [onClose, onGoToSignInPage, shouldLoginByAdeo, handleSignInByAdeo, router.asPath])

	const handleGoToRegisterPage = useCallback(async (): Promise<void> => {
		onClose()

		if (shouldLoginByAdeo) {
			await handleSignInByAdeo(router.asPath)
		} else {
			await router.push(registration)
		}
	}, [onClose, registration, shouldLoginByAdeo, handleSignInByAdeo, router.asPath])

	return (
		<section className={ styles.wrapper }>
			<Button
				size="inherit"
				additionalClass={ styles.loginButton }
				variant="secondary"
				text={ t('signIn') }
				onClick={ handleGoToSignInPage }
			/>

			<footer className={ styles.registrationWrapper }>
				<span className={ styles.question }>
					{ t('newClientQuestion') }
				</span>

				<Button
					isReverse
					variant="secondary"
					text={ t('createAccount') }
					size="small"
					additionalClass={ styles.button }
					onClick={ handleGoToRegisterPage }
					onBlur={ onClose }
				/>
			</footer>
		</section>
	)
}

export { WishListModalFooter }
