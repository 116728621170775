import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'

import { SectionHeader } from '~/components/core/sectionHeader'
import { IRadioOption, RadioGroup } from '~/components/core/form'
import { CartCustomerGuestFormCompany, CartCustomerGuestFormPrivate, ICartCustomerGuestForm, ICartCustomerGuestProps } from '~/components/cart/cartCustomer'
import { CustomerType } from '~/api/dataTypes/customer'
import { AppDispatch } from '~/state/store'
import { setCartCustomerGuestFormType } from '~/actions/cartCustomer'

import styles from './CartCustomerGuestForm.module.scss'

const CartCustomerGuestForm = (props: ICartCustomerGuestProps): ReactElement => {
	const { type } = props
	const { t, i18n } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()

	const schema: yup.SchemaOf<ICartCustomerGuestForm> = useMemo(() => yup.object().shape({
		customerType: yup.string().oneOf(['PRIVATE', 'COMPANY']).required(),
	}), [])

	const { control } = useForm<ICartCustomerGuestForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			customerType: type,
		},
		mode: 'onChange',
	})

	const customerTypeOptions: IRadioOption<CustomerType>[] = useMemo(() => [{
		label: t('customer.guest.private'),
		value: 'PRIVATE',
	}, {
		label: t('customer.guest.company'),
		value: 'COMPANY',
	}], [t, i18n])

	const { field: { value } } = useController({ control, name: 'customerType' })
	const isPrivate = isEqual(value, 'PRIVATE' as CustomerType)

	const renderForm = useCallback((): ReactElement => (
		isPrivate ? <CartCustomerGuestFormPrivate /> : <CartCustomerGuestFormCompany />
	), [isPrivate])

	useEffect(() => {
		(async () => {
			await dispatch(setCartCustomerGuestFormType(value as CustomerType))
		})()
	}, [value])

	return (
		<div className={ styles.wrapper }>
			<SectionHeader additionalClass={ styles.header } title={ t('customer.clientData.title') } />

			<div className={ styles.content }>
				<div className={ styles.description }>
					{ t('customer.guest.description') }
				</div>

				<div className={ styles.form }>
					<RadioGroup
						name="customerType"
						control={ control }
						options={ customerTypeOptions }
					/>
				</div>

				{ renderForm() }
			</div>
		</div>
	)
}

export { CartCustomerGuestForm }
