import { IAccountReviewsState } from '~/state/reducers/accountReducer'

export const accountReviewsInitialState: IAccountReviewsState = {
	isPending: false,
	data: {
		content: [],
		pageable: {
			totalElements: 0,
			totalPages: 0,
			hasNext: false,
			firstPage: false,
			lastPage: false,
			hasPrevious: false,
			pageSize: 0,
			pageNumber: 0,
		},
	},
}
