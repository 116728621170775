import React, { ReactElement, useCallback, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Link } from '~/components/core/link'
import { urls as staticUrls } from '~/utils/urls'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { getCartPreviewData, getCartPreviewLoyaltyData } from '~/state/reducers/cartPreviewReducer'

import styles from './CartPreviewDiscountLysPoints.module.scss'

const CartPreviewDiscountLysPoints = (): ReactElement | null => {
	const { cart: { promotionCodeUsed: isPromotionCodeUsed } } = useSelector(getCartPreviewData, shallowEqual)
	const { accountData: { availablePoints } } = useSelector(getCartPreviewLoyaltyData, shallowEqual)
	const { isLysCommunicationError, isLysMigrated, isEditMode } = useContext(LysLoyaltyProviderContext)
	const { t } = useTranslation(['cart'])

	const renderPointsWithDescription = useCallback((): ReactElement | null => {
		if (isLysCommunicationError) {
			return null
		}

		return (
			<div className={ styles.pointsContainer }>
				<span className={ styles.pointsCaption }>
					{ t('preview.aside.lysDiscount.points.pointsBalance') }
				</span>

				<span className={ styles.points }>
					{ t('preview.aside.lysDiscount.points.points', { points: availablePoints }) }
				</span>
			</div>
		)
	}, [isLysCommunicationError, availablePoints])

	const shouldHidePoints = (isPromotionCodeUsed && !isEditMode) || (!isLysMigrated && !isLysCommunicationError)

	if (shouldHidePoints) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.wrapperError]: isLysCommunicationError,
	})

	const linkWrapperClass = classNames(styles.pointsLink, {
		[styles.pointsLinkError]: isLysCommunicationError,
	})

	return (
		<div className={ wrapperClass }>
			{ renderPointsWithDescription() }

			<Link theme={ { wrapper: linkWrapperClass } } href={ staticUrls.customerSpaceUrl() }>
				<span>
					{ t('preview.aside.lysDiscount.points.pointsLink') }
				</span>
			</Link>
		</div>
	)
}

export { CartPreviewDiscountLysPoints }
