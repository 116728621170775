import React, { ReactElement, useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { isNull } from 'lodash'

import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { IHamburgerMenuUserStoreSelectedProps } from '~/components/core/hamburgerMenu'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HamburgerMenuUserStoreSelected.module.scss'

const HamburgerMenuUserStoreSelected = (props: IHamburgerMenuUserStoreSelectedProps): ReactElement => {
	const { store } = props
	const router = useRouter()
	const { handleSetOpen } = useContext(HamburgerMenuContext)
	const { t } = useTranslation(['header', 'stores'])

	const { name, availableHours, wwwUrl } = store

	const handleGoToStorePage = useCallback(async (): Promise<void> => {
		handleSetOpen(false)

		await router.push(wwwUrl)
	}, [handleSetOpen, wwwUrl])

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ styles.link }
			dataTestId={ DATA_TESTID.CORE.BUTTON_GO_TO_STORE_CARD_DESKTOP }
			ariaLabel={ `${ t('myStore', { ns: 'stores' }) } ${ name } ${ !isNull(availableHours) ? availableHours.message : '' }` }
			onClick={ handleGoToStorePage }
		>
			<span className={ styles.dataWrapper }>
				<div>
					<Icon
						name="pinFull"
						color="navy"
						width={ 15 }
						height={ 22 }
						additionalClass={ styles.icon }
					/>
				</div>

				<div className={ styles.data }>
					<div className={ styles.name }>
						{ t('myStore', { ns: 'stores' }) }<br />

						{ name }
					</div>

					{ !isNull(availableHours) && (
						<div className={ styles.openingHours }>
							{ availableHours.message }
						</div>
					) }
				</div>
			</span>

			<div>
				<Icon
					name="arrowFull"
					width={ 4 }
					additionalClass={ styles.arrowIcon }
				/>
			</div>
		</Button>
	)
}

export { HamburgerMenuUserStoreSelected }
