import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { useDistance } from '~/hooks/distance'
import { IPreferredStoreDistanceListItemProps } from '~/components/core/preferredStore'
import { Button } from '~/components/core/button'

import styles from './PreferredStoreDistanceListItem.module.scss'

const PreferredStoreDistanceListItem = (props: IPreferredStoreDistanceListItemProps): ReactElement => {
	const { store, coords, onSelect } = props
	const { t } = useTranslation(['common'])
	const { getDistance } = useDistance({ lat: coords.latitude, lng: coords.longitude }, { lat: store.address.latitude, lng: store.address.longitude })

	const distance = getDistance(0, 'km')

	const handleSelect = useCallback((): void => {
		onSelect(store)
	}, [onSelect])

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ styles.wrapper }
			ariaLabel={ `${ store.name }, ${ distance } ${ t('kilometer') }` }
			onClick={ handleSelect }
		>
			<span>{ store.name }</span>

			<span>{ `${ distance } ${ t('kilometer') }` }</span>
		</Button>
	)
}

export { PreferredStoreDistanceListItem }
