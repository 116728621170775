import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty, isEqual, isNull, toString } from 'lodash'

import { useCartPreviewCalculate } from '~/hooks/cartPreviewCalculate'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { useModal } from '~/hooks/modal'
import { IRootState } from '~/state/types'
import { getCartItemQuantityList, getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'
import { ICartPreviewDiscountFormContentProps, checkDiscountType } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewDiscountFormContent'
import { CartPreviewSummaryDiscountForm, CartPreviewSummaryLoyaltyProgramPinModal, DISCOUNT, ICartPreviewSummaryDiscountFormData } from '~/components/cart/cartPreview'

const CartPreviewDiscountFormContent = (props: ICartPreviewDiscountFormContentProps): ReactElement => {
	const { onCloseMobileDiscountModal, onToggleActivity } = props
	const { promotionCode, promotionCodeUsed: isPromotionCodeUsed, promotionCodeType, loyaltyCardNumber, promotionCodeValue } = useSelector((state: IRootState) => state.cartPreview.data.cart, shallowEqual)
	const itemQuantityList = useSelector((state: IRootState) => getCartItemQuantityList(state))
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const { isOpen: isOpenPasswordModal, handleOpen: handleOpenPasswordModal, handleClose: handleClosePasswordModal } = useModal('DOMProgramPassword')
	const { onRemoveDiscount, onSetPromotionCode } = useCartPreviewCalculate()
	const { preventAlert } = useAlert()
	const { sendLogError } = useLogError()
	const { t, i18n } = useTranslation(['cart'])

	const schema: yup.SchemaOf<ICartPreviewSummaryDiscountFormData> = useMemo(() => yup.object().shape({
		promotionCode: yup.string().when('discount', {
			is: DISCOUNT.PROMOTION_CODE,
			then: (schema: yup.StringSchema) => schema.required(t('preview.aside.discount.promoCode.noCodeError')),
		}),
		cardNumber: yup.string(),
		discount: yup.string(),
		reward: yup.string(),
	}), [t, i18n])

	const formProps = useForm<ICartPreviewSummaryDiscountFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			promotionCode: isEcommerce ? toString(promotionCode) : '',
			cardNumber: loyaltyCardNumber || '',
			discount: checkDiscountType(promotionCode, isEcommerce),
			reward: isEcommerce ? toString(promotionCode) : '',
		},
		mode: 'onChange',
	})

	const { handleSubmit, setError, setValue, watch, formState: { isSubmitted } } = formProps
	const { promotionCode: promoCode, reward, cardNumber } = watch()

	const handleAdditionalClosePasswordModal = useCallback(async (): Promise<void> => {
		try {
			await onSetPromotionCode('')
			handleClosePasswordModal()
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		}
	}, [promoCode, itemQuantityList, onSetPromotionCode, handleClosePasswordModal])

	const handleClosePinModal = useCallback(async (): Promise<void> => {
		await handleAdditionalClosePasswordModal()
		onToggleActivity()
	}, [handleAdditionalClosePasswordModal, onToggleActivity])

	const handleDismissPasswordModal = useCallback((): void => {
		setValue('discount', DISCOUNT.NONE)
		setValue('reward', '')
		handleClosePasswordModal()
		onCloseMobileDiscountModal()
	}, [onCloseMobileDiscountModal, handleClosePasswordModal])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartPreviewSummaryDiscountFormData): Promise<void> => {
		const { promotionCode, discount } = formData
		const noPromo = isEqual(discount, DISCOUNT.NONE)

		try {
			if (noPromo) {
				onCloseMobileDiscountModal()
				setValue('promotionCode', '')
				await onRemoveDiscount()
			} else {
				await onSetPromotionCode(toString(promotionCode))
			}
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		}
	}), [itemQuantityList, onSetPromotionCode, onCloseMobileDiscountModal])

	const handleSetPromoCodeError = useCallback((promotionCode: string | null, isPromotionCodeUsed: boolean) => {
		if (!isPromotionCodeUsed && (!isNull(promotionCode) && !isEmpty(promotionCode))) {
			setValue('discount', DISCOUNT.PROMOTION_CODE)
			setError('promotionCode', {
				message: t('preview.aside.discount.promoCode.incorrectCode'),
			})
		}
	}, [])

	useEffect(() => {
		if (isSubmitted) {
			handleSetPromoCodeError(promotionCode, isPromotionCodeUsed)
		}
	}, [promotionCode, isPromotionCodeUsed, isSubmitted])

	return (
		<>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<FormProvider { ...formProps }>
				<CartPreviewSummaryDiscountForm
					isOpenPinModal={ isOpenPasswordModal }
					promotionCodeUsed={ isPromotionCodeUsed }
					loyaltyCardNumber={ loyaltyCardNumber }
					promotionCodeValue={ promotionCodeValue }
					promotionCode={ promotionCode }
					promotionCodeType={ promotionCodeType }
					onOpenPinModal={ handleOpenPasswordModal }
					onRemoveCard={ handleClosePinModal }
					onSubmit={ handleFormSubmit }
				/>
			</FormProvider>

			<CartPreviewSummaryLoyaltyProgramPinModal
				rewardCode={ toString(reward) }
				cardNumber={ loyaltyCardNumber ?? cardNumber }
				isOpen={ isOpenPasswordModal }
				onDismiss={ handleDismissPasswordModal }
				onClose={ handleClosePinModal }
			/>
		</>
	)
}

export { CartPreviewDiscountFormContent }
