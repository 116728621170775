import React, { ReactElement, ReactNode, useCallback, KeyboardEvent } from 'react'
import { FieldValues } from 'react-hook-form'
import classNames from 'classnames'
import { useToggle } from 'react-use'
import { isEmpty, isEqual, isFunction, isNull, noop } from 'lodash'

import { HiddenRadioPrefix, IRadioProps, RadioIcon, getRadioId } from '~/components/core/form'
import { useMozaic } from '~/hooks/mozaic'

import styles from './Radio.module.scss'

const Radio = <FormFields extends FieldValues = FieldValues>(props: IRadioProps<FormFields>): ReactElement => {
	const {
		value, field, error,
		label = '',
		isRequired = false,
		isDisabled = false,
		additionalClass = '',
		children = null,
		rightAccessory = '',
		onClick = noop,
		onChange = undefined,
		additionalRadioClass = '',
	} = props
	const [hasFocus, toggleHasFocus] = useToggle(false)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const handleKeyDownEnter = useCallback((value: string) => (event: KeyboardEvent<HTMLLabelElement>): void => {
		event.stopPropagation()

		if (event.key === 'Enter' && !isDisabled) {
			field.onChange(value)
		}
	}, [field.onChange, isDisabled])

	const handleChange = useCallback((value: string) => (): void => {
		field.onChange(value)

		if (isFunction(onChange)) {
			onChange(value)
		}
	}, [field.onChange, onChange])

	const handleClick = useCallback((value: string) => (): void => {
		onClick(value)
	}, [onClick])

	const isChecked = isEqual(field.value, value)

	const labelWrapperClass = classNames(styles.labelWrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const renderLabel = useCallback((): ReactNode | null => {
		if (!isNull(children)) {
			return children
		} else if (!isEmpty(label)) {
			return (
				<div id={ getRadioId(value) } className={ labelWrapperClass }>
					<HiddenRadioPrefix isChecked={ isChecked } isDisabled={ isDisabled } />

					<RadioIcon isChecked={ isChecked } error={ error } />

					<span className={ styles.text }>
						{ label }
					</span>

					{ !isNull(rightAccessory) && (
						<span className={ styles.rightAccessory }>
							{ rightAccessory }
						</span>
					) }
				</div>
			)
		}

		return null
	}, [label, children, isChecked, isDisabled, error, rightAccessory, value])

	const wrapperClass = classNames(styles.wrapper, additionalClass)
	const radioInputClass = classNames(styles.radio, additionalRadioClass)

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<label
			key={ value }
			className={ wrapperClass }
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={ 0 }
			aria-labelledby={ getRadioId(value) }
			aria-live={ hasFocus ? 'assertive' : 'off' }
			onFocusCapture={ toggleHasFocus }
			onBlur={ toggleHasFocus }
			onKeyDown={ handleKeyDownEnter(value) }
		>
			<input
				type="radio"
				className={ radioInputClass }
				required={ isRequired }
				disabled={ isDisabled }
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...field }
				checked={ isChecked }
				onChange={ handleChange(value) }
				onClick={ handleClick(value) }
			/>

			{ renderLabel() }
		</label>
	)
}

export { Radio }
