import { AppPromiseThunkAction } from '~/actions'
import { GetCartConfirmationDataActionType, CartConfirmationType, SetCartConfirmationOrderFrontIdActionType } from '~/actions/cartConfirmation'
import { getAuthlessOrderDetails } from '~/api/requests/authlessOrder'

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartConfirmationData = (orderFrontId: string): AppPromiseThunkAction<GetCartConfirmationDataActionType> => async (dispatch) => {
	return dispatch({
		type: CartConfirmationType.GET_CART_CONFIRMATION_DATA,
		payload: getAuthlessOrderDetails({ orderFrontId }),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartConfirmationOrderFrontId = (orderFrontId: string): AppPromiseThunkAction<SetCartConfirmationOrderFrontIdActionType> => async (dispatch) => {
	return dispatch({
		type: CartConfirmationType.SET_CART_CONFIRMATION_ORDER_FRONT_ID,
		payload: Promise.resolve(orderFrontId),
	})
}
