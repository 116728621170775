import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { IAddToCartResponse, ICartDeliveryNextResponse, ICartSimpleResponse, IRemoveProductFromCartResponse, ISetQuantityProductInCartResponse, ICartDeliveryPickupPointResponse, DeliveryGroupType, ITransportCategoriesResponse, ITransportTimeSlotsCalendarResponse, IShoppingCartResponse, IShoppingCartSimpleResponse, ICheckAvailabilityResponse, IRemoveProductsFromCartResponse } from '~/api/dataTypes/cart'
import { SetAdeoLogoutActionType, SetLogoutActionType } from '~/actions/auth'
import { ICustomerLoyaltyAccountResponse, ICustomerLoyaltyExistRespone } from '~/api/dataTypes/customerLoyalty'

export enum CartActionType {
	GET_CART = 'cart/GET_CART',
	GET_CART_SIMPLE = 'cart/GET_CART_SIMPLE',
	ADD_PRODUCT_TO_CART = 'cart/ADD_PRODUCT_TO_CART',
	REMOVE_PRODUCT_FROM_CART = 'cart/REMOVE_PRODUCT_FROM_CART',
	REMOVE_PRODUCTS_FROM_CART = 'cart/REMOVE_PRODUCTS_FROM_CART',
	SET_QUANTITY_PRODUCT_IN_CART = 'cart/SET_QUANTITY_PRODUCT_IN_CART',
	SET_CART_PAYMENT_WAY_ID = 'cart/SET_PAYMENT_WAY_ID',
	SET_CART_COURIER_NOTE = 'cart/SET_COURIER_NOTE',
	SET_ACTIVE_DELIVERY_GROUP = 'cart/SET_ACTIVE_DELIVERY_GROUP',
	CLEAR_SELECTED_DELIVERY_WAY_ID = 'cart/CLEAR_SELECTED_DELIVERY_WAY_ID',
	GET_TRANSPORT_FROM_STORE_CATEGORIES = 'cart/GET_TRANSPORT_FROM_STORE_CATEGORIES',
	SET_TRANSPORT_FROM_STORE_ZIP_CODE = 'cart/SET_TRANSPORT_FROM_STORE_ZIP_CODE',
	GET_TRANSPORT_FROM_STORE_TIMESLOTS = 'cart/GET_TRANSPORT_FROM_STORE_TIMESLOTS',
	CLEAR_TRANSPORT_FROM_STORE_DATA = 'cart/CLEAR_TRANSPORT_FROM_STORE_DATA',
	CART_CALCULATE = 'cart/CART_CALCULATE',
	CART_MERGE = 'cart/CART_MERGE',
	CART_DELIVERY_NEXT = 'cart/DELIVERY_NEXT',
	CART_DELIVERY_CALCULATE = 'cart/DELIVERY_CALCULATE',
	CART_DELIVERY_TRANSPORT_FROM_STORE = 'cart/CART_DELIVERY_TRANSPORT_FROM_STORE',
	CART_DELIVERY_TRANSPORT_FROM_STORE_PICKUP_POINT = 'cart/CART_DELIVERY_TRANSPORT_FROM_STORE_PICKUP_POINT',
	CART_DELIVERY_PICKUP_IN_STORE_BOOKING = 'cart/CART_DELIVERY_PICKUP_IN_STORE_BOOKING',
	SEARCH_CART_DELIVERY_PICKUP_POINT = 'cart/SEARCH_CART_DELIVERY_PICKUP_POINT',
	CLEAR_CART_DELIVERY_PICKUP_POINT = 'cart/CLEAR_CART_DELIVERY_PICKUP_POINT',
	GET_CART_CHECK_AVAILABILITY = 'cart/GET_CART_CHECK_AVAILABILITY',
	TRANSFER_PRODUCTS_TO_CART = 'cart/TRANSFER_PRODUCTS_TO_CART',
	GET_CART_LOYALTY_ACCOUNT = 'cart/GET_CART_LOYALTY_ACCOUNT',
	SELECT_CART_LOYALTY_ACCOUNT_REWARD = 'cart/SELECT_CART_LOYALTY_ACCOUNT_REWARD',
	SET_CART_STEP_ZERO = 'cart/SET_CART_STEP_ZERO',
	GET_CUSTOMER_LOYALTY_EXIST = 'cart/GET_CUSTOMER_LOYALTY_EXIST',
}

export type GetCartActionType = AppAction<CartActionType.GET_CART, AxiosResponse<IShoppingCartResponse>>

export type GetCartSimpleActionType = AppAction<CartActionType.GET_CART_SIMPLE, AxiosResponse<ICartSimpleResponse>>

export type AddProductToCartActionType = AppAction<CartActionType.ADD_PRODUCT_TO_CART, AxiosResponse<IAddToCartResponse>>

export type TransferProductsToCartActionType = AppAction<CartActionType.TRANSFER_PRODUCTS_TO_CART, AxiosResponse<ICartSimpleResponse>>

export type SetQuantityProductInCartActionType = AppAction<CartActionType.SET_QUANTITY_PRODUCT_IN_CART, AxiosResponse<ISetQuantityProductInCartResponse>>

export type RemoveProductFromCartActionType = AppAction<CartActionType.REMOVE_PRODUCT_FROM_CART, AxiosResponse<IRemoveProductFromCartResponse>>

export type RemoveProductsFromCartActionType = AppAction<CartActionType.REMOVE_PRODUCTS_FROM_CART, AxiosResponse<IRemoveProductsFromCartResponse>>

export type CartCalculateType = AppAction<CartActionType.CART_CALCULATE, AxiosResponse<IShoppingCartSimpleResponse>>

export type CartMergeType = AppAction<CartActionType.CART_MERGE, AxiosResponse<ICartSimpleResponse>>

export type CartDeliveryCalculateType = AppAction<CartActionType.CART_DELIVERY_CALCULATE, AxiosResponse<IShoppingCartResponse>>

export type CartDeliveryNextType = AppAction<CartActionType.CART_DELIVERY_NEXT, AxiosResponse<ICartDeliveryNextResponse>>

export type CartDeliveryPickupInStoreBookingType = AppAction<CartActionType.CART_DELIVERY_PICKUP_IN_STORE_BOOKING, AxiosResponse>

export type SetCartPaymentWayIdType = AppAction<CartActionType.SET_CART_PAYMENT_WAY_ID, number>

export type SetActiveDeliveryGroupType = AppAction<CartActionType.SET_ACTIVE_DELIVERY_GROUP, DeliveryGroupType | null>

export type ClearSelectedDeliveryWayIdType = AppAction<CartActionType.CLEAR_SELECTED_DELIVERY_WAY_ID>

export type SetCartCourierNoteType = AppAction<CartActionType.SET_CART_COURIER_NOTE, string>

export type GetTransportFromStoreCategoriesType = AppAction<CartActionType.GET_TRANSPORT_FROM_STORE_CATEGORIES, AxiosResponse<ITransportCategoriesResponse>>

export type SetTransportFromStoreZipCodeType = AppAction<CartActionType.SET_TRANSPORT_FROM_STORE_ZIP_CODE, string>

export type ClearTransportFromStoreDataType = AppAction<CartActionType.CLEAR_TRANSPORT_FROM_STORE_DATA>

export type GetTransportFromStoreTimeSlotsType = AppAction<CartActionType.GET_TRANSPORT_FROM_STORE_TIMESLOTS, AxiosResponse<ITransportTimeSlotsCalendarResponse>>

export type CartDeliveryTransportFromStoreType = AppAction<CartActionType.CART_DELIVERY_TRANSPORT_FROM_STORE, AxiosResponse>

export type CartDeliveryTransportFromStorePickupPointType = AppAction<CartActionType.CART_DELIVERY_TRANSPORT_FROM_STORE_PICKUP_POINT, AxiosResponse>

export type SearchCartDeliveryPickupPointType = AppAction<CartActionType.SEARCH_CART_DELIVERY_PICKUP_POINT, AxiosResponse<ICartDeliveryPickupPointResponse>>

export type ClearCartDeliveryPickupPointType = AppAction<CartActionType.CLEAR_CART_DELIVERY_PICKUP_POINT>

export type GetCartCheckAvailabilityType = AppAction<CartActionType.GET_CART_CHECK_AVAILABILITY, AxiosResponse<ICheckAvailabilityResponse>>

export type GetCartLoyaltyAccountType = AppAction<CartActionType.GET_CART_LOYALTY_ACCOUNT, AxiosResponse<ICustomerLoyaltyAccountResponse>>

export type GetCartLoyaltyCustomerExistType = AppAction<CartActionType.GET_CUSTOMER_LOYALTY_EXIST, AxiosResponse<ICustomerLoyaltyExistRespone>>

export type SelectCartLoyaltyAccountRewardType = AppAction<CartActionType.SELECT_CART_LOYALTY_ACCOUNT_REWARD, string>

export type SetCartStepZeroType = AppAction<CartActionType.SET_CART_STEP_ZERO, boolean>

export type CartPreviewLoyaltyActionType = GetCartLoyaltyAccountType | SelectCartLoyaltyAccountRewardType | GetCartLoyaltyCustomerExistType

export type MiniCartActionType = GetCartSimpleActionType | RemoveProductFromCartActionType | AddProductToCartActionType | SetQuantityProductInCartActionType | CartCalculateType | CartMergeType | SetLogoutActionType | TransferProductsToCartActionType | SetAdeoLogoutActionType

export type CartPreviewActionType = GetCartActionType | RemoveProductFromCartActionType | RemoveProductsFromCartActionType | CartCalculateType | SearchCartDeliveryPickupPointType | ClearCartDeliveryPickupPointType | CartDeliveryPickupInStoreBookingType | CartDeliveryCalculateType | CartDeliveryTransportFromStoreType | SetLogoutActionType | SetCartPaymentWayIdType | SetCartCourierNoteType | SetActiveDeliveryGroupType | ClearSelectedDeliveryWayIdType | AddProductToCartActionType | GetTransportFromStoreCategoriesType | SetTransportFromStoreZipCodeType | GetTransportFromStoreTimeSlotsType | ClearTransportFromStoreDataType | GetCartCheckAvailabilityType | SetAdeoLogoutActionType | CartPreviewLoyaltyActionType | SetCartStepZeroType

export interface ICartDeliveryNextData {
	deliveryWayId: number | null
	paymentWayId: number | null
	promotionCode: string | null
	pickupPointId: number | null
	courierNote: string
	itemQuantityList: ICartDeliveryNextDataItemList[]
	additionalDeliveryWayOptionSelected: boolean
}

export interface ICartDeliveryNextDataItemList {
	itemId: number
	quantity: number
}
