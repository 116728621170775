import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import classNames from 'classnames'

import { vars } from '~/statics'
import { Button } from '~/components/core/button'

import styles from './FooterAppVersion.module.scss'

const FooterAppVersion = (): ReactElement => {
	const { t } = useTranslation(['footer'])
	const [isVisible, setIsVisible] = useToggle(false)

	const handleToggleVersion = useCallback(() => {
		setIsVisible(!isVisible)
	}, [isVisible])

	const contentClass = classNames(styles.content, {
		[styles.visible]: isVisible,
	})

	return (
		<Button
			isAriaHidden
			variant="neutral"
			size="inherit"
			role="none"
			additionalClass={ styles.wrapper }
			tabIndex={ -1 }
			onClick={ handleToggleVersion }
		>
			<div className={ contentClass }>
				<span className={ styles.label }>
					{ t('version') }
				</span>

				<span className={ styles.version }>
					{ vars.app.version }
				</span>
			</div>
		</Button>
	)
}

export { FooterAppVersion }
