import React, { ReactElement, useContext, useEffect } from 'react'
import { useMeasure, useWindowScroll } from 'react-use'
import classNames from 'classnames'

import { IHeaderWrapperProps } from '~/components/core/layout'
import { HeaderContext } from '~/providers/headerProvider'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './HeaderWrapper.module.scss'

const HeaderWrapper = (props: IHeaderWrapperProps): ReactElement | null => {
	const {
		children,
		isSimple = false,
		isForcedVisible = false,
	} = props
	const [ref, { height }] = useMeasure<HTMLElement>()
	const { y } = useWindowScroll()
	const { isHeaderFixed, handleSetHeaderHeight, handleSetHeaderFixed } = useContext(HeaderContext)
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const wrapperClass = classNames(styles.wrapper, {
		[styles.fixedHeader]: isHeaderFixed,
		[styles.simple]: isSimple,
	})

	useEffect(() => {
		handleSetHeaderHeight(height)
	}, [height])

	useEffect(() => {
		handleSetHeaderFixed(y > 0)
	}, [y])

	if (isKobiIntegrationEnabled && !isForcedVisible) {
		return null
	}

	return (
		<header ref={ ref } className={ wrapperClass }>
			{ children }
		</header>
	)
}

export { HeaderWrapper }
