import { IAccountReturnsList, AccountReturnsOrderItemType, IAccountReturnsApplication, IAccountReturnSentResponse, IAccountReturnsState } from '~/state/reducers/accountReducer'

export const ACCOUNT_RETURNS_LIST_MOCK: IAccountReturnsList = {
	items: [
		{
			id: 0,
			returnNumber: '001503577',
			returnDate: 1586528677,
			orderNumber: '001503577',
			orderDate: 1586528677,
			orderCost: 218.48,
			status: 'Status wniosku',
		},
		{
			id: 1,
			returnNumber: '001210041',
			returnDate: 1586528677,
			orderNumber: '001210041',
			orderDate: 1586528677,
			orderCost: 318.48,
			status: 'Status wniosku',
		},
		{
			id: 2,
			returnNumber: '000642184',
			returnDate: 1586528677,
			orderNumber: '000642184',
			orderDate: 1586528677,
			orderCost: 118.48,
			status: 'Status wniosku',
		},
		{
			id: 3,
			returnNumber: '001210041',
			returnDate: 1586528677,
			orderNumber: '001210041',
			orderDate: 1586528677,
			orderCost: 318.48,
			status: 'Status wniosku',
		},
		{
			id: 4,
			returnNumber: '000642184',
			returnDate: 1586528677,
			orderNumber: '000642184',
			orderDate: 1586528677,
			orderCost: 118.48,
			status: 'Status wniosku',
		},
		{
			id: 5,
			returnNumber: '001210041',
			returnDate: 1586528677,
			orderNumber: '001210041',
			orderDate: 1586528677,
			orderCost: 318.48,
			status: 'Status wniosku',
		},
		{
			id: 6,
			returnNumber: '000642184',
			returnDate: 1586528677,
			orderNumber: '000642184',
			orderDate: 1586528677,
			orderCost: 118.48,
			status: 'Status wniosku',
		},
	],
	pagination: {
		totalItems: 6,
		page: 1,
		totalPages: 1,
		perPage: 10,
	},
}

export const ACCOUNT_RETURNS_ORDERS_MOCK: AccountReturnsOrderItemType[] = [
	{
		id: 1,
		orderNumber: '001503577',
		orderDate: 1637306405,
		orderTotalValue: 218.48,
		orderType: 'INTERNET',
	},
	{
		id: 2,
		orderNumber: '001210041',
		orderDate: 1637306405,
		orderTotalValue: 191.95,
		orderType: 'STATIONARY',
	},
	{
		id: 3,
		orderNumber: '000642184',
		orderDate: 1637306405,
		orderTotalValue: 218.48,
		orderType: 'INTERNET',
	},
	{
		id: 4,
		orderNumber: '000316920',
		orderDate: 1637306405,
		orderTotalValue: 218.48,
		orderType: 'STATIONARY',
	},
]

export const ACCOUNT_RETURNS_APPLICATION_MOCK: IAccountReturnsApplication = {
	orderNumber: '001210041',
	customer: {
		firstName: 'Jan',
		lastName: 'Kowalski',
		email: 'jan.kowalski@gmail.com',
		delivery: {
			firstName: 'Jan',
			lastName: 'Kowalski',
			street: 'ul. Wysyłkowa 15',
			postalCode: '12-121',
			city: 'Warszawa',
			phoneNumber: '123 456 789',
		},
	},
	products: [
		{
			id: 1,
			image: 'https://static02.leroymerlin.pl/files/media/image/497/1834497/product/myjka-do-okien-wv-classic-karcher,main.jpg',
			name: 'Myjka do okien WV Classic Karcher',
			refNumber: '45589453',
			isAbleToComplaint: true,
			isAbleToReturn: true,
			quantity: {
				value: 2,
				unit: 'szt.',
			},
		},
		{
			id: 2,
			image: 'https://static02.leroymerlin.pl/files/media/image/502/2173502/product/fotel-wiszacy-kokon-aruba-szary,main.jpg',
			name: 'Fotel wiszący kokon Aruba szary',
			refNumber: '82395592',
			isAbleToComplaint: true,
			isAbleToReturn: false,
			quantity: {
				value: 1,
				unit: 'szt.',
			},
		},
	],
}

export const ACCOUNT_RETURN_SENT_RESPONSE: IAccountReturnSentResponse = {
	id: 0,
	orderId: 0,
	returnNumber: '001503577',
	date: 1637306405,
}

export const ACCOUNT_RETURN_MOCK: IAccountReturnsState = {
	orders: ACCOUNT_RETURNS_ORDERS_MOCK,
	application: ACCOUNT_RETURNS_APPLICATION_MOCK,
	list: ACCOUNT_RETURNS_LIST_MOCK,
}
