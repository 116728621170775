import { useCallback, useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'

import { ICartGuestCustomerFormData, ICartCustomerGuestPrivateForm, ICartCustomerGuestCompanyForm } from '~/hooks/cartGuestCustomerForm'
import { useInitialLoader } from '~/hooks/initialLoader'
import { IRootState } from '~/state/types'
import { hasNotNumberAndAllowOnlyDash, hasOnlyLetters, hasOnlyLettersNumbersOrDash, hasOnlyLettersSpacesOrDash, hasOnlyLettersNumbersSpacesDotsOrDash, isValidNip } from '~/utils/string'

export const useCartGuestCustomerForm = (): ICartGuestCustomerFormData => {
	const { t, i18n } = useTranslation(['cart', 'common', 'form'])
	const { data, isPending } = useSelector((state: IRootState) => state.cartCustomer, shallowEqual)
	const [isInitialLoader] = useInitialLoader(isPending)

	const { customerGuest: { customerType }, customer: { address, companyName, customerTitle, email, firstName, lastName, nip, phone } } = data
	const { city, flatNumber, houseNumber, street, zipCode } = address

	const companyNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(100, t('mustBeShortestThen100', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.guest.form.corporateNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('requiredFields', { ns: 'form' })), [t, i18n])
	const firstNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLetters', t('validation.unexpectedCharacters', { field: t('customer.guest.form.firstNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLetters(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const lastNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasNotNumberAndAllowOnlyDash', t('validation.unexpectedCharacters', { field: t('customer.guest.form.lastNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasNotNumberAndAllowOnlyDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const houseNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).test('hasOnlyLettersOrNumbers', t('validation.unexpectedCharacters', { field: t('customer.guest.form.houseNumberLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const flatNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).test('hasOnlyLettersOrNumbers', t('validation.unexpectedCharacters', { field: t('customer.guest.form.flatNumberLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersOrDash(value)), [t, i18n])
	const citySchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLettersOrSpaces', t('validation.unexpectedCharacters', { field: t('customer.guest.form.cityLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersSpacesOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const streetSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(42, t('mustBeShortestThen42', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.guest.form.streetLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const nipSchema = useMemo(() => yup.string().test('isValidNip', t('validation.nipFormat', { ns: 'form' }), (value: string | undefined) => isValidNip(value)).required(t('required', { ns: 'form' })), [t, i18n])

	const privateSchema: yup.SchemaOf<ICartCustomerGuestPrivateForm> = useMemo(() => yup.object().shape({
		customerTitle: yup.mixed().oneOf(['MS', 'MR']).required(),
		firstName: firstNameSchema,
		lastName: lastNameSchema,
		street: streetSchema,
		houseNumber:houseNumberSchema,
		flatNumber: flatNumberSchema,
		postalCode: yup.string().required(t('requiredFields', { ns: 'form' })),
		city: citySchema,
		country: yup.string().required(t('requiredFields', { ns: 'form' })),
		phoneNumber: yup.string().required(t('requiredFields', { ns: 'form' })),
		email: yup.string().email(t('validation.emailFormat', { ns: 'form' })).required(t('required', { ns: 'form' })),
		regulations: yup.boolean().oneOf([true], t('required', { ns: 'form' })).required(t('required', { ns: 'form' })),
	}), [])

	const companySchema: yup.SchemaOf<ICartCustomerGuestCompanyForm> = useMemo(() => yup.object().shape({
		nip: nipSchema,
		corporateName: companyNameSchema,
		customerTitle: yup.mixed().oneOf(['MS', 'MR']).required(),
		firstName: firstNameSchema,
		lastName: lastNameSchema,
		street: streetSchema,
		houseNumber:houseNumberSchema,
		flatNumber: flatNumberSchema,
		postalCode: yup.string().required(t('requiredFields', { ns: 'form' })),
		city: yup.string().max(50, t('mustBeShortestThen50', { ns: 'form' })).required(t('requiredFields', { ns: 'form' })),
		country: yup.string().required(t('requiredFields', { ns: 'form' })),
		email: yup.string().email(t('validation.emailFormat', { ns: 'form' })).required(t('required', { ns: 'form' })),
		phoneNumber: yup.string().required(t('requiredFields', { ns: 'form' })),
		regulations: yup.boolean().oneOf([true], t('required', { ns: 'form' })).required(t('required', { ns: 'form' })),
	}), [])

	const privateFormProps = useForm<ICartCustomerGuestPrivateForm>({
		resolver: yupResolver(privateSchema),
		defaultValues: {
			customerTitle: 'MS',
			firstName: '',
			lastName: '',
			street: '',
			houseNumber: '',
			flatNumber: '',
			postalCode: '',
			city: '',
			country: 'PL',
			phoneNumber: '',
			email: '',
			regulations: false,
		},
		mode: 'onChange',
	})

	const companyFormProps = useForm<ICartCustomerGuestCompanyForm>({
		resolver: yupResolver(companySchema),
		defaultValues: {
			nip: '',
			corporateName: '',
			customerTitle: 'MS',
			firstName: '',
			lastName: '',
			street: '',
			houseNumber: '',
			flatNumber: '',
			postalCode: '',
			city: '',
			country: 'PL',
			phoneNumber: '',
			email: '',
			regulations: false,
		},
		mode: 'onChange',
	})

	const { formState: { isDirty: isPrivateDirty, isValid: isPrivateValid }, setValue: setPrivateValue } = privateFormProps
	const { formState: { isDirty: isCompanyDirty, isValid: isCompanyValid }, setValue: setCompanyValue } = companyFormProps

	const isPrivateSubmitDisable = !isPrivateValid || !isPrivateDirty
	const isCompanySubmitDisable = !isCompanyValid || !isCompanyDirty

	const isMainFormSubmitDisable = customerType === 'COMPANY' ? isCompanySubmitDisable : isPrivateSubmitDisable

	const setPrivateDefaultValue = useCallback((): void => {
		setPrivateValue('customerTitle', customerTitle || 'MS')
		setPrivateValue('firstName', firstName || '')
		setPrivateValue('lastName', lastName || '')
		setPrivateValue('street', street || '')
		setPrivateValue('houseNumber', houseNumber || '')
		setPrivateValue('flatNumber', flatNumber || '')
		setPrivateValue('postalCode', zipCode || '')
		setPrivateValue('city', city || '')
		setPrivateValue('phoneNumber', phone || '')
		setPrivateValue('email', email || '')
	}, [data])

	const setCompanyDefaultValue = useCallback((): void => {
		setCompanyValue('customerTitle', customerTitle || 'MS')
		setCompanyValue('nip', nip || '')
		setCompanyValue('corporateName', companyName || '')
		setCompanyValue('firstName', firstName || '')
		setCompanyValue('lastName', lastName || '')
		setCompanyValue('street', street || '')
		setCompanyValue('houseNumber', houseNumber || '')
		setCompanyValue('flatNumber', flatNumber || '')
		setCompanyValue('postalCode', zipCode || '')
		setCompanyValue('city', city || '')
		setCompanyValue('phoneNumber', phone || '')
		setCompanyValue('email', email || '')
	}, [data])

	useEffect(() => {
		if (!isInitialLoader) {
			setCompanyDefaultValue()
			setPrivateDefaultValue()
		}

	}, [isInitialLoader])

	return {
		privateFormProps,
		companyFormProps,
		isMainFormSubmitDisable,
	}
}
