import React, { ReactElement, useCallback } from 'react'
import NumberFormat from 'react-number-format'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { gt, isNull } from 'lodash'

import { ICartPreviewProductsQuantityFieldProps } from '~/components/cart/cartPreview/cartPreviewProducts/cartPreviewProductsQuantityField'

import styles from './CartPreviewProductsQuantityField.module.scss'

const CartPreviewProductsQuantityField = (props: ICartPreviewProductsQuantityFieldProps): ReactElement => {
	const { shouldCalculateByArea, isNotEnoughItem, itemId, basePriceUnit, multiplier, quantityValue, quantityByAreaValue, handleInputChange, handleAreaInputChange } = props
	const { t } = useTranslation(['cart'])

	const id = itemId.toString()

	const renderQuantityField = useCallback((): ReactElement => {
		if (shouldCalculateByArea && !isNull(multiplier) && gt(multiplier, 1)) {
			return (
				<NumberFormat
					id={ id }
					className={ inputClass }
					aria-label={ `${ t('preview.table.header.quantity') }: ${ quantityByAreaValue } ${ basePriceUnit }` }
					value={ quantityByAreaValue }
					allowNegative={ false }
					allowLeadingZeros={ false }
					min={ 0 }
					maxLength={ 5 }
					onValueChange={ handleAreaInputChange }
				/>
			)
		}

		return (
			<NumberFormat
				id={ id }
				className={ inputClass }
				aria-label={ `${ t('preview.table.header.quantity') }: ${ quantityValue } ${ basePriceUnit }` }
				value={ quantityValue }
				allowNegative={ false }
				allowLeadingZeros={ false }
				min={ 0 }
				maxLength={ 5 }
				onValueChange={ handleInputChange }
			/>
		)
	}, [shouldCalculateByArea, multiplier, id, quantityValue, quantityByAreaValue, handleInputChange, handleAreaInputChange])

	const inputClass = classNames(styles.input, {
		[styles.notEnough]: isNotEnoughItem,
	})

	return (
		<div className={ styles.wrapper }>
			{ renderQuantityField() }
		</div>
	)
}

export { CartPreviewProductsQuantityField }
