import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ICartPreviewProductsDeliveryBlockProps } from '~/components/cart/cartPreview'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsDeliveryBlock.module.scss'

const CartPreviewProductsDeliveryBlock = (props: ICartPreviewProductsDeliveryBlockProps): ReactElement | null => {
	const { deliveryInfo } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { isActive, title, isOnDemand } = deliveryInfo

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
		[styles.onDemand]: isOnDemand,
	})

	if (!isActive) {
		return null
	}

	return (
		<div className={ wrapperClass }>
			{ title }
		</div>
	)
}

export { CartPreviewProductsDeliveryBlock }
