import React, { ReactElement, useCallback, useContext, useRef } from 'react'
import { useClickAway, useKeyPressEvent, useToggle } from 'react-use'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { UserContext } from '~/providers/userProvider'
import { HeaderTopBarUserAccountButton, HeaderTopBarUserAccountModal } from '~/components/core/layout'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { useGoToSignIn } from '~/hooks/goToSignIn'

import styles from './HeaderTopBarUserAccount.module.scss'

const HeaderTopBarUserAccount = (): ReactElement => {
	const { t } = useTranslation(['header'])
	const [isModalVisible, setIsModalVisible] = useToggle(false)
	const buttonRef = useRef<HTMLButtonElement | null>(null)
	const sectionRef = useRef<HTMLElement | null>(null)
	const { isLogged, userName } = useContext(UserContext)
	const { urls } = useContext(AppParametersContext)
	const { onGoToSignInPage } = useGoToSignIn()

	const handleHideModal = useCallback((): void => {
		setIsModalVisible(false)
	}, [])

	const handleToggleModal = useCallback(async (): Promise<void> => {
		if (!isLogged) {
			await onGoToSignInPage()
		} else {
			setIsModalVisible()
		}
	}, [isLogged, urls, onGoToSignInPage])

	const prepareText = useCallback((userName: string): string | string[] => {
		if (isLogged) {
			if (isEmpty(userName)) {
				return t('topBar.account.welcome')
			}

			return t('topBar.account.welcomeUser', { userName })
		}

		return [
			t('topBar.account.login'),
			t('topBar.account.register'),
		]
	}, [isLogged])

	useClickAway(sectionRef, handleHideModal)
	useKeyPressEvent('Escape', handleHideModal)

	return (
		<section ref={ sectionRef } className={ styles.wrapper }>
			<HeaderTopBarUserAccountButton
				buttonRef={ buttonRef }
				isLogged={ isLogged }
				userName={ userName }
				prepareText={ prepareText }
				isActive={ isModalVisible }
				handleToggleModal={ handleToggleModal }
			/>

			<HeaderTopBarUserAccountModal
				buttonRef={ buttonRef }
				isOpen={ isModalVisible }
				isLogged={ isLogged }
				onClose={ handleHideModal }
			/>
		</section>
	)
}

export { HeaderTopBarUserAccount }
