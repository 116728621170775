import produce, { Draft } from 'immer'
import { isNil } from 'lodash'

import { AppPromiseAction } from '~/actions'
import { AccountOrdersActionType, AccountOrdersType } from '~/actions/account'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'

import { IAccountOrdersState } from './types'
import { accountOrdersInitialState as initialState } from './constants'

export const accountOrders = produce((draft: Draft<IAccountOrdersState>, action: AppPromiseAction<AccountOrdersActionType>) => {
	switch (action.type) {
		case REQUEST(AccountOrdersType.GET_ORDERS_LIST):
			draft.isListPending = true

			return draft
		case REQUEST(AccountOrdersType.GET_ORDER_DETAILS):
			draft.isDetailsPending = true

			return draft
		case REQUEST(AccountOrdersType.GET_ORDER_PAYMENT_WAYS):
			draft.isPaymentWaysPending = true

			return draft
		case REQUEST(AccountOrdersType.GET_ORDER_STORE_DETAILS):
			draft.isStorePending = true

			return draft
		case REQUEST(AccountOrdersType.ADD_PRODUCTS_TO_CART):
			return draft
		case FAILURE(AccountOrdersType.GET_ORDERS_LIST):
			draft.isListPending = false

			return draft
		case FAILURE(AccountOrdersType.GET_ORDER_DETAILS):
			draft.isDetailsPending = false

			return draft
		case FAILURE(AccountOrdersType.GET_ORDER_PAYMENT_WAYS):
			draft.paymentWays = []
			draft.isPaymentWaysPending = false

			return draft
		case FAILURE(AccountOrdersType.GET_ORDER_STORE_DETAILS):
			draft.storeDetails = null
			draft.isStorePending = false

			return draft
		case SUCCESS(AccountOrdersType.GET_ORDERS_LIST):
			draft.list = action.payload.data
			draft.isListPending = false

			return draft
		case SUCCESS(AccountOrdersType.GET_ORDER_DETAILS):
			draft.isDetailsPending = false
			draft.details = action.payload.data

			return draft
		case SUCCESS(AccountOrdersType.SET_ORDER_ACTIVE_DETAILS):
			draft.storeDetails = null
			draft.details = null
			draft.activeDetailsId = action.payload

			return draft
		case SUCCESS(AccountOrdersType.CLEAR_ORDER_ACTIVE_DETAILS):
			draft.activeDetailsId = null

			return draft
		case SUCCESS(AccountOrdersType.GET_ORDER_PAYMENT_WAYS):
			draft.paymentWays = action.payload.data
			draft.isPaymentWaysPending = false

			return draft
		case SUCCESS(AccountOrdersType.GET_ORDER_STORE_DETAILS):
			draft.storeDetails = isNil(action.payload) ? null : action.payload.data
			draft.isStorePending = false

			return draft
		case SUCCESS(AccountOrdersType.UPDATE_ORDERS_LIST):
			draft.list = action.payload.data

			return draft
		case SUCCESS(AccountOrdersType.ADD_PRODUCTS_TO_CART):
		case SUCCESS(AccountOrdersType.CANCEL_ORDER):
			return draft
	}
}, initialState)
