import React, { ReactElement, useCallback, useContext, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useClickAway, useKeyPressEvent, useToggle } from 'react-use'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { isEmpty } from 'lodash'

import { getFavouriteListData } from '~/actions/favouriteList'
import { HeaderStickyModal, HeaderTopBarEmptyList, HeaderTopBarFavoritesButton, HeaderTopBarFavoritesModal, IHeaderTopBarFavoritesProps } from '~/components/core/layout'
import { WishListModal } from '~/components/core/wishListModal'
import { useModal } from '~/hooks/modal'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { UserContext } from '~/providers/userProvider'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { DATA_TESTID } from '~/utils/dataTestId'
import { urls as temporaryUrls } from '~/utils/urls'

import styles from './HeaderTopBarFavorites.module.scss'

const HeaderTopBarFavorites = (props: IHeaderTopBarFavoritesProps): ReactElement | null => {
	const { isEnabled } = props
	const { simpleList, data: { favouriteItemList } } = useSelector((state: IRootState) => state.favouriteList, shallowEqual)
	const [isModalVisible, setIsModalVisible] = useToggle(false)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const sectionRef = useRef<HTMLElement | null>(null)
	const { t } = useTranslation(['header'])
	const dispatch: AppDispatch = useDispatch()
	const router = useRouter()
	const { urls } = useContext(AppParametersContext)
	const { isLogged } = useContext(UserContext)
	const { isOpen, handleOpen, handleClose } = useModal('FavoritesNotLogged')
	const { isTablet } = useMediaQuery()

	const handleClickFavoritesButton = useCallback(async (): Promise<void> => {
		if (!isLogged) {
			handleOpen()
		} else {
			if (isTablet) {
				setIsModalVisible()
			} else {
				await router.push(urls.shoppingList)
			}
		}
	}, [isLogged, urls, isTablet])

	const handleHideModal = useCallback((): void => {
		setIsModalVisible(false)
	}, [])

	const handleGoToNewspaperList = useCallback(async (): Promise<void> => {
		// todo remove temporary urls
		await router.push(temporaryUrls.newspaperList())
		setIsModalVisible(false)
	}, [temporaryUrls])

	const renderFavoriteModal = useCallback((): ReactElement | null => {
		if (!isLogged) {
			return null
		}

		if (isEmpty(favouriteItemList)) {
			return (
				<HeaderTopBarEmptyList
					title={ t('topBar.favorites.empty.title') }
					subTitle={ t('topBar.favorites.empty.question') }
					buttonText={ t('topBar.favorites.empty.button') }
					onClick={ handleGoToNewspaperList }
					onBlur={ handleHideModal }
				/>
			)
		}

		return (
			<HeaderTopBarFavoritesModal productsData={ favouriteItemList } onOpen={ handleHideModal } />
		)
	}, [isLogged, favouriteItemList, handleGoToNewspaperList])

	useEffect(() => {
		(async () => {
			if (isLogged) {
				await dispatch(getFavouriteListData())
			}
		})()
	}, [simpleList, isLogged])

	useClickAway(sectionRef, handleHideModal)
	useKeyPressEvent('Escape', handleHideModal)

	return (
		<section ref={ sectionRef } className={ styles.wrapper }>
			<HeaderTopBarFavoritesButton
				buttonRef={ buttonRef }
				isActive={ isModalVisible }
				count={ simpleList.itemQuantity }
				isDisabled={ !isEnabled }
				onOpen={ handleClickFavoritesButton }
				onBlur={ handleHideModal }
			/>

			{ isEnabled && (
				<HeaderStickyModal
					buttonRef={ buttonRef }
					isOpen={ isModalVisible }
					dataTestId={ DATA_TESTID.HEADER.MODAL_FAVORITES }
					theme={ {
						content: styles.modalContent,
					} }
					onClose={ handleHideModal }
				>
					{ renderFavoriteModal() }
				</HeaderStickyModal>
			) }

			<WishListModal isOpen={ isOpen } onClose={ handleClose } />
		</section>
	)
}

export { HeaderTopBarFavorites }
