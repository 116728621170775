import React, { ReactElement, useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { i18n, useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'

import { CartCustomerInvoiceModalActions, ICartCustomerInvoiceModalCompanySimplifiedForm, ICartCustomerInvoiceModalCompanySimplifiedFormProps } from '~/components/cart/cartCustomer'
import { Input } from '~/components/core/form'
import { isValidNip } from '~/utils/string'
import { validation } from '~/utils/validation'
import { addReceiptNip } from '~/actions/cartCustomer'
import { AppDispatch } from '~/state/store'
import { ICartCustomerReceiptNip } from '~/api/dataTypes/cart'

import styles from './CartCustomerInvoiceModalCompanySimplifiedForm.module.scss'

const CartCustomerInvoiceModalCompanySimplifiedForm = (props: ICartCustomerInvoiceModalCompanySimplifiedFormProps): ReactElement => {
	const { onClose, onCancel } = props
	const { t } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()

	const { mask: { nip } } = validation

	const nipSchema = useMemo(() => yup.string().test('isValidNip', t('validation.nipFormat', { ns: 'form' }), (value: string | undefined) => isValidNip(value)).required(t('required', { ns: 'form' })), [t, i18n])

	const schema: yup.SchemaOf<ICartCustomerInvoiceModalCompanySimplifiedForm> = useMemo(() => yup.object().shape({
		nip: nipSchema,
	}), [])

	const { control, handleSubmit } = useForm<ICartCustomerInvoiceModalCompanySimplifiedForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			nip:  '',
		},
		mode: 'onChange',
	})

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerInvoiceModalCompanySimplifiedForm) => {
		const { nip } = formData

		const params: ICartCustomerReceiptNip = {
			nip,
			invoiceExpectation: 'YES',
		}

		await dispatch(addReceiptNip(params))
		onClose()
	}), [])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.description }>
				{ t('customer.invoice.receiptModal.description') }
			</div>

			<Input
				name="nip"
				label={ t('customer.invoice.receiptModal.nipLabel') }
				control={ control }
				mask={ nip }
				additionalClass={ styles.control }
			/>

			<CartCustomerInvoiceModalActions onCancel={ onCancel } onSubmit={ handleFormSubmit } />
		</div>
	)
}

export { CartCustomerInvoiceModalCompanySimplifiedForm }
