import { AnyAction } from 'redux'
import produce, { Draft } from 'immer'

import { IStoreResponse } from '~/api/dataTypes/store'
import { SUCCESS } from '~/statics'
import { PreferredStoreActionType } from '~/actions/preferredStore'

import { PreferredStoreDataType } from './types'
import { preferredStoreDataInitialState as initialState } from './constants'

export const preferredStoreData = produce((draft: Draft<PreferredStoreDataType>, action: AnyAction) => {
	switch (action.type) {
		case SUCCESS(PreferredStoreActionType.SET_PREFERRED_STORE):
			// eslint-disable-next-line no-param-reassign
			draft = (action.payload as IStoreResponse)

			return draft
		case SUCCESS(PreferredStoreActionType.CLEAR_PREFERRED_STORE):
			// eslint-disable-next-line no-param-reassign
			draft = null

			return draft
		case SUCCESS(PreferredStoreActionType.GET_CUSTOMER_PREFERRED_STORE):
			// eslint-disable-next-line no-param-reassign
			draft = (action.payload.data as IStoreResponse)

			return draft
		default:
			return draft
	}
}, initialState)
