import React, { ReactElement, useCallback } from 'react'
import { gt, isEmpty, isEqual, map, size } from 'lodash'

import { CartAsidePromotion, CartAsideDeliveryCost, CartAsideTotalCost, CartAsideSummaryCost, CartAsideSummaryWrapper } from '~/components/core/cartAside'
import { ICartPreviewSummaryBlockProps, CartPreviewSummaryBlockActions } from '~/components/cart/cartPreview'
import { ICartAppliedPromotions } from '~/api/dataTypes/cart'

const CartPreviewSummaryBlock = (props: ICartPreviewSummaryBlockProps): ReactElement => {
	const { cart } = props

	const { productsNetValue, productsValue, promotionValue, deliveryCost, appliedPromotions, totalCostValue } = cart

	const renderPromotions = useCallback((): ReactElement | ReactElement[] => {
		if (!isEmpty(appliedPromotions)) {
			return map(appliedPromotions, (promotion: ICartAppliedPromotions, index: number): ReactElement => {
				const { promotionValue, name } = promotion
				const isFirstElement = isEqual(index, 0) && gt(size(appliedPromotions), 1)

				return (
					<CartAsidePromotion
						promotionValue={ promotionValue }
						promotionName={ name }
						isFirstElement={ isFirstElement }
					/>
				)
			})
		}

		return (
			<CartAsidePromotion promotionValue={ promotionValue } />
		)
	}, [appliedPromotions])

	return (
		<CartAsideSummaryWrapper>
			<CartAsideSummaryCost productsNetValue={ productsNetValue } productsValue={ productsValue } />

			<CartAsideDeliveryCost deliveryCost={ deliveryCost } />

			{ renderPromotions() }

			<CartAsideTotalCost totalCost={ totalCostValue } />

			<CartPreviewSummaryBlockActions />
		</CartAsideSummaryWrapper>
	)
}

export { CartPreviewSummaryBlock }
