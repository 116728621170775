import React, { ReactElement, useCallback } from 'react'
import { SlideDown } from 'react-slidedown'
import { map } from 'lodash'

import { IFilesListProps } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'

import styles from './FilesList.module.scss'

const FilesList = (props: IFilesListProps): ReactElement => {
	const { files, remove } = props

	const renderContent = useCallback((): ReactElement[] => (
		map(files, (file: File, index: number) => (
			<div key={ `${ file.name } ${ index }` } className={ styles.item }>
				<span className={ styles.name }>{ file.name }</span>

				<Button
					size="inherit"
					variant="neutral"
					additionalClass={ styles.removeButton }
					onClick={ remove(index) }
					// TODO: add aria-label
				>
					<Icon
						color="navy"
						name="close"
						width={ 17 }
					/>
				</Button>
			</div>
		))
	), [files])

	return (
		<SlideDown className={ styles.wrapper }>
			{ renderContent() }
		</SlideDown>
	)
}

export { FilesList }
