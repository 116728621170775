import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isFunction } from 'lodash'

import { IProductBlockMiniActionsProps } from '~/components/core/productBlock'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'

import styles from './ProductBlockMiniActions.module.scss'

const ProductBlockMiniActions = (props: IProductBlockMiniActionsProps): ReactElement => {
	const {
		count, priceUnit, onRemove,
		onIncrement = undefined,
		onDecrement = undefined,
		onTransferToCart = undefined,
		theme = {},
	} = props
	const { t } = useTranslation(['header'])

	const countText = `${ count } ${ priceUnit }`

	const handleRemove = (): void => {
		onRemove()
	}

	const handleTransferToCart = (): void => {
		if (isFunction(onTransferToCart)) {
			onTransferToCart()
		}
	}

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)
	const transferButtonClass = classNames(styles.transfer, theme.transferButton)
	const removeButtonClass = classNames(styles.remove, theme.removeButton)

	return (
		<div className={ wrapperClass }>
			<div className={ styles.unit }>
				{ isFunction(onDecrement) && (
					<Button
						size="inherit"
						variant="neutral"
						additionalClass={ styles.decrementButton }
						ariaLabel={ t('topBar.favorites.decrement') }
						onClick={ onDecrement }
					>
						<Icon
							name="minus"
							width={ 9 }
							height={ 2 }
							color="black10"
							additionalClass={ styles.icon }
						/>
					</Button>
				) }

				<div className={ styles.value }>
					{ countText }
				</div>

				{ isFunction(onIncrement) && (
					<Button
						variant="neutral"
						size="inherit"
						additionalClass={ styles.incrementButton }
						ariaLabel={ t('topBar.favorites.increment') }
						onClick={ onIncrement }
					>
						<Icon
							name="plus"
							width={ 10 }
							height={ 10 }
							additionalClass={ styles.icon }
						/>
					</Button>
				) }
			</div>

			{ isFunction(onTransferToCart) && (
				<Button
					variant="neutral"
					size="inherit"
					ariaLabel={ t('topBar.favorites.transfer') }
					additionalClass={ transferButtonClass }
					onClick={ handleTransferToCart }
				>
					<div className={ styles.label }>{ t('topBar.favorites.transfer') }</div>

					<Icon name="arrowFull" width={ 4 } />
				</Button>
			) }

			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ removeButtonClass }
				ariaLabel={ t('topBar.favorites.delete') }
				onClick={ handleRemove }
			>
				<Icon
					name="close"
					width={ 13 }
					height={ 13 }
					color="gray"
					additionalClass={ styles.icon }
				/>
			</Button>
		</div>
	)
}

export { ProductBlockMiniActions }
