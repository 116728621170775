import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { map } from 'lodash'

import { HeaderTopBarSearchBarProduct, HeaderTopBarSearchBarProductsLink, IHeaderTopBarSearchBarProductsListProps, ISearchProduct } from '~/components/core/layout'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarSearchBarProductsList.module.scss'

const HeaderTopBarSearchBarProductsList = (props: IHeaderTopBarSearchBarProductsListProps): ReactElement => {
	const {
		products, handleBlur,
		query = '',
	} = props
	const { t } = useTranslation(['header'])

	const renderProductsList = useCallback((): ReactElement[] => (
		map(products, (product: ISearchProduct, index: number) => (
			<HeaderTopBarSearchBarProduct
				key={ product.id }
				product={ product }
				dataTestId={ DATA_TESTID.HEADER.SEARCH_BAR_PRODUCTS_LIST_ITEM(index) }
			/>
		))
	), [products])

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.HEADER.SEARCH_BAR_PRODUCTS_LIST }>
			{ renderProductsList() }

			<HeaderTopBarSearchBarProductsLink
				caption={ t('search.seeAllProducts') }
				query={ query }
				onBlur={ handleBlur }
			/>
		</div>
	)
}

export { HeaderTopBarSearchBarProductsList }
