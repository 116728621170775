import React, { ReactElement, useCallback, useContext, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'

import { ICartPreviewDiscountLysPromoCodeProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysPromoCode'
import { Input } from '~/components/core/form/input'
import { UserContext } from '~/providers/userProvider'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { IRootState } from '~/state/types'
import { ICartPreviewDiscountLysFormCode, ICartPreviewDiscountLysSelectedReward } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { getIsPromotionLoyalty } from '~/state/reducers/cartPreviewReducer'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDiscountLysPromoCode.module.scss'

const CartPreviewDiscountLysPromoCode = (props: ICartPreviewDiscountLysPromoCodeProps): ReactElement | null => {
	const { isPromoCodeDisabled } = props
	const { getShouldUseMozaicFlag } = useMozaic()
	const { promotionCodeUsed: isPromotionCodeUsed } = useSelector((state: IRootState) => state.cartPreview.data.cart, shallowEqual)
	const isLoyalty = useSelector(getIsPromotionLoyalty, shallowEqual)
	const { isLogged } = useContext(UserContext)
	const { isLysCommunicationError, isEditMode, onSetShouldClearInput, onSetSelectedReward } = useContext(LysLoyaltyProviderContext)
	const { control, watch, setValue, resetField } = useFormContext<ICartPreviewDiscountLysFormCode>()
	const { t } = useTranslation(['cart'])

	const { promotionCode } = watch()
	const isInputDisabled = isPromoCodeDisabled || isEmpty(promotionCode) || (isPromotionCodeUsed && !isEditMode)
	const shouldHideInput = !isLogged || isLysCommunicationError || (!isEditMode && isLoyalty)

	const handleClearPromoCode = useCallback((): void => {
		const reward: ICartPreviewDiscountLysSelectedReward = {
			code: '',
			type: null,
		}

		onSetShouldClearInput(isEditMode)
		setValue('promotionCode', '')
		resetField('promotionCode')
		onSetSelectedReward(reward)
	}, [isEditMode, onSetShouldClearInput, setValue, resetField])

	const renderRightBottomAccessory = useCallback((): ReactElement => (
		<Button
			size="inherit"
			variant="neutral"
			onClick={ handleClearPromoCode }
		>
			<Icon
				name="close"
				width={ 14 }
				height={ 14 }
				color="navy"
			/>
		</Button>
	), [handleClearPromoCode])

	useEffect(() => {
		if (!isUndefined(promotionCode) && !isEmpty(promotionCode)) {
			const reward: ICartPreviewDiscountLysSelectedReward = {
				code: promotionCode,
				type: null,
			}

			onSetSelectedReward(reward)
		}
	}, [promotionCode])

	if (shouldHideInput) {
		return null
	}

	const inputClass = classNames(styles.input, {
		[styles.selected]: isPromotionCodeUsed,
	})

	const iconClass = classNames(styles.icon, {
		[styles.disabled]: isInputDisabled,
	})

	const mozaicIconClass = classNames(styles.mozaicIcon, {
		[styles.disabled]: isInputDisabled,
	})

	const labelClass = classNames(styles.label, {
		[styles.selected]: isPromotionCodeUsed,
		[styles.editMode]: isEditMode,
	})

	const inputElementClass = classNames(styles.inputElement, getShouldUseMozaicFlag() && isInputDisabled ? 'mc-field__input' : '')

	return (
		<div className={ styles.wrapper }>
			<Input
				isDisabled={ isPromoCodeDisabled }
				type="text"
				label={ t('preview.aside.lysDiscount.promoCode.label') }
				control={ control }
				name="promotionCode"
				RightBottomAccessory={ renderRightBottomAccessory() }
				theme={ {
					wrapper: styles.inputWrapper,
					inputWrapper: inputClass,
					input: inputElementClass,
					icon: mozaicIconClass,
					label: labelClass,
					rightBottomAccessory: iconClass,
				} }
				placeholder={ t('preview.aside.lysDiscount.promoCode.placeholder') }
			/>
		</div>
	)
}

export { CartPreviewDiscountLysPromoCode }
