import React, { FocusEvent, ForwardedRef, forwardRef, ReactElement, useCallback, useState } from 'react'
import classNames from 'classnames'
import { useToggle } from 'react-use'
import { isEmpty, isFunction, isUndefined } from 'lodash'

import { IInputBaseProps, InputPasswordToggle, Label } from '~/components/core/form'
import { Tooltip } from '~/components/core/tooltip'
import { useMozaic } from '~/hooks/mozaic'
import { Icon } from '~/components/core/icon'

import styles from './InputBase.module.scss'

export const InputBase = forwardRef<HTMLInputElement, IInputBaseProps>((props: IInputBaseProps, forwardedRef: ForwardedRef<HTMLInputElement>): ReactElement => {
	const {
		name, label, type, value, placeholder, isInvalid, isValid, isRequired, disabled, onBlur, onFocus, onChange, onWrapperClick, aside, additionalLabel, ariaLabelledBy,
		tooltipLabel = '',
		prefix = '',
		additionalClass = '',
		theme = {},
		dataTestId = '',
		ariaLabel = '',
		autoComplete = undefined,
	} = props
	const [isPasswordVisible, toggleIsPasswordVisible] = useToggle(false)
	const [isFocusedByPointer, setIsFocusedByPointer] = useState<boolean>(false)
	const [isFocusInput, setIsFocusInput] = useState<boolean>(false)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const inputIcon = isInvalid ? 'errorIcon' : 'checkCircle'
	const inputIconColor = isInvalid ? 'mozaicDanger600' : 'mozaicSecondaryGreen500'

	const hasLabel = !isUndefined(name) && !isUndefined(label) && !isEmpty(label)
	const hasTooltipLabel = !isEmpty(tooltipLabel)
	const hasPrefix = !isEmpty(prefix)
	const isPasswordType = type === 'password'
	const inputType = isPasswordType && isPasswordVisible ? 'text' : type
	const hasWrapperAriaHidden = isFunction(onWrapperClick)
	const hasMozaicIcon = shouldUseMozaic && !isPasswordType && (isInvalid || isValid)

	const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
		setIsFocusInput(true)

		if (isFunction(onFocus)) {
			onFocus(event)
		}
	}, [onFocus])

	const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
		setIsFocusInput(false)
		setIsFocusedByPointer(false)

		if (isFunction(onBlur)) {
			onBlur(event)
		}
	}, [onBlur])

	const handlePointerOver = useCallback(() => {
		setIsFocusedByPointer(true)
	}, [])

	const wrapperClass = classNames(styles.wrapper, theme.wrapper, additionalClass)

	const formControlClass = classNames(styles.formControl, {
		[styles.valid]: isValid,
		[styles.invalid]: isInvalid,
		[styles.isMozaic]: shouldUseMozaic,
	})

	const labelWrapperClass = classNames(styles.label, theme.labelWrapper)

	const mainLabelClass = classNames(styles.mainLabel, theme.label, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const inputClass = classNames(styles.input, theme.input, {
		[styles.valid]: isValid,
		[styles.invalid]: isInvalid,
		[styles.focused]: isFocusedByPointer,
		[styles.isMozaic]: shouldUseMozaic,
		[styles.isMozaicDisabled]: shouldUseMozaic && disabled,
		[styles.isPasswordType]: isPasswordType,
		[styles.hasMozaicIcon]: hasMozaicIcon,
	})

	const prefixClass = classNames(styles.prefix, theme.prefix)

	const inputWrapperClass = classNames(styles.inputWrapper, {
		[styles.valid]: isValid,
		[styles.invalid]: isInvalid,
		[styles.focus]: shouldUseMozaic && isFocusInput,
		[styles.isMozaic]: shouldUseMozaic,
		[styles.isPasswordType]: isPasswordType,
	})

	const additionalLabelClass = classNames(styles.additionalLabel, theme.additionalLabel)

	const iconClass = classNames(styles.icon, theme.icon)

	return (
		<div className={ wrapperClass }>
			{ hasLabel && (
				<div className={ labelWrapperClass }>
					<Label
						name={ name }
						caption={ label }
						isRequired={ isRequired }
						additionalClass={ mainLabelClass }
					/>

					{ !isEmpty(additionalLabel) && (
						<span className={ additionalLabelClass }>
							{ additionalLabel }
						</span>
					) }

					{ hasTooltipLabel && (
						<Tooltip content={ tooltipLabel } theme={ { wrapper: styles.tooltip } } />
					) }
				</div>
			) }

			<div className={ formControlClass }>
				{ /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, react/jsx-newline */ }
				<div
					aria-hidden={ hasWrapperAriaHidden }
					className={ inputWrapperClass }
					onClick={ onWrapperClick }
				>
					{ hasPrefix && (
						<span className={ prefixClass }>
							{ prefix }
						</span>
					) }

					<input
						ref={ forwardedRef }
						className={ inputClass }
						disabled={ disabled }
						id={ name }
						name={ name }
						placeholder={ placeholder }
						type={ inputType }
						value={ value }
						aria-label={ ariaLabel }
						data-testid={ dataTestId }
						autoComplete={ autoComplete }
						aria-labelledby={ ariaLabelledBy }
						onBlur={ handleBlur }
						onChange={ onChange }
						onFocus={ handleFocus }
						onPointerOver={ handlePointerOver }
					/>

					{ aside }

					{ hasMozaicIcon && (
						<Icon
							name={ inputIcon }
							width={ 22 }
							height={ 22 }
							color={ inputIconColor }
							additionalClass={ iconClass }
						/>
					) }
				</div>

				{ isPasswordType && (
					<InputPasswordToggle
						isVisible={ isPasswordVisible }
						additionalClass={ styles.passwordButton }
						onClick={ toggleIsPasswordVisible }
					/>
				) }
			</div>
		</div>
	)
})
