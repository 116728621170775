import React, { createContext, ReactElement } from 'react'

import { IFlatTreeProviderProps } from '~/providers/flatTreeProvider'
import { IProductCatalogData } from '~/api/dataTypes/catalog'

const initialProps: IProductCatalogData[] = []

export const FlatTreeContext = createContext(initialProps)

export const FlatTreeProvider = (props: IFlatTreeProviderProps): ReactElement => {
	const { children, value } = props

	return (
		<FlatTreeContext.Provider value={ value }>
			{ children }
		</FlatTreeContext.Provider>
	)
}
