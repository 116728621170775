import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { ICartAsideDeliveryCostProps } from '~/components/core/cartAside'
import { usePrice } from '~/hooks/price'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartAsideDeliveryCost.module.scss'

const CartAsideDeliveryCost = (props: ICartAsideDeliveryCostProps): ReactElement => {
	const { deliveryCost } = props
	const { t } = useTranslation(['cart'])
	const { priceFormat } = usePrice()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	return (
		<div className={ wrapperClass }>
			<span>
				{ t('aside.summary.deliveryCost') }
			</span>

			<span>
				{ priceFormat(deliveryCost, { precision: 2 }) }
			</span>
		</div>
	)
}

export { CartAsideDeliveryCost }
