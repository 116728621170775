import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { CartCustomerDataItemButton, CartCustomerDataItemButtonType, ICartCustomerDataItemProps } from '~/components/cart/cartCustomer'
import { Icon } from '~/components/core/icon'
import { VisuallyHiddenWrapper } from '~/components/core/visuallyHiddenWrapper'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerDataItem.module.scss'

const CartCustomerDataItem = (props: ICartCustomerDataItemProps): ReactElement => {
	const {
		isActive, onSelect, children, id, onOpen, isCompany,
		isMainData = false,
		Header = null,
	} = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const buttonType: CartCustomerDataItemButtonType = isActive ? 'edit' : 'select'

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.mainData]: isMainData,
		[styles.active]: isActive,
		[styles.company]: isCompany,
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass } id={ `address-${ id }` }>
			<VisuallyHiddenWrapper>
				<span>
					{ `${ buttonType === 'edit' ? t('customer.clientData.edit') : t('customer.clientData.select') } ${ t('customer.clientData.title') } - ` }
				</span>
			</VisuallyHiddenWrapper>

			<div>
				{ Header }
			</div>

			<span className={ styles.addressSection }>
				<VisuallyHiddenWrapper>
					<span>
						{ `${ t('customer.clientData.address') } - ` }
					</span>
				</VisuallyHiddenWrapper>

				{ children }
			</span>

			<CartCustomerDataItemButton
				buttonType={ buttonType }
				ariaLabelledBy={ `address-${ id }` }
				onSelect={ onSelect }
				onEdit={ onOpen }
			/>

			<div className={ styles.check }>
				<Icon
					name="checkmark"
					width={ 15 }
					height={ 11 }
					color="white"
				/>
			</div>
		</div>
	)
}

export { CartCustomerDataItem }
