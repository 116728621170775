import { AxiosError } from 'axios'
import pino from 'pino'
import { isUndefined } from 'lodash'

import { vars } from '~/statics'
import { ILogErrorObject } from '~/utils/logger'
import { getEmptyStringForNull } from '~/utils/string'
import { ILogData } from '~/api/dataTypes/log'

// GCP severity log: https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#logseverity
const levelToSeverity: Record<string, string> = {
	emerg: 'EMERGENCY',
	alert: 'ALERT',
	crit: 'CRITICAL',
	error: 'ERROR',
	warn: 'WARNING',
	notice: 'NOTICE',
	info: 'INFO',
	debug: 'DEBUG',
}

const levels: Record<string, number> = {
	emerg: 80,
	alert: 70,
	crit: 60,
	error: 50,
	warn: 40,
	notice: 30,
	info: 20,
	debug: 10,
}

export const logger = pino({
	enabled: vars.isDeveloperMode ? true : !vars.isBrowserMode,
	customLevels: levels,
	useOnlyCustomLevels: true,
	transport: vars.isDeveloperMode ? {
		target: 'pino-pretty',
	} : undefined,
	browser: {
		asObject: true,
	},
	timestamp: () => `${ new Date(Date.now()).toISOString() }`,
	formatters: {
		level: (label: string) => {
			return {
				severity: levelToSeverity[label],
			}
		},
	},
})

export const log: ILogErrorObject = {
	clientError: (error: Error, url: string): ILogData => {
		const { message, stack } = error

		return {
			httpStatusCode: 0,
			url,
			message,
			stackTrace: stack,
			severity: 'ERROR',
			tags: ['CLIENT'],
		}
	},
	axiosError: (error: AxiosError): ILogData => {
		const { config: { service, url }, response, message, stack } = error

		return {
			httpStatusCode: response?.status,
			url: getEmptyStringForNull(url),
			message: response?.data.code || message,
			stackTrace: stack,
			severity: 'ERROR',
			tags: !isUndefined(service) ? [service] : undefined,
		}
	},
}
