import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { FAILURE, SUCCESS, REQUEST } from '~/statics'
import { StoresListActionType, GetSimpleStoreListActionType } from '~/actions/stores'

import { IContactFormState } from './types'
import { contactFormInitialState as initialState } from './constants'

export type ContactFormAppActionType = GetSimpleStoreListActionType

export const contactForm = produce((draft: Draft<IContactFormState>, action: AppPromiseAction<ContactFormAppActionType>) => {
	switch (action.type) {
		case REQUEST(StoresListActionType.GET_SIMPLE_STORE_LIST):
			draft.isPending = true

			return draft
		case SUCCESS(StoresListActionType.GET_SIMPLE_STORE_LIST):
			draft.isPending = false
			draft.storeList = action.payload.data

			return draft
		case FAILURE(StoresListActionType.GET_SIMPLE_STORE_LIST):
			draft.isPending = false

			return draft
	}
}, initialState)
