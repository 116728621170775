import React, { ReactElement, useCallback, useContext } from 'react'

import { CartCustomerInvoiceHeader, ICartCustomerInvoiceProps, CartCustomerInvoiceCashAndCarry, CartCustomerInvoiceDelivery, CartCustomerInvoiceGuestDelivery } from '~/components/cart/cartCustomer'
import { UserContext } from '~/providers/userProvider'

const CartCustomerInvoice = (props: ICartCustomerInvoiceProps): ReactElement => {
	const { receiptNip, totalCostValue, invoice, customerAddressId, customerOtherContact, customerData, isAnotherReceiverActive, isMainFormIncomplete, guestCustomerType, isInvoiceRequired } = props
	const { isLogged } = useContext(UserContext)

	const renderInvoiceType = useCallback((): ReactElement => {
		if (isInvoiceRequired) {
			if (isLogged) {
				return (
					<CartCustomerInvoiceDelivery
						isDeliveryType
						isInvoiceRequired={ isInvoiceRequired }
						invoice={ invoice }
						totalCostValue={ totalCostValue }
						customerAddressId={ customerAddressId }
						customerData={ customerData }
						isAnotherReceiverActive={ isAnotherReceiverActive }
						customerOtherContact={ customerOtherContact }
						isMainFormIncomplete={ isMainFormIncomplete }
					/>
				)
			}

			return (
				<CartCustomerInvoiceGuestDelivery
					isDeliveryType
					isInvoiceRequired={ isInvoiceRequired }
					invoice={ invoice }
					totalCostValue={ totalCostValue }
					isMainFormIncomplete={ isMainFormIncomplete }
					guestCustomerType={ guestCustomerType }
				/>
			)
		}

		return (
			<CartCustomerInvoiceCashAndCarry
				invoice={ invoice }
				totalCostValue={ totalCostValue }
				receiptNip={ receiptNip }
			/>
		)
	}, [isInvoiceRequired, isAnotherReceiverActive, customerOtherContact, customerData, customerAddressId, isMainFormIncomplete, receiptNip, invoice])

	return (
		<section>
			<CartCustomerInvoiceHeader />

			{ renderInvoiceType() }
		</section>
	)
}

export { CartCustomerInvoice }
