import React, { ReactElement, useCallback } from 'react'
import { FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { ICartCustomerGuestFormCorporateWrapperProps } from '~/components/cart/cartCustomer'
import { ICartCustomerGuestData } from '~/api/dataTypes/cart'
import { AppDispatch } from '~/state/store'
import { setCartCustomerGuest } from '~/actions/cartCustomer'
import { ICartCustomerGuestCompanyForm } from '~/hooks/cartGuestCustomerForm'

const CartCustomerGuestFormCompanyWrapper = (props: ICartCustomerGuestFormCorporateWrapperProps): ReactElement => {
	const { children, formProps, onNext } = props
	const dispatch: AppDispatch = useDispatch()

	const { control, handleSubmit } = formProps

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerGuestCompanyForm) => {
		const { customerTitle, firstName, lastName, email, phoneNumber: phone, city, postalCode: zipCode, street, houseNumber, flatNumber, corporateName: companyName, nip, regulations } = formData

		const params: ICartCustomerGuestData = {
			customerType: 'COMPANY',
			customerTitle,
			companyName,
			nip,
			firstName,
			lastName,
			email,
			phone,
			address: {
				city,
				zipCode,
				street,
				houseNumber,
				flatNumber,
			},
			regulations,
		}

		await dispatch(setCartCustomerGuest(params))

		await onNext(params)
	}), [onNext, control, handleSubmit])

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<FormProvider { ...formProps }>
			<form onSubmit={ handleFormSubmit }>
				{ children }
			</form>
		</FormProvider>
	)
}

export { CartCustomerGuestFormCompanyWrapper }
