import React, { ChangeEvent, ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { FieldValues, useController } from 'react-hook-form'
import IMask from 'imask'
import { isEmpty, isFunction, isUndefined, isString, toString } from 'lodash'

import { ErrorBoundary, getInputAriaLabel, IInputProps, InputBase, MaskedInput } from '~/components/core/form'

import styles from './Input.module.scss'

const Input = <FormFields extends FieldValues = FieldValues>(props: IInputProps<FormFields>): ReactElement => {
	const {
		name, control, type, aside, additionalLabel, ariaLabelledBy,
		isDisabled = false,
		isRequired = false,
		mask = undefined,
		additionalClass = '',
		prefix = '',
		label = '',
		tooltipLabel = '',
		placeholder = '',
		theme = {},
		dataTestId = '',
		RightBottomAccessory = null,
		autoComplete = undefined,
		onChange = undefined,
	} = props
	const { field, fieldState: { error, invalid } } = useController<FormFields>({ control, name })

	const isValid = !invalid && !isEmpty(field.value)
	const hasMask = !isUndefined(mask)
	const ariaLabel = getInputAriaLabel(label, additionalLabel, error)

	const handleMaskedInputAccept = useCallback((value: unknown): void => {
		// @ts-expect-error: "mask" prop type is not fully compatible
		const unmaskedValue = IMask.pipe(value, { mask }, IMask.PIPE_TYPE.UNMASKED)

		if (isEmpty(unmaskedValue)) {
			field.onChange(unmaskedValue)
		}
	}, [field.onChange, mask])

	const handleChange = useCallback(async (value: unknown): Promise<void> => {
		field.onChange(value)

		if (isFunction(onChange)) {
			await onChange(isString(value) ? toString(value) : (value as ChangeEvent<HTMLInputElement>).target.value)
		}
	}, [field.onChange, onChange])

	const wrapperClass = classNames(styles.wrapper, theme.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	}, theme.wrapper)

	const inputWrapperClass = classNames(styles.inputWrapper, theme.inputWrapper)

	const inputClass = classNames(theme.input, {
		[styles.maskInput]: hasMask,
	})

	const rightAccessoryClass = classNames(styles.rightBottomAccessory, theme.rightBottomAccessory)

	const renderInput = useCallback((): ReactElement => hasMask ? (
		// @ts-expect-error: "mask" prop type is not fully compatible
		<MaskedInput
			mask={ mask }
			disabled={ isDisabled }
			inputRef={ field.ref }
			isInvalid={ invalid }
			isRequired={ isRequired }
			isValid={ isValid }
			label={ label }
			additionalLabel={ additionalLabel }
			tooltipLabel={ tooltipLabel }
			prefix={ prefix }
			lazy={ false }
			name={ name }
			aside={ aside }
			placeholderChar="_"
			type={ type }
			value={ field.value }
			autoComplete={ autoComplete }
			ariaLabel={ ariaLabel }
			theme={ {
				input: inputClass,
				label: theme.label,
				labelWrapper: theme.labelWrapper,
				additionalLabel: theme.additionalLabel,
				prefix: theme.prefix,
				wrapper: inputWrapperClass,
				icon: theme.icon,
			} }
			ariaLabelledBy={ ariaLabelledBy }
			onAccept={ handleMaskedInputAccept }
			onComplete={ handleChange }
			onBlur={ field.onBlur }
		/>
	) : (
		<InputBase
			disabled={ isDisabled }
			isInvalid={ invalid }
			isRequired={ isRequired }
			isValid={ isValid }
			label={ label }
			additionalLabel={ additionalLabel }
			tooltipLabel={ tooltipLabel }
			placeholder={ placeholder }
			prefix={ prefix }
			type={ type }
			aside={ aside }
			dataTestId={ dataTestId }
			autoComplete={ autoComplete }
			ariaLabel={ ariaLabel }
			theme={ {
				input: inputClass,
				labelWrapper: theme.labelWrapper,
				inputParentClass: theme.inputParentClass,
				label: theme.label,
				additionalLabel: theme.additionalLabel,
				prefix: theme.prefix,
				wrapper: inputWrapperClass,
				icon: theme.icon,
			} }
			ariaLabelledBy={ ariaLabelledBy }
			// eslint-disable-next-line react/jsx-props-no-spreading
			{ ...field }
			onChange={ handleChange }
		/>
	), [label, additionalLabel, isDisabled, type, invalid, isValid, isRequired, inputClass, field, ariaLabelledBy, theme])

	return (
		<div className={ wrapperClass }>
			{ renderInput() }

			<div className={ rightAccessoryClass }>
				{ RightBottomAccessory }
			</div>

			<ErrorBoundary error={ error } />
		</div>
	)
}

export { Input }
