import React, { createContext, ReactElement, useMemo, useEffect } from 'react'
import { isUndefined } from 'lodash'

import { localStorageSet } from '~/utils/storage'
import { getFlagBehindKobiIntegrationEnabled, IAppParametersProviderProps } from '~/providers/appParametersProvider'
import { PLANNER_CUSTOMER_SERVICE_NEW } from '~/utils/constants'
import { IAppParametersState } from '~/api/dataTypes/app'

const initialProps: IAppParametersState = {} as IAppParametersState

export const AppParametersContext = createContext(initialProps)

export const AppParametersProvider = (props: IAppParametersProviderProps): ReactElement => {
	const { children, value } = props

	const appParamsValue = useMemo((): IAppParametersState => ({
		...value,
		kobiConfig: {
			...value?.kobiConfig,
			integrationEnabled: getFlagBehindKobiIntegrationEnabled(value?.kobiConfig, 'integrationEnabled'),
		},
	}), [value])

	useEffect(() => {
		if (!isUndefined(value.planner)) {
			const { plsAsPlannerSourceEnabled } = value.planner

			if (!isUndefined(plsAsPlannerSourceEnabled)) {
				localStorageSet(PLANNER_CUSTOMER_SERVICE_NEW, plsAsPlannerSourceEnabled)
			}
		}
	}, [value])

	return (
		<AppParametersContext.Provider value={ appParamsValue }>
			{ children }
		</AppParametersContext.Provider>
	)
}
