import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { ICartItemListLabel } from '~/api/dataTypes/cart'
import { ICartPreviewProductsLabelsProps, CartPreviewProductsLabelsItem } from '~/components/cart/cartPreview'

import styles from './CartPreviewProductsLabels.module.scss'

const CartPreviewProductsLabels = (props: ICartPreviewProductsLabelsProps): ReactElement => {
	const { labels } = props

	const renderContent = useCallback(() => (
		map(labels, (item: ICartItemListLabel) => {
			const { message } = item

			return (
				<CartPreviewProductsLabelsItem key={ message } label={ item } />
			)
		})
	), [labels])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { CartPreviewProductsLabels }
