import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsHeader.module.scss'

const CartPreviewProductsHeader = (): ReactElement | null => {
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	return (
		<div className={ wrapperClass }>
			<div className={ styles.product }>
				{ t('preview.table.header.product') }
			</div>

			<div className={ styles.availability }>
				{ t('preview.table.header.availability') }
			</div>

			<div className={ styles.price }>
				{ t('preview.table.header.price') }
			</div>

			<div className={ styles.quantity }>
				{ t('preview.table.header.quantity') }
			</div>

			<div className={ styles.summaryPrice }>
				{ t('preview.table.header.summaryPrice') }
			</div>

			<div className={ styles.action } />
		</div>
	)
}

export { CartPreviewProductsHeader }
