import { AxiosResponse } from 'axios'

import { AppPromiseThunkAction } from '~/actions'
import { withFresherToken } from '~/actions/auth'
import { PreferredStoreActionType, PreferredStoresListGetType, PreferredStoreSetType, PreferredStoreClearType, PreferredStoreSetCustomerType, IPreferredStoreData } from '~/actions/preferredStore'
import { localStorageRemove, localStorageSet } from '~/utils/storage'
import { LS_KEY_PREFERRED_STORE, COOKIE_KEY_PREFERRED_STORE, COOKIE_MAX_AGE_PREFERRED_STORE_CODE } from '~/utils/constants'
import { cookieRemove, cookieSet } from '~/utils/cookies'
import { IStoreResponse } from '~/api/dataTypes/store'
import { putCustomerStore, deleteCustomerStore, getCustomerStore } from '~/api/requests/customerStore'
import { getStores, getStore } from '~/api/requests/store'

// eslint-disable-next-line @typescript-eslint/typedef
export const getPreferredStoresList = (): AppPromiseThunkAction<PreferredStoresListGetType> => async (dispatch) => {
	return dispatch({
		type: PreferredStoreActionType.GET_PREFERRED_STORES_LIST,
		payload: getStores(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setPreferredStore = (storeCode: string, isLogged: boolean): AppPromiseThunkAction<PreferredStoreSetType> => async (dispatch) => {
	const { data }: AxiosResponse<IStoreResponse> = await getStore({ storeCode })
	const { id, name } = data

	const params: IPreferredStoreData = {
		id,
		storeCode,
		name,
	}

	localStorageSet(LS_KEY_PREFERRED_STORE, params)
	cookieSet(COOKIE_KEY_PREFERRED_STORE, { id, storeCode }, `path=/; SameSite=Strict; max-age=${ COOKIE_MAX_AGE_PREFERRED_STORE_CODE }`)

	return dispatch({
		type: PreferredStoreActionType.SET_PREFERRED_STORE,
		payload: async () => {
			try {
				if (isLogged) {
					await putCustomerStore(storeCode)
				}

				return Promise.resolve(data)
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearPreferredStore = (isLogged: boolean): AppPromiseThunkAction<PreferredStoreClearType> => async (dispatch) => {
	localStorageRemove(LS_KEY_PREFERRED_STORE)
	cookieRemove(COOKIE_KEY_PREFERRED_STORE, 'path=/')

	return dispatch({
		type: PreferredStoreActionType.CLEAR_PREFERRED_STORE,
		payload: async () => {
			try {
				if (isLogged) {
					await deleteCustomerStore()
				}

				return Promise.resolve()
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getCustomerPreferredStore = withFresherToken((): AppPromiseThunkAction<PreferredStoreSetCustomerType> => async (dispatch) => {
	return dispatch({
		type: PreferredStoreActionType.GET_CUSTOMER_PREFERRED_STORE,
		payload: async () => {
			try {
				const { data: { storeCode } } = await getCustomerStore()

				return getStore({ storeCode })
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
})
