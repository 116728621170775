import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { useMozaic } from '~/hooks/mozaic'
import { Icon } from '~/components/core/icon'
import { ICartCustomerContactItemProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerContactItem.module.scss'

const CartCustomerContactItem = (props: ICartCustomerContactItemProps): ReactElement => {
	const { phoneNumber, phoneNumberPrefix } = props
	const { t } = useTranslation(['cart', 'common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const renderPrefixContent = useCallback((): ReactElement => (
		shouldUseMozaic ? (
			<>
				<Icon name="flagPolish" width={ 16 } />

				<span>{ phoneNumberPrefix }</span>
			</>
		) : (
			<span>({ phoneNumberPrefix })</span>
		)
	), [shouldUseMozaic, phoneNumberPrefix])

	return (
		<div className={ wrapperClass }>
			<div className={ styles.label }>
				<span>
					{ t('customer.contact.label') }{ ' ' }
				</span>

				<span className={ styles.info }>
					{ t('customer.contact.labelInfo') }
				</span>
			</div>

			<div className={ styles.content }>
				<div className={ styles.prefix }>
					{ renderPrefixContent() }
				</div>

				<div className={ styles.number }>
					{ phoneNumber }
				</div>
			</div>
		</div>
	)
}

export { CartCustomerContactItem }
