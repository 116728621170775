import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { ICartCustomerFormButtonsProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerFormButtons.module.scss'

const CartCustomerFormButtons = (props: ICartCustomerFormButtonsProps): ReactElement => {
	const {
		onCancel, isPending,
		isDisabled = false,
	} = props
	const { t } = useTranslation(['common'])

	return (
		<div className={ styles.wrapper }>
			<Button
				type="submit"
				text={ t('saveChanges') }
				variant="secondary"
				additionalClass={ styles.button }
				isLoading={ isPending }
				isDisabled={ isDisabled }
			/>

			<Button
				isReverse
				text={ t('cancel') }
				variant="secondary"
				additionalClass={ styles.button }
				onClick={ onCancel }
			/>
		</div>
	)
}

export { CartCustomerFormButtons }
