import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { CartCustomerDataAddForm, ICartCustomerDataAddAddressButtonProps } from '~/components/cart/cartCustomer'
import { useModal } from '~/hooks/modal'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerDataAddAddressButton.module.scss'

const CartCustomerDataAddAddressButton = (props: ICartCustomerDataAddAddressButtonProps): ReactElement => {
	const { onAddNewAddress } = props
	const { t } = useTranslation(['cart'])
	const { isOpen, handleOpen, handleClose } = useModal('CartCustomerDataAdd')
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<>
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ wrapperClass }
				ariaLabel={ t('customer.clientData.addAddress') }
				onClick={ handleOpen }
			>
				<Icon
					name="add"
					width={ 14 }
					additionalClass={ styles.icon }
				/>

				<span>
					{ t('customer.clientData.addAddress') }
				</span>
			</Button>

			<CartCustomerDataAddForm
				isOpen={ isOpen }
				onOpen={ handleOpen }
				onClose={ handleClose }
				onAddAddress={ onAddNewAddress }
			/>
		</>
	)
}

export { CartCustomerDataAddAddressButton }
