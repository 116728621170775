import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './WishListModalContent.module.scss'

const WishListModalContent = (): ReactElement => {
	const { t } = useTranslation(['favorites'])

	return (
		<section className={ styles.wrapper }>
			<span className={ styles.caption }>
				{ t('modal.notLogged.title') }
			</span>

			<span className={ styles.info }>
				{ t('modal.notLogged.info') }
			</span>
		</section>
	)
}

export { WishListModalContent }
