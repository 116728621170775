import React, { ReactElement, useCallback } from 'react'
import { isEmpty, map } from 'lodash'

import { HeaderTopBarSearchBarHistoryItem, IHeaderTopBarSearchBarHistoryListProps, ISearchHistoryItem } from '~/components/core/layout'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarSearchBarHistoryList.module.scss'

const HeaderTopBarSearchBarHistoryList = (props: IHeaderTopBarSearchBarHistoryListProps): ReactElement | null => {
	const { history } = props

	const renderHistoryList = useCallback((): ReactElement[] => (
		map(history, (historyItem: ISearchHistoryItem) => (
			<HeaderTopBarSearchBarHistoryItem key={ historyItem.val } historyItem={ historyItem } />
		))
	), [history])

	if (isEmpty(history)) {
		return null
	}

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.HEADER.SEARCH_BAR_HISTORY_LIST }>
			{ renderHistoryList() }
		</div>
	)
}

export { HeaderTopBarSearchBarHistoryList }
