import { map } from 'lodash'

import { ICartDeliveryNextDataItemList } from '~/actions/cart'
import { ICartItemData, IProductsQuantityMap } from '~/api/dataTypes/cart'

export const buildItemQuantityList = (cartItemList: ICartItemData[], productsQuantityMap: IProductsQuantityMap): ICartDeliveryNextDataItemList[] => (
	map(cartItemList, (item: ICartItemData) => {
		const { itemId, productSimple } = item

		return {
			itemId: itemId,
			quantity: productsQuantityMap[productSimple.lmReference],
		}
	})
)
