import React, { ReactElement } from 'react'
import { isEqual } from 'lodash'

import { CartCustomerGuestFormCompanyWrapper, CartCustomerGuestFormPrivateWrapper, ICartSummaryGuestFormWrapperProps } from '~/components/cart/cartCustomer'

const CartSummaryGuestFormWrapper = (props: ICartSummaryGuestFormWrapperProps): ReactElement => {
	const { children, type, privateFormProps, companyFormProps, onNext } = props

	if (isEqual(type, 'PRIVATE')) {
		return (
			<CartCustomerGuestFormPrivateWrapper formProps={ privateFormProps } onNext={ onNext }>
				{ children }
			</CartCustomerGuestFormPrivateWrapper>
		)
	}

	if (isEqual(type, 'COMPANY')) {
		return (
			<CartCustomerGuestFormCompanyWrapper formProps={ companyFormProps } onNext={ onNext }>
				{ children }
			</CartCustomerGuestFormCompanyWrapper>
		)
	}

	return (
		<div>
			{ children }
		</div>
	)
}

export { CartSummaryGuestFormWrapper }
