import React, { ReactElement, useRef, useEffect, useContext } from 'react'
import classNames from 'classnames'
import { usePopper } from 'react-popper'

import { HeaderStickyModalHeader, HeaderStickyModalOverlay, IHeaderStickyModalProps } from '~/components/core/layout'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { HeaderContext } from '~/providers/headerProvider'

import styles from './HeaderStickyModal.module.scss'

const HeaderStickyModal = (props: IHeaderStickyModalProps): ReactElement => {
	const {
		children, buttonRef, isOpen, onClose,
		dataTestId = '',
		theme = {},
	} = props
	const contentRef = useRef<HTMLDivElement | null>(null)
	const { isTablet } = useMediaQuery()
	const { headerHeight } = useContext(HeaderContext)

	const { styles: popperStyles, attributes, update } = usePopper(buttonRef.current, contentRef.current, {
		strategy: 'fixed',
		placement: 'bottom-end',
	})

	const contentClass = classNames(styles.content, theme.content, {
		[styles.active]: isOpen,
		[styles.mobile]: !isTablet,
	})

	useEffect(() => {
		(async () => {
			if (update) {
				await update()
			}
		})()
	}, [update, headerHeight])

	return (
		<>
			<div
				ref={ contentRef }
				role="dialog"
				style={ isTablet ? popperStyles.popper : {} }
				className={ contentClass }
				data-testid={ dataTestId }
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...attributes.popper }
			>
				{ !isTablet && <HeaderStickyModalHeader onClose={ onClose } /> }

				{ children }
			</div>

			<HeaderStickyModalOverlay isOpen={ isOpen } />
		</>
	)
}

export { HeaderStickyModal }
