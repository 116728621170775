import { AppPromiseThunkAction } from '~/actions'
import { ICalendarIdentificationResponse } from '~/api/dataTypes/calendarService'
import { GetServiceCartCalendarUuidType, GetServiceCartIdentificationType, ResetServiceCartIdentificationType, ServiceCartIdentificationType } from '~/actions/serviceCartIdentification'

// eslint-disable-next-line @typescript-eslint/typedef
export const postServiceCartIdentificationData = (data: ICalendarIdentificationResponse): AppPromiseThunkAction<GetServiceCartIdentificationType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartIdentificationType.SERVICE_CART_IDENTIFICATION,
		payload: Promise.resolve(data),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const resetServiceCartIdentificationData = (): AppPromiseThunkAction<ResetServiceCartIdentificationType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartIdentificationType .RESET_SERVICE_CART_IDENTIFICATION,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartCalendarUuid = (calendarUuid: string | null): AppPromiseThunkAction<GetServiceCartCalendarUuidType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartIdentificationType.GET_SERVICE_CART_CALENDAR_UUID,
		payload: Promise.resolve(calendarUuid),
	})
}
