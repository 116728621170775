import React, { ReactElement, useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { FieldPath, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { i18n, useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { gt, isUndefined, map, replace, size } from 'lodash'

import { CartCustomerOrderCollectionModalFormActions, ICartCustomerOrderCollectionModalCompanyForm, ICartCustomerOrderCollectionModalCompanyProps } from '~/components/cart/cartCustomer'
import { Input, RequiredInfo } from '~/components/core/form'
import { validation } from '~/utils/validation'
import { AppDispatch } from '~/state/store'
import { validateCartCustomerCompanyContact } from '~/actions/cartCustomer'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { hasNotNumberAndAllowOnlyDash, hasOnlyLetters, hasOnlyLettersNumbersSpacesDotsOrDash } from '~/utils/string'

const CartCustomerOrderCollectionModalCompanyForm = (props: ICartCustomerOrderCollectionModalCompanyProps): ReactElement => {
	const { onCancel, onClose, defaultValues } = props
	const { t } = useTranslation(['cart', 'form', 'common'])
	const dispatch: AppDispatch = useDispatch()
	const { newAlert } = useAlert()
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const { sendLogError } = useLogError()

	const { companyName, email, phone, firstName, lastName } = defaultValues
	const { mask, regex } = validation

	const companyNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(100, t('mustBeShortestThen100', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.orderCollection.form.companyNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('customer.clientData.form.validation.corporateNameRequired', { ns: 'cart' })), [t, i18n])
	const firstNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLetters', t('validation.unexpectedCharacters', { field: t('customer.orderCollection.form.firstNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLetters(value)).required(t('customer.clientData.form.validation.firstNameRequired', { ns: 'cart' })), [t, i18n])
	const lastNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasNotNumberAndAllowOnlyDash', t('validation.unexpectedCharacters', { field: t('customer.orderCollection.form.lastNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasNotNumberAndAllowOnlyDash(value)).required(t('customer.clientData.form.validation.lastNameRequired', { ns: 'cart' })), [t, i18n])

	const schema: yup.SchemaOf<ICartCustomerOrderCollectionModalCompanyForm> = useMemo(() => yup.object().shape({
		companyName: companyNameSchema,
		firstName: firstNameSchema,
		lastName: lastNameSchema,
		email: yup.string().max(50, t('mustBeShortestThen50', { ns: 'form' })).email(t('validation.emailFormat', { ns: 'form' })).required(t('required', { ns: 'form' })),
		phone: yup.string().length(9, t('validation.phoneNumberLength', { ns: 'form' })).required(t('required', { ns: 'form' })),
	}), [t, i18n])

	const { control, handleSubmit, setError, setValue } = useForm<ICartCustomerOrderCollectionModalCompanyForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			companyName: companyName || '',
			firstName: firstName || '',
			lastName: lastName || '',
			email: email || '',
			phone: phone || '',
		},
		mode: 'onChange',
	})

	const placeholder = replace(mask.phone, /0/g, '_')

	const handleChangePhone = useCallback((value: string): void => {
		let inputValue = value
		const maskSize = size(mask.phone)

		if (gt(size(value), maskSize)) {
			inputValue = replace(value, regex.phoneWithoutAreaCode, '')
		}

		setValue('phone', replace(inputValue, regex.onlyDigits, '').substring(0, maskSize))
	}, [])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerOrderCollectionModalCompanyForm) => {
		try {
			await dispatch(validateCartCustomerCompanyContact(formData))
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					setError(field as FieldPath<ICartCustomerOrderCollectionModalCompanyForm>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				newAlert('danger', getErrorMessage(code), 5000)
			}

			sendLogError(e)
		} finally {
			onClose()
		}
	}), [])

	return (
		<div>
			<Input
				isRequired
				control={ control }
				name="companyName"
				label={ t('customer.orderCollection.form.companyNameLabel') }
			/>

			<Input
				isRequired
				control={ control }
				name="firstName"
				label={ t('customer.orderCollection.form.firstNameLabel') }
			/>

			<Input
				isRequired
				control={ control }
				name="lastName"
				label={ t('customer.orderCollection.form.lastNameLabel') }
			/>

			<Input
				isRequired
				control={ control }
				name="email"
				label={ t('customer.orderCollection.form.emailLabel') }
			/>

			<Input
				isRequired
				control={ control }
				name="phone"
				prefix={ t('phoneNumberPrefix', { ns: 'common' }) }
				label={ t('customer.orderCollection.form.phoneNumberLabel') }
				placeholder={ placeholder }
				onChange={ handleChangePhone }
			/>

			<RequiredInfo />

			<CartCustomerOrderCollectionModalFormActions onSubmit={ handleFormSubmit } onCancel={ onCancel } />
		</div>
	)
}

export { CartCustomerOrderCollectionModalCompanyForm }
