import { TFunction } from 'next-i18next'

export const getSummaryDiscountButtonTranslate = (isLogged: boolean, isLysIntegrationEnabled: boolean, isStepZero: boolean, loyaltyType: string, t: TFunction): string => {
	if (isLysIntegrationEnabled) {
		if (isLogged) {
			return t('preview.aside.lysDiscount.loggedCaption', { loyaltyType })
		} else if (isStepZero) {
			return t('zero.aside.discount.notLoggedCaption')
		}

		return t('preview.aside.lysDiscount.notLoggedCaption')
	}

	if (isLogged) {
		return t('preview.aside.discount.loggedCaption')
	} else if (isStepZero) {
		return t('zero.aside.discount.notLoggedCaption')
	}

	return t('preview.aside.discount.notLoggedCaption')
}
