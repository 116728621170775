import React, { ReactElement, useCallback } from 'react'
import axios, { AxiosError } from 'axios'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import { isNil, isUndefined } from 'lodash'

import { IMergeCartModalActionsProps } from '~/components/core/mergeCartModal'
import { Button } from '~/components/core/button'
import { cartMerge, getCartData } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { useCartStatus } from '~/hooks/cartStatus'
import { useLogError } from '~/hooks/logError'
import { useFormError } from '~/hooks/formError'
import { useAlert } from '~/hooks/alert'

import styles from './MergeCartModalActions.module.scss'

const MergeCartModalActions = (props: IMergeCartModalActionsProps): ReactElement => {
	const { cartUuid, isOnCartView, onClose } = props
	const [isLoading, setIsLoading] = useToggle(false)
	const dispatch: AppDispatch = useDispatch()
	const { t } = useTranslation(['common'])
	const { newAlert } = useAlert()
	const { getErrorMessage } = useFormError()
	const { changeCartView } = useCartStatus()
	const { sendLogError } = useLogError()

	const handleClose = useCallback(async () => {
		try {
			const { value } = await dispatch(cartMerge({ cartUuid, merge: false }))

			await changeCartView(2, value.data.cartStatus)
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				if (!isNil(code)) {
					newAlert('danger', getErrorMessage(code), 5000)
				}
			}

			sendLogError(e)
		} finally {
			onClose()
		}
	}, [onClose, cartUuid])

	const handleMerge = useCallback(async () => {
		try {
			setIsLoading(true)
			const { value } = await dispatch(cartMerge({ cartUuid, merge: true }))

			if (isOnCartView) {
				await dispatch(getCartData())
				await changeCartView(2, value.data.cartStatus)
			}
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				if (!isNil(code)) {
					newAlert('danger', getErrorMessage(code), 5000)
				}
			}

			sendLogError(e)
		} finally {
			setIsLoading(false)
			onClose()
		}
	}, [onClose, cartUuid, isOnCartView])

	return (
		<div className={ styles.wrapper }>
			<Button
				isReverse
				additionalClass={ styles.button }
				text={ t('cancel') }
				onClick={ handleClose }
			/>

			<Button
				isLoading={ isLoading }
				additionalClass={ styles.button }
				text={ t('addToCart') }
				onClick={ handleMerge }
			/>
		</div>
	)
}

export { MergeCartModalActions }
