import React, { ReactElement, useCallback, useContext } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { isEmpty, isEqual, isUndefined } from 'lodash'

import { ICartPreviewDiscountLysCouponElementProps, formatDiscountValue } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons/cartPreviewDiscountLysCouponElement'
import { Button } from '~/components/core/button'
import { CustomerLoyaltyRewardStatusType } from '~/api/dataTypes/customerLoyalty'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { CartPreviewDiscountLysCouponDescription } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons/cartPreviewDiscountLysCouponDescription'
import { getCartPreviewData } from '~/state/reducers/cartPreviewReducer'

import styles from './CartPreviewDiscountLysCouponElement.module.scss'

const CartPreviewDiscountLysCouponElement = (props: ICartPreviewDiscountLysCouponElementProps): ReactElement | null => {
	const { reward, onSelectLoyaltyReward } = props
	const { cart: { promotionCodeUsed: isPromotionCodeUsed, promotionCode } } = useSelector(getCartPreviewData, shallowEqual)
	const { selectedReward: { code: selectedRewardCode }, isEditMode } = useContext(LysLoyaltyProviderContext)
	const { t } = useTranslation(['cart'])

	const { name, code } = reward

	const handleSelectReward = useCallback((rewardCode: string) => (): void => {
		onSelectLoyaltyReward(rewardCode)
	}, [onSelectLoyaltyReward])

	if (isUndefined(name) || isUndefined(code)) {
		return null
	}

	const { type, value, expiryDate, status } = reward
	const isUsed = isEqual(status, 'IN_USE' as CustomerLoyaltyRewardStatusType)
	const isSelectedCoupon = isEqual(code, !isEmpty(selectedRewardCode) ? selectedRewardCode : promotionCode)

	const buttonClass = classNames(styles.wrapper, {
		[styles.selected]: isSelectedCoupon,
		[styles.disabled]: isPromotionCodeUsed && !isEditMode,
	})

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ buttonClass }
			onClick={ handleSelectReward(code) }
		>
			<CartPreviewDiscountLysCouponDescription
				name={ name }
				expiryDate={ expiryDate }
				isUsed={ isUsed }
				isSelectedCoupon={ isSelectedCoupon }
			/>

			<span className={ styles.couponValue }>
				{ formatDiscountValue(value, type, t) }
			</span>
		</Button>
	)
}

export { CartPreviewDiscountLysCouponElement }
