import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { Link } from '~/components/core/link'
import { ICartCustomerAsideBlockProductNameProps } from '~/components/cart/cartCustomer'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerAsideBlockProductName.module.scss'

const CartCustomerAsideBlockProductName = (props: ICartCustomerAsideBlockProductNameProps): ReactElement => {
	const { name, wwwUrl } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<Link
			href={ wwwUrl }
			additionalClass={ wrapperClass }
			ariaLabel={ name }
		>
			{ name }
		</Link>
	)
}

export { CartCustomerAsideBlockProductName }
