import React, { ReactElement, useContext } from 'react'
import classNames from 'classnames'

import { IHamburgerMenuWrapperProps } from '~/components/core/hamburgerMenu'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HamburgerMenuWrapper.module.scss'

const HamburgerMenuWrapper = (props: IHamburgerMenuWrapperProps): ReactElement => {
	const { children } = props
	const { isOpen } = useContext(HamburgerMenuContext)

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.open]: isOpen,
	})

	const overlayClass = classNames({
		[styles.overlay]: true,
		[styles.visible]: isOpen,
	})

	return (
		<>
			<div className={ wrapperClass }>
				<div className={ styles.content } data-testid={ DATA_TESTID.CORE.HAMBURGER_MENU }>
					{ children }
				</div>
			</div>

			<div className={ overlayClass } />
		</>
	)
}

export { HamburgerMenuWrapper }
