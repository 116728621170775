import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { ICartPreviewDeliverySplitQuantityProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliverySplitQuantity.module.scss'

const CartPreviewDeliverySplitQuantity = (props: ICartPreviewDeliverySplitQuantityProps): ReactElement => {
	const { quantity, unit } = props

	const valueClass = classNames({
		[styles.value]: true,
		[styles.notAvailable]: isEqual(quantity, 0),
	})

	return (
		<div className={ styles.wrapper }>
			<div className={ valueClass }>
				{ `${ quantity } ${ unit }` }
			</div>
		</div>
	)
}

export { CartPreviewDeliverySplitQuantity }
