import { AxiosResponse } from 'axios'

import { ILogData } from '~/api/dataTypes/log'
import { request } from '~/api/requests/axios'

export const postErrorLog = async (data: ILogData): Promise<AxiosResponse<void>> => (
	await request.post('/v1/log', data, {
		service: 'LOG_SERVICE',
	})
)
