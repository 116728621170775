import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IProductBlockNameProps } from '~/components/core/productBlock'
import { Link } from '~/components/core/link'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'

import styles from './ProductBlockName.module.scss'

const ProductBlockName = (props: IProductBlockNameProps): ReactElement => {
	const {
		product,
		position = 0,
		additionalClass = '',
		ariaLabel = '',
	} = props
	const { GA_productClick } = useGoogleAnalytics()

	const { name, wwwUrl, promotionLabel } = product

	const handleProductClick = useCallback(() => {
		GA_productClick(product, position)
	}, [product])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.promotionLabel]: !isEmpty(promotionLabel),
		[styles.additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<Link
			href={ wwwUrl }
			theme={ {
				wrapper: wrapperClass,
				link: styles.link,
			} }
			ariaLabel={ !isEmpty(ariaLabel) ? ariaLabel : name }
			onClick={ handleProductClick }
		>
			{ name }
		</Link>
	)
}

export { ProductBlockName }
