import React, { ReactElement, useCallback } from 'react'
import { isNull } from 'lodash'

import { CartCustomerDataEditForm, CartCustomerDataItem, CartCustomerDataMainItemCompHeader, CartCustomerDataMainItemPrivHeader, ICartCustomerDataMainItemProps } from '~/components/cart/cartCustomer'
import { useModal } from '~/hooks/modal'
import { useCountries } from '~/hooks/countries'

const CartCustomerDataMainItem = (props: ICartCustomerDataMainItemProps): ReactElement => {
	const { isActive, onSelect, onAddressEdit, customer, address, customerType, isCompany } = props
	const { countriesShortsMap } = useCountries()
	const { isOpen, handleOpen, handleClose } = useModal('CartCustomerDataEdit')

	const { nip, firstName, lastName, corporateName } = customer
	const { city, country, flatNumber, houseNumber, postalCode, street, addressId } = address

	const handleOnSelect = useCallback((): void => {
		onSelect(addressId)
	}, [addressId, onSelect])

	const renderHeader = useCallback((): ReactElement | null => {
		switch (customerType) {
			case 'COMPANY':
				return (
					<CartCustomerDataMainItemCompHeader
						nip={ nip }
						corporateName={ corporateName }
						firstName={ firstName }
						lastName={ lastName }
					/>
				)
			case 'PRIVATE':
				return (
					<CartCustomerDataMainItemPrivHeader firstName={ firstName } lastName={ lastName } />
				)
			default:
				return null
		}
	}, [nip, corporateName, firstName, lastName])

	return (
		<>
			<CartCustomerDataItem
				isMainData
				isCompany={ isCompany }
				Header={ renderHeader() }
				id={ addressId }
				isActive={ isActive }
				onOpen={ handleOpen }
				onSelect={ handleOnSelect }
			>
				<span>
					{ `${ street } ${ houseNumber }${ !isNull(flatNumber) ? ` /${ flatNumber }` : ' ' }` }
				</span>

				<span>
					{ `${ postalCode }, ${ city }` }
				</span>

				<span>
					{ countriesShortsMap[country] }
				</span>
			</CartCustomerDataItem>

			<CartCustomerDataEditForm
				isOpen={ isOpen }
				address={ address }
				onOpen={ handleOpen }
				onClose={ handleClose }
				onEdit={ onAddressEdit }
			/>
		</>
	)
}

export { CartCustomerDataMainItem }
