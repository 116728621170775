import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'

export enum CartConfirmationType {
	'GET_CART_CONFIRMATION_DATA' = 'cartConfirmation/GET_CART_CONFIRMATION_DATA',
	'SET_CART_CONFIRMATION_ORDER_FRONT_ID' = 'cartConfirmation/SET_CART_CONFIRMATION_ORDER_FRONT_ID',
}

export type GetCartConfirmationDataActionType = AppAction<CartConfirmationType.GET_CART_CONFIRMATION_DATA, AxiosResponse<IOrderDetailsResponse>>
export type SetCartConfirmationOrderFrontIdActionType = AppAction<CartConfirmationType.SET_CART_CONFIRMATION_ORDER_FRONT_ID, string>

export type CartConfirmationActionType = GetCartConfirmationDataActionType | SetCartConfirmationOrderFrontIdActionType
