import { useCallback } from 'react'

import { IUseScrollToTopData } from '~/hooks/scrollToTop'

export const useScrollToTop = (): IUseScrollToTopData => {
	const scrollToTop = useCallback((behavior: ScrollBehavior = 'auto'): void => {
		window.scrollTo({ top: 0, behavior })
	}, [])

	return {
		scrollToTop,
	}
}
