import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { IBreadcrumbItemProps } from '~/components/core/breadcrumbs'
import { useMozaic } from '~/hooks/mozaic'

import styles from './BreadcrumbItem.module.scss'

const BreadcrumbItem = (props: IBreadcrumbItemProps): ReactElement => {
	const {
		label, position,
		isLast = false,
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const labelClass = classNames(styles.label, {
		[styles.isLast]: isLast,
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass } aria-current={ isLast ? 'location' : 'false' }>
			<span className={ labelClass } itemProp="name">
				{ label }
			</span>

			<meta itemProp="position" content={ position } />
		</div>
	)
}

export { BreadcrumbItem }
