import React, { ReactElement, useCallback } from 'react'
import { useToggle } from 'react-use'
import { gt, isEmpty, isEqual, map, size } from 'lodash'

import { CartAsideDeliveryCost, CartAsideSummaryCost, CartAsideTotalCost, CartAsideSummaryWrapper, CartAsideWrapper, CartAsidePromotion } from '~/components/core/cartAside'
import { CartCustomerAsideBlockActions, CartCustomerAsideBlockCollapse, CartCustomerAsideBlockProducts, ICartCustomerAsideBlockProps } from '~/components/cart/cartCustomer'
import { ICartAppliedPromotions } from '~/api/dataTypes/cart'

const CartCustomerAsideBlock = (props: ICartCustomerAsideBlockProps): ReactElement => {
	const { cart, onBack, onNext, isNextButtonDisabled, nextButtonDisabledInfo, isNextLoading, isLoading } = props
	const [isActive, setIsActive] = useToggle(false)

	const { cartItemList, productsNetValue, productsValue, deliveryCost, totalCostValue, promotionValue, appliedPromotions } = cart

	const renderPromotions = useCallback((): ReactElement | ReactElement[] => {
		if (!isEmpty(appliedPromotions)) {
			return map(appliedPromotions, (promotion: ICartAppliedPromotions, index: number): ReactElement => {
				const { promotionValue, name } = promotion
				const isFirstElement = isEqual(index, 0) && gt(size(appliedPromotions), 1)

				return (
					<CartAsidePromotion
						promotionValue={ promotionValue }
						promotionName={ name }
						isFirstElement={ isFirstElement }
					/>
				)
			})
		}

		return (
			<CartAsidePromotion promotionValue={ promotionValue } />
		)
	}, [appliedPromotions])

	const handleClick = useCallback((): void => {
		setIsActive(!isActive)
	}, [isActive])

	return (
		<CartAsideWrapper isLoading={ isLoading }>
			<CartAsideSummaryWrapper>
				<CartCustomerAsideBlockCollapse isActive={ isActive } onClick={ handleClick }>
					<CartCustomerAsideBlockProducts cartItems={ cartItemList } />
				</CartCustomerAsideBlockCollapse>

				<CartAsideSummaryCost productsNetValue={ productsNetValue } productsValue={ productsValue } />

				<CartAsideDeliveryCost deliveryCost={ deliveryCost } />

				{ renderPromotions() }

				<CartAsideTotalCost totalCost={ totalCostValue } />

				<CartCustomerAsideBlockActions
					isNextButtonDisabled={ isNextButtonDisabled }
					nextButtonDisabledInfo={ nextButtonDisabledInfo }
					isNextLoading={ isNextLoading }
					onNext={ onNext }
					onBack={ onBack }
				/>
			</CartAsideSummaryWrapper>
		</CartAsideWrapper>
	)
}

export { CartCustomerAsideBlock }
