import React, { ReactElement, useCallback } from 'react'
import { replace } from 'lodash'

import { IHeaderTopBarSearchBarNodeProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'

import styles from './HeaderTopBarSearchBarNode.module.scss'

const HeaderTopBarSearchBarNode = (props: IHeaderTopBarSearchBarNodeProps): ReactElement => {
	const {
		node, query, onBlur,
		dataTestId = '',
	} = props

	const { name, wwwUrl, count } = node

	const highlightValue = useCallback((name: string): string => {
		const caseIntensive = replace(query, new RegExp('\\\\', 'gi'), '\\\\')

		return name.replace(caseIntensive, `<span class="${ styles.highlight }">${ query }</span>`)
	}, [name, query])

	return (
		<div className={ styles.wrapper } data-testid={ dataTestId }>
			<Link
				href={ wwwUrl }
				ariaLabel={ `${ name }(${ count })` }
				additionalClass={ styles.link }
				onBlur={ onBlur }
			>
				<span dangerouslySetInnerHTML={ { __html: highlightValue(name) } } className={ styles.title } />

				<span className={ styles.count }>({ count })</span>
			</Link>
		</div>
	)
}

export { HeaderTopBarSearchBarNode }
