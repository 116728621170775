import React, { ReactElement, useCallback, useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'

import { AlertProvider } from '~/providers/alertProvider'
import { HeaderProvider } from '~/providers/headerProvider'
import { UserContext } from '~/providers/userProvider'
import { AppDispatch } from '~/state/store'
import { IRootState } from '~/state/types'
import { isPageAuthorized } from '~/utils/authorizedPage'
import { getCustomerData } from '~/actions/customer'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { Main, ILayoutCartProps, LayoutCartWrapper, FooterSimple, isFirstStepOnShoppingPath, HeaderWrapper, HeaderSimple } from '~/components/core/layout'
import { Notifications } from '~/components/core/notifications'
import { Auth } from '~/components/core/auth'
import { MergeCartModal } from '~/components/core/mergeCartModal'
import { ReactErrorBoundary, ERROR_BOUNDARY_KEYS } from '~/components/core/reactErrorBoundary'
import { KobiFavicon } from '~/components/kobi/kobiFavicon'
import { localStorageSet } from '~/utils/storage'
import { LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY } from '~/components/cart/cartCustomer'
import { setActiveDeliveryGroup, setCartStepZeroData } from '~/actions/cart'

const LayoutCart = (props: ILayoutCartProps): ReactElement => {
	const { children, pageType } = props
	const { isStepZero } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const { isLogged } = useContext(UserContext)
	const dispatch: AppDispatch = useDispatch()
	const router = useRouter()
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)
	const { data: { cartGuestOrderAvailable } } = useSelector((state: IRootState) => state.cartIdentification, shallowEqual)

	const FIRST_STEP_URL_PARAM = '?etap=koszyk'
	const isUnauthorized = !isLogged && isPageAuthorized(pageType)
	const shouldDisplayCartStepZeroView = !isLogged && !cartGuestOrderAvailable

	const renderFooter = useCallback((): ReactElement | null => {
		if (isKobiIntegrationEnabled && !isStepZero) {
			return null
		}

		return (
			<FooterSimple />
		)
	}, [isKobiIntegrationEnabled, isStepZero])

	const renderNotifications = useCallback((): ReactElement | null => {
		if (!isKobiIntegrationEnabled) {
			return null
		}

		return (
			<Notifications />
		)
	}, [isKobiIntegrationEnabled])

	useEffectOnce(() => {
		(async () => {
			if (!isFirstStepOnShoppingPath(pageType) && isLogged) {
				await dispatch(getCustomerData())
			}
		})()
	})

	useEffect(() => {
		(async () => {
			await dispatch(setCartStepZeroData(shouldDisplayCartStepZeroView))
		})()
	}, [shouldDisplayCartStepZeroView])

	useEffect(() => {
		router.beforePopState(({ as }: { as: string }) => {
			if (as !== router.asPath) {
				if (as.indexOf(FIRST_STEP_URL_PARAM) !== -1) {
					localStorageSet(LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY, 'false')
				}
			}

			return true
		})

		return () => {
			router.beforePopState(() => true)
		}
	}, [router])

	useEffect(() => {
		return () => {
			(async () => {
				localStorageSet(LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY, 'true')
				await dispatch(setActiveDeliveryGroup(null))
			})()
		}
	}, [])

	return (
		<>
			<KobiFavicon />

			<HeaderProvider>
				<AlertProvider>
					{ renderNotifications() }

					<HeaderWrapper isSimple isForcedVisible={ isStepZero }>
						<HeaderSimple />
					</HeaderWrapper>

					<Main pageType={ pageType }>
						<ReactErrorBoundary key={ ERROR_BOUNDARY_KEYS.LAYOUT_CART } url={ router.asPath }>
							<Auth isUnauthorized={ isUnauthorized }>
								{ /* eslint-disable react/jsx-max-depth */ }

								<LayoutCartWrapper>
									{ children }
								</LayoutCartWrapper>
							</Auth>
						</ReactErrorBoundary>
					</Main>

					{ renderFooter() }

					<MergeCartModal />
				</AlertProvider>
			</HeaderProvider>
		</>
	)
}

export { LayoutCart }
