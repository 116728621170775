import { AppPromiseThunkAction } from '~/actions'
import { AuthAdeoActionType, GetAdeoAuthenticateActionType, SetAdeoExtractTokensActionType, SetAdeoLogoutActionType, SetAdeoRefreshTokensActionType, SetLoginByAdeoType } from '~/actions/auth'
import { getAuthenticate, postExtractTokens, postRefreshTokens } from '~/api/requests/authAdeo'
import { IExtractTokensRequest, IRefreshTokensRequest } from '~/api/dataTypes/authAdeo'
import { localStorageRemove, localStorageSet } from '~/utils/storage'
import { LS_KEY_CART_UUID, LS_KEY_ADEO_AUTH_USER } from '~/utils/constants'

// eslint-disable-next-line @typescript-eslint/typedef
export const getAdeoAuthenticate = (callback: string): AppPromiseThunkAction<GetAdeoAuthenticateActionType> => async (dispatch) => {
	return dispatch({
		type: AuthAdeoActionType.GET_ADEO_AUTHENTICATE,
		payload: getAuthenticate(callback),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setAdeoExtractTokens = (params: IExtractTokensRequest): AppPromiseThunkAction<SetAdeoExtractTokensActionType> => async (dispatch) => {
	return dispatch({
		type: AuthAdeoActionType.SET_ADEO_EXTRACT_TOKENS,
		payload: async () => {
			try {
				const response = await postExtractTokens(params)

				localStorageSet(LS_KEY_ADEO_AUTH_USER, response.data)
				localStorageRemove(LS_KEY_CART_UUID)

				return Promise.resolve(response)
			} catch (e: unknown) {
				localStorageRemove(LS_KEY_ADEO_AUTH_USER)

				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setAdeoRefreshTokens = (params: IRefreshTokensRequest): AppPromiseThunkAction<SetAdeoRefreshTokensActionType> => async (dispatch) => {
	return dispatch({
		type: AuthAdeoActionType.SET_ADEO_REFRESH_TOKENS,
		payload: async () => {
			try {
				const response = await postRefreshTokens(params)

				localStorageSet(LS_KEY_ADEO_AUTH_USER, response.data)

				return Promise.resolve(response)
			} catch (e: unknown) {
				localStorageRemove(LS_KEY_ADEO_AUTH_USER)

				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setLoginByAdeo = (shouldLoginByAdeo: boolean): AppPromiseThunkAction<SetLoginByAdeoType> => async (dispatch) => {
	return dispatch({
		type: AuthAdeoActionType.SET_LOGIN_BY_ADEO,
		payload: Promise.resolve(shouldLoginByAdeo),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setAdeoLogout = (): AppPromiseThunkAction<SetAdeoLogoutActionType> => async (dispatch) => {
	localStorageRemove(LS_KEY_ADEO_AUTH_USER)

	return dispatch({
		type: AuthAdeoActionType.SET_ADEO_LOGOUT,
		payload: Promise.resolve(),
	})
}
