import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import axios, { AxiosError } from 'axios'
import * as yup from 'yup'
import { FieldPath, useController, useForm, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { isEmpty, isEqual, isNil, isUndefined, map } from 'lodash'

import { CartCustomerInvoiceBox, CartCustomerInvoiceModal, getGuestCustomerInvoiceData, ICartCustomerInvoiceForm, ICartCustomerInvoiceGuestDeliveryProps, InvoiceExpectationType } from '~/components/cart/cartCustomer'
import { Radio } from '~/components/core/form'
import { ICartCustomerGuestCompanyForm, ICartCustomerGuestPrivateForm } from '~/hooks/cartGuestCustomerForm'
import { useModal } from '~/hooks/modal'
import { useAlert } from '~/hooks/alert'
import { useScrollToTop } from '~/hooks/scrollToTop'
import { useLogError } from '~/hooks/logError'
import { clearInvoiceData, setCartCustomerInvoiceMethodSelected, validateCartCustomerCompanyInvoice, validateCartCustomerPrivateInvoice } from '~/actions/cartCustomer'
import { IRootState } from '~/state/types'
import { isCartCustomerInvoiceSetInApiData } from '~/state/reducers/cartCustomerReducer'
import { AppDispatch } from '~/state/store'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'

import styles from './CartCustomerInvoiceGuestDelivery.module.scss'

const CartCustomerInvoiceGuestDelivery = (props: ICartCustomerInvoiceGuestDeliveryProps): ReactElement => {
	const { totalCostValue, invoice, isDeliveryType, isMainFormIncomplete, guestCustomerType, isInvoiceRequired } = props
	const { invoiceSelectedType } = useSelector((state: IRootState) => state.cartCustomer)
	const isInvoiceSetInApiData = useSelector((state: IRootState) => isCartCustomerInvoiceSetInApiData(state))
	const { t } = useTranslation(['cart'])
	const { isOpen: isOpenInvoice, handleOpen: handleOpenInvoice, handleClose: handleCloseInvoice } = useModal('CartCustomerInvoice')
	const { getValues, setError } = useFormContext<ICartCustomerGuestPrivateForm | ICartCustomerGuestCompanyForm >()
	const [wasOpenBefore, setWasOpenBefore] = useToggle(false)
	const { scrollToTop } = useScrollToTop()
	const { newAlert } = useAlert()
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()

	const schema: yup.SchemaOf<ICartCustomerInvoiceForm> = useMemo(() => yup.object().shape({
		invoiceExpect: yup.string().oneOf(['NO', 'YES']).required(),
	}), [])

	const { control, setValue } = useForm<ICartCustomerInvoiceForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			invoiceExpect: invoiceSelectedType,
		},
	})

	const { field, field: { value }, fieldState: { error } } = useController({ control, name: 'invoiceExpect' })

	const handleCancelInvoice = useCallback(() => {
		handleCloseInvoice()

		if (!isInvoiceSetInApiData) {
			setValue('invoiceExpect', '')

			dispatch(setCartCustomerInvoiceMethodSelected(''))
		}
	}, [invoice, isInvoiceSetInApiData, handleCloseInvoice])

	const setGuestInvoiceData = useCallback(async (): Promise<void> => {
		const guestFormData = getValues()
		const invoiceData = getGuestCustomerInvoiceData(guestFormData, guestCustomerType)

		if (isEqual(guestCustomerType, 'COMPANY')) {
			try {
				await dispatch(validateCartCustomerCompanyInvoice(invoiceData))
			} catch (e: unknown) {
				const error = e as AxiosError<IResourceBadRequestException>

				if (axios.isAxiosError(error) && !isUndefined(error.response)) {
					const { errors } = error.response.data

					map(errors, (err: IRestExceptionResponse) => {
						const { errorMessages, field } = err

						if (!isNil(errorMessages) && !isEmpty(errorMessages)) {
							setError(field as FieldPath<ICartCustomerGuestCompanyForm>, {
								message: errorMessages[0],
							})
						}
					})
				} else {
					newAlert('danger', t('customer.invoice.invoiceValidateError'), 5000)
				}

				setValue('invoiceExpect', '')
				scrollToTop()
				sendLogError(e)
			}
		}

		if (isEqual(guestCustomerType, 'PRIVATE')) {
			try {
				await dispatch(validateCartCustomerPrivateInvoice(invoiceData))
			} catch (e: unknown) {
				const error = e as AxiosError<IResourceBadRequestException>

				if (axios.isAxiosError(error) && !isUndefined(error.response)) {
					const { errors } = error.response.data

					map(errors, (err: IRestExceptionResponse) => {
						const { errorMessages, field } = err

						if (!isNil(errorMessages) && !isEmpty(errorMessages)) {
							setError(field as FieldPath<ICartCustomerGuestPrivateForm>, {
								message: errorMessages[0],
							})
						}
					})
				} else {
					newAlert('danger', t('customer.invoice.invoiceValidateError'), 5000)
				}

				setValue('invoiceExpect', '')
				scrollToTop()
				sendLogError(e)
			}
		}
	}, [getValues, guestCustomerType])

	const handleAboveInvoiceData = useCallback(async (): Promise<void> => {
		await setGuestInvoiceData()
	}, [])

	const handleSetIsInvoiceMethodSelected = useCallback(async (value: string): Promise<void> => {
		dispatch(setCartCustomerInvoiceMethodSelected(value))
		setWasOpenBefore(true)
	}, [])

	const handleCleanInvoiceData = useCallback(async (): Promise<void> => {
		await dispatch(clearInvoiceData())
	}, [])

	const radioRightAccessory = isMainFormIncomplete && isInvoiceRequired ? t('customer.invoice.delivery.radioRightAccessoryGuest') : ''

	const radioNoLabel = isInvoiceRequired ? t('customer.invoice.delivery.aboveInvoiceData') : t('customer.invoice.noThankYou')
	const radioYesLabel = isInvoiceRequired ? t('customer.invoice.delivery.otherInvoiceData') : t('customer.invoice.yesPlease')

	const renderInvoiceBox = useCallback((): ReactElement | null => {
		if (isEqual(value, 'YES' as InvoiceExpectationType)) {
			return (
				<CartCustomerInvoiceBox invoice={ invoice } onEdit={ handleOpenInvoice } />
			)
		}

		return null
	}, [invoice, value])

	useEffect(() => {
		if (wasOpenBefore || invoiceSelectedType === '') {
			if (isEqual(value, 'NO' as InvoiceExpectationType)) {
				if (isInvoiceRequired) {
					handleAboveInvoiceData()
				} else {
					handleCleanInvoiceData()
				}

				handleSetIsInvoiceMethodSelected('NO')
			} else if (isEqual(value, 'YES' as InvoiceExpectationType)) {
				handleOpenInvoice()
				handleSetIsInvoiceMethodSelected('YES')
			}
		} else {
			setWasOpenBefore(true)
		}
	}, [value])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.radios }>
				<Radio
					isDisabled={ isMainFormIncomplete && isInvoiceRequired }
					value={ 'NO' as InvoiceExpectationType }
					field={ field }
					error={ error }
					label={ radioNoLabel }
					additionalClass={ styles.radioLabel }
					rightAccessory={ radioRightAccessory }
				/>

				<Radio
					value={ 'YES' as InvoiceExpectationType }
					field={ field }
					error={ error }
					label={ radioYesLabel }
					additionalClass={ styles.radioLabel }
				/>

				{ renderInvoiceBox() }

				<CartCustomerInvoiceModal
					invoice={ invoice }
					totalCostValue={ totalCostValue }
					isDeliveryType={ isDeliveryType }
					isOpen={ isOpenInvoice }
					onClose={ handleCloseInvoice }
					onCancel={ handleCancelInvoice }
				/>
			</div>
		</div>
	)
}

export { CartCustomerInvoiceGuestDelivery }
