import { isEqual, isNull, isNil } from 'lodash'

import { ICustomerAddressData, ICustomerAddressRequest } from '~/api/dataTypes/customer'
import { CustomerDataType } from '~/state/reducers/customerReducer'

export const isAddressInList = (addresses: ICustomerAddressData[], newAddress: Partial<ICustomerAddressRequest> | null): boolean => {
	if (isNull(newAddress)) {
		return false
	}

	for (const address of addresses) {
		const newAddressToCompare: Partial<ICustomerAddressRequest> = {
			city: newAddress.city || '',
			country: newAddress.country || '',
			firstName: newAddress.firstName || '',
			lastName: newAddress.lastName || '',
			flatNumber: newAddress.flatNumber || '',
			houseNumber: newAddress.houseNumber || '',
			postalCode: newAddress.postalCode || '',
			street: newAddress.street || '',
			corporateName: newAddress.corporateName || '',
		}

		const addressToCompare: Partial<ICustomerAddressRequest> = {
			city: address.city || '',
			country: address.country || '',
			firstName: address.firstName || '',
			lastName: address.lastName || '',
			flatNumber: address.flatNumber || '',
			houseNumber: address.houseNumber || '',
			postalCode: address.postalCode || '',
			street: address.street || '',
			corporateName: isNil(newAddress.corporateName) || isNil(address.corporateName) ? '' : address.corporateName,
		}

		const isObjectsEqual = isEqual(newAddressToCompare, addressToCompare)

		if (isObjectsEqual) {
			return true
		}
	}

	return false
}

export const getUserAddresses = (userData: CustomerDataType): ICustomerAddressData[] => {
	if (!isNull(userData)) {
		return userData.addresses
	}

	return []
}
