import { useCallback } from 'react'
import { useList, useToggle } from 'react-use'
import { debounce, isEmpty } from 'lodash'

import { getCitiesByZipCode } from '~/api/requests/city'
import { IUseHogaDictionaryData } from '~/hooks/hogaDictionary'
import { useLogError } from '~/hooks/logError'

export const useHogaDictionary = (): IUseHogaDictionaryData => {
	const [list, { set }] = useList<string>([])
	const [isDropdownOpen, toggleOpenDropdown] = useToggle(false)
	const { sendLogError } = useLogError()

	const getHogaDictionary = useCallback(debounce(async (zipCode: string): Promise<void> => {
		if (!isEmpty(zipCode)) {
			try {
				const { data } = await getCitiesByZipCode(zipCode)

				set(data)
			} catch (e: unknown) {
				sendLogError(e)
			}
		}
	}, 1000), [])

	const handleOpenDropdown = useCallback((): void => {
		toggleOpenDropdown(true)
	}, [])

	const handleCloseDropdown = useCallback((): void => {
		toggleOpenDropdown(false)
	}, [])

	const handleToggleDropdown = useCallback((visibility: boolean): void => {
		toggleOpenDropdown(visibility)
	}, [])

	return {
		getHogaDictionary,
		list,
		isDropdownOpen,
		onOpenDropdown: handleOpenDropdown,
		onCloseDropdown: handleCloseDropdown,
		onToggleDropdown: handleToggleDropdown,
	}
}
