import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { gt } from 'lodash'

import { useModal } from '~/hooks/modal'
import { IHeaderTopBarFavoritesModalActionsProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { FavoriteShareModal } from '~/components/favorites'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarFavoritesModalActions.module.scss'

const HeaderTopBarFavoritesModalActions = (props: IHeaderTopBarFavoritesModalActionsProps): ReactElement => {
	const { onOpen, productRows } = props
	const { t } = useTranslation(['common'])
	const router = useRouter()
	const { isOpen, handleOpen, handleClose } = useModal('FavoriteShareModal')
	const { urls } = useContext(AppParametersContext)

	const handleShare = useCallback((): void => {
		onOpen()
		handleOpen()
	}, [onOpen])

	const handleGoToFavoritesPage = useCallback(async (): Promise<void> => {
		await router.push(urls.shoppingList)

		onOpen()
	}, [onOpen, urls.shoppingList])

	const handleOnBlur = useCallback((): void => {
		onOpen()
	}, [onOpen])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.scrollable]: gt(productRows, 2),
	})

	return (
		<>
			<footer className={ wrapperClass }>
				<Button
					variant="neutral"
					size="inherit"
					additionalClass={ styles.shareButton }
					dataTestId={ DATA_TESTID.HEADER.BUTTON_OPEN_FAVORITES }
					ariaLabel={ t('share') }
					onClick={ handleShare }
				>
					<Icon
						name="share"
						color="green"
						width={ 11 }
						additionalClass={ styles.icon }
					/>

					<span>{ t('share') }</span>
				</Button>

				<Button
					isReverse
					variant="secondary"
					text={ t('goToFavorites') }
					additionalClass={ styles.button }
					size="small"
					dataTestId={ DATA_TESTID.HEADER.BUTTON_GO_TO_FAVORITES }
					onClick={ handleGoToFavoritesPage }
					onBlur={ handleOnBlur }
				/>
			</footer>

			<FavoriteShareModal isOpen={ isOpen } onClose={ handleClose } />
		</>
	)
}

export { HeaderTopBarFavoritesModalActions }
