import React, { ReactElement } from 'react'

import { INewsletterIconsWrapperProps } from '~/components/core/newsletter'

import styles from './NewsletterIconsWrapper.module.scss'

const NewsletterIconsWrapper = (props: INewsletterIconsWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { NewsletterIconsWrapper }
