import produce, { Draft } from 'immer'

import { IAccountReviewsState } from '~/state/reducers/accountReducer'
import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { CustomerReviewActionUnionType, CustomerReviewActionType } from '~/actions/account'

import { accountReviewsInitialState as initialState } from './constants'

export const reviews = produce((draft: Draft<IAccountReviewsState>, action: AppPromiseAction<CustomerReviewActionUnionType>) => {
	switch (action.type) {
		case REQUEST(CustomerReviewActionType.GET_CUSTOMER_REVIEWS):
			draft.isPending = true

			return draft
		case SUCCESS(CustomerReviewActionType.GET_CUSTOMER_REVIEWS):
			draft.isPending = false
			draft.data = action.payload.data

			return draft
		case FAILURE(CustomerReviewActionType.GET_CUSTOMER_REVIEWS):
			draft.isPending = false

			return draft
		default:
			return draft
	}
}, initialState)
