import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { IVisuallyHiddenWrapperProps } from '~/components/core/visuallyHiddenWrapper'

import styles from './VisuallyHiddenWrapper.module.scss'

const VisuallyHiddenWrapper = (props: IVisuallyHiddenWrapperProps): ReactElement => {
	const {
		children,
		theme = {},
	} = props

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div className={ wrapperClass }>
			{ children }
		</div>
	)
}

export { VisuallyHiddenWrapper }
