import React, { ReactElement, useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { useWindowElement } from '~/hooks/windowElement'
import { IStickyElementProps, StickyElementPositionType } from '~/components/core/stickyBlock'

import styles from './StickyElement.module.scss'

const StickyElement = (props: IStickyElementProps): ReactElement => {
	const {
		children, wrapperRef,
		additionalClass = '',
	} = props
	const ref = useRef<HTMLDivElement | null>(null)
	const [position, setPosition] = useState<StickyElementPositionType>('top')
	const { yScrollPosition, elementHeight } = useWindowElement(ref)
	const { elementHeight: wrapperElementHeight } = useWindowElement(wrapperRef)

	const wrapperClass = classNames(styles.wrapper, {
		[styles[position]]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	useEffect(() => {
		if (yScrollPosition === 0 && position !== 'top') {
			setPosition('top')
		} else if (yScrollPosition > 0 && yScrollPosition < wrapperElementHeight - elementHeight && position !== 'sticky') {
			setPosition('sticky')
		} else if (yScrollPosition > wrapperElementHeight - elementHeight && position !== 'bottom') {
			setPosition('bottom')
		}
	}, [elementHeight, yScrollPosition, wrapperElementHeight, position])

	return (
		<div ref={ ref } className={ wrapperClass }>
			{ children }
		</div>
	)
}

export { StickyElement }
