import React, { ReactElement } from 'react'

import { Tooltip } from '~/components/core/tooltip'
import { ICartPreviewDeliveryEcommerceTypeProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryEcommerceType.module.scss'

const CartPreviewDeliveryEcommerceType = (props: ICartPreviewDeliveryEcommerceTypeProps): ReactElement => {
	const { caption, description } = props

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.caption }>
				{ caption }
			</span>

			{ description && <Tooltip content={ description } /> }
		</div>
	)
}

export { CartPreviewDeliveryEcommerceType }
