import { AppPromiseThunkAction } from '~/actions'
import { GetServiceCartConfirmationDataActionType, ServiceCartConfirmationType, SetServiceCartConfirmationOrderFrontIdActionType } from '~/actions/serviceCartConfirmation'
import { getAuthlessOrderDetails } from '~/api/requests/authlessOrder'

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartConfirmationData = (orderFrontId: string): AppPromiseThunkAction<GetServiceCartConfirmationDataActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartConfirmationType.GET_SERVICE_CART_CONFIRMATION_DATA,
		payload: getAuthlessOrderDetails({ orderFrontId }),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartConfirmationOrderFrontId = (orderFrontId: string): AppPromiseThunkAction<SetServiceCartConfirmationOrderFrontIdActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartConfirmationType.SET_SERVICE_CART_CONFIRMATION_ORDER_FRONT_ID,
		payload: Promise.resolve(orderFrontId),
	})
}
