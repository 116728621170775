import { AxiosResponse } from 'axios'

import { ICustomerLastViewedProductsResponse } from '~/api/dataTypes/lastViewed'
import { request } from '~/api/requests/axios'

export const postCustomerProductLastViewed = async (lmReference: string): Promise<AxiosResponse<void>> => (
	await request.post(`/v1/preferences/products/last-viewed/${ lmReference }`, null, {
		service: 'CUSTOMER_PREFERENCE_SERVICE',
		withStoreCode: true,
	})
)

export const getCustomerProductsLastViewed = async (): Promise<AxiosResponse<ICustomerLastViewedProductsResponse>> => (
	await request.get('/v1/preferences/products/last-viewed', {
		service: 'CUSTOMER_PREFERENCE_SERVICE',
		withStoreCode: true,
	})
)
