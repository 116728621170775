import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isUndefined } from 'lodash'

import { IHeaderTopBarSearchBarResultsProps } from '~/components/core/layout'

import styles from './HeaderTopBarSearchBarResults.module.scss'

const HeaderTopBarSearchBarResults = (props: IHeaderTopBarSearchBarResultsProps): ReactElement | null => {
	const { children, isModalOpen } = props

	if (isUndefined(children)) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.modal]: isModalOpen,
	})

	return (
		<div className={ wrapperClass }>
			{ children }
		</div>
	)
}

export { HeaderTopBarSearchBarResults }
