import React, { ReactElement, useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'

import { getRedirectUrl } from '~/utils/urls'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { ProfitsList } from '~/components/core/profitsList'
import { Button } from '~/components/core/button'
import { useMozaic } from '~/hooks/mozaic'
import { ISignInAsNewProps } from '~/components/signIn'

import styles from './SignInAsNew.module.scss'

const SignInAsNew = (props: ISignInAsNewProps): ReactElement => {
	const {
		caption,
		url = undefined,
		profits = [],
		onClick = undefined,
	} = props
	const { t } = useTranslation(['signIn', 'form', 'common', 'cart'])
	const router = useRouter()
	const { urls } = useContext(AppParametersContext)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const buttonClass = classNames(styles.button, 'mc-button--bordered')
	const captionClass = shouldUseMozaic ? classNames(styles.wrapper) : 'mt-heading mt-heading--s'

	const renderContent = useCallback((): ReactElement | null =>
		!isEmpty(profits) ? (
			<div className={ styles.profits }>
				<ProfitsList items={ profits } />
			</div>
		) : null
	, [profits])

	const handleGoToRegistrationPage = useCallback(async (): Promise<void> => {
		if (!isUndefined(onClick)) {
			onClick()
		} else {
			await router.push(getRedirectUrl(urls.registration, url))
		}
	}, [urls.registration, url, onClick])

	return (
		<div className={ styles.wrapper }>
			<h2 className={ captionClass }>
				{ caption }
			</h2>

			{ renderContent() }

			<Button
				text={ t('createAccount', { ns: 'common' }) }
				variant="secondary"
				additionalClass={ buttonClass }
				onClick={ handleGoToRegistrationPage }
			/>
		</div>
	)
}

export { SignInAsNew }
