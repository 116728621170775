import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import { isNull } from 'lodash'

import { Link } from '~/components/core/link'
import { Tooltip } from '~/components/core/tooltip'
import { Button } from '~/components/core/button'
import { getCartCheckAvailabilityData, ICartDeliveryNextData } from '~/actions/cart'
import { useCartStatus } from '~/hooks/cartStatus'
import { useCartPreviewNextValidation } from '~/hooks/cartPreviewNextValidation'
import { useModal } from '~/hooks/modal'
import { useMozaic } from '~/hooks/mozaic'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { buildItemQuantityList, CartPreviewSummaryNextModal } from '~/components/cart/cartPreview'
import { postCartDeliveryNextWithAccept, postCartDeliveryNext } from '~/api/requests/cart'
import { DATA_TESTID } from '~/utils/dataTestId'
import { getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'

import styles from './CartPreviewSummaryBlockActions.module.scss'

const CartPreviewSummaryBlockActions = (): ReactElement => {
	const { data: { cart: { selectedPaymentWayId, selectedDeliveryWayId, promotionCode, cartItemList, productsQuantityMap, deliveryStore, pickupPoint } }, tmpCourierNote, isStepZero } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const { t } = useTranslation(['cart'])
	const { changeCartView } = useCartStatus()
	const [isNextLoading, setIsNextLoading] = useToggle(false)
	const dispatch: AppDispatch = useDispatch()
	const { buttonDisabled, tooltipInfo, isProductsNotAvailable } = useCartPreviewNextValidation()
	const { isOpen, handleOpen, handleClose } = useModal('CartPreviewSplitValidation')
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	const isButtonDisabled = !isStepZero && buttonDisabled

	const itemQuantityList = buildItemQuantityList(cartItemList, productsQuantityMap)

	const handleNext = useCallback(async (withAccept: boolean = false): Promise<void> => {
		setIsNextLoading(true)

		try {
			const params: ICartDeliveryNextData = {
				deliveryWayId: selectedDeliveryWayId,
				paymentWayId: selectedPaymentWayId,
				promotionCode: isEcommerce ? promotionCode : '',
				courierNote: tmpCourierNote,
				itemQuantityList,
				pickupPointId: !isNull(pickupPoint) ? pickupPoint.id : null,
				additionalDeliveryWayOptionSelected: !isNull(deliveryStore),
			}

			const { data } = withAccept ? await postCartDeliveryNextWithAccept(params) : await postCartDeliveryNext(params)

			await changeCartView(2, data.cartStatus)
		} finally {
			setIsNextLoading(false)
		}
	}, [selectedPaymentWayId, selectedDeliveryWayId, tmpCourierNote, promotionCode, itemQuantityList, deliveryStore, pickupPoint])

	const handleClickButton = useCallback(async (): Promise<void> => {
		if (isStepZero) {
			await changeCartView(2, 'IDENTIFICATION')
		} else {
			if (isProductsNotAvailable && selectedDeliveryWayId) {
				await setIsNextLoading(true)
				await dispatch(getCartCheckAvailabilityData(selectedDeliveryWayId))
				await handleOpen()
			} else {
				await handleNext()
			}
		}
	}, [selectedDeliveryWayId, isProductsNotAvailable, pickupPoint, handleNext, isStepZero])

	return (
		<>
			<div className={ wrapperClass }>
				<Tooltip
					placement="top"
					content={ tooltipInfo }
					isActive={ isButtonDisabled }
					theme={ { wrapper: styles.tooltip } }
					CustomTriggerComponent={
						<Button
							isLoading={ isNextLoading }
							isDisabled={ isButtonDisabled }
							additionalClass={ styles.button }
							text={ isStepZero ? t('zero.aside.summary.button.next') : t('preview.aside.summary.button.next') }
							dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_GO_TO_NEXT_CART_STEP }
							onClick={ handleClickButton }
						/>
					}
				/>

				<Link
					href="/"
					additionalClass={ styles.link }
					ariaLabel={ t('preview.aside.summary.button.back') }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BACK_TO_HOME }
				>
					{ t('preview.aside.summary.button.back') }
				</Link>
			</div>

			<CartPreviewSummaryNextModal
				isOpen={ isOpen }
				onNext={ handleNext }
				onSetIsNextLoading={ setIsNextLoading }
				onClose={ handleClose }
			/>
		</>
	)
}

export { CartPreviewSummaryBlockActions }
