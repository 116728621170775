import { ReactElement, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'

import { getRedirectUrl } from '~/utils/urls'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { IAuthProps } from '~/components/core/auth'

const Auth = (props: IAuthProps): ReactElement => {
	const { children, isUnauthorized } = props
	const router = useRouter()
	const { urls } = useContext(AppParametersContext)

	useEffect(() => {
		(async () => {
			if (isUnauthorized) {
				await router.replace(getRedirectUrl(urls.login, router.asPath))
			}
		})()
	}, [isUnauthorized])

	return children
}

export { Auth }
