import { lte } from 'lodash'

import { ICON_TYPE } from '~/components/core/icon'

export const getStarIconName = (index: number, avgRate: number): keyof typeof ICON_TYPE => {
	let starIconName: keyof typeof ICON_TYPE = 'starEmpty'

	if (lte(index - 0.5, avgRate)) {
		starIconName = index > avgRate ? 'starHalf' : 'star'
	}

	return starIconName
}
