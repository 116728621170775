import { useCallback, useContext } from 'react'
import { isUndefined } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { IUseKobiHeaderData } from '~/hooks/kobiHeader'

export const useKobiHeader = (): IUseKobiHeaderData => {
	const appParams = useContext(AppParametersContext)

	const isKobiIntegrationEnabled = appParams?.kobiConfig?.integrationEnabled

	const handleDispatchKobiCartHeader = useCallback(() => {
		if (!isUndefined(typeof window) && isKobiIntegrationEnabled) {
			document.dispatchEvent(new Event('cartUpdate'))
		}
	}, [isKobiIntegrationEnabled])

	return {
		handleDispatchKobiCartHeader,
	}
}
