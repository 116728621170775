import React, { ReactElement } from 'react'
import Script from 'next/script'

const RealUserMonitoringScript = (): ReactElement => {
	return (
		<Script
			id="real-user-monitoring"
			dangerouslySetInnerHTML={ {
				__html: `
					(function(h,o,u,n,d) {
					h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
					d=o.createElement(u);d.async=1;d.src=n
					n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
				  	})(window,document,'script','https://www.datadoghq-browser-agent.com/eu1/v4/datadog-rum.js','DD_RUM')
				  	window.DD_RUM.onReady(function() {
					window.DD_RUM.init(
					{ clientToken: 'pub1f708683063c6bac41ee4b9164968dfe', applicationId: 'c8eb82ed-57a1-4853-860f-09109535278a', site: 'datadoghq.eu', service: 'www.leroymerlin.pl', env: 'prod', sessionSampleRate: 1, trackUserInteractions: true, trackResources: true, trackLongTasks: true, defaultPrivacyLevel: 'mask-user-input', }
					);
				  	})
				`,
			} }
		/>
	)
}

export { RealUserMonitoringScript }
