import { StoresListActionType } from '~/actions/stores'
import { CatalogFlatTreeActionType } from '~/actions/catalog'
import { LastViewedActionType } from '~/actions/lastViewed'
import { ProductCardActionType } from '~/actions/productCard'
import { PreferredStoreActionType } from '~/actions/preferredStore'
import { ComparisonActionType } from '~/actions/comparison'
import { CartActionType } from '~/actions/cart'
import { AuthActionType } from '~/actions/auth'

export type ActionType = StoresListActionType | CatalogFlatTreeActionType | LastViewedActionType | ProductCardActionType | PreferredStoreActionType | ComparisonActionType | CartActionType | AuthActionType

export const REQUEST = <ActionType extends string = string>(actionType: ActionType): `${ typeof actionType }_PENDING` => `${ actionType }_PENDING`

export const SUCCESS = <ActionType extends string = string>(actionType: ActionType): `${ typeof actionType }_FULFILLED` => `${ actionType }_FULFILLED`

export const FAILURE = <ActionType extends string = string>(actionType: ActionType): `${ typeof actionType }_REJECTED` => `${ actionType }_REJECTED`
