import produce, { Draft } from 'immer'

import { SUCCESS } from '~/statics'
import { AppPromiseAction } from '~/actions'
import { LastViewedActionType, LastViewedActionUnionType } from '~/actions/lastViewed'
import { CustomerActionType } from '~/actions/customer'

import { lastViewedInitialState as initialState } from './constants'
import { ILastViewedState } from './types'

export const lastViewed = produce((draft: Draft<ILastViewedState>, action: AppPromiseAction<LastViewedActionUnionType>) => {
	switch (action.type) {
		case SUCCESS(LastViewedActionType.SET_LAST_VIEWED_PRODUCTS):
			draft.products = action.payload

			return draft
		case SUCCESS(CustomerActionType.GET_CUSTOMER_LAST_VIEWED_PRODUCTS):
			draft.products = action.payload.data.lastViewedProducts

			return draft
		case SUCCESS(LastViewedActionType.INIT_LAST_VIEWED_PRODUCTS):
			draft.products = action.payload

			return draft
		default:
			return draft
	}
}, initialState)
