import React, { createContext, useState, ReactElement, useMemo, useCallback, useRef } from 'react'
import { noop } from 'lodash'

import { IHeaderContextProps, IHeaderProviderProps } from '~/providers/headerProvider'
import { useMediaQuery } from '~/hooks/mediaQuery'

const initialProps: IHeaderContextProps = {
	isHeaderFixed: false,
	headerHeight: 64,
	handleSetHeaderHeight: noop,
	handleSetHeaderFixed: noop,
}

export const HeaderContext = createContext(initialProps)

export const HeaderProvider = (props: IHeaderProviderProps): ReactElement => {
	const { children } = props
	const { isDesktop } = useMediaQuery(false)
	const [headerHeight, setHeaderHeight] = useState<number>(isDesktop ? 128 : initialProps.headerHeight)
	const headerRef = useRef<HTMLElement | null>(null)
	const [isHeaderFixed, setIsHeaderFixed] = useState<boolean>(false)

	const handleSetHeaderHeight = useCallback((height: number): void => {
		setHeaderHeight(height)
	}, [])

	const handleSetHeaderFixed = useCallback((isFixed: boolean): void => {
		setIsHeaderFixed(isFixed)
	}, [])

	const providerValue: IHeaderContextProps = useMemo(() => ({
		headerHeight,
		isHeaderFixed,
		handleSetHeaderHeight,
		handleSetHeaderFixed,
	}), [headerHeight, headerRef, isHeaderFixed])

	return (
		<HeaderContext.Provider value={ providerValue }>
			{ children }
		</HeaderContext.Provider>
	)
}
