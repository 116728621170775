import queryString from 'query-string'
import { isNull, map } from 'lodash'

import { IProductSimple } from '~/api/dataTypes/product'
import { AppPromiseThunkAction } from '~/actions'
import { LastViewedProductsSetType, LastViewedProductsInitType, LastViewedActionType } from '~/actions/lastViewed'
import { LS_KEY_LAST_VIEWED_PRODUCTS } from '~/utils/constants'
import { localStorageGet, localStorageSet } from '~/utils/storage'
import { getProductsSimple } from '~/api/requests/products'

// eslint-disable-next-line @typescript-eslint/typedef
export const setLastViewedProducts = (products: IProductSimple[]): AppPromiseThunkAction<LastViewedProductsSetType> => async (dispatch) => {
	const lastViewedReferences: string[] = map(products, (product: IProductSimple) => product.lmReference)

	localStorageSet(LS_KEY_LAST_VIEWED_PRODUCTS, lastViewedReferences)

	return dispatch({
		type: LastViewedActionType.SET_LAST_VIEWED_PRODUCTS,
		payload: Promise.resolve(products),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const initLastViewedProducts = (): AppPromiseThunkAction<LastViewedProductsInitType> => async (dispatch) => {
	return dispatch({
		type: LastViewedActionType.INIT_LAST_VIEWED_PRODUCTS,
		payload: async () => {
			try {
				const lmReference: string[] | null = localStorageGet(LS_KEY_LAST_VIEWED_PRODUCTS)

				if (isNull(lmReference)) {
					return []
				}

				const params: string = queryString.stringify({ lmReference })

				const { data: { productSimpleList } } = await getProductsSimple(params)

				return productSimpleList
			} catch (error: unknown) {
				return Promise.reject(error)
			}
		},
	})
}
