import React, { ReactElement, useCallback, useState } from 'react'
import { isNull, lt } from 'lodash'

import { Image } from '~/components/core/image'
import { IProductImageProps } from '~/components/core/productImage'

import styles from './ProductImage.module.scss'

const ProductImage = (props: IProductImageProps): ReactElement => {
	const {
		imageUrl, alt,
		mouseoverUrl = null,
		hasPriority = false,
		position = 0,
	} = props
	const [imageSrc, setImageSrc] = useState<string>(imageUrl)

	const imageSize = 256
	const isPreload = hasPriority ? lt(position, 4) : false

	const handleMouseOver = useCallback((): void => {
		if (!isNull(mouseoverUrl)) {
			setImageSrc(mouseoverUrl)
		}
	}, [mouseoverUrl])

	const handleMouseLeave = useCallback((): void => {
		setImageSrc(imageUrl)
	}, [imageUrl])

	return (
		<Image
			src={ imageSrc }
			width={ imageSize }
			height={ imageSize }
			alt={ alt }
			className={ styles.wrapper }
			onMouseOver={ handleMouseOver }
			onMouseLeave={ handleMouseLeave }
			// eslint-disable-next-line react/jsx-props-no-spreading
			{ ...hasPriority && { priority: isPreload, fetchpriority: isPreload ? 'high' : 'low' } }
		/>
	)
}

export { ProductImage }
