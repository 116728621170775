import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { ICartPreviewDeliveryPickupStoreInfoButtonProps } from '~/components/cart/cartPreview'
import { Button } from '~/components/core/button'

import styles from './CartPreviewDeliveryPickupStoreInfoButton.module.scss'

const CartPreviewDeliveryPickupStoreInfoButton = (props: ICartPreviewDeliveryPickupStoreInfoButtonProps): ReactElement => {
	const { onClick } = props
	const { t } = useTranslation(['cart'])

	return (
		<Button
			size="inherit"
			variant="neutral"
			additionalClass={ styles.wrapper }
			ariaLabel={ t('preview.delivery.groups.pickup.changeStore') }
			onClick={ onClick }
		>
			<span className={ styles.text }>
				{ t('preview.delivery.groups.pickup.changeStore') }
			</span>
		</Button>
	)
}

export { CartPreviewDeliveryPickupStoreInfoButton }
