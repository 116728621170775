import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { IProgressBarProps, getProgress } from '~/components/core/progressBar'
import { useMozaic } from '~/hooks/mozaic'

import styles from './ProgressBar.module.scss'

const ProgressBar = (props: IProgressBarProps): ReactElement => {
	const {
		value, maxValue,
		theme = {},
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, theme.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<div className={ styles.fill } style={ getProgress(value, maxValue) } />
		</div>
	)
}

export { ProgressBar }
