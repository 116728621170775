import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useToggle } from 'react-use'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { isNull } from 'lodash'

import { IRadioOption, RadioGroup } from '~/components/core/form'
import { CartPreviewSummaryDiscountLegend, CartPreviewSummaryDiscountSubmitButton, CartPreviewSummaryDiscountSuccess, CartPreviewSummaryLoyaltyProgram, CartPreviewSummaryPromoCode, DISCOUNT, ICartPreviewSummaryDiscountFormData, ICartPreviewSummaryDiscountFormProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewSummaryDiscountForm.module.scss'

const CartPreviewSummaryDiscountForm = (props: ICartPreviewSummaryDiscountFormProps): ReactElement => {
	const { promotionCode, isOpenPinModal, promotionCodeUsed, promotionCodeType, promotionCodeValue, loyaltyCardNumber, onOpenPinModal, onRemoveCard, onSubmit } = props
	const { control } = useFormContext<ICartPreviewSummaryDiscountFormData>()
	const { t } = useTranslation(['cart'])
	const [isFormHidden, setIsFormHidden] = useToggle(false)

	const hasLoyaltyCard = !isNull(loyaltyCardNumber)

	const noneOptions: IRadioOption[] = [{
		label: t('preview.aside.discount.noDiscount'),
		value: DISCOUNT.NONE,
	}]

	const formClass = classNames(styles.form, {
		[styles.hidden]: isFormHidden,
	})

	return (
		<div className={ styles.wrapper }>
			<CartPreviewSummaryDiscountSuccess
				isFormHidden={ isFormHidden }
				hasLoyaltyCard={ hasLoyaltyCard }
				promotionCodeUsed={ promotionCodeUsed }
				promotionCodeType={ promotionCodeType }
				promotionCode={ promotionCode }
				promotionCodeValue={ promotionCodeValue }
				theme={ { wrapper: styles.successWrapper, icon: styles.checkIcon } }
				setIsFormHidden={ setIsFormHidden }
			/>

			<form className={ formClass } onSubmit={ onSubmit }>
				<CartPreviewSummaryDiscountLegend />

				<CartPreviewSummaryPromoCode promotionCodeType={ promotionCodeType } promotionCodeUsed={ promotionCodeUsed } />

				<CartPreviewSummaryLoyaltyProgram
					hasLoyaltyCard={ hasLoyaltyCard }
					loyaltyCardNumber={ loyaltyCardNumber }
					promotionCodeUsed={ promotionCodeUsed }
					isOpenPinModal={ isOpenPinModal }
					onOpenPinModal={ onOpenPinModal }
					onRemoveCard={ onRemoveCard }
				/>

				<RadioGroup
					control={ control }
					name="discount"
					options={ noneOptions }
					theme={ { wrapper: styles.radio, label: styles.label } }
				/>

				<CartPreviewSummaryDiscountSubmitButton />
			</form>
		</div>
	)
}

export { CartPreviewSummaryDiscountForm }
