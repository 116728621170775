import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { ICartCustomerInvoiceReceiptNipBoxProps } from '~/components/cart/cartCustomer'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'

import styles from './CartCustomerInvoiceReceiptNipBox.module.scss'

const CartCustomerInvoiceReceiptNipBox = (props: ICartCustomerInvoiceReceiptNipBoxProps): ReactElement => {
	const { receiptNip, onClick } = props
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.receipt }>
				<span>
					{ t('customer.invoice.receiptNip') }{ ': ' }
				</span>

				<span className={ styles.nip }>
					{ receiptNip }
				</span>
			</div>

			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.editButton }
				onClick={ onClick }
			>
				{ t('customer.invoice.edit') }
			</Button>

			<div className={ styles.check }>
				<Icon
					name="checkmark"
					width={ 15 }
					height={ 11 }
					color="white"
				/>
			</div>
		</div>
	)
}

export { CartCustomerInvoiceReceiptNipBox }
