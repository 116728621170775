import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AxiosResponse } from 'axios'
import { useToggle } from 'react-use'
import { includes, isEqual, isNil, isUndefined } from 'lodash'

import { getAdeoAuthenticate, setAdeoExtractTokens, setAdeoLogout, setAdeoRefreshTokens } from '~/actions/auth'
import { useLogError } from '~/hooks/logError'
import { ADEO_QUERY_LOGIN_PARAM } from '~/utils/constants'
import { IUseAdeoSignInData } from '~/hooks/signIn'
import { AppDispatch } from '~/state/store'
import { IAuthenticationResult, IRefreshTokensRequest } from '~/api/dataTypes/authAdeo'
import { useQueryParams } from '~/hooks/queryParams'
import { ICustomerResult } from '~/api/dataTypes/customer'
import { getCustomerData, getCustomerLastViewedProducts } from '~/actions/customer'
import { IRootState } from '~/state/types'
import { postCartIdentification } from '~/api/requests/cart'
import { postCartIdentificationData } from '~/actions/cartIdentification'
import { getCartSimpleData } from '~/actions/cart'
import { getFavouriteListSimpleData } from '~/actions/favouriteList'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { getCartUuid } from '~/utils/cart'
import { CartStatusType, ICartIdentificationParameters, ICartIdentificationResponse } from '~/api/dataTypes/cart'
import { CalendarServiceCartStatusType, ICalendarIdentificationResponse } from '~/api/dataTypes/calendarService'
import { postCalendarIdentification } from '~/api/requests/calendarService'
import { postServiceCartIdentificationData } from '~/actions/serviceCartIdentification'
import { useCartStatus } from '~/hooks/cartStatus'
import { useServiceCartStatus } from '~/hooks/serviceCartStatus'
import { vars } from '~/statics'

export const useAdeoSignIn = (): IUseAdeoSignInData => {
	const { authorizationConfig, urls } = useContext(AppParametersContext)
	const { isKobiIntegrationEnabled: isKobiIntegrationEnabledByEnv } = vars
	const { calendarCartUuid } = useSelector((state: IRootState) => state.cartIdentification, shallowEqual)
	const [isPending, setIsPending] = useToggle(false)
	const router = useRouter()
	const { sendLogError } = useLogError()
	const { removeQueryParam } = useQueryParams()
	const { GA_login } = useGoogleAnalytics()
	const dispatch: AppDispatch = useDispatch()
	const { changeCartView } = useCartStatus()
	const { changeServiceCartView } = useServiceCartStatus()

	const cartUuid = getCartUuid()
	const isFromCart = includes(router.asPath, urls?.cartIdentification)
	const isFromServiceCart = includes(router.asPath, urls?.calendarCartsLogin)
	// do not change below code - we need to differentiate login by env due to planner shop version which have to be served from RWD - PLEDEV-9351
	const shouldLoginByAdeo = !isUndefined(isKobiIntegrationEnabledByEnv) ? isEqual(isKobiIntegrationEnabledByEnv, 'true') : isEqual(authorizationConfig?.shouldLoginByAdeo, true)

	const handleSignInByAdeo = useCallback(async (redirectionUrl?: string): Promise<void> => {
		const isHtmlLink = isEqual(router.query.isHtml, 'true')
		const redirectUrl = (router.query.from as string) ?? '/'
		const url = isHtmlLink ? `${ redirectUrl }.html` : redirectUrl

		const callbackUrl = `${ window.location.origin }${ isUndefined(redirectionUrl) ? url : redirectionUrl }`

		try {
			const { value }: { value: AxiosResponse<IAuthenticationResult> } = await dispatch(getAdeoAuthenticate(callbackUrl))

			window.location.href = value.data.authenticationUrl
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [router.query])

	const getCartIdentification = useCallback(async (): Promise<ICartIdentificationResponse | ICalendarIdentificationResponse | undefined> => {
		try {
			const isNotFromCartAndServiceCart = !isFromCart && !isFromServiceCart

			const params: ICartIdentificationParameters = {
				cartUuid,
				calendarCartUuid,
			}

			if (isFromCart || isNotFromCartAndServiceCart) {
				const { data: dataFromCartIdentification } = await postCartIdentification(params)

				await dispatch(postCartIdentificationData(dataFromCartIdentification))

				return dataFromCartIdentification
			}

			if (isFromServiceCart) {
				const { data: dataFromCalendarCartIdentification } = await postCalendarIdentification(params)

				await dispatch(postServiceCartIdentificationData(dataFromCalendarCartIdentification))

				return dataFromCalendarCartIdentification
			}
		} catch (e: unknown) {}
	}, [isFromCart, isFromServiceCart, cartUuid, calendarCartUuid])

	const handleFetchDataAfterLoginByAdeo = useCallback(async (): Promise<ICartIdentificationResponse | ICalendarIdentificationResponse | undefined> => {
		try {
			const { value }: { value: AxiosResponse<ICustomerResult> } = await dispatch(getCustomerData())

			GA_login(value.data.customer.customerNumber)

			const cartIdentificationResponse = await getCartIdentification()

			await dispatch(getCartSimpleData())
			await dispatch(getFavouriteListSimpleData())
			await dispatch(getCustomerLastViewedProducts())

			return cartIdentificationResponse
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [cartUuid])

	const handleRedirectAfterLoginByAdeo = useCallback(async (cartIdentificationData: ICartIdentificationResponse | ICalendarIdentificationResponse | undefined): Promise<void> => {
		try {
			if (isFromCart && !isUndefined(cartIdentificationData)) {
				await changeCartView(2, cartIdentificationData.cartStatus as CartStatusType)
			} else if (isFromServiceCart && !isUndefined(cartIdentificationData)) {
				await changeServiceCartView(2, cartIdentificationData.cartStatus as CalendarServiceCartStatusType)
			}
		} catch (e: unknown) {}
	}, [isFromCart, isFromServiceCart])

	const handleFetchDataAfterRefreshTokenByAdeo = useCallback(async (): Promise<void> => {
		try {
			await dispatch(getCustomerData())
			await dispatch(getCartSimpleData())
			await dispatch(getFavouriteListSimpleData())
			await dispatch(getCustomerLastViewedProducts())
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [])

	const handleRefreshAccessTokenByAdeo = useCallback(async (kobiJwt: string) => {
		try {
			await dispatch(setAdeoExtractTokens({ kobiJwt }))
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [])

	const handleLoginByAdeo = useCallback(async (kobiJwt?: string | null): Promise<void> => {
		try {
			if (!isNil(kobiJwt)) {
				setIsPending(true)

				await dispatch(setAdeoExtractTokens({ kobiJwt }))

				removeQueryParam(ADEO_QUERY_LOGIN_PARAM)

				const cartIdentificationData = await handleFetchDataAfterLoginByAdeo()

				await handleRedirectAfterLoginByAdeo(cartIdentificationData)
			}
		} catch (e: unknown) {
			sendLogError(e)
		} finally {
			setIsPending(false)
		}
	}, [router.query])

	const handleRefreshTokenByAdeo = useCallback(async ({ kobiJwt, idToken }: IRefreshTokensRequest): Promise<void> => {
		try {
			await dispatch(setAdeoRefreshTokens({ kobiJwt, idToken }))
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [])

	const handleLogoutByAdeo = useCallback(async () => {
		try {
			await dispatch(setAdeoLogout())
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [])

	return {
		handleSignInByAdeo,
		handleLoginByAdeo,
		handleRefreshTokenByAdeo,
		handleFetchDataAfterRefreshTokenByAdeo,
		handleRefreshAccessTokenByAdeo,
		handleLogoutByAdeo,
		shouldLoginByAdeo,
		isPending,
	}
}
