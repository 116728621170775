import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToggle } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'

import { CartCustomerInvoiceBox, CartCustomerInvoiceModal, CartCustomerInvoiceReceiptModal, CartCustomerInvoiceReceiptNipBox, ICartCustomerInvoiceCashAndCarryProps, ICartCustomerInvoiceForm, InvoiceExpectationType } from '~/components/cart/cartCustomer'
import { useModal } from '~/hooks/modal'
import { Radio } from '~/components/core/form'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { clearInvoiceData, setCartCustomerInvoiceMethodSelected } from '~/actions/cartCustomer'

import styles from './CartCustomerInvoiceCashAndCarry.module.scss'

const CartCustomerInvoiceCashAndCarry = (props: ICartCustomerInvoiceCashAndCarryProps): ReactElement => {
	const { invoice, totalCostValue, receiptNip } = props
	const { invoiceSelectedType, data: { nipInvoiceThresholdAmount } } = useSelector((state: IRootState) => state.cartCustomer)
	const { isOpen: isOpenReceipt, handleOpen: handleOpenReceipt, handleClose: handleCloseReceipt } = useModal('CartCustomerInvoiceReceipt')
	const { isOpen: isOpenInvoice, handleOpen: handleOpenInvoice, handleClose: handleCloseInvoice } = useModal('CartCustomerInvoice')
	const [wasOpenBefore, setWasOpenBefore] = useToggle(false)
	const { t } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()

	const { nip, invoiceExpectation } = receiptNip
	const isInvoiceExpectAndNipExist = !isEmpty(nip) && isEqual(invoiceExpectation, 'YES')
	const isInvoiceUnexpectAdnNipExist = !isEmpty(nip) && isEqual(invoiceExpectation, 'NO')

	const schema: yup.SchemaOf<ICartCustomerInvoiceForm> = useMemo(() => yup.object().shape({
		invoiceExpect: yup.string().oneOf(['NO', 'YES']).required(),
	}), [])

	const { control, setValue } = useForm<ICartCustomerInvoiceForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			invoiceExpect: invoiceSelectedType,
		},
	})

	const { field, field: { value }, fieldState: { error } } = useController({ control, name: 'invoiceExpect' })

	const renderInvoiceBox = useCallback((): ReactElement | null => {
		if (isEqual(value, 'YES' as InvoiceExpectationType)) {
			return (
				<CartCustomerInvoiceBox invoice={ invoice } onEdit={ handleOpenInvoice } />
			)
		}

		return null
	}, [invoice, receiptNip, value])

	const handleCancelInvoice = useCallback(() => {
		handleCloseInvoice()

		setValue('invoiceExpect', 'NO')
	}, [invoice, handleCloseInvoice])

	const handleSetIsInvoiceMethodSelected = useCallback(async (value: string): Promise<void> => {
		await dispatch(setCartCustomerInvoiceMethodSelected(value))
		setWasOpenBefore(true)
	}, [])

	const handleCleanInvoiceData = useCallback(async (): Promise<void> => {
		await dispatch(clearInvoiceData())
	}, [])

	useEffect(() => {
		if (wasOpenBefore || invoiceSelectedType === '') {
			if (isEqual(value, 'NO' as InvoiceExpectationType)) {
				if (isEmpty(nip)) {
					handleOpenReceipt()
				}

				handleCleanInvoiceData()
				handleSetIsInvoiceMethodSelected('NO')
			} else if (isEqual(value, 'YES' as InvoiceExpectationType)) {
				handleOpenInvoice()
				handleSetIsInvoiceMethodSelected('YES')
			}
		} else {
			setWasOpenBefore(true)
		}
	}, [value, nip])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.radios }>
				<Radio
					value={ 'NO' as InvoiceExpectationType }
					field={ field }
					error={ error }
					label={ t('customer.invoice.noThankYou') }
					additionalClass={ styles.radioLabel }
				/>

				{ isInvoiceUnexpectAdnNipExist && (
					<CartCustomerInvoiceReceiptNipBox receiptNip={ nip } onClick={ handleOpenReceipt } />
				) }

				<Radio
					value={ 'YES' as InvoiceExpectationType }
					field={ field }
					error={ error }
					label={ t('customer.invoice.yesPlease') }
					additionalClass={ styles.radioLabel }
				/>

				{ isInvoiceExpectAndNipExist && (
					<CartCustomerInvoiceReceiptNipBox receiptNip={ nip } onClick={ handleOpenInvoice } />
				) }

				{ renderInvoiceBox() }
			</div>

			<CartCustomerInvoiceReceiptModal
				receiptNip={ nip }
				isOpen={ isOpenReceipt }
				nipInvoiceThresholdAmount={ nipInvoiceThresholdAmount }
				onClose={ handleCloseReceipt }
			/>

			<CartCustomerInvoiceModal
				invoice={ invoice }
				totalCostValue={ totalCostValue }
				isOpen={ isOpenInvoice }
				isDeliveryType={ false }
				onClose={ handleCloseInvoice }
				onCancel={ handleCancelInvoice }
			/>
		</div>
	)
}

export { CartCustomerInvoiceCashAndCarry }
