import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'

import { IRadioOption, RadioGroup } from '~/components/core/form'
import { Modal } from '~/components/core/modal'
import { CartCustomerOrderCollectionModalCompanyForm, CartCustomerOrderCollectionModalPrivateForm, CartCustomerOrderCollectionType, ICartCustomerOrderCollectionModalForm, ICartCustomerOrderCollectionModalProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerOrderCollectionModal.module.scss'

const CartCustomerOrderCollectionModal = (props: ICartCustomerOrderCollectionModalProps): ReactElement => {
	const { isOpen, onClose, onCancel, defaultValues } = props
	const { t } = useTranslation(['cart'])

	const schema: yup.SchemaOf<ICartCustomerOrderCollectionModalForm> = useMemo(() => yup.object().shape({
		type: yup.string().oneOf(['PRIVATE', 'COMPANY']).required(),
	}), [])

	const { control } = useForm<ICartCustomerOrderCollectionModalForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			type: 'PRIVATE',
		},
	})

	const radioOptions: IRadioOption<CartCustomerOrderCollectionType>[] = [
		{ label: t('customer.orderCollection.private'), value: 'PRIVATE' },
		{ label: t('customer.orderCollection.company'), value: 'COMPANY' },
	]

	const { field: { value } } = useController({ control, name: 'type' })

	const renderForm = useCallback((): ReactElement => {
		if (isEqual(value, 'PRIVATE' as CartCustomerOrderCollectionType)) {
			return (
				<CartCustomerOrderCollectionModalPrivateForm
					defaultValues={ defaultValues }
					onCancel={ onCancel }
					onClose={ onClose }
				/>
			)
		}

		return (
			<CartCustomerOrderCollectionModalCompanyForm
				defaultValues={ defaultValues }
				onCancel={ onCancel }
				onClose={ onClose }
			/>
		)
	}, [value, defaultValues])

	return (
		<Modal
			isOpen={ isOpen }
			title={ t('customer.orderCollection.form.otherDataToCollectOrder') }
			additionalClass={ styles.wrapper }
			onClose={ onCancel }
		>
			<div className={ styles.form }>
				<RadioGroup
					control={ control }
					name="type"
					options={ radioOptions }
				/>

				<div className={ styles.content }>
					{ renderForm() }
				</div>
			</div>
		</Modal>
	)
}

export { CartCustomerOrderCollectionModal }
