import React, { ReactElement } from 'react'

import { Image } from '~/components/core/image'
import { ICartCustomerAsideBlockProductImageProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerAsideBlockProductImage.module.scss'

const CartCustomerAsideBlockProductImage = (props: ICartCustomerAsideBlockProductImageProps): ReactElement => {
	const { name, image } = props

	return (
		<div className={ styles.wrapper }>
			<Image
				alt={ name }
				src={ image }
				width={ 81 }
				height={ 81 }
				role="presentation"
			/>
		</div>
	)
}

export { CartCustomerAsideBlockProductImage }
