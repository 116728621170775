import React, { ReactElement } from 'react'
import { isEqual } from 'lodash'

import { buildCashOnDeliveryRadioOption, CartPreviewPaymentLabel, ICartPreviewPaymentOption, ICartPreviewPaymentCashOnDeliveryProps } from '~/components/cart/cartPreview'
import { Radio } from '~/components/core/form'

import styles from './CartPreviewPaymentCashOnDelivery.module.scss'

const CartPreviewPaymentCashOnDelivery = (props: ICartPreviewPaymentCashOnDeliveryProps): ReactElement => {
	const { field, error } = props

	const cashOnDeliveryOption: ICartPreviewPaymentOption = buildCashOnDeliveryRadioOption()

	const { value } = cashOnDeliveryOption
	const isChecked = isEqual(field.value, value)

	return (
		<Radio
			key={ value }
			value={ value }
			field={ field }
			error={ error }
			additionalClass={ styles.wrapper }
		>
			<CartPreviewPaymentLabel
				option={ cashOnDeliveryOption }
				isChecked={ isChecked }
				error={ error }
				isDisabled={ false }
			/>
		</Radio>
	)
}

export { CartPreviewPaymentCashOnDelivery }
