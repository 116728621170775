import { createSelector } from 'reselect'
import { map } from 'lodash'

import { IComparisonProduct } from '~/state/reducers/comparisonReducer'
import { IRootState } from '~/state/types'

export const getComparisonProducts = (state: IRootState): IComparisonProduct[] => (
	state.comparison.products
)

export const getComparisonProductsIds = createSelector(
	getComparisonProducts,
	(products: IComparisonProduct[]): number[] => (
		map(products, (product: IComparisonProduct): number => product.id)
	)
)

export const getIsProductsCountError = createSelector(
	getComparisonProducts,
	(products: IComparisonProduct[]): boolean => (
		products.length < 2
	)
)
