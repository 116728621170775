import { InvoiceExpectationType } from '~/components/cart/cartCustomer'
import { IAddressData } from '~/api/dataTypes/address'
import { AcceptanceCheckType } from '~/api/dataTypes/app'
import { CustomerType, CustomerAddressType } from '~/api/dataTypes/customer'
import { OrderStatusType } from '~/api/dataTypes/customerOrder'
import { IGeolocationData } from '~/api/dataTypes/geolocation'
import { IPickupPointData, PickupPointType } from '~/api/dataTypes/pickupPoint'
import { IProductSimple, IProductQuantity } from '~/api/dataTypes/product'
import { IUserData } from '~/api/dataTypes/user'

export type CartStatusType = 'ZERO' | 'VIEW' | 'IDENTIFICATION' | 'CUSTOMER' | 'SUMMARY' | 'FINALIZE'

export type PromotionCodeType = 'ECOMMERCE' | 'LOYALTY' | 'NONE'

export type CartDataAvailabilityLabelType = 'A' | 'B' | 'C' | 'D' | 'E' | 'PICKUP'

export type CartItemListLabelColorType = 'DEFAULT' | 'RED' | 'ORANGE'

export type ChangeCartItemQuantityStatusType = 'QUANTITY_AVAILABLE_ENOUGH' | 'QUANTITY_UNAVAILABLE_ENOUGH' | 'PRODUCT_UNAVAILABLE_AT_ALL' | 'CART_IS_FULL' | 'AVAILABLE_ENOUGH_WITH_CHANGE_AVAILBILITY' | 'PRODUCT_HAS_LIMIT_SELLING'

export type ChangeCartItemQuantityActionType = 'PRODUCT_ADDED' | 'PRODUCT_REMOVED' | 'QUANTITY_ADDED' | 'QUANTITY_REDUCED' | 'AVAILABILITY_CHANGED'

export type AvailabilityInfoStatusType = 'NONE' | 'ALL_AVAILABLE' | 'SOME_UNAVAILABLE'

export type PaymentWayType = 'PAYU_BANK' | 'PRZELEWY24_BANK' | 'PRZELEWY24_BLIK' | 'PAYU_CARD' | 'PRZELEWY24_CARD' | 'PRZELEWY24_INSTALMENT' | 'PRZELEWY24_ONEY' | 'PRZELEWY24_PAYPO' | 'PAYPAL' | 'DELIVERY' | 'PRZELEWY24_APPLE_PAY' | 'PRZELEWY24_GOOGLE_PAY'

export type ReasonCodeType = 'OFFER_ID_NOT_FOUND'

export interface ISimpleAvailabilityData {
	id: number
	label: CartDataAvailabilityLabelType
	name: string
}

export interface IAvailabilityInfoData {
	availabilityMessage: string
	availabilityStatus: AvailabilityInfoStatusType
}

export interface IDeliveryData {
	id: number
	name: string
	fullName: string
	price: number
	processingTime: string
	description: string
	availabilityInfo: IAvailabilityInfoData
	prepaid: boolean
	pickupWithTransportDelivery: boolean
	isAllowed: boolean
	courierNoteAvailable: boolean
	isCourierWithCarryInDelivery: boolean
	isPickupPoint: boolean
	pickupPointTypes: PickupPointType[]
}

export interface IPaymentData {
	id: number
	name: string
	description: string
	paymentWayType: PaymentWayType
	isAllowed: boolean
	isSelected: boolean
}

export interface ICartItemListLabel {
	color: CartItemListLabelColorType
	message: string
	type: CartItemListLabelType
}

export type CartItemListLabelType = 'DEFAULT' | 'PRICE'

export interface IProductsQuantityMap {
	[key: string]: number
}

export interface IDiscountCounterData {
	missingValueForDiscount: number
	discountedDeliveryCost: number
}

export interface IChangeCartItemQuantityStockData {
	saleUnitQuantity: number
	bigPriceUnit: string
	showQuantity: number
	availability: ISimpleAvailabilityData
}

export interface IChangeCartItemQuantity {
	productId: number
	cartItemId: number | null
	productName: string
	status: ChangeCartItemQuantityStatusType
	actions: ChangeCartItemQuantityActionType[]
	currentStock: IChangeCartItemQuantityStockData
	alternativeStock: IChangeCartItemQuantityStockData | null
	isResultAfterDecision: boolean
}

export interface ICartItemListQuantityInfo {
	possibleQuantity: number
	selectedQuantity: number
	incomplete: boolean
	color: CartItemListLabelColorType
	maxQuantityToSale: number | null
}

export interface ICartDataAvailability {
	id: number
	name: string
	label: CartDataAvailabilityLabelType
	onDemand: boolean
	numberOfDeliveryDays: number
}

export interface ICartItemData {
	itemId: number
	productSimple: IProductSimple
	saleUnitName: string
	valueForLine: number
	labels: ICartItemListLabel[]
	quantityInfo: ICartItemListQuantityInfo
	value: number
	wwwUrl: string
}

export interface ICartData {
	anyPostPurchaseRewards: boolean
	cartUuid: string | null
	selectedDeliveryWayId: number | null
	selectedDeliveryWayType: string | null
	selectedPaymentWayId: number | null
	cartStatus: CartStatusType
	numberOfItems: number
	paymentSummaryNetValue: number
	productsValue: number
	productsNetValue: number
	totalCostValue: number
	wwwUrl: string
	productsQuantityMap: IProductsQuantityMap
	paymentSummaryValue: number
	promotionCodeType: PromotionCodeType
	promotionCode: string | null
	promotionCodeUsed: boolean
	promotionCodeValue: string
	pickupInStore: ICartPickupInStore
	deliveryStore: IDeliveryStoreData | null
	pickupPoint: IPickupPointData | null
	availability: ICartDataAvailability
	cartItemList: ICartItemData[]
	promotionValue: number
	deliveryCost: number
	loyaltyCardNumber: string
	appliedPromotions: ICartAppliedPromotions[]
}

export interface ICartAppliedPromotions {
	name: string
	promotionValue: number
}

export interface ICartPickupInStore {
	pickupInStoreDateInfo: string
	shouldRecalculate: boolean
	timeSlot: string
}

export interface ISimpleCartData {
	cartUuid: string | null
	wwwUrl: string
	numberOfItems: number
	productsValue: number
	totalCartValue: number
	cartStatus: CartStatusType
	productsQuantityMap: IProductsQuantityMap
	cartItemList: ICartItemData[]
}

export interface ICartDeliveryNextResponse {
	deliveryList: IDeliveryData[]
	isPromotionCode: boolean
	cart: ICartData
	cartStatus: CartStatusType
}

export interface IDeliveryGroups {
	delivery: IDeliveryGroup
	pickup: IDeliveryGroup
	storeDelivery: IDeliveryGroup
}

export interface IDeliveryGroup {
	deliveryList: IDeliveryData[]
	deliveryType: DeliveryGroupType
	active: boolean
	selected: boolean
}

export type DeliveryGroupType = 'DELIVERY' | 'PICKUP' | 'STORE_DELIVERY'

export interface ICartSimpleResponse {
	cartUuid: string
	wwwUrl: string
	numberOfItems: number
	productsValue: number
	totalCartValue: number
	cartStatus: CartStatusType
	productsQuantityMap: IProductsQuantityMap
	cartItemList: ICartItemData[]
}

export interface IAddToCartResponse {
	simpleCart: ISimpleCartData
	productSimple: IProductSimple
	changeCartItemQuantity: IChangeCartItemQuantity
}

export interface IAddToCartParameters {
	cartUuid?: string | null
	lmReference: string
	quantity: number
}

export interface ITransferProductsToCart {
	params: ITransferProductsToCartParams
	planner: boolean
}

export interface INotAddedProductToCart {
	lmReference: string
	reasonCode: ReasonCodeType
	message: string
}

export interface IAddedProductToCart {
	lmReference: string
}

export interface IProductAddedToCartStatus {
	notAddedOffers: INotAddedProductToCart[]
	addedOffers: IAddedProductToCart[]
}

export interface ITransferProductsFromPlannerToCartParams {
	cartUUID: string | null
	tempoOrderId: string | null
	storeCode: string | null
	items: ISetQuantityProductInCartParameters[]
}

export interface ITransferProductsFromPlannerToCartParamsResponse {
	cartUUID: string
	tempoOrderId: string
	offers: IProductAddedToCartStatus
	addedAllOffers: boolean
}

export interface ITransferProductsToCartParams {
	[key: string]: number
}

export interface ISetQuantityProductInCartParameters {
	lmReference: string
	quantity: number
}

export interface ISetQuantityProductInCartResponse {
	simpleCart: ISimpleCartData
	cartItemList: ICartItemData[]
	changeCartItemQuantity: IChangeCartItemQuantity
}

export interface IRemoveProductFromCartResponse {
	cartUuid: string
	wwwUrl: string
	numberOfItems: number
	productsValue: number
	totalCartValue: number
	cartStatus: CartStatusType
	productsQuantityMap: IProductsQuantityMap
	cartItemList: ICartItemData[]
}

export interface IRemoveProductsFromCartResponse {
	allProductsUnavailable: boolean
	anyDeliveryAndPaymentAvailable: boolean
	cart: ICartData
	cartStatus: CartStatusType
	changeCartItemQuantityList: IChangeCartItemQuantity
	courierNote: string | null
	deliveryGroups: IDeliveryGroups
	discountCounter: IDiscountCounterData | null
	invalidCartMessage: string
	paymentList: IPaymentData[]
	result: ICartResultData
}

export interface IRemoveFromCartParameters {
	lmReference: string
}

export interface IAddToCartResponseException {
	httpCode: number | null
	code: string
	errors: null
	message: string
}

export interface ICartPreviewCalculateParams {
	promotionCode: string | null
	itemQuantityList: IProductQuantity[]
	clearDiscount?: boolean
}

export interface IShoppingCartResponse {
	cartStatus: CartStatusType
	cart: ICartData
	invalidCartMessage: string
	changeCartItemQuantityList: IChangeCartItemQuantity[]
	allProductUnavailable: boolean
	anyDeliveryAndPaymentsAvailable: boolean
	discountCounter: IDiscountCounterData
	deliveryGroups: IDeliveryGroups | null
	courierNote: string | null
	paymentList: IPaymentData[]
}

export interface IShoppingCartSimpleResponse {
	cartStatus: CartStatusType
	cart: ICartData
	changeCartItemQuantityList: IChangeCartItemQuantity[]
	allProductUnavailable: boolean
	discountCounter: IDiscountCounterData
}

export interface ICartDeliveryCalculateParams {
	itemQuantityList: IProductQuantity[]
	deliveryWayId: number | null
	additionalDeliveryWayOptionSelected: boolean
	paymentWayId?: number | null
	pickupPointId?: number | null
	pickupPoint?: IPickupPoint
	promotionCode?: string | null
	clearDiscount?: boolean
}

export interface IGetCartDeliveryPickupInStoreTimeslotsResponse {
	message: string
	timeslotCalendarList: IPickupCalendarTimeslot[]
	errorMessage: string
}

export interface IPickupOccurrenceDate {
	day: number
	month: number
	year: number
	dayOfWeek: string
	label: string
	occurrenceData: number
	occurrenceDateText: string
}

export interface IPickupCalendarTimeslot {
	pickupOccurrenceDate: IPickupOccurrenceDate
	pickupTimeslotList: IPickupTimeslotData[]
}

export interface IPickupTimeslotData {
	possibleHour: string
}

export interface IPickupPoint {
	code: string
	type: PickupPointType
}

export interface ICartDeliveryPickupPointParameters {
	zipCode?: string
	city?: string
	cashOnDelivery?: boolean
	pickupPointType?: PickupPointType
}

export interface ICartDeliveryPickupPointResponse {
	geolocation: IGeolocationData | null
	lastUsedPickupPoint: IPickupPointData | null
	pickupPointList: IPickupPointData[]
}

export interface IContactData {
	firstName: string
	lastName: string
	phone: string
	email: string
	nip: string | null
	companyName: string | null
}

export interface IInvoiceData {
	invoice: boolean
	contact: IInvoiceContact | null
	address: IInvoiceAddress | null
	customerTitle: string | null
	customerType: CustomerType | null
}

export interface IInvoiceContact {
	companyName?: string
	firstName?: string
	lastName?: string
	phone?: string
	email?: string
	nip?: string
}

export interface IInvoiceAddress {
	city: string
	zipCode: string
	street: string
	houseNumber: string
	flatNumber?: string
	mainAddress?: boolean
	addressType?: CustomerAddressType
}

export interface ICartCustomerAddressData {
	city: string | null
	zipCode: string | null
	street: string | null
	houseNumber: string | null
	flatNumber: string | null
}

export interface ICartCustomer {
	customerType: CustomerType
	customerTitle: ICartCustomerTitle
	companyName: string | null
	nip: string | null
	firstName: string | null
	lastName: string | null
	email: string | null
	phone: string | null
	address: ICartCustomerAddressData
}

export interface ICartCustomerResponse {
	cartStatus: CartStatusType
	addressList: IAddressData[]
	customerContact: IContactData
	customer: ICartCustomer
	cart: ICartData
	delivery: IDeliveryData
	invoice: IInvoiceData
	invoiceRequired: boolean
	orderingAsGuest: boolean
	otherContact: boolean
	choosePaymentStep: boolean
	nipInvoiceThresholdAmount: number
	companySimpleInvoiceAvailable: boolean
}

export interface ICartCustomerData extends ICartCustomerResponse {
	customerOtherContact: ICartCustomerContact
	receiptNip: ICartCustomerReceiptNip
	customerGuest: ICartCustomerGuestData
}

export interface ICartCustomerReceiptNip {
	nip: string
	invoiceExpectation: InvoiceExpectationType
}

export interface ICartCustomerInvoice {
	id: number
	city: string
	zipCode: string
	street: string
	houseNumber: string
	flatNumber: string
	mainAddress: boolean
	customerType: CustomerType
	addressType: CustomerAddressType
	firstName: string
	lastName: string
	nip: string
	companyName: string
	country: string
}

export interface ICartCustomerPrivateInvoice {
	firstName: string
	lastName: string
	address: ICartCustomerInvoiceAddress
	customerType: CustomerType
}

export interface ICartCustomerCompanyInvoice {
	companyName: string
	nip: string
	address: ICartCustomerInvoiceAddress
	customerType: CustomerType
	firstName?: string
	lastName?: string
}

export interface ICartCustomerGuestData {
	customerType: CustomerType
	customerTitle: ICartCustomerTitle
	companyName?: string
	nip?: string
	firstName: string
	lastName: string
	email: string
	phone: string
	address: ICartCustomerGuestAddress
	regulations?: boolean
}

export type ICartCustomerTitle = 'MS' | 'MR' | ''

export interface ICartCustomerGuestAddress {
	city: string
	zipCode: string
	street: string
	houseNumber: string
	flatNumber?: string
}

export interface ICartCustomerInvoiceAddress {
	city: string
	zipCode: string
	street: string
	houseNumber: string
	flatNumber?: string
	addressType: CustomerAddressType
}

export interface ICartCustomerContact {
	firstName: string
	lastName: string
	email: string
	phone: string
	companyName?: string | null
}

export interface ICartDeliveryTransportFromStoreCategoriesParameters {
	zipCode: string
}

export interface ITransportCategoryData {
	code: string
	description: string
	name: string
	price: number
	pickupPoint: boolean
	pickupPointType: PickupPointType | null
}

export interface ITransportCategoriesResponse {
	transportCategoryList: ITransportCategoryData[]
	errorMessage: string | null
}

export interface ITransportTimeSlotsCalendarResponse {
	timeslotList: ITimeSlotBasicData[]
	errorMessage: string | null
}

export type TransportFromStoreTimeslotsType = 'BASIC' | 'CALENDAR'

export interface ICartDeliveryTransportFromStoreTimeslotsParameters {
	category: string
	type: TransportFromStoreTimeslotsType
}

export interface ICartIdentificationParameters {
	calendarCartUuid: string | null
	cartUuid: string | null
}

export interface ICartIdentificationResponse {
	cartStatus: CartStatusType
	simpleCart: ISimpleCartData
	cartToMerge: ICartData | null
	user: IUserData
	cartGuestOrderAvailable: boolean
}

export interface ICartIdentificationOrderAsGuestResponse {
	cartGuestOrderAvailable: boolean
	cartStatus: CartStatusType
	cartToMerge: ICartData | null
	simpleCart: ISimpleCartData
	user: IUserData | null
}

export interface ICartMergeParams {
	merge: boolean
	cartUuid: string
}

export interface ICartDeliveryTransportFromStoreParameters {
	category: string
	date: number
	fromTime: number
	toTime: number
	zipCode: string
}

export interface ICartDeliveryTransportFromStorePickupPointParameters {
	category: string
	zipCode: string
	pickupPointId: number
}

export interface ICartCustomerValidateSimpleDataParameters {
	companyName?: string
	firstName: string
	lastName: string
	email: string
	phone: string
}

export interface ICartCustomerNextParams {
	dataProcessingChecks: AcceptanceCheckType[]
	customerAddressId: number | null
	contact: ICartCustomerContact | null
	invoice: IInvoiceData
	receiptNip: string | null
	customer?: ICartCustomerGuestData
}

export interface ICartCustomerNextResponse {
	cart: ICartData
	cartStatus: CartStatusType
}

export enum LoyaltyProgramCardStatus {
	BLOCKED = 'Zablokowany',
}

export interface ILoyaltyProgramWithRewardResponse {
	cardNumber: string
	accountTypeName: string
	accountStatus: string
	cardStatus: LoyaltyProgramCardStatus | string
	message: string
	pointBalance: number
	programRewardList: ILoyaltyProgramRewardData[]
	checkStatus: RewardCheckStatusType
}

export enum RewardCheckStatusType {
	ERROR = 'ERROR',
	INVALID_CARD_NUMBER_ERROR = 'INVALID_CARD_NUMBER_ERROR',
	ACCOUNT_INACTIVE_ERROR = 'ACCOUNT_INACTIVE_ERROR',
	ACCOUNT_WITHOUT_REDEMPTION_ERROR = 'ACCOUNT_WITHOUT_REDEMPTION_ERROR',
	CARD_WITHOUT_REDEMPTION_WARNING = 'CARD_WITHOUT_REDEMPTION_WARNING',
	SUCCESS = 'SUCCESS',
	LOYALTY_TEMPORARILY_NOT_AVAILABLE_ERROR = 'LOYALTY_TEMPORARILY_NOT_AVAILABLE_ERROR',
}

export interface ILoyaltyProgramRewardData {
	active: boolean
	name: string
	description: string
	rewardCode: string
	discountToReward: number
	value: number
}

export interface ICartLoyaltyProgramResponse {
	success: boolean
	message: string
}

export interface ICartLoyaltyProgramRewardParams {
	cardNumber?: string
	cardPassword?: string
	rewardCode: string
}

export interface ICartCheckPriceParams {
	deliveryWayId: number | null
	storeCode?: string
}

export interface IGetCartCheckPriceResponse {
	currentSelection: IGetCartCheckPriceSelection
	newSelection: IGetCartCheckPriceSelection
}

export interface ICheckPriceData {
	data: IGetCartCheckPriceResponse
	onConfirm: () => void
}

export interface IGetCartCheckPriceSelection {
	changeInfo: IGetCartCheckPriceSelectionChangeInfo
	itemList: IGetCartCheckPriceSelectionItemList[]
}

export interface IGetCartCheckPriceSelectionItemList {
	priceInfo: IGetCartCheckPriceSelectionItemListPriceInfo
	productSimple: IProductSimple
	labels: ICartItemListLabel[]
}

export interface IGetCartCheckPriceSelectionItemListPriceInfo {
	saleUnitName: string
	saleUnitPrice: number
	saleUnitQuantity: number
	value: number
}

export interface IGetCartCheckPriceSelectionChangeInfo {
	storeName: string
	type: string
}

export interface ICartSummaryContactData {
	customerType: CustomerType
	firstName: string
	lastName: string
	phone: string
	email: string
	companyName: string | null
}

export interface ICartSummaryResponse {
	receiptNip: string
	otherContact: boolean
	invoiceSelected: boolean
	cartStatus: CartStatusType
	cartItemList: ICartItemData[]
	cartSimple: ICartSimpleSummaryData | null
	cartDelivery: ICartDeliverySummaryData
	paymentWay: ICartSummaryPaymentData | null
	customer: ICartCustomerSummaryData
	contact: ICartSummaryContactData
	invoice: ICartInvoiceSummaryData | null
	changedPricesAlertMessage: string | null
}

export interface ICartSummaryFinalizeResponse {
	requiredStatus: CartStatusType
	paymentStatus: string
	orderStatus: string
	orderStatusType: OrderStatusType
	orderFrontId: string
	orderTransactionId: string
	orderPaymentTerm: number
	useP24RestApi: boolean
	orderNumber: string | null
	newCartUuid: string | null
}

export interface ICartSummaryPaymentData {
	paymentWayType: PaymentWayType
	name: string
}

export interface ICartSimpleSummaryData {
	summaryNetValue: number
	summaryValue: number
	deliveryValue: number
	totalCartValue: number
	promotionSummaryValue: number
	promotionValue: number
	productsValue: number
	productsNetValue: number
	appliedPromotions: ICartAppliedPromotions[]
}

export interface ICartDeliverySummaryData {
	name: string
	courierNote: string
	processingTime: string
	deliveryValue: number
	pickupPoint: IPickupPointData | null
	pickupPointStore: IPickupPointStoreData | null
	deliveryStore: IDeliveryStoreData | null
}

export interface IDeliveryStoreData {
	storeName: string
	zipCode: string
	category: string
	categoryName: string
	timeSlot: ITimeSlotBasicData
	transportCalculation: boolean
	shouldRecalculate: boolean
	pickupPoint: boolean
}

export interface ITimeSlotBasicData {
	date: number
	fromTime: number
	toTime: number
}

export interface IPickupPointStoreData {
	storeName: string
	street: string
	city: string
	zipCode: string
	informationPointPhone: string
}

export interface ICartCustomerSummaryData {
	firstName: string
	lastName: string
	phone: string
	email: string
	street: string
	houseNumber: string
	flatNumber: string
	city: string
	zipCode: string
}

export interface ICartInvoiceSummaryData {
	companyName: string
	nip: string
	firstName: string
	lastName: string
	city: string
	zipCode: string
	street: string
	houseNumber: string
	flatNumber: string
}

export interface ICheckAvailabilityResponse {
	itemList: ICartItemData[]
}

export interface ICartResultData {
	resultCode: number
	message: string
	bindingErrors: IBindingErrorsData[]
	globalErrors: string[]
}

export interface IBindingErrorsData {
	[key: string]: string
}
