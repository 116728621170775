import queryString from 'query-string'
import { isUndefined, isNil, includes, reduce, isEmpty } from 'lodash'

import { IComparisonQueryParams } from '~/api/dataTypes/comparison'

export const getRedirectUrl = (baseUrl: string, redirectUrl?: string): string => (
	`${ baseUrl }${ !isUndefined(redirectUrl) ? `?from=${ redirectUrl }` : '' }`
)

export const getUrlWithQuery = (baseUrl: string, queries: Record<string, string> = {}): string => {
	if (isEmpty(queries)) {
		return baseUrl
	}

	const initialValue: string = includes(baseUrl, '?') ? '&' : '?'

	const stringifyQuery: string = reduce(Object.keys(queries), (previousValue: string, currentValue: string, index: number) => {
		const separator: string = index === 0 ? '' : '&'
		const query: string = separator.concat(`${ currentValue }=${ queries[currentValue] }`)

		return previousValue.concat(query)
	}, initialValue)

	return baseUrl.concat(stringifyQuery)
}

export const getNoCacheParam = (noCacheParam?: string | string[]): any => noCacheParam ? `?${ queryString.stringify({ noCache: noCacheParam }) }` : ''

// todo remove
export const urls = {
	account: () => '/account',
	newspaperList: () => '/newspaper/list',
	comparison: (params?: IComparisonQueryParams) => isUndefined(params) ? '/comparison' : `/comparison?${ queryString.stringify(params) }`,
	accountMarketingConsents: () => '/account/marketing-consents',
	accountOpinions: () => '/account/opinions/list',
	accountOrderDetails: (id: number, tab?: number) => isNil(tab) ? `/account/orders?id=${ id }` : `/account/orders?id=${ id }&tab=${ tab }`,
	accountReturnsApplicationOrder: () => '/account/returns/application-order',
	accountReturnsApplication: (id: number) => `/account/returns/application?id=${ id }`,
	accountReturnsApplicationSent: (id: number, date: number, orderId: number) => `/account/returns/application-sent?id=${ id }&date=${ date }&orderId=${ orderId }`,
	accountLoyaltyProgram: () => '/account/loyalty-program',
	accountLoyaltyProgramDashboard: () => '/account/loyalty-program/dashboard',
	accountLoyaltyProgramConnect: () => '/account/loyalty-program/connect',
	accountLoyaltyProgramConnectSuccess: () => '/account/loyalty-program/connect/success',
	accountLoyaltyProgramConnectAccount: () => '/account/loyalty-program/connect/account',
	accountLoyaltyProgramConnectCard: () => '/account/loyalty-program/connect/card',
	accountLoyaltyProgramConnectForgotPassword: () => '/account/loyalty-program/connect/forgot-password',
	accountLoyaltyProgramConnectForgotPasswordSuccess: (email: string) => `/account/loyalty-program/connect/forgot-password-success?email=${ email }`,
	accountLoyaltyProgramJoin: () => '/account/loyalty-program/join',
	accountLoyaltyProgramJoinSuccess: () => '/account/loyalty-program/join/success',
	accountLoyaltyProgramExchangePoints: () => '/account/loyalty-program/exchange-points',
	accountLoyaltyProgramVoucherSuccess: (id: number) => `/account/loyalty-program/exchange-points/voucher-activation-success?id=${ id }`,
	accountLoyaltyProgramExchangePointsFoundation: () => '/account/loyalty-program/exchange-points/foundation',
	accountLoyaltyProgramExchangePointsFoundationSuccess: () => '/account/loyalty-program/exchange-points/foundation-success',
	accountLoyaltyProgramExchangePointsTransfer: () => '/account/loyalty-program/exchange-points/transfer',
	accountLoyaltyProgramExchangePointsTransferSuccess: () => '/account/loyalty-program/exchange-points/transfer-success',
	accountLoyaltyProgramHistory: () => '/account/loyalty-program/history',
	accountLoyaltyProgramHistorySale: (id: number) => `/account/loyalty-program/history/sale?id=${ id }`,
	accountLoyaltyProgramHistoryReturn: (id: number) => `/account/loyalty-program/history/return?id=${ id }`,
	accountLoyaltyProgramHistoryAdditionalCard: (id: number) => `/account/loyalty-program/history/additional-card?id=${ id }`,
	accountLoyaltyProgramHistoryMigrationPoints: (id: number) => `/account/loyalty-program/history/migration-points?id=${ id }`,
	accountLoyaltyProgramHistoryExchangePointsForVoucher: (id: number) => `/account/loyalty-program/history/exchange-points-for-voucher?id=${ id }`,
	accountLoyaltyProgramHistoryDirectExchangePoints: (id: number) => `/account/loyalty-program/history/direct-exchange-points?id=${ id }`,
	accountLoyaltyProgramHistoryExchangePoints: (id: number) => `/account/loyalty-program/history/exchange-points?id=${ id }`,
	accountLoyaltyProgramHistoryExpiredPoints: (id: number) => `/account/loyalty-program/history/expired-points?id=${ id }`,
	accountLoyaltyProgramHistoryCustomerAssign: (id: number) => `/account/loyalty-program/history/customer-assign?id=${ id }`,
	accountLoyaltyProgramHistoryCustomerRegister: (id: number) => `/account/loyalty-program/history/customer-register?id=${ id }`,
	accountLoyaltyProgramHistoryPointsCorrection: (id: number) => `/account/loyalty-program/history/points-correction?id=${ id }`,
	customerSpaceUrl: () => '/strefa-klienta/konto',
}
