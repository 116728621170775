import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Button } from '~/components/core/button'
import { ICartCustomerInvoiceReceiptModalActionsProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerInvoiceReceiptModalActions.module.scss'

const CartCustomerInvoiceReceiptModalActions = (props: ICartCustomerInvoiceReceiptModalActionsProps): ReactElement => {
	const { onCancel, onSubmit } = props
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			<Button
				type="submit"
				variant="secondary"
				text={ t('customer.invoice.saveChanges') }
				additionalClass={ styles.button }
				onClick={ onSubmit }
			/>

			<Button
				isReverse
				variant="secondary"
				text={ t('customer.invoice.cancel') }
				additionalClass={ styles.button }
				onClick={ onCancel }
			/>
		</div>
	)
}

export { CartCustomerInvoiceReceiptModalActions }
