import { AxiosResponse } from 'axios'

import { request } from '~/api/requests/axios'
import { ICustomerLoyaltyAccountResponse, ICustomerLoyaltyExistRespone } from '~/api/dataTypes/customerLoyalty'

export const getCustomerLoyaltyAccount = async (): Promise<AxiosResponse<ICustomerLoyaltyAccountResponse>> => (
	await request.get('/v1/loyalty/account', {
		service: 'CUSTOMER_LOYALTY_INTEGRATION_SERVICE',
	})
)

export const getCustomerLoyaltyExist = async (): Promise<AxiosResponse<ICustomerLoyaltyExistRespone>> => (
	await request.get('/v1/loyalty/customer-exists', {
		service: 'CUSTOMER_LOYALTY_INTEGRATION_SERVICE',
	})
)
