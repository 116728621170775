import produce, { Draft } from 'immer'
import { isEmpty, isNull } from 'lodash'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { CartCustomerActionType, CartCustomerType } from '~/actions/cartCustomer'
import { IInvoiceContact } from '~/api/dataTypes/cart'

import { ICartCustomerState } from './types'
import { cartCustomerInitialState as initialState } from './constants'

export const cartCustomer = produce((draft: Draft<ICartCustomerState>, action: AppPromiseAction<CartCustomerActionType>) => {
	switch (action.type) {
		case REQUEST(CartCustomerType.GET_CART_CUSTOMER):
			draft.isPending = true

			return draft
		case FAILURE(CartCustomerType.GET_CART_CUSTOMER):
			draft.isPending = false

			return draft
		case SUCCESS(CartCustomerType.GET_CART_CUSTOMER):
			if (isEmpty(action.payload.data.cart)) {
				draft.data = initialState.data
				draft.isPending = false

				return draft
			}

			draft.data = { ...draft.data, ...action.payload.data }
			draft.data.customerGuest.customerType = action.payload.data.customer.customerType

			draft.data.customerOtherContact = action.payload.data.otherContact ? action.payload.data.customerContact : initialState.data.customerOtherContact

			draft.isPending = false

			return draft
		case SUCCESS(CartCustomerType.VALIDATE_CART_CUSTOMER_COMPANY_CONTACT):
		case SUCCESS(CartCustomerType.VALIDATE_CART_CUSTOMER_PRIVATE_CONTACT):
			draft.data.customerOtherContact = action.payload
			draft.isNextButtonDisabled = isNull(draft.addressId)

			return draft
		case SUCCESS(CartCustomerType.SET_ADDRESS_ID):
			draft.addressId = action.payload
			draft.isNextButtonDisabled = isNull(draft.addressId)

			return draft
		case SUCCESS(CartCustomerType.SET_ANOTHER_RECEIVE_ACTIVE):
			draft.isAnotherReceiverActive = action.payload
			draft.isNextButtonDisabled = isNull(draft.addressId)

			return draft
		case SUCCESS(CartCustomerType.ADD_RECEIPT_NIP):
			draft.data.invoice.contact = null
			draft.data.invoice.address = null
			draft.data.invoice.customerType = null
			draft.data.invoice.invoice = false

			draft.data.receiptNip = { ...draft.data.receiptNip, ...action.payload }

			return draft
		case SUCCESS(CartCustomerType.VALIDATE_CART_CUSTOMER_PRIVATE_INVOICE): {
			const { firstName, lastName, address, customerType } = action.payload

			const contact: IInvoiceContact = { firstName, lastName }

			draft.data.invoice.contact = contact
			draft.data.invoice.address = address
			draft.data.invoice.customerType = customerType
			draft.data.invoice.invoice = true

			draft.data.receiptNip.nip = ''

			return draft
		}
		case SUCCESS(CartCustomerType.VALIDATE_CART_CUSTOMER_COMPANY_INVOICE): {
			const { companyName, nip, firstName, lastName, address, customerType } = action.payload

			const contact: IInvoiceContact = { companyName, nip, firstName, lastName }

			draft.data.invoice.contact = contact
			draft.data.invoice.address = address
			draft.data.invoice.customerType = customerType
			draft.data.invoice.invoice = true

			draft.data.receiptNip.nip = ''

			return draft
		}
		case SUCCESS(CartCustomerType.SET_CART_CUSTOMER_GUEST): {
			draft.data.customerGuest = action.payload

			return draft
		}
		case SUCCESS(CartCustomerType.SET_CART_CUSTOMER_GUEST_FORM_TYPE): {
			draft.data.customerGuest.customerType = action.payload

			return draft
		}
		case SUCCESS(CartCustomerType.SET_CART_CUSTOMER_EMAIL): {
			draft.data.customerGuest.email = action.payload

			return draft
		}
		case SUCCESS(CartCustomerType.SET_CART_CUSTOMER_INVOICE_METHOD_SELECTED): {
			draft.invoiceSelectedType = action.payload
			draft.isNextButtonDisabledByInvoice = action.payload === ''

			return draft
		}
		case SUCCESS(CartCustomerType.CLEAR_CART_CUSTOMER_INVOICE_DATA): {
			draft.data.invoice.contact = null
			draft.data.invoice.address = null
			draft.data.invoice.customerType = null
			draft.data.invoice.invoice = false

			return draft
		}
		case SUCCESS(CartCustomerType.CLEAR_CART_CUSTOMER_DATA): {
			draft.data = initialState.data
			draft.addressId = initialState.addressId
			draft.invoiceSelectedType = initialState.invoiceSelectedType
			draft.isAnotherReceiverActive = initialState.isAnotherReceiverActive
			draft.isNextButtonDisabled = initialState.isNextButtonDisabled
			draft.isNextButtonDisabledByInvoice = initialState.isNextButtonDisabledByInvoice

			return draft
		}
		case FAILURE(CartCustomerType.VALIDATE_CART_CUSTOMER_COMPANY_INVOICE):
		case FAILURE(CartCustomerType.VALIDATE_CART_CUSTOMER_PRIVATE_INVOICE): {
			draft.isNextButtonDisabledByInvoice = initialState.isNextButtonDisabledByInvoice

			return draft
		}
	}
}, initialState)
