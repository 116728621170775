import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { IProductBlockMiniNameAndPriceProps } from '~/components/core/productBlock'
import { Link } from '~/components/core/link'
import { ProductPrice } from '~/components/core/productPrice'

import styles from './ProductBlockMiniNameAndPrice.module.scss'

const ProductBlockMiniNameAndPrice = (props: IProductBlockMiniNameAndPriceProps): ReactElement => {
	const {
		name, url, price, priceUnit, priceStrikethrough, promoLabel, promotionLabelCode,
		omnibusPrice = null,
		ariaLabel = '',
		theme = {},
	} = props

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)
	const priceClass = classNames(styles.price, theme.priceWrapper)

	return (
		<div className={ wrapperClass }>
			<Link
				href={ url }
				additionalClass={ styles.name }
				ariaLabel={ ariaLabel }
			>
				{ name }
			</Link>

			<ProductPrice
				price={ price }
				pricePromoLabel={ promoLabel }
				priceStrikethrough={ priceStrikethrough }
				priceUnit={ priceUnit }
				omnibusPrice={ omnibusPrice }
				promotionLabelCode={ promotionLabelCode }
				theme={ {
					wrapper: priceClass,
					priceSaleWrapper: theme.priceSaleWrapper,
				} }
				size="small"
			/>
		</div>
	)
}

export { ProductBlockMiniNameAndPrice }
