import dayjs from 'dayjs'
import { isEqual, isNull, isEmpty, isUndefined } from 'lodash'

import { CustomerDataRequestUnionType, ICustomerPrivateDataRequest, ICustomerCompanyDataRequest, ICustomerConsentData } from '~/api/dataTypes/customer'
import { ICustomerComparisonData, UseCustomerUpdateType } from '~/hooks/customerUpdate'
import { CustomerDataType } from '~/state/reducers/customerReducer'

export const isCustomerUpdated = (newCustomerData: Partial<CustomerDataRequestUnionType> | null, currentCustomerData: CustomerDataType): boolean => {
	if (isNull(currentCustomerData) || isNull(newCustomerData)) {
		return false
	}

	const { customerType } = newCustomerData

	if (customerType === 'COMPANY') {
		return isComparisonCompanyData(newCustomerData, currentCustomerData)
	}

	return isComparisonPrivateData(newCustomerData, currentCustomerData)
}

export const isComparisonPrivateData = (newData: Partial<ICustomerPrivateDataRequest>, currentData: CustomerDataType): boolean => {
	const newBirthData = !isUndefined(newData.birthDate) ? dayjs(newData.birthDate).format('YYYY-MM-DD') : ''

	const newDataToCompare: Partial<ICustomerComparisonData> = {
		birthDate: newBirthData,
		firstName: newData.firstName || '',
		lastName: newData.lastName || '',
		nickname: newData.nickname || '',
		phone: newData.phone || '',
		mainAddress: {
			city: newData.mainAddress?.city || '',
			country: newData.mainAddress?.country || '',
			flatNumber: newData.mainAddress?.flatNumber || '',
			houseNumber: newData.mainAddress?.houseNumber || '',
			postalCode: newData.mainAddress?.postalCode || '',
			street: newData.mainAddress?.street || '',
		},
	}

	const currentDataToCompare: Partial<ICustomerComparisonData> = {
		birthDate: currentData?.customer.birthDate || '',
		firstName: currentData?.customer.firstName,
		lastName: currentData?.customer.lastName,
		nickname: currentData?.customer.nickname || '',
		phone: isEmpty(newDataToCompare.phone) ? '' : currentData?.customer.phone,
		mainAddress: {
			city: currentData?.customer.mainAddress?.city || '',
			country: currentData?.customer.mainAddress?.country || '',
			flatNumber: currentData?.customer.mainAddress?.flatNumber || '',
			houseNumber: currentData?.customer.mainAddress?.houseNumber || '',
			postalCode: currentData?.customer.mainAddress?.postalCode || '',
			street: currentData?.customer.mainAddress?.street || '',
		},
	}

	return isEqual(newDataToCompare, currentDataToCompare)
}

export const isComparisonCompanyData = (newData: Partial<ICustomerCompanyDataRequest>, currentData: CustomerDataType): boolean => {
	const newBirthData = !isUndefined(newData.birthDate) ? dayjs(newData.birthDate).format('YYYY-MM-DD') : ''

	const newDataToCompare: Partial<ICustomerComparisonData> = {
		birthDate: newBirthData,
		firstName: newData.firstName || '',
		lastName: newData.lastName || '',
		nickname: newData.nickname || '',
		corporateName: newData.corporateName || '',
		nip: newData.nip || '',
		phone: newData.phone || '',
		mainAddress: {
			city: newData.mainAddress?.city || '',
			country: newData.mainAddress?.country || '',
			flatNumber: newData.mainAddress?.flatNumber || '',
			houseNumber: newData.mainAddress?.houseNumber || '',
			postalCode: newData.mainAddress?.postalCode || '',
			street: newData.mainAddress?.street || '',
		},
	}

	const currentDataToCompare: Partial<ICustomerComparisonData> = {
		birthDate: currentData?.customer.birthDate || '',
		firstName: currentData?.customer.firstName,
		lastName: currentData?.customer.lastName,
		nickname: currentData?.customer.nickname || '',
		corporateName: currentData?.customer.corporateName || '',
		nip: currentData?.customer.nip || '',
		phone: isEmpty(newDataToCompare.phone) ? '' : currentData?.customer.phone,
		mainAddress: {
			city: currentData?.customer.mainAddress?.city || '',
			country: currentData?.customer.mainAddress?.country || '',
			flatNumber: currentData?.customer.mainAddress?.flatNumber || '',
			houseNumber: currentData?.customer.mainAddress?.houseNumber || '',
			postalCode: currentData?.customer.mainAddress?.postalCode || '',
			street: currentData?.customer.mainAddress?.street || '',
		},
	}

	return isEqual(newDataToCompare, currentDataToCompare)
}

export const isCustomerPhoneUpdated = (newCustomerData: Partial<CustomerDataRequestUnionType> | null, currentCustomerData: CustomerDataType): boolean => {
	if (isNull(currentCustomerData) || isNull(newCustomerData)) {
		return false
	}

	const { phone: newPhone } = newCustomerData
	const { customer: { phone: currentPhone } } = currentCustomerData

	return isEqual(newPhone, currentPhone)
}

export const isCustomerConsentsUpdated = (newCustomerData: Partial<CustomerDataRequestUnionType> | null, currentCustomerData: CustomerDataType): boolean => {
	if (isNull(currentCustomerData) || isNull(newCustomerData)) {
		return false
	}

	const { consents: newConsents } = newCustomerData
	const { consents: currentConsents } = currentCustomerData

	return isEqual(newConsents, currentConsents)
}

export const getComparisonFunction = (updateType: UseCustomerUpdateType): ((newCustomerData: Partial<CustomerDataRequestUnionType> | null, currentCustomerData: CustomerDataType) => boolean) => {
	switch (updateType) {
		case 'PHONE':
			return isCustomerPhoneUpdated
		case 'CONSENTS':
			return isCustomerConsentsUpdated
		default:
			return isCustomerUpdated
	}
}
