import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Image } from '~/components/core/image'
import { vars } from '~/statics'
import { RadioIcon } from '~/components/core/form'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDeliveryPickupTransferLogotype.module.scss'

const CartPreviewDeliveryPickupTransferLogotype = (): ReactElement => {
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<RadioIcon isChecked />

			<div className={ styles.img }>
				<Image
					priority
					src={ vars.images.logo }
					alt={ t('leroyMerlin') }
					width={ 40 }
					height={ 25 }
				/>
			</div>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferLogotype }
