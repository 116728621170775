import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Icon } from '~/components/core/icon'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './AddToCartModalHeader.module.scss'

const AddToCartModalHeader = (): ReactElement => {
	const { t } = useTranslation(['common'])
	const { isMobile } = useMediaQuery()

	const iconSize = isMobile ? 30 : 25

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.icon }>
				<Icon name="checkCircle" width={ iconSize } />
			</span>

			<h2 className={ styles.title }>
				{ t('productAddedToCart') }
			</h2>
		</div>
	)
}

export { AddToCartModalHeader }
