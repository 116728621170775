import React, { ReactElement, useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { Image } from '~/components/core/image'
import { vars } from '~/statics'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IHeaderLogotypeProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'

import styles from './HeaderLogotype.module.scss'

const HeaderLogotype = (props: IHeaderLogotypeProps): ReactElement => {
	const {
		isWithoutAnimation = false,
	} = props
	const { t } = useTranslation(['header', 'common'])
	const { y: heightPosition } = useWindowScroll()
	const [isHeaderFixedState, setIsHeaderFixedState] = useState<boolean>(false)

	const isBiggerLogotype = !isHeaderFixedState && !isWithoutAnimation
	const isHeaderFixed = isHeaderFixedState || isWithoutAnimation

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.withFixedHeader]: isHeaderFixedState,
	})

	const linkClass = classNames(styles.link, {
		[styles.desktopSize]: isBiggerLogotype,
		[styles.fixedSize]: !isBiggerLogotype && isHeaderFixedState,
		[styles.withoutAnimation]: isWithoutAnimation,
	})

	const logoClass = classNames(styles.logo, {
		[styles.withoutAnimation]: isWithoutAnimation,
	})

	const triangleClass = classNames(styles.triangle, {
		[styles.fixedTriangle]: isHeaderFixed,
	})

	useEffect(() => {
		setIsHeaderFixedState(heightPosition > 0)
	}, [heightPosition])

	return (
		<div className={ wrapperClass }>
			<div className={ triangleClass } />

			<div className={ logoClass }>
				<Link
					href="/"
					additionalClass={ linkClass }
					ariaLabel={ t('homeLinkLabel') }
					dataTestId={ DATA_TESTID.HEADER.LOGO }
				>
					<Image
						priority
						loading="eager"
						width={ 0 }
						height={ 0 }
						src={ vars.images.logo }
						alt={ t('leroyMerlin', { ns: 'common' }) }
						className={ styles.img }
					/>
				</Link>
			</div>
		</div>
	)
}

export { HeaderLogotype }
