import React, { forwardRef, ReactElement, Ref } from 'react'
import { isEmpty } from 'lodash'

import { BreadcrumbsWrapper, BreadcrumbsItems, IBreadcrumbsProps } from '~/components/core/breadcrumbs'

const Breadcrumbs = forwardRef((props: IBreadcrumbsProps, ref: Ref<HTMLDivElement>): ReactElement | null => {
	const {
		breadcrumbs = [],
		name = '',
		theme = {},
	} = props

	if (isEmpty(breadcrumbs) && isEmpty(name)) {
		return null
	}

	return (
		<BreadcrumbsWrapper ref={ ref } additionalClass={ theme.wrapper }>
			<BreadcrumbsItems breadcrumbs={ breadcrumbs } name={ name } />
		</BreadcrumbsWrapper>
	)
})

export { Breadcrumbs }
