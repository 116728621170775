import React, { ReactElement, useContext } from 'react'
import { find, isUndefined } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { AcceptanceCheckType } from '~/api/dataTypes/app'

import styles from './CartShareDisclaimer.module.scss'

const CartShareDisclaimer = (): ReactElement | null => {
	const { personalDataProcessingChecks: { contactChecks } } = useContext(AppParametersContext)

	const contactAgreement = find(contactChecks, ['code', 'CONTACT_PROCESSING' as AcceptanceCheckType])

	if (isUndefined(contactAgreement)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<span dangerouslySetInnerHTML={ { __html: contactAgreement.content } } />
		</div>
	)
}

export { CartShareDisclaimer }
