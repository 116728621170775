import { isNull } from 'lodash'

import { AppPromiseThunkAction } from '~/actions'
import { GetCartIdentificationType, CartIdentificationType, ResetCartIdentificationType, SetOrderAsGuestType } from '~/actions/cartIdentification'
import { ICartIdentificationResponse } from '~/api/dataTypes/cart'
import { adeoCookies } from '~/utils/adeoCookies'

// eslint-disable-next-line @typescript-eslint/typedef
export const postCartIdentificationData = (data: ICartIdentificationResponse): AppPromiseThunkAction<GetCartIdentificationType> => async (dispatch) => {
	const { simpleCart: { cartUuid } } = data

	if (!isNull(cartUuid)) {
		adeoCookies.customerUuid.set({ cartUuid, hostname: window.location.hostname })
	}

	return dispatch({
		type: CartIdentificationType.CART_IDENTIFICATION,
		payload: Promise.resolve(data),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartIdentificationData = (data: ICartIdentificationResponse): AppPromiseThunkAction<GetCartIdentificationType> => async (dispatch) => {
	return dispatch({
		type: CartIdentificationType.CART_IDENTIFICATION,
		payload: Promise.resolve(data),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const resetCartIdentificationData = (): AppPromiseThunkAction<ResetCartIdentificationType> => async (dispatch) => {
	return dispatch({
		type: CartIdentificationType.RESET_CART_IDENTIFICATION,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setOrderAsGuestData = (isOrderAsGuest: boolean): AppPromiseThunkAction<SetOrderAsGuestType> => async (dispatch) => {
	return dispatch({
		type: CartIdentificationType.SET_ORDER_AS_GUEST,
		payload: Promise.resolve(isOrderAsGuest),
	})
}
