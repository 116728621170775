import { CalendarServicePaymentWayCodeType } from '~/api/dataTypes/calendarService'
import { PaymentWayType } from '~/api/dataTypes/cart'
import { vars } from '~/statics'

export const getPaymentLogo = (paymentCode: PaymentWayType | CalendarServicePaymentWayCodeType): string => {
	switch (paymentCode) {
		case 'PRZELEWY24_BANK':
		case 'BANK':
			return vars.images.paymentTransfer
		case 'PRZELEWY24_BLIK':
		case 'BLIK':
			return vars.images.paymentBlik
		case 'PRZELEWY24_CARD':
		case 'CARD':
			return vars.images.paymentCard
		case 'PRZELEWY24_ONEY':
		case 'ONEY':
			return vars.images.paymentOney
		case 'PRZELEWY24_PAYPO':
		case 'PAYPO':
			return vars.images.paymentPaypo
		case 'PRZELEWY24_GOOGLE_PAY':
		case 'GOOGLE_PAY':
			return vars.images.paymentGpay
		case 'PRZELEWY24_APPLE_PAY':
		case 'APPLE_PAY':
			return vars.images.paymentApay
		default:
			return vars.images.paymentTransfer
	}
}
