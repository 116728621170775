import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './HeaderTopBarFavoritesModalHeader.module.scss'

const HeaderTopBarFavoritesModalHeader = (): ReactElement => {
	const { t } = useTranslation(['header'])

	return (
		<header className={ styles.wrapper }>
			<span>
				{ t('topBar.favorites.caption') }
			</span>
		</header>
	)
}

export { HeaderTopBarFavoritesModalHeader }
