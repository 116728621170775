import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { IStoreResponse } from '~/api/dataTypes/store'
import { ICustomerOrderListResponse, ICustomerOrderDetailsResponse, IAvailableOrderPaymentWayResponse, ICustomerPostAddToCartResponse } from '~/api/dataTypes/customerOrder'

export enum AccountOrdersType {
	GET_ORDERS_LIST = 'accountOrders/GET_ORDERS_LIST',
	GET_ORDER_DETAILS = 'accountOrders/GET_ORDER_DETAILS',
	SET_ORDER_ACTIVE_DETAILS = 'accountOrders/SET_ACTIVE_ORDER_DETAILS',
	CLEAR_ORDER_ACTIVE_DETAILS = 'accountOrders/CLEAR_ORDER_ACTIVE_DETAILS',
	GET_ORDER_PAYMENT_WAYS = 'accountOrders/GET_ORDER_PAYMENT_WAYS',
	GET_ORDER_STORE_DETAILS = 'accountOrders/GET_ORDER_STORE_DETAILS',
	ADD_PRODUCTS_TO_CART = 'accountOrders/ADD_PRODUCTS_TO_CART',
	CANCEL_ORDER = 'accountOrders/CANCEL_ORDER',
	UPDATE_ORDERS_LIST = 'accountOrders/UPDATE_ORDERS_LIST',
}

export type GetOrdersListActionType = AppAction<AccountOrdersType.GET_ORDERS_LIST, AxiosResponse<ICustomerOrderListResponse>>
export type GetOrderDetailsActionType = AppAction<AccountOrdersType.GET_ORDER_DETAILS, AxiosResponse<ICustomerOrderDetailsResponse>>
export type SetOrderActiveDetailsActionType = AppAction<AccountOrdersType.SET_ORDER_ACTIVE_DETAILS, string>
export type ClearOrderActiveDetailsActionType = AppAction<AccountOrdersType.CLEAR_ORDER_ACTIVE_DETAILS>
export type GetOrderPaymentWaysActionType = AppAction<AccountOrdersType.GET_ORDER_PAYMENT_WAYS, AxiosResponse<IAvailableOrderPaymentWayResponse[]>>
export type GetOrderStoreDetailsActionType = AppAction<AccountOrdersType.GET_ORDER_STORE_DETAILS, AxiosResponse<IStoreResponse> | null>
export type AddOrderProductsToCartActionType = AppAction<AccountOrdersType.ADD_PRODUCTS_TO_CART, AxiosResponse<ICustomerPostAddToCartResponse>>
export type CancelOrderActionType = AppAction<AccountOrdersType.CANCEL_ORDER>
export type UpdateOrdersListActionType = AppAction<AccountOrdersType.UPDATE_ORDERS_LIST, AxiosResponse<ICustomerOrderListResponse>>

export type AccountOrdersActionType = GetOrdersListActionType | GetOrderDetailsActionType | SetOrderActiveDetailsActionType | ClearOrderActiveDetailsActionType |
GetOrderPaymentWaysActionType | GetOrderStoreDetailsActionType | AddOrderProductsToCartActionType | CancelOrderActionType | UpdateOrdersListActionType
