import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useRouter } from 'next/router'
import { noop, toString } from 'lodash'

import { putCartLoyaltyProgramRemoveCard } from '~/api/requests/cart'
import { getRedirectUrl } from '~/utils/urls'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { UserContext } from '~/providers/userProvider'
import { useCartPreviewCalculate } from '~/hooks/cartPreviewCalculate'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { IRootState } from '~/state/types'
import { getCartItemQuantityList } from '~/state/reducers/cartPreviewReducer'
import { CartPreviewSummaryLoyaltyProgramFormFields, CartPreviewSummaryLoyaltyProgramNotLogged, ICartPreviewSummaryDiscountFormData, ICartPreviewSummaryLoyaltyProgramProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewSummaryLoyaltyProgram.module.scss'

const CartPreviewSummaryLoyaltyProgram = (props: ICartPreviewSummaryLoyaltyProgramProps): ReactElement => {
	const {
		hasLoyaltyCard, promotionCodeUsed, loyaltyCardNumber, isOpenPinModal, onOpenPinModal,
		onRemoveCard = noop,
	} = props
	const itemQuantityList = useSelector((state: IRootState) => getCartItemQuantityList(state))
	const { watch, reset } = useFormContext<ICartPreviewSummaryDiscountFormData>()
	const { isLogged } = useContext(UserContext)
	const { urls } = useContext(AppParametersContext)
	const { t } = useTranslation(['cart'])
	const { preventAlert } = useAlert()
	const router = useRouter()
	const { onSetPromotionCode } = useCartPreviewCalculate()
	const { sendLogError } = useLogError()

	const { promotionCode } = watch()

	const handleSignIn = useCallback(async (): Promise<void> => {
		await router.push(getRedirectUrl(urls.login, router.asPath))
	}, [urls.login, router.asPath])

	const handleRemoveCard = useCallback(async (): Promise<void> => {
		try {
			const { data: { success } } = await putCartLoyaltyProgramRemoveCard()

			if (success) {
				await onSetPromotionCode(toString(promotionCode))

				reset()
			}
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		} finally {
			onRemoveCard()
		}
	}, [promotionCode, itemQuantityList, onRemoveCard, onSetPromotionCode])

	const renderContent = useCallback((): ReactElement => {
		if (!isLogged) {
			return (
				<CartPreviewSummaryLoyaltyProgramNotLogged onClick={ handleSignIn } />
			)
		}

		return (
			<CartPreviewSummaryLoyaltyProgramFormFields
				loyaltyCardNumber={ loyaltyCardNumber }
				isOpen={ isOpenPinModal }
				onOpen={ onOpenPinModal }
				onRemove={ handleRemoveCard }
			/>
		)
	}, [hasLoyaltyCard, promotionCodeUsed, loyaltyCardNumber, isLogged, isOpenPinModal, onOpenPinModal, handleRemoveCard, handleSignIn])

	return (
		<div className={ styles.wrapper }>
			{ (!hasLoyaltyCard && !isLogged) && (
				<div className={ styles.caption }>
					{ t('preview.aside.discount.domProgram.caption') }
				</div>
			) }

			<div className={ styles.content }>
				{ renderContent() }
			</div>
		</div>
	)
}

export { CartPreviewSummaryLoyaltyProgram }
