import { AxiosError, AxiosResponse } from 'axios'

import { logger, IAxiosResponseLoggerObject, IAxiosErrorLoggerObject } from '~/utils/logger'

const createAxiosResponseLoggerMessage = (response: AxiosResponse): IAxiosResponseLoggerObject => {
	const { status, statusText, data, config } = response
	const { url, baseURL, method } = config

	return {
		type: 'AXIOS_RESPONSE',
		baseURL,
		url,
		data,
		method,
		status,
		statusText,
	}
}

const createAxiosErrorLoggerMessage = (error: AxiosError): IAxiosErrorLoggerObject => {
	const { code, config, response } = error
	const { url, baseURL, method } = config

	return {
		type: 'AXIOS_ERROR',
		code,
		baseURL,
		url,
		data: response?.data,
		method,
		status: response?.status,
		statusText: response?.statusText,
	}
}

export const logAxiosResponse = (response: AxiosResponse): AxiosResponse => {
	if (response.status === 404) {
		logger.warn(createAxiosResponseLoggerMessage(response))
	}

	return response
}

export const logAxiosError = (error: AxiosError): Promise<AxiosError> => {
	logger.error(createAxiosErrorLoggerMessage(error))

	return Promise.reject(error)
}
