import React, { ReactElement, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { getCartItemQuantityList } from '~/state/reducers/cartPreviewReducer'
import { IRootState } from '~/state/types'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { Modal } from '~/components/core/modal'
import { CartPreviewSummaryLoyaltyProgramPinModalForm, ICartPreviewSummaryLoyaltyProgramPinModalFormData, ICartPreviewSummaryLoyaltyProgramPinModalProps } from '~/components/cart/cartPreview'
import { postCartLoyaltyProgramSelectReward } from '~/api/requests/cart'

import styles from './CartPreviewSummaryLoyaltyProgramPinModal.module.scss'

const CartPreviewSummaryLoyaltyProgramPinModal = (props: ICartPreviewSummaryLoyaltyProgramPinModalProps): ReactElement => {
	const { cardNumber, rewardCode, isOpen, onClose, onDismiss } = props
	const itemQuantityList = useSelector((state: IRootState) => getCartItemQuantityList(state))
	const { t, i18n } = useTranslation(['form'])
	const { preventAlert } = useAlert()
	const { sendLogError } = useLogError()

	const schema: yup.SchemaOf<ICartPreviewSummaryLoyaltyProgramPinModalFormData> = useMemo(() => yup.object().shape({
		cardPassword: yup.string().required(t('required', { ns: 'form' })),
	}), [t, i18n])

	const { control, handleSubmit, setError, reset, formState: { isSubmitting, errors } } = useForm<ICartPreviewSummaryLoyaltyProgramPinModalFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			cardPassword: '',
		},
	})

	const handleOpenModal = useCallback((): void => {
		reset()
	}, [])

	const handleCloseModal = useCallback((): void => {
		onDismiss()
		reset()
	}, [onDismiss])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartPreviewSummaryLoyaltyProgramPinModalFormData): Promise<void> => {
		const { cardPassword } = formData

		try {
			const { data: { success, message } } = await postCartLoyaltyProgramSelectReward({
				cardNumber,
				rewardCode,
				cardPassword,
			})

			if (success) {
				onClose()
			} else {
				setError('cardPassword', {
					type: 'validate',
					message,
				})
			}
		} catch (e: unknown) {
			preventAlert(e)
			sendLogError(e)
		}
	}), [cardNumber, rewardCode, onClose, itemQuantityList])

	const hasAuthorizationError = !isEmpty(errors) && errors.cardPassword?.type === 'validate'

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.wrapper }
			onOpen={ handleOpenModal }
			onClose={ handleCloseModal }
		>
			<CartPreviewSummaryLoyaltyProgramPinModalForm
				hasAuthorizationError={ hasAuthorizationError }
				control={ control }
				isSubmitting={ isSubmitting }
				onSubmit={ handleFormSubmit }
			/>
		</Modal>
	)
}

export { CartPreviewSummaryLoyaltyProgramPinModal }
