import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { CartPreviewProductsHeader, CartPreviewProductsItem, ICartPreviewProductsProps } from '~/components/cart/cartPreview'
import { ICartItemData } from '~/api/dataTypes/cart'
import { useSkipElements } from '~/hooks/skipElements'
import { SkipContentList } from '~/components/core/skipContent'

import styles from './CartPreviewProducts.module.scss'

const CartPreviewProducts = (props: ICartPreviewProductsProps): ReactElement => {
	const { cart } = props
	const { cartPreviewProductsItemSkip } = useSkipElements()

	const { cartItemList, productsQuantityMap } = cart

	const renderProductTable = useCallback((): ReactElement[] => (
		map(cartItemList, (item: ICartItemData) => {
			const { itemId, productSimple: { lmReference } } = item

			return (
				<CartPreviewProductsItem
					key={ itemId }
					item={ item }
					quantity={ productsQuantityMap[lmReference] }
				/>
			)
		})
	), [cartItemList, productsQuantityMap])

	return (
		<div className={ styles.wrapper }>
			<SkipContentList skipElements={ cartPreviewProductsItemSkip } theme={ { wrapper: styles.skipContentWrapper } } />

			<CartPreviewProductsHeader />

			{ renderProductTable() }
		</div>
	)
}

export { CartPreviewProducts }
