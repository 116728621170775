import { ICartConfirmationState } from '~/state/reducers/cartConfirmationReducer'

export const cartConfirmationInitialState: ICartConfirmationState = {
	data: {
		orderFrontId: '',
		orderDate: 0,
		orderNumber: null,
		orderStatus: '',
		orderStatusType: 'NEW',
		customer: {
			number: '',
			guestAccount: false,
			email: '',
		},
		deliveryOrderDetailsData: null,
		pickupOrderDetailsData: null,
		serviceOrderDetailsData: null,
		paymentDetailsData: {
			orderTransactionId: '',
			paymentConfirmationDate: null,
			paidAmount: null,
			paymentStatus: null,
			orderPaymentWay: null,
			orderPaymentWayName: null,
			paymentDeadline: null,
			canChangeOrderPaymentWay: false,
			canPayForOrder: false,
		},
		deliveryWayDetailsData: {
			deliveryWayName: null,
		},
		invoiceRequested: true,
		invoiceDetailsData: null,
		orderLines: [],
		orderCostSummaryData: {
			totalCostGrossValue: 0,
			transportCostValue: null,
			totalCostNetValue: 0,
			paymentValue: 0,
			productsValue: 0,
			paymentGrossValue: 0,
			taxValue: 0,
		},
		canCancelOrder: false,
		orderStatusUrl: '',
	},
	orderFrontId: '',
	isPending: false,
	isFailure: false,
}
