import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { FieldPath, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios, { AxiosError } from 'axios'
import { useSetState } from 'react-use'
import { every, isEmpty, isUndefined, map, split, toString } from 'lodash'

import { getStringWithNoSpaces } from '~/utils/string'
import { validation } from '~/utils/validation'
import { useFormError } from '~/hooks/formError'
import { useRecaptchaError } from '~/hooks/recaptchaError'
import { useLogError } from '~/hooks/logError'
import { UserContext } from '~/providers/userProvider'
import { Alert } from '~/components/core/notifications'
import { Button } from '~/components/core/button'
import { Modal } from '~/components/core/modal'
import { RecaptchaLabel } from '~/components/core/recaptchaLabel'
import { Input, RequiredInfo, Textarea } from '~/components/core/form'
import { CartShareDisclaimer, ICartShareAlertState, ICartShareModalFormData, ICartShareModalProps } from '~/components/cart/cartShare'
import { useGoogleRecaptcha } from '~/hooks/googleRecaptcha'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { postCartShare, postShare } from '~/api/requests/share'
import { getCartUuid } from '~/utils/cart'

import styles from './CartShareModal.module.scss'

const CartShareModal = (props: ICartShareModalProps): ReactElement => {
	const { isOpen, onClose } = props
	const [state, setState] = useSetState<ICartShareAlertState>({ isInvalid: false, info: '' })
	const { isLogged } = useContext(UserContext)
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const { t, i18n } = useTranslation(['form', 'cart'])
	const { executeRecaptcha } = useGoogleRecaptcha()
	const { isDisabled, onRecaptchaError } = useRecaptchaError()
	const { sendLogError } = useLogError()

	const isValidEmail = (value: string): boolean => validation.regex.email.test(getStringWithNoSpaces(value))

	const schema: yup.SchemaOf<ICartShareModalFormData> = useMemo(() => yup.object().shape({
		recipientEmails: yup.string().required(t('required', { ns: 'form' })).test('isValidEmail', t('validation.emailsFormat', { ns: 'form' }), (value: string | undefined = ''): boolean => every(value.split(','), isValidEmail)),
		senderEmail: yup.string().email(t('validation.emailFormat', { ns: 'form' })).required(t('required', { ns: 'form' })),
		message: yup.string(),
		signature: yup.string().required(t('required', { ns: 'form' })),
	}), [t, i18n])

	const { control, handleSubmit, setError, formState: { isSubmitting }, reset } = useForm<ICartShareModalFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			recipientEmails: '',
			senderEmail: '',
			message: '',
			signature: '',
		},
	})

	const handleCloseAlert = useCallback((): void => {
		setState({
			isInvalid: false,
			info: '',
		})
	}, [])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartShareModalFormData) => {
		const { recipientEmails } = formData

		try {
			if (isLogged) {
				const reCaptchaToken = await executeRecaptcha('shareCartLoggedUser')

				await postCartShare({
					...formData,
					recipientEmails: split(getStringWithNoSpaces(recipientEmails), ','),
				}, reCaptchaToken)
			} else {
				const reCaptchaToken = await executeRecaptcha('shareCartNotLoggedUser')

				await postShare({
					...formData,
					recipientEmails: split(getStringWithNoSpaces(recipientEmails), ','),
				},
				toString(getCartUuid()),
				reCaptchaToken)
			}

			setState({ isInvalid: false, info: t('modal.form.success', { ns: 'cart' }) })
			reset()
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					setError(field as FieldPath<ICartShareModalFormData>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				setState({ isInvalid: true, info: getErrorMessage(code) })

				onRecaptchaError(code)
			} else {
				setState({ isInvalid: true, info: getErrorMessage('UNKNOWN_EXCEPTION') })
			}

			sendLogError(e)
		}
	}), [isLogged, executeRecaptcha])

	const { isInvalid, info } = state

	return (
		<Modal
			isOpen={ isOpen }
			title={ t('modal.title', { ns: 'cart' }) }
			additionalClass={ styles.modal }
			onClose={ onClose }
		>
			{ !isEmpty(info) && (
				<Alert
					canClose
					icon={ isInvalid ? 'errorIcon' : 'checkCircle' }
					type={ isInvalid ? 'danger' : 'success' }
					onClose={ handleCloseAlert }
				>
					{ info }
				</Alert>
			) }

			<form className={ styles.wrapper } onSubmit={ handleFormSubmit }>
				<Input
					isRequired
					control={ control }
					name="recipientEmails"
					label={ t('modal.form.toEmailsLabel', { ns: 'cart' }) }
					placeholder={ t('modal.form.toEmailsPlaceholder', { ns: 'cart' }) }
				/>

				<Textarea
					control={ control }
					name="message"
					label={ t('modal.form.messageLabel', { ns: 'cart' }) }
					placeholder={ t('modal.form.messagePlaceholder', { ns: 'cart' }) }
				/>

				<Input
					isRequired
					control={ control }
					name="senderEmail"
					label={ t('modal.form.fromEmailLabel', { ns: 'cart' }) }
					type="email"
					placeholder={ t('modal.form.fromEmailPlaceholder', { ns: 'cart' }) }
				/>

				<Input
					isRequired
					control={ control }
					name="signature"
					label={ t('modal.form.signatureLabel', { ns: 'cart' }) }
					placeholder={ t('modal.form.signaturePlaceholder', { ns: 'cart' }) }
				/>

				<RequiredInfo />

				<CartShareDisclaimer />

				<Button
					isLoading={ isSubmitting }
					isDisabled={ isDisabled }
					type="submit"
					variant="secondary"
					text={ t('send', { ns: 'common' }) }
					additionalClass={ styles.submit }
				/>

				<RecaptchaLabel theme={ { wrapper: styles.recaptcha } } />
			</form>
		</Modal>
	)
}

export { CartShareModal }
