import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import axios, { AxiosError } from 'axios'
import { useToggle } from 'react-use'
import classNames from 'classnames'
import { forEach, gt, isUndefined, replace, size } from 'lodash'

import { Input, IRadioOption, RadioGroup, RequiredInfo, Select } from '~/components/core/form'
import { Button } from '~/components/core/button'
import { guestNipFields, CartCustomerGuestFormEmail, CartCustomerGuestFormAgreements } from '~/components/cart/cartCustomer'
import { validation } from '~/utils/validation'
import { ICartCustomerGuestCompanyForm } from '~/hooks/cartGuestCustomerForm'
import { useCountries } from '~/hooks/countries'
import { useFormError } from '~/hooks/formError'
import { useAlert } from '~/hooks/alert'
import { useLogError } from '~/hooks/logError'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { ICartCustomerTitle } from '~/api/dataTypes/cart'
import { getGusCustomerData } from '~/api/requests/account'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerGuestFormCompany.module.scss'

const CartCustomerGuestFormCompany = (): ReactElement => {
	const { t, i18n } = useTranslation(['cart', 'common', 'form', 'registration'])
	const [isLoading, setIsLoading] = useToggle(false)
	const { newAlert } = useAlert()
	const { getErrorMessage } = useFormError()
	const { countriesSelectOptions } = useCountries()
	const { control, watch, setError, resetField, setValue } = useFormContext<ICartCustomerGuestCompanyForm>()
	const { sendLogError } = useLogError()
	const { personalDataProcessingChecks: { orderChecks } } = useContext(AppParametersContext)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { mask, regex } = validation
	const { nip } = watch()

	const phonePlaceholder = replace(mask.phone, /0/g, '_')

	const nipButtonClass = classNames(styles.nipButton, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const handleChangePhone = useCallback((value: string): void => {
		let inputValue = value
		const maskSize = size(mask.phone)

		if (gt(size(value), maskSize)) {
			inputValue = replace(value, regex.phoneWithoutAreaCode, '')
		}

		setValue('phoneNumber', replace(inputValue, regex.onlyDigits, '').substring(0, maskSize))
	}, [])

	const handleClick = useCallback(async (): Promise<void> => {
		if (isUndefined(nip)) {
			return
		}

		setIsLoading(true)

		try {
			const { data } = await getGusCustomerData({ nip })

			forEach(guestNipFields, (field: keyof ICartCustomerGuestCompanyForm) => setValue(field, data[field] || ''))
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				if (code === 'INVALID_NIP_NUMBER') {
					setError('nip', { message: getErrorMessage(code) })
					forEach(guestNipFields, (field: keyof ICartCustomerGuestCompanyForm) => resetField(field))
				} else {
					newAlert('danger', getErrorMessage(code), 5000)
				}
			}

			sendLogError(e)
		} finally {
			setIsLoading(false)
		}
	}, [nip])

	const renderNipButton = useCallback((): ReactElement => (
		<Button
			isReverse
			variant="secondary"
			isLoading={ isLoading }
			text={ t('customer.guest.form.nipGUSLabel') }
			additionalClass={ nipButtonClass }
			onClick={ handleClick }
		/>
	), [isLoading, handleClick])

	const customerTypeOptions: IRadioOption<ICartCustomerTitle>[] = useMemo(() => [{
		label: t('customer.guest.form.mrs'),
		value: 'MS',
	}, {
		label: t('customer.guest.form.mr'),
		value: 'MR',
	}], [t, i18n])

	return (
		<div className={ styles.wrapper }>
			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.nipLabel') }
				additionalLabel={ t('customer.guest.form.nipAdditionalLabel') }
				mask={ mask.nip }
				aside={ renderNipButton() }
				name="nip"
				theme={ {
					labelWrapper: styles.nipLabel,
					additionalLabel: styles.nipAdditionalLabel,
				} }
			/>

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.corporateNameLabel') }
				name="corporateName"
			/>

			<RadioGroup
				control={ control }
				name="customerTitle"
				options={ customerTypeOptions }
			/>

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.firstNameLabel') }
				name="firstName"
			/>

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.lastNameLabel') }
				name="lastName"
			/>

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.streetLabel') }
				name="street"
			/>

			<div className={ styles.address }>
				<Input
					isRequired
					control={ control }
					label={ t('customer.guest.form.houseNumberLabel') }
					name="houseNumber"
					additionalClass={ styles.control }
				/>

				<Input
					control={ control }
					label={ t('customer.guest.form.flatNumberLabel') }
					name="flatNumber"
					additionalClass={ styles.control }
				/>
			</div>

			<div className={ styles.postal }>
				<Input
					isRequired
					control={ control }
					label={ t('customer.guest.form.zipCodeLabel') }
					mask={ mask.zipCode }
					name="postalCode"
					additionalClass={ styles.zip }
				/>

				<Input
					isRequired
					control={ control }
					label={ t('customer.guest.form.cityLabel') }
					name="city"
					additionalClass={ styles.city }
				/>
			</div>

			<Select
				isRequired
				isDisabled
				instanceId="customer-guest-country"
				options={ countriesSelectOptions }
				name="country"
				label={ t('customer.guest.form.countryLabel') }
				placeholder={ t('customer.guest.form.countryPlaceholder') }
				control={ control }
			/>

			<CartCustomerGuestFormEmail setCompanyValue={ setValue } />

			<Input
				isRequired
				control={ control }
				label={ t('customer.guest.form.phoneNumberLabel') }
				name="phoneNumber"
				type="tel"
				prefix={ t('phoneNumberPrefix', { ns: 'common' }) }
				placeholder={ phonePlaceholder }
				onChange={ handleChangePhone }
			/>

			<CartCustomerGuestFormAgreements agreements={ orderChecks } />

			<RequiredInfo />
		</div>
	)
}

export { CartCustomerGuestFormCompany }
