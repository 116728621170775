import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { isNil, isUndefined } from 'lodash'

import { FooterSectionItemContent, IFooterSectionItemProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'

import styles from './FooterSectionItem.module.scss'

const FooterSectionItem = (props: IFooterSectionItemProps): ReactElement => {
	const { item, isParentOpen } = props

	const { icon, name, pageType, url, buttonClass } = item

	const renderContent = useCallback((): ReactElement => {
		const isAriaHidden = !isParentOpen
		const tabIndex = isParentOpen ? 0 : -1

		if (!isUndefined(buttonClass)) {
			const buttonClassName = classNames(buttonClass, styles.customLink)

			// TODO: change into <Button /> component
			return (
				<button
					type="button"
					tabIndex={ tabIndex }
					aria-label={ name }
					aria-hidden={ isAriaHidden }
					className={ buttonClassName }
				>
					{ name }
				</button>
			)
		}

		if (isNil(pageType)) {
			return (
				<Link
					isNativeLink
					href={ url }
					theme={ { wrapper: styles.link } }
					target="_blank"
					rel="noopener noreferrer"
					ariaLabel={ name }
					isAriaHidden={ isAriaHidden }
					tabIndex={ tabIndex }
				>
					<FooterSectionItemContent name={ name } icon={ icon } />
				</Link>
			)
		}

		return (
			<Link
				href={ url }
				theme={ { wrapper: styles.link } }
				ariaLabel={ name }
				isAriaHidden={ isAriaHidden }
				tabIndex={ tabIndex }
			>
				<FooterSectionItemContent name={ name } icon={ icon } />
			</Link>
		)
	}, [pageType, url, name, icon, isParentOpen, buttonClass])

	return (
		<li className={ styles.wrapper }>
			{ renderContent() }
		</li>
	)
}

export { FooterSectionItem }
