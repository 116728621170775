import React, { ReactElement, useCallback, useContext } from 'react'
import { filter } from 'lodash'

import { HamburgerMenuMegaMenuWrapper, HamburgerMenuMegaMenuMegaWorlds, HamburgerMenuMegaMenuHeader, HamburgerMenuMegaMenuItems } from '~/components/core/hamburgerMenu'
import { isMegaWorld } from '~/components/core/megamenu'
import { FlatTreeContext } from '~/providers/flatTreeProvider'
import { IProductCatalogData } from '~/api/dataTypes/catalog'

const HamburgerMenuMegaMenu = (): ReactElement => {
	const nodes = useContext(FlatTreeContext)

	const megaWorlds = filter(nodes, isMegaWorld)

	const handleGetNodeData = useCallback((id: number): IProductCatalogData => nodes[id], [nodes])

	return (
		<HamburgerMenuMegaMenuWrapper>
			<HamburgerMenuMegaMenuMegaWorlds megaWorlds={ megaWorlds } />

			<HamburgerMenuMegaMenuHeader />

			<HamburgerMenuMegaMenuItems onGetNodeData={ handleGetNodeData } />
		</HamburgerMenuMegaMenuWrapper>
	)
}

export { HamburgerMenuMegaMenu }
