import React, { ReactElement } from 'react'
import { NextSeo } from 'next-seo'
import { isNil } from 'lodash'

import { ISeoMetaTag, ISeoTagsProps, buildKeywordsMetaTag } from '~/components/core/seoTags'

const SeoTags = (props: ISeoTagsProps): ReactElement | null => {
	const { seoData } = props

	if (isNil(seoData)) {
		return null
	}

	const { title, description, canonicalUrl, keywords, robots } = seoData
	const { index, follow } = robots

	const keywordsMetaTag: ISeoMetaTag = buildKeywordsMetaTag(keywords)

	return (
		<NextSeo
			noindex={ !index }
			nofollow={ !follow }
			title={ title }
			description={ description }
			canonical={ canonicalUrl }
			additionalMetaTags={ [
				keywordsMetaTag,
			] }
		/>
	)
}

export { SeoTags }
