import { useCallback, useContext } from 'react'

import { AppParametersContext } from '~/providers/appParametersProvider'

import { IUseMozaicData } from './types'

export const useMozaic = (): IUseMozaicData => {
	const appParams = useContext(AppParametersContext)
	const isKobiIntegrationEnabled = appParams?.kobiConfig?.integrationEnabled

	const getShouldUseMozaicFlag = useCallback((isMozaic: boolean = true): boolean => {
		if (!isMozaic) {
			return false
		}

		return isKobiIntegrationEnabled
	}, [isKobiIntegrationEnabled])

	return {
		getShouldUseMozaicFlag,
	}
}
