import React, { ReactElement, useContext } from 'react'
import { isNull } from 'lodash'

import { HamburgerMenuHeader, HamburgerMenuMegaMenu, HamburgerMenuUserStore, HamburgerMenuUserAccount, HamburgerMenuMainMenu, HamburgerMenuWrapper, HamburgerMenuUserAccountButton } from '~/components/core/hamburgerMenu'
import { HamburgerMegaMenuContext } from '~/providers/hamburgerMegaMenuProvider'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'

const HamburgerMenu = (): ReactElement => {
	const { activeMegaWorld, pageType } = useContext(HamburgerMegaMenuContext)
	const { isUserMenuOpen } = useContext(HamburgerMenuContext)

	const isMegaMenuActive = !isNull(activeMegaWorld)
	const isAnyMenuWindowActive = isUserMenuOpen || isMegaMenuActive

	return (
		<HamburgerMenuWrapper>
			{ !isAnyMenuWindowActive && (
				<>
					<HamburgerMenuHeader />

					<HamburgerMenuUserStore />

					<HamburgerMenuUserAccountButton />
				</>
			) }

			{ isUserMenuOpen ? <HamburgerMenuUserAccount /> : <HamburgerMenuMegaMenu /> }

			{ !isAnyMenuWindowActive && <HamburgerMenuMainMenu pageType={ pageType } /> }
		</HamburgerMenuWrapper>
	)
}

export { HamburgerMenu }
