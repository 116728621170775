import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { useForm, useController } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isEqual, isEmpty } from 'lodash'
import { useToggle } from 'react-use'

import { CartCustomerOrderCollectionModal, CartCustomerOtherContact, CustomerOrderCollectionType, ICartCustomerOrderCollectionForm, ICartCustomerOrderCollectionProps } from '~/components/cart/cartCustomer'
import { IRadioOption, RadioGroup } from '~/components/core/form'
import { useModal } from '~/hooks/modal'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { setAnotherReceiverActive } from '~/actions/cartCustomer'

import styles from './CartCustomerOrderCollection.module.scss'

const CartCustomerOrderCollection = (props: ICartCustomerOrderCollectionProps): ReactElement => {
	const { otherContact } = props
	const { otherContact: isInitialOtherContact } = useSelector((state: IRootState) => state.cartCustomer.data, shallowEqual)
	const [wasOpen, setWasOpen] = useToggle(false)
	const { t } = useTranslation(['cart'])
	const { isOpen, handleOpen, handleClose } = useModal('CollectOrder')
	const dispatch: AppDispatch = useDispatch()

	const schema: yup.SchemaOf<ICartCustomerOrderCollectionForm> = useMemo(() => yup.object().shape({
		orderCollectionType: yup.string().oneOf(['PRIVATE', 'COMPANY']).required(),
	}), [])

	const { control, setValue } = useForm<ICartCustomerOrderCollectionForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			orderCollectionType: isInitialOtherContact ? 'ANOTHERPERSON' : 'MYSELF',
		},
	})

	const radioOptions: IRadioOption<CustomerOrderCollectionType>[] = [
		{ label: t('customer.orderCollection.collectByMyself'), value: 'MYSELF' },
		{ label: t('customer.orderCollection.collectByAnotherPerson'), value: 'ANOTHERPERSON' },
	]

	const { field: { value } } = useController({ control, name: 'orderCollectionType' })

	const { firstName, email, phone } = otherContact
	const isAnotherReceiver = isEqual(value, 'ANOTHERPERSON' as CustomerOrderCollectionType)
	const isInitialOpenDisable = isInitialOtherContact && !wasOpen
	const isDataEmpty = isEmpty(firstName) && isEmpty(email) && isEmpty(phone)

	const handleCloseModal = useCallback(() => {
		setValue('orderCollectionType', 'MYSELF')
		handleClose()
	}, [])

	const handleEdit = useCallback(() => {
		handleOpen()
	}, [otherContact])

	useEffect(() => {
		if (!isOpen && isAnotherReceiver && isDataEmpty && !isInitialOpenDisable) {
			handleOpen()
			setWasOpen(true)
		}
	}, [value, isOpen, otherContact])

	useEffect(() => {
		(async () => {
			await dispatch(setAnotherReceiverActive(isAnotherReceiver))
		})()
	}, [isAnotherReceiver])

	return (
		<section className={ styles.wrapper }>
			<h3 className={ styles.caption }>
				{ t('customer.orderCollection.caption') }
			</h3>

			<RadioGroup
				control={ control }
				name="orderCollectionType"
				options={ radioOptions }
				theme={ { wrapper: styles.radio, label: styles.radioLabel } }
			/>

			<CartCustomerOrderCollectionModal
				isOpen={ isOpen }
				defaultValues={ otherContact }
				onClose={ handleClose }
				onCancel={ handleCloseModal }
			/>

			{ isAnotherReceiver && (
				<CartCustomerOtherContact
					isDataEmpty={ isDataEmpty }
					otherContact={ otherContact }
					onEdit={ handleEdit }
				/>
			) }
		</section>
	)
}

export { CartCustomerOrderCollection }
