import React, { ReactElement } from 'react'

import { IKobiHeaderIconsProps } from '~/components/kobi/kobiHeaderIcons'

const KobiHeaderIcons = (props: IKobiHeaderIconsProps): ReactElement | null => {
	const { isKobiIntegrationEnabled } = props

	if (!isKobiIntegrationEnabled) {
		return null
	}

	return (
		<svg
			className="kl-hidden"
			aria-hidden="true"
			xmlns="http://www.w3.org/2000/svg"
			style={ { width: 0, height: 0 } }
		>
			<symbol id="Navigation_Arrow_Arrow--Right_16px" viewBox="0 0 16 16">
				<path
					d="M5.293 3.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L8.586 8 5.293 4.707a1 1 0 010-1.414z"
				/>
			</symbol>

			<symbol id="Navigation_Arrow_Arrow--Right_24px" viewBox="0 0 24 24">
				<path
					d="M7.793 4.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-7 7a1 1 0 01-1.414-1.414L14.086 12 7.793 5.707a1 1 0 010-1.414z"
				/>
			</symbol>

			<symbol id="Navigation_Arrow_Arrow--Right_32px" viewBox="0 0 32 32">
				<path
					d="M10.293 5.293a1 1 0 011.414 0l10 10a1 1 0 010 1.414l-10 10a1 1 0 01-1.414-1.414L19.586 16l-9.293-9.293a1 1 0 010-1.414z"
				/>
			</symbol>

			<symbol id="Navigation_Arrow_Arrow--Right_48px" viewBox="0 0 48 48">
				<path
					d="M15.19 7.44a1.5 1.5 0 012.12 0l15.5 15.5a1.5 1.5 0 010 2.12l-15.5 15.5a1.5 1.5 0 01-2.12-2.12L29.628 24 15.189 9.56a1.5 1.5 0 010-2.12z"
				/>
			</symbol>

			<symbol id="Navigation_Arrow_Arrow--Right_64px" viewBox="0 0 64 64">
				<path
					d="M20.19 9.44a1.5 1.5 0 012.12 0l21.5 21.5a1.5 1.5 0 010 2.12l-21.5 21.5a1.5 1.5 0 01-2.12-2.12L40.628 32l-20.44-20.44a1.5 1.5 0 010-2.12z"
				/>
			</symbol>

			<symbol id="Product_Basket_Standard_24px" viewBox="0 0 24 24">
				<path
					d="M18 16H8a1 1 0 01-1-.75L4.38 5H3a1 1 0 010-2h2.15a1 1 0 011 .75L6.71 6H20a1 1 0 01.79.38 1 1 0 01.18.86l-2 8A1 1 0 0118 16zm-9.2-2h8.42l1.5-6H7.23z"
				/>

				<circle
					cx="16.5"
					cy="20"
					r="2"
				/>

				<circle
					cx="9.5"
					cy="20"
					r="2"
				/>
			</symbol>

			<symbol id="Product_Basket_Standard_32px" viewBox="0 0 32 32">
				<path
					d="M22.41 21.3H11.57a2 2 0 01-1.93-1.48L6.15 7H4a1 1 0 010-2h2.15a2 2 0 011.93 1.48l.59 2.17h16.9a2 2 0 011.88 2.69L24.29 20a2 2 0 01-1.88 1.3zm-10.84-2h10.84l3.16-8.65H9.22zM12.75 25.18a.91.91 0 11-.91.91.91.91 0 01.91-.91m0-2a2.91 2.91 0 102.91 2.91 2.91 2.91 0 00-2.91-2.91zM21.17 25.18a.91.91 0 11-.91.91.91.91 0 01.91-.91m0-2a2.91 2.91 0 102.91 2.91 2.91 2.91 0 00-2.91-2.91z"
				/>
			</symbol>

			<symbol id="Product_Basket_Standard_48px" viewBox="0 0 48 48">
				<path
					d="M39.85 13h-27L11.6 8.47A2 2 0 009.67 7H5.55a1.5 1.5 0 000 3H8.9l5.6 20.53A2 2 0 0016.43 32h18.26a2 2 0 001.89-1.35l5.16-15A2 2 0 0039.85 13zM34 29H17.2l-3.55-13h24.8zM19 35a4.5 4.5 0 104.5 4.5A4.49 4.49 0 0019 35zm0 6a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0119 41zM32 35a4.5 4.5 0 104.5 4.5A4.49 4.49 0 0032 35zm0 6a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0132 41z"
				/>
			</symbol>

			<symbol id="Product_Basket_Standard_64px" viewBox="0 0 64 64">
				<path
					d="M55.93 18.82a2 2 0 00-1.61-.82h-38l-1.74-6.52A2 2 0 0012.62 10H6.5a1.5 1.5 0 000 3h5.35l7.6 28.52A2 2 0 0021.38 43h26.25a2 2 0 001.91-1.39l6.68-21a2 2 0 00-.29-1.79zM46.9 40H22.15l-5.06-19H53zM25 47a6 6 0 106 6 6 6 0 00-6-6zm0 9a3 3 0 113-3 3 3 0 01-3 3zM44 47a6 6 0 106 6 6 6 0 00-6-6zm0 9a3 3 0 113-3 3 3 0 01-3 3z"
				/>
			</symbol>
		</svg>
	)
}

export { KobiHeaderIcons }
