import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isEqual, isNull } from 'lodash'

import { Modal } from '~/components/core/modal'
import { ICartPreviewCheckPriceModalProps } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceModal/types'
import { Button } from '~/components/core/button'
import { CartPreviewCheckPriceItems } from '~/components/cart/cartPreview/cartPreviewCheckPrice'

import styles from './CartPreviewCheckPriceModal.module.scss'

const CartPreviewCheckPriceModal = (props: ICartPreviewCheckPriceModalProps): ReactElement | null => {
	const { isOpen, onClose, itemsData, type } = props
	const { t } = useTranslation(['cart'])

	const handleConfirm = useCallback(() => {
		onClose()
		itemsData?.onConfirm()
	}, [itemsData, onClose])

	if (isNull(itemsData)) {
		return null
	}

	const { data } = itemsData
	const { currentSelection, newSelection } = data

	const subtitle = isEqual(type, 'changeStore') ? t('preview.checkPrice.changeStore') : t('preview.checkPrice.changeDelivery')
	const actionLabel = isEqual(type, 'changeStore') ? t('preview.checkPrice.confirmChangeStore') : t('preview.checkPrice.confirmChangeDelivery')

	return (
		<Modal
			isOpen={ isOpen }
			title={ t('preview.checkPrice.title') }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
		>
			<div className={ styles.content }>
				<div>
					{ subtitle }
				</div>

				<CartPreviewCheckPriceItems
					data={ currentSelection }
				/>

				<CartPreviewCheckPriceItems
					data={ newSelection }
				/>

				<div className={ styles.actions }>
					<Button
						text={ actionLabel }
						additionalClass={ styles.button }
						onClick={ handleConfirm }
					/>

					<Button
						isReverse
						text={ t('cancel', { ns: 'common' }) }
						additionalClass={ styles.button }
						onClick={ onClose }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { CartPreviewCheckPriceModal }
