import { isNil } from 'lodash'

import { AppPromiseThunkAction } from '~/actions'
import { AddProductCardReviewActionType, AddProductCardReviewVoteActionType, CleanUpProductCardActionType, DeleteProductCardReviewVoteActionType, GetProductCardReviewsActionType, GetProductCardReviewsNextPageActionType, GetProductCardStoreAvailabilityActionType, ProductCardActionType, SetProductCardReviewsActionType } from '~/actions/productCard'
import { localStorageSet } from '~/utils/storage'
import { LS_KEY_PRODUCT_CARD_OPINION_VOTES } from '~/utils/constants'
import { IProductAllReviewsResponse, IProductAddReviewRequest } from '~/api/dataTypes/product'
import { deleteProductReviewVote, getProductReviews, getProductStoreAvailableQuantity, postProductReview, putProductReviewVote } from '~/api/requests/products'

// eslint-disable-next-line @typescript-eslint/typedef
export const getProductCardStoreAvailability = (lmReference: string): AppPromiseThunkAction<GetProductCardStoreAvailabilityActionType> => async (dispatch) => {
	return dispatch({
		type: ProductCardActionType.GET_STORE_AVAILABILITY,
		payload: getProductStoreAvailableQuantity(lmReference),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getProductCardOpinionsNextPage = (lmReference: string, page: number, size?: number): AppPromiseThunkAction<GetProductCardReviewsNextPageActionType> => async (dispatch) => {
	return dispatch({
		type: ProductCardActionType.GET_REVIEWS_NEXT_PAGE,
		payload: getProductReviews(lmReference, { page, size }),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setProductCardOpinions = (reviews: IProductAllReviewsResponse): AppPromiseThunkAction<SetProductCardReviewsActionType> => async (dispatch) => {
	return dispatch({
		type: ProductCardActionType.SET_REVIEWS,
		payload: Promise.resolve(reviews),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const addProductCardOpinion = (lmReference: string, params: IProductAddReviewRequest): AppPromiseThunkAction<AddProductCardReviewActionType> => async (dispatch) => {
	return dispatch({
		type: ProductCardActionType.ADD_REVIEW,
		payload: postProductReview(lmReference, params),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const addProductCardOpinionVote = (opinionId: number): AppPromiseThunkAction<AddProductCardReviewVoteActionType> => async (dispatch, getState) => {
	const { productCard } = getState()
	const { userUuid } = productCard.voteOpinion
	const response = await putProductReviewVote(opinionId, userUuid)

	localStorageSet(LS_KEY_PRODUCT_CARD_OPINION_VOTES, response.data)

	return dispatch({
		type: ProductCardActionType.ADD_REVIEW_VOTE,
		payload: Promise.resolve(response),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const deleteProductCardOpinionVote = (opinionId: number): AppPromiseThunkAction<DeleteProductCardReviewVoteActionType> => async (dispatch, getState) => {
	const { productCard } = getState()
	const { userUuid } = productCard.voteOpinion

	if (isNil(userUuid)) {
		return
	}

	const response = await deleteProductReviewVote(opinionId, userUuid)

	localStorageSet(LS_KEY_PRODUCT_CARD_OPINION_VOTES, response.data)

	return dispatch({
		type: ProductCardActionType.DELETE_REVIEW_VOTE,
		payload: Promise.resolve(response),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const cleanUpProductCard = (): AppPromiseThunkAction<CleanUpProductCardActionType> => async (dispatch) => {
	return dispatch({
		type: ProductCardActionType.CLEAN_UP,
		payload: Promise.resolve(),
	})
}
