import React, { ReactElement } from 'react'

import { ICartPreviewDeliveryPickupOccurrenceDateProps, CartPreviewDeliveryPickupTimeTile } from '~/components/cart/cartPreview'

import { addLeadingZeroToNumber } from './helpers'

const CartPreviewDeliveryPickupOccurrenceDate = (props: ICartPreviewDeliveryPickupOccurrenceDateProps): ReactElement => {
	const { isChecked, occurrenceDate } = props

	const { day, month, year, label } = occurrenceDate

	const formattedDay = addLeadingZeroToNumber(day)
	const formattedMonth = addLeadingZeroToNumber(month)

	return (
		<CartPreviewDeliveryPickupTimeTile isChecked={ isChecked }>
			<div>
				{ formattedDay }{ '.' }{ formattedMonth }{ '.' }{ year }
			</div>

			<div>
				{ `(${ label })` }
			</div>
		</CartPreviewDeliveryPickupTimeTile>
	)
}

export { CartPreviewDeliveryPickupOccurrenceDate }
