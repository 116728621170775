import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useToggle } from 'react-use'
import { isNull, isUndefined, isEmpty, isEqual } from 'lodash'

import { UserContext } from '~/providers/userProvider'
import { ISimpleStoreResponse } from '~/api/dataTypes/store'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { getPreferredStoresList } from '~/actions/preferredStore'
import { IPreferredStoreModalProps, PreferredStoreBlock, PreferredStoreInfo } from '~/components/core/preferredStore'
import { Modal } from '~/components/core/modal'
import { usePreferredStore } from '~/hooks/preferredStore'
import { getCartCheckPrice } from '~/api/requests/cart'
import { CartPreviewCheckPriceModal } from '~/components/cart/cartPreview/cartPreviewCheckPrice'
import { useModal } from '~/hooks/modal'
import { ICheckPriceData } from '~/api/dataTypes/cart'

import styles from './PreferredStoreModal.module.scss'

const PreferredStoreModal = (props: IPreferredStoreModalProps): ReactElement => {
	const {
		isOpen, onClose,
		canForceChange = false,
		isWithCheckPrice = false,
	} = props
	const { data: preferredStore, list } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { data: { cart: { selectedDeliveryWayId }, deliveryGroups }, activeDeliveryGroup } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const [selectedStore, setSelectedStore] = useState<ISimpleStoreResponse | undefined>(undefined)
	const [showSelection, setShowSelection] = useToggle(true)
	const { isOpen: isOpenCheckPriceModal, handleOpen, handleClose } = useModal('CartPreviewCheckPriceModal')
	const dispatch: AppDispatch = useDispatch()
	const [checkPriceData, setCheckPriceData] = useState<ICheckPriceData | null>(null)
	const { isLogged } = useContext(UserContext)
	const { setPreferredStore } = usePreferredStore()

	const { items, isPending } = list

	const showStoreInfo = !isNull(preferredStore) && !showSelection && !canForceChange

	const handleGetStores = useCallback(async (): Promise<void> => {
		await dispatch(getPreferredStoresList())
	}, [])

	const handleSelectStore = useCallback((store: ISimpleStoreResponse | undefined): void => {
		setSelectedStore(store)
	}, [])

	const handleChangeStore = useCallback((): void => {
		setShowSelection(true)
		setSelectedStore(undefined)
	}, [])

	const handleSetPreferredStore = useCallback(async (): Promise<void> => {
		setShowSelection(false)

		if (!isUndefined(selectedStore)) {
			const { storeCode, name } = selectedStore

			if (isWithCheckPrice && !isNull(deliveryGroups)) {
				const deliveryWayId = isNull(selectedDeliveryWayId) && isEqual(activeDeliveryGroup, 'PICKUP') ? deliveryGroups.pickup.deliveryList[0].id : selectedDeliveryWayId

				const { data } = await getCartCheckPrice({
					storeCode: selectedStore.storeCode,
					deliveryWayId,
				})

				if (!isEmpty(data.newSelection.itemList)) {
					setCheckPriceData({
						data,
						onConfirm: () => setPreferredStore(storeCode, name, isLogged),
					})

					setSelectedStore(undefined)
					onClose()
					handleOpen()

					return
				}
			}

			await setPreferredStore(storeCode, name, isLogged)
		}

		onClose()
	}, [selectedStore, isLogged, selectedDeliveryWayId, deliveryGroups, isWithCheckPrice, activeDeliveryGroup])

	useEffect(() => {
		if (isNull(preferredStore)) {
			setSelectedStore(undefined)
		}
	}, [preferredStore])

	useEffect(() => {
		if (isOpen && !isNull(preferredStore)) {
			setShowSelection(false)
		}
	}, [isOpen])

	return (
		<>
			<Modal
				isOpen={ isOpen }
				additionalClass={ styles.modal }
				onOpen={ handleGetStores }
				onClose={ onClose }
			>
				<div className={ styles.content }>
					<div className={ styles.scroll }>
						{ showStoreInfo ? (
							<PreferredStoreInfo
								store={ preferredStore }
								additionalActionsClass={ styles.actions }
								onChange={ handleChangeStore }
								onNavigate={ onClose }
							/>
						) : (
							<PreferredStoreBlock
								isPending={ isPending }
								items={ items }
								selectedStore={ selectedStore }
								onSelectStore={ handleSelectStore }
								onClose={ handleSetPreferredStore }
							/>
						) }
					</div>
				</div>
			</Modal>

			<CartPreviewCheckPriceModal
				isOpen={ isOpenCheckPriceModal }
				itemsData={ checkPriceData }
				type="changeStore"
				onClose={ handleClose }
			/>
		</>
	)
}

export { PreferredStoreModal }
