import React, { useCallback, ReactElement } from 'react'
import { map } from 'lodash'

import { IFooterButtonsProps } from '~/components/core/layout'
import { Icon } from '~/components/core/icon'
import { Link } from '~/components/core/link'
import { IFooterNavigationButton } from '~/hooks/footerNavigation'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './FooterButtons.module.scss'

const FooterButtons = (props: IFooterButtonsProps): ReactElement => {
	const { buttons } = props

	const renderButtons = useCallback((): ReactElement[] => (
		map(buttons, (button: IFooterNavigationButton): ReactElement => {
			const { label, url, id } = button

			return (
				<Link
					key={ id }
					href={ url }
					theme={ {
						wrapper: styles.button,
						link: styles.linkWrapper,
					} }
					dataTestId={ DATA_TESTID.FOOTER.BUTTON_ITEM(label) }
					ariaLabel={ label }
				>
					<span className={ styles.label }>
						{ label }
					</span>

					<Icon
						name="arrowFull"
						color="green"
						width={ 4 }
						height={ 8 }
						additionalClass={ styles.icon }
					/>
				</Link>
			)
		})
	), [buttons])

	return (
		<div className={ styles.wrapper }>
			{ renderButtons() }
		</div>
	)
}

export { FooterButtons }
