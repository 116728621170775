import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { ICartCustomerContactEditButtonProps } from '~/components/cart/cartCustomer'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerContactEditButton.module.scss'

const CartCustomerContactEditButton = (props: ICartCustomerContactEditButtonProps): ReactElement => {
	const { onClick, ariaLabelledBy } = props
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ wrapperClass }
			ariaLabel={ ` - ${ t('edit') }` }
			ariaLabelledBy={ ariaLabelledBy }
			onClick={ onClick }
		>
			<Icon
				name="edit"
				additionalClass={ styles.icon }
				width={ 15 }
			/>

			<span>
				{ t('edit') }
			</span>
		</Button>
	)
}

export { CartCustomerContactEditButton }
