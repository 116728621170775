import React, { ReactElement } from 'react'

import { CartPreviewDeliverySplitQuantity, CartPreviewProductsProductBlockImage, CartPreviewProductsProductBlockInfo, CartPreviewDeliverySplitTotalPrice, ICartPreviewDeliverySplitProductBlockProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliverySplitProductBlock.module.scss'

const CartPreviewDeliverySplitProductBlock = (props: ICartPreviewDeliverySplitProductBlockProps): ReactElement => {
	const { item } = props

	const { productSimple, value, labels, quantityInfo: { possibleQuantity }, saleUnitName } = item
	const { price: { basePriceUnit } } = productSimple

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.container }>
				<CartPreviewProductsProductBlockImage product={ productSimple } />

				<CartPreviewProductsProductBlockInfo
					labels={ labels }
					product={ productSimple }
					priceUnit={ basePriceUnit }
				/>
			</div>

			<div className={ styles.mobileContainer }>
				<CartPreviewDeliverySplitQuantity quantity={ possibleQuantity } unit={ saleUnitName } />

				<CartPreviewDeliverySplitTotalPrice summaryPrice={ value } />
			</div>
		</div>
	)
}

export { CartPreviewDeliverySplitProductBlock }
