import React, { ReactElement } from 'react'
import { isNil } from 'lodash'

import { IHamburgerMenuItemContentProps } from '~/components/core/hamburgerMenu'
import { Icon } from '~/components/core/icon'

import styles from './HamburgerMenuItemContent.module.scss'

const HamburgerMenuItemContent = (props: IHamburgerMenuItemContentProps): ReactElement => {
	const { name, icon } = props

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.name }>
				{ !isNil(icon) && (
					<Icon
						name={ icon }
						color="navy"
						width={ 16 }
						additionalClass={ styles.icon }
					/>
				) }

				<span>{ name }</span>
			</span>

			<Icon name="arrowFull" width={ 4 } />
		</div>
	)
}

export { HamburgerMenuItemContent }
