import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Link } from '~/components/core/link'
import { Icon } from '~/components/core/icon'
import { useMozaic } from '~/hooks/mozaic'

import styles from './BreadcrumbHome.module.scss'

const BreadcrumbHome = (): ReactElement | null => {
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
		'mc-breadcrumb__item': shouldUseMozaic,
	})

	return (
		<Link
			isNativeLink
			href="/"
			itemProp="item"
			additionalClass={ wrapperClass }
			ariaLabel={ `${ t('breadcrumbs') } - ${ t('homepage') }` }
		>
			{ !shouldUseMozaic && (
				<Icon
					name="home"
					width={ 13 }
					additionalClass={ styles.icon }
				/>
			) }

			<span
				className={ styles.label }
				itemProp="name"
				id="homepage-breadcrumb-label"
			>
				{ t('homepage') }
			</span>

			<meta itemProp="position" content="1" />
		</Link>
	)
}

export { BreadcrumbHome }
