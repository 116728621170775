import React, { ReactElement, useCallback, useRef, KeyboardEvent } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isNil } from 'lodash'

import { ButtonBoxWrapper } from '~/components/core/buttonBoxWrapper'
import { focusFirstElementAfterSkipAnchor } from '~/components/core/skipContent'
import { Icon } from '~/components/core/icon'
import { getCartPreviewDeliveryGroupAriaLabel, ICartPreviewDeliveryGroupProps } from '~/components/cart/cartPreview'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDeliveryGroup.module.scss'

const CartPreviewDeliveryGroup = (props: ICartPreviewDeliveryGroupProps): ReactElement => {
	const { data, isSelected, dataTestId, onSelectGroup } = props
	const buttonRef = useRef<HTMLButtonElement | null>(null)
	const { isSmallDesktop } = useMediaQuery()
	const { t } = useTranslation(['form'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { getName, getDescription, active: isActive, icon: { name: iconName, width, smallWidth, height, smallHeight }, deliveryType } = data
	const iconWidth = isSmallDesktop ? width : smallWidth
	const iconHeight = isSmallDesktop ? height : smallHeight

	const handleSelect = useCallback(async (): Promise<void> => {
		onSelectGroup(deliveryType)
	}, [onSelectGroup])

	const handleKeyDownEnter = useCallback((event: KeyboardEvent<HTMLButtonElement>): void => {
		event.stopPropagation()

		if (event.key === 'Enter' && !isNil(buttonRef.current)) {
			if (!isSelected) {
				handleSelect()
			}

			focusFirstElementAfterSkipAnchor(buttonRef.current.parentElement as HTMLButtonElement)
		}
	}, [isSelected, buttonRef.current])

	const className = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })
	const iconColor = shouldUseMozaic ? (isSelected ? 'mozaicGrey800' : 'mozaicGrey600') : 'black65'

	return (
		<ButtonBoxWrapper
			buttonRef={ buttonRef }
			isSelected={ isSelected }
			isDisabled={ !isActive }
			additionalClass={ className }
			ariaLabel={ getCartPreviewDeliveryGroupAriaLabel(getName(), getDescription(), isSelected, t) }
			dataTestId={ dataTestId }
			onClick={ handleSelect }
			onKeyDown={ handleKeyDownEnter }
		>
			<div className={ styles.group }>
				<div className={ styles.iconWrapper }>
					<Icon
						name={ iconName }
						color={ iconColor }
						width={ iconWidth }
						height={ iconHeight }
					/>
				</div>

				<div className={ styles.data }>
					<div className={ styles.name }>
						{ getName() }
					</div>

					<div className={ styles.description }>
						{ getDescription() }
					</div>
				</div>
			</div>
		</ButtonBoxWrapper>
	)
}

export { CartPreviewDeliveryGroup }
