import React, { ReactElement, useCallback } from 'react'
import { useToggle } from 'react-use'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { getCartPreviewItemsAvailable, getCartPreviewItemsNotAvailable } from '~/state/reducers/cartPreviewReducer'
import { CartPreviewSplitOrderNotEnough, CartPreviewSplitOrder, CartPreviewNotAvailableOrder, ICartPreviewSummaryNextModalProps } from '~/components/cart/cartPreview'

const CartPreviewSummaryNextModal = (props: ICartPreviewSummaryNextModalProps): ReactElement | null => {
	const { isOpen, onNext, onSetIsNextLoading, onClose } = props
	const itemsAvailable = useSelector(getCartPreviewItemsAvailable)
	const itemsNotAvailable = useSelector(getCartPreviewItemsNotAvailable)
	const [isLoading, setIsLoading] = useToggle(false)

	const handleClose = useCallback(async (): Promise<void> => {
		await onSetIsNextLoading(false)
		await onClose()
	}, [onSetIsNextLoading, onClose])

	const handleNext = useCallback(async (): Promise<void> => {
		await setIsLoading(true)
		await onNext(true)
		await setIsLoading(false)
		await onClose()
	}, [onNext, onClose])

	const renderContent = useCallback((): ReactElement | null => {
		if (!isEmpty(itemsAvailable) && !isEmpty(itemsNotAvailable)) {
			return (
				<CartPreviewSplitOrder
					itemsAvailable={ itemsAvailable }
					itemsNotAvailable={ itemsNotAvailable }
					isOpen={ isOpen }
					isLoading={ isLoading }
					onNext={ handleNext }
					onClose={ handleClose }
				/>
			)
		}

		if (!isEmpty(itemsNotAvailable)) {
			return (
				<CartPreviewNotAvailableOrder
					isOpen={ isOpen }
					isLoading={ isLoading }
					onClose={ handleClose }
				/>
			)
		}

		if (!isEmpty(itemsAvailable)) {
			return (
				<CartPreviewSplitOrderNotEnough
					itemsAvailable={ itemsAvailable }
					itemsNotAvailable={ itemsNotAvailable }
					isOpen={ isOpen }
					isLoading={ isLoading }
					onNext={ handleNext }
					onClose={ handleClose }
				/>
			)
		}

		return null
	}, [itemsAvailable, itemsNotAvailable, isOpen, isLoading])

	return renderContent()
}

export { CartPreviewSummaryNextModal }
