import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { ServiceCartPreviewActionType, ServiceCartPreviewType } from '~/actions/serviceCartPreview'

import { IServiceCartPreviewState } from './types'
import { serviceCartPreviewInitialState as initialState } from './constants'

export const serviceCartPreview = produce((draft: Draft<IServiceCartPreviewState>, action: AppPromiseAction<ServiceCartPreviewActionType>) => {
	switch (action.type) {
		case REQUEST(ServiceCartPreviewType.GET_SERVICE_CART_PREVIEW):
			draft.isPending = true

			return draft
		case REQUEST(ServiceCartPreviewType.GET_SERVICE_CART_STORES):
			draft.isStoresPending = true

			return draft
		case REQUEST(ServiceCartPreviewType.GET_SERVICE_CART_SCHEDULE):
			draft.isSchedulePending = true

			return draft
		case REQUEST(ServiceCartPreviewType.GET_SERVICE_CART_DESIGNER):
			draft.isDesignerPending = true

			return draft
		case FAILURE(ServiceCartPreviewType.GET_SERVICE_CART_PREVIEW):
			draft.isPending = false

			return draft
		case FAILURE(ServiceCartPreviewType.GET_SERVICE_CART_STORES):
			draft.isStoresPending = false

			return draft
		case FAILURE(ServiceCartPreviewType.GET_SERVICE_CART_SCHEDULE):
			draft.isSchedulePending = false

			return draft
		case FAILURE(ServiceCartPreviewType.GET_SERVICE_CART_DESIGNER):
			draft.isDesignerPending = false

			return draft
		case SUCCESS(ServiceCartPreviewType.GET_SERVICE_CART_PREVIEW):
			draft.data = action.payload.data
			draft.isPending = false

			return draft
		case SUCCESS(ServiceCartPreviewType.GET_SERVICE_CART_STORES):
			draft.storesData = action.payload.data.storeList
			draft.isStoresPending = false

			return draft
		case SUCCESS(ServiceCartPreviewType.GET_SERVICE_CART_SCHEDULE):
			draft.scheduleData = action.payload.data.calendarDayDataList
			draft.isSchedulePending = false

			return draft
		case SUCCESS(ServiceCartPreviewType.GET_SERVICE_CART_DESIGNER):
			draft.designerData = action.payload.data
			draft.isDesignerPending = false

			return draft
		case SUCCESS(ServiceCartPreviewType.SET_SERVICE_CART_ONLINE_APPOINTMENT):
			draft.data.onlineAppointment = action.payload

			return draft
		case SUCCESS(ServiceCartPreviewType.SET_SERVICE_CART_STORE):
			draft.data.designer = initialState.data.designer
			draft.data.store = action.payload

			return draft
		case SUCCESS(ServiceCartPreviewType.SET_SERVICE_CART_DESIGNER):
			draft.data.designer = action.payload

			return draft
		case SUCCESS(ServiceCartPreviewType.GET_SERVICE_CART_CONFIGURATION):
			draft.configuration = action.payload.data

			return draft
		case SUCCESS(ServiceCartPreviewType.SET_SERVICE_CART_SLOT):
			draft.data.slot = action.payload

			return draft
		case SUCCESS(ServiceCartPreviewType.CLEAR_SERVICE_CART_SLOT):
			draft.data.slot = initialState.data.slot
			draft.designerData = initialState.designerData

			return draft
		case SUCCESS(ServiceCartPreviewType.SET_SERVICE_CART_VISIBLE_DATES):
			draft.calendarVisibleDates = action.payload

			return draft
	}
}, initialState)
