import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEqual, isNull } from 'lodash'

import { CartCustomerInvoiceCorporateBox, CartCustomerInvoicePrivateBox, ICartCustomerInvoiceBoxProps } from '~/components/cart/cartCustomer'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { CustomerType } from '~/api/dataTypes/customer'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerInvoiceBox.module.scss'

const CartCustomerInvoiceBox = (props: ICartCustomerInvoiceBoxProps): ReactElement | null => {
	const { invoice, onEdit } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { customerType } = invoice

	const renderProperBox = useCallback((): ReactElement | null => {
		if (isEqual(customerType, 'PRIVATE' as CustomerType)) {
			return (
				<CartCustomerInvoicePrivateBox invoice={ invoice } />
			)
		}

		if (isEqual(customerType, 'COMPANY' as CustomerType)) {
			return (
				<CartCustomerInvoiceCorporateBox invoice={ invoice } />
			)
		}

		return null
	}, [invoice])

	if (isNull(customerType)) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass } id="cart-customer-invoice-box">
			{ renderProperBox() }

			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.editButton }
				ariaLabel={ ` - ${ t('customer.invoice.edit') }` }
				ariaLabelledBy="cart-customer-invoice-box"
				onClick={ onEdit }
			>
				{ t('customer.invoice.edit') }
			</Button>

			<div className={ styles.check }>
				<Icon
					name="checkmark"
					width={ 15 }
					height={ 11 }
					color="white"
				/>
			</div>
		</div>
	)
}

export { CartCustomerInvoiceBox }
