import React, { ReactElement } from 'react'

import { Icon } from '~/components/core/icon'

import styles from './BreadcrumbFirst.module.scss'

const BreadcrumbFirst = (): ReactElement => {
	return (
		<Icon
			name="home"
			width={ 13 }
			additionalClass={ styles.icon }
		/>
	)
}

export { BreadcrumbFirst }
