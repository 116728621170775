import React, { ReactElement } from 'react'
import { isEqual } from 'lodash'

import { useMediaQuery } from '~/hooks/mediaQuery'
import { CartPreviewDeliverySplitQuantity, CartPreviewDeliverySplitTotalPrice, ICartPreviewDeliverySplitProductsItemProps, CartPreviewDeliverySplitProductBlock, CartPreviewDeliverySplitPrice, CartPreviewDeliverySplitType } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliverySplitProductsItem.module.scss'

const CartPreviewDeliverySplitProductsItem = (props: ICartPreviewDeliverySplitProductsItemProps): ReactElement => {
	const { item, type } = props
	const { isDesktop } = useMediaQuery()

	const { productSimple, value, saleUnitName, labels, quantityInfo: { possibleQuantity, selectedQuantity } } = item
	const { price } = productSimple
	const quantity = isEqual(type, 'availableProducts' as CartPreviewDeliverySplitType) ? possibleQuantity : selectedQuantity

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.data }>
				<CartPreviewDeliverySplitProductBlock item={ item } />

				{ isDesktop && (
					<>
						<CartPreviewDeliverySplitPrice labels={ labels } price={ price } />

						<CartPreviewDeliverySplitQuantity quantity={ quantity } unit={ saleUnitName } />

						<CartPreviewDeliverySplitTotalPrice summaryPrice={ value } />
					</>
				) }
			</div>
		</div>
	)
}

export { CartPreviewDeliverySplitProductsItem }
