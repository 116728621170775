import React, { ReactElement } from 'react'

import { IAdditionalFooterWrapper } from '~/components/core/layout/footer'
import { Container } from '~/components/core/layout/container'
import { SkipContentAnchor, SkipContentList } from '~/components/core/skipContent'
import { SKIP_ADDITIONAL_FOOTER, useSkipElements } from '~/hooks/skipElements'

import styles from './AdditionalFooterWrapper.module.scss'

const AdditionalFooterWrapper = (props: IAdditionalFooterWrapper): ReactElement => {
	const { children } = props
	const { additionalFooterWrapperSkip } = useSkipElements()

	return (
		<div className={ styles.wrapper }>
			<Container>
				<SkipContentAnchor elementId={ SKIP_ADDITIONAL_FOOTER } />

				<SkipContentList skipElements={ additionalFooterWrapperSkip } />

				{ children }
			</Container>
		</div>
	)
}

export { AdditionalFooterWrapper }
