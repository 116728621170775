import { find, isEmpty, isEqual, isNil, isNull, isUndefined, toString } from 'lodash'
import * as yup from 'yup'

import { validation } from '~/utils/validation'
import { ICartCustomerGuestCompanyForm, ICartCustomerGuestPrivateForm } from '~/hooks/cartGuestCustomerForm'
import { CustomerDataType } from '~/state/reducers/customerReducer'
import { ICartCustomerContact, ICartCustomerPrivateInvoice, ICartCustomerCompanyInvoice } from '~/api/dataTypes/cart'
import { ICustomerAddressData, ICustomerData, ICustomerResult, CustomerType } from '~/api/dataTypes/customer'

import { IGetCustomerLoggedNextTooltipInfo } from './types'

export const getCustomerLoggedNextTooltipInfo = (data: IGetCustomerLoggedNextTooltipInfo): string => {
	const { t, customerAddressId, disabledInfoForAddressData, disabledInfoForCustomerData, isNextButtonDisabledByPhone, isNextButtonDisabledByPhonePrefix, isNextButtonDisabledByInvoice } = data
	if (isNull(customerAddressId)) {
		return (t('customer.aside.summary.button.disabledInfo.clientData'))
	}

	if (!isEmpty(disabledInfoForAddressData)) {
		return (t(disabledInfoForAddressData))
	}

	if (!isEmpty(disabledInfoForCustomerData)) {
		return (t(disabledInfoForCustomerData))
	}

	if (isNextButtonDisabledByPhone) {
		return t('customer.aside.summary.button.disabledPhoneInfo')
	}

	if (isNextButtonDisabledByPhonePrefix) {
		return t('customer.aside.summary.button.disabledPhonePrefixInfo')
	}

	if (isNextButtonDisabledByInvoice) {
		return t('customer.aside.summary.button.disabledInvoiceInfo')
	}

	return ''
}

export const getCustomerContactData = (userData: CustomerDataType, selectedAddressId: number | null): ICartCustomerContact | null => {
	if (isNull(userData) || isNull(selectedAddressId)) {
		return null
	}

	const { addresses, customer, login } = userData
	const address = find(addresses, (address: ICustomerAddressData) => address.addressId === selectedAddressId)

	if (isUndefined(address)) {
		return null
	}

	return getCustomerContact(customer, address, login)
}

const getCustomerContact = (customer: ICustomerData, address: ICustomerAddressData, email: string): ICartCustomerContact => ({
	firstName: address.firstName,
	lastName: address.lastName,
	companyName: address.corporateName,
	phone: customer.phone,
	email,
})

export const getCustomerInvoiceData = (userData: CustomerDataType): ICartCustomerPrivateInvoice | ICartCustomerCompanyInvoice | null => {
	if (isNull(userData)) {
		return null
	}

	const { customerType, customer: { mainAddress } } = userData

	if (isNil(mainAddress)) {
		return null
	}

	if (customerType === 'COMPANY') {
		return getCompanyCustomerInvoiceData(userData, mainAddress)
	}

	return getPrivateCustomerInvoiceData(userData, mainAddress)
}

const getCompanyCustomerInvoiceData = (userData: ICustomerResult, address: ICustomerAddressData): ICartCustomerCompanyInvoice | null => {
	if (isNull(userData)) {
		return null
	}

	const { customer: { corporateName, nip, firstName, lastName } } = userData
	const { city, postalCode, street, houseNumber, flatNumber } = address

	return ({
		companyName: corporateName || '',
		nip: nip || '',
		firstName,
		lastName,
		address: {
			city,
			zipCode: postalCode,
			street,
			houseNumber,
			flatNumber: flatNumber || '',
			addressType: 'INVOICE',
		},
		customerType: 'COMPANY',
	})
}

const getPrivateCustomerInvoiceData = (userData: ICustomerResult, address: ICustomerAddressData): ICartCustomerPrivateInvoice | null => {
	if (isNull(userData)) {
		return null
	}

	const { customer: { firstName, lastName } } = userData
	const { city, postalCode, street, houseNumber, flatNumber } = address

	return ({
		firstName,
		lastName,
		address: {
			city,
			zipCode: postalCode,
			street,
			houseNumber,
			flatNumber: flatNumber || '',
			addressType: 'INVOICE',
		},
		customerType: 'PRIVATE',
	})
}

export const getGuestCustomerInvoiceData = (formData: ICartCustomerGuestPrivateForm | ICartCustomerGuestCompanyForm, customerType?: CustomerType): ICartCustomerPrivateInvoice | ICartCustomerCompanyInvoice | null => {
	if (isNull(customerType)) {
		return null
	}

	if (isEqual(customerType, 'PRIVATE')) {
		return getGuestCustomerInvoicePrivateData(formData as ICartCustomerGuestPrivateForm)
	}

	return getGuestCustomerInvoiceCompanyData(formData as ICartCustomerGuestCompanyForm)
}

const getGuestCustomerInvoicePrivateData = (formData: ICartCustomerGuestPrivateForm): ICartCustomerPrivateInvoice => {
	const { firstName, lastName, city, postalCode, street, houseNumber, flatNumber } = formData

	return ({
		firstName,
		lastName,
		address: {
			city,
			zipCode: postalCode,
			street,
			houseNumber,
			flatNumber: flatNumber || '',
			addressType: 'INVOICE',
		},
		customerType: 'PRIVATE',
	})
}

const getGuestCustomerInvoiceCompanyData = (formData: ICartCustomerGuestCompanyForm): ICartCustomerCompanyInvoice => {
	const { corporateName, nip, city, postalCode, street, houseNumber, flatNumber, firstName, lastName } = formData

	return ({
		companyName: corporateName || '',
		nip: nip || '',
		firstName,
		lastName,
		address: {
			city,
			zipCode: postalCode,
			street,
			houseNumber,
			flatNumber: flatNumber || '',
			addressType: 'INVOICE',
		},
		customerType: 'COMPANY',
	})
}

export const getDisabledInfoForAddressData = (userData: CustomerDataType, selectedAddressId: number | null): string => {
	if (isNull(userData) || isNull(selectedAddressId)) {
		return 'customer.aside.summary.button.disabledInfo.clientData'
	}

	const address = find(userData.addresses, (address: ICustomerAddressData) => address.addressId === selectedAddressId)

	if (isUndefined(address)) {
		return 'customer.aside.summary.button.disabledInfo.clientData'
	}

	if (!validation.regex.polishZipCode.test(toString(address.postalCode))) {
		return 'customer.aside.summary.button.disabledZipCodeInfo'
	}

	if (isEmpty(address.street) || isNil(address.street)) {
		return 'customer.aside.summary.button.disabledStreetInfo'
	}

	if (isEmpty(address.houseNumber) || isNil(address.houseNumber)) {
		return 'customer.aside.summary.button.disabledHouseNumberInfo'
	}

	if (isEmpty(address.city) || isNil(address.city)) {
		return 'customer.aside.summary.button.disabledCityInfo'
	}

	return ''
}

export const getDisabledInfoForCustomerData = (userData: CustomerDataType): string => {
	if (isNull(userData)) {
		return 'customer.aside.summary.button.disabledInfo.clientData'
	}

	const schema = yup.object().shape({
		corporateName: yup.string().nullable().min(3, 'customer.aside.summary.button.disabledCorporateNameToShort').max(100, 'customer.aside.summary.button.disabledCorporateNameToLong'),
		firstName: yup.string().min(3, 'customer.aside.summary.button.disabledFirstNameToShort').max(50, 'customer.aside.summary.button.disabledFirstNameToLong'),
		lastName: yup.string().min(3, 'customer.aside.summary.button.disabledLastNameToShort').max(50, 'customer.aside.summary.button.disabledLastNameToLong'),
	})

	try {
		schema.validate(userData.customer)
	} catch (error: unknown) {
		const e = error as yup.ValidationError

		return e.errors[0]
	}

	return ''
}
