import React, { ReactElement, useCallback, useContext } from 'react'
import classNames from 'classnames'
import { isNil } from 'lodash'

import { SoldOutButton } from '~/components/core/soldOutButton'
import { AddToCartButton } from '~/components/core/addToCartButton'
import { AvailabilityInStoreButton } from '~/components/core/availabilityInStoreButton'
import { IProductBlockButtonProps } from '~/components/core/productBlock'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './ProductBlockButton.module.scss'

const ProductBlockButton = (props: IProductBlockButtonProps): ReactElement | null => {
	const {
		product,
		theme = {},
		isReverse = false,
		quantity = 1,
	} = props
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const { availability, price: { newPrice, basePrice }, wwwUrl } = product

	const buttonClass = classNames(styles.button, theme.button, {
		'mc-button--bordered': isKobiIntegrationEnabled,
	})
	const checkAvailabilityButtonClass = classNames(buttonClass, theme.checkAvailabilityButton)

	const renderContent = useCallback((): ReactElement => {
		const { availabilityType } = availability
		const priceValue = (newPrice ? newPrice : basePrice) || 0

		switch (availabilityType) {
			case 'CHECK_AVAILABILITY':
				return (
					<AvailabilityInStoreButton additionalClass={ checkAvailabilityButtonClass } wwwUrl={ wwwUrl } />
				)
			case 'CAN_ADD_TO_CART':
				return (
					<AddToCartButton
						productSimple={ product }
						isReverse={ isReverse }
						priceValue={ priceValue }
						additionalClass={ buttonClass }
						quantity={ quantity }
					/>
				)
			case 'SOLD_OUT':
			default:
				return (
					<SoldOutButton additionalClass={ buttonClass } />
				)
		}
	}, [product, quantity, isReverse, theme])

	// FIXME: availability shouldn't be undefined
	if (isNil(availability)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { ProductBlockButton }
