import React, { ReactElement, useCallback, useRef } from 'react'

import { IMegaMenuWorldsSubWorldProps } from '~/components/core/megamenu'
import { Link } from '~/components/core/link'

import styles from './MegaMenuWorldsSubWorld.module.scss'

const MegaMenuWorldsSubWorld = (props: IMegaMenuWorldsSubWorldProps): ReactElement => {
	const { data, ariaLabel, tabIndex, onBlur, onClose } = props
	const linkRef = useRef<HTMLAnchorElement | null>(null)

	const { name, wwwUrl } = data

	const handleCloseMegaMenu = useCallback((): void => {
		onClose()
	}, [onClose])

	return (
		<li className={ styles.wrapper }>
			<Link
				linkRef={ linkRef }
				href={ wwwUrl }
				theme={ {
					wrapper: styles.linkWrapper,
					link: styles.link,
				} }
				ariaLabel={ `${ ariaLabel } ${ name }` }
				tabIndex={ tabIndex }
				onClick={ handleCloseMegaMenu }
				onBlur={ onBlur }
			>
				{ name }
			</Link>
		</li>
	)
}

export { MegaMenuWorldsSubWorld }
