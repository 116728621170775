import React, { ReactElement } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'

import { ITooltipProps, TOOLTIP_PARAMS, TooltipContent, TooltipIcon } from '~/components/core/tooltip'

const Tooltip = (props: ITooltipProps): ReactElement => {
	const {
		content = '',
		placement = 'auto',
		CustomTriggerComponent = null,
		CustomContentComponent = null,
		width = 17,
		height = 17,
		theme = {},
		isActive = true,
		ariaLabel = '',
	} = props

	const { setTooltipRef, setTriggerRef, visible, getTooltipProps, getArrowProps } = usePopperTooltip({ ...TOOLTIP_PARAMS, placement })

	return (
		<>
			<TooltipIcon
				CustomTriggerComponent={ CustomTriggerComponent }
				width={ width }
				height={ height }
				ariaLabel={ ariaLabel }
				theme={ {
					wrapper: theme.wrapper,
					icon: theme.icon,
				} }
				onSetRef={ setTriggerRef }
			/>

			{ isActive && (
				<TooltipContent
					content={ content }
					visible={ visible }
					CustomContentComponent={ CustomContentComponent }
					theme={ {
						wrapper: theme.wrapper,
					} }
					onSetRef={ setTooltipRef }
					onGetTooltipProps={ getTooltipProps }
					onGetArrowProps={ getArrowProps }
				/>
			) }
		</>
	)
}

export { Tooltip }
