import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty, lte } from 'lodash'

import { HeaderTopBarSearchBarHistory, IHeaderTopBarSearchBarNoResultsProps } from '~/components/core/layout'

import styles from './HeaderTopBarSearchBarNoResults.module.scss'

const HeaderTopBarSearchBarNoResults = (props: IHeaderTopBarSearchBarNoResultsProps): ReactElement => {
	const { query, isModalOpen, uniqSearchHistory, handleForceRerenderResults, handleHideSuggestions } = props
	const { t } = useTranslation(['header'])

	const shouldDisplayHistory = !isEmpty(uniqSearchHistory) && lte(query.length, 3)

	const renderContent = useCallback((): ReactElement | null => {
		if (shouldDisplayHistory) {
			return (
				<HeaderTopBarSearchBarHistory
					history={ uniqSearchHistory }
					theme={ {
						header: styles.historyHeader,
						clearButton: styles.historyClearButton,
					} }
					onForceRerenderResults={ handleForceRerenderResults }
					onHideSuggestions={ handleHideSuggestions }
				/>
			)
		}

		if (isEmpty(query)) {
			return null
		}

		return (
			<>
				<span className={ styles.noResults }>
					<span>{ t('search.noResults') }</span>

					<span className={ styles.query }>&quot;{ query }&quot;</span>
				</span>

				<div className={ styles.findByCategory }>
					<span>{ t('search.findInterestingProducts') }</span>

					<span className={ styles.category }>{ t('search.products') }</span>
				</div>
			</>
		)
	}, [uniqSearchHistory, query, shouldDisplayHistory, isModalOpen, handleForceRerenderResults, handleHideSuggestions])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.modal]: isModalOpen,
		[styles.history]: shouldDisplayHistory,
		[styles.hidden]: (isEmpty(query) && !shouldDisplayHistory),
	})

	return (
		<div className={ wrapperClass }>
			{ renderContent() }
		</div>
	)
}

export { HeaderTopBarSearchBarNoResults }
