import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { stringify } from 'query-string'

import { IUseQueryParamsData } from '~/hooks/queryParams'

export const useQueryParams = (): IUseQueryParamsData => {
	const router = useRouter()

	const removeQueryParam = useCallback((param: string): void => {
		const { pathname, query } = router
		const params = new URLSearchParams(stringify(query))

		params.delete(param)

		router.replace({ pathname, query: params.toString() }, undefined, { shallow: true })
	}, [])

	return {
		removeQueryParam,
	}
}
