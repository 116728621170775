import React, { ReactElement } from 'react'
import Script from 'next/script'
import { isEqual } from 'lodash'

import { vars } from '~/statics'

const OneTrustCookieConsentScript = (): ReactElement | null => {
	const { isProductionEnvironment, isKobiIntegrationEnabled } = vars

	if (isEqual(isKobiIntegrationEnabled, 'true')) {
		return null
	}

	return (
		<>
			<Script
				id="oneTrust-script"
				strategy="beforeInteractive"
				src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
				data-domain-script={ `c3573ec5-4641-4058-8dbe-b77be387bb60${ !isProductionEnvironment ? '-test' : '' }` }
			/>

			<Script id="oneTrust-inline-script" strategy="beforeInteractive">
				{ `
					function OptanonWrapper() { }
				` }
			</Script>
		</>
	)
}

export { OneTrustCookieConsentScript }
