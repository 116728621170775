import React, { ReactElement } from 'react'

import { FooterLinks, FooterApps, FooterBanners, FooterSectionsWrapper, FooterAppsAndBannersWrapper, IFooterSectionsProps } from '~/components/core/layout'
import { PreferredStore } from '~/components/core/preferredStore'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './FooterSections.module.scss'

const FooterSections = (props: IFooterSectionsProps): ReactElement => {
	const { sections, apps, banners } = props

	return (
		<FooterSectionsWrapper>
			<FooterLinks sections={ sections } />

			<FooterAppsAndBannersWrapper>
				<FooterApps apps={ apps } />

				<FooterBanners banners={ banners } />
			</FooterAppsAndBannersWrapper>

			<PreferredStore
				color="white"
				additionalClass={ styles.preferredStoreWrapper }
				dataTestId={ DATA_TESTID.FOOTER.BUTTON_OPEN_PREFERRED_STORE }
			/>
		</FooterSectionsWrapper>
	)
}

export { FooterSections }
