import React, { ReactElement, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isNull } from 'lodash'

import { ICartPreviewPreferredStoreBlockProps } from '~/components/cart/cartPreview'
import { Button } from '~/components/core/button'
import { Icon, ICON_TYPE } from '~/components/core/icon'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { vars } from '~/statics'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './CartPreviewPreferredStoreBlock.module.scss'

const CartPreviewPreferredStoreBlock = (props: ICartPreviewPreferredStoreBlockProps): ReactElement => {
	const {
		onClick, preferredStore,
		isMozaic = false,
	} = props
	const { isSmallDesktop } = useMediaQuery()
	const { t } = useTranslation(['cart'])
	const [isMounted, setIsMounted] = useState<boolean>(false)

	const isPreferredStoreChosen = !isNull(preferredStore) && isMounted
	const icon: keyof typeof ICON_TYPE = isMozaic ? 'mozaicStoreLocation' : isPreferredStoreChosen ? 'pinFull' : 'pin'
	const buttonIcon: keyof typeof ICON_TYPE = isPreferredStoreChosen ? 'changeFull' : 'pin'
	const description = isPreferredStoreChosen ? t('preview.preferredStore.chosenStoreInfo') : t('preview.preferredStore.noChosenStoreDescription')
	const buttonText = isPreferredStoreChosen ? t('preview.preferredStore.changeStore') : t('preview.preferredStore.choseStore')

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: isMozaic })
	const descriptionClass = classNames(styles.description, { [styles.isMozaic]: isMozaic })

	const iconColor: keyof typeof vars.colors = isMozaic ? 'black' : isPreferredStoreChosen ? 'navy' : 'grayMedium'
	const iconWidth = isMozaic ? 48 : 25

	const renderTitle = useCallback((): ReactElement => {
		if (isPreferredStoreChosen) {
			return (
				<div className={ styles.title }>
					<span>
						{ t('preview.preferredStore.chosenStore') }
					</span>

					<span className={ styles.store }>
						{ ' ' }{ preferredStore.name }
					</span>
				</div>
			)
		}

		return (
			<span className={ classNames(styles.title, styles.bold) }>
				{ t('preview.preferredStore.noChosenStore') }
			</span>
		)
	}, [isPreferredStoreChosen, preferredStore])

	const renderButton = useCallback((): ReactElement => (
		<Button
			size="inherit"
			variant="neutral"
			additionalClass={ styles.button }
			ariaLabel={ buttonText }
			dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_OPEN_PREFERRED_STORE_MODAL }
			onClick={ onClick }
		>
			<Icon
				name={ buttonIcon }
				width={ 14 }
				color="green"
				additionalClass={ styles.icon }
			/>

			<span className={ styles.text }>
				{ buttonText }
			</span>
		</Button>
	), [buttonIcon, buttonText, onClick, preferredStore])

	useEffect(() => {
		setIsMounted(true)

		return () => setIsMounted(false)
	}, [])

	return (
		<div className={ wrapperClass }>
			<div className={ styles.contentWrapper }>
				<div className={ styles.iconWrapper }>
					<Icon
						name={ icon }
						width={ iconWidth }
						color={ iconColor }
						additionalClass={ styles.icon }
					/>
				</div>

				<div className={ styles.content }>
					<div className={ styles.header }>
						{ renderTitle() }

						{ !isSmallDesktop && renderButton() }
					</div>

					<div className={ descriptionClass }>
						{ description }
					</div>
				</div>
			</div>

			{ isSmallDesktop && renderButton() }
		</div>
	)
}

export { CartPreviewPreferredStoreBlock }
