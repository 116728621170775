import React, { ReactElement, useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import axios, { AxiosError } from 'axios'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isUndefined, map } from 'lodash'

import { CartCustomerInvoiceModalActions, CartCustomerInvoiceModalCompanySimplifiedForm, CartCustomerInvoiceModalCompanyFormFields, ICartCustomerInvoiceModalCompanyForm, ICartCustomerInvoiceModalCompanyFormProps } from '~/components/cart/cartCustomer'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { validateCartCustomerCompanyInvoice } from '~/actions/cartCustomer'
import { hasNotNumberAndAllowOnlyDash, hasOnlyLetters, hasOnlyLettersNumbersOrDash, hasOnlyLettersSpacesOrDash, hasOnlyLettersNumbersSpacesDotsOrDash, isValidNip } from '~/utils/string'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { ICartCustomerCompanyInvoice } from '~/api/dataTypes/cart'

const CartCustomerInvoiceModalCompanyForm = (props: ICartCustomerInvoiceModalCompanyFormProps): ReactElement => {
	const { onClose, onCancel, invoice } = props
	const { companySimpleInvoiceAvailable } = useSelector((state: IRootState) => state.cartCustomer.data, shallowEqual)
	const { t, i18n } = useTranslation(['cart'])
	const { newAlert } = useAlert()
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const dispatch: AppDispatch = useDispatch()
	const { sendLogError } = useLogError()

	const { contact, address } = invoice
	const { firstName, lastName, companyName, nip } = contact || {}
	const { street, houseNumber, flatNumber, zipCode, city } = address || {}

	const companyNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(100, t('mustBeShortestThen100', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoiceCompanyForm.companyNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const firstNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLetters', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoiceCompanyForm.firstNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLetters(value)), [t, i18n])
	const lastNameSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasNotNumberAndAllowOnlyDash', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoiceCompanyForm.lastNameLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasNotNumberAndAllowOnlyDash(value)), [t, i18n])
	const houseNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).test('hasOnlyLettersOrNumbers', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoiceCompanyForm.buildingNumberLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const flatNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).test('hasOnlyLettersOrNumbers', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoiceCompanyForm.flatNumberLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersOrDash(value)), [t, i18n])
	const citySchema = useMemo(() => yup.string().max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLettersOrSpaces', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoiceCompanyForm.cityLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersSpacesOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const streetSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(42, t('mustBeShortestThen42', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.invoice.invoiceCompanyForm.streetLabel', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('required', { ns: 'form' })), [t, i18n])
	const nipSchema = useMemo(() => yup.string().test('isValidNip', t('validation.nipFormat', { ns: 'form' }), (value: string | undefined) => isValidNip(value)).required(t('required', { ns: 'form' })), [t, i18n])

	const schema: yup.SchemaOf<ICartCustomerInvoiceModalCompanyForm> = useMemo(() => yup.object().shape({
		nip: nipSchema,
		corporateName: companyNameSchema,
		firstName: firstNameSchema,
		lastName: lastNameSchema,
		street: streetSchema,
		houseNumber: houseNumberSchema,
		flatNumber: flatNumberSchema,
		postalCode: yup.string().required(t('required', { ns: 'form' })),
		city: citySchema,
	}), [t, i18n])

	const formProps = useForm<ICartCustomerInvoiceModalCompanyForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			nip: nip || '',
			corporateName:  companyName || '',
			firstName: firstName || '',
			lastName: lastName || '',
			street: street || '',
			houseNumber: houseNumber || '',
			flatNumber: flatNumber || '',
			postalCode: zipCode || '',
			city: city || '',
		},
		mode: 'onChange',
	})

	const { handleSubmit } = formProps

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerInvoiceModalCompanyForm) => {
		const { nip, corporateName, firstName, lastName, ...rest } = formData

		try {
			const params: ICartCustomerCompanyInvoice = {
				nip,
				companyName: corporateName,
				firstName,
				lastName,
				address: {
					...rest,
					addressType: 'INVOICE',
					zipCode: formData.postalCode,
				},
				customerType: 'COMPANY',
			}

			await dispatch(validateCartCustomerCompanyInvoice(params))

			onClose()
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					formProps.setError(field as FieldPath<ICartCustomerInvoiceModalCompanyForm>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				newAlert('danger', getErrorMessage(code), 5000)

				sendLogError(error)
			}
		}
	}), [])

	const renderForm = useCallback((): ReactElement => {
		if (companySimpleInvoiceAvailable) {
			return (
				<CartCustomerInvoiceModalCompanySimplifiedForm onClose={ onClose } onCancel={ onCancel } />
			)
		}

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<FormProvider { ...formProps }>
				<CartCustomerInvoiceModalCompanyFormFields />

				<CartCustomerInvoiceModalActions onCancel={ onCancel } onSubmit={ handleFormSubmit } />
			</FormProvider>
		)
	}, [])

	return (
		renderForm()
	)
}

export { CartCustomerInvoiceModalCompanyForm }
