import { isEmpty } from 'lodash'

import { ICustomerNewAddressRequest, CustomerDataRequestUnionType } from '~/api/dataTypes/customer'
import { ICartCustomerDataAddressForm, ICartCustomerUpdatedDataHelperParams } from '~/components/cart/cartCustomer'

export const getCartCustomerAddressNewData = (formData: ICartCustomerDataAddressForm): ICustomerNewAddressRequest => ({
	...formData,
	types: ['DELIVERY'],
	flatNumber: isEmpty(formData.flatNumber) ? null : formData.flatNumber,
})

export const getCartCustomerUpdatedData = (params: ICartCustomerUpdatedDataHelperParams): Partial<CustomerDataRequestUnionType> => {
	const { customerType, formData, nip, corporateName } = params

	if (customerType === 'COMPANY') {

		return {
			...formData,
			customerType,
			nip: nip || '',
			corporateName: corporateName || '',
		}
	}

	return {
		...formData,
		customerType,
	}
}
