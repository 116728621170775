import React, { useCallback, ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { map } from 'lodash'

import { Image } from '~/components/core/image'
import { IFooterNavigationApp } from '~/hooks/footerNavigation'
import { IFooterAppsProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'

import styles from './FooterApps.module.scss'

const FooterApps = (props: IFooterAppsProps): ReactElement => {
	const { apps } = props
	const { t } = useTranslation(['footer'])

	const renderApps = useCallback((): ReactElement[] => (
		map(apps, (app: IFooterNavigationApp): ReactElement => {
			const { id, imageUrl, url, name } = app

			return (
				<Link
					key={ id }
					href={ url }
					additionalClass={ styles.link }
					target="_blank"
					rel="noopener noreferrer"
					ariaLabel={ t('downloadApp') }
				>
					<Image
						fill
						src={ imageUrl }
						alt={ name }
						loading="lazy"
						className={ styles.image }
					/>
				</Link>
			)
		})
	), [apps])

	return (
		<section className={ styles.wrapper }>
			<h3 className={ styles.header }>
				{ t('downloadApp') }
			</h3>

			<div className={ styles.apps }>
				{ renderApps() }
			</div>
		</section>
	)
}

export { FooterApps }
