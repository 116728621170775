import { useCallback } from 'react'
import { isUndefined } from 'lodash'

import { vars } from '~/statics'
import { IUseGoogleRecaptchaData } from '~/hooks/googleRecaptcha'

export const useGoogleRecaptcha = (): IUseGoogleRecaptchaData => {

	const executeRecaptcha = useCallback((action: string): Promise<any> => {
		const grecaptcha = (window as any).grecaptcha

		if (isUndefined(grecaptcha)) {
			throw new Error('Google Recaptcha has not been loaded')
		}

		return grecaptcha.execute(vars.google.reCaptchaSiteKey, { action })
	}, [])

	return {
		executeRecaptcha,
	}
}
