import dynamic from 'next/dynamic'

import { IconPlaceholder } from '~/components/core/iconPlaceholder'

const Add = dynamic(() => import('~/assets/svg/add.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Arrow = dynamic(() => import('~/assets/svg/arrow.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ArrowFull = dynamic(() => import('~/assets/svg/arrowFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ArrowThin = dynamic(() => import('~/assets/svg/arrowThin.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Bathroom = dynamic(() => import('~/assets/svg/bathroom.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Book = dynamic(() => import('~/assets/svg/book.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Bricks = dynamic(() => import('~/assets/svg/bricks.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Brush = dynamic(() => import('~/assets/svg/brush.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Calendar = dynamic(() => import('~/assets/svg/calendar.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Card = dynamic(() => import('~/assets/svg/card.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Cart = dynamic(() => import('~/assets/svg/cart.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const CartArrow = dynamic(() => import('~/assets/svg/cartArrow.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Checkmark = dynamic(() => import('~/assets/svg/checkmark.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Clock = dynamic(() => import('~/assets/svg/clock.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Close = dynamic(() => import('~/assets/svg/close.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Compare = dynamic(() => import('~/assets/svg/compare.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const CompareFull = dynamic(() => import('~/assets/svg/compareFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ConcreteMixer = dynamic(() => import('~/assets/svg/concreteMixer.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Dashboard = dynamic(() => import('~/assets/svg/dashboard.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const CheckCircle = dynamic(() => import('~/assets/svg/checkCircle.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Decoration = dynamic(() => import('~/assets/svg/decoration.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Delivery = dynamic(() => import('~/assets/svg/delivery.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Doors = dynamic(() => import('~/assets/svg/doors.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Download = dynamic(() => import('~/assets/svg/download.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Envelope = dynamic(() => import('~/assets/svg/envelope.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ErrorIcon = dynamic(() => import('~/assets/svg/errorIcon.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Fabric = dynamic(() => import('~/assets/svg/fabric.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Facebook = dynamic(() => import('~/assets/svg/facebook.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const File = dynamic(() => import('~/assets/svg/file.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const FlagPolish = dynamic(() => import('~/assets/svg/flagPolish.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Frame = dynamic(() => import('~/assets/svg/frame.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GalleryPlay = dynamic(() => import('~/assets/svg/galleryPlay.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Group = dynamic(() => import('~/assets/svg/group.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Hamburger = dynamic(() => import('~/assets/svg/hamburger.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Hammer = dynamic(() => import('~/assets/svg/hammer.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const HammerHand = dynamic(() => import('~/assets/svg/hammerHand.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const HandCoins = dynamic(() => import('~/assets/svg/handCoins.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const HandReturn = dynamic(() => import('~/assets/svg/handReturn.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const HeartEmpty = dynamic(() => import('~/assets/svg/heartEmpty.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const HeartFull = dynamic(() => import('~/assets/svg/heartFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Heater = dynamic(() => import('~/assets/svg/heater.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Home = dynamic(() => import('~/assets/svg/home.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Info = dynamic(() => import('~/assets/svg/info.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const IndividualOffer = dynamic(() => import('~/assets/svg/individualOffer.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const InfoCircle = dynamic(() => import('~/assets/svg/infoCircle.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Instagram = dynamic(() => import('~/assets/svg/instagram.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const LightBulb = dynamic(() => import('~/assets/svg/lightBulb.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const LikeEmpty = dynamic(() => import('~/assets/svg/likeEmpty.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const LikeFull = dynamic(() => import('~/assets/svg/likeFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Linkedin = dynamic(() => import('~/assets/svg/linkedin.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const List = dynamic(() => import('~/assets/svg/list.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Minus = dynamic(() => import('~/assets/svg/minus.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Paint = dynamic(() => import('~/assets/svg/paint.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Paper = dynamic(() => import('~/assets/svg/paper.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Pen = dynamic(() => import('~/assets/svg/pen.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Person = dynamic(() => import('~/assets/svg/person.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PersonFull = dynamic(() => import('~/assets/svg/personFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Pin = dynamic(() => import('~/assets/svg/pin.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PinFull = dynamic(() => import('~/assets/svg/pinFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Pinterest = dynamic(() => import('~/assets/svg/pinterest.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Play = dynamic(() => import('~/assets/svg/play.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Plus = dynamic(() => import('~/assets/svg/plus.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Percent = dynamic(() => import('~/assets/svg/percent.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Project = dynamic(() => import('~/assets/svg/project.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PromoBadge = dynamic(() => import('~/assets/svg/promoBadge.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PromoLabel = dynamic(() => import('~/assets/svg/promoLabel.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Radio = dynamic(() => import('~/assets/svg/radio.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const RadioFull = dynamic(() => import('~/assets/svg/radioFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const QuickDelivery = dynamic(() => import('~/assets/svg/quickDelivery.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Refresh = dynamic(() => import('~/assets/svg/refresh.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Refund = dynamic(() => import('~/assets/svg/refund.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Rotate = dynamic(() => import('~/assets/svg/rotate.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Saw = dynamic(() => import('~/assets/svg/saw.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Search = dynamic(() => import('~/assets/svg/search.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const SewingMachine = dynamic(() => import('~/assets/svg/sewingMachine.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Share = dynamic(() => import('~/assets/svg/share.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Shovel = dynamic(() => import('~/assets/svg/shovel.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Size = dynamic(() => import('~/assets/svg/size.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Star = dynamic(() => import('~/assets/svg/star.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const StarHalf = dynamic(() => import('~/assets/svg/starHalf.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const StarEmpty = dynamic(() => import('~/assets/svg/starEmpty.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Tap = dynamic(() => import('~/assets/svg/tap.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Target = dynamic(() => import('~/assets/svg/target.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ThumbUp = dynamic(() => import('~/assets/svg/thumbUp.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TransportImmediate = dynamic(() => import('~/assets/svg/transportImmediate.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Trash = dynamic(() => import('~/assets/svg/trash.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Turn = dynamic(() => import('~/assets/svg/turn.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const VisibilityOff = dynamic(() => import('~/assets/svg/visibilityOff.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const visibilityOn = dynamic(() => import('~/assets/svg/visibilityOn.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Video = dynamic(() => import('~/assets/svg/video.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Wall = dynamic(() => import('~/assets/svg/wall.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Warning = dynamic(() => import('~/assets/svg/warning.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const WaterTap = dynamic(() => import('~/assets/svg/waterTap.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const WishList = dynamic(() => import('~/assets/svg/wishlist.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Wrench = dynamic(() => import('~/assets/svg/wrench.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Youtube = dynamic(() => import('~/assets/svg/youtube.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const CloseCircle = dynamic(() => import('~/assets/svg/closeCircle.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Edit = dynamic(() => import('~/assets/svg/edit.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const CalendarEmpty = dynamic(() => import('~/assets/svg/calendarEmpty.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Copy = dynamic(() => import('~/assets/svg/copy.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DeliveryEcomm = dynamic(() => import('~/assets/svg/deliveryEcomm.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DeliveryPickup = dynamic(() => import('~/assets/svg/deliveryPickup.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DeliveryTransport = dynamic(() => import('~/assets/svg/deliveryTransport.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ProfitPersonalData = dynamic(() => import('~/assets/svg/profitPersonalData.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ProfitOrder = dynamic(() => import('~/assets/svg/profitOrder.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ProfitReturn = dynamic(() => import('~/assets/svg/profitReturn.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ProfitHistory = dynamic(() => import('~/assets/svg/profitHistory.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ProfitCart = dynamic(() => import('~/assets/svg/profitCart.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const OnlineAppointment = dynamic(() => import('~/assets/svg/onlineAppointment.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const People = dynamic(() => import('~/assets/svg/people.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
// MONOLITH
const Service = dynamic(() => import('~/assets/svg/monolith/service.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceAdvice = dynamic(() => import('~/assets/svg/monolith/serviceAdvice.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceAdjourned = dynamic(() => import('~/assets/svg/monolith/serviceAdjourned.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceAnimalsonleash = dynamic(() => import('~/assets/svg/monolith/serviceAnimalsonleash.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceBinding = dynamic(() => import('~/assets/svg/monolith/serviceBinding.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceBathroom = dynamic(() => import('~/assets/svg/monolith/serviceBathroom.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceBlinds = dynamic(() => import('~/assets/svg/monolith/serviceBlinds.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceBordering = dynamic(() => import('~/assets/svg/monolith/serviceBordering.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceCalculate = dynamic(() => import('~/assets/svg/monolith/serviceCalculate.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceCamera = dynamic(() => import('~/assets/svg/monolith/serviceCamera.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceCamera2 = dynamic(() => import('~/assets/svg/monolith/serviceCamera2.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceCar = dynamic(() => import('~/assets/svg/monolith/serviceCar.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceCards = dynamic(() => import('~/assets/svg/monolith/serviceCards.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceCoins = dynamic(() => import('~/assets/svg/monolith/serviceCoins.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceComplaints = dynamic(() => import('~/assets/svg/monolith/serviceComplaints.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceDisabled = dynamic(() => import('~/assets/svg/monolith/serviceDisabled.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceFinance = dynamic(() => import('~/assets/svg/monolith/serviceFinance.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceFwdarrows = dynamic(() => import('~/assets/svg/monolith/serviceFwdarrows.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceHammertime = dynamic(() => import('~/assets/svg/monolith/serviceHammertime.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceIndividual = dynamic(() => import('~/assets/svg/monolith/serviceIndividual.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceInstallation = dynamic(() => import('~/assets/svg/monolith/serviceInstallation.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceKitchen = dynamic(() => import('~/assets/svg/monolith/serviceKitchen.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceKitchenbathroom = dynamic(() => import('~/assets/svg/monolith/serviceKitchenbathroom.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceLock = dynamic(() => import('~/assets/svg/monolith/serviceLock.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceMaterials = dynamic(() => import('~/assets/svg/monolith/serviceMaterials.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceMixing = dynamic(() => import('~/assets/svg/monolith/serviceMixing.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceNosmoking = dynamic(() => import('~/assets/svg/monolith/serviceNosmoking.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceOpen = dynamic(() => import('~/assets/svg/monolith/serviceOpen.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServicePhone = dynamic(() => import('~/assets/svg/monolith/servicePhone.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServicePhone2 = dynamic(() => import('~/assets/svg/monolith/servicePhone2.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServicePhoneorders = dynamic(() => import('~/assets/svg/monolith/servicePhoneorders.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServicePhoto = dynamic(() => import('~/assets/svg/monolith/servicePhoto.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServicePickup = dynamic(() => import('~/assets/svg/monolith/servicePickup.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceProgram = dynamic(() => import('~/assets/svg/monolith/serviceProgram.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServicePruning = dynamic(() => import('~/assets/svg/monolith/servicePruning.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServicePuzzle = dynamic(() => import('~/assets/svg/monolith/servicePuzzle.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceQuestions = dynamic(() => import('~/assets/svg/monolith/serviceQuestions.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceReturn = dynamic(() => import('~/assets/svg/monolith/serviceReturn.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceSewing = dynamic(() => import('~/assets/svg/monolith/serviceSewing.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceShopping = dynamic(() => import('~/assets/svg/monolith/serviceShopping.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTaxfree = dynamic(() => import('~/assets/svg/monolith/serviceTaxfree.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTimecar = dynamic(() => import('~/assets/svg/monolith/serviceTimecar.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTimeenvelope = dynamic(() => import('~/assets/svg/monolith/serviceTimeenvelope.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTimeform = dynamic(() => import('~/assets/svg/monolith/serviceTimeform.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceToilet = dynamic(() => import('~/assets/svg/monolith/serviceToilet.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTransport = dynamic(() => import('~/assets/svg/monolith/serviceTransport.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTransportbringing = dynamic(() => import('~/assets/svg/monolith/serviceTransportbringing.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTransporthds = dynamic(() => import('~/assets/svg/monolith/serviceTransporthds.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceTrolley = dynamic(() => import('~/assets/svg/monolith/serviceTrolley.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceWifi = dynamic(() => import('~/assets/svg/monolith/serviceWifi.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ServiceWrenchscrew = dynamic(() => import('~/assets/svg/monolith/serviceWrenchscrew.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildBrickWall = dynamic(() => import('~/assets/svg/monolith/buildBrickWall.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildConstruction = dynamic(() => import('~/assets/svg/monolith/buildConstruction.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildFence = dynamic(() => import('~/assets/svg/monolith/buildFence.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildHeating = dynamic(() => import('~/assets/svg/monolith/buildHeating.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildIsolation = dynamic(() => import('~/assets/svg/monolith/buildIsolation.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildPlumbing = dynamic(() => import('~/assets/svg/monolith/buildPlumbing.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildRoofing = dynamic(() => import('~/assets/svg/monolith/buildRoofing.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildSiding = dynamic(() => import('~/assets/svg/monolith/buildSiding.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildTools = dynamic(() => import('~/assets/svg/monolith/buildTools.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const BuildWindowDoor = dynamic(() => import('~/assets/svg/monolith/buildWindowDoor.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoCarpet = dynamic(() => import('~/assets/svg/monolith/decoCarpet.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoCurtains = dynamic(() => import('~/assets/svg/monolith/decoCurtains.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoLamp = dynamic(() => import('~/assets/svg/monolith/decoLamp.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoMirrorFrame = dynamic(() => import('~/assets/svg/monolith/decoMirrorFrame.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoPaintBrush = dynamic(() => import('~/assets/svg/monolith/decoPaintBrush.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoPaintBucket = dynamic(() => import('~/assets/svg/monolith/decoPaintBucket.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoVase = dynamic(() => import('~/assets/svg/monolith/decoVase.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoWallpaper = dynamic(() => import('~/assets/svg/monolith/decoWallpaper.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const DecoWorkspace = dynamic(() => import('~/assets/svg/monolith/decoWorkspace.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenBalcony = dynamic(() => import('~/assets/svg/monolith/gardenBalcony.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenBench = dynamic(() => import('~/assets/svg/monolith/gardenBench.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenGazebo = dynamic(() => import('~/assets/svg/monolith/gardenGazebo.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenHoose = dynamic(() => import('~/assets/svg/monolith/gardenHoose.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenLamp = dynamic(() => import('~/assets/svg/monolith/gardenLamp.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenMower = dynamic(() => import('~/assets/svg/monolith/gardenMower.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenPlant = dynamic(() => import('~/assets/svg/monolith/gardenPlant.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenRake = dynamic(() => import('~/assets/svg/monolith/gardenRake.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenSeeds = dynamic(() => import('~/assets/svg/monolith/gardenSeeds.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const GardenWheelbarrow = dynamic(() => import('~/assets/svg/monolith/gardenWheelbarrow.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanBathroomEquipment = dynamic(() => import('~/assets/svg/monolith/planBathroomEquipment.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanDrawers = dynamic(() => import('~/assets/svg/monolith/planDrawers.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanFaucet = dynamic(() => import('~/assets/svg/monolith/planFaucet.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanFireplace = dynamic(() => import('~/assets/svg/monolith/planFireplace.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanFloorHeating = dynamic(() => import('~/assets/svg/monolith/planFloorHeating.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanKitchen = dynamic(() => import('~/assets/svg/monolith/planKitchen.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanKitchenEquipment = dynamic(() => import('~/assets/svg/monolith/planKitchenEquipment.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanShower = dynamic(() => import('~/assets/svg/monolith/planShower.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanSinkCabinet = dynamic(() => import('~/assets/svg/monolith/planSinkCabinet.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanSoapdispensor = dynamic(() => import('~/assets/svg/monolith/planSoapdispensor.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanStairs = dynamic(() => import('~/assets/svg/monolith/planStairs.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanTiles = dynamic(() => import('~/assets/svg/monolith/planTiles.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanToilet = dynamic(() => import('~/assets/svg/monolith/planToilet.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanWallsiding = dynamic(() => import('~/assets/svg/monolith/planWallsiding.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanWardrobe = dynamic(() => import('~/assets/svg/monolith/planWardrobe.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const PlanWorkspace = dynamic(() => import('~/assets/svg/monolith/planWorkspace.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicBoiler = dynamic(() => import('~/assets/svg/monolith/technicBoiler.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicDrill = dynamic(() => import('~/assets/svg/monolith/technicDrill.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicDuctape = dynamic(() => import('~/assets/svg/monolith/technicDuctape.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicHammer = dynamic(() => import('~/assets/svg/monolith/technicHammer.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicHeater = dynamic(() => import('~/assets/svg/monolith/technicHeater.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicHeating = dynamic(() => import('~/assets/svg/monolith/technicHeating.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicHomeSecurity = dynamic(() => import('~/assets/svg/monolith/technicHomeSecurity.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicPaintGlue = dynamic(() => import('~/assets/svg/monolith/technicPaintGlue.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicPowerCable = dynamic(() => import('~/assets/svg/monolith/technicPowerCable.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicScreww = dynamic(() => import('~/assets/svg/monolith/technicScreww.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicVent = dynamic(() => import('~/assets/svg/monolith/technicVent.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const TechnicWorkshop = dynamic(() => import('~/assets/svg/monolith/technicWorkshop.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const ChangeFull = dynamic(() => import('~/assets/svg/changeFull.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MenuDots = dynamic(() => import('~/assets/svg/menuDots.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Logout = dynamic(() => import('~/assets/svg/logout.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const Author = dynamic(() => import('~/assets/svg/author.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
// MOZAIC
const MozaicStoreLocation = dynamic(() => import('@mozaic-ds/icons/svg/Store_Monochrome_StoreLocation_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicInformation = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Notification_Information_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicShareAndroid = dynamic(() => import('@mozaic-ds/icons/svg/Social_Share_ShareAndroid_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicArrowTop = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Arrow_Arrow--Top_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicArrowBottom = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Arrow_Arrow--Bottom_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicArrowRight16 = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Arrow_Arrow--Right_16px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicArrowRight = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Arrow_Arrow--Right_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicControlCross = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Control_Cross_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicControlCircleMore = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Control_Circle--More_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicPublishEdit = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Publish_Edit_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicTrashbin = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Publish_Trashbin_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicDownloadWeb = dynamic(() => import('@mozaic-ds/icons/svg/Media_Download_Web_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicNotificationCircleAvailable = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Notification_Circle--Available_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })
const MozaicNotificationCircleInformation = dynamic(() => import('@mozaic-ds/icons/svg/Navigation_Notification_Information_48px.svg').then((module: any) => module.default), { ssr: false, loading: () => <IconPlaceholder /> })

export const ICON_TYPE = {
	add: Add,
	arrow: Arrow,
	arrowFull: ArrowFull,
	arrowThin: ArrowThin,
	bathroom: Bathroom,
	book: Book,
	bricks: Bricks,
	brush: Brush,
	calendar: Calendar,
	card: Card,
	cart: Cart,
	cartArrow: CartArrow,
	checkmark: Checkmark,
	clock: Clock,
	close: Close,
	compare: Compare,
	compareFull: CompareFull,
	concreteMixer: ConcreteMixer,
	copy: Copy,
	dashboard: Dashboard,
	checkCircle: CheckCircle,
	decoration: Decoration,
	delivery: Delivery,
	doors: Doors,
	download: Download,
	envelope: Envelope,
	errorIcon: ErrorIcon,
	fabric: Fabric,
	facebook: Facebook,
	file: File,
	flagPolish: FlagPolish,
	frame: Frame,
	galleryPlay: GalleryPlay,
	group: Group,
	hamburger: Hamburger,
	hammer: Hammer,
	hammerHand: HammerHand,
	handCoins: HandCoins,
	handReturn: HandReturn,
	heartEmpty: HeartEmpty,
	heartFull: HeartFull,
	heater: Heater,
	home: Home,
	info: Info,
	individualOffer: IndividualOffer,
	infoCircle: InfoCircle,
	instagram: Instagram,
	lightBulb: LightBulb,
	likeEmpty: LikeEmpty,
	likeFull: LikeFull,
	linkedin: Linkedin,
	list: List,
	minus: Minus,
	paint: Paint,
	paper: Paper,
	pen: Pen,
	person: Person,
	personFull: PersonFull,
	pin: Pin,
	pinFull: PinFull,
	pinterest: Pinterest,
	play: Play,
	plus: Plus,
	percent: Percent,
	project: Project,
	promoBadge: PromoBadge,
	promoLabel: PromoLabel,
	quickDelivery: QuickDelivery,
	radio: Radio,
	radioFull: RadioFull,
	refresh: Refresh,
	refund: Refund,
	rotate: Rotate,
	saw: Saw,
	search: Search,
	sewingMachine: SewingMachine,
	share: Share,
	shovel: Shovel,
	size: Size,
	star: Star,
	starHalf: StarHalf,
	starEmpty: StarEmpty,
	tap: Tap,
	target: Target,
	thumbUp: ThumbUp,
	transportImmediate: TransportImmediate,
	trash: Trash,
	turn: Turn,
	visibilityOff: VisibilityOff,
	visibilityOn: visibilityOn,
	video: Video,
	wall: Wall,
	warning: Warning,
	waterTap: WaterTap,
	wishlist: WishList,
	wrench: Wrench,
	youtube: Youtube,
	closeCircle: CloseCircle,
	edit: Edit,
	calendarEmpty: CalendarEmpty,
	service: Service,
	serviceAdvice: ServiceAdvice,
	serviceAdjourned: ServiceAdjourned,
	serviceAnimalsonleash: ServiceAnimalsonleash,
	serviceBathroom: ServiceBathroom,
	serviceBinding: ServiceBinding,
	serviceBlinds: ServiceBlinds,
	serviceBordering: ServiceBordering,
	serviceCalculate: ServiceCalculate,
	serviceCamera: ServiceCamera,
	serviceCamera2: ServiceCamera2,
	serviceCar: ServiceCar,
	serviceCards: ServiceCards,
	serviceCoins: ServiceCoins,
	serviceComplaints: ServiceComplaints,
	serviceDisabled: ServiceDisabled,
	serviceFinance: ServiceFinance,
	serviceFwdarrows: ServiceFwdarrows,
	serviceHammertime: ServiceHammertime,
	serviceIndividual: ServiceIndividual,
	serviceInstallation: ServiceInstallation,
	serviceKitchen: ServiceKitchen,
	serviceKitchenbathroom: ServiceKitchenbathroom,
	serviceLock: ServiceLock,
	serviceMaterials: ServiceMaterials,
	serviceMixing: ServiceMixing,
	serviceNosmoking: ServiceNosmoking,
	serviceOpen: ServiceOpen,
	servicePhone: ServicePhone,
	servicePhone2: ServicePhone2,
	servicePhoneorders: ServicePhoneorders,
	servicePhoto: ServicePhoto,
	servicePickup: ServicePickup,
	serviceProgram: ServiceProgram,
	servicePruning: ServicePruning,
	servicePuzzle: ServicePuzzle,
	serviceQuestions: ServiceQuestions,
	serviceReturn: ServiceReturn,
	serviceSewing: ServiceSewing,
	serviceShopping: ServiceShopping,
	serviceTaxfree: ServiceTaxfree,
	serviceTimecar: ServiceTimecar,
	serviceTimeenvelope: ServiceTimeenvelope,
	serviceTimeform: ServiceTimeform,
	serviceToilet: ServiceToilet,
	serviceTransport: ServiceTransport,
	serviceTransportbringing: ServiceTransportbringing,
	serviceTransporthds: ServiceTransporthds,
	serviceTrolley: ServiceTrolley,
	serviceWifi: ServiceWifi,
	serviceWrenchscrew: ServiceWrenchscrew,
	buildBrickWall: BuildBrickWall,
	buildConstruction: BuildConstruction,
	buildFence: BuildFence,
	buildHeating: BuildHeating,
	buildIsolation: BuildIsolation,
	buildPlumbing: BuildPlumbing,
	buildRoofing: BuildRoofing,
	buildSiding: BuildSiding,
	buildTools: BuildTools,
	buildWindowDoor: BuildWindowDoor,
	decoCarpet: DecoCarpet,
	decoCurtains: DecoCurtains,
	decoLamp: DecoLamp,
	decoMirrorFrame: DecoMirrorFrame,
	decoPaintBrush: DecoPaintBrush,
	decoPaintBucket: DecoPaintBucket,
	decoVase: DecoVase,
	decoWallpaper: DecoWallpaper,
	decoWorkspace: DecoWorkspace,
	gardenBalcony: GardenBalcony,
	gardenBench: GardenBench,
	gardenGazebo: GardenGazebo,
	gardenHoose: GardenHoose,
	gardenLamp: GardenLamp,
	gardenMower: GardenMower,
	gardenPlant: GardenPlant,
	gardenRake: GardenRake,
	gardenSeeds: GardenSeeds,
	gardenWheelbarrow: GardenWheelbarrow,
	planBathroomEquipment: PlanBathroomEquipment,
	planDrawers: PlanDrawers,
	planFaucet: PlanFaucet,
	planFireplace: PlanFireplace,
	planFloorHeating: PlanFloorHeating,
	planKitchen: PlanKitchen,
	planKitchenEquipment: PlanKitchenEquipment,
	planShower: PlanShower,
	planSinkCabinet: PlanSinkCabinet,
	planSoapdispensor: PlanSoapdispensor,
	planStairs: PlanStairs,
	planTiles: PlanTiles,
	planToilet: PlanToilet,
	planWallsiding: PlanWallsiding,
	planWardrobe: PlanWardrobe,
	planWorkspace: PlanWorkspace,
	technicBoiler: TechnicBoiler,
	technicDrill: TechnicDrill,
	technicDuctape: TechnicDuctape,
	technicHammer: TechnicHammer,
	technicHeater: TechnicHeater,
	technicHeating: TechnicHeating,
	technicHomeSecurity: TechnicHomeSecurity,
	technicPaintGlue: TechnicPaintGlue,
	technicPowerCable: TechnicPowerCable,
	technicScreww: TechnicScreww,
	technicVent: TechnicVent,
	technicWorkshop: TechnicWorkshop,
	deliveryEcomm: DeliveryEcomm,
	deliveryPickup: DeliveryPickup,
	deliveryTransport: DeliveryTransport,
	changeFull: ChangeFull,
	menuDots: MenuDots,
	logout: Logout,
	profitPersonalData: ProfitPersonalData,
	profitOrder: ProfitOrder,
	profitReturn: ProfitReturn,
	profitHistory: ProfitHistory,
	profitCart: ProfitCart,
	author: Author,
	onlineAppointment: OnlineAppointment,
	people: People,
	mozaicInformation: MozaicInformation,
	mozaicStoreLocation: MozaicStoreLocation,
	mozaicShareAndroid: MozaicShareAndroid,
	mozaicArrowTop: MozaicArrowTop,
	mozaicArrowBottom: MozaicArrowBottom,
	mozaicArrowRight16: MozaicArrowRight16,
	mozaicControlCross: MozaicControlCross,
	mozaicControlCircleMore: MozaicControlCircleMore,
	mozaicPublishEdit: MozaicPublishEdit,
	mozaicTrashbin: MozaicTrashbin,
	mozaicDownloadWeb: MozaicDownloadWeb,
	mozaicNotificationCircleAvailable: MozaicNotificationCircleAvailable,
	mozaicNotificationCircleInformation: MozaicNotificationCircleInformation,
	mozaicArrowRight: MozaicArrowRight,
}
