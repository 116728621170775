import React, { ReactElement, useCallback } from 'react'
import { isNull } from 'lodash'

import { PreferredStoreInfoHolidays, PreferredStoreInfoOpeningHours, PreferredStoreModal } from '~/components/core/preferredStore'
import { CartPreviewDeliveryPickupStoreInfoShort, ICartPreviewDeliveryPickupStoreInfoProps } from '~/components/cart/cartPreview'
import { useModal } from '~/hooks/modal'

import styles from './CartPreviewDeliveryPickupStoreInfo.module.scss'

const CartPreviewDeliveryPickupStoreInfo = (props: ICartPreviewDeliveryPickupStoreInfoProps): ReactElement | null => {
	const { store } = props
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')

	const handleChangeStore = useCallback(() => {
		handleOpen()
	}, [handleOpen])

	if (isNull(store)) {
		return null
	}

	const { availableHours: { holidayList, shopDepartment } } = store

	return (
		<div className={ styles.wrapper }>
			<CartPreviewDeliveryPickupStoreInfoShort store={ store } onClick={ handleChangeStore } />

			<PreferredStoreInfoOpeningHours workingDays={ shopDepartment.workingDayList } />

			<PreferredStoreInfoHolidays holidays={ holidayList } />

			<PreferredStoreModal
				isWithCheckPrice
				canForceChange={ !isNull(store) }
				isOpen={ isOpen }
				onClose={ handleClose }
			/>
		</div>
	)
}

export { CartPreviewDeliveryPickupStoreInfo }
