import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { isEqual, isNull } from 'lodash'

import { ICartPreviewProductsAvailabilityBlockProps } from '~/components/cart/cartPreview'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsAvailabilityBlock.module.scss'

const CartPreviewProductsAvailabilityBlock = (props: ICartPreviewProductsAvailabilityBlockProps): ReactElement | null => {
	const { userStoreStock, priceUnit, deliveryInfo } = props
	const { isOnDemand, isActive } = deliveryInfo
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const isNotAvailable = isEqual(userStoreStock, 0) || (isNull(userStoreStock) && !isActive)

	const availabilityPointerClass = classNames(styles.availabilityPointer, {
		[styles.notAvailability]: isNotAvailable,
		[styles.isOnDemand]: isOnDemand && !isNotAvailable,
		[styles.isMozaic]: shouldUseMozaic,
	})

	const textClass = classNames({
		[styles.isMozaic]: shouldUseMozaic,
	})

	const renderText = useCallback((): string => {
		if (isNull(userStoreStock) && isActive) {
			return t('preview.table.available')
		}

		if (isEqual(userStoreStock, 0) || (isNull(userStoreStock) && !isActive)) {
			return t('preview.table.notAvailable')
		}

		return `${ t('preview.table.availability.products', { count: userStoreStock || 0 }) } ${ userStoreStock || 0 } ${ priceUnit }`
	}, [userStoreStock, isNotAvailable, priceUnit, deliveryInfo])

	return (
		<div className={ styles.wrapper }>
			<span className={ availabilityPointerClass } />

			<span className={ textClass }>
				{ renderText() }
			</span>
		</div>
	)
}

export { CartPreviewProductsAvailabilityBlock }
