import { AxiosResponse } from 'axios'

import { IFavouriteListResponse, IFavouriteListSimpleResponse, IFavouriteListParams, IFavouriteListTransferProductsParams, IFavouriteListTransferProductsResponse } from '~/api/dataTypes/favouriteList'
import { request } from '~/api/requests/axios'

export const getFavouriteList = async (): Promise<AxiosResponse<IFavouriteListResponse>> => (
	await request.get('/v1/favourite-list/products', {
		service: 'PROXY_API_SERVICE',
		restAuthorization: true,
	})
)

export const getFavouriteListSimple = async (): Promise<AxiosResponse<IFavouriteListSimpleResponse>> => (
	await request.get('/v1/favourite-list/products/simple', {
		service: 'PROXY_API_SERVICE',
		restAuthorization: true,
	})
)

export const postAddToFavouriteList = async (params: IFavouriteListParams): Promise<AxiosResponse<IFavouriteListSimpleResponse>> => {
	const { lmReference } = params

	const data = {
		lmReference,
		quantity: 1,
	}

	return await request.post('/v1/favourite-list/products', data, {
		service: 'PROXY_API_SERVICE',
		restAuthorization: true,
	})
}

export const deleteFromFavouriteList = async (lmReference: string): Promise<AxiosResponse<IFavouriteListSimpleResponse>> => (
	await request.delete(`/v1/favourite-list/products/${ lmReference }`, {
		service: 'PROXY_API_SERVICE',
		restAuthorization: true,
	})
)

export const postTransferToCart = async (params: IFavouriteListTransferProductsParams): Promise<AxiosResponse<IFavouriteListTransferProductsResponse>> => (
	await request.post('/v1/favourite-list/products/transfer-products', null, {
		service: 'PROXY_API_SERVICE',
		restAuthorization: true,
		params,
	})
)
