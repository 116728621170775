import React, { ReactElement } from 'react'

import { IFooterWrapperProps } from '~/components/core/layout/footer'
import { Container } from '~/components/core/layout/container'
import { SkipContentAnchor, SkipContentList } from '~/components/core/skipContent'
import { SKIP_FOOTER, useSkipElements } from '~/hooks/skipElements'

import styles from './FooterWrapper.module.scss'

const FooterWrapper = (props: IFooterWrapperProps): ReactElement => {
	const { children } = props
	const { footerWrapperSkip } = useSkipElements()

	return (
		<footer className={ styles.wrapper }>
			<Container>
				<SkipContentAnchor elementId={ SKIP_FOOTER } />

				<SkipContentList skipElements={ footerWrapperSkip } />

				{ children }
			</Container>
		</footer>
	)
}

export { FooterWrapper }
