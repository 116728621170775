import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import { map, isNull, size, isEqual, toNumber } from 'lodash'

import { IMegaMenuWorldsItemProps, MegaMenuWorldsSubWorld, focusToActiveElement, focusToFirstChildOfActiveElement } from '~/components/core/megamenu'
import { Icon } from '~/components/core/icon'
import { Link } from '~/components/core/link'

import styles from './MegaMenuWorldsItem.module.scss'

const MegaMenuWorldsItem = (props: IMegaMenuWorldsItemProps): ReactElement | null => {
	const { data, isFirstElement, isLastGroupElement, parentTabIndex, onGetNodeData, onClose } = props
	const linkRef = useRef<HTMLAnchorElement | null>(null)
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')
	const { t } = useTranslation(['header'])

	const handleCloseMegaMenu = useCallback(async (): Promise<void> => {
		onClose()
	}, [onClose])

	const handleBlur = useCallback((): void => {
		if (isFirstElement && isShiftTabPressed) {
			focusToActiveElement()
		}
	}, [isFirstElement, isShiftTabPressed])

	const handleBlurLastElement = useCallback((isLastElement: boolean) => (): void => {
		if (isLastElement) {
			focusToActiveElement()
		}
	}, [])

	useEffect(() => {
		const { current } = linkRef

		if (isFirstElement && !isNull(current)) {
			focusToFirstChildOfActiveElement(-1, current)
		}
	}, [isFirstElement, linkRef])

	if (isNull(data)) {
		return null
	}

	const { childrenIds, name, wwwUrl } = data

	const renderSubWorlds = (): ReactElement => (
		<ul className={ styles.subWorlds }>
			{ map(childrenIds, (id: number, index: number) => {
				const nodeData = onGetNodeData(id)
				const tabIndex = toNumber(`${ parentTabIndex }${ index }`)
				const isLastElement = isLastGroupElement && isEqual(index, size(childrenIds)-1)

				if (isNull(nodeData)) {
					return null
				}

				return (
					<MegaMenuWorldsSubWorld
						key={ id }
						data={ nodeData }
						ariaLabel={ t('menu.megaMenu.subNavAriaPrefix') }
						tabIndex={ tabIndex }
						onBlur={ handleBlurLastElement(isLastElement) }
						onClose={ onClose }
					/>
				)
			}) }
		</ul>
	)

	return (
		<li className={ styles.wrapper }>
			<Link
				linkRef={ linkRef }
				href={ wwwUrl }
				theme={ {
					wrapper: styles.world,
				} }
				ariaLabel={ `${ t('menu.megaMenu.subNavAriaPrefix') } ${ name }` }
				tabIndex={ toNumber(`${ parentTabIndex }0`) }
				onClick={ handleCloseMegaMenu }
				onBlur={ handleBlur }
			>
				<span className={ styles.linkWrapper }>
					<span>
						{ name }
					</span>

					<Icon
						name="arrowFull"
						additionalClass={ styles.arrow }
						width={ 4 }
						height={ 8 }
					/>
				</span>
			</Link>

			{ renderSubWorlds() }
		</li>
	)
}

export { MegaMenuWorldsItem }
