import queryString from 'query-string'
import axios, { AxiosResponse } from 'axios'
import { isNil, isNull, isUndefined, isEqual } from 'lodash'

import { AppPromiseThunkAction } from '~/actions'
import { AuthActionType, IGetAuthenticateTokenActionParams, GetAuthenticateRefreshTokenActionType, GetAuthenticateTokenActionType, SetLogoutActionType, FresherTokenPromiseAction, setAdeoRefreshTokens, setAdeoExtractTokens, setAdeoLogout } from '~/actions/auth'
import { postAuthenticateToken, postAuthenticateRefreshToken } from '~/api/requests/auth'
import { IAuthenticateRefreshTokenRequest, IAuthenticateTokenRequest } from '~/api/dataTypes/auth'
import { localStorageGet, localStorageSet, localStorageRemove } from '~/utils/storage'
import { LS_KEY_AUTH_USER, LS_KEY_CART_UUID, LS_KEY_CALENDAR_CART_UUID, LS_KEY_SHOULD_BE_LOGIN_BY_ADEO } from '~/utils/constants'
import { isValidKobiJwtToken, isValidToken } from '~/utils/token'
import { IGetAdeoCookieAuthData } from '~/utils/types'
import { adeoCookies } from '~/utils/adeoCookies'

// eslint-disable-next-line @typescript-eslint/typedef
export const getAuthenticateToken = (data: IGetAuthenticateTokenActionParams): AppPromiseThunkAction<GetAuthenticateTokenActionType> => async (dispatch) => {
	const { username, password } = data
	const params: IAuthenticateTokenRequest = {
		username,
		password,
		grant_type: 'password',
	}

	return dispatch({
		type: AuthActionType.SET_AUTHENTICATE_TOKEN,
		payload: async () => {
			try {
				const response = await postAuthenticateToken(params)

				localStorageSet(LS_KEY_AUTH_USER, response.data)
				localStorageRemove(LS_KEY_CART_UUID) // TODO: cartuuid in LS is deprecated - this line should stay for some time after PLEDEV-7974 deploy
				localStorageRemove(LS_KEY_CALENDAR_CART_UUID)

				return Promise.resolve(response)
			} catch (e: unknown) {
				localStorageRemove(LS_KEY_AUTH_USER)

				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getAuthenticateRefreshToken = (): AppPromiseThunkAction<GetAuthenticateRefreshTokenActionType> => async (dispatch, getState) => {
	const { auth } = getState()
	const { data } = auth

	if (isNull(data)) {
		return
	}

	const params: IAuthenticateRefreshTokenRequest = {
		refresh_token: data.refresh_token,
		grant_type: 'refresh_token',
	}

	return dispatch({
		type: AuthActionType.SET_AUTHENTICATE_REFRESH_TOKEN,
		payload: async () => {
			try {
				const response = await postAuthenticateRefreshToken(params)
				const isRememberedSession = !isNil(localStorageGet(LS_KEY_AUTH_USER))

				if (isRememberedSession) {
					localStorageSet(LS_KEY_AUTH_USER, response.data)
				}

				return Promise.resolve(response)
			} catch (e: unknown) {
				localStorageRemove(LS_KEY_AUTH_USER)

				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setLogout = (): AppPromiseThunkAction<SetLogoutActionType> => async (dispatch) => {
	localStorageRemove(LS_KEY_AUTH_USER)
	adeoCookies.customerUuid.delete(window.location.hostname)

	return dispatch({
		type: AuthActionType.SET_LOGOUT,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const withFresherToken: FresherTokenPromiseAction = (action) => (...args) => async (dispatch, getState) => {
	const { auth } = getState()
	const shouldBeLoginByAdeo = localStorageGet(LS_KEY_SHOULD_BE_LOGIN_BY_ADEO)

	if (shouldBeLoginByAdeo) {
		// TODO: commented due to issue PLEDEV-8596
		// const { data }: AxiosResponse<IGetAdeoCookieAuthData> = await axios.get('/api/cookies')
		// const { cookies } = data
		// const { kobiJwt, idToken } = cookies || {}

		// const hasTokenChanged = !isUndefined(kobiJwt) && !isEqual(kobiJwt, auth.adeo?.kobiJwt)

		// if (hasTokenChanged) {
		// await dispatch(setAdeoExtractTokens({ kobiJwt }))
		// } else if (!isUndefined(kobiJwt) && !isUndefined(idToken) && !isValidKobiJwtToken(kobiJwt)) {
		// await dispatch(setAdeoRefreshTokens({ kobiJwt, idToken }))
		// }
	} else {
		if (!isNil(auth.data)) {
			if (!isValidToken(auth.data.access_token)) {
				await dispatch(getAuthenticateRefreshToken())
			}
		}
	}

	return await dispatch(action(...args))
}
