import { combineReducers } from 'redux'

import { IPreferredStoreState } from '~/state/reducers/preferredStoreReducer'

import { preferredStoreData } from './storeDataReducer'
import { preferredStoresList } from './storesListReducer'

const preferredStore = combineReducers<IPreferredStoreState>({
	data: preferredStoreData,
	list: preferredStoresList,
})

export { preferredStore }
