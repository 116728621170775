import { AxiosResponse } from 'axios'

import { IShareParams } from '~/api/dataTypes/share'
import { request } from '~/api/requests/axios'

export const postShare = async (params: IShareParams, cartUuid: string, reCaptchaToken: string): Promise<AxiosResponse<void>> => (
	await request.post(`/v1/cart/${ cartUuid }/share`, params, {
		service: 'AUTHLESS_WEB_INTERACTION_SERVICE',
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
	})
)

export const postCartShare = async (params: IShareParams, reCaptchaToken: string): Promise<AxiosResponse<void>> => (
	await request.post('/v1/preferences/cart/share', params, {
		service: 'CUSTOMER_PREFERENCE_SERVICE',
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
	})
)

export const postWishListShare = async (params: IShareParams, reCaptchaToken: string): Promise<AxiosResponse<void>> => (
	await request.post('/v1/preferences/wish-list/share', params, {
		service: 'CUSTOMER_PREFERENCE_SERVICE',
		headers: {
			'recaptcha-response': reCaptchaToken,
		},
	})
)
