import React, { ReactElement } from 'react'

import { INewsletterIconsRowProps } from '~/components/core/newsletter'

import styles from './NewsletterIconsRow.module.scss'

const NewsletterIconsRow = (props: INewsletterIconsRowProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { NewsletterIconsRow }
