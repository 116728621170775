import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty, isEqual, isUndefined } from 'lodash'

import { IArrowLinkContentProps } from '~/components/core/arrowLink'
import { Icon } from '~/components/core/icon'
import { useMozaic } from '~/hooks/mozaic'

import styles from './ArrowLinkContent.module.scss'

const ArrowLinkContent = (props: IArrowLinkContentProps): ReactElement => {
	const {
		label,
		isReverse = false,
		theme = {},
		color = undefined,
		additionalClass = '',
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const iconName = shouldUseMozaic ? 'arrow' : 'arrowFull'
	const iconWidth = shouldUseMozaic ? 7 : 4
	const iconColor = !isUndefined(color) ? color : shouldUseMozaic ? 'black' : 'green'

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
		[styles.isMozaic]: shouldUseMozaic,
	})

	const textClass = classNames(styles.text, theme.link, {
		[styles.reverse]: isReverse,

	})

	const arrowClass = classNames(styles.arrow, {
		[styles.reverse]: isReverse,
	}, theme.arrow)

	return (
		<div className={ wrapperClass }>
			<span className={ textClass }>
				{ label }
			</span>

			<Icon
				name={ iconName }
				additionalClass={ arrowClass }
				width={ iconWidth }
				color={ iconColor }
			/>
		</div>
	)
}

export { ArrowLinkContent }
