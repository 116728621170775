import React, { ReactElement, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { IAddToCartModalActionsProps } from '~/components/core/addToCartModal'
import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './AddToCartModalActions.module.scss'

const AddToCartModalActions = (props: IAddToCartModalActionsProps): ReactElement => {
	const { onClose, wwwUrl } = props
	const { t } = useTranslation(['common'])
	const router = useRouter()

	const buttonClass = classNames(styles.button, 'mc-button--bordered')

	const handleGoToCartPage = useCallback(async (): Promise<void> => {
		onClose()

		await router.push(wwwUrl)
	}, [])

	return (
		<div className={ styles.wrapper }>
			<Button
				variant="secondary"
				text={ t('goToCart') }
				additionalClass={ styles.button }
				dataTestId={ DATA_TESTID.CART_MODAL.BUTTON_GO_TO_CART_PREVIEW }
				onClick={ handleGoToCartPage }
			/>

			<Button
				isReverse
				variant="secondary"
				text={ t('continueOrders') }
				additionalClass={ buttonClass }
				dataTestId={ DATA_TESTID.CART_MODAL.BUTTON_CLOSE_MODAL }
				onClick={ onClose }
			/>
		</div>
	)
}

export { AddToCartModalActions }
