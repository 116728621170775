import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { IProductBlockRatingProps } from '~/components/core/productBlock'
import { StarsRating } from '~/components/core/starsRating'

import styles from './ProductBlockRating.module.scss'

const ProductBlockRating = (props: IProductBlockRatingProps): ReactElement => {
	const { review, additionalClass } = props

	const { averageRating, countReviews } = review
	const isRatingVisible = !isEqual(countReviews, 0)
	const wrapperClass = classNames(styles.wrapper, additionalClass)

	return (
		<div className={ wrapperClass }>
			{ isRatingVisible && (
				<StarsRating
					averageRating={ averageRating }
					reviewCount={ countReviews }
					size={ 14 }
				/>
			) }
		</div>
	)
}

export { ProductBlockRating }
