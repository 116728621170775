import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'

import { HeaderTopBarSearchBarResults, HeaderTopBarSearchBarNoResults, HeaderTopBarSearchBarResultsMain, HeaderTopBarSearchBarResultsAside, HeaderTopBarSearchBarLoader, getSubmitSearchButtonAriaLabel } from '~/components/core/layout'
import { SearchClearButton } from '~/components/core/searchClearButton'
import { ErrorBoundary } from '~/components/core/form'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { useHeaderSearch } from '~/hooks/headerSearch'
import { DATA_TESTID } from '~/utils/dataTestId'
import { HeaderTopBarSearchContext } from '~/providers/headerTopBarSearchProvider'

import styles from './HeaderTopBarSearchBar.module.scss'

const HeaderTopBarSearchBar = (): ReactElement => {
	const { isVisible, isAsideVisible, isFocusedByPointer, isLoading, isNoResultContentVisible, isSearchResultVisible, isDesktop, isValid, suggestionNodeList, suggestionProductList, suggestionSearchList, uniqSearchHistory, name, search, errors, formRef, ref, handleFormSubmit, handleChange, handleBlur, handleFocus, handlePointerOver, handleToggleVisible, handleHideSearchSuggestions, handleForceRerenderResults, clearSearch, searchConfig } = useHeaderSearch()
	const { isOpenSearchModal } = useContext(HeaderTopBarSearchContext)
	const { t } = useTranslation(['header'])

	const hasSearchError = !isUndefined(errors.search)

	const hasCategoriesOnly = useMemo((): boolean => {
		return !isEmpty(suggestionNodeList) && isEmpty(suggestionProductList)
	}, [suggestionNodeList, suggestionProductList])

	const handleHideSuggestions = useCallback((): void => {
		if (hasCategoriesOnly && !isEmpty(uniqSearchHistory)) {
			handleHideSearchSuggestions()
		}
	}, [hasCategoriesOnly, uniqSearchHistory, hasCategoriesOnly, handleHideSearchSuggestions])

	const renderSearchNoResults = useCallback((): ReactElement | null => {
		if (isNoResultContentVisible) {
			return (
				<HeaderTopBarSearchBarNoResults
					query={ search }
					isModalOpen={ isOpenSearchModal }
					uniqSearchHistory={ uniqSearchHistory }
					handleForceRerenderResults={ handleForceRerenderResults }
					handleHideSuggestions={ handleHideSuggestions }
				/>
			)
		}

		return null
	}, [uniqSearchHistory, search, isOpenSearchModal, handleForceRerenderResults, handleHideSuggestions])

	const renderSearchResults = useCallback((): ReactElement | null => {
		if (isSearchResultVisible && isValid) {
			return (
				<section role="dialog" className={ styles.resultsWrapper }>
					<HeaderTopBarSearchBarResults isModalOpen={ isOpenSearchModal }>
						{ !isNoResultContentVisible && (
							<HeaderTopBarSearchBarResultsMain
								nodes={ suggestionNodeList }
								suggestions={ suggestionSearchList }
								products={ suggestionProductList }
								history={ uniqSearchHistory }
								query={ search }
								isModalOpen={ isOpenSearchModal }
								handleHideSuggestions={ handleHideSuggestions }
								hasCategoriesOnly={ hasCategoriesOnly }
								onHideSearchSuggestions={ handleHideSearchSuggestions }
								onForceRerenderResults={ handleForceRerenderResults }
							/>) }

						{ isAsideVisible && (
							<HeaderTopBarSearchBarResultsAside
								shouldHideOnBlur
								products={ suggestionProductList }
								query={ search }
								onHideSearchSuggestions={ handleHideSearchSuggestions }
							/>
						) }
					</HeaderTopBarSearchBarResults>

					{ renderSearchNoResults() }

				</section>
			)
		}

		return null
	}, [isAsideVisible, isSearchResultVisible, isValid, isOpenSearchModal, hasCategoriesOnly, suggestionNodeList, suggestionSearchList, suggestionProductList, uniqSearchHistory, search, handleHideSearchSuggestions, handleForceRerenderResults, renderSearchNoResults, handleHideSuggestions])

	const renderSearchBar = useCallback((): ReactElement => {
		const inputClass = classNames(styles.input, {
			[styles.focused]: isFocusedByPointer,
		})

		const tabIndex = isSearchResultVisible || isEmpty(search) ? -1 : 0

		return (
			<div className={ styles.inputWrapper }>
				<input
					ref={ ref }
					type="search"
					name={ name }
					placeholder={ t('search.placeholder', { ns: 'header' }) }
					className={ inputClass }
					autoComplete="off"
					data-testid={ DATA_TESTID.HEADER.FIELD_SEARCH }
					maxLength={ searchConfig.searchKeywordMaxLength }
					onChange={ handleChange }
					onBlur={ handleBlur }
					onFocus={ handleFocus }
					onPointerOver={ handlePointerOver }
				/>

				<Button
					hasAriaPopup
					isAriaExpanded={ isSearchResultVisible }
					type="submit"
					additionalClass={ styles.searchButton }
					dataTestId={ DATA_TESTID.HEADER.BUTTON_SUBMIT_SEARCH }
					variant="neutral"
					size="inherit"
					ariaLabel={ getSubmitSearchButtonAriaLabel(t, search) }
					tabIndex={ tabIndex }
				>
					<Icon
						name="search"
						color="navy"
						width={ 20 }
						height={ 22 }
						additionalClass={ styles.icon }
					/>
				</Button>

				<ErrorBoundary error={ errors.search } additionalClass={ styles.error } />

				<SearchClearButton
					additionalClass={ styles.clearButton }
					hasError={ hasSearchError }
					search={ search }
					onClick={ clearSearch }
				/>

				<HeaderTopBarSearchBarLoader isLoading={ isLoading } isModalOpen={ isOpenSearchModal } />
			</div>
		)
	}, [isFocusedByPointer, isSearchResultVisible, isLoading, name, search, errors.search, handleChange, handleBlur, handleFocus, handlePointerOver])

	const renderForm = useCallback((): ReactElement => {
		const wrapperClass = classNames(styles.wrapper, {
			[styles.notVisible]: !isVisible && !isDesktop,
			[styles.modalView]: isOpenSearchModal,
		})

		return (
			<form
				ref={ formRef }
				className={ wrapperClass }
				onSubmit={ handleFormSubmit }
			>
				{ renderSearchBar() }

				{ renderSearchResults() }
			</form>
		)
	}, [formRef, isVisible, isDesktop, renderSearchBar, renderSearchResults, handleFormSubmit, handleToggleVisible, isOpenSearchModal])

	return renderForm()
}

export { HeaderTopBarSearchBar }
