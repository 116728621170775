import React, { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useList } from 'react-use'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { find, isEmpty, isNull } from 'lodash'

import { ICustomerLoyaltyAccountReward } from '~/api/dataTypes/customerLoyalty'
import { CartPreviewDiscountLysPoints } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysPoints'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { WatermarkPlaceholder } from '~/components/core/watermarkPlaceholder'
import { CartPreviewDiscountLysSubmit } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysSubmit'
import { CartPreviewDiscountLysLoyaltyCoupons } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons'
import { CartPreviewDiscountLysPromoCode } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysPromoCode'
import { CartPreviewDiscountLysPromoCodeInfo } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysPromoCode/cartPreviewDiscountLysPromoCodeInfo'
import { ICartPreviewDiscountLysFormCode } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'
import { getCartPreviewData, getCartPreviewLoyaltyData, getIsPromotionEcommerce, getIsPromotionLoyalty } from '~/state/reducers/cartPreviewReducer'
import { getSelectedRewardElement, limitCurrentRewards } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysContent'
import { CartPreviewDiscountLysNoCoupons } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons/cartPreviewDiscountLysNoCoupons'

import styles from './CartPreviewDiscountLysContent.module.scss'

const CartPreviewDiscountLysContent = (): ReactElement | null => {
	const { cart: { promotionCode: promotionCodeState, promotionCodeUsed: isPromotionCodeUsed } } = useSelector(getCartPreviewData, shallowEqual)
	const { accountData: { currentRewards: availableRewards } } = useSelector(getCartPreviewLoyaltyData, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const isLoyalty = useSelector(getIsPromotionLoyalty, shallowEqual)
	const { selectedReward, isEditMode } = useContext(LysLoyaltyProviderContext)
	const [currentRewards, { set: setCurrentRewards }] = useList<ICustomerLoyaltyAccountReward>(availableRewards)
	const { t } = useTranslation(['cart'])

	const isPromoCodeDisabled = isLoyalty && isEditMode
	const isCouponsSectionDisabled = isEcommerce && isEditMode && !isEmpty(currentRewards)

	const inUseReward = useMemo((): ICustomerLoyaltyAccountReward | undefined => (
		find(availableRewards, { code: selectedReward.code })
	), [selectedReward, availableRewards])

	const schema: yup.SchemaOf<ICartPreviewDiscountLysFormCode> = useMemo(() => yup.object().shape({
		promotionCode: yup.string(),
	}), [])

	const formProps = useForm<ICartPreviewDiscountLysFormCode>({
		resolver: yupResolver(schema),
		defaultValues: {
			promotionCode: promotionCodeState ?? '',
		},
		mode: 'onChange',
	})

	const { watch, setError } = formProps
	const { promotionCode: promoCode } = watch()

	const selectedRewardElement = useMemo((): ICustomerLoyaltyAccountReward | undefined => (
		getSelectedRewardElement(currentRewards, selectedReward)
	), [selectedReward, currentRewards])

	const handleSetCurrentRewards = useCallback((rewards: ICustomerLoyaltyAccountReward[]): void => {
		setCurrentRewards(rewards)
	}, [])

	const renderLoyaltyCoupons = useCallback((): ReactElement | null => {
		if (isCouponsSectionDisabled) {
			return (
				<WatermarkPlaceholder text="">
					<CartPreviewDiscountLysLoyaltyCoupons currentRewards={ currentRewards } />

					<CartPreviewDiscountLysNoCoupons currentRewards={ currentRewards } />
				</WatermarkPlaceholder>
			)
		}

		return (
			<>
				<CartPreviewDiscountLysLoyaltyCoupons currentRewards={ currentRewards } />

				<CartPreviewDiscountLysNoCoupons currentRewards={ currentRewards } />
			</>
		)
	}, [currentRewards, isCouponsSectionDisabled])

	useEffect(() => {
		handleSetCurrentRewards(availableRewards)
	}, [availableRewards])

	useEffect(() => {
		if (isPromotionCodeUsed) {
			const rewardToSelect = selectedRewardElement || inUseReward

			handleSetCurrentRewards(limitCurrentRewards(isEditMode, isEcommerce, isLoyalty, availableRewards, rewardToSelect))
		} else if (!isEmpty(promoCode) && !isNull(promotionCodeState)) {
			setError('promotionCode', {
				message: t('preview.aside.discount.promoCode.incorrectCode'),
			})
		}
	}, [isPromotionCodeUsed, promoCode, promotionCodeState, isEditMode, availableRewards, inUseReward])

	return (
		<div className={ styles.wrapper }>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<FormProvider { ...formProps }>
				{ renderLoyaltyCoupons() }

				<CartPreviewDiscountLysPoints />

				<CartPreviewDiscountLysPromoCode isPromoCodeDisabled={ isPromoCodeDisabled } />

				<CartPreviewDiscountLysPromoCodeInfo />

				<div className={ styles.buttonWrapper }>
					<CartPreviewDiscountLysSubmit
						inUseReward={ inUseReward }
						selectedRewardElement={ selectedRewardElement }
						onSetCurrentRewards={ handleSetCurrentRewards }
					/>
				</div>
			</FormProvider>
		</div>
	)
}

export { CartPreviewDiscountLysContent }
