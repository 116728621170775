import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Alert } from '~/components/core/notifications'

import styles from './CartPreviewSummaryLoyaltyProgramPinModalError.module.scss'

const CartPreviewSummaryLoyaltyProgramPinModalError = (): ReactElement => {
	const { t } = useTranslation(['cart'])

	return (
		<Alert
			canClose={ false }
			icon="infoCircle"
			type="danger"
			additionalClass={ styles.alert }
		>
			<span className={ styles.text }>
				{ t('preview.aside.discount.domProgram.modal.error') }
			</span>
		</Alert>
	)
}

export { CartPreviewSummaryLoyaltyProgramPinModalError }
