import { HEADER_MINI_BOX_HEIGHT, IGetHeaderMiniBoxListStyles } from '~/components/core/layout'

export const getHeaderMiniBoxListStyles = (itemsLength: number): IGetHeaderMiniBoxListStyles => {
	const { ONE_ELEMENT, TWO_ELEMENTS, MORE_THAN_TWO } = HEADER_MINI_BOX_HEIGHT

	switch (itemsLength) {
		case 1:
			return { height: ONE_ELEMENT }
		case 2:
			return { height: TWO_ELEMENTS }
		default:
			return { height: MORE_THAN_TWO }
	}
}
