import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { isNil } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { Button } from '~/components/core/button'
import { getCartIdentification, postCartIdentificationOrderAsGuest } from '~/api/requests/cart'
import { AppDispatch } from '~/state/store'
import { useLogError } from '~/hooks/logError'
import { getCartUuid } from '~/utils/cart'
import { getCartIdentificationData, setOrderAsGuestData } from '~/actions/cartIdentification'

import styles from './SignInAsGuest.module.scss'

const SignInAsGuest = (): ReactElement | null => {
	const { t } = useTranslation(['signIn'])
	const router = useRouter()
	const { urls } = useContext(AppParametersContext)
	const cartUuid = getCartUuid()
	const dispatch: AppDispatch = useDispatch()
	const [customerGuestOrderAvailable, setCustomerGuestOrderAvailable] = useState<boolean>(false)
	const { sendLogError } = useLogError()

	const buttonClass = classNames(styles.button, 'mc-button--bordered')

	const handleGuestOrderAvailable = useCallback(async (): Promise<void> => {
		try {
			const { data } = await getCartIdentification(String(cartUuid))
			await dispatch(getCartIdentificationData(data))

			setCustomerGuestOrderAvailable(data.cartGuestOrderAvailable)
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [cartUuid])

	const handleBuyAsGuest = useCallback(async (): Promise<void> => {
		try {
			await dispatch(setOrderAsGuestData(true))
			await router.push(urls.cartView)
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [cartUuid])

	useEffect(() => {
		if (!isNil(cartUuid)) {
			(async () => {
				await handleGuestOrderAvailable()
			})()
		}
	}, [cartUuid])

	if (!customerGuestOrderAvailable) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption }>
				{ t('guest.caption') }
			</div>

			<div className={ styles.description }>
				{ t('guest.description') }
			</div>

			<Button
				text={ t('guest.button') }
				variant="secondary"
				additionalClass={ buttonClass }
				onClick={ handleBuyAsGuest }
			/>
		</div>
	)
}

export { SignInAsGuest }
