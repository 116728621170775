import React, { ReactElement, useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isNull, isUndefined } from 'lodash'

import { buildItemQuantityList, CartPreviewDeliveryEcommerce, CartPreviewDeliveryGroups, CartPreviewDeliveryPickup, CartPreviewDeliveryPickupTransfer, CartPreviewDeliveryWrapper, ICartChangeDeliveryParams, ICartPreviewDeliveryProps } from '~/components/cart/cartPreview'
import { ICartDeliveryCalculateParams } from '~/api/dataTypes/cart'
import { cartDeliveryCalculate } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { IRootState } from '~/state/types'

const CartPreviewDelivery = (props: ICartPreviewDeliveryProps): ReactElement | null => {
	const { cartData, tmpCourierNote, activeDeliveryGroup } = props
	const dispatch: AppDispatch = useDispatch()
	const preferredStore = useSelector((state: IRootState) => state.preferredStore.data, shallowEqual)

	const { cart: { pickupInStore, cartItemList, productsQuantityMap, deliveryStore, promotionCode, promotionCodeType }, deliveryGroups } = cartData
	const itemQuantityList = useMemo(() => buildItemQuantityList(cartItemList, productsQuantityMap), [cartItemList, productsQuantityMap])

	const deliveryList = deliveryGroups?.delivery.deliveryList
	const pickupData = deliveryGroups?.pickup.deliveryList[0]
	const deliveryStoreData = deliveryGroups?.storeDelivery.deliveryList[0]
	const selectedPaymentWayId = cartData.cart.selectedPaymentWayId

	const handleChangeDelivery = useCallback(async (params: ICartChangeDeliveryParams): Promise<void> => {
		const { deliveryId, isDeliveryFromStore = false, pickupPointId = null, pickupPoint = undefined } = params

		const calculateParams: ICartDeliveryCalculateParams = {
			itemQuantityList,
			promotionCode: promotionCodeType === 'ECOMMERCE' ? promotionCode : '',
			paymentWayId: selectedPaymentWayId,
			deliveryWayId: deliveryId,
			additionalDeliveryWayOptionSelected: isDeliveryFromStore,
			pickupPointId,
			pickupPoint,
		}

		await dispatch(cartDeliveryCalculate(calculateParams))
	}, [itemQuantityList, promotionCode, promotionCodeType, selectedPaymentWayId])

	const renderDeliveryItems = useCallback((): ReactElement | null => {
		if (isNull(activeDeliveryGroup)) {
			return null
		}

		const selectedPromotionCode = promotionCodeType === 'ECOMMERCE' ? promotionCode : ''

		switch (activeDeliveryGroup) {
			case 'DELIVERY':
				return !isUndefined(deliveryList) ? (
					<CartPreviewDeliveryEcommerce
						items={ deliveryList }
						cartData={ cartData }
						tmpCourierNote={ tmpCourierNote }
						onChangeDelivery={ handleChangeDelivery }
					/>
				) : null
			case 'PICKUP':
				return !isUndefined(pickupData) ? (
					<CartPreviewDeliveryPickup
						pickupData={ pickupData }
						pickupInStore={ pickupInStore }
						itemQuantityList={ itemQuantityList }
						promotionCode={ selectedPromotionCode }
						onChangeDelivery={ handleChangeDelivery }
					/>
				) : null
			case 'STORE_DELIVERY':
				return !isUndefined(deliveryStoreData) ? (
					<CartPreviewDeliveryPickupTransfer
						deliveryStoreData={ deliveryStoreData }
						deliveryStore={ deliveryStore }
						onChangeDelivery={ handleChangeDelivery }
					/>
				) : null
			default:
				return null
		}
	}, [activeDeliveryGroup, tmpCourierNote, itemQuantityList, deliveryStore, deliveryStoreData, promotionCode, promotionCodeType])

	return (
		<CartPreviewDeliveryWrapper>
			{ !isNull(deliveryGroups) && <CartPreviewDeliveryGroups groups={ deliveryGroups } selectedDeliveryType={ activeDeliveryGroup } /> }

			{ renderDeliveryItems() }
		</CartPreviewDeliveryWrapper>
	)
}

export { CartPreviewDelivery }
