import React, { ReactElement } from 'react'

import { ILayoutAccountWrapperProps, LayoutAccountMenu } from '~/components/core/layout/layoutAccount'
import { Container } from '~/components/core/layout/container'
import { Breadcrumbs } from '~/components/core/breadcrumbs'
import { useAccountBreadcrumbs } from '~/hooks/accountBreadcrumbs'

import styles from './LayoutAccountWrapper.module.scss'

const LayoutAccountWrapper = (props: ILayoutAccountWrapperProps): ReactElement => {
	const { children, pageType } = props
	const { getBreadcrumbs } = useAccountBreadcrumbs()

	const breadcrumbs = getBreadcrumbs(pageType)

	return (
		<Container additionalClass={ styles.wrapper }>
			<Breadcrumbs breadcrumbs={ breadcrumbs } />

			<div className={ styles.mainWrapper }>
				<LayoutAccountMenu active={ pageType } />

				<div className={ styles.content }>
					{ children }
				</div>
			</div>
		</Container>
	)
}

export { LayoutAccountWrapper }
