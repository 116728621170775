import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { ICartAsideTotalCostProps } from '~/components/core/cartAside'
import { usePrice } from '~/hooks/price'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartAsideTotalCost.module.scss'

const CartAsideTotalCost = (props: ICartAsideTotalCostProps): ReactElement => {
	const {
		totalCost,
		precision = 2,
	} = props
	const { t } = useTranslation(['cart'])
	const { priceFormat } = usePrice()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	return (
		<div className={ wrapperClass }>
			<span>
				{ t('aside.summary.totalCost') }
			</span>

			<span>
				{ priceFormat(totalCost, { precision }) }
			</span>
		</div>
	)
}

export { CartAsideTotalCost }
