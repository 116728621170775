import React, { ReactElement, useCallback, useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useToggle } from 'react-use'
import { includes, isEqual, isNil } from 'lodash'

import { MergeCartModalActions, MergeCartModalHeader, MergeCartModalList } from '~/components/core/mergeCartModal'
import { Modal } from '~/components/core/modal'
import { resetCartIdentificationData } from '~/actions/cartIdentification'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { useAdeoSignIn } from '~/hooks/signIn'

import styles from './MergeCartModal.module.scss'

const MergeCartModal = (): ReactElement | null => {
	const [isOpen, setIsOpen] = useToggle(false)
	const { data: { cartToMerge } } = useSelector((state: IRootState) => state.cartIdentification, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const { asPath } = useRouter()
	const { urls: { login: loginUrl } } = useContext(AppParametersContext)
	const { shouldLoginByAdeo } = useAdeoSignIn()

	const handleCancel = useCallback(async (): Promise<void> => {
		await dispatch(resetCartIdentificationData())
	}, [])

	useEffect(() => {
		if (!includes(asPath, loginUrl)) {
			setIsOpen(!isNil(cartToMerge))
		}
	}, [cartToMerge, asPath, loginUrl])

	if (isNil(cartToMerge) || isNil(cartToMerge.cartUuid) || shouldLoginByAdeo) {
		return null
	}

	const isOnCartView = isEqual(asPath, cartToMerge.wwwUrl)

	return (
		<Modal isOpen={ isOpen } additionalClass={ styles.wrapper }>
			<MergeCartModalHeader />

			<MergeCartModalList cartItemList={ cartToMerge.cartItemList } productsQuantityMap={ cartToMerge.productsQuantityMap } />

			<MergeCartModalActions
				cartUuid={ cartToMerge.cartUuid }
				isOnCartView={ isOnCartView }
				onClose={ handleCancel }
			/>
		</Modal>
	)
}

export { MergeCartModal }
