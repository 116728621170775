import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { Icon } from '~/components/core/icon'
import { IPasswordInputValidationListProps } from '~/components/core/form'
import { usePasswordInputSchema } from '~/hooks/passwordInputSchema'

import styles from './PasswordInputValidationList.module.scss'

const PasswordInputValidationList = (props: IPasswordInputValidationListProps): ReactElement => {
	const {
		password, isValidated, inputsId,
		theme = {},
	} = props
	const { t, i18n } = useTranslation(['form'])
	const {
		passwordInputSchemaMin,
		passwordInputSchemaOneUpperCase,
		passwordInputSchemaOneLowerCase,
		passwordInputSchemaOneNumber,
		passwordInputSchemaOneSpecialChar,
	} = usePasswordInputSchema()

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	const hasMinLength = passwordInputSchemaMin.isValidSync(password)
	const hasOneUpperCase = passwordInputSchemaOneUpperCase.isValidSync(password)
	const hasOneLoweCase = passwordInputSchemaOneLowerCase.isValidSync(password)
	const hasOneNumber = passwordInputSchemaOneNumber.isValidSync(password)
	const hasOneSpecialChar = passwordInputSchemaOneSpecialChar.isValidSync(password)

	const renderIcon = useCallback((condition: boolean): ReactElement => (
		<>
			<span className={ styles.iconText }>
				{ `${ condition ? t('password.ariaLabelHelpers.valid') : t('password.ariaLabelHelpers.invalid') } - ` }
			</span>

			<Icon
				name={ condition ? 'checkmark' : 'close' }
				width={ 7 }
				height={ 5 }
				color={ condition ? 'green' : 'red' }
			/>
		</>
	), [t, i18n])

	const getListItemClass = useCallback((isValid: boolean): string => classNames(styles.listElement, {
		[styles.valid]: isValidated && isValid,
		[styles.invalid]: isValidated && !isValid,
	}), [isValidated])

	return (
		<div className={ wrapperClass } id={ inputsId }>
			<p>{ t('password.requirementsTitle') }</p>

			<ul className={ styles.list }>
				<li className={ getListItemClass(hasMinLength) }>
					{ renderIcon(hasMinLength) }

					{ t('password.requirementsMinLength') }
				</li>

				<li className={ getListItemClass(hasOneUpperCase) }>
					{ renderIcon(hasOneUpperCase) }

					{ t('password.requirementsUppercase') }
				</li>

				<li className={ getListItemClass(hasOneLoweCase) }>
					{ renderIcon(hasOneLoweCase) }

					{ t('password.requirementsLowercase') }
				</li>

				<li className={ getListItemClass(hasOneNumber) }>
					{ renderIcon(hasOneNumber) }

					{ t('password.requirementsNumber') }
				</li>

				<li className={ getListItemClass(hasOneSpecialChar) }>
					{ renderIcon(hasOneSpecialChar) }

					{ t('password.requirementsSpecialChars') }
				</li>
			</ul>
		</div>
	)
}

export { PasswordInputValidationList }
