import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { ServiceCartCustomerActionType, ServiceCartCustomerType } from '~/actions/serviceCartCustomer'

import { IServiceCartCustomerState } from './types'
import { serviceCartCustomerInitialState as initialState } from './constants'

export const serviceCartCustomer = produce((draft: Draft<IServiceCartCustomerState>, action: AppPromiseAction<ServiceCartCustomerActionType>) => {
	switch (action.type) {
		case REQUEST(ServiceCartCustomerType.GET_SERVICE_CART_CUSTOMER):
			draft.isPending = true

			return draft
		case FAILURE(ServiceCartCustomerType.GET_SERVICE_CART_CUSTOMER):
			draft.isPending = false

			return draft
		case SUCCESS(ServiceCartCustomerType.GET_SERVICE_CART_CUSTOMER):
			draft.data = action.payload.data
			draft.isPending = false

			return draft
		case SUCCESS(ServiceCartCustomerType.UPDATE_SERVICE_CART_CUSTOMER_DATA):
		case SUCCESS(ServiceCartCustomerType.UPDATE_SERVICE_CART_CUSTOMER_CONTACT):
			draft.data = { ...draft.data, ...action.payload }

			return draft
		case SUCCESS(ServiceCartCustomerType.SET_SERVICE_CART_CUSTOMER_NO_INVOICE):
			draft.data.invoiceData = {
				invoiceSelected: false,
				nipOnReceipt: null,
			}

			return draft
		case SUCCESS(ServiceCartCustomerType.SET_SERVICE_CART_CUSTOMER_YES_INVOICE):
			draft.data.invoiceData = {
				invoiceSelected: true,
				nipOnReceipt: action.payload,
			}

			return draft
	}
}, initialState)
