import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { ISearchCloseButtonProps } from '~/components/core/searchClearButton'

import styles from './SearchClearButton.module.scss'

export const SearchClearButton = (props: ISearchCloseButtonProps): ReactElement | null => {
	const {
		search, hasError, onClick,
		additionalClass = '',
	} = props
	const { t } = useTranslation(['header'])

	if (isEmpty(search) || hasError) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ wrapperClass }
			ariaLabel={ t('search.clearSearch') }
			onClick={ onClick }
		>
			<Icon
				name="close"
				width={ 12 }
				height={ 12 }
				color="navy"
			/>
		</Button>
	)
}
