import { AnyAction } from 'redux'
import produce, { Draft } from 'immer'

import { SUCCESS } from '~/statics'
import { StoresListActionType } from '~/actions/stores'
import { LatLng } from '~/hooks/distance'

import { StoresUserLocationStateType } from './types'
import { storesUserLocationInitState as initialState } from './constants'

export const storesUserLocation = produce((draft: Draft<StoresUserLocationStateType>, action: AnyAction): StoresUserLocationStateType => {
	switch (action.type) {
		case SUCCESS(StoresListActionType.SET_STORE_USER_LOCATION): {
			// eslint-disable-next-line no-param-reassign
			draft = (action.payload as LatLng)

			return draft
		}

		case SUCCESS(StoresListActionType.CLEAR_STORE_USER_LOCATION): {
			// eslint-disable-next-line no-param-reassign
			draft = null

			return draft
		}

		default:
			return draft
	}
}, initialState)
