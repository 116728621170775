import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { BADGE_VALUE_THRESHOLD, IBadgeProps } from '~/components/core/badge'

import styles from './Badge.module.scss'

const Badge = (props: IBadgeProps): ReactElement | null => {
	const {
		value,
		additionalClass = '',
	} = props

	if (value < 1) {
		return null
	}

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.bigValue]: value > BADGE_VALUE_THRESHOLD,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass }>
			{ value }
		</div>
	)
}

export { Badge }
