import React, { ReactElement } from 'react'

import { IFooterAppsAndBannersWrapperProps } from '~/components/core/layout'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './FooterAppsAndBannersWrapper.module.scss'

const FooterAppsAndBannersWrapper = (props: IFooterAppsAndBannersWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper } data-testid={ DATA_TESTID.FOOTER.APPS_AND_BANNERS_SECTION }>
			{ children }
		</div>
	)
}

export { FooterAppsAndBannersWrapper }
