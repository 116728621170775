import React, { ReactElement } from 'react'

import { IHamburgerMenuUserAccountHeaderProps, HamburgerMenuUserAccountHeaderNav, HamburgerMenuUserAccountHeaderTitle } from '~/components/core/hamburgerMenu'

import styles from './HamburgerMenuUserAccountHeader.module.scss'

const HamburgerMenuUserAccountHeader = (props: IHamburgerMenuUserAccountHeaderProps): ReactElement | null => {
	const { isUserMenuOpen } = props

	if (!isUserMenuOpen) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<HamburgerMenuUserAccountHeaderNav />

			<HamburgerMenuUserAccountHeaderTitle />
		</div>
	)
}

export { HamburgerMenuUserAccountHeader }
