import React, { ReactElement } from 'react'
import { noop } from 'lodash'

import { IHeaderTopBarSearchBarProductsLinkProps } from '~/components/core/layout'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'

import styles from './HeaderTopBarSearchBarProductsLink.module.scss'

const HeaderTopBarSearchBarProductsLink = (props: IHeaderTopBarSearchBarProductsLinkProps): ReactElement => {
	const {
		caption, query,
		onBlur = noop,
	} = props

	return (
		<Button
			ariaLabel={ `${ caption } ${ query }` }
			additionalClass={ styles.wrapper }
			variant="neutral"
			type="submit"
			size="inherit"
			onBlur={ onBlur }
		>
			<span>
				{ caption }
			</span>

			<span className={ styles.query }>
				{ query }
			</span>

			<Icon
				name="arrowFull"
				width={ 4 }
				additionalClass={ styles.icon }
			/>
		</Button>
	)
}

export { HeaderTopBarSearchBarProductsLink }
