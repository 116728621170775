import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { camelCase, includes } from 'lodash'

import { Image } from '~/components/core/image'
import { CartPreviewDeliveryEcommerceAdditionalNote, CartPreviewDeliveryEcommerceCost, CartPreviewDeliveryEcommercePickupPoint, CartPreviewDeliveryEcommerceRealization, CartPreviewDeliveryEcommerceType, CartPreviewDeliveryModal, getDeliveryCaption, getDeliveryLogo, ICartPreviewDeliveryEcommerceLabelProps } from '~/components/cart/cartPreview'
import { getRadioId, HiddenRadioPrefix, RadioIcon } from '~/components/core/form'
import { useMozaic } from '~/hooks/mozaic'
import { PickupPointType } from '~/api/dataTypes/pickupPoint'
import { InPostGeowidget } from '~/components/core/inPostGeowidget'

import styles from './CartPreviewDeliveryEcommerceLabel.module.scss'

const CartPreviewDeliveryEcommerceLabel = (props: ICartPreviewDeliveryEcommerceLabelProps): ReactElement => {
	const { item, isChecked, error, onSetCourierNote, tmpCourierNote, pickupPointId, pickupPointData, onSetPickupPointId, onSetPickupPoint } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const { fullName, processingTime, price, description, name, courierNoteAvailable, id, availabilityInfo: { availabilityStatus }, isCourierWithCarryInDelivery, isPickupPoint, pickupPointTypes, isAllowed } = item
	const { imgSrc, imgWidth, imgHeight } = getDeliveryLogo(isPickupPoint, pickupPointTypes, isCourierWithCarryInDelivery)
	const isInPostPicked = includes(pickupPointTypes, 'INPOST' as PickupPointType)
	const caption = getDeliveryCaption(fullName)

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	const renderContent = useCallback((): ReactElement | null => {
		if (!isChecked) {
			return null
		}

		if (isInPostPicked) {
			return (
				<InPostGeowidget
					config="parcelCollect"
					pickupPointData={ pickupPointData }
					onSetPickupPoint={ onSetPickupPoint }
				/>
			)
		}

		if (isPickupPoint) {
			return (
				<CartPreviewDeliveryEcommercePickupPoint pickupPointId={ pickupPointId } onSetPickupPointId={ onSetPickupPointId } />
			)
		}

		if (courierNoteAvailable) {
			return (
				<CartPreviewDeliveryEcommerceAdditionalNote tmpCourierNote={ tmpCourierNote } onSetCourierNote={ onSetCourierNote } />
			)
		}

		return null
	}, [isChecked, isPickupPoint, tmpCourierNote, courierNoteAvailable, pickupPointTypes, pickupPointId, isInPostPicked, pickupPointData, onSetCourierNote, onSetPickupPointId])

	return (
		<div className={ wrapperClass }>
			<div className={ styles.content } id={ getRadioId(camelCase(item.fullName)) }>
				<HiddenRadioPrefix isDisabled={ !isAllowed } isChecked={ isChecked } />

				<RadioIcon isChecked={ isChecked } error={ error } />

				<div className={ styles.icon }>
					<Image
						alt={ name }
						src={ imgSrc }
						width={ imgWidth }
						height={ imgHeight }
						role="presentation"
					/>
				</div>

				<div className={ styles.labelData }>
					<CartPreviewDeliveryEcommerceType caption={ caption } description={ description } />

					<CartPreviewDeliveryEcommerceRealization processingTime={ processingTime } />

					<CartPreviewDeliveryEcommerceCost price={ price } />
				</div>
			</div>

			<div className={ styles.additionalContent }>
				{ isChecked && (
					<div className={ styles.description }>
						{ description }
					</div>
				) }

				{ renderContent() }

				<CartPreviewDeliveryModal
					availabilityStatus={ availabilityStatus }
					isDisabled={ !isAllowed }
					deliveryWayId={ id }
				/>
			</div>
		</div>
	)
}

export { CartPreviewDeliveryEcommerceLabel }
