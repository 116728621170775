import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { find, isEqual, isNil, isNull, isUndefined, map } from 'lodash'

import { IHandleCalculateProps, IUseCartPreviewCalculateData } from '~/hooks/cartPreviewCalculate'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useSare } from '~/hooks/sare'
import { cartCalculate, cartDeliveryCalculate, getCartData } from '~/actions/cart'
import { getCartItemQuantityList, getCartPreviewData, getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'
import { AppDispatch } from '~/state/store'
import { IRootState } from '~/state/types'
import { ICartItemData } from '~/api/dataTypes/cart'
import { IProductQuantity } from '~/api/dataTypes/product'

export const useCartPreviewCalculate = (): IUseCartPreviewCalculateData => {
	const { cart } = useSelector((state: IRootState) => getCartPreviewData(state))
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const cartItemQuantityList = useSelector((state: IRootState) => getCartItemQuantityList(state))
	const dispatch: AppDispatch = useDispatch()
	const { GA_addToCart } = useGoogleAnalytics()
	const { SARE_addToCart } = useSare()

	const { selectedDeliveryWayId, deliveryStore, promotionCode: selectedPromotionCode, pickupPoint } = cart

	const handleCalculate = useCallback(async (params: IHandleCalculateProps): Promise<void> => {
		const {
			itemQuantityList = cartItemQuantityList,
			promotionCode = isEcommerce ? selectedPromotionCode : '',
			clearDiscount = false,
		} = params

		const isDeliverySelected = !isNull(selectedDeliveryWayId)
		const isDeliveryStoreSelected = !isNull(deliveryStore)

		if (isDeliverySelected) {
			await dispatch(cartDeliveryCalculate({
				itemQuantityList,
				promotionCode,
				deliveryWayId: selectedDeliveryWayId,
				additionalDeliveryWayOptionSelected: isDeliveryStoreSelected,
				pickupPointId: !isNull(pickupPoint) ? pickupPoint.id : null,
				clearDiscount,
			}))
		} else {
			await dispatch(cartCalculate({
				itemQuantityList,
				promotionCode,
				clearDiscount,
			}))
			await dispatch(getCartData())
		}
	}, [cart, cartItemQuantityList, pickupPoint, selectedDeliveryWayId, selectedPromotionCode, isEcommerce])

	const handleToggleQuantity = useCallback(async (newQuantity: number, item: ICartItemData): Promise<void> => {
		const { itemId, productSimple } = item
		const { price: { basePrice, newPrice } } = productSimple
		const priceValue = (newPrice ? newPrice : basePrice) || 0

		if (!isNil(newQuantity)) {
			const itemQuantityList: IProductQuantity[] = map(cartItemQuantityList, (item: IProductQuantity) => {
				const { itemId: itemQuantityId } = item

				return itemQuantityId === itemId ? { itemId, quantity: newQuantity } : item
			})

			const activeItem: IProductQuantity | undefined = find(cartItemQuantityList, (quantityItem: IProductQuantity) => quantityItem.itemId === itemId)

			if (!isUndefined(activeItem)) {
				const quantityDifference = newQuantity - activeItem.quantity
				const totalPriceValue = Math.abs(priceValue * quantityDifference)

				if (!isEqual(quantityDifference, 0)) {
					if (quantityDifference > 0) {
						GA_addToCart({ product: productSimple, value: totalPriceValue, quantity: quantityDifference })
						SARE_addToCart(productSimple)
					}

					await handleCalculate({ itemQuantityList })
				}
			}
		}
	}, [cartItemQuantityList, cart])

	const handleRemoveDiscount = useCallback(async (): Promise<void> => {
		await handleCalculate({ promotionCode: '', clearDiscount: true })
	}, [cartItemQuantityList, handleCalculate])

	const handleSetPromotionCode = useCallback(async (promotionCode: string): Promise<void> => {
		await handleCalculate({ promotionCode })
	}, [handleCalculate])

	const handleRecalculate = useCallback(async (): Promise<void> => {
		await handleCalculate({})
	}, [handleCalculate])

	return {
		onRecalculate: handleRecalculate,
		onToggleQuantity: handleToggleQuantity,
		onRemoveDiscount: handleRemoveDiscount,
		onSetPromotionCode: handleSetPromotionCode,
	}
}
