import React, { ReactElement, useEffect, useState, useCallback } from 'react'
import ReactCalendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { find, isEmpty, isEqual, isNil, isUndefined, isNull } from 'lodash'

import { CartPreviewDeliveryPickupTransferTimeslotsDescription, CartPreviewDeliveryPickupTransferTimeslotsTiles, deliveryFromStoreTimeslotBuilder, ICartPreviewDeliveryPickupTransferTimeslotsProps, IFormattedTimeslot } from '~/components/cart/cartPreview'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { ICartDeliveryTransportFromStoreTimeslotsParameters } from '~/api/dataTypes/cart'
import { CalendarToggleMonth } from '~/components/core/calendar'
import { useDate } from '~/hooks/date'
import { getCartDeliveryTransportFromStoreTimeslotsData } from '~/actions/cart'

import styles from './CartPreviewDeliveryPickupTransferTimeslots.module.scss'

const CartPreviewDeliveryPickupTransferTimeslots = (props: ICartPreviewDeliveryPickupTransferTimeslotsProps): ReactElement => {
	const { categoryCode, onChangeDelivery } = props
	const { data: { deliveryGroups, cart: { deliveryStore, selectedDeliveryWayId } }, deliveryStoreTimeSlots } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const { t } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()
	const [formattedTimeslots, setFormattedTimeslots] = useState<IFormattedTimeslot[]>([])
	const [activeDayTiles, setActiveDayTiles] = useState<IFormattedTimeslot | undefined>(undefined)
	const { formatDate, getDateFormat, getDateFormatIso, formatDateUnix } = useDate()

	const { zipCode, timeslotList } = deliveryStoreTimeSlots
	const storeDelivery = deliveryGroups?.storeDelivery
	const selectedTimeSlot = !isNull(deliveryStore) ? deliveryStore.timeSlot : null
	const selectedCategory = !isNull(deliveryStore) ? deliveryStore.category : ''
	const selectedZipCode = !isNull(deliveryStore) ? deliveryStore.zipCode : ''

	const getTileContent = useCallback(({ date }: { date: Date }): string => (
		!isNil(find(formattedTimeslots, (timeslot: IFormattedTimeslot) => formatDateUnix(timeslot.date, getDateFormat()) === formatDate(date, getDateFormat()))) ? 'available' : ''
	), [formattedTimeslots])

	const getTileDisabled = useCallback(({ date }: { date: Date }): boolean => (
		isNil(find(formattedTimeslots, (timeslot: IFormattedTimeslot) => formatDateUnix(timeslot.date, getDateFormat()) === formatDate(date, getDateFormat())))
	), [formattedTimeslots])

	const handleClickDay = useCallback((value: Date | undefined): void => {
		if (!isEmpty(formattedTimeslots)) {
			const selectedDay = find(formattedTimeslots, (timeslot: IFormattedTimeslot) => formatDate(timeslot.date * 1000, getDateFormat()) === formatDate(value, getDateFormat()))
			setActiveDayTiles(selectedDay)
		}
	}, [formattedTimeslots])

	const renderTimeslotTiles = useCallback((): ReactElement | null => {
		if (!isUndefined(activeDayTiles)) {
			return (
				<>
					<CartPreviewDeliveryPickupTransferTimeslotsTiles
						timeslot={ activeDayTiles }
						deliveryStore={ deliveryStore }
						selectedDeliveryWayId={ selectedDeliveryWayId }
						deliveryStoreTimeSlots={ deliveryStoreTimeSlots }
						categoryCode={ categoryCode }
						onChangeDelivery={ onChangeDelivery }
					/>

					<CartPreviewDeliveryPickupTransferTimeslotsDescription />
				</>
			)
		}

		return null
	}, [activeDayTiles, deliveryStore, selectedDeliveryWayId, deliveryStoreTimeSlots, categoryCode])

	const getDeliveryTransportTimeslotData = useCallback(async () => {
		const params: ICartDeliveryTransportFromStoreTimeslotsParameters = {
			category: categoryCode,
			type: 'BASIC',
		}

		await dispatch(getCartDeliveryTransportFromStoreTimeslotsData(params))

		if (isEqual(categoryCode, selectedCategory) && storeDelivery?.selected && isEqual(selectedZipCode, zipCode)) {
			await setActiveDayTiles(!isNull(selectedTimeSlot) ? {
				date: selectedTimeSlot.date,
				timePeriod: [{
					fromTime: selectedTimeSlot.fromTime,
					toTime: selectedTimeSlot.toTime,
				}],
			} : undefined)
		}
	}, [categoryCode, selectedCategory, storeDelivery, selectedTimeSlot, selectedZipCode, zipCode])

	useEffect(() => {
		(async () => {
			await getDeliveryTransportTimeslotData()
		})()
	}, [categoryCode])

	useEffect(() => {
		(async () => {
			await setFormattedTimeslots(deliveryFromStoreTimeslotBuilder(timeslotList))
		})()
	}, [timeslotList])

	useEffect(() => {
		handleClickDay(!isUndefined(activeDayTiles) ? new Date(formatDateUnix(activeDayTiles.date, getDateFormatIso())) : undefined)
	}, [selectedTimeSlot, formattedTimeslots])

	useEffect(() => {
		(async () => {
			await setActiveDayTiles(undefined)
		})()
	}, [zipCode])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.date }>
				{ t('preview.delivery.groups.storeDelivery.date') }
			</div>

			<ReactCalendar
				value={ !isUndefined(activeDayTiles) ? new Date(formatDateUnix(activeDayTiles.date, getDateFormatIso())) : undefined }
				className={ styles.calendar }
				prevLabel={ <CalendarToggleMonth type="prev" /> }
				nextLabel={ <CalendarToggleMonth type="next" /> }
				prev2Label={ null }
				next2Label={ null }
				tileClassName={ getTileContent }
				tileDisabled={ getTileDisabled }
				locale="pl-PL"
				onClickDay={ handleClickDay }
			/>

			{ renderTimeslotTiles() }
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTimeslots }
