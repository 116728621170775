import React, { ReactElement } from 'react'

import { useFooterNavigation } from '~/hooks/footerNavigation'
import { IFooterProps, FooterWrapper, FooterButtons, FooterSections, AdditionalFooterWrapper } from '~/components/core/layout'
import { ScrollToTop } from '~/components/core/scrollToTop'
import { Newsletter } from '~/components/core/newsletter'

import styles from './Footer.module.scss'

const Footer = (props: IFooterProps): ReactElement => {
	const { hideNewsletter, AdditionalCustomFooter } = props
	const { apps, banners, buttons, sections } = useFooterNavigation()

	return (
		<section className={ styles.wrapper }>
			<AdditionalFooterWrapper>
				{ AdditionalCustomFooter }

				{ !hideNewsletter && <Newsletter /> }
			</AdditionalFooterWrapper>

			<FooterWrapper>
				<FooterButtons buttons={ buttons } />

				<FooterSections
					sections={ sections }
					apps={ apps }
					banners={ banners }
				/>
			</FooterWrapper>

			<ScrollToTop />
		</section>
	)
}

export { Footer }
