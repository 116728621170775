import React, { ReactElement } from 'react'

import { ILayoutOrdersStatusWrapperProps } from '~/components/core/layout'

const LayoutOrdersStatusWrapper = (props: ILayoutOrdersStatusWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div>
			{ children }
		</div>
	)
}

export { LayoutOrdersStatusWrapper }
