import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import { isEmpty, isNull, noop } from 'lodash'

import { IRootState } from '~/state/types'
import { getAriaLabelText, IPreferredStoreButtonProps } from '~/components/core/preferredStore'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'

import styles from './PreferredStoreButton.module.scss'

const PreferredStoreButton = (props: IPreferredStoreButtonProps): ReactElement => {
	const {
		color, additionalClass, onOpen, isOpen,
		onClose = noop,
		buttonRef = undefined,
		dataTestId = '',
	} = props
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { t } = useTranslation(['common', 'stores'])
	const [isMounted, setIsMounted] = useState<boolean>(false)
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')

	useEffect(() => {
		setIsMounted(true)

		return () => setIsMounted(false)
	}, [])

	const handleOnBlur = useCallback((): void => {
		if (isShiftTabPressed) {
			onClose()
		}
	}, [onClose, isShiftTabPressed])

	const renderContent = useCallback((): ReactElement => {
		if (!isNull(preferredStore) && isMounted) {
			return (
				<>
					{ `${ t('myStore', { ns: 'stores' }) }:` }

					<br />

					{ preferredStore.name }
				</>
			)
		}

		return (
			<>
				{ t('preferredShop.choice') }

				<br />

				{ t('preferredShop.store') }
			</>
		)
	}, [preferredStore, isMounted])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles[color]]: color,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<Button
			hasAriaPopup
			isAriaExpanded={ isOpen }
			buttonRef={ buttonRef }
			variant="neutral"
			size="inherit"
			additionalClass={ wrapperClass }
			dataTestId={ dataTestId }
			ariaLabel={ getAriaLabelText(isMounted, preferredStore?.name, t) }
			onClick={ onOpen }
			onBlur={ handleOnBlur }
		>
			<Icon
				name="pinFull"
				color={ color }
				width={ 18 }
				height={ 27 }
				additionalClass={ styles.icon }
			/>

			<span className={ styles.label }>
				{ renderContent() }
			</span>
		</Button>
	)
}

export { PreferredStoreButton }
