import React, { ReactElement, useContext } from 'react'

import { IconContext } from '~/providers/iconProvider'

const IconPlaceholder = (): ReactElement => {
	const { width, height } = useContext(IconContext)

	return <div style={ { width, height } } />
}

export { IconPlaceholder }
