import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'

import { ICartPreviewRemoveProductsModalProps } from '~/components/cart/cartPreview'
import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { useLogError } from '~/hooks/logError'

import styles from './CartPreviewRemoveProductsModal.module.scss'

const CartPreviewRemoveProductsModal = (props: ICartPreviewRemoveProductsModalProps): ReactElement => {
	const { isOpen, onClose, onRemoveProducts } = props
	const { t } = useTranslation(['cart', 'common'])
	const [isPending, setIsPending] = useToggle(false)
	const { sendLogError } = useLogError()

	const handleConfirm = useCallback(async (): Promise<void> => {
		setIsPending(true)

		try {
			await onRemoveProducts()
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [onRemoveProducts])

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
		>
			<div className={ styles.header }>
				{ t('preview.table.actions.removeModal.header', { ns: 'cart' }) }
			</div>

			<div className={ styles.question }>
				{ t('preview.table.actions.removeModal.question', { ns: 'cart' }) }
			</div>

			<div className={ styles.actions }>
				<Button
					variant="secondary"
					text={ t('preview.table.actions.removeModal.confirm', { ns: 'cart' }) }
					additionalClass={ styles.button }
					isLoading={ isPending }
					onClick={ handleConfirm }
				/>

				<Button
					isReverse
					variant="secondary"
					text={ t('cancel', { ns: 'common' }) }
					additionalClass={ styles.button }
					onClick={ onClose }
				/>
			</div>
		</Modal>
	)
}

export { CartPreviewRemoveProductsModal }
