import React, { ReactElement, useContext, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { useTranslation } from 'next-i18next'
import axios, { AxiosError } from 'axios'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'

import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { useModal } from '~/hooks/modal'
import { useMounted } from '~/hooks/mounted'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { UserContext } from '~/providers/userProvider'
import { vars } from '~/statics'
import { Icon, ICON_TYPE } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { IWishListProps } from '~/components/core/wishList'
import { WishListModal } from '~/components/core/wishListModal'
import { addToFavouriteList, removeFromFavouriteList } from '~/actions/favouriteList'
import { useSare } from '~/hooks/sare'

import styles from './WishList.module.scss'

const WishList = (props: IWishListProps): ReactElement | null => {
	const {
		product,
		type = 'product',
		iconSize = 18,
		additionalClass = '',
	} = props
	const { simpleList: { productsQuantityMap } } = useSelector((state: IRootState) => state.favouriteList, shallowEqual)
	const { t } = useTranslation(['common'])
	const [isHover, setIsHover] = useToggle(false)
	const { isLogged } = useContext(UserContext)
	const dispatch: AppDispatch = useDispatch()
	const { newAlert } = useAlert()
	const { getErrorMessage } = useFormError()
	const { isMounted } = useMounted()
	const { isOpen, handleOpen, handleClose } = useModal('FavoritesNotLogged')
	const { sendLogError } = useLogError()
	const { SARE_addToWishList } = useSare()

	const { lmReference } = product

	const iconColor: keyof typeof vars.colors = type === 'inspiration' ? 'tealLight' : 'green'
	const isItemInList = !isUndefined(productsQuantityMap[lmReference])
	const iconName: keyof typeof ICON_TYPE = isItemInList ? 'heartFull' : 'heartEmpty'
	const iconWidth = isHover ? (iconSize + 4) : iconSize

	const wrapperClass = classNames(styles.wrapper, {
		[styles[type]]: type,
		[additionalClass]: !isEmpty(additionalClass),
	})

	const handleToggleActivity = useCallback(async (): Promise<void> => {
		try {
			if (!isLogged) {
				handleOpen()
			} else if (isItemInList) {
				await dispatch(removeFromFavouriteList(lmReference))
			} else {
				await dispatch(addToFavouriteList({ lmReference }))
				SARE_addToWishList(product)
			}
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code } = error.response.data

				newAlert('danger', getErrorMessage(code), 5000)
			}

			sendLogError(e)
		}
	}, [isItemInList, lmReference, product, isLogged])

	const handleMouseEnter = useCallback(() => {
		setIsHover(true)
	}, [])

	const handleMouseLeave = useCallback(() => {
		setIsHover(false)
	}, [])

	if (!isMounted) {
		return null
	}

	return (
		<>
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ wrapperClass }
				ariaLabel={ t('addToFavorites') }
				onMouseEnter={ handleMouseEnter }
				onMouseLeave={ handleMouseLeave }
				onClick={ handleToggleActivity }
			>
				<Icon
					name={ iconName }
					color={ iconColor }
					width={ iconWidth }
					additionalClass={ styles.icon }
				/>
			</Button>

			<WishListModal isOpen={ isOpen } onClose={ handleClose } />
		</>
	)
}

export { WishList }
