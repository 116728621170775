import { useEffect, useCallback, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useToggle } from 'react-use'
import { isEqual, isNull, isEmpty } from 'lodash'

import { IUseCartPreviewNextValidationData } from '~/hooks/cartPreviewNextValidation'
import { getChangeCartItemQuantityList } from '~/state/reducers/cartPreviewReducer'
import { IRootState } from '~/state/types'
import { isCashOnDelivery, isPickupDelivery } from '~/components/cart/cartPreview'

export const useCartPreviewNextValidation = (): IUseCartPreviewNextValidationData => {
	const { data, activeDeliveryGroup } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const changeCartItemQuantityList = useSelector((state: IRootState) => getChangeCartItemQuantityList(state))
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
	const [tooltipInfo, setTooltipInfo] = useState<string>('')
	const [isProductsNotAvailable, setProductsNotAvailable] = useToggle(!isEmpty(changeCartItemQuantityList))
	const { t } = useTranslation(['cart'])

	const { cart, deliveryGroups } = data

	const { pickupInStore, selectedPaymentWayId, selectedDeliveryWayId, deliveryStore, pickupPoint } = cart
	const cashOnDelivery = !isNull(deliveryGroups) ? isCashOnDelivery(activeDeliveryGroup, selectedDeliveryWayId, deliveryGroups.delivery.deliveryList) : false
	const pickupDelivery = !isNull(deliveryGroups) ? isPickupDelivery(activeDeliveryGroup, selectedDeliveryWayId, deliveryGroups.delivery.deliveryList) : false

	const isPaymentValid = useCallback((): boolean => (
		!isNull(selectedPaymentWayId) || cashOnDelivery
	), [selectedPaymentWayId, cashOnDelivery])

	const isDeliveryValid = useCallback((): boolean => (
		!isNull(selectedDeliveryWayId)
	), [selectedDeliveryWayId])

	const isDeliveryFromStoreValid = useCallback((): boolean => {
		const isDeliveryFromStoreSelected = isEqual(activeDeliveryGroup, 'STORE_DELIVERY')

		return !isDeliveryFromStoreSelected || (isDeliveryFromStoreSelected && !isNull(deliveryStore))
	}, [activeDeliveryGroup, pickupInStore, deliveryStore])

	const isDeliveryPickupPointValid = useCallback((): boolean => (
		!pickupDelivery || (pickupDelivery && !isNull(pickupPoint))
	), [pickupDelivery, pickupPoint])

	const handleSetButtonDisabled = useCallback((): void => {
		setButtonDisabled(!isPaymentValid() || !isDeliveryValid() || !isDeliveryFromStoreValid() || !isDeliveryPickupPointValid())
	}, [isPaymentValid, selectedDeliveryWayId, isDeliveryFromStoreValid, isDeliveryPickupPointValid])

	const handleSetTooltipInfo = useCallback((): void => {
		if (isNull(selectedPaymentWayId) && !isDeliveryValid()) {
			setTooltipInfo(t('preview.aside.summary.button.tooltip.deliveryAndPayment'))

			return
		}

		if (!isDeliveryValid()) {
			setTooltipInfo(t('preview.aside.summary.button.tooltip.delivery'))

			return
		}

		if (isNull(selectedPaymentWayId)) {
			setTooltipInfo(t('preview.aside.summary.button.tooltip.payment'))

			return
		}

		if (pickupDelivery && isNull(pickupPoint)) {
			setTooltipInfo(t('preview.aside.summary.button.tooltip.deliveryPickupPoint'))

			return
		}

		if (selectedDeliveryWayId && isNull(pickupInStore)) {
			setTooltipInfo(t('preview.aside.summary.button.tooltip.pickup'))

			return
		}

		if (selectedDeliveryWayId && isNull(deliveryStore)) {
			setTooltipInfo(t('preview.aside.summary.button.tooltip.deliveryFromStore'))

			return
		}
	}, [selectedPaymentWayId, selectedDeliveryWayId, pickupInStore, deliveryStore, pickupDelivery, pickupPoint])

	useEffect(() => {
		handleSetButtonDisabled()
		handleSetTooltipInfo()
	}, [cart, activeDeliveryGroup, cashOnDelivery])

	useEffect(() => {
		setProductsNotAvailable(!isEmpty(changeCartItemQuantityList))
	}, [changeCartItemQuantityList])

	return {
		buttonDisabled,
		tooltipInfo,
		isProductsNotAvailable,
	}
}
