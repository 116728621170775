import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { IProductBlockCompareButtonProps } from '~/components/core/productBlock'
import { Icon, ICON_TYPE } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { getComparisonBlockProduct, getIsProductInComparison } from '~/utils/comparison'
import { addComparisonItem, removeComparisonItem } from '~/actions/comparison'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'

import styles from './ProductBlockCompareButton.module.scss'

const ProductBlockCompareButton = (props: IProductBlockCompareButtonProps): ReactElement | null => {
	const {
		product,
		isAbleToCompare = false,
	} = props
	const { products } = useSelector((state: IRootState) => state.comparison, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const { t } = useTranslation(['common'])

	const { id } = product
	const isProductInCompare = getIsProductInComparison(id, products)
	const icon: keyof typeof ICON_TYPE = isProductInCompare ? 'compareFull' : 'compare'
	const title = isProductInCompare ? t('inCompare') : t('compare')

	const handleAddToCompare = useCallback(async (): Promise<void> => {
		if (!isProductInCompare) {
			await dispatch(addComparisonItem(getComparisonBlockProduct(product)))
		}
	}, [isProductInCompare, product])

	const handleRemoveFromCompare = useCallback(async (): Promise<void> => {
		if (isProductInCompare) {
			const { id } = getComparisonBlockProduct(product)

			await dispatch(removeComparisonItem(id))
		}
	}, [isProductInCompare, product])

	const handleOnClick = isProductInCompare ? handleRemoveFromCompare : handleAddToCompare

	if (!isAbleToCompare) {
		return null
	}

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ styles.wrapper }
			ariaLabel={ title }
			onClick={ handleOnClick }
		>
			<div className={ styles.iconWrapper }>
				<Icon
					name={ icon }
					color="green"
					width={ 16 }
					additionalClass={ styles.icon }
				/>
			</div>

			<span className={ styles.title }>{ title }</span>
		</Button>
	)
}

export { ProductBlockCompareButton }
