import { TFunction } from 'next-i18next'

export const getLysLoyaltyLegendText = (isEcommerce: boolean, isPercent: boolean, t: TFunction): string => {
	if (isEcommerce) {
		return t('preview.aside.lysDiscount.legend.selectedCode')
	}

	if (isPercent) {
		return t('preview.aside.lysDiscount.legend.selectedCoupon.percent.info')
	}

	return t('preview.aside.lysDiscount.legend.selectedCoupon.fixed.info')
}
