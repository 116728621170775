import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { isEmpty, isNull } from 'lodash'

import { usePrice } from '~/hooks/price'
import { IProductPriceProps } from '~/components/core/productPrice'

import styles from './ProductPrice.module.scss'

const ProductPrice = (props: IProductPriceProps): ReactElement => {
	const {
		price,
		priceStrikethrough = null,
		priceUnit = '',
		pricePromoLabel = '',
		additionalClass = '',
		size = '',
		priceId = '',
		omnibusPrice = null,
		promotionLabelCode = null,
		theme = {},
	} = props
	const { priceFormat } = usePrice()
	const { t } = useTranslation(['common'])

	const wrapperClass = classNames(styles.wrapper, {
		[styles[size]]: !isEmpty(size),
		[additionalClass]: !isEmpty(additionalClass),
	}, theme.wrapper)

	const baseClass = classNames(styles.base, {
		[styles.promotional]: !isNull(priceStrikethrough) || promotionLabelCode === 'SUPER_OFFER',
	})

	const omnibusPriceClass = classNames(styles.label, {
		[styles.omnibusPrice]: !isNull(omnibusPrice),
	})

	const saleClass = classNames(styles.sale, theme.priceSaleWrapper)

	return (
		<div className={ wrapperClass }>
			<div className={ baseClass }>
				<span
					// eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline
					{ ...!isEmpty(priceId) && { id: priceId } }
					className={ styles.price }
				>
					{ priceFormat(price) }
				</span>

				{ !isEmpty(priceUnit) && (
					<span className={ styles.unit }>/{ ' ' }{ priceUnit }</span>
				) }
			</div>

			{ !isNull(priceStrikethrough) && (
				<div className={ saleClass }>
					<div className={ styles.strikethrough }>
						{ `${ priceFormat(priceStrikethrough) } ${ !isEmpty(priceUnit) ? ` / ${ priceUnit }` : '' }` }
					</div>

					{ !isNull(omnibusPrice) && <div className={ omnibusPriceClass }>{ t('cheapestPrice') }</div> }

					{ !isEmpty(pricePromoLabel) && isNull(omnibusPrice) && <div className={ styles.label }>{ pricePromoLabel }</div> }
				</div>
			) }
		</div>
	)
}

export { ProductPrice }
