import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { ICloseButtonProps } from '~/components/core/closeButton'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CloseButton.module.scss'

const CloseButton = (props: ICloseButtonProps): ReactElement => {
	const {
		onClose,
		showLabel = true,
		additionalContextAriaLabel = '',
		dataTestId = '',
	} = props
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const iconColor = shouldUseMozaic ? 'black' : 'navy'

	return (
		<Button
			size="inherit"
			variant="neutral"
			additionalClass={ styles.closeButton }
			ariaLabel={ `${ t('close') } ${ additionalContextAriaLabel }` }
			dataTestId={ dataTestId }
			onClick={ onClose }
		>
			<Icon
				name="close"
				color={ iconColor }
				width={ 17 }
				additionalClass={ styles.icon }
			/>

			{ showLabel && (
				<div className={ styles.label }>
					{ t('close') }
				</div>
			) }
		</Button>
	)
}

export { CloseButton }
