import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { map, isNull } from 'lodash'

import { CartPreviewCheckPriceHeader } from '~/components/cart/cartPreview/cartPreviewCheckPrice/cartPreviewCheckPriceItems/cartPreviewCheckPriceHeader'
import { CartPreviewCheckPriceItem, ICartPreviewCheckPriceItemsProps } from '~/components/cart/cartPreview/cartPreviewCheckPrice'
import { IGetCartCheckPriceSelectionItemList } from '~/api/dataTypes/cart'

import styles from './CartPreviewCheckPriceItems.module.scss'

const CartPreviewCheckPriceItems = (props: ICartPreviewCheckPriceItemsProps): ReactElement => {
	const { data } = props
	const { t } = useTranslation(['cart'])

	const { itemList, changeInfo: { type, storeName } } = data

	const typeText = !isNull(storeName) ? ` ${ type }` : ` ${ type }:`
	const storeNameText = !isNull(storeName) ? ` ${ storeName }:` : ''

	const renderProductTable = useCallback((): ReactElement => (
		<>
			<CartPreviewCheckPriceHeader />

			{ map(itemList, (item: IGetCartCheckPriceSelectionItemList) => (
				<CartPreviewCheckPriceItem
					key={ item.productSimple.id }
					item={ item }
				/>
			)) }
		</>
	), [itemList])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption }>
				<span>
					{ t('preview.checkPrice.deliveryTypePrefix') }
				</span>

				<span className={ styles.type }>
					{ typeText }
				</span>

				<span className={ styles.storeName }>
					{ storeNameText }
				</span>
			</div>

			{ renderProductTable() }
		</div>
	)
}

export { CartPreviewCheckPriceItems }
