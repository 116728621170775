import React, { ReactElement, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars-2'
import { lte, size } from 'lodash'

import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { CART_PREVIEW_MAX_COUPONS_WITHOUT_SCROLL, CART_PREVIEW_SCROLL_AUTO_HEIGHT, ICartPreviewDiscountLysLoyaltyCouponsProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons'
import { getCartPreviewData, getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'

import { CartPreviewDiscountLysCouponsList } from './cartPreviewDiscountLysCouponsList'

import styles from './CartPreviewDiscountLysLoyaltyCoupons.module.scss'

const CartPreviewDiscountLysLoyaltyCoupons = (props: ICartPreviewDiscountLysLoyaltyCouponsProps): ReactElement | null => {
	const { currentRewards } = props
	const { cart: { promotionCodeUsed: isPromotionCodeUsed } } = useSelector(getCartPreviewData, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const { isEditMode } = useContext(LysLoyaltyProviderContext)

	if (isEcommerce && !isEditMode) {
		return null
	}

	const shouldRenderWithoutScroll = lte(size(currentRewards), CART_PREVIEW_MAX_COUPONS_WITHOUT_SCROLL)

	if (shouldRenderWithoutScroll) {
		return (
			<CartPreviewDiscountLysCouponsList currentRewards={ currentRewards } shouldDisplaySelectedRewardInfo={ isPromotionCodeUsed && !isEditMode } />
		)
	}

	return (
		<Scrollbars
			universal
			autoHeight
			autoHeightMax={ CART_PREVIEW_SCROLL_AUTO_HEIGHT }
			className={ styles.wrapper }
		>
			<CartPreviewDiscountLysCouponsList currentRewards={ currentRewards } theme={ { wrapper: styles.scrollbar } } />
		</Scrollbars>
	)
}

export { CartPreviewDiscountLysLoyaltyCoupons }
