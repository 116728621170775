import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isNil } from 'lodash'

import { ICartCustomerOtherContactDataProps } from '~/components/cart/cartCustomer'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerOtherContactData.module.scss'

const CartCustomerOtherContactData = (props: ICartCustomerOtherContactDataProps): ReactElement => {
	const { otherContact } = props
	const { t } = useTranslation(['cart', 'common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { firstName, companyName, lastName, email, phone } = otherContact

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<span>
				{ `${ firstName } ${ lastName }` }
			</span>

			{ !isNil(companyName) && (
				<span>
					{ companyName }
				</span>
			) }

			<span>
				{ email }
			</span>

			<span>
				{ t('phoneNumberPrefix', { ns: 'common' }) }{ ' ' }{ phone }
			</span>
		</div>
	)
}

export { CartCustomerOtherContactData }
