import { isUndefined } from 'lodash'
import { TFunction } from 'next-i18next'

export const getAriaLabelText = (isMounted: boolean, storeName: string | undefined, t: TFunction): string => {
	if (!isUndefined(storeName) && isMounted) {
		return `${ t('myStore', { ns: 'stores' }) }: ${ storeName }`
	}

	return `${ t('preferredShop.choice') } ${ t('preferredShop.store') }`
}
