import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './CartPreviewDeliverySplitProductsHeader.module.scss'

const CartPreviewDeliverySplitProductsHeader = (): ReactElement => {
	const { t } = useTranslation(['cart'])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.product }>
				{ t('preview.table.header.product') }
			</div>

			<div className={ styles.price }>
				{ t('preview.table.header.price') }
			</div>

			<div className={ styles.quantity }>
				{ t('preview.table.header.quantity') }
			</div>

			<div className={ styles.summaryPrice }>
				{ t('preview.table.header.summaryPrice') }
			</div>
		</div>
	)
}

export { CartPreviewDeliverySplitProductsHeader }
