import React, { ReactElement } from 'react'

import { Loader } from '~/components/core/loader'

import styles from './CartCustomerDataLoader.module.scss'

const CartCustomerDataLoader = (): ReactElement => {
	return (
		<div className={ styles.wrapper }>
			<Loader />
		</div>
	)
}

export { CartCustomerDataLoader }
