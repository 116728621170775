import React, { ReactElement, useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import classNames from 'classnames'
import { isNull, isEqual } from 'lodash'

import { useModal } from '~/hooks/modal'
import { useMozaic } from '~/hooks/mozaic'
import { PreferredStoreModal } from '~/components/core/preferredStore'
import { CartPreviewPreferredStoreBlock, ICartPreviewPreferredStoreProps } from '~/components/cart/cartPreview'
import { IRootState } from '~/state/types'

import styles from './CartPreviewPreferredStore.module.scss'

const CartPreviewPreferredStore = (props: ICartPreviewPreferredStoreProps): ReactElement | null => {
	const { preferredStore } = props
	const { data: { cart: { selectedDeliveryWayType } }, isStepZero } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const { isOpen, handleOpen, handleClose } = useModal('PreferredStore')
	const { getShouldUseMozaicFlag } = useMozaic()

	const isDeliverySelected = !isEqual(selectedDeliveryWayType, 'PICKUP_IN_STORE') && !isEqual(selectedDeliveryWayType, 'STORE_DELIVERY')
	const isSectionNotVisible = isDeliverySelected && !isNull(preferredStore) && !isStepZero

	const handleChangeStore = useCallback(() => {
		handleOpen()
	}, [handleOpen])

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	if (isSectionNotVisible) {
		return null
	}

	return (
		<div className={ wrapperClass }>
			<CartPreviewPreferredStoreBlock
				preferredStore={ preferredStore }
				isMozaic={ shouldUseMozaic }
				onClick={ handleChangeStore }
			/>

			<PreferredStoreModal
				isWithCheckPrice
				canForceChange={ !isNull(preferredStore) }
				isOpen={ isOpen }
				onClose={ handleClose }
			/>
		</div>
	)
}

export { CartPreviewPreferredStore }
