import { vars } from '~/statics'

const Console = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	log: (message: any, ...optionalParams: any[]) => {
		if (vars.isDeveloperMode) {
			console.log(message, ...optionalParams)
		}
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	info: (message: any, ...optionalParams: any[]) => {
		if (vars.isDeveloperMode) {
			console.info(message, ...optionalParams)
		}
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	warn: (message: any, ...optionalParams: any[]) => {
		if (vars.isDeveloperMode) {
			console.warn(message, ...optionalParams)
		}
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: (message: any, ...optionalParams: any[]) => {
		console.error(message, ...optionalParams)
	},
}

export { Console as console }
