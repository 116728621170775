import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { HeaderTopBarSearchBarHistoryList, HeaderTopBarSearchBarHistoryReset, HeaderTopBarSearchBarTitleTile, IHeaderTopBarSearchBarHistoryProps } from '~/components/core/layout'
import { sessionStorageRemove } from '~/utils/storage'
import { S_KEY_SEARCH_HISTORY } from '~/hooks/headerSearch'

import styles from './HeaderTopBarSearchBarHistory.module.scss'

const HeaderTopBarSearchBarHistory = (props: IHeaderTopBarSearchBarHistoryProps): ReactElement | null => {
	const {
		history, onForceRerenderResults, onHideSuggestions,
		theme = {},
	} = props
	const { t } = useTranslation(['header'])

	const handleReset = useCallback((): void => {
		sessionStorageRemove(S_KEY_SEARCH_HISTORY)
		onForceRerenderResults()
	}, [history])

	if (isEmpty(history)) {
		return null
	}

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<>
			<div className={ wrapperClass }>
				<HeaderTopBarSearchBarTitleTile title={ t('search.searchHistory') } additionalClass={ theme.header } />

				<HeaderTopBarSearchBarHistoryReset
					additionalClass={ theme.clearButton }
					handleBlur={ onHideSuggestions }
					onReset={ handleReset }
				/>
			</div>

			<HeaderTopBarSearchBarHistoryList history={ history } />
		</>
	)
}

export { HeaderTopBarSearchBarHistory }
