import React, { ReactElement } from 'react'

import { INewsletterIconProps } from '~/components/core/newsletter'
import { Icon } from '~/components/core/icon'

import styles from './NewsletterIcon.module.scss'

const NewsletterIcon = (props: INewsletterIconProps): ReactElement => {
	const { icon, label, width, height } = props

	return (
		<div className={ styles.wrapper }>
			<Icon
				name={ icon }
				color="gray"
				width={ width }
				height={ height }
			/>

			<span className={ styles.label }>
				{ label }
			</span>
		</div>
	)
}

export { NewsletterIcon }
