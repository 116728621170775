import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isFunction } from 'lodash'

import { IArrowLinkProps, ArrowLinkContent } from '~/components/core/arrowLink'
import { Button } from '~/components/core/button'

import styles from './ArrowLink.module.scss'

const ArrowLink = (props: IArrowLinkProps): ReactElement => {
	const {
		text, ariaLabelledBy, dataTestId,
		additionalClass = '',
		isReverse = false,
		theme = {},
		color = undefined,
		onClick = undefined,
	} = props

	const buttonClass = classNames(styles.wrapper, theme.button)

	if (isFunction(onClick)) {
		return (
			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ buttonClass }
				ariaLabel={ text }
				ariaLabelledBy={ ariaLabelledBy }
				dataTestId={ dataTestId }
				onClick={ onClick }
			>
				<ArrowLinkContent
					isReverse={ isReverse }
					label={ text }
					theme={ theme }
					color={ color }
					additionalClass={ additionalClass }
				/>
			</Button>
		)
	}

	return (
		<ArrowLinkContent
			isReverse={ isReverse }
			label={ text }
			theme={ theme }
			color={ color }
			additionalClass={ additionalClass }
		/>
	)
}

export { ArrowLink }
