import { useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { join, map } from 'lodash'

import { IUseFormErrorData } from '~/hooks/formError'
import { FormErrorCodeType } from '~/api/dataTypes/form'

export const useFormError = (): IUseFormErrorData => {
	const { t, i18n } = useTranslation(['form'])

	const getErrorMessage = useCallback((errorCode: FormErrorCodeType): string => {
		switch (errorCode) {
			case 'ACCOUNT_TEMPORARY_BLOCKED':
				return t('codes.accountTemporaryBlocked')
			case 'ACTIVATE_CUSTOMER_PARAMS_INVALID':
				return t('codes.activateCustomerParamsInvalid')
			case 'ADDRESS_NOT_FOUND':
				return t('codes.addressNotFound')
			case 'AUTH_SERVICE_UNAVAILABLE':
				return t('codes.authServiceUnavailable')
			case 'BAD_REQUEST':
				return t('codes.badRequest')
			case 'CHANGE_PASSWORD_PARAMS_INVALID':
				return t('codes.changePasswordParamsInvalid')
			case 'CUSTOMER_TYPE_MUST_BE_PRIVATE_OR_COMPANY':
				return t('codes.customerTypeMustBePrivateOrCompany')
			case 'DATE_MUST_BE_IN_CORRECT_FORMAT':
				return t('codes.dateMustBeInCorrectFormat')
			case 'EDIT_CUSTOMER_PARAMS_INVALID':
				return t('codes.editCustomerParamsInvalid')
			case 'GENDER_MUST_BE_MALE_FEMALE_OR_UNKNOWN':
				return t('codes.genderMustBeMaleFemaleOrUnknown')
			case 'ILLEGAL_ARGUMENT':
				return t('codes.illegalArgument')
			case 'INVALID_DATA':
				return t('codes.invalidData')
			case 'INVALID_NIP_NUMBER':
				return t('codes.nipNumberFormat')
			case 'INVALID_REQUEST_PARAMS':
				return t('codes.invalidRequestParams')
			case 'LOGIN_ALREADY_EXISTS':
				return t('codes.loginAlreadyExists')
			case 'LOGIN_TAKEN_PARAMS_INVALID':
				return t('codes.loginTakenParamsInvalid')
			case 'MUST_BE_BETWEEN_1900_01_01_AND_PRESENT':
				return t('codes.mustBeBetween19000101AndPresent')
			case 'MUST_BE_TWO_CHAR_ISO_COUNTRY':
				return t('codes.mustBeTwoCharIsoCountry')
			case 'MUST_BE_TWO_CHAR_ISO_LANGUAGE':
				return t('codes.mustBeTwoCharIsoLanguage')
			case 'MUST_BE_VALID_EMAIL':
				return t('codes.mustBeValidEmail')
			case 'MUST_BE_VALID_POLISH_NIP':
				return t('codes.mustBeValidPolishNip')
			case 'MUST_CONTAIN_DIGITS':
				return t('codes.mustContainDigits')
			case 'MUST_CONTAIN_LETTERS':
				return t('codes.mustContainLetters')
			case 'MUST_CONTAIN_LOWERCASE_LETTER':
				return t('codes.mustContainLowercaseLetter')
			case 'MUST_CONTAIN_ONLY_ASCII_CHARACTERS':
				return t('codes.mustContainOnlyAsciiCharacters')
			case 'MUST_CONTAIN_ONLY_DIGITS':
				return t('codes.mustContainOnlyDigits')
			case 'MUST_CONTAIN_PLUS_AND_DIGITS_ONLY':
				return t('codes.mustContainPlusAndDigitsOnly')
			case 'MUST_CONTAIN_UPPERCASE_LETTER':
				return t('codes.mustContainUppercaseLetter')
			case 'MUST_HAVE_ONLY_DIGIT_ASCII_LETTER_DOT_COLON_DASH_OR_SPACE':
				return t('codes.mustHaveOnlyDigitAsciiLetterDotColonDashOrSpace')
			case 'MUST_HAVE_ONLY_LETTER_DASH_OR_APOSTROPHE':
				return t('codes.mustHaveOnlyLetterDashOrApostrophe')
			case 'MUST_HAVE_ONLY_LETTER_DASH_SPACE_DOT_OR_APOSTROPHE':
				return t('codes.mustHaveOnlyLetterDashSpaceDotOrApostrophe')
			case 'MUST_HAVE_ONLY_LETTER_DIGITS_AND_SPECIAL_SIGNS':
				return t('codes.mustHaveOnlyLetterDigitsAndSpecialSigns')
			case 'MUST_HAVE_ONLY_LETTER_DIGITS_AND_SPECIAL_SIGNS_NO_SPACE':
				return t('codes.mustHaveOnlyLetterDigitsAndSpecialSignsNoSpace')
			case 'MUST_NOT_BE_NULL':
				return t('codes.mustNotBeNull')
			case 'MUST_NOT_BE_BLANK':
				return t('codes.mustNotBeBlank')
			case 'NEW_PASSWORD_MUST_NOT_BE_EQUAL_TO_OLD':
				return t('codes.newPasswordMustNotBeEqualToOld')
			case 'NEWSLETTER_ALREADY_SUBSCRIBED':
				return t('codes.newsletterAlreadySubscribed')
			case 'ONE_ACCOUNT_EMPTY_RESPONSE_BODY':
				return t('codes.oneAccountEmptyResponseBody')
			case 'ONLY_COMPANY_MUST_HAVE_NIP_AND_CORPORATE_NAME':
				return t('codes.onlyCompanyMustHaveNipAndCorporateName')
			case 'PESEL_MUST_BE_IN_CORRECT_FORMAT':
				return t('codes.peselMustBeInCorrectFormat')
			case 'REACH_FIVE_EMPTY_BODY':
				return t('codes.reachFiveEmptyBody')
			case 'RECOVER_PASSWORD_PARAMS_INVALID':
				return t('codes.recoverPasswordParamsInvalid')
			case 'SIZE_MUST_BE_BETWEEN_0_AND_7':
				return t('codes.sizeMustBeBetween0And7')
			case 'SIZE_MUST_BE_BETWEEN_0_AND_100':
				return t('codes.sizeMustBeBetween0And100')
			case 'SIZE_MUST_BE_BETWEEN_1_AND_100':
				return t('codes.sizeMustBeBetween1And100')
			case 'SIZE_MUST_BE_BETWEEN_1_AND_1000':
				return t('codes.sizeMustBeBetween1And1000')
			case 'SIZE_MUST_BE_BETWEEN_1_AND_12':
				return t('codes.sizeMustBeBetween1And12')
			case 'SIZE_MUST_BE_BETWEEN_1_AND_3':
				return t('codes.sizeMustBeBetween1And3')
			case 'SIZE_MUST_BE_BETWEEN_1_AND_4':
				return t('codes.sizeMustBeBetween1And4')
			case 'SIZE_MUST_BE_BETWEEN_1_AND_50':
				return t('codes.sizeMustBeBetween1And50')
			case 'SIZE_MUST_BE_BETWEEN_1_AND_7':
				return t('codes.sizeMustBeBetween1And7')
			case 'SIZE_MUST_BE_BETWEEN_5_AND_15':
				return t('codes.sizeMustBeBetween5And15')
			case 'SIZE_MUST_BE_BETWEEN_8_AND_50':
				return t('codes.sizeMustBeBetween8And50')
			case 'SIZE_MUST_BE_BETWEEN_2_AND_50':
				return t('codes.sizeMustBeBetween2And50')
			case 'TOKEN_INVALID':
				return t('codes.tokenInvalid')
			case 'UNAUTHORIZED':
				return t('codes.unauthorized')
			case 'LOGIN_ALREADY_EXISTS_IN_ECOMMERCE':
				return t('codes.loginAlreadyExistsInEcommerce')
			case 'UNALLOWED_WORDS':
				return t('codes.unallowedWords')
			case 'UPDATE_SAME_PASSWORD':
				return t('codes.updateSamePassword')
			case 'ORDER_PAYMENT_TRANSACTION_NOT_ALLOWED':
				return t('codes.orderPaymentTransactionNotAllowed')
			case 'RECAPTCHA_MISSING_SECRET':
			case 'RECAPTCHA_INVALID_SECRET':
			case 'RECAPTCHA_MISSING_RESPONSE':
			case 'RECAPTCHA_BAD_REQUEST':
			case 'RECAPTCHA_INVALID_RESPONSE':
			case 'RECAPTCHA_TIMEOUT_OR_DUPLICATE':
			case 'RECAPTCHA_INVALID_SCORE':
			case 'RECAPTCHA_UNSUCCESSFUL':
			case 'UNKNOWN_EXCEPTION':
			default:
				return t('codes.unknownException')
		}
	}, [t, i18n])

	const getFieldErrorMessage = (errorCodes: FormErrorCodeType[]): string => join(map(errorCodes, (errorCode: FormErrorCodeType) => getErrorMessage(errorCode)), '\r\n')

	return {
		getErrorMessage,
		getFieldErrorMessage,
	}
}
