import haversineDistance from 'haversine-distance'
import { isEqual, isNil, round } from 'lodash'

import { DistanceUnitType, IDistanceData, LatLng } from '~/hooks/distance'

export const useDistance = (pointA?: LatLng | null, pointB?: LatLng | null): IDistanceData => {
	const calculateDistance = (coordsA?: LatLng | null, coordsB?: LatLng | null): number | null => {
		if (isNil(coordsA) || isNil(coordsB)) {
			return null
		}

		return haversineDistance({ lat: coordsA.lat, lng: coordsA.lng }, { lat: coordsB.lat, lng: coordsB.lng })
	}

	const getDistance = (roundPrecision: number, unit: DistanceUnitType = 'm', optionalDistance?: number): number | null => {
		const distance = calculateDistance(pointA, pointB) || optionalDistance

		if (isNil(distance)) {
			return null
		}

		if (isEqual(unit, 'km' as DistanceUnitType)) {
			return round(distance / 1000, roundPrecision)
		}

		return round(distance, roundPrecision)
	}

	return {
		getDistance,
		calculateDistance,
	}
}
