import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { find, isNil, isNull, isUndefined, isEqual, isEmpty } from 'lodash'

import { buildItemQuantityList, CartPreviewDeliveryGroup, ICartPreviewDeliveryGroupsProps, initialDeliveryGroupDelivery, initialDeliveryGroupPickup, initialDeliveryGroupStoreDelivery } from '~/components/cart/cartPreview'
import { cartDeliveryCalculate, setActiveDeliveryGroup } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { SkipContentAnchor, SkipContentList } from '~/components/core/skipContent'
import { SKIP_CART_DELIVERY_METHODS, useSkipElements } from '~/hooks/skipElements'
import { DeliveryGroupType, ICartDeliveryCalculateParams, ICheckPriceData, IDeliveryGroup } from '~/api/dataTypes/cart'
import { DATA_TESTID } from '~/utils/dataTestId'
import { IRootState } from '~/state/types'
import { CartPreviewCheckPriceModal } from '~/components/cart/cartPreview/cartPreviewCheckPrice'
import { useModal } from '~/hooks/modal'
import { getCartCheckPrice } from '~/api/requests/cart'
import { getCartItemQuantityList, getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'

import styles from './CartPreviewDeliveryGroups.module.scss'

const CartPreviewDeliveryGroups = (props: ICartPreviewDeliveryGroupsProps): ReactElement | null => {
	const { groups, selectedDeliveryType } = props
	const dispatch: AppDispatch = useDispatch()
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { data } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const { isOpen: isOpenCheckPriceModal, handleOpen, handleClose } = useModal('CartPreviewCheckPriceModal')
	const [checkPriceData, setCheckPriceData] = useState<ICheckPriceData | null>(null)
	const { cartDeliveryPreviewGroupsSkip } = useSkipElements()
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const cartItemQuantityList = useSelector((state: IRootState) => getCartItemQuantityList(state))

	const { cart: { cartItemList, productsQuantityMap, selectedDeliveryWayType, promotionCode: selectedPromotionCode, pickupPoint } } = data

	const itemQuantityList = useMemo(() => buildItemQuantityList(cartItemList, productsQuantityMap), [cartItemList, productsQuantityMap])

	const { cart: { selectedPaymentWayId } } = data

	const { delivery, pickup, storeDelivery } = groups
	const deliveryData = { ...delivery, ...initialDeliveryGroupDelivery }
	const pickupData = { ...pickup, ...initialDeliveryGroupPickup }
	const storeDeliveryData = { ...storeDelivery, ...initialDeliveryGroupStoreDelivery }

	const handleConfirmChangePrice = useCallback(async (deliveryType: DeliveryGroupType) => {
		const { cart: { promotionCode } } = data

		const calculateParams: ICartDeliveryCalculateParams = {
			itemQuantityList,
			deliveryWayId: storeDelivery.deliveryList[0].id,
			additionalDeliveryWayOptionSelected: false,
			promotionCode,
			paymentWayId: selectedPaymentWayId,
		}

		await dispatch(setActiveDeliveryGroup(deliveryType))

		if ((isEqual(deliveryType, 'STORE_DELIVERY'))) {
			await dispatch(cartDeliveryCalculate(calculateParams))
		} else if (isEqual(deliveryType, 'DELIVERY')) {
			await dispatch(cartDeliveryCalculate({
				...calculateParams,
				deliveryWayId: null,
			}))
		}
	}, [data, itemQuantityList, storeDelivery])

	const handleSelectGroup = useCallback(async (deliveryType: DeliveryGroupType): Promise<void> => {
		if ((isEqual(deliveryType, 'STORE_DELIVERY') || isEqual(deliveryType, 'PICKUP')) && !isEqual(selectedDeliveryWayType, 'PICKUP_IN_STORE') && !isNull(preferredStore)) {
			const { data } = await getCartCheckPrice({
				storeCode: preferredStore.storeCode,
				deliveryWayId: storeDelivery.deliveryList[0].id,
			})

			if (!isEmpty(data.newSelection.itemList)) {
				setCheckPriceData({
					data,
					onConfirm: () => handleConfirmChangePrice(deliveryType),
				})

				handleOpen()

				return
			}
		} else if (isEqual(deliveryType, 'DELIVERY')) {
			const { data } = await getCartCheckPrice({
				storeCode: preferredStore?.storeCode,
				deliveryWayId: null,
			})

			if (!isEmpty(data.newSelection.itemList)) {
				setCheckPriceData({
					data,
					onConfirm: () => handleConfirmChangePrice(deliveryType),
				})

				handleOpen()

				return
			}

			await dispatch(cartDeliveryCalculate({
				itemQuantityList: cartItemQuantityList,
				promotionCode : isEcommerce ? selectedPromotionCode : '',
				deliveryWayId: null,
				additionalDeliveryWayOptionSelected: false,
				pickupPointId: !isNull(pickupPoint) ? pickupPoint.id : null,
				clearDiscount: false,
			}))
		}

		await dispatch(setActiveDeliveryGroup(deliveryType))
	}, [itemQuantityList, storeDelivery, data, preferredStore, handleConfirmChangePrice, selectedDeliveryWayType, cartItemQuantityList, isEcommerce, selectedPromotionCode, pickupPoint])

	const setInitialSelectedGroup = useCallback(async (): Promise<void> => {
		const newSelectedGroup = find(groups, (group: IDeliveryGroup) => group.selected)
		const firstActiveGroup = find(groups, (group: IDeliveryGroup) => group.active)
		const initialActiveGroup = !isUndefined(newSelectedGroup) ? newSelectedGroup.deliveryType : !isNull(selectedDeliveryType) ? selectedDeliveryType : !isUndefined(firstActiveGroup) ? firstActiveGroup.deliveryType : null
		const currentActiveOnList = !isNull(selectedDeliveryType) ? find(groups, (group: IDeliveryGroup) => group.deliveryType === selectedDeliveryType) : null

		if (!isNull(initialActiveGroup) && isNil(currentActiveOnList)) {
			await handleSelectGroup(initialActiveGroup)
		}
	}, [groups, selectedDeliveryType])

	useEffect(() => {
		(async () => {
			await setInitialSelectedGroup()
		})()
	}, [groups])

	return (
		<>
			<div className={ styles.wrapper }>
				<SkipContentAnchor elementId={ SKIP_CART_DELIVERY_METHODS } additionalClass={ styles.skipContentAnchor } />

				<SkipContentList skipElements={ cartDeliveryPreviewGroupsSkip } theme={ { wrapper: styles.skipContentList } } />

				<CartPreviewDeliveryGroup
					data={ deliveryData }
					isSelected={ delivery.deliveryType === selectedDeliveryType }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_SET_DELIVERY_METHOD }
					onSelectGroup={ handleSelectGroup }
				/>

				<CartPreviewDeliveryGroup
					data={ pickupData }
					isSelected={ pickup.deliveryType === selectedDeliveryType }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_SET_PICKUP_METHOD }
					onSelectGroup={ handleSelectGroup }
				/>

				<CartPreviewDeliveryGroup
					data={ storeDeliveryData }
					isSelected={ storeDelivery.deliveryType === selectedDeliveryType }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_SET_STORE_DELIVERY_METHOD }
					onSelectGroup={ handleSelectGroup }
				/>
			</div>

			<CartPreviewCheckPriceModal
				isOpen={ isOpenCheckPriceModal }
				itemsData={ checkPriceData }
				type="changeDelivery"
				onClose={ handleClose }
			/>
		</>

	)
}

export { CartPreviewDeliveryGroups }
