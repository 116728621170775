import React, { ReactElement, Fragment, ElementType } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty, isNull } from 'lodash'

import { IProductBlockProps, ProductBlockImage, ProductBlockName, ProductBlockPrice, ProductBlockButton, ProductBlockWrapper, ProductBlockCompareButton } from '~/components/core/productBlock'
import { WishList } from '~/components/core/wishList'
import { usePrice } from '~/hooks/price'
import { getProductAriaLabelWithPrice } from '~/utils/productAria'

import styles from './ProductBlock.module.scss'

const ProductBlock = (props: IProductBlockProps): ReactElement => {
	const {
		product,
		position = 0,
		isAbleToCompare = false,
		hasWishList = true,
		hasPriority = false,
		overrideImageSrc = '',
		TopAccessory = null,
		BottomAccessory = null,
		theme = {},
		tag = Fragment,
	} = props
	const { priceFormat } = usePrice()
	const { t } = useTranslation(['header'])

	const { price, name, lmReference, promotionLabel } = product
	const { newPrice, basePriceUnit, newPricePromoLabel } = price

	const bigPrice = priceFormat(newPrice)
	const ariaLabel = getProductAriaLabelWithPrice({
		promotional: !isEmpty(newPricePromoLabel),
		bigPrice,
		name,
		bigPriceUnit: `${ basePriceUnit } ${ !isEmpty(newPricePromoLabel) ? `- ${ newPricePromoLabel }` : '' }`,
		t,
	})

	const WrapperTag: ElementType = tag

	const wishListClass = classNames(styles.wishList, theme.wishList)

	return (
		<ProductBlockWrapper additionalClass={ theme.wrapper } lmReference={ lmReference }>
			{ !isNull(TopAccessory) && TopAccessory }

			<div className={ styles.wrapper }>
				{ hasWishList && (
					<WishList product={ product } additionalClass={ wishListClass } />
				) }

				<ProductBlockImage
					hasPriority={ hasPriority }
					product={ product }
					newPricePromoLabel={ newPricePromoLabel }
					overrideImageSrc={ overrideImageSrc }
					additionalClass={ theme.image }
					position={ position }
				/>

				<WrapperTag>
					<ProductBlockName
						product={ product }
						additionalClass={ theme.name }
						ariaLabel={ ariaLabel }
						position={ position }
					/>
				</WrapperTag>
			</div>

			<ProductBlockPrice
				price={ price }
				promotionLabelCode={ promotionLabel?.code }
				additionalClass={ theme.priceBlock }
			/>

			<ProductBlockButton product={ product } theme={ { button: theme.button, checkAvailabilityButton: theme.checkAvailabilityButton } } />

			<ProductBlockCompareButton isAbleToCompare={ isAbleToCompare } product={ product } />

			{ !isNull(BottomAccessory) && BottomAccessory }
		</ProductBlockWrapper>
	)
}

export { ProductBlock }
