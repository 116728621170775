import React, { ReactElement, useCallback } from 'react'
import { gt } from 'lodash'

import { ICartPreviewDeliveryListWrapperProps } from '~/components/cart/cartPreview'
import { SkipContentList } from '~/components/core/skipContent'
import { useSkipElements } from '~/hooks/skipElements'

import styles from './CartPreviewDeliveryListWrapper.module.scss'

const CartPreviewDeliveryListWrapper = (props: ICartPreviewDeliveryListWrapperProps): ReactElement => {
	const { children, itemsCount } = props
	const { cartPreviewDeliveryListWrapperSkip } = useSkipElements()

	const renderSkipContentList = useCallback((): ReactElement | null => {
		if (gt(itemsCount, 1)) {
			return (
				<SkipContentList skipElements={ cartPreviewDeliveryListWrapperSkip } />
			)
		}

		return null
	}, [itemsCount, cartPreviewDeliveryListWrapperSkip])

	return (
		<div className={ styles.wrapper }>
			{ renderSkipContentList() }

			{ children }
		</div>
	)
}

export { CartPreviewDeliveryListWrapper }
