import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'

import { Link } from '~/components/core/link'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './CartAsideRegulationsInfo.module.scss'

const CartAsideRegulationsInfo = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { urls } = useContext(AppParametersContext)

	const renderRegulationsLink = useCallback((): ReactElement => (
		<Link
			href={ urls.ecommerceTerms }
			additionalClass={ styles.link }
			ariaLabel={ t('aside.summary.info.regulations') }
		>
			{ t('aside.summary.info.regulations') }
		</Link>
	), [])

	const renderPrivacyPolicyLink = useCallback((): ReactElement => (
		<Link
			href={ urls.privacyPolicy }
			additionalClass={ styles.link }
			ariaLabel={ t('aside.summary.info.privacyPolicy') }
		>
			{ t('aside.summary.info.privacyPolicy') }
		</Link>
	), [])

	const renderInfo = useCallback((): ReactElement => (
		<>
			{ `${ t('aside.summary.info.regulationsPrefix') } ` }

			{ renderRegulationsLink() }

			{ `${ t('aside.summary.info.privacyPolicyPrefix') } ` }

			{ renderPrivacyPolicyLink() }
		</>
	), [urls])

	return (
		<div className={ styles.wrapper }>
			{ renderInfo() }
		</div>
	)
}

export { CartAsideRegulationsInfo }
