import React, { ReactElement, useEffect } from 'react'
import { useRouter } from 'next/router'
import { isEqual, isUndefined } from 'lodash'

import { IFallbackProps } from '~/components/core/fallback'
import { ServerError } from '~/components/core/serverError'

import styles from './Fallback.module.scss'

const Fallback = (props: IFallbackProps): ReactElement => {
	const { brokenUrl, onResetState } = props
	const router = useRouter()

	useEffect(() => {
		if (!isEqual(brokenUrl, router.asPath) && !isUndefined(onResetState)) {
			onResetState()
		}
	}, [router.asPath, onResetState, brokenUrl])

	return (
		<ServerError theme={ { wrapper: styles.wrapper } } />
	)
}

export { Fallback }
