import React, { ReactElement, useContext } from 'react'

import { IHeaderTopBarSearchBarHistoryItemProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './HeaderTopBarSearchBarHistoryItem.module.scss'

const HeaderTopBarSearchBarHistoryItem = (props: IHeaderTopBarSearchBarHistoryItemProps): ReactElement => {
	const { historyItem } = props
	const { urls } = useContext(AppParametersContext)

	const { val } = historyItem
	const wwwUrl = `${ urls.search }${ val }`

	return (
		<div className={ styles.wrapper }>
			<Link
				href={ wwwUrl }
				additionalClass={ styles.link }
				ariaLabel={ val }
			>
				<span className={ styles.title }>
					{ val }
				</span>
			</Link>
		</div>
	)
}

export { HeaderTopBarSearchBarHistoryItem }
