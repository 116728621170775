import { SwiperOptions } from 'swiper'

import { vars } from '~/statics'

const { mobile, tablet, smallDesktop } = vars.breakpoints

export const ADD_TO_CART_PRODUCTS_CAROUSEL_PARAMS: SwiperOptions = {
	slidesPerView: 'auto',
	slidesPerGroup: 2,
	breakpoints: {
		[mobile]: {
			slidesPerView: 'auto',
			slidesPerGroup: 2,
		},
		[tablet]: {
			slidesPerView: 'auto',
			slidesPerGroup: 3,
		},
		[smallDesktop]: {
			slidesPerView: 3,
			slidesPerGroup: 3,
		},
	},
}
