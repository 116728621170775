export const SKIP_MAIN_MENU = 'SKIP_MAIN_MENU'
export const SKIP_FOOTER = 'SKIP_FOOTER'
export const SKIP_ADDITIONAL_FOOTER = 'SKIP_ADDITIONAL_FOOTER'
export const SKIP_SCROLL_TO_TOP = 'SKIP_SCROLL_TO_TOP'

export const SKIP_CART_PAYMENT_METHODS = 'SKIP_CART_PAYMENT_METHODS'
export const SKIP_CART_ASIDE = 'SKIP_CART_ASIDE'
export const SKIP_CART_DELIVERY_METHODS = 'SKIP_CART_DELIVERY_METHODS'

export const SKIP_SERVICE_CART_PAYMENT_METHODS = 'SKIP_SERVICE_CART_PAYMENT_METHODS'

export const SKIP_PRODUCT_BREADCRUMBS = 'SKIP_PRODUCT_BREADCRUMBS'
export const SKIP_PRODUCT = 'SKIP_PRODUCT'
export const SKIP_PRODUCT_INFO = 'SKIP_PRODUCT_INFO'
export const SKIP_PRODUCT_MEDIA = 'SKIP_PRODUCT_MEDIA'
export const SKIP_PRODUCT_DETAILS = 'SKIP_PRODUCT_DETAILS'
export const SKIP_PRODUCT_OPINIONS = 'SKIP_PRODUCT_OPINIONS'
export const SKIP_PRODUCT_ADVICES = 'SKIP_PRODUCT_ADVICES'
export const SKIP_PRODUCT_SIMILAR = 'SKIP_PRODUCT_SIMILAR'
export const SKIP_PRODUCT_SERVICES = 'SKIP_PRODUCT_SERVICES'
export const SKIP_PRODUCT_SUMMARY = 'SKIP_PRODUCT_SUMMARY'
export const SKIP_PRODUCT_SHOP_AVAILABILITY = 'SKIP_PRODUCT_SHOP_AVAILABILITY'

export const SKIP_LISTING_BREADCRUMBS = 'SKIP_LISTING_BREADCRUMBS'
export const SKIP_LISTING = 'SKIP_LISTING'
export const SKIP_LISTING_FILTERS = 'SKIP_LISTING_FILTERS'
export const SKIP_LISTING_PRODUCTS = 'SKIP_LISTING_PRODUCTS'

export const SKIP_FILTERS_SUBCATEGORIES = 'SKIP_FILTERS_SUBCATEGORIES'
export const SKIP_TO_FILTERS = 'SKIP_TO_FILTERS'
export const SKIP_FILTERS_BUTTON = 'SKIP_FILTERS_BUTTON'

export const ALL_FOCUSABLE_SELECTOR_QUERY = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]'
