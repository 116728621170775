import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { Button } from '~/components/core/button'
import { ICON_TYPE, Icon } from '~/components/core/icon'
import { useMounted } from '~/hooks/mounted'
import { useGoToSignIn } from '~/hooks/goToSignIn'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'
import { UserContext } from '~/providers/userProvider'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HamburgerMenuUserAccountButton.module.scss'

const HamburgerMenuUserAccountButton = (): ReactElement | null => {
	const { isMounted } = useMounted()
	const { isLogged, userName } = useContext(UserContext)
	const { handleSetUserMenuOpen, handleSetOpen } = useContext(HamburgerMenuContext)
	const { t } = useTranslation(['header'])
	const { onGoToSignInPage } = useGoToSignIn()

	const iconName: keyof typeof ICON_TYPE = isLogged ? 'personFull' : 'person'

	const getLabel = useCallback((): string => {
		if (isLogged) {
			if (isEmpty(userName)) {
				return (
					t('topBar.account.welcome')
				)
			}

			return t('topBar.account.welcomeUser', { userName })
		}

		return t('menu.loginAndRegister')
	}, [isLogged, userName])

	const handleClick = useCallback(async (): Promise<void> => {
		if (isLogged) {
			handleSetUserMenuOpen(true)
		} else {
			handleSetOpen(false)

			await onGoToSignInPage()
		}
	}, [handleSetUserMenuOpen, handleSetOpen, onGoToSignInPage, isLogged])

	const label = getLabel()

	if (!isMounted) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<Button
				variant="neutral"
				size="inherit"
				additionalClass={ styles.button }
				dataTestId={ DATA_TESTID.CORE.BUTTON_CLICK_HAMBURGER_MENU_USER }
				ariaLabel={ label }
				onClick={ handleClick }
			>
				<span className={ styles.name }>
					<Icon
						name={ iconName }
						color="navy"
						width={ 21 }
						additionalClass={ styles.icon }
					/>

					<span>
						{ label }
					</span>
				</span>

				<Icon name="arrowFull" width={ 4 } />
			</Button>
		</div>
	)
}

export { HamburgerMenuUserAccountButton }
