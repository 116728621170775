import React, { ReactElement } from 'react'

import { HeaderLogotype } from '~/components/core/layout/header'
import { Container } from '~/components/core/layout/container'
import { Notifications } from '~/components/core/notifications'

import styles from './HeaderSimple.module.scss'

const HeaderSimple = (): ReactElement => {
	return (
		<div className={ styles.wrapper }>
			<Container>
				<div className={ styles.content }>
					<HeaderLogotype isWithoutAnimation />
				</div>
			</Container>

			<Notifications />
		</div>
	)
}

export { HeaderSimple }
