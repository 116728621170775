import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import classNames from 'classnames'
import { includes } from 'lodash'

import { IMainProps, CART_PAGE_TYPES } from '~/components/core/layout'
import { SkipContentAnchor } from '~/components/core/skipContent'
import { HeaderContext } from '~/providers/headerProvider'
import { KobiHeaderIcons } from '~/components/kobi/kobiHeaderIcons'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './Main.module.scss'

const Main = (props: IMainProps): ReactElement => {
	const { children, pageType } = props
	const { isHeaderFixed, headerHeight } = useContext(HeaderContext)
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)
	const [paddingTop, setPaddingTop] = useState<number>(0)

	const wrapperClass = classNames(styles.wrapper, {
		[styles.kobiMargin]: isKobiIntegrationEnabled,
	})

	const renderKobiHeaderIcons = useCallback((): ReactElement | null => {
		if (!isKobiIntegrationEnabled || includes(CART_PAGE_TYPES, pageType)) {
			return null
		}

		return (
			<KobiHeaderIcons isKobiIntegrationEnabled={ isKobiIntegrationEnabled } />
		)
	}, [isKobiIntegrationEnabled, pageType])

	useIsomorphicLayoutEffect(() => {
		document.documentElement.style.scrollPaddingTop = `${ headerHeight + 16 }px`
	}, [headerHeight])

	useEffect(() => {
		setPaddingTop(isHeaderFixed ? headerHeight : 0)
	}, [isHeaderFixed])

	return (
		<>
			{ renderKobiHeaderIcons() }

			<main className={ wrapperClass } style={ { paddingTop } }>
				<SkipContentAnchor elementId={ pageType } />

				{ children }
			</main>
		</>
	)
}

export { Main }
