import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { find, isNil } from 'lodash'

import { StorePhoneType } from '~/api/dataTypes/store'
import { ArrowLink } from '~/components/core/arrowLink'
import { getPhoneUrl } from '~/components/core/preferredStore'
import { Link } from '~/components/core/link'
import { CartPreviewDeliveryPickupStoreInfoButton, ICartPreviewDeliveryPickupStoreInfoShortProps } from '~/components/cart/cartPreview'
import { useNavigation } from '~/hooks/navigation'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDeliveryPickupStoreInfoShort.module.scss'

const CartPreviewDeliveryPickupStoreInfoShort = (props: ICartPreviewDeliveryPickupStoreInfoShortProps): ReactElement => {
	const { store, onClick } = props
	const { t } = useTranslation(['stores', 'cart'])
	const { getGoogleMapToStoreUrl } = useNavigation()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { address, name, phoneList } = store
	const { street, zipCode, city } = address

	const informationPointPhone = find(phoneList, ['phoneType', 'INFORMATION_POINT_PHONE' as StorePhoneType])

	const handleOpenMap = useCallback((): void => {
		window.open(getGoogleMapToStoreUrl(store), '_blank')
	}, [store])

	const arrowClass = classNames(styles.arrow, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.header }>
				<span>
					{ t('preview.delivery.groups.pickup.pickupSpot', { ns: 'cart' }) }:
				</span>

				<span className={ styles.store }>
					{ name }
				</span>
			</div>

			<CartPreviewDeliveryPickupStoreInfoButton onClick={ onClick } />

			<div className={ styles.data }>
				<div>
					{ `${ street }, ${ zipCode } ${ city }` }
				</div>

				<ArrowLink
					text={ t('checkWay') }
					theme={ {
						arrow: arrowClass,
					} }
					onClick={ handleOpenMap }
				/>
			</div>

			{ !isNil(informationPointPhone) && (
				<div className={ styles.phone }>
					<span className={ styles.phoneNumber }>
						{ informationPointPhone.name }:
					</span>

					<Link
						isNativeLink
						href={ getPhoneUrl(informationPointPhone.number) }
						additionalClass={ styles.link }
						ariaLabel={ informationPointPhone.number }
					>
						{ informationPointPhone.number }
					</Link>
				</div>
			) }
		</div>
	)
}

export { CartPreviewDeliveryPickupStoreInfoShort }
