import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'

import { useModal } from '~/hooks/modal'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { ICartPreviewProductsItemProps, CartPreviewProductsActionBlock, CartPreviewProductsAvailabilityBlock, CartPreviewProductsProductBlock, CartPreviewProductsPriceBlock, CartPreviewProductsQuantityBlock, CartPreviewProductsSummaryPriceBlock, CartPreviewRemoveProductModal } from '~/components/cart/cartPreview'
import { getCartData, removeProductFromCart } from '~/actions/cart'
import { AppDispatch } from '~/state/store'
import { useKobiHeader } from '~/hooks/kobiHeader'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsItem.module.scss'

const CartPreviewProductsItem = (props: ICartPreviewProductsItemProps): ReactElement => {
	const { item, quantity } = props
	const { isDesktop, isTablet } = useMediaQuery()
	const { isOpen, handleOpen, handleClose } = useModal('CartPreviewRemoveProduct')
	const dispatch: AppDispatch = useDispatch()
	const { handleDispatchKobiCartHeader } = useKobiHeader()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { productSimple, valueForLine, labels, quantityInfo: { possibleQuantity } } = item
	const { price, availability: { userStoreStock }, id, lmReference, name, priceByPackageUnit, promotionLabel, deliveryInfo } = productSimple
	const { basePriceUnit, packagePriceUnit } = price

	const isNotAvailableItemInStore = isEqual(possibleQuantity, 0)
	const isNotEnoughItemInStore = quantity > possibleQuantity
	const priceUnit = priceByPackageUnit ? packagePriceUnit : basePriceUnit

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	const handleRemoveProduct = useCallback(async (): Promise<void> => {
		await dispatch(removeProductFromCart({ lmReference }))
		await dispatch(getCartData())
		handleDispatchKobiCartHeader()
		handleClose()
	}, [id, quantity])

	return (
		<div className={ wrapperClass }>
			<div className={ styles.data }>
				<CartPreviewProductsProductBlock
					item={ item }
					quantity={ quantity }
					userStoreStock={ userStoreStock }
					isNotEnoughItem={ !isNotAvailableItemInStore && isNotEnoughItemInStore }
					priceUnit={ priceUnit }
					onOpenMobileRemoveProductModal={ handleOpen }
				/>

				{ isDesktop && (
					<>
						<CartPreviewProductsAvailabilityBlock
							userStoreStock={ userStoreStock }
							priceUnit={ priceUnit }
							deliveryInfo={ deliveryInfo }
						/>

						<CartPreviewProductsPriceBlock
							labels={ labels }
							price={ price }
							priceUnit={ basePriceUnit }
							promotionLabelCode={ promotionLabel?.code }
						/>

						<CartPreviewProductsQuantityBlock
							quantity={ quantity }
							item={ item }
							priceUnit={ priceUnit }
							isNotEnoughItem={ !isNotAvailableItemInStore && isNotEnoughItemInStore }
						/>

						<CartPreviewProductsSummaryPriceBlock summaryPrice={ valueForLine } />
					</>
				) }

				{ isTablet && <CartPreviewProductsActionBlock onOpenRemoveConfirmationModal={ handleOpen } /> }

				<CartPreviewRemoveProductModal
					isOpen={ isOpen }
					productName={ name }
					onClose={ handleClose }
					onRemoveProduct={ handleRemoveProduct }
				/>
			</div>
		</div>
	)
}

export { CartPreviewProductsItem }
