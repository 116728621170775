import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { ICartPreviewSummaryDiscountCodeProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountCode'
import { Icon } from '~/components/core/icon'

import styles from './CartPreviewSummaryDiscountCode.module.scss'

const CartPreviewSummaryDiscountCode = (props: ICartPreviewSummaryDiscountCodeProps): ReactElement | null => {
	const {
		hasPromoCode, hasLoyaltyReward, promotionCodeValue,
		promotionCode = '',
		theme = {},
	} = props
	const { t } = useTranslation(['cart'])

	const hasCode = hasPromoCode && !isEmpty(promotionCode)
	const hasLoyalty = hasLoyaltyReward && !isEmpty(promotionCodeValue)

	const renderDiscountAdditionalMessage = useCallback((): ReactElement | null => {
		if (hasCode) {
			return (
				<span dangerouslySetInnerHTML={ { __html: t('preview.aside.discount.additionalInfoMessages.promoCode', { promotionCode }) } } className={ styles.additionalMessage } />
			)
		}

		if (hasLoyalty) {
			return (
				<span dangerouslySetInnerHTML={ { __html: t('preview.aside.discount.additionalInfoMessages.domProgramDiscount', { promotionCodeValue }) } } className={ styles.additionalMessage } />
			)
		}

		return null
	}, [hasCode, hasLoyalty])

	if (!hasCode && !hasLoyalty) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<Icon
				name="checkCircle"
				width={ 16 }
				additionalClass={ theme.icon }
			/>

			<span dangerouslySetInnerHTML={ { __html: t('preview.aside.discount.successMessages.promoCode') } } className={ styles.message } />

			{ renderDiscountAdditionalMessage() }
		</div>
	)
}

export { CartPreviewSummaryDiscountCode }
