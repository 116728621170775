import { createContext, ReactElement, useCallback, useMemo, useState } from 'react'
import { isEqual, filter, noop, size, omit, last } from 'lodash'

import { IAlertsContextProps, IAlertProviderProps, IAlert } from '~/providers/alertProvider'

const initialProps: IAlertsContextProps = {
	alerts: [],
	addAlert: noop,
	removeAlert: noop,
}

export const AlertContext = createContext(initialProps)

export const AlertProvider = (props: IAlertProviderProps): ReactElement => {
	const { children } = props
	const [alerts, setAlerts] = useState<IAlert[]>(initialProps.alerts)
	const [index, setIndex] = useState<number>(0)

	const isDuplicatedAlert = useCallback((lastAlert: IAlert | undefined, newAlert: IAlert): boolean => (
		isEqual(omit(lastAlert, 'index'), omit(newAlert, 'index'))
	), [])

	const addAlert = useCallback((alert: IAlert): void => {
		const newAlert = { ...alert, index }

		setIndex((index: number) => index + 1)
		setAlerts((alerts: IAlert[]) => {
			const lastAlert = last(alerts)

			if (isEqual(size(alerts), 0) || !isDuplicatedAlert(lastAlert, newAlert)) {
				return [...alerts, newAlert]
			}

			return alerts
		})
	}, [index])

	const removeAlert = useCallback((index: number): void => {
		setAlerts((alerts: IAlert[]) => filter(alerts, (alert: IAlert) => !isEqual(alert.index, index)))
	}, [])

	const providerValue: IAlertsContextProps = useMemo(() => ({
		alerts,
		addAlert,
		removeAlert,
	}), [alerts])

	return (
		<AlertContext.Provider value={ providerValue }>
			{ children }
		</AlertContext.Provider>
	)
}
