import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'

import { ITurbineMicroservicesState } from './types'
import { turbineMicroservicesReducerInitialState as initialState } from './constants'

export const turbineMicroservices = produce((draft: Draft<ITurbineMicroservicesState>, action: AppPromiseAction<any>) => {
	switch (action.type) {
		default:
			return draft
	}
}, initialState)
