import { isNull } from 'lodash'

import { ALL_FOCUSABLE_SELECTOR_QUERY } from '~/hooks/skipElements'

export const focusFirstElementAfterSkipAnchor = (buttonRef: HTMLButtonElement): void => {
	const { nextElementSibling } = buttonRef

	if (!isNull(nextElementSibling)) {
		const firstFocusable = nextElementSibling.querySelector(ALL_FOCUSABLE_SELECTOR_QUERY) as HTMLElement

		if (!isNull(firstFocusable)) {
			firstFocusable.focus()
		}
	}
}
