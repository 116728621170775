import React, { createContext, ReactElement } from 'react'

import { IStoreServicesProviderProps } from '~/providers/storeServicesProvider'
import { IStoreAllServicesResponse } from '~/api/dataTypes/store'

const initialProps: IStoreAllServicesResponse = {} as IStoreAllServicesResponse

export const StoreServicesContext = createContext(initialProps)

export const StoreServicesProvider = (props: IStoreServicesProviderProps): ReactElement => {
	const { children, value } = props

	return (
		<StoreServicesContext.Provider value={ value }>
			{ children }
		</StoreServicesContext.Provider>
	)
}
