import produce, { Draft } from 'immer'

import { AppPromiseAction } from '~/actions'
import { CartIdentificationActionType, CartIdentificationType } from '~/actions/cartIdentification'
import { FAILURE, REQUEST, SUCCESS } from '~/statics'
import { ServiceCartIdentificationActionType, ServiceCartIdentificationType } from '~/actions/serviceCartIdentification'

import { ICartIdentificationState } from './types'
import { cartIdentificationInitialState as initialState } from './constants'

export const cartIdentification = produce((draft: Draft<ICartIdentificationState>, action: AppPromiseAction<CartIdentificationActionType | ServiceCartIdentificationActionType>) => {
	switch (action.type) {
		case REQUEST(CartIdentificationType.CART_IDENTIFICATION):
			draft.isPending = true

			return draft
		case SUCCESS(CartIdentificationType.CART_IDENTIFICATION):
			draft.isPending = false
			draft.data = action.payload

			return draft
		case SUCCESS(ServiceCartIdentificationType.SERVICE_CART_IDENTIFICATION):
			draft.data.cartToMerge = action.payload.cartToMerge
			draft.data.user = action.payload.user

			return draft
		case SUCCESS(ServiceCartIdentificationType.GET_SERVICE_CART_CALENDAR_UUID): {
			draft.calendarCartUuid = action.payload

			return draft
		}
		case SUCCESS(CartIdentificationType.SET_ORDER_AS_GUEST):
			draft.isGuestOrder = action.payload

			return draft
		case SUCCESS(CartIdentificationType.RESET_CART_IDENTIFICATION):
			return initialState
		case FAILURE(CartIdentificationType.CART_IDENTIFICATION):
			draft.isPending = false

			return draft
		default:
			return draft
	}
}, initialState)
