import { AppAction } from '~/actions'
import { IProductSimple } from '~/api/dataTypes/product'
import { GetCustomerLastViewedProductsType } from '~/actions/customer'

export type LastViewedProductsSetType = AppAction<LastViewedActionType.SET_LAST_VIEWED_PRODUCTS, IProductSimple[]>
export type LastViewedProductsInitType = AppAction<LastViewedActionType.INIT_LAST_VIEWED_PRODUCTS, IProductSimple[]>

export type LastViewedActionUnionType = LastViewedProductsSetType | GetCustomerLastViewedProductsType | LastViewedProductsInitType

export enum LastViewedActionType {
	SET_LAST_VIEWED_PRODUCTS = 'lastViewed/setLastViewedProducts',
	INIT_LAST_VIEWED_PRODUCTS = 'lastViewed/initLastViewedProducts',
}
