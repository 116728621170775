import { localStorageGet } from '~/utils/storage'
import { LS_KEY_PREFERRED_STORE } from '~/utils/constants'

import { PreferredStoreDataType } from './types'

export const preferredStoreDataInitialState: PreferredStoreDataType = (
	localStorageGet<PreferredStoreDataType>(LS_KEY_PREFERRED_STORE) ?
		{
			id: 0,
			name: '',
			open: false,
			phoneList: [],
			departmentLocationList: [],
			pickupOrder: {
				imageUrl: '',
				imageLayerUrl: '',
				pickupInStorePlace: '',
			},
			storeServiceList: [],
			storeCode: '',
			address: {
				id: 0,
				city: '',
				zipCode: '',
				street: '',
				houseNumber: '',
				flatNumber: '',
				mainAddress: false,
				firstName: '',
				lastName: '',
				longitude: 0,
				latitude: 0,
				customerTitle: 'UNKNOWN',
				customerType: 'PRIVATE',
				addressType: 'NOT_SPECIFIED',
			},
			availableHours: {
				message: '',
				shopDepartment: {
					shopName: '',
					workingDayList: [],
				},
				holidayList: [],
			},
			announcementGroupList: [],
			showDepartmentPhonesSection: false,
			wwwUrl: '',
			...localStorageGet<PreferredStoreDataType>(LS_KEY_PREFERRED_STORE),
		} : null
)
