import React, { ReactElement } from 'react'
import { noop } from 'lodash'

import { DATA_TESTID } from '~/utils/dataTestId'
import { IPreferredStoreInfoProps, PreferredStoreInfoActions, PreferredStoreInfoHolidays, PreferredStoreInfoData, PreferredStoreInfoOpeningHours, PreferredStoreInfoNavigation } from '~/components/core/preferredStore'

import styles from './PreferredStoreInfo.module.scss'

const PreferredStoreInfo = (props: IPreferredStoreInfoProps): ReactElement => {
	const {
		store, onChange,
		onNavigate = noop,
		additionalActionsClass = '',
	} = props

	const { availableHours, id, wwwUrl } = store
	const { shopDepartment, holidayList } = availableHours

	return (
		<section className={ styles.wrapper } data-testid={ DATA_TESTID.CORE.STORE_INFO }>
			<PreferredStoreInfoData store={ store } />

			<PreferredStoreInfoOpeningHours workingDays={ shopDepartment.workingDayList } />

			<PreferredStoreInfoHolidays holidays={ holidayList } />

			<PreferredStoreInfoNavigation
				id={ id }
				url={ wwwUrl }
				onNavigate={ onNavigate }
			/>

			<PreferredStoreInfoActions
				additionalClass={ additionalActionsClass }
				onChange={ onChange }
				onBlur={ onNavigate }
			/>
		</section>
	)
}

export { PreferredStoreInfo }
