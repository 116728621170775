import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { IDotMenuListItemProps } from '~/components/core/dotMenu'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'

import styles from './DotMenuListItem.module.scss'

const DotMenuListItem = (props: IDotMenuListItemProps): ReactElement => {
	const { item } = props

	const {
		icon, title, onClick,
		additionalClass = '',
	} = item

	const {
		name,
		color = 'green',
		size = 16,
	} = icon

	const buttonClass = classNames({
		[styles.button]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ styles.wrapper }>
			<Icon
				color={ color }
				width={ size }
				name={ name }
			/>

			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ buttonClass }
				ariaLabel={ title }
				onClick={ onClick }
			>
				{ title }
			</Button>
		</div>
	)
}

export { DotMenuListItem }
