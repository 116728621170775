import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useToggle } from 'react-use'
import { debounce, isEmpty, isEqual, isNull, size } from 'lodash'

import { IPickupPointData } from '~/api/dataTypes/pickupPoint'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { searchCartDeliveryPickupPoint, clearCartDeliveryPickupPoint } from '~/actions/cart'
import { validation } from '~/utils/validation'
import { useHogaDictionary } from '~/hooks/hogaDictionary'
import { DropdownInput, Input } from '~/components/core/form'
import { CartPreviewDeliveryEcommercePickupPointMap, ICartPreviewDeliveryEcommercePickupPointProps, ICartPreviewEcommercePickupPointData } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryEcommercePickupPoint.module.scss'

const CartPreviewDeliveryEcommercePickupPoint = (props: ICartPreviewDeliveryEcommercePickupPointProps): ReactElement => {
	const { pickupPointId, onSetPickupPointId } = props
	const { pickupPoints: { geolocation, pickupPointList }, data: { cart: { pickupPoint } } } = useSelector((state: IRootState) => state.cartPreview, shallowEqual)
	const [mapZoom, setMapZoom] = useState<number>(isNull(pickupPoint) ? 10 : 16)
	const { t, i18n } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()
	const [isInitialDropdownOpen, setIsInitialDropdownOpen] = useToggle(isNull(pickupPoint))
	const { isDropdownOpen, list, getHogaDictionary, onOpenDropdown, onToggleDropdown } = useHogaDictionary()

	const schema: yup.SchemaOf<ICartPreviewEcommercePickupPointData> = useMemo(() => yup.object().shape({
		zipCode: yup.string().max(6),
		city: yup.string(),
	}), [t, i18n])

	const { mask } = validation

	const { control, setValue, watch } = useForm<ICartPreviewEcommercePickupPointData>({
		resolver: yupResolver(schema),
		defaultValues: {
			zipCode: pickupPoint?.address.zipCode ?? '',
			city: pickupPoint?.address.city ?? '',
		},
		mode: 'onChange',
	})

	const { zipCode } = watch()

	const getDeliveryPickupPoint = useCallback(debounce(async ({ zipCode = '', city = '' }: { zipCode?: string; city?: string }): Promise<void> => {
		if (!isEmpty(zipCode) || !isEmpty(city)) {
			await dispatch(searchCartDeliveryPickupPoint({ zipCode, city }))
		}
	}, 1000), [])

	const handleChangeZipCode = useCallback(async (postalCode: string): Promise<void> => {
		await getHogaDictionary(postalCode ?? '')

		setIsInitialDropdownOpen(true)
	}, [])

	const handleChangeCity = useCallback(async (town: string): Promise<void> => {
		if (!isEmpty(town)) {
			await getDeliveryPickupPoint({ zipCode, city: town })
		}
	}, [zipCode])

	const handleChangeDropdownList = useCallback(async (): Promise<void> => {
		if (isEqual(size(list), 1)) {
			const town = list[0]

			setValue('city', town)

			await handleChangeCity(town)
		} else if (!isEmpty(list) && isInitialDropdownOpen) {
			onOpenDropdown()
		}
	}, [list, isInitialDropdownOpen])

	const handleSelectPoint = useCallback((point: IPickupPointData): void => {
		onSetPickupPointId(point.id)
	}, [onSetPickupPointId])

	useEffect(() => {
		(async () => {
			if (!isNull(pickupPoint)) {
				await getHogaDictionary(pickupPoint.address.zipCode ?? '')
				await getDeliveryPickupPoint({
					zipCode: pickupPoint.address.zipCode,
					city: pickupPoint.address.city,
				})

				setMapZoom(16)
				setIsInitialDropdownOpen(false)
			}
		})()

		return () => {
			(async () => {
				await dispatch(clearCartDeliveryPickupPoint())
			})()
		}
	}, [])

	useEffect(() => {
		(async () => {
			await handleChangeDropdownList()
		})()
	}, [list])

	return (
		<div className={ styles.wrapper }>
			<form className={ styles.form }>
				<div>
					{ t('preview.delivery.pickupPoints.caption') }
				</div>

				<div className={ styles.inputs }>
					<Input
						control={ control }
						name="zipCode"
						label={ t('preview.delivery.pickupPoints.zipCode') }
						mask={ mask.zipCode }
						theme={ { labelWrapper: styles.label } }
						onChange={ handleChangeZipCode }
					/>

					<DropdownInput
						isMenuOpen={ isDropdownOpen }
						control={ control }
						name="city"
						label={ t('preview.delivery.pickupPoints.chosenCity') }
						options={ list }
						tooltipLabel={ t('preview.delivery.pickupPoints.cityTooltip') }
						theme={ { wrapper: styles.cityField } }
						onToggle={ onToggleDropdown }
						onChange={ handleChangeCity }
					/>
				</div>
			</form>

			<CartPreviewDeliveryEcommercePickupPointMap
				pickupPoint={ pickupPoint }
				mapZoom={ mapZoom }
				geolocation={ geolocation }
				pickupPointList={ pickupPointList }
				selectedPointId={ pickupPointId }
				onSelect={ handleSelectPoint }
			/>
		</div>
	)
}

export { CartPreviewDeliveryEcommercePickupPoint }
