import currency, { Options } from 'currency.js'
import { useTranslation } from 'next-i18next'
import { isInteger, isString, toNumber } from 'lodash'

import { IUsePriceData } from '~/hooks/price'

export const usePrice = (): IUsePriceData => {
	const { t } = useTranslation(['common'])

	const priceFormat = (price: number, params?: Options): string => (
		// TODO: change type in customer-preference-service/last viewed
		currency(isString(price) ? toNumber(price) : price, {
			symbol: t('currency'),
			separator: '',
			decimal: ',',
			precision: isInteger(price) ? 0 : 2,
			pattern: '# !',
			...params,
		}).format()
	)

	return {
		priceFormat,
	}
}
