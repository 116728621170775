import React, { ReactElement, useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

import { Button } from '~/components/core/button'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { ICartPreviewDiscountLysRemoveOrEditButtonProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysSubmit/cartPreviewDiscountLysRemoveOrEditButton'
import { putCartLoyaltyProgramRemoveCard } from '~/api/requests/cart'
import { useCartPreviewCalculate } from '~/hooks/cartPreviewCalculate'

import { ICartPreviewDiscountLysFormCode, ICartPreviewDiscountLysSelectedReward } from '../../types'

import styles from './CartPreviewDiscountLysRemoveOrEditButton.module.scss'

const CartPreviewDiscountLysRemoveOrEditButton = (props: ICartPreviewDiscountLysRemoveOrEditButtonProps): ReactElement => {
	const { onRequestLoyaltyReward, additionalClass } = props
	const { onSetEditMode, onSetIsSelectedReward, onSetSelectedReward } = useContext(LysLoyaltyProviderContext)
	const { onRemoveDiscount } = useCartPreviewCalculate()
	const { setValue, resetField } = useFormContext<ICartPreviewDiscountLysFormCode>()
	const { t } = useTranslation(['cart'])

	const handleRemoveLoyaltyReward = useCallback(async (): Promise<void> => {
		const { data: { success } } = await putCartLoyaltyProgramRemoveCard()

		if (success) {
			await onRemoveDiscount()

			const reward: ICartPreviewDiscountLysSelectedReward = {
				code: '',
				type: null,
			}

			onSetIsSelectedReward(false)
			onSetSelectedReward(reward)
			setValue('promotionCode', '')
			resetField('promotionCode')
		}

		onSetEditMode(true)

		onRequestLoyaltyReward()
	}, [onRequestLoyaltyReward, onRemoveDiscount, onSetSelectedReward, onSetEditMode, onSetIsSelectedReward])

	const buttonClass = classNames(styles.wrapper, additionalClass)

	return (
		<Button
			variant="neutral"
			size="inherit"
			additionalClass={ buttonClass }
			text={ t('preview.aside.lysDiscount.submitButton.removeOrEdit') }
			onClick={ handleRemoveLoyaltyReward }
		/>
	)
}

export { CartPreviewDiscountLysRemoveOrEditButton }
