import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { ICartCustomerInvoiceCorporateBoxProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerInvoiceCorporateBox.module.scss'

const CartCustomerInvoiceCorporateBox = (props: ICartCustomerInvoiceCorporateBoxProps): ReactElement => {
	const { invoice } = props
	const { t } = useTranslation(['cart'])

	const { address, contact } = invoice
	const { nip, companyName } = contact || {}
	const { street, houseNumber, flatNumber, zipCode, city } = address || {}

	const renderFlatNumber = useCallback((): ReactElement | null => {
		if (!isEmpty(flatNumber)) {
			return (
				<span>
					{ '/' }{ flatNumber }
				</span>
			)
		}

		return null
	}, [flatNumber])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.company }>
				<div className={ styles.nip }>
					{ t('customer.invoice.nip') }{ ': ' }{ nip }
				</div>

				<div className={ styles.companyName }>
					{ companyName }
				</div>
			</div>

			<div className={ styles.address }>
				<div>
					<span>
						{ street }{ ' ' }{ houseNumber }
					</span>

					{ renderFlatNumber() }
				</div>

				<div>
					{ zipCode }{ ', ' }{ city }
				</div>
			</div>
		</div>
	)
}

export { CartCustomerInvoiceCorporateBox }
