import { AppPromiseThunkAction } from '~/actions'
import { GetServiceCartConfigurationActionType, ClearServiceCartSlotActionType, GetServiceCartDesignerActionType, GetServiceCartPreviewActionType, GetServiceCartScheduleActionType, GetServiceCartStoresActionType, ServiceCartPreviewType, SetServiceCartDesignerActionType, SetServiceCartOnlineAppointmentActionType, SetServiceCartSlotActionType, SetServiceCartStoreActionType, ICalendarVisibleDates, SetServiceCartVisibleDatesActionType } from '~/actions/serviceCartPreview'
import { getCalendarCart, getCalendarDesigners, getCalendarSchedule, getCalendarServicesStores, getCalendarConfiguration } from '~/api/requests/calendarService'
import { ICalendarDesignerData, ICalendarDesignerQueryParams, ICalendarScheduleQueryParams, ICalendarServiceSimpleStoreData, ICalendarServiceStoresQueryParams, ICalendarSlotData } from '~/api/dataTypes/calendarService'
import { withFresherToken } from '~/actions/auth'

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartPreviewData = withFresherToken((): AppPromiseThunkAction<GetServiceCartPreviewActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.GET_SERVICE_CART_PREVIEW,
		payload: getCalendarCart(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartConfiguration = withFresherToken((): AppPromiseThunkAction<GetServiceCartConfigurationActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.GET_SERVICE_CART_CONFIGURATION,
		payload: getCalendarConfiguration(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartStoreData = withFresherToken((params: ICalendarServiceStoresQueryParams): AppPromiseThunkAction<GetServiceCartStoresActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.GET_SERVICE_CART_STORES,
		payload: getCalendarServicesStores(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartScheduleData = withFresherToken((params: ICalendarScheduleQueryParams): AppPromiseThunkAction<GetServiceCartScheduleActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.GET_SERVICE_CART_SCHEDULE,
		payload: getCalendarSchedule(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getServiceCartDesignerData = withFresherToken((params: ICalendarDesignerQueryParams): AppPromiseThunkAction<GetServiceCartDesignerActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.GET_SERVICE_CART_DESIGNER,
		payload: getCalendarDesigners(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceOnlineAppointment = withFresherToken((isOnline: boolean): AppPromiseThunkAction<SetServiceCartOnlineAppointmentActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.SET_SERVICE_CART_ONLINE_APPOINTMENT,
		payload: Promise.resolve(isOnline),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartStore = withFresherToken((store: ICalendarServiceSimpleStoreData): AppPromiseThunkAction<SetServiceCartStoreActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.SET_SERVICE_CART_STORE,
		payload: Promise.resolve(store),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartDesigner = withFresherToken((params: ICalendarDesignerData): AppPromiseThunkAction<SetServiceCartDesignerActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.SET_SERVICE_CART_DESIGNER,
		payload: Promise.resolve(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartSlot = withFresherToken((slot: ICalendarSlotData): AppPromiseThunkAction<SetServiceCartSlotActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.SET_SERVICE_CART_SLOT,
		payload: Promise.resolve(slot),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const clearServiceCartSlot = withFresherToken((): AppPromiseThunkAction<ClearServiceCartSlotActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.CLEAR_SERVICE_CART_SLOT,
		payload: Promise.resolve(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setServiceCartVisibleDates = withFresherToken((dates: ICalendarVisibleDates): AppPromiseThunkAction<SetServiceCartVisibleDatesActionType> => async (dispatch) => {
	return dispatch({
		type: ServiceCartPreviewType.SET_SERVICE_CART_VISIBLE_DATES,
		payload: Promise.resolve(dates),
	})
})
