import React, { ReactElement } from 'react'
import Script from 'next/script'

const SareScript = (): ReactElement => {
	return (
	// eslint-disable-next-line @next/next/no-before-interactive-script-outside-document, react/jsx-newline
		<Script
			src="https://leroy-merlin.net.pl/i/2cd4a8e7f466bb55db82956caaafcac9.js"
			id="sare-script"
			strategy="beforeInteractive"
		/>
	)
}

export { SareScript }
