import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { isEmpty, isEqual, size } from 'lodash'

import { ICartCustomerInvoiceModalPrivateForm } from '~/components/cart/cartCustomer'
import { Input, RequiredInfo, DropdownInput } from '~/components/core/form'
import { useHogaDictionary } from '~/hooks/hogaDictionary'
import { validation } from '~/utils/validation'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerInvoiceModalPrivateFormFields.module.scss'

const CartCustomerInvoiceModalPrivateFormFields = (): ReactElement => {
	const { control, setValue, watch } = useFormContext<ICartCustomerInvoiceModalPrivateForm>()
	const { t } = useTranslation(['cart'])
	const { isDropdownOpen, list, getHogaDictionary, onOpenDropdown, onToggleDropdown } = useHogaDictionary()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { zipCode: postalCode, city } = watch()
	const { mask: { zipCode } } = validation

	const handleChangePostalCode = useCallback(async (): Promise<void> => {
		await getHogaDictionary(postalCode ?? '')
	}, [postalCode])

	const handleChangeSelectList = useCallback((): void => {
		if (isEqual(size(list), 1) && isEmpty(city)) {
			setValue('city', list[0])
		} else if (!isEmpty(list)) {
			onOpenDropdown()
		}
	}, [list, city])

	useEffect(() => {
		(async () => {
			await handleChangePostalCode()
		})()
	}, [postalCode])

	useEffect(() => {
		handleChangeSelectList()
	}, [list])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<Input
				isRequired
				control={ control }
				name="firstName"
				label={ t('customer.invoice.invoicePrivateForm.firstNameLabel') }
			/>

			<Input
				isRequired
				control={ control }
				name="lastName"
				label={ t('customer.invoice.invoicePrivateForm.lastNameLabel') }
			/>

			<Input
				isRequired
				control={ control }
				name="street"
				label={ t('customer.invoice.invoicePrivateForm.streetLabel') }
			/>

			<div className={ styles.addressNumbersControls }>
				<Input
					isRequired
					control={ control }
					name="houseNumber"
					label={ t('customer.invoice.invoicePrivateForm.buildingNumberLabel') }
					additionalClass={ styles.control }
				/>

				<Input
					control={ control }
					name="flatNumber"
					label={ t('customer.invoice.invoicePrivateForm.flatNumberLabel') }
					additionalClass={ styles.control }
				/>
			</div>

			<div className={ styles.cityControls }>
				<Input
					isRequired
					control={ control }
					name="zipCode"
					label={ t('customer.invoice.invoicePrivateForm.zipCodeLabel') }
					mask={ zipCode }
					additionalClass={ styles.control }
				/>

				<DropdownInput
					isRequired
					isMenuOpen={ isDropdownOpen }
					control={ control }
					name="city"
					label={ t('customer.invoice.invoicePrivateForm.cityLabel') }
					options={ list }
					theme={ { wrapper: styles.control } }
					onToggle={ onToggleDropdown }
				/>
			</div>

			<RequiredInfo />
		</div>
	)
}

export { CartCustomerInvoiceModalPrivateFormFields }
