import { TFunction } from 'next-i18next'

import { ICartCustomerGuestData, ICartCustomerContact } from '~/api/dataTypes/cart'

export const getCustomerGuestContact = (customerGuest: ICartCustomerGuestData): ICartCustomerContact => {
	const { companyName, firstName, lastName, phone, email } = customerGuest

	return {
		companyName: companyName || null,
		firstName,
		lastName,
		email,
		phone,
	}
}

export const getCustomerNextTooltipInvoiceInfo = (t: TFunction, isNextButtonDisabledByInvoice: boolean, isNextButtonDisabled: boolean): string => {
	if (isNextButtonDisabled) {
		return t('customer.aside.summary.button.disabledDataInfo')
	}

	if (isNextButtonDisabledByInvoice) {
		return t('customer.aside.summary.button.disabledInvoiceInfo')
	}

	return ''
}
