import React, { ReactElement, useCallback } from 'react'
import { isEmpty, isUndefined } from 'lodash'
import classNames from 'classnames'

import { Icon, ICON_TYPE } from '~/components/core/icon'
import { IRadioIconProps } from '~/components/core/form'
import { vars } from '~/statics/variables'
import { useMozaic } from '~/hooks/mozaic'

import styles from './RadioIcon.module.scss'

const RadioIcon = (props: IRadioIconProps): ReactElement => {
	const { isChecked, error } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const isError = !isUndefined(error) && !isEmpty(error.message)
	const iconName: keyof typeof ICON_TYPE = isChecked ? 'radioFull' : 'radio'
	const iconColor: keyof typeof vars.colors = isError ? 'red' : isChecked ? 'navy' : 'black10'

	const iconClass = classNames(styles.icon, {
		[styles.checked]: isChecked,
		[styles.error]: isError,
	})

	const renderContent = useCallback((): ReactElement => (
		shouldUseMozaic ? (
			<div className={ styles.wrapper }>
				<span className={ iconClass } />
			</div>
		) : (
			<Icon
				name={ iconName }
				width={ 20 }
				color={ iconColor }
				additionalClass={ styles.wrapper }
			/>
		)
	), [shouldUseMozaic, isChecked, isError])

	return (
		<>
			{ renderContent() }
		</>
	)
}

export { RadioIcon }
