import React, { ReactElement, useCallback } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { useMozaic } from '~/hooks/mozaic'
import { ICartHeaderStepTitleProps, CartHeaderStepCounter } from '~/components/core/cartHeader'
import { Button } from '~/components/core/button'

import styles from './CartHeaderStepTitle.module.scss'

const CartHeaderStepTitle = (props: ICartHeaderStepTitleProps): ReactElement => {
	const { step } = props
	const { getShouldUseMozaicFlag } = useMozaic()
	const router = useRouter()

	const { isActive, isPrevious, isDisabled, stepNumber, title, url } = step

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const handleGoToUrl = useCallback(async (): Promise<void> => {
		await router.push(url)
	}, [url])

	const titleClass = classNames(styles.title, {
		[styles.isMozaic]: shouldUseMozaic,
		[styles.active]: isActive || isPrevious,
	})

	return (
		<Button
			variant="neutral"
			isDisabled={ !isPrevious || isDisabled }
			additionalClass={ styles.wrapper }
			onClick={ handleGoToUrl }
		>
			<CartHeaderStepCounter
				stepNumber={ stepNumber }
				isActive={ isActive }
				isPrevious={ isPrevious }
				isMozaic={ shouldUseMozaic }
			/>

			<span className={ titleClass }>
				{ title }
			</span>
		</Button>
	)
}

export { CartHeaderStepTitle }
