import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty } from 'lodash'

import { HeaderTopBarSearchBarSuggestionsList, HeaderTopBarSearchBarTitleTile, IHeaderTopBarSearchBarSuggestionsProps } from '~/components/core/layout'

const HeaderTopBarSearchBarSuggestions = (props: IHeaderTopBarSearchBarSuggestionsProps): ReactElement | null => {
	const { suggestions, query } = props
	const { t } = useTranslation(['header'])

	if (isEmpty(suggestions)) {
		return null
	}

	return (
		<div>
			<HeaderTopBarSearchBarTitleTile title={ t('search.suggestions') } />

			<HeaderTopBarSearchBarSuggestionsList
				suggestions={ suggestions }
				query={ query }
			/>
		</div>
	)
}

export { HeaderTopBarSearchBarSuggestions }
