import { IServiceCartPreviewState } from '~/state/reducers/serviceCartPreviewReducer'

export const serviceCartPreviewInitialState: IServiceCartPreviewState = {
	data: {
		id: null,
		cartStatus: 'APPOINTMENT',
		cartUuid: null,
		customerId: null,
		designer: null,
		onlineAppointment: false,
		service: {
			id: null,
			lmReference: '',
			name: '',
			content: '',
			imageUrl: '',
			price: 0,
		},
		slot: null,
		store: null,
	},
	configuration: {
		message: '',
		meetingOnline: false,
		guestOrderingAvailable: false,
		maxMinutesToPayOrder: 0,
	},
	storesData: [],
	scheduleData: [],
	designerData: {
		anyDesigner: {
			firstName: '',
			operatorId: 0,
			imageUrl: '',
			store: {
				id: 0,
				name: '',
				storeCode: '',
			},
		},
		availableDesignerList: [],
		unavailableDesignerList: [],
	},
	calendarVisibleDates: {
		dateFrom: null,
		dateTo: null,
	},
	isPending: false,
	isStoresPending: false,
	isSchedulePending: false,
	isDesignerPending: false,
}
