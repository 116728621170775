import React, { ReactElement } from 'react'
import { Marker } from '@react-google-maps/api'

import { vars } from '~/statics'
import { IGoogleMapsMarkerLocationProps } from '~/components/core/googleMaps'

import styles from './GoogleMapsMarkerLocation.module.scss'

const GoogleMapsMarkerLocation = (props: IGoogleMapsMarkerLocationProps): ReactElement => {
	const { position } = props

	return (
		<div className={ styles.wrapper }>
			<Marker
				position={ position }
				icon={ vars.images.locationPoint }
				clickable={ false }
				zIndex={ 0 }
			/>
		</div>
	)
}

export { GoogleMapsMarkerLocation }
