import { IServiceCartCustomerState } from '~/state/reducers/serviceCartCustomerReducer'

export const serviceCartCustomerInitialState: IServiceCartCustomerState = {
	data: {
		firstName: '',
		lastName: '',
		email: '',
		address: null,
		companyName: null,
		customerTitle: null,
		customerType: null,
		invoiceData: null,
		invoiceConfiguration: null,
		nip: null,
		phone: null,
		calendarService: null,
		orderingAsGuest: false,
	},
	isPending: false,
}
