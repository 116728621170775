import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { useDate } from '~/hooks/date'

import styles from './CartPreviewDeliveryPickupTransferTimeslotsDescription.module.scss'

const CartPreviewDeliveryPickupTransferTimeslotsDescription = (): ReactElement => {
	const { t } = useTranslation(['cart'])
	const { formatDate, getDateFullTimeFormat, getTodayDate } = useDate()

	return (
		<div className={ styles.wrapper }>
			<div>
				{ t('preview.delivery.groups.storeDelivery.availableDate') }

				<span className={ styles.currentTime }>
					{ formatDate(getTodayDate(), getDateFullTimeFormat()) }
				</span>
			</div>

			<div>
				<span>
					{ t('preview.delivery.groups.storeDelivery.dateReservation') }
				</span>
			</div>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTimeslotsDescription }
