import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { ICartTransportFromStoreModalProps } from '~/components/cart/cartTransportFromStoreModal'

import styles from './CartTransportFromStoreModal.module.scss'

const CartTransportFromStoreModal = (props: ICartTransportFromStoreModalProps): ReactElement => {
	const { isOpen, onBack, onClose } = props
	const { t } = useTranslation(['cart'])

	const handleBack = useCallback((): void => {
		onBack()
		onClose()
	}, [onBack])

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.modal }
			title={ t('customer.transportFromStore.caption') }
			onClose={ onClose }
		>
			<div className={ styles.wrapper }>
				<div className={ styles.description }>
					{ t('customer.transportFromStore.description') }
				</div>

				<div className={ styles.buttons }>
					<Button
						variant="secondary"
						text={ t('customer.transportFromStore.button.back') }
						onClick={ handleBack }
					/>

					<Button
						isReverse
						variant="secondary"
						text={ t('customer.transportFromStore.button.next') }
						onClick={ onClose }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { CartTransportFromStoreModal }
