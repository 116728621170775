import { ApiServiceType } from '~/api/dataTypes/axios'
import { PLANNER_CUSTOMER_SERVICE_NEW } from '~/utils/constants'
import { localStorageGet } from '~/utils/storage'

export const getCustomerPlannerServicePath = (): string => {
	const isNewCustomerPlannerServicePath = localStorageGet(PLANNER_CUSTOMER_SERVICE_NEW)

	return isNewCustomerPlannerServicePath ? '/projects/v2' : '/v1/projects'
}

export const getAuthlessCustomerPlannerServiceForPath = (): string => {
	const isNewCustomerPlannerServicePath = localStorageGet(PLANNER_CUSTOMER_SERVICE_NEW)

	return isNewCustomerPlannerServicePath ? '/authless/projects/v2' : '/v1/planner/projects'
}

export const getAuthlessServiceForCreatePlannerProject = (): ApiServiceType => {
	const isNewCustomerPlannerServicePath = localStorageGet(PLANNER_CUSTOMER_SERVICE_NEW)

	return isNewCustomerPlannerServicePath ? 'CUSTOMER_PLANNER_SERVICE' : 'AUTHLESS_WEB_INTERACTION_SERVICE'
}
