import React, { ReactElement } from 'react'

import { IHeaderTopBarMiniCartModalWrapperProps } from '~/components/core/layout'

import styles from './HeaderTopBarMiniCartModalWrapper.module.scss'

const HeaderTopBarMiniCartModalWrapper = (props: IHeaderTopBarMiniCartModalWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { HeaderTopBarMiniCartModalWrapper }
