import React, { ReactElement, useCallback, useContext, useMemo, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'
import { includes, isEqual } from 'lodash'

import { Container } from '~/components/core/layout/container'
import { IProfitListItem } from '~/components/core/profitsList'
import { Breadcrumbs } from '~/components/core/breadcrumbs'
import { BREADCRUMBS, SignInAsNew, SignInForm, SignInWrapper } from '~/components/signIn'
import { UserContext } from '~/providers/userProvider'
import { Loader } from '~/components/core/loader'
import { useAdeoSignIn, useSignIn } from '~/hooks/signIn'

import styles from './SignIn.module.scss'

const SignIn = (): ReactElement => {
	const { t, i18n } = useTranslation(['signIn'])
	const { isLogged } = useContext(UserContext)
	const { handleRedirectAfterSignIn } = useSignIn()
	const { shouldLoginByAdeo } = useAdeoSignIn()
	const router = useRouter()

	const isShopVersion = isEqual(router.query?.isShopVersion, 'true') || includes(router.query?.from, 'isShopVersion')

	const profits = useMemo((): IProfitListItem[] => [
		{
			name: 'personalData',
			icon: 'profitPersonalData',
			content: t('profits.personalData'),
		},
		{
			name: 'order',
			icon: 'profitOrder',
			content: t('profits.order'),
		},
		{
			name: 'history',
			icon: 'profitHistory',
			content: t('profits.history'),
		},
	], [t, i18n])

	const renderLoading = useCallback((): ReactElement | null => {
		if (!isLogged) {
			return null
		}

		return <Loader fullPage theme={ { overlay: styles.overlay } } />
	}, [isLogged])

	useEffectOnce(() => {
		if (isLogged && !shouldLoginByAdeo) {
			(async () => {
				await handleRedirectAfterSignIn()
			})()
		}
	})

	useEffect(() => {
		if (isLogged && shouldLoginByAdeo) {
			(async () => {
				await handleRedirectAfterSignIn()
			})()
		}
	}, [isLogged, shouldLoginByAdeo])

	if (isShopVersion) {
		return (
			<Container additionalClass={ styles.wrapper }>
				{ renderLoading() }

				<SignInWrapper>
					<SignInForm caption={ t('caption.currentCustomer') } theme={ { wrapper: styles.signInWrapper, actions: styles.signInActions } } />
				</SignInWrapper>
			</Container>
		)
	}

	return (
		<Container>
			{ renderLoading() }

			<Breadcrumbs breadcrumbs={ BREADCRUMBS } />

			<SignInWrapper>
				<SignInForm caption={ t('caption.currentCustomer') } />

				<SignInAsNew caption={ t('description.newCustomer') } profits={ profits } />
			</SignInWrapper>
		</Container>
	)
}

export { SignIn }
