import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { ICalendarDesignerData, ICalendarDesignerResponse, ICalendarScheduleResponse, ICalendarServiceConfigurationResponse, ICalendarServiceResponse, ICalendarServiceSimpleStoreData, ICalendarServiceStoreResponse, ICalendarSlotData } from '~/api/dataTypes/calendarService'

export interface ICalendarVisibleDates {
	dateFrom: string | null
	dateTo: string | null
}

export enum ServiceCartPreviewType {
	GET_SERVICE_CART_PREVIEW = 'serviceCartPreview/GET_SERVICE_CART_PREVIEW',
	GET_SERVICE_CART_STORES = 'serviceCartPreview/GET_SERVICE_CART_STORES',
	GET_SERVICE_CART_SCHEDULE = 'serviceCartPreview/GET_SERVICE_CART_SCHEDULE',
	GET_SERVICE_CART_DESIGNER = 'serviceCartPreview/GET_SERVICE_CART_DESIGNER',
	SET_SERVICE_CART_ONLINE_APPOINTMENT = 'serviceCartPreview/SET_SERVICE_CART_ONLINE_APPOINTMENT',
	SET_SERVICE_CART_STORE = 'serviceCartPreview/SET_SERVICE_CART_STORE',
	SET_SERVICE_CART_DESIGNER = 'serviceCartPreview/SET_SERVICE_CART_DESIGNER',
	GET_SERVICE_CART_CONFIGURATION = 'serviceCartPreview/GET_SERVICE_CART_CONFIGURATION',
	SET_SERVICE_CART_SLOT = 'serviceCartPreview/SET_SERVICE_CART_SLOT',
	CLEAR_SERVICE_CART_SLOT = 'serviceCartPreview/CLEAR_SERVICE_CART_SLOT',
	SET_SERVICE_CART_VISIBLE_DATES = 'serviceCartPreview/SET_SERVICE_CART_VISIBLE_DATES',
}

export type GetServiceCartPreviewActionType = AppAction<ServiceCartPreviewType.GET_SERVICE_CART_PREVIEW, AxiosResponse<ICalendarServiceResponse>>
export type GetServiceCartStoresActionType = AppAction<ServiceCartPreviewType.GET_SERVICE_CART_STORES, AxiosResponse<ICalendarServiceStoreResponse>>
export type GetServiceCartScheduleActionType = AppAction<ServiceCartPreviewType.GET_SERVICE_CART_SCHEDULE, AxiosResponse<ICalendarScheduleResponse>>
export type GetServiceCartDesignerActionType = AppAction<ServiceCartPreviewType.GET_SERVICE_CART_DESIGNER, AxiosResponse<ICalendarDesignerResponse>>
export type SetServiceCartOnlineAppointmentActionType = AppAction<ServiceCartPreviewType.SET_SERVICE_CART_ONLINE_APPOINTMENT, boolean>
export type SetServiceCartStoreActionType = AppAction<ServiceCartPreviewType.SET_SERVICE_CART_STORE, ICalendarServiceSimpleStoreData>
export type SetServiceCartDesignerActionType = AppAction<ServiceCartPreviewType.SET_SERVICE_CART_DESIGNER, ICalendarDesignerData>
export type GetServiceCartConfigurationActionType = AppAction<ServiceCartPreviewType.GET_SERVICE_CART_CONFIGURATION, AxiosResponse<ICalendarServiceConfigurationResponse>>
export type SetServiceCartSlotActionType = AppAction<ServiceCartPreviewType.SET_SERVICE_CART_SLOT, ICalendarSlotData>
export type ClearServiceCartSlotActionType = AppAction<ServiceCartPreviewType.CLEAR_SERVICE_CART_SLOT>
export type SetServiceCartVisibleDatesActionType = AppAction<ServiceCartPreviewType.SET_SERVICE_CART_VISIBLE_DATES, ICalendarVisibleDates>

export type ServiceCartPreviewActionType = GetServiceCartPreviewActionType | GetServiceCartStoresActionType | GetServiceCartScheduleActionType | GetServiceCartDesignerActionType | SetServiceCartOnlineAppointmentActionType | SetServiceCartStoreActionType | SetServiceCartDesignerActionType | GetServiceCartConfigurationActionType | SetServiceCartSlotActionType | ClearServiceCartSlotActionType | SetServiceCartVisibleDatesActionType
