import { IAccountLoyaltyExchangePointsItem, IAccountLoyaltyHistory, IAccountLoyaltyProgramState, IAccountLoyaltyJoinState, IAccountLoyaltyExchangePointsSuccess } from '~/state/reducers/accountReducer'

import { ACCOUNT_LOYALTY_HISTORY_DETAILS_MOCK } from './accountLoyaltyHistoryDetailsMock'

export const ACCOUNT_LOYALTY_HISTORY_MOCK: IAccountLoyaltyHistory = {
	items: [
		{
			id: '1',
			date: 1637306405,
			cardNumber: '2046500582453',
			actionType: 'ADDITIONAL_CARD',
			pointsBalance: 100,
		},
		{
			id: '2',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'CUSTOMER_ASSIGN',
			pointsBalance: 20,
		},
		{
			id: '3',
			date: 1637306405,
			cardNumber: '2046500582221',
			actionType: 'MIGRATION_POINTS',
			pointsBalance: -20,
		},
		{
			id: '4',
			date: 1637306405,
			cardNumber: '2046500581111',
			actionType: 'EXCHANGE_POINTS_FOR_VOUCHER',
			pointsBalance: 400,
		},
		{
			id: '5',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'REGISTRATION',
			pointsBalance: 0,
		},
		{
			id: '6',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'EXCHANGE_POINTS',
			pointsBalance: 0,
		},
		{
			id: '7',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'DIRECT_EXCHANGE_POINTS',
			pointsBalance: -20,
		},
		{
			id: '8',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'POINTS_CORRECTION',
			pointsBalance: -20,
		},
		{
			id: '9',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'EXPIRED_POINTS',
			pointsBalance: -20,
		},
		{
			id: '10',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'RETURN',
			pointsBalance: 0,
		},
		{
			id: '11',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'SALE',
			pointsBalance: -20,
		},
		{
			id: '12',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'SALE',
			pointsBalance: -20,
		},
		{
			id: '13',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'SALE',
			pointsBalance: -20,
		},
		{
			id: '15',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'RETURN',
			pointsBalance: -20,
		},
		{
			id: '16',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'RETURN',
			pointsBalance: 0,
		},
		{
			id: '17',
			date: 1637306405,
			cardNumber: '2046500582222',
			actionType: 'RETURN',
			pointsBalance: -20,
		},
		{
			id: '18',
			date: 1637306405,
			cardNumber: '2046500581111',
			actionType: 'RETURN',
			pointsBalance: -200,
		},
		{
			id: '19',
			date: 1637306405,
			cardNumber: '2046500581111',
			actionType: 'RETURN',
			pointsBalance: -40,
		},
		{
			id: '20',
			date: 1637306405,
			cardNumber: '2046500581111',
			actionType: 'ADDITIONAL_CARD',
			pointsBalance: 0,
		},
		{
			id: '21',
			date: 1637306405,
			cardNumber: '2046500581111',
			actionType: 'RETURN',
			pointsBalance: 0,
		},
	],
	dateFilter: '1',
	sort: '1',
	pagination: {
		perPage: 10,
		totalPages: 3,
		page: 1,
		totalItems: 21,
	},
	details: ACCOUNT_LOYALTY_HISTORY_DETAILS_MOCK,
}

export const ACCOUNT_LOYALTY_EXCHANGE_POINTS_MOCK: IAccountLoyaltyExchangePointsItem[] = [
	{
		id: '1123',
		expired: null,
		missingPoints: null,
		cost: 20,
		type: 'VOUCHER',
		name: 'Rabat lojalnościowy: 100 zł',
		value: '-100 zł',
	},
	{
		id: '1234',
		expired: 1672312860,
		missingPoints: null,
		cost: 10,
		type: 'VOUCHER',
		name: 'Rabat lojalnościowy: 50 zł',
		value: '-50 zł',
	},
	{
		id: '1155',
		expired: 1672312860,
		missingPoints: 20,
		cost: 130,
		type: 'VOUCHER',
		name: 'Rabat lojalnościowy: 300 zł',
		value: '-300 zł',
	},
	{
		id: '1166',
		expired: 1672312860,
		missingPoints: null,
		cost: 20,
		type: 'EXCHANGE_POINTS',
		name: 'na dowolny rabat powyżej 100 zł',
		value: null,
	},
	{
		id: '1177',
		expired: null,
		missingPoints: null,
		cost: null,
		type: 'FOUNDATION',
		name: null,
		value: null,
	},
]

export const ACCOUNT_LOYALTY_EXCHANGE_POINTS_SUCCESS_MOCK: IAccountLoyaltyExchangePointsSuccess = {
	id: '1123',
	cost: 20,
	name: 'Rabat lojalnościowy: 100 zł',
	value: '-100 zł',
	info: '<strong>Kod rabatowy uprawniający Cię do zniżki w sklepach Leroy Merlin został wysłany na Twój adres e-mail,</strong> a z Twojego konta lojalnościowego została odjęta odpowiednia liczba punktów',
	tips: {
		caption: 'Jak skorzystać z rabatu?',
		points: [
			'w sklepie stacjonarnym podaj otrzymany kod rabatowy kasjerowi w trakcie skanowania produktów',
			'na stronie internetowej wpisz otrzymany kod rabatowy w koszyku, przed finalizacją zakupu',
			'kod rabatowy należy wykorzystać w podanym powyżej terminie, w przeciwnym wypadku rabat przepada',
		],
	},
	transferPoints: 240,
	discountValue: 240,
	discountExpDate: 1652684232,
	points: 250,
}

export const ACCOUNT_LOYALTY_JOIN_MOCK: IAccountLoyaltyJoinState = {
	policyText: 'Państwa dane osobowe przetwarzane będą w celu korzystania z Serwisu, w tym zakupu towarów lub usług, przez okres korzystania z Serwisu, a po tym czasie przez okres realizacji umowy oraz w zakresie wymaganym przez przepisy prawa lub dla zabezpieczenia ewentualnych roszczeń',
	policyFullText: 'Państwa dane osobowe przetwarzane będą w celu korzystania z Serwisu, w tym zakupu towarów lub usług, przez okres korzystania z Serwisu, a po tym czasie przez okres realizacji umowy oraz w zakresie wymaganym przez przepisy prawa lub dla zabezpieczenia ewentualnych roszczeń. Państwa dane osobowe przetwarzane będą w celu korzystania z Serwisu, w tym zakupu towarów lub usług, przez okres korzystania z Serwisu.',
	regulationPrivateConsent: {
		name: 'regulations',
		label: 'Oświadczam, iż zapoznałem się z treścią regulaminu Programu Lojalnościowego DOM oraz akceptuję jego postanowienia.',
		description: null,
		required: true,
	},
	regulationCompanyConsent: {
		name: 'regulations',
		label: 'Oświadczam, iż zapoznałem się z treścią regulaminu Programu Lojalnościowego PRO oraz akceptuję jego postanowienia.',
		description: null,
		required: true,
	},
	additionalCardConsent: {
		name: 'additionalCard',
		label: 'Chcę dodatkową kartę dla pracownika',
		description: 'Zaznacz, jeśli chcesz kartę dodatkową dla pracownika. Karta zbiera punkty, ale nie upoważnia do korzystania z bonów klubu PRO',
		required: false,
	},
}

export const ACCOUNT_LOYALTY_MOCK: IAccountLoyaltyProgramState = {
	// PRIVATE / COMPANY
	type: 'PRIVATE',
	// null / 2046500582453
	cardNumber: '2046500582453',
	additionalCard: null,
	loyaltyClubUrl: '/klub',
	regulationsUrl: '/todoRegulationsUrl',
	contactMail: 'programdom@leroymerlin.pl',
	resignMail: 'rezygnacja@leroymerlin.pl',
	discounts: [
		{
			name: 'Rabat lojalnościowy: 5%',
			expDate: 1652684232,
		},
	],
	name: 'Jan Kowalski',
	balance: 750,
	expiringBalance: 30,
	cardsLimit: 3,
	cards: [
		{
			number: '2046500582453',
			type: 'MAIN',
			status: 'ACTIVE',
		},
		{
			number: '2046502051241',
			type: 'ADDITIONAL',
			status: 'BLOCKED',
		},
		{
			number: '2046502052657',
			type: 'ADDITIONAL',
			status: 'INACTIVE',
		},
	],
	pointsActions: [
		{
			id: '1',
			action: 'Każde 5 zł wydane w Sklepie w przypadku transakcji bez Rabatu Lojalnościowego',
			points: 1,
		},
		{
			id: '2',
			action: 'Każde 10 zł wydane w Sklepie w przypadku transakcji z wykorzystaniem Rabatu Lojalnościowego',
			points: 1,
		},
		{
			id: '3',
			action: 'Każda opinia o produkcie',
			points: null,
		},
		{
			id: '4',
			action: 'Każda opinia o poradzie lub inspiracji',
			points: null,
		},
		{
			id: '5',
			action: 'Wyrażenie zgody na marketingową komunikację e-mailową',
			points: 30,
		},
		{
			id: '6',
			action: 'Wyrażenie zgody na marketingową komunikację telefoniczną',
			points: 20,
		},
	],
	history:  ACCOUNT_LOYALTY_HISTORY_MOCK,
	exchangePoints: {
		success: ACCOUNT_LOYALTY_EXCHANGE_POINTS_SUCCESS_MOCK,
		items: ACCOUNT_LOYALTY_EXCHANGE_POINTS_MOCK,
	},
	joinConsents: ACCOUNT_LOYALTY_JOIN_MOCK,
	transferExpDate: 1652684232,
}
