import React, { ReactElement, useCallback, useState } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import classNames from 'classnames'
import { isEmpty, lte, map, times } from 'lodash'

import { ErrorBoundary, IRatingProps, Label } from '~/components/core/form'
import { Icon } from '~/components/core/icon'

import styles from './Rating.module.scss'

const Rating = <FormFields extends FieldValues = FieldValues>(props: IRatingProps<FormFields>): ReactElement => {
	const {
		label, name, control,
		numberOfStars = 5,
		isDisabled = false,
		additionalClass = '',
	} = props
	const { field, fieldState: { error } } = useController<FormFields>({ control, name })
	const [hover, setHover] = useState<number>(Number(field.value))

	const stars: number[] = times(numberOfStars, (index: number) => index + 1)

	const handleClick = useCallback((index: number) => (): void => {
		field.onChange(index)
	}, [field.onChange])

	const handleMouseEnter = useCallback((index: number) => (): void => {
		setHover(index)
	}, [])

	const handleMouseLeave = useCallback((index: number) => (): void => {
		setHover(index)
	}, [])

	const renderStars = useCallback((): ReactElement => (
		<>
			{ map(stars, (star: number, index: number) => {
				const rate = index + 1
				const { value } = field

				const iconClass = classNames({
					[styles.icon]: true,
					[styles.selected]: lte(rate, hover) || lte(rate, value),
				})

				return (
					<button
						key={ star }
						disabled={ isDisabled }
						type="button"
						className={ styles.star }
						// eslint-disable-next-line react/jsx-props-no-spreading
						{ ...field }
						onClick={ handleClick(rate) }
						onMouseEnter={ handleMouseEnter(rate) }
						onMouseLeave={ handleMouseLeave(value) }
					>
						<Icon
							name="star"
							width={ 22 }
							additionalClass={ iconClass }
						/>
					</button>
				)
			}) }
		</>
	), [stars])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ wrapperClass }>
			<Label name={ name } caption={ label } />

			<div className={ styles.rating }>
				{ renderStars() }
			</div>

			<ErrorBoundary error={ error } />
		</div>
	)
}

export { Rating }
