import React, { ReactElement, useCallback, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { isEqual } from 'lodash'

import { Icon } from '~/components/core/icon'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { getIsPromotionEcommerce, getIsPromotionLoyalty, getIsPromotionNotApplied, getIsTargetedOffer } from '~/state/reducers/cartPreviewReducer'
import { CustomerLoyaltyRewardType } from '~/api/dataTypes/customerLoyalty'
import { UserContext } from '~/providers/userProvider'
import { vars } from '~/statics'
import { Image } from '~/components/core/image'

import styles from './CartPreviewDiscountLysPromoCodeInfo.module.scss'

const CartPreviewDiscountLysPromoCodeInfo = (): ReactElement | null => {
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const isLoyalty = useSelector(getIsPromotionLoyalty, shallowEqual)
	const isNotApplied = useSelector(getIsPromotionNotApplied, shallowEqual)
	const isTargetedOffer = useSelector(getIsTargetedOffer, shallowEqual)
	const { isLogged } = useContext(UserContext)
	const { isLysMigrated, loyaltyType, isEditMode, selectedReward } = useContext(LysLoyaltyProviderContext)
	const { t } = useTranslation(['cart'])

	const isPercentLoyalty = isEqual(selectedReward.type, 'PERCENT' as CustomerLoyaltyRewardType)
	const shouldHidePromoCodeInfo = isEditMode || isNotApplied || (isLoyalty && isPercentLoyalty)

	const renderSelectedCodeDescription = useCallback((): ReactElement | null => {
		if (isEcommerce) {
			return (
				<span className={ styles.selectedCodeDescription }>
					{ t('preview.aside.lysDiscount.legend.selectedCodeDescription') }
				</span>
			)
		}

		return null
	}, [isEcommerce])

	const renderSelectedCodeMainInfo = useCallback((): ReactElement | null => {
		if (isLogged && !isLysMigrated && !isTargetedOffer) {
			return null
		}

		if (isLogged && !isLysMigrated && isTargetedOffer) {
			return (
				<span dangerouslySetInnerHTML={ { __html: t('preview.aside.lysDiscount.legend.selectedTargetedOfferCodeDescription') } } />
			)
		}

		if (isLogged && isLysMigrated && isTargetedOffer) {
			return (
				<div className={ styles.targetedOfferSuccessWrapper }>
					<span dangerouslySetInnerHTML={ { __html: t('preview.aside.lysDiscount.legend.selectedTargetedOfferCodeDescription') } } />

					<div className={ styles.targetedOfferInfoWrapper }>
						<Image
							src={ vars.images.loyaltyCard }
							alt="loyalty card"
							width={ 24 }
							height={ 25 }
						/>

						<span>
							{ t('preview.aside.lysDiscount.legend.selectedTargetedOfferCodeDescriptionPro') }
						</span>
					</div>
				</div>
			)
		}

		return (
			<>
				<div className={ styles.successWrapper }>
					<div className={ styles.iconWrapper }>
						<Icon
							name="checkCircle"
							width={ 16 }
							height={ 16 }
							color="green"
						/>
					</div>

					<span className={ styles.caption }>
						{ t('preview.aside.lysDiscount.legend.selectedCodeInformationPoints', { loyaltyType }) }
					</span>
				</div>

				<span className={ styles.description }>
					{ t('preview.aside.lysDiscount.legend.selectedCodeInformation', { loyaltyType }) }
				</span>
			</>
		)
	}, [isLogged, isLysMigrated, isTargetedOffer])

	if (shouldHidePromoCodeInfo) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			{ renderSelectedCodeDescription() }

			{ renderSelectedCodeMainInfo() }
		</div>
	)
}

export { CartPreviewDiscountLysPromoCodeInfo }
