import React, { ReactElement } from 'react'

import { ICartPreviewDeliveryWrapperProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryWrapper.module.scss'

const CartPreviewDeliveryWrapper = (props: ICartPreviewDeliveryWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { CartPreviewDeliveryWrapper }
