import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty, map } from 'lodash'

import { IHolidaysData } from '~/api/dataTypes/store'
import { useDate } from '~/hooks/date'
import { IPreferredStoreInfoHolidaysProps } from '~/components/core/preferredStore'

import styles from './PreferredStoreInfoHolidays.module.scss'

const PreferredStoreInfoHolidays = (props: IPreferredStoreInfoHolidaysProps): ReactElement | null => {
	const { holidays } = props
	const { t } = useTranslation(['stores'])
	const { formatDateUnix, getDateShortFormat } = useDate()

	const renderHolidays = useCallback((): ReactElement => (
		<div className={ styles.items }>
			{ map(holidays, (item: IHolidaysData) => {
				const { dayOfDate, dayOfWeek, name } = item

				return (
					<div key={ dayOfDate } className={ styles.item }>
						<div>
							{ `${ formatDateUnix(dayOfDate, getDateShortFormat()) } (${ dayOfWeek })` }
						</div>

						<div>{ name }</div>
					</div>
				)
			}) }
		</div>
	), [holidays])

	if (isEmpty(holidays)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption }>{ t('holidays') }</div>

			{ renderHolidays() }
		</div>
	)
}

export { PreferredStoreInfoHolidays }
