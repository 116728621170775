import React, { ReactElement, ElementType } from 'react'

import { ICON_TYPE, IIconProps } from '~/components/core/icon'
import { vars } from '~/statics/variables'
import { IconProvider } from '~/providers/iconProvider'

const Icon = (props: IIconProps): ReactElement => {
	const {
		name, width,
		color = 'green',
		height = '100%',
		additionalClass = '',
		ariaHidden = true,
		ariaLabel = '',
	} = props

	const fillColor = vars.colors[color]
	const IconComponent: ElementType = ICON_TYPE[name]
	const initialHeight = typeof height === 'number' ? `${ height }px` : height
	const initialWidth = `${ width }px`

	return (
		<IconProvider width={ initialWidth } height={ initialHeight }>
			<IconComponent
				width={ width }
				height={ height }
				fill={ fillColor }
				className={ additionalClass }
				aria-hidden={ ariaHidden }
				aria-label={ ariaLabel }
			/>
		</IconProvider>
	)
}

export { Icon }
