import { combineReducers } from 'redux'

import { IAccountState } from '~/state/reducers/accountReducer'

import { accountOrders as orders } from './accountOrdersReducer'
import { reviews } from './accountReviewsReducer'
import { loyaltyProgram } from './accountLoyaltyProgramReducer'
import { returns } from './accountReturnsReducer'
import { projects } from './accountProjectsReducer'

const account = combineReducers<IAccountState>({
	orders,
	reviews,
	loyaltyProgram,
	returns,
	projects,
})

export { account }
