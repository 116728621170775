import React, { ReactElement } from 'react'

import { Loader } from '~/components/core/loader'

import styles from './AuthLoader.module.scss'

const AuthLoader = (): ReactElement => {
	return (
		<div className={ styles.wrapper }>
			<Loader fullPage />
		</div>
	)
}

export { AuthLoader }
