import React, { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { useCounter, useList, useToggle } from 'react-use'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { includes, isEmpty, isEqual, map, toString } from 'lodash'

import { validation } from '~/utils/validation'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { UserContext } from '~/providers/userProvider'
import { Loader } from '~/components/core/loader'
import { Input, RadioGroup, IRadioOption } from '~/components/core/form'
import { Icon, ICON_TYPE } from '~/components/core/icon'
import { Button } from '~/components/core/button'
import { Link } from '~/components/core/link'
import { CARD_NUMBER_ERRORS, CartPreviewSummaryLoyaltyProgramPointsBalance, DISCOUNT, ICartPreviewSummaryDiscountFormData, ICartPreviewSummaryLoyaltyProgramFormFieldsProps } from '~/components/cart/cartPreview'
import { ILoyaltyProgramRewardData, RewardCheckStatusType } from '~/api/dataTypes/cart'
import { getCartLoyaltyProgramRewards } from '~/api/requests/cart'
import { useMozaic } from '~/hooks/mozaic'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { vars } from '~/statics'

import styles from './CartPreviewSummaryLoyaltyProgramFormFields.module.scss'

const CartPreviewSummaryLoyaltyProgramFormFields = (props: ICartPreviewSummaryLoyaltyProgramFormFieldsProps): ReactElement => {
	const { loyaltyCardNumber, isOpen, onOpen, onRemove } = props
	const { control, setError, watch, setValue, reset, clearErrors } = useFormContext<ICartPreviewSummaryDiscountFormData>()
	const [rewards, { set: setRewards }] = useList<ILoyaltyProgramRewardData>([])
	const [pointsBalance, { set: setPointsBalance }] = useCounter(undefined)
	const [isLoading, setIsLoading] = useToggle(false)
	const [isValidCard, setIsValidCard] = useToggle(false)
	const [isWarningCardStatus, setIsWarningCardStatus] = useToggle(false)
	const [isErrorCardStatus, setIsErrorCardStatus] = useToggle(false)
	const [isNotAvailableCardError, setIsNotAvailableCardError] = useToggle(false)
	const { t } = useTranslation(['cart', 'form'])
	const { getShouldUseMozaicFlag } = useMozaic()
	const { GA_lys, GA_lysError } = useGoogleAnalytics()
	const { urls, loyaltyProgram: { cartInputOnCartEnabled: isLoyaltyCardEnabled, cartInputOnCartNotAvailableMessage: loyaltyCardNotAvailableMessage } } = useContext(AppParametersContext)
	const { customerNumber } = useContext(UserContext)

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const { mask } = validation
	const { cardNumber, discount } = watch()
	const hasAwards = !isEmpty(rewards)

	const rewardOptions: IRadioOption[] = useMemo(() => (
		map(rewards, (reward: ILoyaltyProgramRewardData): IRadioOption => ({
			label: `${ reward.name } ${ reward.value }%`,
			value: reward.rewardCode,
			rightAccessory: t('preview.aside.discount.domProgram.points', { ns: 'cart', points: reward.discountToReward }),
		}))
	), [rewards])

	const handleChangeCardNumber = useCallback(async (): Promise<void> => {
		if (!isLoyaltyCardEnabled) {
			setIsErrorCardStatus(true)

			return
		}

		setIsLoading(true)

		try {
			const { data } = await getCartLoyaltyProgramRewards(toString(cardNumber))
			const { programRewardList, pointBalance, checkStatus } = data

			if (includes(CARD_NUMBER_ERRORS, checkStatus)) {
				setError('cardNumber', {
					type: 'validate',
					message: '',
				})
				setIsValidCard(false)
				setIsErrorCardStatus(true)
			} else if (isEqual(RewardCheckStatusType.LOYALTY_TEMPORARILY_NOT_AVAILABLE_ERROR, checkStatus)) {
				setError('cardNumber', {
					type: 'validate',
					message: '',
				})
				setIsValidCard(false)
				setIsErrorCardStatus(false)
				setIsNotAvailableCardError(true)
			} else {
				setIsErrorCardStatus(false)
				setIsNotAvailableCardError(false)
			}

			if (isEqual(RewardCheckStatusType.CARD_WITHOUT_REDEMPTION_WARNING, checkStatus)) {
				clearErrors()
				setPointsBalance(pointBalance)
				setIsValidCard(true)
				setIsWarningCardStatus(true)
			} else {
				setIsWarningCardStatus(false)
			}

			if (isEqual(RewardCheckStatusType.SUCCESS, checkStatus)) {
				clearErrors()
				setRewards(programRewardList)
				setPointsBalance(pointBalance)
				setIsValidCard(true)
			}

			googleAnalytics(checkStatus)
		} finally {
			setIsLoading(false)
		}
	}, [cardNumber, isLoyaltyCardEnabled])

	const googleAnalytics = useCallback((checkStatus: RewardCheckStatusType): void => {
		if (isValidCard) {
			GA_lys({
				userId: customerNumber,
				lysStep: 1,
				lysType: discount || '',
			}, 3)
		} else {
			GA_lysError({
				userId: customerNumber,
				lysErrorType: checkStatus,
			}, 2)
		}
	}, [isValidCard, customerNumber, discount])

	const handleRemoveCardNumber = useCallback(async (): Promise<void> => {
		await onRemove()

		setRewards([])
		setIsValidCard(false)
		setIsWarningCardStatus(false)
		reset()
		setValue('cardNumber', '')
		setValue('reward', '')
	}, [onRemove])

	const handleChangeReward = useCallback((selectedReward: string): void => {
		if (!isEmpty(selectedReward)) {
			setValue('discount', DISCOUNT.DOM_PRO)
			onOpen()
		}
	}, [])

	const renderApplyButton = useCallback((): ReactElement | undefined => {
		if (!shouldUseMozaic && isEmpty(cardNumber)) {
			return undefined
		}

		const iconName: keyof typeof ICON_TYPE = shouldUseMozaic ? 'mozaicTrashbin' : 'close'
		const iconColor: keyof typeof vars.colors = shouldUseMozaic ? 'black' : 'navy'
		const iconWidth: number = shouldUseMozaic ? 24 : 10
		const accessoryClass = classNames({
			[styles.applyButton]: !isValidCard,
			[styles.removeButton]: isValidCard,
			[styles.isMozaic]: shouldUseMozaic,
		})

		return (
			<Button
				isReverse
				size="small"
				variant={ isValidCard ? 'neutral' : 'secondary' }
				text={ isValidCard ? '' : t('ok', { ns: 'common' }) }
				additionalClass={ accessoryClass }
				onClick={ isValidCard ? handleRemoveCardNumber : handleChangeCardNumber }
			>
				{ isValidCard && (
					<Icon
						name={ iconName }
						width={ iconWidth }
						color={ iconColor }
					/>
				) }
			</Button>
		)
	}, [cardNumber, isValidCard, handleRemoveCardNumber, handleChangeCardNumber])

	const renderCardStatus = useCallback((): ReactElement | null => {
		if (isLoyaltyCardEnabled) {
			if (isWarningCardStatus) {
				return (
					<span className={ styles.warning }>
						{ t('preview.aside.discount.domProgram.cardWithoutRedemptionWarning', { ns: 'cart' }) }
					</span>
				)
			} else if (isErrorCardStatus) {
				return (
					<span className={ styles.error }>
						{ t('preview.aside.discount.domProgram.incorrectCardNumberPartOne', { ns: 'cart' }) }{ ' ' }

						<Link
							href={ urls.contact }
							additionalClass={ styles.link }
							target="_blank"
						>
							{ t('preview.aside.discount.domProgram.incorrectCardNumberPartTwo', { ns: 'cart' }) }
						</Link>
					</span>
				)
			}

			return null
		}

		const cardStatusClass = classNames(styles.warning, {
			[styles.error]: isErrorCardStatus,
		})

		return (
			<span className={ cardStatusClass }>
				{ loyaltyCardNotAvailableMessage }
			</span>
		)
	}, [isLoyaltyCardEnabled, isWarningCardStatus, isErrorCardStatus])

	const renderContent = useCallback((): ReactElement | null => {
		const rightBottomAccessoryClass = classNames(styles.rightBottomAccessory, {
			[styles.isMozaic]: shouldUseMozaic,
		})

		if (isLoading) {
			return (
				<Loader theme={ { wrapper: styles.loader } } />
			)
		}

		return (
			<div className={ styles.container }>
				<Input
					label={ t('preview.aside.discount.domProgram.label') }
					isDisabled={ hasAwards }
					control={ control }
					mask={ mask.cardNumber }
					name="cardNumber"
					RightBottomAccessory={ renderApplyButton() }
					theme={ {
						wrapper: styles.cardNumberWrapper,
						label: styles.label,
						rightBottomAccessory: rightBottomAccessoryClass,
					} }
				/>

				{ renderCardStatus() }

				{ isNotAvailableCardError && (
					<span className={ styles.error }>
						{ t('preview.aside.discount.domProgram.tempNotAvailableError', { ns: 'cart' }) }
					</span>
				) }

				{ isValidCard && (
					<div className={ styles.collectWrapper }>
						<CartPreviewSummaryLoyaltyProgramPointsBalance pointsBalance={ pointsBalance } isWarningCardStatus={ isWarningCardStatus } />

						{ !isWarningCardStatus && (
							<>
								<div className={ styles.rewardsLabel }>
									{ t('preview.aside.discount.domProgram.useClubDiscounts') }
								</div>

								<RadioGroup
									control={ control }
									name="reward"
									options={ rewardOptions }
									theme={ { wrapper: styles.radio, label: styles.label } }
									onChange={ handleChangeReward }
								/>
							</>
						) }
					</div>
				) }
			</div>
		)
	}, [hasAwards, rewardOptions, isOpen, isLoading, discount, cardNumber, isValidCard, isWarningCardStatus, renderApplyButton, shouldUseMozaic, isErrorCardStatus, urls.contact, renderCardStatus])

	useEffect(() => {
		(async () => {
			if (!isEmpty(loyaltyCardNumber)) {
				setValue('cardNumber', loyaltyCardNumber)
				await handleChangeCardNumber()
			}
		})()
	}, [loyaltyCardNumber])

	useEffect(() => {
		if (!isEmpty(discount) && !isEqual(discount, DISCOUNT.DOM_PRO)) {
			setValue('reward', '')
		}
	}, [discount])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { CartPreviewSummaryLoyaltyProgramFormFields }
