import React, { createContext, useState, ReactElement, useMemo, useCallback } from 'react'
import { noop } from 'lodash'

import { IHamburgerMegaMenuContextProps, IHamburgerMegaMenuProviderProps } from '~/providers/hamburgerMegaMenuProvider'
import { IProductCatalogData } from '~/api/dataTypes/catalog'

const initialProps: IHamburgerMegaMenuContextProps = {
	activeMegaWorld: null,
	handleSetActiveMegaWorld: noop,
	activeWorld: null,
	handleSetActiveWorld: noop,
	handleResetMegaMenu: noop,
	pageType: null,
}

export const HamburgerMegaMenuContext = createContext(initialProps)

export const HamburgerMegaMenuProvider = (props: IHamburgerMegaMenuProviderProps): ReactElement => {
	const { children, pageType } = props
	const [activeMegaWorld, setActiveMegaWorld] = useState<IProductCatalogData | null>(null)
	const [activeWorld, setActiveWorld] = useState<IProductCatalogData | null>(null)

	const handleSetActiveMegaWorld = useCallback((megaWorld: IProductCatalogData | null): void => {
		setActiveMegaWorld(megaWorld)
	}, [])

	const handleSetActiveWorld = useCallback((world: IProductCatalogData | null): void => {
		setActiveWorld(world)
	}, [])

	const handleResetMegaMenu = useCallback((): void => {
		handleSetActiveMegaWorld(null)
		handleSetActiveWorld(null)
	}, [])

	const providerValue: IHamburgerMegaMenuContextProps = useMemo(() => ({
		activeMegaWorld,
		handleSetActiveMegaWorld,
		activeWorld,
		handleSetActiveWorld,
		handleResetMegaMenu,
		pageType,
	}), [activeMegaWorld, activeWorld, pageType])

	return (
		<HamburgerMegaMenuContext.Provider value={ providerValue }>
			{ children }
		</HamburgerMegaMenuContext.Provider>
	)
}
