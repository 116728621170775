import React, { ReactElement, useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'

import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'
import { ICartPreviewRemoveProductModalProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewRemoveProductModal.module.scss'

const CartPreviewRemoveProductModal = (props: ICartPreviewRemoveProductModalProps): ReactElement => {
	const { isOpen, onClose, onRemoveProduct, productName } = props
	const { t } = useTranslation(['cart'])
	const [isPending, setIsPending] = useState<boolean>(false)

	const handleConfirm = useCallback((): void => {
		setIsPending(true)
		onRemoveProduct()
	}, [onRemoveProduct])

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
		>
			<div className={ styles.name }>
				{ productName }
			</div>

			<div className={ styles.question }>
				{ t('preview.table.removeModal.question') }
			</div>

			<div className={ styles.actions }>
				<Button
					variant="secondary"
					text={ t('preview.table.removeModal.confirm') }
					additionalClass={ styles.button }
					isLoading={ isPending }
					onClick={ handleConfirm }
				/>

				<Button
					isReverse
					variant="secondary"
					text={ t('preview.table.removeModal.cancel') }
					additionalClass={ styles.button }
					onClick={ onClose }
				/>
			</div>
		</Modal>
	)
}

export { CartPreviewRemoveProductModal }
