import { includes, isEqual } from 'lodash'

import { vars } from '~/statics'
import { ICartPreviewDeliveryEcommerceLabelLogotype } from '~/components/cart/cartPreview'
import { PickupPointType } from '~/api/dataTypes/pickupPoint'

import { INPOST_CAPTION } from './constants'

export const getDeliveryLogo = (isPickupPoint: boolean, pickupPointTypes: PickupPointType[], isCourierWithCarryInDelivery: boolean): ICartPreviewDeliveryEcommerceLabelLogotype => {
	const isMachine = includes(pickupPointTypes, 'APACZKA_INPOST' as PickupPointType)

	if (isPickupPoint) {
		return {
			imgSrc: isMachine ? vars.images.deliveryPackage : vars.images.deliveryCollectionPoint,
			imgWidth: isMachine ? 40 : 25,
			imgHeight: isMachine ? 11 : 25,
		}
	} else if (isCourierWithCarryInDelivery) {
		return {
			imgSrc: vars.images.deliveryCourierWithCarry,
			imgWidth: 40,
			imgHeight: 7,
		}
	}

	return {
		imgSrc: vars.images.deliveryTruck,
		imgWidth: 33,
		imgHeight: 20,
	}
}

export const getDeliveryCaption = (fullName: string): string => isEqual(fullName.toUpperCase(), 'PACZKOMAT') ? INPOST_CAPTION : fullName
