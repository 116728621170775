import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { Button } from '~/components/core/button'
import { ISoldOutButtonProps } from '~/components/core/soldOutButton'

import styles from './SoldOutButton.module.scss'

const SoldOutButton = (props: ISoldOutButtonProps): ReactElement => {
	const {
		additionalClass = '',
	} = props
	const { t } = useTranslation(['common'])

	const buttonClass = classNames(styles.button, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<Button
			isReverse
			isDisabled
			text={ t('soldOutProduct') }
			additionalClass={ buttonClass }
			variant="secondary"
		/>
	)
}

export { SoldOutButton }
