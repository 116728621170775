import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { shallowEqual, useSelector } from 'react-redux'
import classNames from 'classnames'
import { isEmpty, isNil, isNull, isUndefined } from 'lodash'

import { CartPreviewDeliveryListWrapper, CartPreviewDeliveryPickupTopBar, ICartPreviewDeliveryPickupProps, CartPreviewDeliveryPickupTimeSlotsCalendarList, CartPreviewDeliveryPickupStoreInfo, CartPreviewDeliveryPickupStoreNotSelected, CartPreviewDeliveryModal } from '~/components/cart/cartPreview'
import { useAlert } from '~/hooks/alert'
import { usePreferredStore } from '~/hooks/preferredStore'
import { useLogError } from '~/hooks/logError'
import { IRootState } from '~/state/types'
import { UserContext } from '~/providers/userProvider'
import { IResourceBadRequestException } from '~/api/dataTypes/axios'
import { IGetCartDeliveryPickupInStoreTimeslotsResponse, ICartDeliveryCalculateParams } from '~/api/dataTypes/cart'
import { postCartDeliveryCalculate, getCartDeliveryPickupInStoreTimeslots } from '~/api/requests/cart'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewDeliveryPickup.module.scss'

const CartPreviewDeliveryPickup = (props: ICartPreviewDeliveryPickupProps): ReactElement => {
	const { pickupData, pickupInStore, itemQuantityList, promotionCode, onChangeDelivery } = props
	const preferredStore = useSelector((state: IRootState) => state.preferredStore.data, shallowEqual)
	const selectedPaymentWayId = useSelector((state: IRootState) => state.cartPreview.data.cart.selectedPaymentWayId)
	const [timeslots, setTimeslots] = useState<IGetCartDeliveryPickupInStoreTimeslotsResponse | null>(null)
	const { newAlert } = useAlert()
	const { setPreferredStore } = usePreferredStore()
	const { sendLogError } = useLogError()
	const { isLogged } = useContext(UserContext)
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const { id, name, description, processingTime, price, availabilityInfo: { availabilityStatus } } = pickupData

	const calculateParams: ICartDeliveryCalculateParams = {
		itemQuantityList,
		deliveryWayId: Number(id),
		additionalDeliveryWayOptionSelected: false,
		promotionCode,
		paymentWayId: selectedPaymentWayId,
	}

	const handlePreferredStoreChange = useCallback(async (storeCode?: string): Promise<void> => {
		try {
			if (!isUndefined(storeCode)) {
				await postCartDeliveryCalculate(calculateParams)

				const { data } = await getCartDeliveryPickupInStoreTimeslots()
				setTimeslots(data)
			}
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { message } = error.response.data

				if (!isNil(message)) {
					newAlert('danger', <div dangerouslySetInnerHTML={ { __html: message } } />)
				}
			}

			sendLogError(e)
		}
	}, [itemQuantityList])

	useEffect(() => {
		(async () => {
			if (!isNil(preferredStore) && isEmpty(preferredStore.wwwUrl)) {
				await setPreferredStore(preferredStore.storeCode, preferredStore.name, isLogged)
			}
		})()
	}, [isLogged])

	useEffect(() => {
		(async () => {
			await handlePreferredStoreChange(preferredStore?.storeCode)
		})()
	}, [preferredStore?.storeCode])

	useEffect(() => {
		if (!isNull(Number(id))) {
			onChangeDelivery({ deliveryId: Number(id) })
		}
	}, [id])

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<CartPreviewDeliveryListWrapper>
			<div className={ wrapperClass }>
				<CartPreviewDeliveryPickupTopBar
					name={ name }
					description={ description }
					processingTime={ processingTime }
					price={ price }
				/>

				{ !preferredStore && <CartPreviewDeliveryPickupStoreNotSelected /> }

				{ !isNull(timeslots) && (
					<CartPreviewDeliveryPickupTimeSlotsCalendarList
						timeslotCalendarList={ timeslots }
						pickupInStore={ pickupInStore }
						calculateParams={ calculateParams }
					/>
				) }

				<CartPreviewDeliveryPickupStoreInfo store={ preferredStore } />

				<CartPreviewDeliveryModal
					availabilityStatus={ availabilityStatus }
					deliveryWayId={ id }
				/>
			</div>
		</CartPreviewDeliveryListWrapper>
	)
}

export { CartPreviewDeliveryPickup }
