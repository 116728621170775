import React, { createContext, useCallback, useMemo, useState, ReactElement } from 'react'
import { noop } from 'lodash'

import { IAddToCartModalProviderProps, IAddToCartModalContextProps, IAddToCartModalActiveProduct } from '~/providers/addToCartModalProvider'

const initialProps: IAddToCartModalContextProps = {
	isOpen: false,
	activeProduct: null,
	handleOpen: noop,
	handleClose: noop,
	handleSetActiveProduct: noop,
}

export const AddToCartModalContext = createContext(initialProps)

export const AddToCartModalProvider = (props: IAddToCartModalProviderProps): ReactElement => {
	const { children } = props
	const [isOpen, setIsOpen] = useState<boolean>(initialProps.isOpen)
	const [activeProduct, setActiveProduct] = useState<IAddToCartModalActiveProduct | null>(initialProps.activeProduct)

	const handleOpen = useCallback((): void => {
		setIsOpen(true)
	}, [])

	const handleClose = useCallback((): void => {
		setIsOpen(false)
	}, [])

	const handleSetActiveProduct = useCallback((activeProduct: IAddToCartModalActiveProduct): void => {
		setActiveProduct(activeProduct)
	}, [])

	const providerValue: IAddToCartModalContextProps = useMemo(() => ({
		isOpen,
		activeProduct,
		handleOpen,
		handleClose,
		handleSetActiveProduct,
	}), [isOpen])

	return (
		<AddToCartModalContext.Provider value={ providerValue }>
			{ children }
		</AddToCartModalContext.Provider>
	)
}
