import React, { ReactElement, useCallback, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { isUndefined, map } from 'lodash'

import { AppDispatch } from '~/state/store'
import { Modal } from '~/components/core/modal'
import { RequiredInfo } from '~/components/core/form'
import { CartCustomerFormButtons, CartCustomerDataAddressFormFields, CartCustomerFormHeader, ICartCustomerDataEditFormProps, ICartCustomerDataAddressForm } from '~/components/cart/cartCustomer'
import { useAlert } from '~/hooks/alert'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { updateCustomerAddress } from '~/actions/customer'
import { hasOnlyNumbersWithLetters, hasOnlyLettersSpacesOrDash, hasOnlyLettersNumbersSpacesDotsOrDash } from '~/utils/string'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'

import styles from './CartCustomerDataEditForm.module.scss'

const CartCustomerDataEditForm = (props: ICartCustomerDataEditFormProps): ReactElement => {
	const { isOpen, address, onOpen, onClose, onEdit } = props
	const { t, i18n } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const { newAlert } = useAlert()
	const { sendLogError } = useLogError()

	const { postalCode, city, street, houseNumber, flatNumber } = address

	const houseNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).test('hasOnlyNumbersWithLetters', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.houseNumber', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyNumbersWithLetters(value)).required(t('customer.clientData.form.validation.houseNumberRequired', { ns: 'cart' })), [t, i18n])
	const flatNumberSchema = useMemo(() => yup.string().max(7, t('mustBeShortestThen7', { ns: 'form' })).defined().test('hasOnlyNumbersWithLetters', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.flatNumber', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyNumbersWithLetters(value)), [t, i18n])
	const citySchema = useMemo(() => yup.string().max(50, t('mustBeShortestThen50', { ns: 'form' })).test('hasOnlyLettersOrSpaces', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.city', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersSpacesOrDash(value)).required(t('customer.clientData.form.validation.cityRequired', { ns: 'cart' })), [t, i18n])
	const streetSchema = useMemo(() => yup.string().min(3, t('mustBeLongerThen3', { ns: 'form' })).max(42, t('mustBeShortestThen42', { ns: 'form' })).test('hasOnlyLettersNumbersSpacesDotsOrDash', t('validation.unexpectedCharacters', { field: t('customer.clientData.form.street', { ns: 'cart' }), ns: 'form' }), (value: string | undefined): boolean => hasOnlyLettersNumbersSpacesDotsOrDash(value)).required(t('customer.clientData.form.validation.streetRequired', { ns: 'cart' })), [t, i18n])

	const schema: yup.SchemaOf<ICartCustomerDataAddressForm> = useMemo(() => yup.object().shape({
		country: yup.string().required(t('customer.clientData.form.validation.countryRequired')),
		postalCode: yup.string().required(t('customer.clientData.form.validation.zipCodeRequired')),
		city: citySchema,
		street:streetSchema,
		houseNumber: houseNumberSchema,
		flatNumber: flatNumberSchema,
	}), [t, i18n])

	const formProps = useForm<ICartCustomerDataAddressForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			country: 'PL',
			postalCode: postalCode || '',
			city: city || '',
			street: street || '',
			houseNumber: houseNumber || '',
			flatNumber: flatNumber || '',
		},
		mode: 'onChange',
	})

	const { formState: { isSubmitting }, handleSubmit } = formProps

	const handleFormSubmit = useCallback(handleSubmit(async (formData: ICartCustomerDataAddressForm): Promise<void> => {
		try {
			const { addressId } = address

			await dispatch(updateCustomerAddress(addressId, formData))

			await onEdit(formData)
			onClose()
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					formProps.setError(field as FieldPath<ICartCustomerDataAddressForm>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				newAlert('danger', getErrorMessage(code), 5000)
			}

			sendLogError(e)
		}
	}), [address, onClose, onEdit])

	return (
		<Modal
			isOpen={ isOpen }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
			onOpen={ onOpen }
		>
			{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
			<FormProvider { ...formProps }>
				<form onSubmit={ handleFormSubmit }>
					<CartCustomerFormHeader title={ t('customer.clientData.form.editTitle') } />

					<CartCustomerDataAddressFormFields />

					<RequiredInfo />

					<CartCustomerFormButtons isPending={ isSubmitting } onCancel={ onClose } />
				</form>
			</FormProvider>
		</Modal>
	)
}

export { CartCustomerDataEditForm }
