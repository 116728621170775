import React, { ReactElement } from 'react'
import { isUndefined } from 'lodash'

import { CartPreviewDeliveryPickupTransferTopBarInfo, CartPreviewDeliveryPickupTransferTopBarPrice, CartPreviewDeliveryPickupTransferTopBarRealization, ICartPreviewDeliveryPickupTransferTopBarProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryPickupTransferTopBar.module.scss'

const CartPreviewDeliveryPickupTransferTopBar = (props: ICartPreviewDeliveryPickupTransferTopBarProps): ReactElement | null => {
	const { content } = props

	if (isUndefined(content)) {
		return null
	}

	const { name, description, processingTime } = content

	return (
		<div className={ styles.wrapper }>
			<CartPreviewDeliveryPickupTransferTopBarInfo name={ name } description={ description } />

			{
			/*
			** Commented due to PLEDEV-10360
			<CartPreviewDeliveryPickupTransferTopBarRealization processingTime={ processingTime } />
			*/
			}

			<CartPreviewDeliveryPickupTransferTopBarPrice />
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTopBar }
