import { useMedia } from 'react-use'

import { vars } from '~/statics'
import { IMediaQueryData } from '~/hooks/mediaQuery'

const useMediaQuery = (defaultState: boolean = true): IMediaQueryData => {
	const { grid } = vars

	// the same as in _mixins.scss
	const isSmallMobile = useMedia(grid.viewSizeSmallMobile, defaultState)
	const isMediumMobile = useMedia(grid.viewSizeMediumMobile, defaultState)
	const isMobile = useMedia(grid.viewSizeMobile, defaultState)
	const isTablet = useMedia(grid.viewSizeTablet, defaultState)
	const isSmallDesktop = useMedia(grid.viewSizeSmallDesktop, defaultState)
	const isDesktop = useMedia(grid.viewSizeDesktop, defaultState)
	const isLargeDesktop = useMedia(grid.viewSizeLargeDesktop, defaultState)

	return {
		isSmallMobile,
		isMediumMobile,
		isMobile,
		isTablet,
		isSmallDesktop,
		isDesktop,
		isLargeDesktop,
	}
}

export { useMediaQuery }
