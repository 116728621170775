import React, { ReactElement, useCallback } from 'react'
import Masonry from 'react-masonry-css'
import classNames from 'classnames'
import { isEqual, map, size } from 'lodash'

import { MegaMenuWorldsItem, IMegaMenuWorldsListProps, MEGA_MENU_LIST_BREAKPOINT_COLS } from '~/components/core/megamenu'

import styles from './MegaMenuWorldsList.module.scss'

const MegaMenuWorldsList = (props: IMegaMenuWorldsListProps): ReactElement => {
	const { megaWorld, isActive, onGetNodeData, onClose } = props

	const { id, childrenIds } = megaWorld

	const renderWorlds = useCallback((worldsIds: number[]): ReactElement[] => (
		map(worldsIds, (id: number, index: number) => {
			const nodeData = onGetNodeData(id)
			const isFirstElement = isActive && isEqual(index, 0)
			const isLastGroupElement = isEqual(index, size(worldsIds)-1)

			return (
				<MegaMenuWorldsItem
					key={ id }
					data={ nodeData }
					isFirstElement={ isFirstElement }
					isLastGroupElement={ isLastGroupElement }
					parentTabIndex={ index+1 }
					onGetNodeData={ onGetNodeData }
					onClose={ onClose }
				/>
			)
		})
	), [isActive, onGetNodeData, onClose])

	const listClass = classNames({
		[styles.wrapper]: true,
		[styles.active]: isActive,
	})

	return (
		<div key={ id } className={ listClass }>
			<Masonry
				role="list"
				breakpointCols={ MEGA_MENU_LIST_BREAKPOINT_COLS }
				className={ styles.masonryGrid }
				columnClassName={ styles.masonryColumn }
			>
				{ renderWorlds(childrenIds) }
			</Masonry>
		</div>
	)
}

export { MegaMenuWorldsList }
