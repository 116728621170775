import React, { ReactElement, useCallback } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import ReactSelect, { PropsValue, SingleValue } from 'react-select'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { find, isEmpty, isEqual, isUndefined, noop } from 'lodash'

import { ErrorBoundary, ISelectOption, ISelectProps, Label, ReactSelectProps } from '~/components/core/form'
import { useDropdownSelect } from '~/hooks/dropdownSelect'
import { useMozaic } from '~/hooks/mozaic'

import styles from './Select.module.scss'

const Select = <FormFields extends FieldValues = FieldValues>(props: ISelectProps<FormFields>): ReactElement => {
	const { t } = useTranslation(['form'])
	const {
		label, name, control, options, instanceId,
		placeholder = t('select.placeholder'),
		isSearchable = false,
		isDisabled = false,
		isRequired = false,
		isLoading = false,
		isMenuOpen = undefined,
		noOptionsMessage = t('select.noResults'),
		loadingMessage = t('select.loading'),
		additionalClass = '',
		theme = {},
		onChange = noop,
	} = props
	const { handleFocusAria, guidance } = useDropdownSelect()
	const { field, fieldState: { error } } = useController<FormFields>({ control, name })
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const customNoOptionsMessage: ReactSelectProps['noOptionsMessage'] = () => noOptionsMessage
	const customLoadingOptionsMessage: ReactSelectProps['loadingMessage'] = () => loadingMessage

	const isError = !isUndefined(error) && !isEmpty(error.message)

	const selectedOption: PropsValue<ISelectOption> | undefined = find(options, (option: ISelectOption) => isEqual(option.value, field.value))

	const handleChange = useCallback((selectedOption: SingleValue<ISelectOption>) => {
		field.onChange(selectedOption?.value)

		onChange()
	}, [field.onChange, onChange])

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	const selectClass = classNames('react-select', styles.select, theme.select, {
		[styles.disabled]: isDisabled,
		[styles.error]: isError,
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<Label
				isRequired={ isRequired }
				name={ name }
				caption={ label }
			/>

			<ReactSelect
				isLoading={ isLoading }
				instanceId={ instanceId }
				isMulti={ false }
				isSearchable={ isSearchable }
				isDisabled={ isDisabled }
				menuIsOpen={ isMenuOpen }
				inputId={ name }
				options={ options }
				className={ selectClass }
				classNamePrefix="react-select"
				components={ {
					IndicatorSeparator: () => null,
				} }
				placeholder={ placeholder }
				noOptionsMessage={ customNoOptionsMessage }
				loadingMessage={ customLoadingOptionsMessage }
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...field }
				value={ selectedOption ?? null }
				ariaLiveMessages={ {
					onFocus: handleFocusAria,
					guidance,
				} }
				onChange={ handleChange }
			/>

			<ErrorBoundary error={ error } />
		</div>
	)
}

export { Select }
