import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { ICartCustomerCompanyInvoice, ICartCustomerGuestData, ICartCustomerPrivateInvoice, ICartCustomerReceiptNip, ICartCustomerResponse, ICartCustomerValidateSimpleDataParameters } from '~/api/dataTypes/cart'
import { CustomerType } from '~/api/dataTypes/customer'

export enum CartCustomerType {
	GET_CART_CUSTOMER = 'cartCustomer/GET_CART_CUSTOMER',
	VALIDATE_CART_CUSTOMER_PRIVATE_CONTACT = 'cartCustomer/VALIDATE_CART_CUSTOMER_PRIVATE_CONTACT',
	VALIDATE_CART_CUSTOMER_COMPANY_CONTACT = 'cartCustomer/VALIDATE_CART_CUSTOMER_COMPANY_CONTACT',
	SET_ADDRESS_ID = 'cartCustomer/SET_ADDRESS_ID',
	SET_ANOTHER_RECEIVE_ACTIVE = 'cartCustomer/SET_ANOTHER_RECEIVE_ACTIVE',
	ADD_RECEIPT_NIP = 'cartCustomer/ADD_RECEIPT_NIP',
	VALIDATE_CART_CUSTOMER_PRIVATE_INVOICE = 'cartCustomer/VALIDATE_CART_CUSTOMER_PRIVATE_INVOICE',
	VALIDATE_CART_CUSTOMER_COMPANY_INVOICE = 'cartCustomer/VALIDATE_CART_CUSTOMER_COMPANY_INVOICE',
	SET_CART_CUSTOMER_GUEST = 'cartCustomer/SET_CART_CUSTOMER_GUEST',
	SET_CART_CUSTOMER_GUEST_FORM_TYPE = 'cartCustomer/SET_CART_CUSTOMER_GUEST_FORM_TYPE',
	SET_CART_CUSTOMER_EMAIL = 'cartCustomer/SET_CART_CUSTOMER_EMAIL',
	SET_CART_CUSTOMER_INVOICE_METHOD_SELECTED = 'cartCustomer/SET_CART_CUSTOMER_INVOICE_METHOD_SELECTED',
	CLEAR_CART_CUSTOMER_INVOICE_DATA = 'cartCustomer/CLEAR_CART_CUSTOMER_INVOICE_DATA',
	CLEAR_CART_CUSTOMER_DATA = 'cartCustomer/CLEAR_CART_CUSTOMER_DATA',
}

export type GetCartCustomerActionType = AppAction<CartCustomerType.GET_CART_CUSTOMER, AxiosResponse<ICartCustomerResponse>>
export type ValidateCartCustomerPrivateContactDataType = AppAction<CartCustomerType.VALIDATE_CART_CUSTOMER_PRIVATE_CONTACT, ICartCustomerValidateSimpleDataParameters>
export type ValidateCartCustomerCompanyContactDataType = AppAction<CartCustomerType.VALIDATE_CART_CUSTOMER_COMPANY_CONTACT, ICartCustomerValidateSimpleDataParameters>
export type SetCustomerAddressIdType = AppAction<CartCustomerType.SET_ADDRESS_ID, number>
export type SetOtherPersonActive = AppAction<CartCustomerType.SET_ANOTHER_RECEIVE_ACTIVE, boolean>
export type AddReceiptNip = AppAction<CartCustomerType.ADD_RECEIPT_NIP, ICartCustomerReceiptNip>
export type ValidateCartCustomerPrivateInvoice = AppAction<CartCustomerType.VALIDATE_CART_CUSTOMER_PRIVATE_INVOICE, ICartCustomerPrivateInvoice>
export type ValidateCartCustomerCompanyInvoice = AppAction<CartCustomerType.VALIDATE_CART_CUSTOMER_COMPANY_INVOICE, ICartCustomerCompanyInvoice>
export type SetCartCustomerGuest = AppAction<CartCustomerType.SET_CART_CUSTOMER_GUEST, ICartCustomerGuestData>
export type SetCartCustomerGuestFormType = AppAction<CartCustomerType.SET_CART_CUSTOMER_GUEST_FORM_TYPE, CustomerType>
export type SetCartCustomerGuestEmail = AppAction<CartCustomerType.SET_CART_CUSTOMER_EMAIL, string>
export type SetCartCustomerInvoiceMethodSelected = AppAction<CartCustomerType.SET_CART_CUSTOMER_INVOICE_METHOD_SELECTED, string>
export type ClearCartCustomerInvoiceDataType = AppAction<CartCustomerType.CLEAR_CART_CUSTOMER_INVOICE_DATA>
export type ClearCartCustomerDataType = AppAction<CartCustomerType.CLEAR_CART_CUSTOMER_DATA>

export type CartCustomerActionType = GetCartCustomerActionType | ValidateCartCustomerPrivateContactDataType | ValidateCartCustomerCompanyContactDataType | SetCustomerAddressIdType | SetOtherPersonActive | AddReceiptNip | ValidateCartCustomerPrivateInvoice | ValidateCartCustomerCompanyInvoice | SetCartCustomerGuest | SetCartCustomerGuestFormType | SetCartCustomerGuestEmail | SetCartCustomerInvoiceMethodSelected | ClearCartCustomerInvoiceDataType | ClearCartCustomerDataType
