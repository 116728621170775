import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { ICartAsideSummaryCostProps } from '~/components/core/cartAside'
import { usePrice } from '~/hooks/price'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartAsideSummaryCost.module.scss'

const CartAsideSummaryCost = (props: ICartAsideSummaryCostProps): ReactElement => {
	const { productsNetValue, productsValue } = props
	const { t } = useTranslation(['cart'])
	const { priceFormat } = usePrice()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, { [styles.isMozaic]: shouldUseMozaic })

	return (
		<div className={ wrapperClass }>
			<div className={ styles.row }>
				<span>
					{ t('aside.summary.netValue') }
				</span>

				<span>
					{ priceFormat(productsNetValue, { precision: 2 }) }
				</span>
			</div>

			<div className={ styles.row }>
				<span>
					{ t('aside.summary.grossValue') }
				</span>

				<span>
					{ priceFormat(productsValue, { precision: 2 }) }
				</span>
			</div>
		</div>
	)
}

export { CartAsideSummaryCost }
