import React, { ReactElement, useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { getRedirectUrl } from '~/utils/urls'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { Button } from '~/components/core/button'
import { UserContext } from '~/providers/userProvider'
import { useAdeoSignIn } from '~/hooks/signIn'
import { CartPreviewDiscountLysStaticLegend } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLegend/cartPreviewDiscountLysStaticLegend'
import { CartPreviewDiscountLysLoyaltyLegend } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLegend/cartPreviewDiscountLysLoyaltyLegend'

import styles from './CartPreviewDiscountLysLegend.module.scss'

const CartPreviewDiscountLysLegend = (): ReactElement | null => {
	const { urls: { login: loginUrl } } = useContext(AppParametersContext)
	const { isLogged } = useContext(UserContext)
	const { shouldLoginByAdeo, handleSignInByAdeo } = useAdeoSignIn()
	const { push, asPath } = useRouter()
	const { t } = useTranslation(['cart'])

	const handleSignIn = useCallback(async (): Promise<void> => {
		if (shouldLoginByAdeo) {
			await handleSignInByAdeo(asPath)
		} else {
			await push(getRedirectUrl(loginUrl, asPath))
		}
	}, [loginUrl, shouldLoginByAdeo, asPath, push])

	if (isLogged) {
		return (
			<div className={ styles.wrapper }>
				<div className={ styles.mainContainer }>
					<CartPreviewDiscountLysStaticLegend />

					<CartPreviewDiscountLysLoyaltyLegend />
				</div>
			</div>
		)
	}

	return (
		<div className={ styles.wrapper }>
			<span>
				{ t('preview.aside.lysDiscount.legend.notLoggedCaption') }
			</span>

			<Button
				variant="secondary"
				size="inherit"
				additionalClass={ styles.signInButton }
				text={ t('preview.aside.lysDiscount.submitButton.notLogged') }
				onClick={ handleSignIn }
			/>
		</div>
	)
}

export { CartPreviewDiscountLysLegend }
