import React, { ReactElement } from 'react'
import { isUndefined } from 'lodash'

import { IHeaderTopBarSearchBarProductsCounterProps } from '~/components/core/layout'

import styles from './HeaderTopBarSearchBarProductsCounter.module.scss'

const HeaderTopBarSearchBarProductsCounter = (props: IHeaderTopBarSearchBarProductsCounterProps): ReactElement | null => {
	const { count } = props

	if (isUndefined(count)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			({ count })
		</div>
	)
}

export { HeaderTopBarSearchBarProductsCounter }
