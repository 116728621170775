import React, { ReactElement, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { FieldPath, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios, { AxiosError } from 'axios'
import { useSetState } from 'react-use'
import { every, isEmpty, isUndefined, map, split } from 'lodash'

import { validation } from '~/utils/validation'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useFormError } from '~/hooks/formError'
import { useLogError } from '~/hooks/logError'
import { Alert } from '~/components/core/notifications'
import { Button } from '~/components/core/button'
import { Input, RequiredInfo, Textarea } from '~/components/core/form'
import { Modal } from '~/components/core/modal'
import { IFavoriteShareAlertState, IFavoriteShareModalFormData, IFavoriteShareModalProps } from '~/components/favorites'
import { IResourceBadRequestException, IRestExceptionResponse } from '~/api/dataTypes/axios'
import { postWishListShare } from '~/api/requests/share'
import { UserContext } from '~/providers/userProvider'
import { useGoogleRecaptcha } from '~/hooks/googleRecaptcha'

import styles from './FavoriteShareModal.module.scss'

const FavoriteShareModal = (props: IFavoriteShareModalProps): ReactElement | null => {
	const { isOpen, onClose } = props
	const [state, setState] = useSetState<IFavoriteShareAlertState>({ isInvalid: false, info: '' })
	const { getErrorMessage, getFieldErrorMessage } = useFormError()
	const { t, i18n } = useTranslation(['form', 'favorites'])
	const { sendLogError } = useLogError()
	const { isLogged } = useContext(UserContext)
	const { executeRecaptcha } = useGoogleRecaptcha()

	const isValidEmail = (value: string): boolean => validation.regex.email.test(value)

	const schema: yup.SchemaOf<IFavoriteShareModalFormData> = useMemo(() => yup.object().shape({
		toEmails: yup.string().required(t('required', { ns: 'form' })).test('isValidEmail', t('validation.emailsFormat', { ns: 'form' }), (value: string | undefined = ''): boolean => every(value.split(','), isValidEmail)),
		fromEmail: yup.string().email(t('validation.emailFormat', { ns: 'form' })).required(t('required', { ns: 'form' })),
		message: yup.string(),
		signature: yup.string().required(t('required', { ns: 'form' })),
	}), [t, i18n])

	const { control, handleSubmit, setError, formState: { isSubmitting }, reset } = useForm<IFavoriteShareModalFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			toEmails: '',
			fromEmail: '',
			message: '',
			signature: '',
		},
	})

	const handleCloseAlert = useCallback((): void => {
		setState({
			isInvalid: false,
			info: '',
		})
	}, [])

	const handleFormSubmit = useCallback(handleSubmit(async (formData: IFavoriteShareModalFormData) => {
		const { toEmails, fromEmail, message, signature } = formData

		try {
			const reCaptchaToken = isLogged ? await executeRecaptcha('shareFavoriteLoggedUser') : await executeRecaptcha('shareFavoriteNotLoggedUser')

			await postWishListShare({
				recipientEmails: split(toEmails, ','),
				message,
				signature,
				senderEmail: fromEmail,
			}, reCaptchaToken)

			setState({ isInvalid: false, info: t('modal.form.success', { ns: 'favorites' }) })
			reset()
		} catch (e: unknown) {
			const error = e as AxiosError<IResourceBadRequestException>

			if (axios.isAxiosError(error) && !isUndefined(error.response)) {
				const { code, errors } = error.response.data

				map(errors, (err: IRestExceptionResponse) => {
					const { field, errorCodes } = err

					setError(field as FieldPath<IFavoriteShareModalFormData>, {
						message: getFieldErrorMessage(errorCodes),
					})
				})

				setState({ isInvalid: true, info: getErrorMessage(code) })
			}

			sendLogError(e)
		}
	}), [isLogged])

	const { isInvalid, info } = state

	return (
		<Modal
			isOpen={ isOpen }
			title={ t('modal.title', { ns: 'favorites' }) }
			additionalClass={ styles.modal }
			onClose={ onClose }
		>
			{ !isEmpty(info) && (
				<Alert
					canClose
					icon={ isInvalid ? 'errorIcon' : 'checkCircle' }
					type={ isInvalid ? 'danger' : 'success' }
					onClose={ handleCloseAlert }
				>
					{ info }
				</Alert>
			) }

			<form
				className={ styles.wrapper }
				data-testid={ DATA_TESTID.FAVORITES.MODAL_SHARE }
				onSubmit={ handleFormSubmit }
			>
				<Input
					isRequired
					control={ control }
					name="toEmails"
					label={ t('modal.form.toEmailsLabel', { ns: 'favorites' }) }
					placeholder={ t('modal.form.toEmailsPlaceholder', { ns: 'favorites' }) }
				/>

				<Textarea
					control={ control }
					name="message"
					label={ t('modal.form.messageLabel', { ns: 'favorites' }) }
					placeholder={ t('modal.form.messagePlaceholder', { ns: 'favorites' }) }
				/>

				<Input
					isRequired
					control={ control }
					name="fromEmail"
					label={ t('modal.form.fromEmailLabel', { ns: 'favorites' }) }
					type="email"
					placeholder={ t('modal.form.fromEmailPlaceholder', { ns: 'favorites' }) }
				/>

				<Input
					isRequired
					control={ control }
					name="signature"
					label={ t('modal.form.signatureLabel', { ns: 'favorites' }) }
					placeholder={ t('modal.form.signaturePlaceholder', { ns: 'favorites' }) }
				/>

				<RequiredInfo />

				<Button
					isLoading={ isSubmitting }
					type="submit"
					variant="secondary"
					text={ t('send', { ns: 'common' }) }
					additionalClass={ styles.submit }
					dataTestId={ DATA_TESTID.FAVORITES.BUTTON_SUBMIT_SHARE }
				/>
			</form>
		</Modal>
	)
}

export { FavoriteShareModal }
