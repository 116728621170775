import { useMemo } from 'react'
import { createStore, applyMiddleware, compose, Middleware, Store, StoreEnhancer, combineReducers, AnyAction, ReducersMapObject } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkDispatch } from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { createLogger } from 'redux-logger'

import { vars } from '~/statics'
import { AppAction } from '~/actions'

import { IRootState } from './types'
import { productCard } from './reducers/productCardReducer'
import { account } from './reducers/accountReducer'
import { stores } from './reducers/storesReducer'
import { preferredStore } from './reducers/preferredStoreReducer'
import { lastViewed } from './reducers/lastViewedReducer'
import { comparison } from './reducers/comparisonReducer'
import { cartPreview } from './reducers/cartPreviewReducer'
import { auth } from './reducers/authReducer'
import { customer } from './reducers/customerReducer'
import { miniCart } from './reducers/miniCartReducer'
import { favouriteList } from './reducers/favouriteListReducer'
import { cartCustomer } from './reducers/cartCustomerReducer'
import { cartIdentification } from './reducers/cartIdentificationReducer'
import { cartSummary } from './reducers/cartSummaryReducer'
import { cartConfirmation } from './reducers/cartConfirmationReducer'
import { serviceCartConfirmation } from './reducers/serviceCartConfirmationReducer'
import { serviceCartPreview } from './reducers/serviceCartPreviewReducer'
import { serviceCartCustomer } from './reducers/serviceCartCustomerReducer'
import { serviceCartSummary } from './reducers/serviceCartSummaryReducer'
import { contactForm } from './reducers/contactFormReducer'
import { turbineMicroservices } from './reducers/turbineMicroservicesReducer'

const reducers: ReducersMapObject<IRootState, AnyAction> = {
	productCard,
	account,
	stores,
	preferredStore,
	lastViewed,
	comparison,
	miniCart,
	cartPreview,
	auth,
	customer,
	favouriteList,
	cartCustomer,
	cartIdentification,
	cartSummary,
	cartConfirmation,
	contactForm,
	serviceCartPreview,
	serviceCartConfirmation,
	serviceCartCustomer,
	serviceCartSummary,
	turbineMicroservices,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let store: Store<IRootState, AppAction | any> | undefined

const middlewares: Middleware[] = [
	thunk, promise,
	...(vars.isDeveloperMode ? [createLogger({ collapsed: true })] : []),
]

const enhancer: StoreEnhancer = vars.isDeveloperMode ? composeWithDevTools(applyMiddleware(...middlewares)) : compose(applyMiddleware(...middlewares))

// TODO: remove preloadedState
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function initStore(preloadedState: Record<never, never>): Store<IRootState, AppAction | any> {
	return createStore(
		combineReducers(reducers),
		preloadedState,
		enhancer,
	)
}

export const initializeStore = (preloadedState: IRootState): Store<IRootState, AppAction | any> => {
	store = store ?? initStore(preloadedState)

	return store
}

export const useStore = (initialState: IRootState): Store<IRootState, AppAction | any> => {
	const store = useMemo(() => initializeStore(initialState), [initialState])

	return store
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppDispatch = ThunkDispatch<IRootState, any, AppAction>

export { store }
