import { useState } from 'react'
import { isEqual } from 'lodash'

import { IModalData, IModalOption, ModalType } from '~/hooks/modal'

const useModal = (type: ModalType): IModalData => {
	const initialState: IModalOption = {
		type,
		isOpen: false,
	}
	const [modal, setModal] = useState<IModalOption>(initialState)

	const isOpen = (): boolean => {
		const { isOpen, type: modalType } = modal

		return isOpen && isEqual(type, modalType)
	}

	const handleOpen = (): void => {
		setModal({ type, isOpen: true })
	}

	const handleClose = (): void => {
		setModal({ type, isOpen: false })
	}

	return {
		isOpen: isOpen(),
		handleOpen,
		handleClose,
	}
}

export { useModal }
