import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'

import { IHeaderTopBarFavoritesButtonProps } from '~/components/core/layout'
import { Badge } from '~/components/core/badge'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './HeaderTopBarFavoritesButton.module.scss'

const HeaderTopBarFavoritesButton = (props: IHeaderTopBarFavoritesButtonProps): ReactElement => {
	const { isActive, onOpen, onBlur, buttonRef, count, isDisabled } = props
	const { t } = useTranslation(['header'])
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')

	const handleOpen = useCallback((): void => {
		onOpen()
	}, [onOpen])

	const handleBlur = useCallback((): void => {
		if (isShiftTabPressed) {
			onBlur()
		}
	}, [isShiftTabPressed])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.active]: isActive,
	})

	return (
		<Button
			hasAriaPopup
			isAriaExpanded={ isActive }
			buttonRef={ buttonRef }
			variant="neutral"
			size="inherit"
			additionalClass={ wrapperClass }
			ariaLabel={ t('topBar.favorites.caption') }
			dataTestId={ DATA_TESTID.HEADER.BUTTON_OPEN_FAVORITES }
			isDisabled={ isDisabled }
			onClick={ handleOpen }
			onBlur={ handleBlur }
		>
			<span className={ styles.iconWrapper } data-testid={ DATA_TESTID.HEADER.FAVORITES_COUNTER }>
				<Icon
					name="wishlist"
					color="navy"
					width={ 26 }
					height={ 27 }
					additionalClass={ styles.icon }
				/>

				<Badge value={ count } />
			</span>
		</Button>
	)
}

export { HeaderTopBarFavoritesButton }
