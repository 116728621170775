import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'

import { Image } from '~/components/core/image'
import commonTranslation from 'public/locales/pl/common.json'
import { vars } from '~/statics'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { IServerErrorProps } from '~/components/core/serverError'

import styles from './ServerError.module.scss'

const ServerError = (props: IServerErrorProps): ReactElement => {
	const {
		theme = {},
	} = props
	const { isMobile } = useMediaQuery()
	const { t } = useTranslation(['common'])

	const imageWidth = isMobile ? 344 : 280
	const imageHeight = isMobile ? 195 : 158

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div className={ wrapperClass }>
			<Head>
				<title>{ t('internalServerErrorTitle') }</title>
			</Head>

			<div className={ styles.container }>
				<span className={ styles.title }>
					{ commonTranslation.serverError.title }
				</span>

				<span className={ styles.message }>
					{ commonTranslation.serverError.message }
				</span>
			</div>

			<div className={ styles.container }>
				<div className={ styles.image }>
					<Image
						src={ vars.images.serverError }
						alt={ t('leroyMerlin') }
						width={ imageWidth }
						height={ imageHeight }
						className={ styles.img }
					/>
				</div>
			</div>
		</div>
	)
}

export { ServerError }
