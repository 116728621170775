import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { useMediaQuery } from '~/hooks/mediaQuery'
import { HeaderStickyModalOverlayProps } from '~/components/core/layout'

import styles from './HeaderStickyModalOverlay.module.scss'

const HeaderStickyModalOverlay = (props: HeaderStickyModalOverlayProps): ReactElement => {
	const { isOpen } = props
	const { isTablet } = useMediaQuery()

	const overlayClass = classNames({
		[styles.overlay]: true,
		[styles.active]: isOpen && !isTablet,
	})

	return (
		<div className={ overlayClass } />
	)
}

export { HeaderStickyModalOverlay }
