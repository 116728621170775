import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty, isNil, toLower } from 'lodash'

import { IPromoLabelProps } from '~/components/core/promoLabel'

import styles from './PromoLabel.module.scss'

const PromoLabel = (props: IPromoLabelProps): ReactElement | null => {
	const {
		code, name,
		additionalClass = '',
		type = 'NORMAL',
	} = props

	if (isNil(code) || isNil(name)) {
		return null
	}

	const labelClass = classNames({
		[styles.label]: true,
		[styles[toLower(code)]]: code,
		[styles[toLower(type)]]: type,
		[additionalClass]: !isEmpty(additionalClass),
	})

	return (
		<div className={ labelClass }>{ name }</div>
	)
}

export { PromoLabel }
