import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { Loader } from '~/components/core/loader'
import { ICartCustomerLoaderProps } from '~/components/cart/cartCustomer'

import styles from './CartCustomerLoader.module.scss'

const CartCustomerLoader = (props: ICartCustomerLoaderProps): ReactElement | null => {
	const { isLoading } = props
	const { t } = useTranslation(['cart'])

	if (!isLoading) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<Loader label={ t('customer.dataUpdating') } />
		</div>
	)
}

export { CartCustomerLoader }
