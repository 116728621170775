import { isNull } from 'lodash'

import { CustomerType, ICustomerData, CustomerDataRequestUnionType, CustomerMainAddressType } from '~/api/dataTypes/customer'
import { ICartCustomerContactForm } from '~/components/cart/cartCustomer'

export const getCartContactUpdatedData = (formData: ICartCustomerContactForm, customerType: CustomerType, customer: ICustomerData): Partial<CustomerDataRequestUnionType> => {
	const { firstName, lastName, mainAddress: address, nip, corporateName } = customer

	const mainAddress: CustomerMainAddressType = {
		city: address?.city || '',
		country: address?.country || '',
		flatNumber: address?.flatNumber || '',
		houseNumber: address?.houseNumber || '',
		postalCode: address?.postalCode || '',
		street: address?.street || '',
	}

	if (customerType === 'COMPANY' && !isNull(nip) && !isNull(corporateName)) {
		return {
			...formData,
			customerType,
			firstName,
			lastName,
			mainAddress,
			corporateName,
			nip,
		}
	}

	return {
		...formData,
		customerType,
		firstName,
		lastName,
		mainAddress,
	}
}
