import { createSelector } from 'reselect'
import { isNil } from 'lodash'

import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'
import { IRootState } from '~/state/types'
import { IServiceCartConfirmationState, IServiceConfirmationBasicData } from '~/state/reducers/serviceCartConfirmationReducer'

export const getConfirmationData = (state: IRootState): IOrderDetailsResponse => (
	state.serviceCartConfirmation.data
)

export const getConfirmationState = (state: IRootState): IServiceCartConfirmationState => (
	state.serviceCartConfirmation
)

export const isConfirmationOrderNumberAvailable = createSelector(getConfirmationData, (data: IOrderDetailsResponse): boolean => {
	const { orderNumber } = data

	return !isNil(orderNumber)
})

export const getConfirmationClientEmail = createSelector(getConfirmationData, (data: IOrderDetailsResponse): string | null => {
	const { customer: { email } } = data

	return email
})

export const getConfirmationBasicData = createSelector(getConfirmationState, (state: IServiceCartConfirmationState): IServiceConfirmationBasicData => {
	const { orderFrontId, data: { orderNumber, serviceOrderDetailsData, paymentDetailsData: { orderTransactionId }, orderStatusUrl } } = state

	return {
		orderFrontId,
		orderNumber,
		orderStatusUrl,
		orderTransactionId,
		appointmentDate: serviceOrderDetailsData?.appointmentDate || null,
	}
})

export const getServiceLmRef = createSelector(getConfirmationData, (data: IOrderDetailsResponse): string | null => {
	const { orderLines } = data

	return orderLines[0]?.orderItemData?.lmReference || null
})
