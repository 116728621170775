import React, { ReactElement, useCallback, useContext } from 'react'
import { map, isNull } from 'lodash'

import { getMegaWorldIconName } from '~/utils/catalog'
import { HamburgerMenuMegaMenuItem, IHamburgerMenuMegaMenuMegaWorldsProps } from '~/components/core/hamburgerMenu'
import { IProductCatalogData } from '~/api/dataTypes/catalog'
import { HamburgerMegaMenuContext } from '~/providers/hamburgerMegaMenuProvider'

const HamburgerMenuMegaMenuMegaWorlds = (props: IHamburgerMenuMegaMenuMegaWorldsProps): ReactElement | null => {
	const { megaWorlds } = props
	const { handleSetActiveMegaWorld, activeMegaWorld } = useContext(HamburgerMegaMenuContext)

	const handleSetMegaWorld = useCallback((megaWorld: IProductCatalogData) => (): void => {
		handleSetActiveMegaWorld(megaWorld)
	}, [])

	const renderMegaWorlds = useCallback((): ReactElement[] => (
		map(megaWorlds, (megaWorld: IProductCatalogData) => {
			const { name, id } = megaWorld
			const megaWorldIcon = getMegaWorldIconName(id)

			return (
				<HamburgerMenuMegaMenuItem
					key={ id }
					icon={ megaWorldIcon }
					name={ name }
					onClick={ handleSetMegaWorld(megaWorld) }
				/>
			)
		})
	), [megaWorlds])

	if (!isNull(activeMegaWorld)) {
		return null
	}

	return (
		<>
			{ renderMegaWorlds() }
		</>
	)
}

export { HamburgerMenuMegaMenuMegaWorlds }
