import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isNull } from 'lodash'

import { usePrice } from '~/hooks/price'
import { ICartPreviewProductsSummaryPriceBlockProps } from '~/components/cart/cartPreview'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewProductsSummaryPriceBlock.module.scss'

const CartPreviewProductsSummaryPriceBlock = (props: ICartPreviewProductsSummaryPriceBlockProps): ReactElement => {
	const { summaryPrice } = props
	const { priceFormat } = usePrice()
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const priceClass = classNames(styles.price, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<span className={ styles.header }>
				{ t('preview.table.header.summaryPrice') }
			</span>

			<span className={ priceClass }>
				{ priceFormat(summaryPrice, { precision: 2 }) }
			</span>
		</div>
	)
}

export { CartPreviewProductsSummaryPriceBlock }
