import { i18n } from 'next-i18next'

import { IInitialDeliveryGroupValues } from '~/components/cart/cartPreview'

export const initialDeliveryGroupDelivery: IInitialDeliveryGroupValues = {
	getName: () => i18n?.t('preview.delivery.groups.delivery.title', { ns: 'cart' }),
	getDescription: () => i18n?.t('preview.delivery.groups.delivery.description', { ns: 'cart' }),
	icon: {
		name: 'deliveryEcomm',
		width: 49,
		smallWidth: 28,
		height: 45,
		smallHeight: 26,
	},
}

export const initialDeliveryGroupPickup: IInitialDeliveryGroupValues = {
	getName: () => i18n?.t('preview.delivery.groups.pickup.title', { ns: 'cart' }),
	getDescription: () => i18n?.t('preview.delivery.groups.pickup.description', { ns: 'cart' }),
	icon: {
		name: 'deliveryPickup',
		width: 46,
		smallWidth: 26,
		height: 51,
		smallHeight: 28,
	},
}

export const initialDeliveryGroupStoreDelivery: IInitialDeliveryGroupValues = {
	getName: () => i18n?.t('preview.delivery.groups.storeDelivery.title', { ns: 'cart' }),
	getDescription: () => i18n?.t('preview.delivery.groups.storeDelivery.description', { ns: 'cart' }),
	icon: {
		name: 'deliveryTransport',
		width: 75,
		smallWidth: 35,
		height: 48,
		smallHeight: 22,
	},
}

export const DEBOUNCE_DELAY = 500
