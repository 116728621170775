import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ICartPreviewDeliveryPickupTransferTimeslotsTileProps } from '~/components/cart/cartPreview'
import { useDate } from '~/hooks/date'

import styles from './CartPreviewDeliveryPickupTransferTimeslotsTile.module.scss'

const CartPreviewDeliveryPickupTransferTimeslotsTile = (props: ICartPreviewDeliveryPickupTransferTimeslotsTileProps): ReactElement => {
	const { timePeriod, isChecked } = props
	const { formatDateUnix, getTimeFormat } = useDate()

	const { fromTime, toTime } = timePeriod

	const wrapperClass = classNames(styles.wrapper, {
		[styles.selected]: isChecked,
	})

	return (
		<div className={ wrapperClass }>
			{ formatDateUnix(Number(fromTime), getTimeFormat()) }{ '-' }{ formatDateUnix(Number(toTime), getTimeFormat()) }
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferTimeslotsTile }
