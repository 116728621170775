import { AxiosResponse } from 'axios'

import { ICustomerStoreResult } from '~/api/dataTypes/customerStore'
import { request } from '~/api/requests/axios'

export const deleteCustomerStore = async (): Promise<AxiosResponse<void>> => (
	await request.delete('/v1/preferences/store', {
		service: 'CUSTOMER_PREFERENCE_SERVICE',
	})
)

export const putCustomerStore = async (storeCode: string): Promise<AxiosResponse<void>> => (
	await request.put('/v1/preferences/store', { storeCode }, {
		service: 'CUSTOMER_PREFERENCE_SERVICE',
	})
)

export const getCustomerStore = async (): Promise<AxiosResponse<ICustomerStoreResult>> => (
	await request.get('/v1/preferences/store', {
		service: 'CUSTOMER_PREFERENCE_SERVICE',
	})
)
