import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { ICartPreviewSummaryLoyaltyProgramPointsBalanceProps } from '~/components/cart/cartPreview'
import { Icon } from '~/components/core/icon'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewSummaryLoyaltyProgramPointsBalance.module.scss'

const CartPreviewSummaryLoyaltyProgramPointsBalance = (props: ICartPreviewSummaryLoyaltyProgramPointsBalanceProps): ReactElement => {
	const { pointsBalance, isWarningCardStatus } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			<span className={ styles.label }>
				{ t('preview.aside.discount.domProgram.pointsBalance') }
			</span>

			<span className={ styles.value }>
				{ t('preview.aside.discount.domProgram.points', { points: pointsBalance }) }
			</span>

			{ !isWarningCardStatus && (
				<div className={ styles.info }>
					<Icon
						name="checkCircle"
						width={ 24 }
						additionalClass={ styles.icon }
					/>

					<span className={ styles.message }>
						{ t('preview.aside.discount.domProgram.addedMessage') }
					</span>
				</div>
			) }
		</div>
	)
}

export { CartPreviewSummaryLoyaltyProgramPointsBalance }
