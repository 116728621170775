import React, { ReactElement, useCallback, useContext } from 'react'

import { Container } from '~/components/core/layout/container'
import { AuthLoader } from '~/components/core/auth'
import { UserContext } from '~/providers/userProvider'
import { CartCustomerLogged, CartCustomerGuest } from '~/components/cart/cartCustomer'
import { CartStepsHeader } from '~/components/cart/cartStepsHeader'
import { useMounted } from '~/hooks/mounted'

import styles from './CartCustomer.module.scss'

const CartCustomer = (): ReactElement => {
	const { isMounted } = useMounted()
	const { isLogged } = useContext(UserContext)

	const renderContent = useCallback((): ReactElement => {
		if (isLogged) {
			return (
				<CartCustomerLogged />
			)
		}

		return (
			<CartCustomerGuest />
		)
	}, [isLogged])

	if (!isMounted) {
		return (
			<AuthLoader />
		)
	}

	return (
		<Container additionalClass={ styles.wrapper }>
			<CartStepsHeader cartStatus="CUSTOMER" />

			{ renderContent() }
		</Container>
	)
}

export { CartCustomer }
