import React, { ReactElement, useCallback } from 'react'
import { isUndefined, map } from 'lodash'

import { Alert, IAlertsProps, INotification } from '~/components/core/notifications'

const Alerts = (props: IAlertsProps): ReactElement => {
	const { alerts, removeAlert } = props

	const handleClose = useCallback((index: number) => (): void => {
		removeAlert(index)
	}, [removeAlert])

	const renderAlerts = useCallback((): ReactElement => (
		<>
			{ map(alerts, (alert: INotification) => {
				const {
					index, type, icon, children,
					duration = undefined,
				} = alert

				if (!isUndefined(duration)) {
					setTimeout(handleClose(index), duration)
				}

				return (
					<Alert
						key={ index }
						type={ type }
						icon={ icon }
						onClose={ handleClose(index) }
					>
						{ children }
					</Alert>
				)
			}) }
		</>
	), [alerts, handleClose])

	return (
		<>
			{ renderAlerts() }
		</>
	)
}

export { Alerts }
