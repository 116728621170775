import React, { ReactElement, useContext } from 'react'
import { useRouter } from 'next/router'

import { ILayoutAccountProps, LayoutAccountWrapper } from '~/components/core/layout'
import { Fallback } from '~/components/core/fallback'
import { AuthLoader } from '~/components/core/auth'
import { ReactErrorBoundary, ERROR_BOUNDARY_KEYS } from '~/components/core/reactErrorBoundary'
import { SignIn } from '~/components/signIn'
import { UserContext } from '~/providers/userProvider'
import { useMounted } from '~/hooks/mounted'
import { KobiFavicon } from '~/components/kobi/kobiFavicon'

const LayoutAccount = (props: ILayoutAccountProps): ReactElement => {
	const { children, pageType } = props
	const { isLogged } = useContext(UserContext)
	const { isMounted } = useMounted()
	const router = useRouter()

	if (!isMounted) {
		return (
			<AuthLoader />
		)
	}

	if (!isLogged) {
		return (
			<SignIn />
		)
	}

	return (
		<ReactErrorBoundary
			key={ ERROR_BOUNDARY_KEYS.LAYOUT_ACCOUNT }
			url={ router.asPath }
			Fallback={ <Fallback /> }
		>
			<KobiFavicon />

			<LayoutAccountWrapper pageType={ pageType }>
				{ children }
			</LayoutAccountWrapper>
		</ReactErrorBoundary>
	)
}

export { LayoutAccount }
