import React, { ReactElement } from 'react'

import { ILayoutCartWrapperProps } from '~/components/core/layout'

import styles from './LayoutCartWrapper.module.scss'

const LayoutCartWrapper = (props: ILayoutCartWrapperProps): ReactElement => {
	const { children } = props

	return (
		<div className={ styles.wrapper }>
			{ children }
		</div>
	)
}

export { LayoutCartWrapper }
