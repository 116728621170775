import { deleteCookie, setCookie } from 'cookies-next'

import { COOKIE_KEY_TEMPO_ORDER_ID, COOKIE_MAX_AGE_ONE_YEAR } from '~/utils/constants'
import { ITempoCookies, ITempoOderIdCookieSetOptions } from '~/utils/types'

export const tempoCookies: ITempoCookies = {
	tempoOrderId: {
		set: ({ tempoOrderId, hostname }: ITempoOderIdCookieSetOptions): void => setCookie(COOKIE_KEY_TEMPO_ORDER_ID, tempoOrderId, { ...tempoCookies.tempoOrderId.options, domain: `.${ hostname.replace('www.', '') }` }),
		delete: (hostname: string): void => deleteCookie(COOKIE_KEY_TEMPO_ORDER_ID, { ...tempoCookies.tempoOrderId.options, domain: `.${ hostname.replace('www.', '') }` }),
		options: {
			maxAge: COOKIE_MAX_AGE_ONE_YEAR,
			secure: true,
		},
	},
}
