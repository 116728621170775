import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { Button } from '~/components/core/button'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewSummaryDiscountSubmitButton.module.scss'

const CartPreviewSummaryDiscountSubmitButton = (): ReactElement => {
	const { t } = useTranslation(['common'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<Button
			isReverse={ shouldUseMozaic }
			type="submit"
			variant="secondary"
			text={ t('apply') }
			additionalClass={ wrapperClass }
			dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_SUBMIT_DISCOUNT }
		/>
	)
}

export { CartPreviewSummaryDiscountSubmitButton }
