import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { SlideDown } from 'react-slidedown'
import classNames from 'classnames'
import { isUndefined } from 'lodash'

import { ICartCustomerAsideBlockCollapseProps } from '~/components/cart/cartCustomer'
import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerAsideBlockCollapse.module.scss'

const CartCustomerAsideBlockCollapse = (props: ICartCustomerAsideBlockCollapseProps): ReactElement | null => {
	const { isActive, children, onClick } = props
	const { t } = useTranslation(['cart'])
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const iconName = shouldUseMozaic ? 'mozaicArrowRight16' : 'arrowFull'
	const iconWidth = shouldUseMozaic ? 16 : 6

	if (isUndefined(children)) {
		return null
	}

	const buttonClass = classNames(styles.button, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const iconClass = classNames(styles.icon, {
		[styles.isActive]: isActive,
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ styles.wrapper }>
			<Button
				size="inherit"
				variant="neutral"
				additionalClass={ buttonClass }
				ariaLabel={ t('customer.aside.summary.orderedProducts') }
				onClick={ onClick }
			>
				<span>
					{ t('customer.aside.summary.orderedProducts') }
				</span>

				<Icon
					name={ iconName }
					width={ iconWidth }
					additionalClass={ iconClass }
				/>
			</Button>

			<SlideDown>
				{ isActive && children }
			</SlideDown>
		</div>
	)
}

export { CartCustomerAsideBlockCollapse }
