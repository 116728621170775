import { IFavouriteListState } from '~/state/reducers/favouriteListReducer'

export const favouriteListInitialState: IFavouriteListState = {
	isPending: false,
	simpleList: {
		favouriteListUuid: '',
		wwwUrl: '',
		itemQuantity: 0,
		productsQuantityMap: {},
	},
	data: {
		name: '',
		createDate: 0,
		favouriteItemList: [],
		summaryPrice: 0,
	},
}
