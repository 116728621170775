import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { isEmpty, noop } from 'lodash'

import { Icon } from '~/components/core/icon'
import { IButtonBoxWrapperProps } from '~/components/core/buttonBoxWrapper'
import { Button } from '~/components/core/button'
import { useMozaic } from '~/hooks/mozaic'

import styles from './ButtonBoxWrapper.module.scss'

const ButtonBoxWrapper = (props: IButtonBoxWrapperProps): ReactElement => {
	const {
		children, ariaLabel, buttonRef, dataTestId,
		additionalClass = '',
		isSelected = false,
		isDisabled = false,
		onClick = noop,
		onKeyDown = noop,
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
		[styles.selected]: isSelected,
		[additionalClass]: !isEmpty(additionalClass),
		[styles.disabled]: isDisabled,
	})

	return (
		<Button
			buttonRef={ buttonRef }
			size="inherit"
			variant="neutral"
			additionalClass={ wrapperClass }
			ariaLabel={ ariaLabel }
			isDisabled={ isDisabled }
			dataTestId={ dataTestId }
			onClick={ onClick }
			onKeyDown={ onKeyDown }
		>
			{ isSelected && !isDisabled && (
				<div className={ styles.selected }>
					<Icon
						width={ 12 }
						name="checkmark"
						color="white"
					/>
				</div>
			) }

			{ children }
		</Button>
	)
}

export { ButtonBoxWrapper }
