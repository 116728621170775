import { AppPromiseThunkAction } from '~/actions'
import { AddReceiptNip, CartCustomerType, ClearCartCustomerDataType, ClearCartCustomerInvoiceDataType, GetCartCustomerActionType, SetCartCustomerGuest, SetCartCustomerGuestFormType, SetCartCustomerInvoiceMethodSelected, SetCustomerAddressIdType, SetOtherPersonActive, ValidateCartCustomerCompanyContactDataType, ValidateCartCustomerPrivateContactDataType } from '~/actions/cartCustomer'
import { getCartCustomer, postCartCustomerValidateCorporateInvoiceData, postCartCustomerValidatePrivateInvoiceData, postCartCustomerValidateCompanyContactData, postCartCustomerValidatePrivateContactData } from '~/api/requests/cart'
import { ICartCustomerReceiptNip, ICartCustomerValidateSimpleDataParameters, IInvoiceData, ICartCustomerGuestData } from '~/api/dataTypes/cart'
import { CustomerType } from '~/api/dataTypes/customer'
import { withFresherToken } from '~/actions/auth'

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartCustomerData = withFresherToken((): AppPromiseThunkAction<GetCartCustomerActionType> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.GET_CART_CUSTOMER,
		payload: getCartCustomer(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const validateCartCustomerPrivateContact = (params: ICartCustomerValidateSimpleDataParameters): AppPromiseThunkAction<ValidateCartCustomerPrivateContactDataType> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.VALIDATE_CART_CUSTOMER_PRIVATE_CONTACT,
		payload: async () => {
			try {
				await postCartCustomerValidatePrivateContactData(params)

				return params
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const validateCartCustomerCompanyContact = (params: ICartCustomerValidateSimpleDataParameters): AppPromiseThunkAction<ValidateCartCustomerCompanyContactDataType> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.VALIDATE_CART_CUSTOMER_COMPANY_CONTACT,
		payload: async () => {
			try {
				await postCartCustomerValidateCompanyContactData(params)

				return params
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setSelectedAddressId = (addressId: number): AppPromiseThunkAction<SetCustomerAddressIdType> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.SET_ADDRESS_ID,
		payload: Promise.resolve(addressId),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setAnotherReceiverActive = (isActive: boolean): AppPromiseThunkAction<SetOtherPersonActive> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.SET_ANOTHER_RECEIVE_ACTIVE,
		payload: Promise.resolve(isActive),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const addReceiptNip = (params: ICartCustomerReceiptNip): AppPromiseThunkAction<AddReceiptNip> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.ADD_RECEIPT_NIP,
		payload: Promise.resolve(params),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const validateCartCustomerPrivateInvoice = withFresherToken((params: IInvoiceData): AppPromiseThunkAction<any> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.VALIDATE_CART_CUSTOMER_PRIVATE_INVOICE,
		payload: async () => {
			try {
				await postCartCustomerValidatePrivateInvoiceData(params)

				return params
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
})

// FIXME: FIX ANY TYPE!
// eslint-disable-next-line @typescript-eslint/typedef
export const validateCartCustomerCompanyInvoice = withFresherToken((params: IInvoiceData): AppPromiseThunkAction<any> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.VALIDATE_CART_CUSTOMER_COMPANY_INVOICE,
		payload: async () => {
			try {
				await postCartCustomerValidateCorporateInvoiceData(params)

				return params
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
})

// FIXME: FIX ANY TYPE!
// eslint-disable-next-line @typescript-eslint/typedef
export const setCartCustomerGuest = (params: ICartCustomerGuestData): AppPromiseThunkAction<SetCartCustomerGuest> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.SET_CART_CUSTOMER_GUEST,
		payload: Promise.resolve(params),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartCustomerGuestFormType = (params: CustomerType): AppPromiseThunkAction<SetCartCustomerGuestFormType> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.SET_CART_CUSTOMER_GUEST_FORM_TYPE,
		payload: Promise.resolve(params),
	})
}

// FIXME: FIX ANY TYPE!
// eslint-disable-next-line @typescript-eslint/typedef
export const setCartCustomerGuestEmail = (email: string): AppPromiseThunkAction<any> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.SET_CART_CUSTOMER_EMAIL,
		payload: Promise.resolve(email),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearInvoiceData = (): AppPromiseThunkAction<ClearCartCustomerInvoiceDataType> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.CLEAR_CART_CUSTOMER_INVOICE_DATA,
		payload:  Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const setCartCustomerInvoiceMethodSelected = (selectedType: string): AppPromiseThunkAction<SetCartCustomerInvoiceMethodSelected> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.SET_CART_CUSTOMER_INVOICE_METHOD_SELECTED,
		payload: Promise.resolve(selectedType),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearCartCustomerData = (): AppPromiseThunkAction<ClearCartCustomerDataType> => async (dispatch) => {
	return dispatch({
		type: CartCustomerType.CLEAR_CART_CUSTOMER_DATA,
		payload: Promise.resolve(),
	})
}
