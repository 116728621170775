import { AppPromiseThunkAction } from '~/actions'
import { CartSummaryType, GetCartSummaryActionType, ClearCartSummaryChangedPricesAlertMessage } from '~/actions/cartSummary'
import { getCartSummary } from '~/api/requests/cart'
import { withFresherToken } from '~/actions/auth'

// eslint-disable-next-line @typescript-eslint/typedef
export const getCartSummaryData = withFresherToken((): AppPromiseThunkAction<GetCartSummaryActionType> => async (dispatch) => {
	return dispatch({
		type: CartSummaryType.GET_CART_SUMMARY,
		payload: getCartSummary(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const clearCartSummaryChangedPricesAlertMessage = (): AppPromiseThunkAction<ClearCartSummaryChangedPricesAlertMessage> => async (dispatch) => {
	return dispatch({
		type: CartSummaryType.CLEAR_CART_SUMMARY_CHANGED_PRICES_ALERT_MESSAGE,
		payload: Promise.resolve(),
	})
}
