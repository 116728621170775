import React, { ReactElement } from 'react'

import { Loader } from '~/components/core/loader'

import styles from './CartCustomerGuestLoader.module.scss'

const CartCustomerGuestLoader = (): ReactElement => {
	return (
		<div className={ styles.wrapper }>
			<Loader />
		</div>
	)
}

export { CartCustomerGuestLoader }
