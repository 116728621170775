import { MARKETING_CONSENT_COOKIE_CODE } from '~/utils/constants'
import { ISareCustomerDataEvent, ISareCustomerEmailEvent, ISareProductEvent, ISareUtils } from '~/utils/sare'

export const sare: ISareUtils = {
	productEvents: (data: ISareProductEvent): void => {
		if (!sare.isCookieConsent()) {
			return
		}

		window.siDataLayer = window.siDataLayer || {}

		window.siDataLayer['event_category'] = 'content_ids'
		window.siDataLayer['event_action'] = data.interactionType
		window.siDataLayer['event_label'] = data.productDefine
		window.siDataLayer['event_value'] = data.productValue

		sare.log({ event: data.interactionType, data })

		sare.trackSareEvent(window.siDataLayer)
	},
	customerEmailEvents: (data: ISareCustomerEmailEvent): void => {
		if (!sare.isCookieConsent()) {
			return
		}

		window.siDataLayer = window.siDataLayer || {}

		window.siDataLayer['customer_email_input'] = { source: 'shop' }
		window.siDataLayer['customer_email_ids'] = data.emailIds

		sare.log({ event: 'customer_email', data })

		sare.trackSareEvent(window.siDataLayer)
	},
	customerDataEvents: (data: ISareCustomerDataEvent): void => {
		if (!sare.isCookieConsent()) {
			return
		}

		window.siDataLayer = window.siDataLayer || {}

		window.siDataLayer['customer_data'] = data

		sare.log({ event: 'customer_data', data })

		sare.trackSareEvent(window.siDataLayer)
	},
	isCookieConsent: (): boolean => {
		const oneTrustCookieConsents = window.OnetrustActiveGroups || ''

		return oneTrustCookieConsents.includes(MARKETING_CONSENT_COOKIE_CODE)
	},
	trackSareEvent: (dataLayer: unknown): void => {
		if (typeof window.AnalystServices === 'object') {
			window.AnalystServices.trackEvent(dataLayer)
		}
	},
	log: (log: unknown): void => {
		console.log(
			'%cSARE:',
			'color:yellow;font-weight:bold',
			log,
		)
	},
}
