import React, { ReactElement, useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { HeaderContext } from '~/providers/headerProvider'
import { Container } from '~/components/core/layout/container'
import { Alerts, INotificationsProps } from '~/components/core/notifications'
import { useAlert } from '~/hooks/alert'

import styles from './Notifications.module.scss'

const Notifications = (props: INotificationsProps): ReactElement => {
	const {
		theme ={},
	} = props
	const { isHeaderFixed, headerHeight } = useContext(HeaderContext)
	const { alerts, removeAlert } = useAlert()
	const [paddingTop, setPaddingTop] = useState<number>(0)

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	useEffect(() => {
		setPaddingTop(isHeaderFixed && !isEmpty(alerts) ? headerHeight : 0)
	}, [isHeaderFixed, alerts])

	return (
		<div className={ wrapperClass } style={ { paddingTop } }>
			<Container>
				<Alerts alerts={ alerts } removeAlert={ removeAlert } />
			</Container>
		</div>
	)
}

export { Notifications }
