import React, { ReactElement, useCallback, useContext } from 'react'
import classNames from 'classnames'
import { isNil } from 'lodash'

import { IHamburgerMenuItemProps, HamburgerMenuItemContent } from '~/components/core/hamburgerMenu'
import { Link } from '~/components/core/link'
import { DATA_TESTID } from '~/utils/dataTestId'
import { HamburgerMenuContext } from '~/providers/hamburgerMenuProvider'

import styles from './HamburgerMenuItem.module.scss'

const HamburgerMenuItem = (props: IHamburgerMenuItemProps): ReactElement => {
	const { item } = props
	const { handleSetOpen } = useContext(HamburgerMenuContext)

	const { pageType, url, name, promotion, icon, color, isSelected } = item

	const handleClose = useCallback(() => {
		handleSetOpen(false)
	}, [handleSetOpen])

	const renderContent = useCallback((): ReactElement => {
		if (isNil(pageType)) {
			return (
				<Link
					href={ url }
					theme={ {
						wrapper: styles.link,
					} }
					style={ { color } }
					target="_blank"
					rel="noopener noreferrer"
					dataTestId={ DATA_TESTID.CORE.HAMBURGER_MENU_ITEM(name) }
				>
					<HamburgerMenuItemContent name={ name } icon={ icon } />
				</Link>
			)
		}

		return (
			<Link
				href={ url }
				additionalClass={ styles.link }
				style={ { color } }
				dataTestId={ DATA_TESTID.CORE.HAMBURGER_MENU_ITEM(name) }
				onClick={ handleClose }
			>
				<HamburgerMenuItemContent name={ name } icon={ icon } />
			</Link>
		)
	}, [pageType, url, name, icon])

	const itemClass = classNames(styles.item, {
		[styles.promotion]: promotion,
		[styles.selected]: isSelected,
	})

	return (
		<li className={ itemClass }>
			{ renderContent() }
		</li>
	)
}

export { HamburgerMenuItem }
