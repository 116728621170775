import { IGetAriaLabel } from '~/components/core/layout'

export const getProductAriaLabelWithPrice = (params: IGetAriaLabel): string => {
	const { name, promotional, bigPrice, bigPriceUnit, t } = params

	let ariaText = ''

	if (name) {
		ariaText += `${ t('product.ariaPrefix', { ns: 'header' }) } ${ name },`
	}

	if (promotional) {
		ariaText += ` ${ t('product.price.promotional', { price: bigPrice, ns: 'header' }) }`
	} else {
		ariaText += ` ${ t('product.price.regular', { price: bigPrice, ns: 'header' }) }`
	}

	ariaText += bigPriceUnit

	return ariaText
}
