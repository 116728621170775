import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { useController, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty, isEqual, map, isNull } from 'lodash'

import { CartPreviewDeliveryPickupTransferCategory, ICartPreviewDeliveryPickupTransferCategoriesForm, ICartPreviewDeliveryPickupTransferCategoriesProps } from '~/components/cart/cartPreview'
import { ITransportCategoryData } from '~/api/dataTypes/cart'
import { Radio } from '~/components/core/form'

import styles from './CartPreviewDeliveryPickupTransferCategories.module.scss'

const CartPreviewDeliveryPickupTransferCategories = (props: ICartPreviewDeliveryPickupTransferCategoriesProps): ReactElement | null => {
	const { deliveryStoreTimeSlots, deliveryStore, onChangeDelivery } = props
	const { t } = useTranslation(['cart'])

	const selectedZipCode = !isNull(deliveryStore) ? deliveryStore.zipCode : ''
	const { zipCode } = deliveryStoreTimeSlots

	const schema: yup.SchemaOf<ICartPreviewDeliveryPickupTransferCategoriesForm> = useMemo(() => yup.object().shape({
		value: yup.string().required(),
	}), [])

	const { control, setValue } = useForm<ICartPreviewDeliveryPickupTransferCategoriesForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			value: !isNull(deliveryStore) ? deliveryStore.category : '',
		},
	})

	const { field, fieldState: { error } } = useController({ control, name: 'value' })
	const { categories } = deliveryStoreTimeSlots
	const { transportCategoryList } = categories

	const renderCategories = useCallback((): ReactElement[] => (
		map(transportCategoryList, (radioOption: ITransportCategoryData): ReactElement => {
			const { code } = radioOption

			const isChecked = isEqual(field.value, code)

			return (
				<Radio
					key={ code }
					value={ code }
					field={ field }
					error={ error }
				>
					<CartPreviewDeliveryPickupTransferCategory
						category={ radioOption }
						isChecked={ isChecked }
						error={ error }
						zipCode={ zipCode }
						onChangeDelivery={ onChangeDelivery }
					/>
				</Radio>
			)
		})
	), [field, transportCategoryList, error, zipCode])

	useEffect(() => {
		if (!isEqual(selectedZipCode, zipCode)) {
			setValue('value', '')
		}
	}, [zipCode])

	if (isEmpty(transportCategoryList)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.caption }>
				{ t('preview.delivery.groups.storeDelivery.deliveryCategory') }
			</div>

			<div className={ styles.categories }>
				{ renderCategories() }
			</div>
		</div>
	)
}

export { CartPreviewDeliveryPickupTransferCategories }
