import React, { ReactElement, useCallback, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { find, isEqual, map } from 'lodash'

import { CustomerLoyaltyRewardType, ICustomerLoyaltyAccountReward } from '~/api/dataTypes/customerLoyalty'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { CartPreviewDiscountLysCouponElement } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons/cartPreviewDiscountLysCouponElement'
import { ICartPreviewDiscountLysCouponsListProps } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLoyaltyCoupons/cartPreviewDiscountLysCouponsList'
import { ICartPreviewDiscountLysFormCode, ICartPreviewDiscountLysSelectedReward } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys'

import styles from './CartPreviewDiscountLysCouponsList.module.scss'

const CartPreviewDiscountLysCouponsList = (props: ICartPreviewDiscountLysCouponsListProps): ReactElement => {
	const {
		currentRewards,
		shouldDisplaySelectedRewardInfo = false,
		theme = {},
	} = props
	const { selectedReward, onSetSelectedReward } = useContext(LysLoyaltyProviderContext)
	const { setValue, resetField } = useFormContext<ICartPreviewDiscountLysFormCode>()
	const { t } = useTranslation(['cart'])

	const handleSelectLoyaltyReward = useCallback((code: string): void => {
		const rewardToSelect = find(currentRewards, { code })

		const reward: ICartPreviewDiscountLysSelectedReward = {
			code: rewardToSelect?.code || '',
			type: rewardToSelect?.type || null,
		}

		if (isEqual(selectedReward, reward)) {
			onSetSelectedReward(reward)
		} else {
			onSetSelectedReward(reward)
			setValue('promotionCode', '')
			resetField('promotionCode')
		}
	}, [currentRewards, selectedReward, onSetSelectedReward])

	const renderCouponList = useCallback((currentRewards: ICustomerLoyaltyAccountReward[]): ReactElement[] => (
		map(currentRewards, (reward: ICustomerLoyaltyAccountReward): ReactElement => (
			<li key={ reward.id } className={ styles.couponWrapper }>
				<CartPreviewDiscountLysCouponElement reward={ reward } onSelectLoyaltyReward={ handleSelectLoyaltyReward } />
			</li>
		))
	), [handleSelectLoyaltyReward])

	const renderSelectedRewardInfo = useCallback((): ReactElement | null => {
		if (shouldDisplaySelectedRewardInfo) {
			const isPercentLoyalty = isEqual(selectedReward.type, 'PERCENT' as CustomerLoyaltyRewardType)
			const text = isPercentLoyalty ? t('preview.aside.lysDiscount.legend.selectedCoupon.percent.description') : t('preview.aside.lysDiscount.legend.selectedCoupon.fixed.description')

			return (
				<span className={ styles.selectedRewardDescription }>
					{ text }
				</span>
			)
		}

		return null
	}, [shouldDisplaySelectedRewardInfo, selectedReward.type])

	const listClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<ul className={ listClass }>
			{ renderCouponList(currentRewards) }

			{ renderSelectedRewardInfo() }
		</ul>
	)
}

export { CartPreviewDiscountLysCouponsList }
