import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ICartCustomerFormHeaderProps } from '~/components/cart/cartCustomer'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartCustomerFormHeader.module.scss'

const CartCustomerFormHeader = (props: ICartCustomerFormHeaderProps): ReactElement => {
	const { title } = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const wrapperClass = classNames(styles.wrapper, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	return (
		<div className={ wrapperClass }>
			{ title }
		</div>
	)
}

export { CartCustomerFormHeader }
