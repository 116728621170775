import { WritableDraft } from 'immer/dist/internal'
import { isEqual, map } from 'lodash'

import { AppPromiseActionFulfilled } from '~/actions'
import { SelectCartLoyaltyAccountRewardType } from '~/actions/cart'
import { ICustomerLoyaltyAccountReward } from '~/api/dataTypes/customerLoyalty'

export const mapSelectedRewardStatus = (currentRewardsDraft: WritableDraft<ICustomerLoyaltyAccountReward>[], action: AppPromiseActionFulfilled<SelectCartLoyaltyAccountRewardType>): WritableDraft<ICustomerLoyaltyAccountReward>[] => (
	map(currentRewardsDraft, (reward: ICustomerLoyaltyAccountReward) => {
		reward.selected = isEqual(reward.code, action.payload)

		return reward
	})
)
