import { IValidationData } from '~/utils/types'

export const validation: IValidationData = {
	mask: {
		nip: '0000000000',
		phone: '000000000',
		zipCode: '00-000',
		bankAccount: '00 0000 0000 0000 0000 0000 0000',
		cardNumber: '0000000000000',
		date: '00/00/0000',
	},
	placeholder: {
		date: 'DD/MM/RRRR',
	},
	regex: {
		// eslint-disable-next-line no-useless-escape
		email: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
		minThreeCharacters: new RegExp(/.{3,}/),
		uuid: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
		phoneWithoutAreaCode: new RegExp(/^(00?[0-9]{2}|\+[0-9]{2}|[4,8]{2})\s?/),
		polishPhonePrefix: new RegExp(/^(\+48|48|0048)/),
		polishZipCode: new RegExp(/^\d{2}-\d{3}/),
		onlyDigits: new RegExp(/[\D\s]+/g),
	},
}
