import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'

import { CartStatusType } from '~/api/dataTypes/cart'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { IUseCartStatusData, StepNumberType } from '~/hooks/cartStatus'
import { getUrlWithQuery } from '~/utils/urls'
import { localStorageSet } from '~/utils/storage'
import { LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY } from '~/components/cart/cartCustomer'

export const useCartStatus = (): IUseCartStatusData => {
	const { urls } = useContext(AppParametersContext)
	const router = useRouter()

	const handleChangeCartView = useCallback(async (nextStepNumber: StepNumberType, cartStatus: CartStatusType, query?: Record<string, string>): Promise<void> => {
		switch (nextStepNumber) {
			case 1:
				await router.push(urls.cartView)
				break
			case 2:
				switch (cartStatus) {
					case 'CUSTOMER':
					case 'SUMMARY':
					case 'FINALIZE':
						await router.push(getUrlWithQuery(urls.cartCustomer, query))
						break
					case 'IDENTIFICATION':
						await router.push(getUrlWithQuery(urls.cartIdentification, query))
						break
					case 'VIEW':
					default:
						localStorageSet(LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY, 'false')
						await router.push(getUrlWithQuery(urls.cartView, query))
				}
				break
			case 3:
				switch (cartStatus) {
					case 'SUMMARY':
					case 'FINALIZE':
						await router.push(getUrlWithQuery(urls.cartSummary, query))
						break
					case 'CUSTOMER':
						await router.push(getUrlWithQuery(urls.cartCustomer, query))
						break
					case 'IDENTIFICATION':
						await router.push(getUrlWithQuery(urls.cartIdentification, query))
						break
					case 'VIEW':
					default:
						localStorageSet(LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY, 'false')
						await router.push(getUrlWithQuery(urls.cartView, query))
				}
				break
			case 4:
				switch (cartStatus) {
					case 'FINALIZE':
						await router.push(getUrlWithQuery(urls.cartConfirmation, query))
						break
					case 'CUSTOMER':
						await router.push(getUrlWithQuery(urls.cartCustomer, query))
						break
					case 'IDENTIFICATION':
						await router.push(getUrlWithQuery(urls.cartIdentification, query))
						break
					case 'SUMMARY':
						await router.push(getUrlWithQuery(urls.cartSummary, query))
						break
					case 'VIEW':
					default:
						localStorageSet(LS_KEY_CART_PREVIEW_ALLOW_OVERWRITE_DELIVERY_WAY, 'false')
						await router.push(getUrlWithQuery(urls.cartView, query))
				}
				break
		}
	}, [urls])

	return {
		changeCartView: handleChangeCartView,
	}
}
