import { ProductCatalogPathItemIconType } from '~/api/dataTypes/catalog'
import { ICON_TYPE } from '~/components/core/icon'

export const getCatalogIcon = (iconType: ProductCatalogPathItemIconType | null): keyof typeof ICON_TYPE => {
	switch (iconType) {
		case 'BUILD_BRICK_WALL':
			return 'buildBrickWall'
		case 'BUILD_CONSTRUCTION':
			return 'buildConstruction'
		case 'BUILD_FENCE':
			return 'buildFence'
		case 'BUILD_HEATING':
			return 'buildHeating'
		case 'BUILD_ISOLATION':
			return 'buildIsolation'
		case 'BUILD_PLUMBING':
			return 'buildPlumbing'
		case 'BUILD_ROOFING':
			return 'buildRoofing'
		case 'BUILD_SIDING':
			return 'buildSiding'
		case 'BUILD_TOOLS':
			return 'buildTools'
		case 'BUILD_WINDOW_DOOR':
			return 'buildWindowDoor'
		case 'DECO_CARPET':
			return 'decoCarpet'
		case 'DECO_CURTAINS':
			return 'decoCurtains'
		case 'DECO_LAMP':
			return 'decoLamp'
		case 'DECO_MIRROR_FRAME':
			return 'decoMirrorFrame'
		case 'DECO_PAINT_BRUSH':
			return 'decoPaintBrush'
		case 'DECO_PAINT_BUCKET':
			return 'decoPaintBucket'
		case 'DECO_VASE':
			return 'decoVase'
		case 'DECO_WALLPAPER':
			return 'decoWallpaper'
		case 'DECO_WORKSPACE':
			return 'decoWorkspace'
		case 'GARDEN_BALCONY':
			return 'gardenBalcony'
		case 'GARDEN_BENCH':
			return 'gardenBench'
		case 'GARDEN_GAZEBO':
			return 'gardenGazebo'
		case 'GARDEN_HOOSE':
			return 'gardenHoose'
		case 'GARDEN_LAMP':
			return 'gardenLamp'
		case 'GARDEN_MOWER':
			return 'gardenMower'
		case 'GARDEN_PLANT':
			return 'gardenPlant'
		case 'GARDEN_RAKE':
			return 'gardenRake'
		case 'GARDEN_SEEDS':
			return 'gardenSeeds'
		case 'GARDEN_WHEELBARROW':
			return 'gardenWheelbarrow'
		case 'PLAN_BATHROOM_EQUIPMENT':
			return 'planBathroomEquipment'
		case 'PLAN_DRAWERS':
			return 'planDrawers'
		case 'PLAN_FAUCET':
			return 'planFaucet'
		case 'PLAN_FIREPLACE':
			return 'planFireplace'
		case 'PLAN_FLOOR_HEATING':
			return 'planFloorHeating'
		case 'PLAN_KITCHEN':
			return 'planKitchen'
		case 'PLAN_KITCHEN_EQUIPMENT':
			return 'planKitchenEquipment'
		case 'PLAN_SHOWER':
			return 'planShower'
		case 'PLAN_SINK_CABINET':
			return 'planSinkCabinet'
		case 'PLAN_SOAPDISPENSOR':
			return 'planSoapdispensor'
		case 'PLAN_STAIRS':
			return 'planStairs'
		case 'PLAN_TILES':
			return 'planTiles'
		case 'PLAN_TOILET':
			return 'planToilet'
		case 'PLAN_WALLSIDING':
			return 'planWallsiding'
		case 'PLAN_WARDROBE':
			return 'planWardrobe'
		case 'PLAN_WORKSPACE':
			return 'planWorkspace'
		case 'TECHNIC_BOILER':
			return 'technicBoiler'
		case 'TECHNIC_DUCTAPE':
			return 'technicDuctape'
		case 'TECHNIC_HAMMER':
			return 'technicHammer'
		case 'TECHNIC_HEATER':
			return 'technicHeater'
		case 'TECHNIC_HEATING':
			return 'technicHeating'
		case 'TECHNIC_HOME_SECURITY':
			return 'technicHomeSecurity'
		case 'TECHNIC_PAINT_GLUE':
			return 'technicPaintGlue'
		case 'TECHNIC_POWER_CABLE':
			return 'technicPowerCable'
		case 'TECHNIC_SCREWW':
			return 'technicScreww'
		case 'TECHNIC_VENT':
			return 'technicVent'
		case 'TECHNIC_WORKSHOP':
			return 'technicWorkshop'
		default:
			return 'wrench'
	}
}

export const getMegaWorldIconName = (id: number): keyof typeof ICON_TYPE => {
	switch (id) {
		case 5:
			// budowa
			return 'bricks'
		case 8:
			// technika
			return 'tap'
		case 9:
			// projekt
			return 'brush'
		case 10:
			// dekoracja
			return 'frame'
		case 11:
			// ogród
			return 'shovel'
		default:
			return 'hammer'
	}
}

export const getWorldIconName = (id: number): keyof typeof ICON_TYPE => {
	switch (id) {
		case 19:
			// maszyny ogrodnicze
			return 'gardenMower'
		case 20:
			// porządki w ogrodzie
			return 'gardenWheelbarrow'
		case 21:
			// narzędzie ogrodnicze
			return 'gardenRake'
		case 22:
			// relaks w ogrodzie
			return 'gardenBench'
		case 23:
			// nawadnianie
			return 'gardenHoose'
		case 24:
			// architektura ogrodowa
			return 'gardenGazebo'
		case 25:
			// oświetlenie, doniczki i dekoracje
			return 'gardenLamp'
		case 26:
			// rośliny, nasiona, cebule
			return 'gardenSeeds'
		case 27:
			// uprawa i ochrona roślin
			return 'gardenPlant'
		case 103:
			// elektronarzędzia
			return 'technicDrill'
		case 104:
			// narzędzia ręczne
			return 'technicHammer'
		case 105:
			// warsztat
			return 'technicWorkshop'
		case 107:
			// hydraulika
			return 'technicBoiler'
		case 110:
			// ogrzewanie
			return 'technicHeating'
		case 111:
			// klimatyzacja i wentylacja
			return 'technicVent'
		case 114:
			// elektryczność
			return 'technicPowerCable'
		case 117:
			// artykuły metalowe
			return 'technicScreww'
		case 119:
			// zabezpieczenie domu
			return 'technicHomeSecurity'
		case 120:
			// farby, lakiery i kleje
			return 'technicPaintGlue'
		case 121:
			// artykuły gospodarcze
			return 'technicDuctape'
		case 126:
			// drzwi, klamki, schody
			return 'planStairs'
		case 132:
			// materiały budowlane
			return 'buildBrickWall'
		case 138:
			// okna i drzwi
			return 'buildWindowDoor'
		case 141:
			// izolacja budynków
			return 'buildIsolation'
		case 143:
			// dachy i akcesoria
			return 'buildRoofing'
		case 148:
			// konstrukcje drewniane i metalowe
			return 'buildConstruction'
		case 150:
			// materiały wykończeniowe
			return 'buildSiding'
		case 152:
			// ogrodzenia i bramy
			return 'buildFence'
		case 154:
			// narzędzia budowlane
			return 'buildTools'
		case 153:
			// hydraulika
			return 'buildPlumbing'
		case 184:
			// podłogi drewniane, panele ścienne
			return 'planWallsiding'
		case 226:
			// płytki ceramiczne na ścianę i podłogę
			return 'planTiles'
		case 636:
			// kuchnie
			return 'planKitchen'
		case 525:
			// meble łazienkowe i lustra
			return 'planSinkCabinet'
		case 540:
			// ceramika łazienkowa
			return 'planToilet'
		case 2106:
			// wanny i kabiny prysznicowe
			return 'planShower'
		case 590:
			// armatura łazienkowa
			return 'planFaucet'
		case 604:
			// wyposażenie łazienki
			return 'planBathroomEquipment'
		case 724:
			// garderoba i wnętrze
			return 'planWardrobe'
		case 737:
			// farby
			return 'decoPaintBucket'
		case 863:
			// dekoracja ścian, sufitów i innych powierzchni
			return 'decoWallpaper'
		case 834:
			// elementy mebli i okucia
			return 'planDrawers'
		case 894:
			// kominki
			return 'planFireplace'
		case 924:
			// dekoracja okien
			return 'decoCurtains'
		case 953:
			// oświetlenie
			return 'decoLamp'
		case 1210:
			// dywany i wykładziny
			return 'decoCarpet'
		case 1234:
			// dekoracja wnętrz
			return 'decoVase'
		case 1292:
			// ramki, obrazy, lustra
			return 'decoMirrorFrame'
		case 1306:
			// atelier artystyczne
			return 'decoPaintBrush'
		case 2092:
			// akcesoria łazienkowe
			return 'planSoapdispensor'
		case 2022:
			// ogrzewanie
			return 'buildHeating'
		case 2368:
			// balkon i taras
			return 'gardenBalcony'
		case 2490:
			// organizacja miejsca pracy
			return 'decoWorkspace'
		default:
			return 'buildBrickWall'
	}
}
