import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { find, isEmpty, isEqual, isNil, isNull, map } from 'lodash'

import { vars } from '~/statics'
import { CartPreviewDeliveryPickupPointInfoWindow, ICartPreviewDeliveryEcommercePickupPointMapProps } from '~/components/cart/cartPreview'
import { GoogleMaps, GoogleMapsPositionType, GoogleMapsStateType, IGoogleMapsMarker } from '~/components/core/googleMaps'
import { IPickupPointData } from '~/api/dataTypes/pickupPoint'

import styles from './CartPreviewDeliveryEcommercePickupPointMap.module.scss'

const CartPreviewDeliveryEcommercePickupPointMap = (props: ICartPreviewDeliveryEcommercePickupPointMapProps): ReactElement | null => {
	const {
		pickupPoint, pickupPointList, onSelect, selectedPointId, mapZoom,
		geolocation = null,
	} = props
	const [activeMarkerWindow, setActiveMarkerWindow] = useState<IGoogleMapsMarker | null>(null)
	const mapInstance = useRef<GoogleMapsStateType | null>(null)
	const { t } = useTranslation(['cart'])

	const initialPosition = useMemo((): GoogleMapsPositionType => {
		if (!isNull(pickupPoint)) {
			return { lat: pickupPoint?.geolocation.latitude, lng: pickupPoint?.geolocation.longitude }
		}

		if (!isNull(geolocation)) {
			const { latitude: lat, longitude: lng } = geolocation

			return { lat, lng }
		}

		return vars.map
	}, [pickupPoint, geolocation, vars.map])

	const markers: IGoogleMapsMarker[] = useMemo(() => map(pickupPointList, (point: IPickupPointData) => {
		const { id, geolocation: { latitude, longitude }, pickupPointCode } = point

		return {
			id,
			position: {
				lat: latitude,
				lng: longitude,
			},
			icon: vars.images.pinGreen,
			type: 'markerInfoWindow',
			title: pickupPointCode,
			infoWindow: (
				<CartPreviewDeliveryPickupPointInfoWindow
					point={ point }
					selectedPointId={ selectedPointId }
					onSelect={ onSelect }
				/>
			),
		}
	}), [pickupPointList, onSelect, selectedPointId])

	const handleLoad = useCallback((map: GoogleMapsStateType) => {
		mapInstance.current = map
	}, [])

	useEffect(() => {
		const marker = find(markers, ({ id }: IGoogleMapsMarker) => isEqual(id, selectedPointId))

		setActiveMarkerWindow(marker ?? null)
	}, [selectedPointId, markers])

	useEffect(() => {
		if (!isNil(geolocation) && isNull(pickupPoint)) {
			mapInstance.current?.setCenter({ lat: geolocation.latitude, lng: geolocation.longitude })
			mapInstance.current?.setZoom(10)
		} else if (!isNil(geolocation)) {
			mapInstance.current?.setCenter({ lat: geolocation.latitude, lng: geolocation.longitude })
		}
	}, [geolocation, pickupPoint])

	if (isEmpty(pickupPointList) || isNil(geolocation)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.header }>
				{ t('preview.delivery.pickupPoints.mapCaption') }
			</div>

			<div className={ styles.map }>
				<GoogleMaps
					hasCluster
					shouldCenterOnMarkerClick
					id="pickup-point"
					initCenter={ initialPosition }
					initZoom={ mapZoom }
					markers={ markers }
					activeMarkerWindow={ activeMarkerWindow }
					onLoad={ handleLoad }
				/>
			</div>
		</div>
	)
}

export { CartPreviewDeliveryEcommercePickupPointMap }
