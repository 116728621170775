import React, { useCallback, ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { CartPreviewDeliverySplitProducts, getNotAvailableSplitProducts, ICartPreviewSummarySplitProps } from '~/components/cart/cartPreview'
import { Modal } from '~/components/core/modal'
import { Button } from '~/components/core/button'

import styles from './CartPreviewSplitOrder.module.scss'

const CartPreviewSplitOrder = (props: ICartPreviewSummarySplitProps): ReactElement => {
	const { itemsAvailable, itemsNotAvailable, isOpen, onNext, isLoading, onClose } = props
	const { t } = useTranslation(['cart', 'common'])

	const notAvailableSplitProducts = getNotAvailableSplitProducts(itemsAvailable, itemsNotAvailable)

	const handleNext = useCallback(() => {
		onNext(true)
	}, [onNext])

	return (
		<Modal
			isOpen={ isOpen }
			title={ t('preview.aside.summary.modal.title') }
			additionalClass={ styles.wrapper }
			onClose={ onClose }
		>
			<div className={ styles.content }>
				<div className={ styles.subtitle }>
					{ t('preview.aside.summary.modal.subtitle') }
				</div>

				<CartPreviewDeliverySplitProducts
					type="availableProducts"
					titles={ [t('preview.aside.summary.modal.availableTitle')] }
					items={ itemsAvailable }
				/>

				<CartPreviewDeliverySplitProducts
					type="availableProducts"
					titles={ [t('preview.aside.summary.modal.notAvailableTitle'), t('preview.aside.summary.modal.notAvailableTitlePostfix')] }
					items={ notAvailableSplitProducts }
				/>

				<div className={ styles.actions }>
					<Button
						text={ t('ok', { ns: 'common' }) }
						additionalClass={ styles.button }
						isLoading={ isLoading }
						onClick={ handleNext }
					/>

					<Button
						isReverse
						text={ t('cancel', { ns: 'common' }) }
						additionalClass={ styles.button }
						onClick={ onClose }
					/>
				</div>
			</div>
		</Modal>
	)
}

export { CartPreviewSplitOrder }
