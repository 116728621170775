import { useEffect } from 'react'
import { useToggle } from 'react-use'

import { useMounted } from '~/hooks/mounted'

export const useInitialLoader = (isPending: boolean): [boolean] => {
	const { isMounted } = useMounted()
	const [isInitialLoading, setIsInitialLoading] = useToggle(true)

	useEffect(() => {
		if (isMounted && !isPending) {
			setIsInitialLoading(false)
		}
	}, [isPending, isMounted])

	return [isInitialLoading]
}
