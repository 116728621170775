import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { filter, isEmpty, isNull } from 'lodash'

import { MegaMenuWrapper, MegaMenuMegaWorlds, MegaMenuWorlds, IMegaMenuProps, isMegaWorld } from '~/components/core/megamenu'
import { IProductCatalogData } from '~/api/dataTypes/catalog'

const MegaMenu = (props: IMegaMenuProps): ReactElement => {
	const { nodes, buttonRef, isVisible, onClose } = props
	const [activeMegaWorld, setActiveMegaWorld] = useState<IProductCatalogData | null>(null)

	const megaWorlds = filter(nodes, isMegaWorld)

	const handleGetNodeData = useCallback((id: number): IProductCatalogData => nodes[id], [nodes])

	useEffect(() => {
		if (!isEmpty(megaWorlds) && isNull(activeMegaWorld)) {
			setActiveMegaWorld(megaWorlds[0])
		}
	}, [megaWorlds])

	return (
		<MegaMenuWrapper
			buttonRef={ buttonRef }
			isVisible={ isVisible }
			onClose={ onClose }
		>
			<MegaMenuMegaWorlds
				active={ activeMegaWorld }
				items={ megaWorlds }
				onSetActive={ setActiveMegaWorld }
				onClose={ onClose }
			/>

			<MegaMenuWorlds
				activeMegaWorld={ activeMegaWorld }
				megaWorlds={ megaWorlds }
				onGetNodeData={ handleGetNodeData }
				onClose={ onClose }
			/>
		</MegaMenuWrapper>
	)
}

export { MegaMenu }
