import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { ICarouselNavigationProps } from '~/components/core/carousel'
import { Icon } from '~/components/core/icon'
import { Button } from '~/components/core/button'

import styles from './CarouselNavigation.module.scss'

const CarouselNavigation = (props: ICarouselNavigationProps): ReactElement => {
	const {
		innerRef,
		isPrev = false,
		isHidden = false,
		isWithoutGradient = false,
	} = props
	const { t } = useTranslation(['common'])

	const carouselNavigationClass = classNames({
		[styles.carouselNavigation]: true,
		[styles.prev]: isPrev,
		[styles.hidden]: isHidden,
		[styles.withoutGradient]: isWithoutGradient,
	})

	const buttonClass = isPrev ? 'swiper-button-prev' : 'swiper-button-next'
	const buttonLabel = isPrev ? t('carousel.prevButtonLabel') : t('carousel.nextButtonLabel')

	return (
		<div className={ carouselNavigationClass }>
			<Button
				size="inherit"
				variant="neutral"
				buttonRef={ innerRef }
				ariaLabel={ buttonLabel }
				additionalClass={ buttonClass }
			>
				<Icon
					name="arrow"
					width={ 10 }
					additionalClass={ styles.icon }
					color="navy"
				/>
			</Button>
		</div>
	)
}

export { CarouselNavigation }
