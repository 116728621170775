import { has, isEmpty, isUndefined, reduce, split, trim } from 'lodash'

export type CookieValueType = string | number | boolean | null | undefined | object

const getParamsString = (params?: string): string => !isEmpty(params) ? `; ${ params }` : ''

const cookiesValueToObject = (cookiesValue: string): Record<string, string> => (
	reduce(split(cookiesValue, ';'), (acc: Record<string, string>, entry: string) => {
		const [key, value] = split(entry, '=')

		return {
			...acc,
			[trim(key)]: value,
		}
	}, {})
)

export const cookieParse = <T extends CookieValueType>(value: string | undefined): T | undefined => (
	!isUndefined(value) ? JSON.parse(value) : undefined
)

export const cookieGet = <T extends CookieValueType>(key: string): T | null => {
	if (typeof document === 'undefined') {
		return null
	}

	const cookies = cookiesValueToObject(document.cookie)

	if (has(cookies, key)) {
		return cookieParse<T>(cookies[key]) || null
	}

	return null
}

export const cookieSet = (key: string, value: CookieValueType, params?: string): void => {
	if (typeof document !== 'undefined') {
		document.cookie = `${ key }=${ JSON.stringify(value) }${ getParamsString(params) }`
	}
}

export const cookieRemove = (key: string, params?: string): void => {
	cookieSet(key, '', `max-age=0${ getParamsString(params) }`)
}
