import { isNull, map } from 'lodash'

import { AppPromiseThunkAction } from '~/actions'
import { withFresherToken } from '~/actions/auth'
import { CustomerActionType, PatchCustomerActionType, GetCustomerActionType, RemoveCustomerAddressActionType, AddCustomerAddressActionType, UpdateCustomerAddressActionType, GetCustomerLastViewedProductsType, getCustomersAfterAddressRemove } from '~/actions/customer'
import { CustomerDataRequestUnionType, ICustomerAddressRequest } from '~/api/dataTypes/customer'
import { IProductSimple } from '~/api/dataTypes/product'
import { patchCustomer, deleteCustomerAddress, postCustomerAddress, patchCustomerAddress, getCustomer } from '~/api/requests/customer'
import { getCustomerProductsLastViewed } from '~/api/requests/customerLastViewed'
import { LS_KEY_LAST_VIEWED_PRODUCTS } from '~/utils/constants'
import { localStorageSet } from '~/utils/storage'

// eslint-disable-next-line @typescript-eslint/typedef
export const getCustomerData = withFresherToken((): AppPromiseThunkAction<GetCustomerActionType> => async (dispatch) => {
	return dispatch({
		type: CustomerActionType.GET_CUSTOMER,
		payload: getCustomer(),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const patchCustomerData = withFresherToken((data: Partial<CustomerDataRequestUnionType>): AppPromiseThunkAction<PatchCustomerActionType> => async (dispatch) => {
	return dispatch({
		type: CustomerActionType.PATCH_CUSTOMER,
		payload: patchCustomer(data),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const removeCustomerAddress = withFresherToken((addressId: number): AppPromiseThunkAction<RemoveCustomerAddressActionType> => async (dispatch, getState) => {
	return dispatch({
		type: CustomerActionType.REMOVE_CUSTOMER_ADDRESS,
		payload: async () => {
			try {
				const { customer } = getState()
				const { data } = customer

				if (isNull(data)) {
					return Promise.reject()
				}

				await deleteCustomerAddress(addressId)

				return Promise.resolve(getCustomersAfterAddressRemove(data, addressId))
			} catch (error: unknown) {
				return Promise.reject(error)
			}
		},
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const addCustomerAddress = withFresherToken((address: ICustomerAddressRequest): AppPromiseThunkAction<AddCustomerAddressActionType> => async (dispatch) => {
	return dispatch({
		type: CustomerActionType.ADD_CUSTOMER_ADDRESS,
		payload: postCustomerAddress(address),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const updateCustomerAddress = withFresherToken((addressId: number, address: Partial<ICustomerAddressRequest>): AppPromiseThunkAction<UpdateCustomerAddressActionType> => async (dispatch) => {
	return dispatch({
		type: CustomerActionType.UPDATE_CUSTOMER_ADDRESS,
		payload: patchCustomerAddress(addressId, address),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getCustomerLastViewedProducts = withFresherToken((): AppPromiseThunkAction<GetCustomerLastViewedProductsType> => async (dispatch) => {
	return dispatch({
		type: CustomerActionType.GET_CUSTOMER_LAST_VIEWED_PRODUCTS,
		payload: async () => {
			try {
				const response = await getCustomerProductsLastViewed()
				const lastViewedReferences: string[] = map(response.data.lastViewedProducts, (product: IProductSimple) => product.lmReference)

				localStorageSet(LS_KEY_LAST_VIEWED_PRODUCTS, lastViewedReferences)

				return Promise.resolve(response)
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
})
