import React, { ReactElement } from 'react'
import { isNil } from 'lodash'

import { IFooterSectionItemContentProps } from '~/components/core/layout'
import { Icon } from '~/components/core/icon'

import styles from './FooterSectionItemContent.module.scss'

const FooterSectionItemContent = (props: IFooterSectionItemContentProps): ReactElement => {
	const { icon, name } = props

	return (
		<div className={ styles.wrapper }>
			{ !isNil(icon) && (
				<Icon
					name={ icon }
					color="white"
					width={ 32 }
					additionalClass={ styles.icon }
				/>
			) }

			<span>
				{ name }
			</span>
		</div>
	)
}

export { FooterSectionItemContent }
