import React, { ReactElement } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isNull } from 'lodash'

import { CartCustomerContactItem, CartCustomerContactHeader, CartCustomerContactEditButton, CartCustomerContactEditForm, CartCustomerLoader } from '~/components/cart/cartCustomer'
import { IRootState } from '~/state/types'
import { useCustomerUpdate } from '~/hooks/customerUpdate'
import { useModal } from '~/hooks/modal'

import styles from './CartCustomerContact.module.scss'

const CartCustomerContact = (): ReactElement | null => {
	const { data: userData } = useSelector((state: IRootState) => state.customer, shallowEqual)
	const { handleOpen, handleClose, isOpen } = useModal('CartCustomerContactEdit')
	const { isUpdating, onCustomerUpdate } = useCustomerUpdate('PHONE')

	if (isNull(userData)) {
		return null
	}

	const { customer, customerType } = userData
	const { phone, phonePrefix } = customer

	return (
		<section className={ styles.wrapper } id="contact-phone-number">
			<CartCustomerContactHeader />

			<CartCustomerContactItem phoneNumber={ phone } phoneNumberPrefix={ phonePrefix } />

			<CartCustomerContactEditButton ariaLabelledBy="contact-phone-number" onClick={ handleOpen } />

			<CartCustomerContactEditForm
				customer={ customer }
				customerType={ customerType }
				isOpen={ isOpen }
				onClose={ handleClose }
				onOpen={ handleOpen }
				onUpdate={ onCustomerUpdate }
			/>

			<CartCustomerLoader isLoading={ isUpdating } />
		</section>
	)
}

export { CartCustomerContact }
