import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import { NewsletterIconsWrapper, NewsletterIconsRow, NewsletterIcon } from '~/components/core/newsletter'

const NewsletterIcons = (): ReactElement => {
	const { t } = useTranslation(['newsletter'])

	return (
		<NewsletterIconsWrapper>
			<NewsletterIconsRow>
				<NewsletterIcon
					icon="paper"
					label={ t('newsletter.newspaperLabel') }
					width={ 32 }
					height={ 25 }
				/>

				<NewsletterIcon
					icon="promoLabel"
					label={ t('newsletter.promoLabel') }
					width={ 29 }
					height={ 25 }
				/>
			</NewsletterIconsRow>

			<NewsletterIconsRow>
				<NewsletterIcon
					icon="delivery"
					label={ t('newsletter.deliveryLabel') }
					width={ 37 }
					height={ 23 }
				/>

				<NewsletterIcon
					icon="lightBulb"
					label={ t('newsletter.inspirationsLabel') }
					width={ 27 }
					height={ 31 }
				/>
			</NewsletterIconsRow>
		</NewsletterIconsWrapper>
	)
}

export { NewsletterIcons }
