import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty, toString } from 'lodash'

import { ProductPrice } from '~/components/core/productPrice'
import { Link } from '~/components/core/link'
import { IAddToCartModalProductInfoProps } from '~/components/core/addToCartModal'
import { getProductAriaLabelWithPrice } from '~/utils/productAria'

import styles from './AddToCartModalProductInfo.module.scss'

const AddToCartModalProductInfo = (props: IAddToCartModalProductInfoProps): ReactElement => {
	const { product, quantity } = props
	const { t } = useTranslation(['header'])

	const { name, wwwUrl, price, promotionLabel, priceByPackageUnit } = product
	const { basePrice, newPrice, basePriceUnit, newPricePromoLabel, omnibusPrice, packagePriceUnit } = price
	const unitName = priceByPackageUnit ? packagePriceUnit : basePriceUnit

	const ariaLabel = getProductAriaLabelWithPrice({
		promotional: !isEmpty(newPricePromoLabel),
		bigPrice: toString(newPrice),
		name,
		bigPriceUnit: `${ basePriceUnit } ${ !isEmpty(newPricePromoLabel) ? `- ${ newPricePromoLabel }` : '' }`,
		t,
	})

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.block }>
				<Link
					href={ wwwUrl }
					additionalClass={ styles.link }
					ariaLabel={ ariaLabel }
				>
					{ name }
				</Link>

				<span className={ styles.unit }>
					{ `${ quantity } ${ unitName }` }
				</span>
			</div>

			<ProductPrice
				price={ newPrice }
				priceStrikethrough={ basePrice }
				priceUnit={ basePriceUnit }
				omnibusPrice={ omnibusPrice }
				promotionLabelCode={ promotionLabel?.code }
				additionalClass={ styles.productPrice }
				size="small"
			/>
		</div>
	)
}

export { AddToCartModalProductInfo }
