import React, { ReactElement, useContext } from 'react'
import Head from 'next/head'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { KOBI_FAVICON_SRC } from '~/components/kobi/kobiFavicon'

const KobiFavicon = (): ReactElement | null => {
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	if (!isKobiIntegrationEnabled) {
		return null
	}

	return (
		<Head>
			<link rel="icon" href={ KOBI_FAVICON_SRC } />
		</Head>
	)
}

export { KobiFavicon }
