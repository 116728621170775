import React, { ReactElement } from 'react'

import { HeaderStickyModal, HeaderTopBarUserAccountLogged, IHeaderTopBarUserAccountModalProps } from '~/components/core/layout/header'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useMounted } from '~/hooks/mounted'

const HeaderTopBarUserAccountModal = (props: IHeaderTopBarUserAccountModalProps): ReactElement | null => {
	const { buttonRef, isOpen, isLogged, onClose } = props
	const { isMounted } = useMounted()

	if (!isLogged || !isMounted) {
		return null
	}

	return (
		<HeaderStickyModal
			buttonRef={ buttonRef }
			isOpen={ isOpen }
			dataTestId={ DATA_TESTID.HEADER.MODAL_USER_MENU }
			onClose={ onClose }
		>
			<HeaderTopBarUserAccountLogged onClose={ onClose } />
		</HeaderStickyModal>
	)
}

export { HeaderTopBarUserAccountModal }
