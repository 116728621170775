import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { isEmpty } from 'lodash'

import { PASSWORD_MIN_LENGTH } from '~/components/core/form'
import { IUsePasswordInputSchemaData } from '~/hooks/passwordInputSchema'
import { hasLowercase, hasNumber, hasSpecialChar, hasUppercase } from '~/utils/string'

export const usePasswordInputSchema = (): IUsePasswordInputSchemaData => {
	const { t, i18n } = useTranslation(['form'])
	const errorMessage = t('password.defaultError')

	const passwordInputSchemaEmpty = useMemo((): yup.StringSchema => yup.string().test('empty', t('required'), (value: string | undefined): boolean => !isEmpty(value)), [t, i18n])
	const passwordInputSchemaMin = useMemo((): yup.StringSchema => yup.string().min(PASSWORD_MIN_LENGTH, errorMessage), [errorMessage])
	const passwordInputSchemaOneLowerCase = useMemo((): yup.StringSchema => yup.string().test('oneLowerCase', errorMessage, (value: string | undefined): boolean => hasLowercase(value)), [errorMessage])
	const passwordInputSchemaOneUpperCase = useMemo((): yup.StringSchema => yup.string().test('oneUpperCase', errorMessage, (value: string | undefined): boolean => hasUppercase(value)), [errorMessage])
	const passwordInputSchemaOneNumber = useMemo((): yup.StringSchema => yup.string().test('oneNumber', errorMessage, (value: string | undefined): boolean => hasNumber(value)), [errorMessage])
	const passwordInputSchemaOneSpecialChar = useMemo((): yup.StringSchema => yup.string().test('specialChars', errorMessage, (value: string | undefined): boolean => hasSpecialChar(value)), [errorMessage])

	const schema = useMemo((): yup.StringSchema => yup.string()
		.concat(passwordInputSchemaEmpty)
		.concat(passwordInputSchemaMin)
		.concat(passwordInputSchemaOneLowerCase)
		.concat(passwordInputSchemaOneUpperCase)
		.concat(passwordInputSchemaOneNumber)
		.concat(passwordInputSchemaOneSpecialChar)
	, [
		passwordInputSchemaMin,
		passwordInputSchemaOneLowerCase,
		passwordInputSchemaOneUpperCase,
		passwordInputSchemaOneNumber,
		passwordInputSchemaOneSpecialChar,
	])

	return {
		schema,
		passwordInputSchemaMin,
		passwordInputSchemaOneLowerCase,
		passwordInputSchemaOneUpperCase,
		passwordInputSchemaOneNumber,
		passwordInputSchemaOneSpecialChar,
	}
}
