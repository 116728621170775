import { isEqual, isNil, isNull } from 'lodash'

import { withFresherToken } from '~/actions/auth'
import { AppPromiseThunkAction } from '~/actions'
import { AccountOrdersActionType, AccountOrdersType, AddOrderProductsToCartActionType, CancelOrderActionType, UpdateOrdersListActionType } from '~/actions/account'
import { getCustomerOrdersList, getCustomerOrderPaymentWays, getCustomerOrderDetails, postAddToCart, putCancelOrder } from '~/api/requests/customerOrder'
import { getStore } from '~/api/requests/store'
import { ICustomerOrderListRequestParams } from '~/api/dataTypes/customerOrder'
import { IPaginationRequestParams } from '~/api/dataTypes/pageable'
import { getCartUuid } from '~/utils/cart'
import { adeoCookies } from '~/utils/adeoCookies'

// eslint-disable-next-line @typescript-eslint/typedef
export const getAccountOrdersData = withFresherToken((customParams: ICustomerOrderListRequestParams): AppPromiseThunkAction<AccountOrdersActionType> => async (dispatch, getState) => {
	const { account: { orders: { list: { orders: { pageable: { pageNumber, pageSize } } } } } } = getState()
	const paginationParams: IPaginationRequestParams = { size: pageSize, page: pageNumber }
	const params: ICustomerOrderListRequestParams = { ...paginationParams, ...customParams }

	return dispatch({
		type: AccountOrdersType.GET_ORDERS_LIST,
		payload: getCustomerOrdersList(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const updateAccountOrdersData = withFresherToken((customParams: ICustomerOrderListRequestParams): AppPromiseThunkAction<UpdateOrdersListActionType> => async (dispatch, getState) => {
	const { account: { orders: { list: { orders: { pageable: { pageNumber, pageSize } } } } } } = getState()
	const paginationParams: IPaginationRequestParams = { size: pageSize, page: pageNumber }
	const params: ICustomerOrderListRequestParams = { ...paginationParams, ...customParams }

	return dispatch({
		type: AccountOrdersType.UPDATE_ORDERS_LIST,
		payload: getCustomerOrdersList(params),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getAccountOrderDetailsData = withFresherToken((orderFrontId: string): AppPromiseThunkAction<AccountOrdersActionType> => async (dispatch) => {
	return dispatch({
		type: AccountOrdersType.GET_ORDER_DETAILS,
		payload: getCustomerOrderDetails(orderFrontId),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const setAccountOrderActiveDetails = (orderFrontId: string): AppPromiseThunkAction<AccountOrdersActionType> => async (dispatch) => {
	return dispatch({
		type: AccountOrdersType.SET_ORDER_ACTIVE_DETAILS,
		payload: Promise.resolve(orderFrontId),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const clearAccountOrderActiveDetails = (): AppPromiseThunkAction<AccountOrdersActionType> => async (dispatch) => {
	return dispatch({
		type: AccountOrdersType.CLEAR_ORDER_ACTIVE_DETAILS,
		payload: Promise.resolve(),
	})
}

// eslint-disable-next-line @typescript-eslint/typedef
export const getAccountOrderPaymentWaysData = withFresherToken((orderFrontId: string): AppPromiseThunkAction<AccountOrdersActionType> => async (dispatch) => {
	return dispatch({
		type: AccountOrdersType.GET_ORDER_PAYMENT_WAYS,
		payload: getCustomerOrderPaymentWays(orderFrontId),
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const getAccountOrderStoreDetails = withFresherToken((): AppPromiseThunkAction<AccountOrdersActionType> => async (dispatch, getState) => {
	return dispatch({
		type: AccountOrdersType.GET_ORDER_STORE_DETAILS,
		payload: async () => {
			const { account: { orders: { details } } } = getState()

			const storeCode = details?.pickupOrderDetailsData?.storeCode || details?.serviceOrderDetailsData?.storeCode

			if (isNil(storeCode)) {
				return null
			}

			return getStore({ storeCode })
		},
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const addOrderProductsToCart = withFresherToken((orderFrontId: string): AppPromiseThunkAction<AddOrderProductsToCartActionType> => async (dispatch) => {
	return dispatch({
		type: AccountOrdersType.ADD_PRODUCTS_TO_CART,
		payload: async () => {
			try {
				const response = await postAddToCart(orderFrontId)

				const { data: { cart: { cartUuid } } } = response
				const currentCartUuid = getCartUuid()

				if (!isNull(cartUuid) && !isEqual(currentCartUuid, cartUuid)) {
					adeoCookies.customerUuid.set({ cartUuid, hostname: window.location.hostname })
				}

				return Promise.resolve(response)
			} catch (e: unknown) {
				return Promise.reject(e)
			}
		},
	})
})

// eslint-disable-next-line @typescript-eslint/typedef
export const cancelOrder = withFresherToken((orderFrontId: string): AppPromiseThunkAction<CancelOrderActionType> => async (dispatch) => {
	return dispatch({
		type: AccountOrdersType.CANCEL_ORDER,
		payload: putCancelOrder(orderFrontId),
	})
})
