import React, { ReactElement, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { getCartPreviewData, getIsPromotionEcommerce } from '~/state/reducers/cartPreviewReducer'
import { LysLoyaltyProviderContext } from '~/providers/lysLoyaltyProvider'
import { Icon } from '~/components/core/icon'
import { CustomerLoyaltyRewardType } from '~/api/dataTypes/customerLoyalty'
import { getLysLoyaltyLegendText } from '~/components/cart/cartPreview/cartPreviewSummary/cartPreviewSummaryDiscount/cartPreviewSummaryDiscountLys/cartPreviewDiscountLysLegend/cartPreviewDiscountLysLoyaltyLegendCoupons'

import styles from './CartPreviewDiscountLysLoyaltyLegendCoupons.module.scss'

const CartPreviewDiscountLysLoyaltyLegendCoupons = (): ReactElement => {
	const { cart: { promotionCodeUsed: isPromotionCodeUsed } } = useSelector(getCartPreviewData, shallowEqual)
	const isEcommerce = useSelector(getIsPromotionEcommerce, shallowEqual)
	const { isEditMode, loyaltyType, selectedReward } = useContext(LysLoyaltyProviderContext)
	const { t } = useTranslation(['cart'])

	if (isPromotionCodeUsed && !isEditMode) {
		const isPercentLoyalty = isEqual(selectedReward.type, 'PERCENT' as CustomerLoyaltyRewardType)
		const wrapperClass = classNames(styles.wrapper, styles.success)

		return (
			<div className={ wrapperClass }>
				<div className={ styles.iconWrapper }>
					<Icon
						name="checkCircle"
						width={ 16 }
						height={ 16 }
						color="green"
					/>
				</div>

				<span className={ styles.caption }>
					{ getLysLoyaltyLegendText(isEcommerce, isPercentLoyalty, t) }
				</span>
			</div>
		)
	}

	return (
		<span className={ styles.wrapper }>
			{ t('preview.aside.lysDiscount.legend.availableCoupons', { loyaltyType }) }
		</span>
	)
}

export { CartPreviewDiscountLysLoyaltyLegendCoupons }
