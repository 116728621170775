import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { useMozaic } from '~/hooks/mozaic'
import { ICartHeaderStepLineProps } from '~/components/core/cartHeader'

import styles from './CartHeaderStepLine.module.scss'

const CartHeaderStepLine = (props: ICartHeaderStepLineProps): ReactElement | null => {
	const {
		isPrevious,
		hasStepLine = true,
	} = props
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()

	const innerClass = classNames(styles.inner, {
		[styles.isMozaic]: shouldUseMozaic,
		[styles.active]: isPrevious,
	})

	if (!hasStepLine) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<div className={ innerClass } />
		</div>
	)
}

export { CartHeaderStepLine }
