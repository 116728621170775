import { ICartIdentificationState } from '~/state/reducers/cartIdentificationReducer'
import { LS_KEY_CALENDAR_CART_UUID } from '~/utils/constants'
import { localStorageGet } from '~/utils/storage'

export const cartIdentificationInitialState: ICartIdentificationState = {
	isPending: false,
	data: {
		cartStatus: 'IDENTIFICATION',
		simpleCart: {
			cartUuid: null,
			wwwUrl: '',
			numberOfItems: 0,
			productsValue: 0,
			totalCartValue: 0,
			cartStatus: 'IDENTIFICATION',
			productsQuantityMap: {},
			cartItemList: [],
		},
		cartToMerge: null,
		user: {
			id: null,
			firstName: '',
			lastName: '',
			email: '',
			nick: null,
			userHashedId: '',
		},
		cartGuestOrderAvailable: false,
	},
	calendarCartUuid: localStorageGet(LS_KEY_CALENDAR_CART_UUID) || null,
	isGuestOrder: false,
}
