import { AxiosResponse } from 'axios'

import { AppAction } from '~/actions'
import { ICartSummaryResponse } from '~/api/dataTypes/cart'

export enum CartSummaryType {
	GET_CART_SUMMARY = 'cartCustomer/GET_CART_SUMMARY',
	CLEAR_CART_SUMMARY_CHANGED_PRICES_ALERT_MESSAGE = 'cartCustomer/CLEAR_CART_SUMMARY_CHANGED_PRICES_ALERT_MESSAGE',
}

export type GetCartSummaryActionType = AppAction<CartSummaryType.GET_CART_SUMMARY, AxiosResponse<ICartSummaryResponse>>
export type ClearCartSummaryChangedPricesAlertMessage = AppAction<CartSummaryType.CLEAR_CART_SUMMARY_CHANGED_PRICES_ALERT_MESSAGE>

export type CartSummaryActionType = GetCartSummaryActionType | ClearCartSummaryChangedPricesAlertMessage
