import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { isEqual, isNil, map } from 'lodash'

import { IOpenHourData } from '~/api/dataTypes/openHour'
import { Button } from '~/components/core/button'
import { ICartPreviewDeliveryPickupPointInfoWindowProps } from '~/components/cart/cartPreview'

import styles from './CartPreviewDeliveryPickupPointInfoWindow.module.scss'

const CartPreviewDeliveryPickupPointInfoWindow = (props: ICartPreviewDeliveryPickupPointInfoWindowProps): ReactElement => {
	const { point, selectedPointId, onSelect } = props
	const { t } = useTranslation(['cart'])

	const { id, pickupPointCode, address: { city, street, zipCode }, typeName, openHours } = point

	const isSelected = !isNil(selectedPointId) && isEqual(selectedPointId, id)

	const handleSelectPoint = useCallback((): void => {
		onSelect(point)
	}, [onSelect, point])

	const renderOpenHours = useCallback((): ReactElement => (
		<ul className={ styles.list }>
			{ map(openHours, (openHour: IOpenHourData) => {
				const { dayOfWeek, open, close } = openHour

				return (
					<li key={ dayOfWeek } className={ styles.dayOfWeek }>
						<span className={ styles.weekDay }>
							{ dayOfWeek }{ ': ' }
						</span>

						<span>
							{ open }{ '-' }{ close }
						</span>
					</li>
				)
			}) }
		</ul>
	), [openHours])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.name }>
				{ typeName }{ ' - ' }{ pickupPointCode }
			</div>

			<div className={ styles.address }>
				{ street }<br />

				{ zipCode }{ ', ' }{ city }
			</div>

			<div className={ styles.openingHours }>
				<div className={ styles.caption }>
					{ t('preview.delivery.pickupPoints.openHours') }
				</div>

				{ renderOpenHours() }
			</div>

			<Button
				text={ isSelected ? t('preview.delivery.pickupPoints.selectedPoint') : t('preview.delivery.pickupPoints.selectPoint') }
				size="small"
				variant="secondary"
				additionalClass={ styles.button }
				isDisabled={ isSelected }
				onClick={ handleSelectPoint }
			/>
		</div>
	)
}

export { CartPreviewDeliveryPickupPointInfoWindow }
