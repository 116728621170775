import React, { ReactElement, useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import classNames from 'classnames'

import { IHeaderMenuWrapperProps } from '~/components/core/layout/header'
import { Container } from '~/components/core/layout/container'

import styles from './HeaderMenuWrapper.module.scss'

const HeaderMenuWrapper = (props: IHeaderMenuWrapperProps): ReactElement => {
	const { children } = props
	const { y } = useWindowScroll()
	const [isHeaderFixed, setIsHeaderFixed] = useState<boolean>(false)

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.notVisible]: isHeaderFixed,
	})

	useEffect(() => {
		setIsHeaderFixed(y > 0)
	}, [y])

	return (
		<div className={ wrapperClass }>
			<Container>
				{ children }
			</Container>
		</div>
	)
}

export { HeaderMenuWrapper }
