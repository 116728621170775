import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { Button } from '~/components/core/button'
import { Icon } from '~/components/core/icon'
import { CartPreviewRemoveProductsModal } from '~/components/cart/cartPreview'
import { CartShareModal } from '~/components/cart/cartShare'
import { useModal } from '~/hooks/modal'
import { AppDispatch } from '~/state/store'
import { removeProductsFromCart } from '~/actions/cart'
import { DATA_TESTID } from '~/utils/dataTestId'
import { useKobiHeader } from '~/hooks/kobiHeader'
import { useMozaic } from '~/hooks/mozaic'

import styles from './CartPreviewActions.module.scss'

const CartPreviewActions = (): ReactElement => {
	const { isOpen: isOpenShare, handleOpen: handleOpenShare, handleClose: handleCloseShare } = useModal('CartShareModal')
	const { isOpen: isOpenRemove, handleOpen: handleOpenRemove, handleClose: handleCloseRemove } = useModal('CartRemoveModal')
	const { t } = useTranslation(['cart'])
	const dispatch: AppDispatch = useDispatch()
	const { handleDispatchKobiCartHeader } = useKobiHeader()
	const { getShouldUseMozaicFlag } = useMozaic()

	const shouldUseMozaic = getShouldUseMozaicFlag()
	const shareIconName = shouldUseMozaic ? 'mozaicShareAndroid' : 'share'
	const shareIconColor = shouldUseMozaic ? 'mozaicPrimary01600' : 'green'
	const shareIconWidth = shouldUseMozaic ? 16 : 14

	const buttonClass = classNames(styles.button, {
		[styles.isMozaic]: shouldUseMozaic,
	})

	const handleShare = useCallback((): void => {
		handleOpenShare()
	}, [isOpenShare])

	const handleRemove = useCallback((): void => {
		handleOpenRemove()
	}, [isOpenRemove])

	const handleRemoveProducts = useCallback(async (): Promise<void> => {
		await dispatch(removeProductsFromCart())

		handleDispatchKobiCartHeader()
	}, [handleDispatchKobiCartHeader])

	return (
		<div className={ styles.wrapper }>
			<div className={ styles.actionButtonsContainer }>
				<Button
					size="inherit"
					variant="neutral"
					additionalClass={ buttonClass }
					ariaLabel={ t('preview.table.actions.removeProductsFromCart') }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_REMOVE_ALL_PRODUCTS }
					onClick={ handleRemove }
				>
					<span className={ styles.caption }>
						{ t('preview.table.actions.removeProductsFromCart') }
					</span>
				</Button>

				<Button
					size="inherit"
					variant="neutral"
					additionalClass={ buttonClass }
					ariaLabel={ t('preview.table.actions.shareYourCart') }
					dataTestId={ DATA_TESTID.CART_PREVIEW.BUTTON_OPEN_SHARE_CART_MODAL }
					onClick={ handleShare }
				>
					<Icon
						name={ shareIconName }
						width={ shareIconWidth }
						color={ shareIconColor }
						additionalClass={ styles.icon }
					/>

					<span className={ styles.caption }>
						{ t('preview.table.actions.shareYourCart') }
					</span>
				</Button>
			</div>

			<CartShareModal isOpen={ isOpenShare } onClose={ handleCloseShare } />

			<CartPreviewRemoveProductsModal
				isOpen={ isOpenRemove }
				onClose={ handleCloseRemove }
				onRemoveProducts={ handleRemoveProducts }
			/>
		</div>
	)
}

export { CartPreviewActions }
