import React, { ReactElement } from 'react'

import { HeaderTopBarSearchBarProductPrice, IHeaderTopBarSearchBarProductInfoBlockProps } from '~/components/core/layout'

import styles from './HeaderTopBarSearchBarProductInfoBlock.module.scss'

const HeaderTopBarSearchBarProductInfoBlock = (props: IHeaderTopBarSearchBarProductInfoBlockProps): ReactElement => {
	const { name, bigPrice, bigPriceUnit, promotional } = props

	return (
		<span className={ styles.wrapper }>
			<span className={ styles.caption }>{ name }</span>

			<HeaderTopBarSearchBarProductPrice
				bigPrice={ bigPrice }
				bigPriceUnit={ bigPriceUnit }
				promotional={ promotional }
			/>
		</span>
	)
}

export { HeaderTopBarSearchBarProductInfoBlock }
