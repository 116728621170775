import { AxiosResponse } from 'axios'

import { request } from '~/api/requests/axios'

export const getCitiesByZipCode = async (zipCode: string): Promise<AxiosResponse<string[]>> => (
	await request.get('/v1/cities', {
		params: {
			zipCode,
		},
	})
)
