import { map, find, some, isUndefined } from 'lodash'

import { ITimeSlotBasicData } from '~/api/dataTypes/cart'

export const deliveryFromStoreTimeslotBuilder = (timeslots: ITimeSlotBasicData[]): IFormattedTimeslot[] => {
	const formattedTimeslots: IFormattedTimeslot[] = []

	map(timeslots, (timeslot: ITimeSlotBasicData) => {
		const { date, fromTime, toTime } = timeslot

		const formattedTime: IFormattedTimeslot = {
			date,
			timePeriod: [{
				fromTime,
				toTime,
			}],
		}

		const formattedTimePeriod: IFormattedTimeslotTimePeriod = {
			fromTime,
			toTime,
		}

		some(formattedTimeslots, (item: IFormattedTimeslot, index: number) => {
			if (item.date === date) {
				formattedTimeslots[index].timePeriod.push(formattedTimePeriod)
			}
		})

		if (isUndefined(find(formattedTimeslots, (item: IFormattedTimeslot) => item.date === date))) {
			formattedTimeslots.push(formattedTime)
		}

		return
	})

	return formattedTimeslots
}

export interface IFormattedTimeslotTimePeriod {
	fromTime: number
	toTime: number
}

export interface IFormattedTimeslot {
	date: number
	timePeriod: IFormattedTimeslotTimePeriod[]
}
