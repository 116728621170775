import React, { ReactElement, useCallback } from 'react'
import { map } from 'lodash'

import { CartCustomerGuestFormAgreementsItem, ICartCustomerGuestFormAgreementsProps } from '~/components/cart/cartCustomer'
import { IAcceptanceCheckAppParamsData } from '~/api/dataTypes/app'

import styles from './CartCustomerGuestFormAgreements.module.scss'

const CartCustomerGuestFormAgreements = (props: ICartCustomerGuestFormAgreementsProps): ReactElement => {
	const { agreements } = props

	const renderContent = useCallback((): ReactElement[] => map(agreements, (agreement: IAcceptanceCheckAppParamsData) => {
		const { code } = agreement

		return (
			<CartCustomerGuestFormAgreementsItem key={ code } agreement={ agreement } />
		)
	}), [agreements])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { CartCustomerGuestFormAgreements }
