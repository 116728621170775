import { ChangeEvent, FocusEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useClickAway, useKeyPressEvent, useList, useToggle } from 'react-use'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import { debounce, gte, isEmpty, isEqual, isNull, uniqBy, toString } from 'lodash'

import { IHeaderTopBarSearchBarForm, ISearchHistoryItem } from '~/components/core/layout'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { IUseHeaderSearchData, MIN_SEARCH_TEXT_LENGTH, S_KEY_SEARCH_HISTORY } from '~/hooks/headerSearch'
import { HeaderTopBarSearchContext } from '~/providers/headerTopBarSearchProvider'
import { AppParametersContext } from '~/providers/appParametersProvider'
import { MobileSearchBarContext } from '~/providers/mobileSearchBarProvider'
import { sessionStorageGet, sessionStorageSet } from '~/utils/storage'
import { ISuggestionSearchData, ISuggestionNodeData, ISuggestionProductData } from '~/api/dataTypes/search'
import { getSuggestions } from '~/api/requests/search'

export const useHeaderSearch = (): IUseHeaderSearchData => {
	const { visible: isVisible, handleToggleVisible } = useContext(MobileSearchBarContext)
	const { urls, searchConfig } = useContext(AppParametersContext)
	const formRef = useRef<HTMLFormElement | null>(null)
	const router = useRouter()
	const [suggestionSearchList, { set: setSuggestionSearchList }] = useList<ISuggestionSearchData>([])
	const [suggestionNodeList, { set: setSuggestionNodeList }] = useList<ISuggestionNodeData>([])
	const [suggestionProductList, { set: setSuggestionProductList }] = useList<ISuggestionProductData>([])
	const { isDesktop } = useMediaQuery()
	const { onCloseSearchModal } = useContext(HeaderTopBarSearchContext)
	const [isLoading, setIsLoading] = useToggle(false)
	const [, toggleForceRerenderResults] = useToggle(false)
	const [isSearchResultVisible, setIsSearchResultVisible] = useToggle(false)
	const [isFocusedByPointer, setIsFocusedByPointer] = useToggle(false)
	const [isShiftTabPressed] = useKeyboardJs('shift+tab')
	const { t, i18n } = useTranslation(['form'])

	const schema: yup.SchemaOf<IHeaderTopBarSearchBarForm> = useMemo(() => (
		yup.object().shape({
			search: yup.string().ensure().test({
				name: 'search',
				params: { min: MIN_SEARCH_TEXT_LENGTH },
				message: t('validation.minLength'),
				test: (text: string): boolean => gte(text.length, MIN_SEARCH_TEXT_LENGTH) || isEqual(text.length, 0),
			}),
		})
	), [t, i18n])

	const { handleSubmit, register, setValue, formState: { errors, isValid }, watch } = useForm<IHeaderTopBarSearchBarForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			search: '',
		},
		mode: 'onChange',
	})

	const { onChange, onBlur, name, ref } = register('search')
	const { search } = watch()

	const isNoResults = isEmpty(suggestionSearchList) && isEmpty(suggestionNodeList) && isEmpty(suggestionProductList)
	const isAsideVisible = isDesktop && !isNoResults
	const isNoResultContentVisible = isNoResults && !isLoading

	const clearData = useCallback(() => {
		setSuggestionSearchList([])
		setSuggestionNodeList([])
		setSuggestionProductList([])
	}, [])

	const getData = useCallback(debounce(async (query: string): Promise<void> => {
		if (!isEmpty(query)) {
			const { data } = await getSuggestions(query)

			if (!isEmpty(data)) {
				const { suggestionSearchList, suggestionNodeList, suggestionProductList } = data

				setSuggestionSearchList(suggestionSearchList)
				setSuggestionNodeList(suggestionNodeList)
				setSuggestionProductList(suggestionProductList)
			}

			setIsSearchResultVisible(true)
		}

		setIsLoading(false)
	}, 200), [isLoading])

	const handleChange = useCallback(debounce(async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		const { value: rawValue } = event.target
		const value = toString(rawValue).trim()

		clearData()
		setIsLoading(true)
		getData(value)
		await onChange(event)
	}, 200), [getData])

	const handleForceRerenderResults = useCallback((): void => {
		toggleForceRerenderResults()
	}, [])

	const handlePointerOver = useCallback((): void => {
		setIsFocusedByPointer(true)
	}, [])

	const handleBlur = useCallback(async (event: FocusEvent<HTMLInputElement>): Promise<void> => {
		await onBlur(event)
		setIsFocusedByPointer(false)

		if (isShiftTabPressed) {
			handleHideSearchSuggestions()
		} else {
			handleForceRerenderResults()
		}
	}, [onBlur, isNoResultContentVisible, isShiftTabPressed])

	const handleFocus = useCallback((): void => {
		setIsSearchResultVisible(true)
	}, [])

	const searchHistory: ISearchHistoryItem[] = sessionStorageGet(S_KEY_SEARCH_HISTORY) || []
	const uniqSearchHistory: ISearchHistoryItem[] = uniqBy(searchHistory, 'val')

	const handleFormSubmit = useCallback(handleSubmit(async (formData: IHeaderTopBarSearchBarForm) => {
		const { search } = formData

		if (isEmpty(search.trim())) {
			return
		}

		const wwwUrl = `${ urls.search }${ search }`

		await router.push(wwwUrl)

		const storageToSave = !isNull(searchHistory) ? [...searchHistory, { val: search }] : { val: search }

		sessionStorageSet(S_KEY_SEARCH_HISTORY, storageToSave)
		handleHideSearchSuggestions()
	}), [searchHistory])

	const handleHideSearchSuggestions = useCallback((): void => {
		setIsSearchResultVisible(false)
	}, [])

	const clearSearch = useCallback((): void => {
		setValue('search', '')
		handleHideSearchSuggestions()
		clearData()
		onCloseSearchModal()
	}, [])

	useEffect(() => {
		clearSearch()
	}, [router.asPath])

	useKeyPressEvent('Escape', handleHideSearchSuggestions)
	useClickAway(formRef, handleHideSearchSuggestions)

	return {
		isValid,
		isVisible,
		isLoading,
		isSearchResultVisible,
		isNoResultContentVisible,
		isAsideVisible,
		isFocusedByPointer,
		isDesktop,
		handlePointerOver,
		handleFocus,
		handleBlur,
		handleFormSubmit,
		handleHideSearchSuggestions,
		handleForceRerenderResults,
		handleChange,
		handleToggleVisible,
		clearSearch,
		suggestionSearchList,
		suggestionNodeList,
		suggestionProductList,
		uniqSearchHistory,
		formRef,
		name,
		ref,
		errors,
		search,
		router,
		searchConfig,
	}
}
