import React, { ReactElement, useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { SCROLL_TO_TOP_OFFSET } from '~/components/core/scrollToTop'
import { Icon } from '~/components/core/icon'
import { SkipContentAnchor } from '~/components/core/skipContent'
import { Button } from '~/components/core/button'
import { SKIP_SCROLL_TO_TOP } from '~/hooks/skipElements'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { DATA_TESTID } from '~/utils/dataTestId'

import styles from './ScrollToTop.module.scss'

const ScrollToTop = (): ReactElement => {
	const { t } = useTranslation(['common'])
	const [isVisible, setIsVisible] = useState<boolean>(false)
	const { y } = useWindowScroll()
	const { isTablet } = useMediaQuery()

	const scrollButtonClass = classNames({
		[styles.scrollButton]: true,
		[styles.visible]: isVisible && isTablet,
	})

	const handleClick = (): void => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	useEffect(() => {
		setIsVisible(y > SCROLL_TO_TOP_OFFSET)
	}, [y])

	return (
		<div className={ styles.wrapper }>
			<SkipContentAnchor elementId={ SKIP_SCROLL_TO_TOP } />

			<div>
				<Button
					variant="neutral"
					size="inherit"
					additionalClass={ scrollButtonClass }
					ariaLabel={ t('scrollToTop') }
					dataTestId={ DATA_TESTID.CORE.BUTTON_SCROLL_TO_TOP }
					onClick={ handleClick }
				>
					<Icon
						name="arrowThin"
						width={ 12 }
						color="white"
						height={ 18 }
						additionalClass={ styles.icon }
					/>
				</Button>
			</div>
		</div>
	)
}

export { ScrollToTop }
