import { MutableRefObject, useEffect, useState } from 'react'
import { useWindowScroll, useWindowSize } from 'react-use'
import { useResizeDetector } from 'react-resize-detector'

import { IWindowElementData } from '~/hooks/windowElement'

export const useWindowElement = (ref: MutableRefObject<HTMLDivElement | null>): IWindowElementData => {
	const [elementTopOffset, setElementTopOffset] = useState<number>(0)
	const [distanceToTop, setDistanceToTop] = useState<number>(0)
	const { y } = useWindowScroll()
	const { height, width } = useWindowSize()
	const { height: elementHeight } = useResizeDetector<HTMLDivElement | null>({ targetRef: ref })

	useEffect(() => {
		const topOffset = ref.current?.offsetTop || 0
		const distanceToTop = topOffset - y

		setDistanceToTop(distanceToTop)
		setElementTopOffset(topOffset)
	}, [y, height, width])

	return {
		yScrollPosition: y,
		elementHeight: elementHeight || 0,
		elementTopOffset,
		distanceToTop,
	}
}
