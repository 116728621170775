import { isNull } from 'lodash'

export type StorageValueType = string | number | boolean | null | undefined | object

export const createStorageGet = (storage: Storage | null) => <T extends StorageValueType>(key: string): T | null => {
	if (isNull(storage)) {
		return null
	}

	const value = storage.getItem(key)

	return !isNull(value) ? JSON.parse(value) : null
}

export const createStorageSet = (storage: Storage | null) => (key: string, value: StorageValueType): void => {
	if (!isNull(storage)) {
		storage.setItem(key, JSON.stringify(value))
	}
}

export const createStorageRemove = (storage: Storage | null) => (key: string): void => {
	if (!isNull(storage)) {
		storage.removeItem(key)
	}
}

export const localStorageGet = createStorageGet(typeof window === 'undefined' ? null : localStorage)
export const localStorageSet = createStorageSet(typeof window === 'undefined' ? null : localStorage)
export const localStorageRemove = createStorageRemove(typeof window === 'undefined' ? null : localStorage)

export const sessionStorageGet = createStorageGet(typeof window === 'undefined' ? null : sessionStorage)
export const sessionStorageSet = createStorageSet(typeof window === 'undefined' ? null : sessionStorage)
export const sessionStorageRemove = createStorageRemove(typeof window === 'undefined' ? null : sessionStorage)
