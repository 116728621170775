import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

import styles from './HeaderTopBarMiniCartModalHeader.module.scss'

const HeaderTopBarMiniCartModalHeader = (): ReactElement => {
	const { t } = useTranslation(['header'])

	return (
		<div className={ styles.wrapper }>
			{ t('topBar.miniCart.caption') }
		</div>
	)
}

export { HeaderTopBarMiniCartModalHeader }
