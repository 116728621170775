import { createSelector } from 'reselect'
import { isNull, isNil } from 'lodash'

import { IOrderDetailsResponse } from '~/api/dataTypes/authlessOrder'
import { IRootState } from '~/state/types'
import { ConfirmationOrderSuccessType, ICartConfirmationState, IConfirmationBasicData } from '~/state/reducers/cartConfirmationReducer'

export const getConfirmationData = (state: IRootState): IOrderDetailsResponse => (
	state.cartConfirmation.data
)

export const getConfirmationState = (state: IRootState): ICartConfirmationState => (
	state.cartConfirmation
)

export const isConfirmationOrderNumberAvailable = createSelector(getConfirmationData, (data: IOrderDetailsResponse): boolean => {
	const { orderNumber } = data

	return !isNil(orderNumber)
})

export const getConfirmationSuccessType = createSelector(getConfirmationData, (data: IOrderDetailsResponse): ConfirmationOrderSuccessType => {
	const { paymentDetailsData: { orderPaymentWay } } = data

	if (isNull(orderPaymentWay) || orderPaymentWay === 'DELIVERY') {
		return 'cashOnDelivery'
	}

	return 'unpaid'
})

export const getConfirmationClientEmail = createSelector(getConfirmationData, (data: IOrderDetailsResponse): string | null => {
	const { deliveryOrderDetailsData, pickupOrderDetailsData } = data

	if (!isNull(deliveryOrderDetailsData)) {
		return deliveryOrderDetailsData.customerDetailsData.email
	}

	if (!isNull(pickupOrderDetailsData)) {
		return pickupOrderDetailsData.customerDetailsData?.email || null
	}

	return null
})

export const getConfirmationBasicData = createSelector(getConfirmationState, (state: ICartConfirmationState): IConfirmationBasicData => {
	const { orderFrontId, data: { orderNumber, pickupOrderDetailsData, paymentDetailsData: { paymentDeadline, orderTransactionId }, orderStatusUrl } } = state

	return {
		orderFrontId,
		orderNumber,
		orderStatusUrl,
		paymentDeadline,
		orderTransactionId,
		promiseDate: pickupOrderDetailsData?.promisedDate || null,
	}
})
